<template>
  <Card border-color="primary">
    <CardTitle>
      <VText component="h2" variant="h5"> Setup your image grid link </VText>
      <FlexSpacer />
      <IconButton
        size="1.25rem"
        :loading="isDeleting"
        title="Delete"
        aria-label="Delete"
        @click="$emit('remove')"
      >
        <CloseIcon />
      </IconButton>
    </CardTitle>
    <Divider color="primary" />
    <CardText>
      <template v-if="isLoading">
        <Skeleton :count="2" />
      </template>
      <template v-else-if="hasInstagramAuthed">
        <VText gutter-bottom>
          We'll load in your latest {{ numberOfPostsLoaded }} Instagram posts.
          <InlineLink v-if="isFreePlan" to="/account/change-plan"
            >Upgrade to Pro to load your last 50 posts.</InlineLink
          >
        </VText>
        <div style="margin-bottom: 1em">
          <AppAuthItem :item="instagramAuthorization" readonly />
        </div>
        <Button key="setup" :loading="isSaving" @click="handleSetup"
          >Finish Setup</Button
        >
      </template>
      <template v-else>
        <VText gutter-bottom>
          You'll need to authorize with Instagram before you can complete setup.
        </VText>
        <AuthorizeInstagramBtn key="auth">
          Authorize Instagram
        </AuthorizeInstagramBtn>
      </template>
    </CardText>
  </Card>
</template>

<script>
import {
  Card,
  CardText,
  CardTitle,
  Button,
  Divider,
  VText,
  IconButton,
  CloseIcon,
  FlexSpacer,
  Skeleton,
  Badge,
  InlineLink,
} from '@campsite-bio/component-lib';
import { computed, onMounted, ref } from 'vue';

import { useStore } from '../../../compositions';
import toast from '../../../utils/toast';
import AuthorizeInstagramBtn from '../../authorize-instagram-btn';
import { AppAuthItem } from '../../app-authorizations';

export default {
  components: {
    Card,
    CardText,
    CardTitle,
    Button,
    Divider,
    VText,
    FlexSpacer,
    IconButton,
    CloseIcon,
    Skeleton,
    AuthorizeInstagramBtn,
    AppAuthItem,
    Badge,
    InlineLink,
  },

  props: {
    imageGridOptions: {
      type: Object,
      required: true,
    },

    linkId: {
      type: String,
      required: true,
    },

    isDeleting: Boolean,
  },

  setup(props, { emit }) {
    const store = useStore();
    const isSaving = ref(false);
    const isLoading = ref(false);

    const isFreePlan = computed(() => {
      return store.getters['profiles/isProfileOnFreePlan'];
    });

    const instagramAuthorization = computed(() => {
      return store.getters['profiles/instagramAuthorization'];
    });

    const hasInstagramAuthed = computed(() => {
      return (
        !!instagramAuthorization.value &&
        instagramAuthorization.value?.status === 'connected'
      );
    });

    const numberOfPostsLoaded = computed(() => {
      return isFreePlan.value ? 18 : 50;
    });

    async function handleSetup() {
      isSaving.value = true;

      try {
        // Save the result to the feed options
        const { link } = await store.dispatch('links/updateOptions', {
          id: props.linkId,
          has_setup: true,
          // Limit free users to 12 posts
          limit: isFreePlan.value ? 12 : null,
          name: 'image-grid',
        });

        const items = link.items;
        delete link.items;
        // Show the link now that it's been setup
        store.commit('links/updateLink', link);
        store.commit('linkItems/set', {
          linkId: props.linkId,
          items: items,
        });
        emit('setup-complete');
      } catch (e) {
        const { error_message } = e.response?.data || {};
        console.error(e);
        toast.error(
          error_message || 'There was an error trying to setup your image grid',
        );
      }

      isSaving.value = false;
    }

    async function load() {
      isLoading.value = true;
      try {
        await store.dispatch('profiles/getAppAuthorizations');
      } catch (e) {
        toast.error('There was an error trying to get your providers');
      }
      isLoading.value = false;
    }

    onMounted(load);

    return {
      handleSetup,
      isSaving,
      isLoading,
      instagramAuthorization,
      hasInstagramAuthed,
      numberOfPostsLoaded,
      isFreePlan,
    };
  },
};
</script>

<style lang="scss" scoped></style>
