import { ref, watch } from 'vue';
import { PROFILE_ACTIVITY_TYPES } from '../../utils';

import { useProfileLogger } from './use-profile-logger';
import { useUserLogger } from './use-user-logger';

export function useReportLogger(options) {
  const { profileId, userId, allData, report } = options;
  const hasLoggedView = ref(false);

  // If profileId is passed, log to profile, otherwise log to user
  const log = profileId ? useProfileLogger(profileId) : useUserLogger(userId);

  // reset so we can log again if the profile changes
  if (profileId) {
    watch(profileId, () => {
      hasLoggedView.value = false;
    });
  }

  watch(
    allData,
    (newVal) => {
      if (newVal && !hasLoggedView.value) {
        const { mlc_cache, report: reportFromData } = newVal;
        const data = {
          report_name: report || reportFromData,
          mlc_cache,
        };
        log(PROFILE_ACTIVITY_TYPES.REPORT_VIEW, data);
        hasLoggedView.value = true;
      }
    },
    { immediate: true },
  );

  return log;
}
