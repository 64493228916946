<template>
  <ChartCard
    :title="title"
    :subtitle="subtitle"
    :is-loading="isLoading"
    :timestamp="timestamp"
  >
    <Badge
      v-if="percentDifference || typeof percentDifference === 'number'"
      slot="badge"
      :color="badgeColor"
      :content="percentDifferenceFormatted"
    />
    <div class="v-chart-card__numbers">
      <InteractiveChartCardNumber
        v-for="({ title, subtitle, color }, index) in currentValues"
        :key="`a${index}`"
        :title="title"
        :subtitle="subtitle"
        :color="color"
      />
      <FlexSpacer />
      <template
        v-for="({ title, subtitle, color = dimColor }, index) in previousValues"
      >
        <InteractiveChartCardNumber
          v-if="title"
          :key="`b${index}`"
          :title="title"
          :subtitle="subtitle"
          :color="color"
        />
      </template>
    </div>
    <slot />
    <div class="v-chart-card__timeline">
      <VText component="span" variant="inherit" :color="dimColor">{{
        startDate
      }}</VText>
      <VText component="span" variant="inherit" :color="dimColor">{{
        endDate
      }}</VText>
    </div>
    <slot name="after" />

    <template slot="actions">
      <slot name="actions" />
    </template>
  </ChartCard>
</template>

<script>
import { colors, VText, Badge, FlexSpacer } from '@campsite-bio/component-lib';
import { computed } from 'vue';
import ChartCard from './chart-card';
import InteractiveChartCardNumber from './interactive-chart-card-number';

export default {
  components: {
    VText,
    Badge,
    FlexSpacer,
    ChartCard,
    InteractiveChartCardNumber,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      default: null,
    },

    percentDifference: {
      type: [String, Number],
      default: null,
    },

    currentValues: {
      type: Array,
      required: true,
    },

    previousValues: {
      type: Array,
      default: () => [],
    },

    startDate: {
      type: String,
      required: true,
    },

    endDate: {
      type: String,
      required: true,
    },

    isLoading: Boolean,
    timestamp: Date,
  },

  setup(props) {
    const badgeColor = computed(() => {
      return props.percentDifference >= 0 ? 'success' : 'info';
    });

    const percentDifferenceFormatted = computed(() => {
      if (typeof props.percentDifference === 'number')
        return `${props.percentDifference.toFixed(1)}%`;
      return props.percentDifference;
    });

    return {
      badgeColor,
      dimColor: colors.grayDark,
      percentDifferenceFormatted,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-chart-card {
  &__numbers,
  &__timeline {
    align-items: center;
    display: flex;
  }

  &__numbers,
  &__timeline {
    justify-content: space-between;
  }

  &__numbers {
    font-size: 1.5rem;
    gap: 0.75rem;

    @media (min-width: 544px) {
      font-size: 1.75rem;
    }
  }

  &__timeline {
    font-size: 0.75rem;
    margin-top: -0.25rem;
  }
}
</style>
