<template>
  <div>
    <Input
      :id="inputId"
      ref="input"
      v-model="text"
      :placeholder="placeholder"
      @focus="handleFocus"
      @blur="handleBlur"
    >
      <template slot="prepend">
        <CircularLoader v-if="isLoading" :thickness="4" />
        <slot v-else name="icon">
          <SearchIcon size="inherit" />
        </slot>
      </template>
    </Input>
    <v-menu
      v-model="showResults"
      :activator="inputEl"
      :open-on-click="false"
      bottom
      offset-y
      v-bind="menuProps"
      max-height="400"
    >
      <List>
        <ListItem v-if="results.length == 0">
          <ListItemContent>
            <ListItemTitle>{{
              isLoading ? 'Loading results...' : 'No results'
            }}</ListItemTitle>
          </ListItemContent>
        </ListItem>
        <ListItem
          v-for="result in results"
          v-else
          :key="result.etag"
          component="button"
          type="button"
          @click="handleSelect(result)"
        >
          <ListItemAvatar>
            <img
              :src="result.snippet.thumbnails.default.url"
              alt=""
              aria-hidden="true"
            />
          </ListItemAvatar>
          <ListItemContent>
            <ListItemTitle>{{ unescape(result.snippet.title) }}</ListItemTitle>
            <ListItemSubtitle>{{
              startCase(result.id.kind.replace('youtube#', ''))
            }}</ListItemSubtitle>
          </ListItemContent>
          <ListItemAction v-if="result.id.kind === 'youtube#playlist'">
            <PlaylistPlayIcon size="1.5rem" title="Playlist" />
          </ListItemAction>
        </ListItem>
      </List>
    </v-menu>
  </div>
</template>

<script>
import {
  SearchIcon,
  Input,
  CircularLoader,
  PlaylistPlayIcon,
  List,
  ListItem,
  ListItemContent,
  ListItemTitle,
  ListItemSubtitle,
  ListItemAction,
  ListItemAvatar,
} from '@campsite-bio/component-lib';
import { computed, ref, toRefs, watch } from 'vue';
import axios from 'axios';
import debounce from 'lodash/debounce';
import unescape from 'lodash/unescape';
import startCase from 'lodash/startCase';

import { useModel } from '../../compositions';
import { localAxios } from '../../apis';

export default {
  components: {
    SearchIcon,
    Input,
    CircularLoader,
    PlaylistPlayIcon,
    List,
    ListItem,
    ListItemContent,
    ListItemTitle,
    ListItemSubtitle,
    ListItemAction,
    ListItemAvatar,
  },
  props: {
    value: {
      type: String,
      default: null,
    },

    /** The type of resource to search */
    types: {
      type: Array,
      default() {
        return ['channel', 'playlist', 'video'];
      },
    },

    maxResults: {
      type: Number,
      default: 20,
    },

    inputId: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      default: 'Search Youtube',
    },

    menuProps: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  setup(props, { emit }) {
    const { value, types, maxResults } = toRefs(props);
    const isLoading = ref(false);
    const showResults = ref(false);
    const input = ref(null);
    const cancelToken = ref(null);
    const results = ref([]);
    const selectedItem = ref(null);

    const text = useModel(value, (val) => emit('input', val));

    async function search() {
      if (!text.value) {
        results.value = [];
        showResults.value = false;
        return;
      }

      isLoading.value = true;
      results.value = [];
      if (cancelToken.value) cancelToken.value.cancel();
      cancelToken.value = axios.CancelToken.source();

      try {
        const { data } = await localAxios.get(`/api/search/youtube`, {
          params: {
            q: text.value,
            maxResults: maxResults.value,
            type: types.value.join(','),
          },
          cancelToken: cancelToken.value.token,
        });

        showResults.value = true;
        results.value = data.items;
      } catch (e) {
        if (axios.isCancel(e) || e.name === 'AbortError') return;
        console.error(e);
      }

      isLoading.value = false;
    }

    watch(
      text,
      debounce(() => search(), 500),
    );

    const inputEl = computed(() => {
      if (!input.value) return null;

      return input.value.$el;
    });

    function handleFocus() {
      if (text.value) showResults.value = true;
    }

    function handleBlur() {
      // showResults.value = false;
    }

    function handleSelect(item) {
      selectedItem.value = item;
      emit('select', item);
    }

    return {
      text,
      isLoading,
      showResults,
      results,
      handleFocus,
      handleBlur,
      unescape,
      startCase,
      selectedItem,
      handleSelect,
      input,
      inputEl,
    };
  },
};
</script>

<style lang="scss" scoped></style>
