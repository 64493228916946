import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'cookies-js';
import { getField, updateField } from 'vuex-map-fields';

import user from './user';
import links from './links';
import linkItems from './link-items';
import settings from './settings';
import media from './media';
import qrCode from './qr-code';
import profiles from './profiles';
import subscriptions from './subscriptions';
import analytics from './analytics';
import canvaMedia from './canva-media';
import organizations from './organizations';
import linkSet from './link-set';
import designer from './designer';
import { localAxios } from '@/apis';

Vue.use(Vuex);

const defaultMediaDialogProps = {};

export default new Vuex.Store({
  modules: {
    user,
    links,
    linkItems,
    settings,
    media,
    canvaMedia,
    qrCode,
    profiles,
    analytics,
    subscriptions,
    designer,
    organizations,
    linkSet,
  },

  state: {
    appSettings: {},
    clientVersion: sessionStorage['clientVersion'],
    csrfToken: '',
    layout: '',
    isSubmenuOpen: true,
    isLoading: false,
    loadCount: 0,
    timezones: [],
    showMediaDialog: false,
    campsiteAddData: null,
    showAccountDialog: false,
    showCheckedOutDialog: false,
    showAuthDialog: false,
    authData: null,
    hideFeedback: false,
    mediaDialogProps: defaultMediaDialogProps,
    isDocumentVisible: true,
    isIdle: false,
    isAddingLink: false,
    maintenanceMode: false,
  },

  getters: {
    clientVersion: (state) => state.clientVersion,
    csrfToken: (state) => state.csrfToken,
    isLoading: (state) => state.isLoading,
    layout: (state) => state.layout,
    timezones: (state) => state.timezones,
    showMediaDialog: (state) => state.showMediaDialog,
    campsiteAddData: (state) => state.campsiteAddData,
    showCheckedOutDialog: (state) => state.showCheckedOutDialog,
    hideFeedback: (state) => state.hideFeedback,
    mediaDialogProps: (state) => state.mediaDialogProps,
    isDocumentVisible: (state) => state.isDocumentVisible,
    isAddingLink: (state) => state.isAddingLink,
    maintenanceMode: (state) => state.maintenanceMode,

    getField,
  },

  mutations: {
    setClientVersion(state, value) {
      state.clientVersion = value;
      sessionStorage['clientVersion'] = value;
    },

    setCSRFToken(state) {
      state.csrfToken = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content');
    },

    setMaintenanceMode(state, value) {},

    setIsLoading(state, isLoading) {
      if (!isLoading && state.loadCount > 0)
        state.loadCount = state.loadCount - 1;
      else if (isLoading) state.loadCount = state.loadCount + 1;

      if (state.loadCount === 0) state.isLoading = false;
      else state.isLoading = true;
    },

    setLayout(state, layout) {
      state.layout = layout;
    },

    setIsAddingLink(state, value) {
      state.isAddingLink = value;
    },

    setTimezones(state, value) {
      state.timezones = value;
    },

    setShowMediaDialog(state, value) {
      state.showMediaDialog = value;
    },

    setCampsiteAddData(state, value) {
      state.campsiteAddData = value;
    },

    setHideFeedback(state, value) {
      state.hideFeedback = value;
    },

    setMediaDialogProps(state, value) {
      state.mediaDialogProps = Object.assign(
        {},
        defaultMediaDialogProps,
        value,
      );
    },

    setDocumentVisibility(state, value) {
      state.isDocumentVisible = value;
    },

    updateField,
  },

  actions: {
    async init({ commit }) {
      const { data } = await localAxios.get('/api/app/init');
      commit('setUser', data.user || {});
      commit('updateField', {
        path: 'appSettings',
        value: data.app,
      });
    },

    // Clears the store and resets the state
    switchProfile({ commit }, id) {
      let expires = new Date();
      expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000); // 1 year
      Cookies.set('campsite_profile', id, { expires });
      commit('profiles/set', { name: 'currentId', value: id });

      // reset the store state
      commit('profiles/resetState');
      commit('analytics/resetState');
      commit('qrCode/resetState');
      commit('canvaMedia/resetState');
      commit('links/resetState');
      commit('linkSet/resetState');
      commit('media/resetState');

      commit('profiles/set', { name: 'currentId', value: id });
    },

    unsetProfile({ commit }) {
      Cookies.expire('campsite_profile');
      commit('profiles/set', { name: 'currentId', value: null });
    },

    getTimezones({ commit, getters }, { force } = {}) {
      if (!getters.timezones.length || force) {
        return localAxios.get(`/api/app/timezones`).then(({ data }) => {
          commit('setTimezones', data.timezones);
        });
      } else {
        return Promise.resolve(getters.timezones);
      }
    },

    openMediaDialog({ commit }, props) {
      commit('setMediaDialogProps', props);
      commit('setShowMediaDialog', true);
    },

    closeMediaDialog({ commit }) {
      commit('setShowMediaDialog', false);
    },

    tryUpdateMaintenanceMode({ commit, getters }, value) {
      if (
        !getters.currentUser ||
        (getters.currentUser && getters.currentUser.role !== 'admin')
      )
        commit('updateField', { path: 'maintenanceMode', value });
    },

    showAuthorizationDialog({ commit }, data) {
      commit('updateField', { path: 'authData', value: data });
      commit('updateField', { path: 'showAuthDialog', value: true });
    },
  },
});
