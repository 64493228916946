export const FEED_LINK = {
  _id: { $oid: '61cb6a9d7d357455147796f5' },
  label: 'This is your feed link',
  type: 'feed',
  enabled: true,
  feed_options: {
    layout: 'stacked',
    limit: 3,
    items: [
      {
        title: "We'll pull in your latest blog posts or Youtube videos",
        url: 'https://campsite.bio',
      },
      {
        title: 'Have it done automatically with a feed',
        url: 'https://campsite.bio',
      },
      {
        title: 'A great way to share your latest posts',
        url: 'https://campsite.bio',
      },
    ],
  },
};
