<template>
  <List class="payment-cards">
    <slot />
  </List>
</template>

<script>
import { List } from '@campsite-bio/component-lib';

export default {
  components: {
    List,
  },
};
</script>

<style lang="scss" scoped>
.payment-cards.payment-cards {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0;
}
</style>
