<template>
  <SettingsSection id="email-preferences">
    <SettingsSectionHeader :loading="isLoading">
      <SettingsSectionTitle>Email Preferences</SettingsSectionTitle>
      <VText>Update your Campsite.bio email preferences.</VText>
    </SettingsSectionHeader>

    <FormGroup>
      <Toggle
        v-model="isWeeklyInsights"
        :disabled="isFreePlan || isLoading || !isEmailVerified"
        >Weekly Insights</Toggle
      >
      <FormGroupHelp
        >A weekly email filled with analytics and insights info about your
        profiles.</FormGroupHelp
      >
      <FormGroupHelp v-if="isFreePlan"
        ><LockIcon /> Upgrade to Pro to unlock.</FormGroupHelp
      >
    </FormGroup>
    <FormGroup>
      <Toggle v-model="isNewsletter" :disabled="isLoading || !isEmailVerified"
        >Newsletter</Toggle
      >
      <FormGroupHelp
        >A monthly newsletter about recent social media news and what's
        happening on Campsite.bio.</FormGroupHelp
      >
    </FormGroup>
    <FormGroup :gutter-bottom="false">
      <Toggle v-model="isMarketing" :disabled="isLoading || !isEmailVerified"
        >Marketing</Toggle
      >
      <FormGroupHelp
        >Occasional emails about deals. No spam here.</FormGroupHelp
      >
    </FormGroup>
  </SettingsSection>
</template>

<script>
import {
  Button,
  VText,
  Toggle,
  FormGroup,
  FormGroupHelp,
  LockIcon,
} from '@campsite-bio/component-lib';
import { computed, ref, watch, nextTick } from 'vue';

import {
  SettingsSection,
  SettingsSectionTitle,
  SettingsSectionHeader,
} from '../settings-section';
import { useStore } from '../../compositions';
import toast from '../../utils/toast';
import debounce from 'lodash/debounce';
import { intercomUpdate } from '../../utils/intercom';

export default {
  components: {
    SettingsSection,
    SettingsSectionTitle,
    SettingsSectionHeader,
    Toggle,
    FormGroup,
    FormGroupHelp,
    VText,
    LockIcon,
  },

  setup() {
    const store = useStore();
    const isLoading = ref(true);
    const ignoreChanges = ref(true);
    const isWeeklyInsights = ref(true);
    const isNewsletter = ref(true);
    const isMarketing = ref(true);

    const isFreePlan = computed(() => {
      return store.getters.isFreePlan;
    });

    const isEmailVerified = computed(() => {
      return store.getters.currentUser.email_verified;
    });

    async function load() {
      isLoading.value = true;
      try {
        const { weekly_insights_email, newsletter_email, marketing_email } =
          await store.dispatch('getEmailPreferences');

        isWeeklyInsights.value = weekly_insights_email;
        isNewsletter.value = newsletter_email;
        isMarketing.value = marketing_email;

        await nextTick();

        ignoreChanges.value = false;
      } catch (e) {
        const { error_message } = e.response?.data || {};
        console.error(e);
        toast.error(
          error_message ||
            'There was an error trying to get your email preferences',
          { timeout: 3000 },
        );
      }
      isLoading.value = false;
    }

    async function save() {
      if (isLoading.value) return;

      try {
        await store.dispatch('updateEmailPreferences', {
          weekly_insights_email: isWeeklyInsights.value,
          newsletter_email: isNewsletter.value,
          marketing_email: isMarketing.value,
        });

        toast.success('Your email preferences have been updated');
      } catch (e) {
        const { error_message } = e.response?.data || {};
        console.error(e);
        toast.error(
          error_message ||
            'There was an error trying to update your email preferences',
          { timeout: 3000 },
        );
      }

      // Save intercom email preferences
      intercomUpdate({
        unsubscribed_from_emails: isNewsletter.value,
      });
    }

    const saveDebounced = debounce(save, 1000);

    watch([isWeeklyInsights, isNewsletter, isMarketing], () => {
      if (!ignoreChanges.value) saveDebounced();
    });

    load();

    return {
      isFreePlan,
      isWeeklyInsights,
      isNewsletter,
      isMarketing,
      isLoading,
      isEmailVerified,
    };
  },
};
</script>

<style lang="scss" scoped></style>
