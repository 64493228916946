import { ref } from 'vue';

/**
 * Disable a button for a set amount of time after an action
 */
export function useDisableTimeout(timeout = 3000) {
  const isDisabled = ref(false);

  function disableTimeout() {
    isDisabled.value = true;
    setTimeout(() => {
      isDisabled.value = false;
    }, timeout);
  }

  return {
    isDisabled,
    disableTimeout,
  };
}
