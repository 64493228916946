<template>
  <LinkExpander
    :id="`default-${linkId}`"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template slot="header">
      Image Grid Options
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <IconButton
            href="https://support.campsite.bio/en/articles/6846405-image-grid-link"
            target="_blank"
            aria-label="Image grid setup help"
            size="1.25rem"
            v-bind="attrs"
            v-on="on"
          >
            <QuestionCircleOIcon />
          </IconButton>
        </template>
        <span>Get some help setting up your image grid link</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="form">
      <ValidationProvider
        v-slot="{ errors, ariaMsg }"
        ref="limitProvider"
        :rules="{
          required: false,
          min_value: 3,
          max_value: 100,
        }"
        name="limit"
        :detect-input="false"
        slim
      >
        <FormGroup>
          <Label for="limit">Images to show</Label>
          <ChipGroup v-model="limitLocal" aria-label="Number of images to show">
            <Chip
              v-for="limit in LIMIT_OPTIONS"
              :key="limit"
              :value="limit"
              selectable
              >{{ limit }}</Chip
            >
            <Chip :value="null" selectable :disabled="isFreePlan"
              >No limit</Chip
            >
            <Chip value="custom" selectable :disabled="isFreePlan">Custom</Chip>
            <div style="max-width: 80px">
              <Input
                v-if="
                  !LIMIT_OPTIONS.includes(limitLocal) && limitLocal !== null
                "
                v-model.number="actualLimit"
                type="number"
                min="3"
                max="100"
                step="1"
                pattern="[0-9]*"
                style="height: 2rem"
              />
            </div>
          </ChipGroup>
          <FormGroupHelp v-if="errors.length === 0">
            We'll show up to this many images if they're available.
            <InlineLink v-if="isFreePlan" to="/account/change-plan"
              >Upgrade to Pro to unlock the no limit and custom
              option.</InlineLink
            >
          </FormGroupHelp>
          <FormGroupHelp v-else color="error" v-bind="ariaMsg">
            {{ errors[0] }}
          </FormGroupHelp>
        </FormGroup>
      </ValidationProvider>

      <FormGroup>
        <Toggle v-model="showMessage"> Show a message above the images </Toggle>
      </FormGroup>

      <template v-if="showMessage">
        <ValidationProvider
          v-slot="{ errors, ariaMsg, ariaInput }"
          :rules="{
            required: true,
            max: 50,
          }"
          name="message"
          slim
        >
          <FormGroup>
            <Label :for="`message${_uid}`" gutter-bottom>Message</Label>
            <Input
              :id="`message${_uid}`"
              v-model="messageLocal"
              placeholder="Tap any photo to visit the link"
              maxlength="50"
              required
              :has-error="errors.length > 0"
              v-bind="ariaInput"
            />
            <FormGroupHelp color="error" v-bind="ariaMsg">
              {{ errors[0] }}
            </FormGroupHelp>
          </FormGroup>
        </ValidationProvider>
      </template>
    </ValidationObserver>
  </LinkExpander>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  IconButton,
  QuestionCircleOIcon,
  FormGroup,
  Label,
  FormGroupHelp,
  Input,
  Toggle,
  InlineLink,
  ChipGroup,
  Chip,
} from '@campsite-bio/component-lib';
import uniqueId from 'lodash/uniqueId';
import debounce from 'lodash/debounce';
import { computed, ref, watch } from 'vue';

import LinkExpander from '../expand/link-expander';
import { useStore } from '../../../compositions';

const LIMIT_OPTIONS = [3, 6, 9, 12];

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    LinkExpander,
    IconButton,
    QuestionCircleOIcon,
    FormGroup,
    Label,
    FormGroupHelp,
    Input,
    Toggle,
    InlineLink,
    ChipGroup,
    Chip,
  },

  props: {
    isOpen: Boolean,
    linkId: {
      type: String,
      required: true,
    },
    imageGridOptions: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const form = ref(null);
    const limitProvider = ref(null);
    const id = ref(uniqueId('image-grid'));

    const limitLocal = ref(null);
    const actualLimit = ref(null);
    const showMessage = ref(false);
    const messageLocal = ref(null);

    const isFreePlan = computed(() => {
      return store.getters['profiles/isProfileOnFreePlan'];
    });

    watch(limitLocal, (val) => {
      if (typeof val === 'number' || val === null) actualLimit.value = val;
      else if (val === 'custom' && !LIMIT_OPTIONS.includes(val))
        actualLimit.value = 15;
    });

    watch(actualLimit, (val) => {
      if (limitProvider.value) limitProvider.value.syncValue(val);
    });

    watch(
      () => props.imageGridOptions,
      ({ limit, show_message, message }) => {
        limitLocal.value =
          !LIMIT_OPTIONS.includes(limit) && limit !== null ? 'custom' : limit;
        actualLimit.value = limit;
        showMessage.value = show_message;
        messageLocal.value = message;
      },
      { immediate: true },
    );

    async function save() {
      if (!form.value) return;

      const success = await form.value.validate();
      if (!success) return;

      emit('save', {
        limit: actualLimit.value,
        show_message: showMessage.value,
        message: messageLocal.value,
      });
    }

    watch(
      [actualLimit, limitLocal, showMessage, messageLocal],
      debounce(save, 1000),
    );

    return {
      id,
      form,
      limitLocal,
      actualLimit,
      showMessage,
      messageLocal,
      LIMIT_OPTIONS,
      isFreePlan,
      limitProvider,
    };
  },
};
</script>

<style lang="scss">
.limit-box-input {
  .box-input__title {
    font-size: 1rem;
  }
}
</style>
