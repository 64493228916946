import { computed, ref, unref } from 'vue';
import { DateTime } from 'luxon';

import { useSelectedDateGranularity } from './use-selected-date-granularity';

export function useChartStartEndDate(data, options = {}) {
  const { granularity, useGranularity = true } = options;
  let localGranularity = ref(granularity);
  if (useGranularity) localGranularity.value = useSelectedDateGranularity();
  const dataRef = computed(() => unref(data));

  const startDate = computed(() => {
    if (!dataRef.value) return '-';
    if (localGranularity.value === 'hour') return '12:00 AM';
    const date = DateTime.fromISO(dataRef.value.dates.start_date);
    if (localGranularity.value === 'month') return date.toFormat('MMM');
    return date.toFormat('MMM d');
  });

  const endDate = computed(() => {
    if (!dataRef.value) return '-';
    if (localGranularity.value === 'hour') return '11:59 PM';
    const date = DateTime.fromISO(dataRef.value.dates.end_date);
    if (localGranularity.value === 'month') return date.toFormat('MMM');

    const yesterday = DateTime.local().minus({ days: 1 });
    return date.hasSame(yesterday, 'day')
      ? 'Yesterday'
      : date.toFormat('MMM d');
  });

  return {
    startDate,
    endDate,
  };
}
