import { computed, onUnmounted, ref, watch } from 'vue';
import { useIdle } from '@vueuse/core';
import throttle from 'lodash/throttle';

import { localAxios } from '../../apis';
import { useStore } from '../use-store';
import { useRoute } from '../use-route';

export function useProfileCheckOut(options = { interval: 15000 }) {
  const { interval } = options;
  const timeout = ref(null);
  const isSubscribed = ref(false);
  const isCheckingOut = ref(false);
  const isCheckingIn = ref(false);
  const store = useStore();
  const route = useRoute();

  const currentUserId = computed(() => {
    return store.getters.currentUserId;
  });

  const profile = computed(() => {
    return store.getters['profiles/currentProfile'];
  });

  const currentCheckOut = computed(() => {
    return store.getters['profiles/checkOut'];
  });

  const currentCheckedOutUserId = computed(() => {
    if (!currentCheckOut.value) return null;

    return currentCheckOut.value && currentCheckOut.value.checked_out_by
      ? currentCheckOut.value.checked_out_by.$oid
      : null;
  });

  const isCurrentUserCheckedOut = computed(() => {
    return currentCheckedOutUserId.value === currentUserId.value;
  });

  const canCheckout = computed(() => {
    return (
      profile.value &&
      profile.value.has_access_users &&
      route.value.meta.canCheckout
    );
  });

  const showCheckedOutDialog = computed(() => {
    return store.getters.showCheckedOutDialog;
  });

  const { idle, lastActive } = useIdle();

  function checkStatus() {
    if (canCheckout.value) {
      // Check out the profile as the current user
      if (!currentCheckedOutUserId.value || isCurrentUserCheckedOut.value) {
        if (isSubscribed.value || isCheckingOut.value) return;
        checkOut();
        // Someone else has the profile checked out
      } else if (!isCurrentUserCheckedOut.value) {
        store.commit('updateField', {
          path: 'showCheckedOutDialog',
          value: true,
        });
      }
      // Check out if there was a change and they can no longer checkout
    } else if (isCurrentUserCheckedOut.value) {
      checkIn();
    }
  }

  async function checkOut() {
    if (isCheckingOut.value) return;

    isCheckingOut.value = true;
    try {
      await store.dispatch('profiles/checkOut');

      if (!isSubscribed.value && !timeout.value) {
        isSubscribed.value = true;
        timeout.value = setTimeout(subscribe, interval);
      }
    } catch (e) {
      console.error(e);
      if (e.response.status === 400) {
        store.commit('updateField', {
          path: 'showCheckedOutDialog',
          value: true,
        });
      }
    }
    isCheckingOut.value = false;
  }

  async function checkIn() {
    if (isCheckingIn.value) return;

    isCheckingIn.value = true;

    try {
      unsubscribe();
      await store.dispatch('profiles/checkIn');
    } catch (e) {
      console.error(e);
    }

    isCheckingIn.value = false;
  }

  async function subscribe() {
    isSubscribed.value = true;
    try {
      const { data } = await localAxios.get(
        `/api/profiles/${store.getters['profiles/currentProfileId']}/status`,
      );

      if (data.checked_out_by.$oid === currentCheckedOutUserId.value) {
        timeout.value = setTimeout(subscribe, interval);
      } else {
        store.commit('updateField', {
          path: 'showCheckedOutDialog',
          value: true,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  function unsubscribe() {
    isSubscribed.value = false;
    clearTimeout(timeout.value);
    timeout.value = null;
  }

  watch(
    [canCheckout, currentCheckedOutUserId, route, showCheckedOutDialog],
    throttle(
      () => {
        checkStatus();
      },
      250,
      { trailing: true },
    ),
  );

  watch(idle, (newValue) => {
    if (!canCheckout.value) return;

    if (newValue) checkIn();
    else checkStatus();
  });

  onUnmounted(() => unsubscribe());

  return {
    canCheckout,
  };
}
