<template>
  <ListItem
    component="button"
    type="button"
    :disabled="disabled"
    @click="$emit('click', 'feed')"
  >
    <ListItemAction>
      <LockIcon v-if="disabled" size="1.25rem" />
      <RssIcon v-else size="1.25rem" />
    </ListItemAction>
    <ListItemContent>
      <ListItemTitle>Feed</ListItemTitle>
      <ListItemSubtitle wrap
        >Pull in your latest blog posts or Youtube videos
        automatically.</ListItemSubtitle
      >
    </ListItemContent>
  </ListItem>
</template>

<script>
import {
  ListItem,
  ListItemAction,
  ListItemContent,
  ListItemSubtitle,
  ListItemTitle,
  LockIcon,
  RssIcon,
} from '@campsite-bio/component-lib';

export default {
  components: {
    ListItem,
    ListItemAction,
    ListItemContent,
    ListItemSubtitle,
    ListItemTitle,
    LockIcon,
    RssIcon,
  },

  props: {
    disabled: Boolean,
  },
};
</script>

<style lang="scss" scoped></style>
