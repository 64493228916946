<template>
  <RatioBox :ratio="1">
    <QrCode
      v-bind="$props"
      ref="qrCode"
      class="qr-code"
      :data="qrUrl"
      :image="imageSrc"
    />
  </RatioBox>
</template>

<script>
import {
  QrCode,
  RatioBox,
  QrCodeProps,
  convertImageUrlToEncodedUrl,
} from '@campsite-bio/component-lib';
import { computed, ref, toRefs } from 'vue';
import { useStore } from '../../compositions';
import config from '../../config';

export default {
  components: {
    QrCode,
    RatioBox,
  },
  props: {
    ...QrCodeProps,

    image: {
      type: String,
      default: null,
    },
    data: {
      type: String,
      default: null,
    },

    imageType: {
      type: String,
      default: 'logo',
    },

    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { imageType, data, id } = toRefs(props);
    const store = useStore();
    const profileImage = computed(() =>
      convertImageUrlToEncodedUrl(
        store.getters['profiles/currentProfile'].profile_image,
        200,
      ),
    );
    const qrCode = ref(null);
    const qrUrl = computed(() => `${data.value}?qrcode=${id.value}`);

    const imageSrc = computed(() => {
      switch (imageType.value) {
        case 'logo':
          return `${config.ASSET_URL}campsite-bw-mark.png`;
        case 'profile_image':
          return `${profileImage.value}?qr=1`;
        default:
          return null;
      }
    });

    function download() {
      qrCode.value.download();
    }

    return { qrCode, qrUrl, download, imageSrc };
  },
};
</script>

<style lang="scss" scoped>
.qr-code {
  ::v-deep canvas {
    height: 100%;
    width: 100%;
  }
}
</style>
