<template>
  <List class="v-app-auth-items">
    <slot />
  </List>
</template>

<script>
import { List } from '@campsite-bio/component-lib';

export default {
  components: {
    List,
  },
};
</script>

<style lang="scss" scoped>
.v-app-auth-items.v-app-auth-items {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0;
  margin-bottom: 1rem;
}
</style>
