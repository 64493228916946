const template = `
<div class="pace">
    <div class="pace-progress"></div>
</div>
`;

let cancelAnimation = null,
  animation = null;

const now = function () {
  var _ref;
  return (_ref =
    typeof performance !== 'undefined' && performance !== null
      ? typeof performance.now === 'function'
        ? performance.now()
        : void 0
      : void 0) != null
    ? _ref
    : +new Date();
};

const runAnimation = function (fn) {
  var last, tick;
  last = now();
  tick = function () {
    var diff;
    diff = now() - last;
    if (diff >= 33) {
      last = now();
      return fn(diff, function () {
        return window.requestAnimationFrame(tick);
      });
    } else {
      return setTimeout(tick, 33 - diff);
    }
  };
  return tick();
};

class Pace {
  constructor() {
    this.bar = new Bar();
    this.scaler = new Scaler();
  }

  start() {
    this.scaler.progress = 0;
    this.go();
  }

  go() {
    cancelAnimation = false;
    animation = runAnimation((frameTime, enqueueNextFrame) => {
      let count = 0,
        start = now();
      count += this.scaler.tick(frameTime);
      this.bar.update(count);
      if (this.bar.done() || cancelAnimation) {
        this.bar.update(100);
        return setTimeout(() => {
          this.bar.finish();
        }, Math.max(250 - (now() - start), 0));
      } else {
        return enqueueNextFrame();
      }
    });
  }

  stop() {
    cancelAnimation = true;
    if (animation) window.cancelAnimationFrame(animation);
  }
}

class Bar {
  constructor() {
    document.body.innerHTML += template;
    this.progress = 0;
    this.el = document.querySelector('.pace-progress');
  }

  set(prog) {
    this.progress = prog;
  }

  update(prog) {
    this.progress = prog;
    this.render();
  }

  finish() {
    this.el.classList.remove('pace-active');
    this.el.classList.add('pace-inactive');
    document.body.classList.remove('pace-running');
    document.body.classList.add('pace-done');
  }

  render() {
    this.el.classList.add('pace-active');
    this.el.classList.remove('pace-inactive');
    document.body.classList.add('pace-running');
    document.body.classList.remove('pace-done');
    let transform = 'translate3d(' + this.progress + '%, 0, 0)',
      transforms = ['webkitTransform', 'msTransform', 'transform'],
      key;
    for (let i = 0; i < transforms.length; i++) {
      key = transforms[i];
      this.el.style[key] = transform;
    }
  }

  done() {
    return this.progress >= 100;
  }
}

class Scaler {
  constructor() {
    this.last = this.sinceLastUpdate = 0;
    this.rate = 0.03;
    this.catchup = 0;
    this.progress = this.lastProgress = 0;
  }

  tick(frameTime, val) {
    let scaling;
    if (val == null) {
      val = 'progress';
    }
    if (val >= 100) {
      this.done = true;
    }
    if (val === this.last) {
      this.sinceLastUpdate += frameTime;
    } else {
      if (this.sinceLastUpdate) {
        this.rate = (val - this.last) / this.sinceLastUpdate;
      }
      this.catchup = (val - this.progress) / 100;
      this.sinceLastUpdate = 0;
      this.last = val;
    }
    if (val > this.progress) {
      this.progress += this.catchup * frameTime;
    }
    scaling = 1 - Math.pow(this.progress / 100, 1.25);
    this.progress += scaling * this.rate * frameTime;
    this.progress = Math.min(this.lastProgress + 20, this.progress);
    this.progress = Math.max(0, this.progress);
    this.progress = Math.min(100, this.progress);
    this.lastProgress = this.progress;
    return this.progress;
  }
}

export default new Pace();
