<template>
  <div class="label-with-text">
    <strong>{{ label }}:</strong>&nbsp;
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
      default: '',
    },
    text: {
      type: [String, Number],
      required: true,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.label-with-text {
  margin: 0.4rem 0;
}
</style>
