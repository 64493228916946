<template>
  <LinkExpander
    :id="`default-${linkId}`"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template slot="header">
      Shop Options
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <IconButton
            href="https://support.campsite.bio/en/articles/8129166-shop-link"
            target="_blank"
            aria-label="Shop setup help"
            size="1.25rem"
            v-bind="attrs"
            v-on="on"
          >
            <QuestionCircleOIcon />
          </IconButton>
        </template>
        <span>Get some help setting up your shop link</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="form">
      <ValidationProvider
        v-slot="{ errors, ariaMsg, ariaInput }"
        :rules="{
          required: true,
        }"
        name="limit"
        slim
      >
        <FormGroup>
          <Label :for="`shop_provider${id}`">Shop Provider</Label>
          <AppAuthorizationsSelect
            :id="`shop_provider${id}`"
            v-model="appAuthLocal"
            :has-error="errors.length > 0"
            store-key="profiles/shopAuthorizations"
            v-bind="ariaInput"
            required
            @change:auth="handleAuthChange"
          />
          <FormGroupHelp color="error" v-bind="ariaMsg">
            {{ errors[0] }}
          </FormGroupHelp>
          <FormGroupHelp>
            <InlineLink :to="`/profile/${profileId}/settings#commerce`"
              >Add a new provider</InlineLink
            >
          </FormGroupHelp>
        </FormGroup>
      </ValidationProvider>

      <FormGroup>
        <FormGroupHeader gutter-bottom>
          <Label component="span">Products</Label>
          <FlexSpacer />
          <InlineLink
            v-if="collectionIdLocal || productsIdsLocal"
            component="button"
            type="button"
            style="font-size: 0.875rem"
            @click="handleProductActionClick"
          >
            {{ productActionText }}
          </InlineLink>
        </FormGroupHeader>
        <VText
          v-if="productDetailsText && hasSelectedProductsOrCollection"
          style="margin-bottom: 0.75rem; font-size: 0.875rem"
        >
          <Skeleton v-if="isLoadingProducts" width="100px" />
          <template v-else>{{ productDetailsText }}</template>
        </VText>

        <component
          :is="collectionIdLocal ? 'div' : 'VProductGroup'"
          v-model="selectedProducts"
          aria-label="Products"
          multiple
        >
          <VProducts>
            <template v-if="isLoadingProducts && !products.length">
              <VProduct v-for="i in loadingItemsCount" :key="i">
                <Skeleton slot="image" height="100%" width="100%" />
                <Skeleton slot="title" width="100px" />
                <Skeleton slot="price" width="50px" />
              </VProduct>
            </template>
            <VProduct
              v-for="product in products"
              :key="product.id"
              :value="product.id"
              v-bind="product"
              :currency-code="currencyCode"
            />
            <SelectDialog
              v-if="showAddBtn"
              v-model="isProductSelectOpen"
              :selected-products="productsIdsLocal"
              :selected-collection="collectionIdLocal"
              :link-id="linkId"
              @update:products="handleProductsChange"
              @update:collection="collectionIdLocal = $event"
            >
              <template #activator="{ on, attrs }">
                <AddBtn v-bind="attrs" :disabled="!appAuthLocal" v-on="on" />
              </template>
            </SelectDialog>
          </VProducts>
        </component>
      </FormGroup>

      <FormGroup>
        <Label :for="`description${id}`">Description (optional)</Label>
        <Input
          :id="`description${id}`"
          v-model="descriptionLocal"
          component="textarea"
          rows="2"
          placeholder="Check out my shop!"
          maxlength="240"
        />
      </FormGroup>

      <FormGroup>
        <Toggle v-model="showStoreButtonLocal">Show store link</Toggle>
        <FormGroupHelp>{{
          collectionIdLocal
            ? 'Allow customers to go browse your selected collection'
            : 'Allow customers to go browse your store'
        }}</FormGroupHelp>
      </FormGroup>

      <VText component="h3" variant="h5" style="margin-top: 1.75rem"
        >Product detail options</VText
      >

      <List>
        <ListItem>
          <ListItemContent>
            <ListItemTitle>
              <Label :for="`add_to_cart${id}`"
                >Show the add to cart button</Label
              >
            </ListItemTitle>
            <ListItemSubtitle
              >Only allow a customer to go directly to the
              checkout</ListItemSubtitle
            >
          </ListItemContent>
          <ListItemAction>
            <Toggle :id="`add_to_cart${id}`" v-model="showAddToCartLocal" />
          </ListItemAction>
        </ListItem>
        <Divider thickness="1" color="gray200" />
        <ListItem>
          <ListItemContent>
            <ListItemTitle>
              <Label :for="`show_vendor${id}`">Show product vendor</Label>
            </ListItemTitle>
          </ListItemContent>
          <ListItemAction>
            <Toggle :id="`show_vendor${id}`" v-model="showVendorLocal" />
          </ListItemAction>
        </ListItem>
        <Divider thickness="1" color="gray200" />
        <ListItem>
          <ListItemContent>
            <ListItemTitle>
              <Label :for="`show_description${id}`"
                >Show product description</Label
              >
            </ListItemTitle>
          </ListItemContent>
          <ListItemAction>
            <Toggle
              :id="`show_description${id}`"
              v-model="showProductDescriptionLocal"
            />
          </ListItemAction>
        </ListItem>
      </List>
    </ValidationObserver>
  </LinkExpander>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  IconButton,
  QuestionCircleOIcon,
  FormGroup,
  Label,
  FormGroupHelp,
  InlineLink,
  Input,
  VProduct,
  VProductGroup,
  VProducts,
  VText,
  FormGroupHeader,
  FlexSpacer,
  Skeleton,
  List,
  ListItem,
  ListItemContent,
  ListItemSubtitle,
  ListItemTitle,
  ListItemAction,
  Divider,
  Toggle,
} from '@campsite-bio/component-lib';
import uniqueId from 'lodash/uniqueId';
import { computed, nextTick, onBeforeUnmount, ref, toRefs, watch } from 'vue';
import debounce from 'lodash/debounce';

import LinkExpander from '@/components/links/expand/link-expander';
import { SelectDialog } from './components';
import { useCurrentProfile, useStore } from '@/compositions';
import { AppAuthorizationsSelect } from '@/components/form';
import { localAxios } from '@/apis';
import AddBtn from './components/add-btn';
import toast from '@/utils/toast';

const ALLOWED_PROVIDERS = ['shopify', 'spring'];

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    LinkExpander,
    IconButton,
    QuestionCircleOIcon,
    FormGroup,
    FormGroupHelp,
    SelectDialog,
    InlineLink,
    AppAuthorizationsSelect,
    Label,
    Input,
    VText,
    VProduct,
    VProductGroup,
    VProducts,
    AddBtn,
    FormGroupHeader,
    FlexSpacer,
    Skeleton,
    List,
    ListItem,
    ListItemContent,
    ListItemSubtitle,
    ListItemTitle,
    ListItemAction,
    Divider,
    Toggle,
  },

  props: {
    isOpen: Boolean,

    linkId: {
      type: String,
      required: true,
    },

    shopOptions: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const { shopOptions } = toRefs(props);
    const form = ref(null);
    const id = ref(uniqueId('shop'));
    const { id: profileId } = useCurrentProfile();
    const isProductSelectOpen = ref(false);
    const products = ref([]);
    const currencyCode = ref(null);
    const collection = ref(null);
    const isLoadingProducts = ref(false);
    const selectedProducts = ref([]);

    const integrations = computed(() => {
      return store.getters['profiles/authorizations'].filter((authorization) =>
        ALLOWED_PROVIDERS.includes(authorization.provider),
      );
    });

    const ignoreChanges = ref(false);
    const dirty = ref(false);
    const descriptionLocal = ref(null);
    const appAuthLocal = ref(null);
    const sourceLocal = ref(null);
    const collectionIdLocal = ref(null);
    const productsIdsLocal = ref(null);
    const showAddToCartLocal = ref(null);
    const showVendorLocal = ref(null);
    const showProductDescriptionLocal = ref(null);
    const showStoreButtonLocal = ref(null);

    const showAddBtn = computed(() => {
      return (
        !collectionIdLocal.value ||
        (collectionIdLocal.value === null && productsIdsLocal.value?.length < 6)
      );
    });

    const hasSelectedProductsOrCollection = computed(() => {
      return collectionIdLocal.value || productsIdsLocal.value?.length > 0;
    });

    const productDetailsText = computed(() => {
      const text = [];
      const productsLength = products.value?.length;

      if (collection.value) text.push(`${collection.value.title} collection`);
      if (!isLoadingProducts.value)
        text.push(
          `Showing ${productsLength} product${productsLength === 1 ? '' : 's'}`,
        );

      return text.join(' · ');
    });

    const productActionText = computed(() => {
      if (props.shopOptions.product_ids?.length > 0) {
        if (selectedProducts.value.length > 0)
          return `Remove ${selectedProducts.value.length} Product${
            selectedProducts.value.length > 1 ? 's' : ''
          }`;
        else return 'Remove Products';
      } else {
        return 'Remove Collection';
      }
    });

    const loadingItemsCount = computed(() => {
      if (props.shopOptions.product_ids?.length > 0) {
        return props.shopOptions.product_ids?.length;
      } else {
        return 3;
      }
    });

    watch(dirty, (newVal) => emit('change:dirty', newVal));

    watch(
      shopOptions,
      async ({
        app_authorization_id,
        description,
        source,
        collection_id,
        product_ids,
        show_product_add_to_cart,
        show_product_vendor,
        show_product_description,
        show_store_button,
      }) => {
        ignoreChanges.value = true;
        appAuthLocal.value = app_authorization_id?.$oid;
        descriptionLocal.value = description;
        sourceLocal.value = source;
        collectionIdLocal.value = collection_id;
        productsIdsLocal.value = product_ids;
        showAddToCartLocal.value = show_product_add_to_cart;
        showVendorLocal.value = show_product_vendor;
        showProductDescriptionLocal.value = show_product_description;
        showStoreButtonLocal.value = show_store_button;
        dirty.value = false;
        await nextTick();
        ignoreChanges.value = false;
        if (props.isOpen) loadProductsDebounced();
      },
      { immediate: true },
    );

    async function save() {
      if (!form.value) return;

      const success = await form.value.validate();
      if (!success) return;

      emit('save', {
        app_authorization_id: appAuthLocal.value,
        description: descriptionLocal.value,
        source: sourceLocal.value,
        collection_id: collectionIdLocal.value,
        product_ids: productsIdsLocal.value,
        show_product_add_to_cart: showAddToCartLocal.value,
        show_product_vendor: showVendorLocal.value,
        show_product_description: showProductDescriptionLocal.value,
        show_store_button: showStoreButtonLocal.value,
      });

      await nextTick();
      dirty.value = false;
    }

    async function loadProducts() {
      if (!props.shopOptions.collection_id && !props.shopOptions.product_ids)
        return;

      isLoadingProducts.value = true;

      try {
        const { data } = await localAxios.get(
          `/api/links/${props.linkId}/shop`,
        );

        products.value = data.products.map((product) => ({
          id: product.product_id,
          title: product.title,
          price: product.min_max_price,
          image: product.featured_image?.src,
        }));
        collection.value = data.collection;
        currencyCode.value = data.shop_config.currency;
      } catch (e) {
        console.error(e);
        toast.error('Failed to load products');
      }

      isLoadingProducts.value = false;
    }

    const loadProductsDebounced = debounce(loadProducts, 100);

    const saveDebounced = debounce(save, 1000);

    watch(
      [
        appAuthLocal,
        descriptionLocal,
        collectionIdLocal,
        productsIdsLocal,
        sourceLocal,
        showAddToCartLocal,
        showVendorLocal,
        showProductDescriptionLocal,
        showStoreButtonLocal,
      ],
      () => {
        if (!ignoreChanges.value) {
          dirty.value = true;
          saveDebounced();
        }
      },
    );

    // Reset products or collection id when either changes
    watch(collectionIdLocal, (newValue) => {
      if (newValue) {
        productsIdsLocal.value = null;
        selectedProducts.value = [];
        isLoadingProducts.value = true;
      }
    });

    watch(productsIdsLocal, (newValue) => {
      if (newValue) {
        collectionIdLocal.value = null;
        isLoadingProducts.value = true;
      }
    });

    watch(
      () => props.isOpen,
      (newValue) => {
        if (newValue) {
          loadProductsDebounced();
        }
      },
    );

    function handleAuthChange(auth) {
      if (appAuthLocal.value === auth?._id.$oid) return;

      collectionIdLocal.value = null;
      productsIdsLocal.value = null;
      sourceLocal.value = auth?.provider;
    }

    function handleProductsChange(products) {
      const newProductIds = [...(productsIdsLocal.value || []), ...products];
      productsIdsLocal.value = [...new Set(newProductIds)];
    }

    function handleProductActionClick() {
      if (props.shopOptions.product_ids?.length > 0) {
        if (selectedProducts.value.length > 0) {
          productsIdsLocal.value = productsIdsLocal.value.filter(
            (id) => !selectedProducts.value.includes(id),
          );
          products.value = products.value.filter(
            (product) => !selectedProducts.value.includes(product.id),
          );
          selectedProducts.value = [];
        } else {
          productsIdsLocal.value = null;
          products.value = [];
        }
      } else {
        collectionIdLocal.value = null;
        products.value = [];
        collection.value = null;
      }
    }

    onBeforeUnmount(() => {
      dirty.value = false;
    });

    return {
      profileId,
      id,
      form,
      appAuthLocal,
      descriptionLocal,
      collectionIdLocal,
      productsIdsLocal,
      showAddToCartLocal,
      showVendorLocal,
      showProductDescriptionLocal,
      showStoreButtonLocal,
      integrations,
      isProductSelectOpen,
      handleAuthChange,
      products,
      handleProductsChange,
      showAddBtn,
      productDetailsText,
      hasSelectedProductsOrCollection,
      selectedProducts,
      currencyCode,
      productActionText,
      handleProductActionClick,
      isLoadingProducts,
      loadingItemsCount,
    };
  },
};
</script>

<style lang="scss" scoped></style>
