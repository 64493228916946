<template>
  <div class="channel-options print-hide">
    <slot name="before" />

    <ChannelAuthorizationDialog>
      <template #activator="{ on, attrs }">
        <Chip label selectable v-bind="attrs" :disabled="disabled" v-on="on">
          <PersonAddIcon />&nbsp;Authorize New Channel
        </Chip>
      </template>
    </ChannelAuthorizationDialog>

    <slot name="after" />

    <div class="hide">
      <AppAuthItem
        v-if="selectedAppAuthorization"
        :item="selectedAppAuthorization"
      />
    </div>
  </div>
</template>

<script>
import { Chip, PersonAddIcon } from '@campsite-bio/component-lib';
import { nextTick, onMounted, onUnmounted, ref } from 'vue';

import { ChannelAuthorizationDialog } from '../../../dialogs';
import { PROVIDERS } from '../../../../config';
import bus from '../../../../bus';
import { localAxios } from '../../../../apis';
import { AppAuthItem } from '../../../app-authorizations';
import { timeout } from '../../../../utils';

export default {
  components: {
    Chip,
    PersonAddIcon,
    ChannelAuthorizationDialog,
    AppAuthItem,
  },

  props: {
    disabled: Boolean,
  },

  setup() {
    const isAddOpen = ref(false);

    const selectedAppAuthorization = ref(null);

    // Show the new app auth so they can update it
    async function setAppAuth({ authId, profileId }) {
      await timeout(250);
      const { data } = await localAxios.get(
        `/api/profiles/authorizations/${profileId}/${authId}`,
      );
      selectedAppAuthorization.value = data;
      await nextTick();
      bus.$emit(`app-authorization:${authId}:open`);
    }

    onMounted(() => {
      bus.$on('app-authorization:authorize', setAppAuth);
    });

    onUnmounted(() => {
      bus.$off('app-authorization:authorize', setAppAuth);
    });

    return {
      isAddOpen,
      PROVIDERS,
      selectedAppAuthorization,
    };
  },
};
</script>

<style lang="scss" scoped>
.channel-options {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: -1rem;
}
</style>
