import { render, staticRenderFns } from "./create-account.vue?vue&type=template&id=9a99a35e&scoped=true&"
import script from "./create-account.vue?vue&type=script&lang=js&"
export * from "./create-account.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a99a35e",
  null
  
)

export default component.exports