<template>
  <SimpleTable v-if="hasResults || isLoading" fixed-header height="400px">
    <thead>
      <tr>
        <th>Item</th>
        <th class="text-right">Clicks</th>
      </tr>
    </thead>
    <tbody>
      <template v-if="isLoading">
        <tr v-for="i in 3" :key="i">
          <td><Skeleton height="60px" width="60px" /></td>
          <td class="text-right"><Skeleton width="30px" /></td>
        </tr>
      </template>
      <tr
        v-for="{ _id, count, ctr, ctrColor, imgUrl, title, imgAlt } in stats"
        :key="_id.item_id"
      >
        <td>
          <Skeleton v-if="isLoadingItems" height="60px" width="60px" />
          <span v-else-if="imgUrl || title" class="item-title">
            <img v-if="imgUrl" :src="imgUrl" :alt="imgAlt" height="60" />
            <span v-if="title">{{ title | truncate(40) }}</span>
          </span>
          <span v-else> (unknown) </span>
        </td>
        <td class="text-right">
          <Badge v-if="ctr !== '-'" :color="ctrColor" :content="ctr" />
          {{ count | numberFormatter(1) }}
        </td>
      </tr>
    </tbody>
  </SimpleTable>
  <VText v-else>No data available</VText>
</template>

<script>
import { computed, ref, watch } from 'vue';
import {
  SimpleTable,
  Skeleton,
  Badge,
  VText,
  convertImageUrlToEncodedUrl,
} from '@campsite-bio/component-lib';
import { percentageChange } from '../../../utils';
import { localAxios } from '../../../apis';

export default {
  components: {
    SimpleTable,
    Skeleton,
    Badge,
    VText,
  },

  props: {
    data: {
      type: Array,
      default: null,
    },

    linkId: {
      type: String,
      required: true,
    },

    items: {
      type: Array,
      default: null,
    },

    type: {
      type: String,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const isLoadingItems = ref(false);
    const localItems = ref(props.items || []);

    const current = computed(() => {
      return props.data?.filter((s) => s._id.range === 'current');
    });

    const past = computed(() => {
      return props.data?.filter((s) => s._id.range === 'past');
    });

    const itemIds = computed(() => {
      return current.value?.map((s) => s._id.item_id);
    });

    const hasResults = computed(() => {
      return current.value?.length > 0;
    });

    // Match different link types
    function getItemImageUrl(item) {
      let imgSrc;
      if (item?.media_item) imgSrc = item.media_item.url;
      if (item?.images) imgSrc = item.images.max;
      if (item?.image)
        imgSrc = typeof item.image === 'string' ? item.image : item.image.url;

      return convertImageUrlToEncodedUrl(imgSrc, 150);
    }

    const stats = computed(() => {
      return current.value?.map((s) => {
        const matched = past.value.find((p) => p._id.item_id === s._id.item_id);
        const change = percentageChange(matched?.count, s.count);
        const changeFormatted =
          typeof change === 'number' ? `${change.toFixed(2)}%` : change;
        const itemMatch = localItems.value.find((i) => {
          return i.id ? i.id === s._id.item_id : i._id.$oid === s._id.item_id;
        });
        return {
          ...s,
          ctr: changeFormatted,
          ctrColor: change > 0 ? 'success' : 'info',
          title: itemMatch?.title,
          imgUrl: getItemImageUrl(itemMatch),
          imgAlt: itemMatch?.title || itemMatch?.image_alt,
        };
      });
    });

    async function loadItems() {
      isLoadingItems.value = true;

      try {
        const { data } = await localAxios.get(
          `/api/links/items/${props.linkId}`,
          {
            params: {
              ids: itemIds.value,
            },
          },
        );

        localItems.value = data;
      } catch (e) {
        console.error(e);
      }

      isLoadingItems.value = false;
    }

    if (props.type === 'image-grid') {
      watch(
        itemIds,
        () => {
          if (itemIds.value?.length) loadItems();
        },
        { immediate: true },
      );
    }

    return {
      stats,
      isLoadingItems,
      hasResults,
    };
  },
};
</script>

<style lang="scss" scoped>
img {
  height: 60px;
  width: 60px;
  object-fit: contain;
}

.item-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}
</style>
