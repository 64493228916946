<template>
  <Select v-model="localValue" v-bind="$attrs">
    <option :value="null">- Select a state</option>
    <template v-if="stateType === 'string'">
      <option v-for="state in states" :key="state" :value="state">
        {{ state }}
      </option>
    </template>
    <template v-else>
      <template v-for="state in states">
        <option v-for="(value, key) in state" :key="key" :value="key">
          {{ value }}
        </option>
      </template>
    </template>
  </Select>
</template>

<script>
import { computed, onMounted, ref, toRefs, watch } from 'vue';

import { Select } from '@campsite-bio/component-lib';
import { useModel } from '../../compositions';
import toast from '../../utils/toast';

export default {
  components: {
    Select,
  },

  props: {
    value: {
      type: String,
      default: null,
    },

    countryCode: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const allStates = ref(null);

    const localValue = useModel(value, (val) => emit('input', val));

    const states = computed(() => {
      if (props.countryCode && allStates.value) {
        return allStates.value[props.countryCode.toUpperCase()];
      }

      return [];
    });

    const stateType = computed(() => {
      if (states.value?.length) {
        return typeof states.value[0];
      }

      return null;
    });

    async function load() {
      try {
        const data = await import('../../data/states.json');
        allStates.value = data.default;
      } catch (e) {
        console.error(e);
        toast.error('There was an error trying to load the states.');
      }
    }

    watch([localValue, states], () => {
      if (localValue.value && states.value.length) {
        if (stateType.value === 'string') {
          emit('input:state', {
            name: localValue.value,
            code: localValue.value,
          });
        } else {
          const state = states.value.find((s) => s[localValue.value]);
          emit('input:state', {
            name: state[localValue.value],
            code: localValue.value,
          });
        }
      } else if (!localValue.value) {
        emit('input:state', null);
      }
    });

    onMounted(() => {
      load();
    });

    return {
      localValue,
      states,
      stateType,
    };
  },
};
</script>

<style scoped></style>
