<template>
  <v-menu
    v-model="display"
    :close-on-content-click="false"
    persistent
    :max-width="width"
    style="width: 100%"
    bottom
    nudge-bottom="30"
  >
    <template #activator="{ on, attrs }">
      <button
        class="swatch-btn"
        type="button"
        :title="colorValue"
        :aria-label="colorValue"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="on"
      >
        <div class="swatch-btn__preview">
          <div
            class="swatch-btn__preview-inner"
            :style="{ 'background-color': colorValue }"
          ></div>
        </div>
        <div class="swatch-btn__dd">▼</div>
      </button>
    </template>

    <Card class="color-picker">
      <div v-if="palette" class="color-picker__side">
        <div class="color-palette">
          <ColorPaletteItem
            v-for="(color, index) in paletteWithLocal"
            :key="index"
            :color="color"
            :is-active="isPaletteActive(color)"
            @select="selectPalette"
          />
        </div>
      </div>
      <Card class="color-picker__picker">
        <chrome-picker :value="colors" @input="updateFromPicker" />
        <Divider color="gray200" thickness="1" />
        <CardActions>
          <Button color="error" variant="flat" @click="display = false"
            >Cancel</Button
          >
          <Button color="success" variant="flat" @click="choose">Choose</Button>
        </CardActions>
      </Card>
    </Card>
  </v-menu>
</template>

<script>
import {
  Card,
  CardActions,
  Button,
  Divider,
} from '@campsite-bio/component-lib';
import Chrome from 'vue-color/src/components/Chrome';
import ColorPaletteItem from './color-picker-palette-item';
import tinyColor from 'tinycolor2';

const defaultColor = '#000';

export default {
  name: 'ColorPicker',

  components: {
    'chrome-picker': Chrome,
    ColorPaletteItem,
    Card,
    CardActions,
    Button,
    Divider,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    inputId: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      colors: {
        hex: defaultColor,
      },
      colorValue: '',
      selectedColor: undefined,
      display: false,
      width: 385,
      palette: undefined,
      localPaletteRef: undefined,
    };
  },

  computed: {
    paletteOptions() {
      return this.options ? this.options.palette : undefined;
    },

    localStorageKey() {
      return this.options ? this.options.localStorageKey : undefined;
    },

    localPalette: {
      get() {
        let data = localStorage[this.localStorageKey];
        if (data) {
          this.localPaletteRef = data.split(';');
          return this.localPaletteRef;
        } else {
          this.localPaletteRef = [];
        }
        return [];
      },
      set(newValue) {
        if (this.localStorageKey) {
          localStorage[this.localStorageKey] = newValue.join(';');
          this.localPaletteRef = newValue;
        }
      },
    },

    paletteWithLocal() {
      const depend = this.localPaletteRef;
      if (this.palette && this.palette.length)
        return this.palette.concat(this.localPalette);
      else if (this.localPalette && this.localPalette.length)
        return this.localPalette;
      return [];
    },
  },

  watch: {
    paletteOptions() {
      this.palette = this.paletteOptions;
    },

    colorValue(val) {
      if (val) {
        this.updateColors(val);
        this.$emit('input', val);
      }
    },

    value(newValue) {
      this.setColor(newValue || defaultColor);
    },
  },

  created() {
    this.palette = this.paletteOptions;
    this.setColor(this.value || defaultColor);
  },

  methods: {
    isPaletteActive(color) {
      let activeColor = tinyColor(color);
      if (this.selectedColor)
        return tinyColor.equals(
          activeColor,
          tinyColor(this.selectedColor.rgba),
        );
      return tinyColor.equals(activeColor, tinyColor(this.colorValue));
    },

    addToLocalStorage(color) {
      var match = false,
        colorTiny = tinyColor(color);
      for (let i = 0; i < this.localPaletteRef.length; i++) {
        const palColor = tinyColor(this.localPaletteRef[i]);
        if (tinyColor.equals(colorTiny, palColor)) match = true;
      }
      for (let x = 0; x < this.palette.length; x++) {
        const palColor = tinyColor(this.palette[x]);
        if (tinyColor.equals(colorTiny, palColor)) match = true;
      }
      if (!match) {
        this.localPaletteRef = this.localPaletteRef || [];
        this.localPaletteRef.push(color);
        this.localPalette = [].concat(this.localPaletteRef);
      }
    },

    selectPalette(color) {
      this.updateColors(color);
      const tColor = tinyColor(color);
      this.selectedColor = {
        rgba: tColor.toRgb(),
        hex: tColor.toHexString(),
      };
    },

    setColor(color) {
      this.updateColors(color);
      this.colorValue = color;
    },

    updateColors(color) {
      if (color.slice(0, 1) === '#') {
        this.colors = {
          hex: color,
        };
      } else if (color.slice(0, 3) === 'rgb') {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
          hex =
            '#' +
            (
              (1 << 24) +
              (parseInt(rgba[0]) << 16) +
              (parseInt(rgba[1]) << 8) +
              parseInt(rgba[2])
            )
              .toString(16)
              .slice(1);
        this.colors = {
          hex: hex,
          a: rgba[3],
        };
      }
    },

    updateFromPicker(color) {
      this.colors = color;
      this.selectedColor = color;
    },

    choose() {
      let color = this.selectedColor || this.value;
      if (typeof color === 'string') {
        this.colorValue = color;
      } else if (color.rgba.a === 1) {
        this.colorValue = color.hex;
      } else {
        this.colorValue =
          'rgba(' +
          color.rgba.r +
          ', ' +
          color.rgba.g +
          ', ' +
          color.rgba.b +
          ', ' +
          color.rgba.a +
          ')';
      }
      this.display = false;
      if (this.localStorageKey) this.addToLocalStorage(this.colorValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.swatch-btn {
  align-items: center;
  display: flex;
  margin: 0;
  overflow: hidden;
  cursor: pointer;
  padding: 0.25rem;
  border: solid 1px var(--g-color-gray-300);
  background: #eee;
  color: #333;
  user-select: none;
  max-width: 400px;
  width: 100%;

  &:hover {
    border-color: var(--g-color-primary);
    color: #111;
  }

  &:disabled {
    cursor: default;
    border-color: silver;
    color: silver;
  }
}

.swatch-btn__dd {
  padding: 2px 0;
  height: 16px;
  line-height: 16px;
  float: left;
  font-size: 10px;
}

.swatch-btn__preview {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAIUlEQVQ4jWN49uzZf3yYEGAYNWBYGEBIASELRg0YFgYAABpE1q4luwPVAAAAAElFTkSuQmCC');
  border: solid 1px #222;
  position: relative;
  height: 20px;
  float: left;
  margin-right: 5px;
  width: calc(100% - 0.925rem);
  z-index: 0;
}

.swatch-btn__preview-inner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

///

.color-picker.color-picker {
  background-color: #ececec;
  display: flex;

  .vc-chrome {
    box-shadow: none;
  }
}

.color-picker__side {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
}

.color-picker__picker {
  flex-shrink: 0;
}

///

.color-palette {
  display: flex;
  flex-wrap: wrap;
  margin-left: -2px;
  margin-right: -2px;
}

.v-card__actions .v-button {
  margin-bottom: 0;
  margin-top: 0;
}
</style>
