<template>
  <FeatureSection
    title="Unlock our best with the Pro+ subscription"
    bg-color="gray100"
    single
  >
    <VText slot="text"
      >With Pro+ or our advanced analytics add-on, you get best in class
      analytics features and reports.</VText
    >
    <Button slot="cta" to="/account/change-plan">Upgrade Now</Button>

    <FeatureCard
      span="2"
      title="Reporting tools"
      bg-color="secondary"
      color="secondary"
    >
      <LineChartIcon slot="icon" />
      <VText
        >We're constantly gathering data on what is happening on your profiles.
        We dissect and present your data in seven different reports to help you
        gain insights. It's easy track your link in bio performance and make
        improvements.
      </VText>
      <img
        slot="img"
        src="~images/features/leaderboard-report.png"
        loading="lazy"
        alt="Leaderboard report"
        height="967"
        width="1463"
      />
    </FeatureCard>

    <FeatureCard title="Overview report">
      <VText>
        Get a high level view of profile performance across your account. See
        each of your profiles side by side and compare their performance.
      </VText>
    </FeatureCard>
    <FeatureCard title="Channel report">
      <VText
        >Look at performance data from channels, like Instagram, pre and post
        profile visit to get the full story of your data.
      </VText>
    </FeatureCard>
    <FeatureCard title="Events report">
      <VText
        >See what events are happing on your profile. We break it all down for
        you.</VText
      >
    </FeatureCard>
    <FeatureCard title="Goals report">
      <VText>Set a goal, track your progress, go on a hot streak. </VText>
    </FeatureCard>
    <FeatureCard title="Leaderboard report">
      <VText
        >Did you just break a record for views, clicks or reach? We'll break it
        down for you so you can see how you measure up to your all-time stats.
      </VText>
    </FeatureCard>
    <FeatureCard title="Insights report">
      <VText
        >We'll do the hard work of analyzing your data and giving you an easy to
        digest insight report on each of your profiles.
      </VText>
    </FeatureCard>
    <FeatureCard title="Tracking report">
      <VText
        >See the behavior of your visitors by campaign, medium, or source.
      </VText>
    </FeatureCard>
    <FeatureCard title="Filtering &amp; Exports">
      <VText
        >Filter down our analytics by country, device, referrer, or campaign.
        You can even download your analytics data to dive deeper into it.
      </VText>
    </FeatureCard>
  </FeatureSection>
</template>

<script>
import { VText, Button, LineChartIcon } from '@campsite-bio/component-lib';

import { FeatureSection, FeatureCard } from '../../features';

import 'images/features/leaderboard-report.png';

export default {
  components: {
    VText,
    Button,
    LineChartIcon,
    FeatureSection,
    FeatureCard,
  },
};
</script>

<style lang="scss" scoped></style>
