import Vue from 'vue';
import Vuetify, {
  VApp,
  VSnackbar,
  VDialog,
  VMenu,
  VDatePicker,
  VTimePicker,
  VTooltip,
} from 'vuetify/lib';

import { mdiChevronLeft, mdiChevronRight, mdiClose } from '@mdi/js';

Vue.use(Vuetify, {
  components: {
    VApp,
    VSnackbar,
    VDialog,
    VMenu,
    VDatePicker,
    VTimePicker,
    VTooltip,
  },
});

export default new Vuetify({
  theme: { disable: true },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      mdiChevronLeft,
      mdiChevronRight,
      mdiClose,
    },
  },
});
