import { unref } from 'vue';

import { useAuthorizationDialog } from '../use-authorization-dialog';

export function useFacebookAuthorization(
  profileId,
  authDialogOptions,
  openParams = {},
) {
  const { open, isAuthorizing } = useAuthorizationDialog(authDialogOptions);

  function authorize() {
    open({
      provider: 'facebook_business',
      providerTitle: 'Facebook',
      params: {
        profile_id: unref(profileId),
        ...openParams,
      },
    });
  }

  return {
    authorize,
    isAuthorizing,
  };
}
