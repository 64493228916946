import { useBillingPortal } from '../subscription';
import { useCurrentUser } from './use-current-user';

export function useUserBillingPortal(options) {
  const { id } = useCurrentUser();

  return useBillingPortal(
    `/api/users/${id.value}/create-portal-session`,
    options,
  );
}
