<template>
  <MultiStepDialog
    v-model="isOpen"
    max-width="400px"
    content-class="commerce-dialog"
    :steps="STEPS"
  >
    <template v-for="(index, name) in $scopedSlots" #[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
  </MultiStepDialog>
</template>

<script>
import { ref } from 'vue';

import { MultiStepDialog } from '../../multi-step-dialog';
import {
  ProviderList,
  PaypalFees,
  PaypalAuth,
  SquareFees,
  SquareAuth,
  SquareCreateAccount,
  ShopifyAuth,
  ShopifyDetails,
} from './steps';

const STEPS = [
  {
    path: '',
    component: ProviderList,
    title: 'Add Commerce Integration',
  },
  {
    path: 'paypal-fees',
    component: PaypalFees,
    title: 'Connect PayPal',
  },
  {
    path: 'paypal-auth',
    component: PaypalAuth,
    title: 'Connect PayPal',
  },
  {
    path: 'square-fees',
    component: SquareFees,
    title: 'Connect Square',
  },
  {
    path: 'square-auth',
    component: SquareAuth,
    title: 'Connect Square',
  },
  {
    path: 'square-create-account',
    component: SquareCreateAccount,
    title: 'Create a Square Account',
  },
  {
    path: 'shopify-details',
    component: ShopifyDetails,
    title: 'Connect Shopify',
  },
  {
    path: 'shopify-auth',
    component: ShopifyAuth,
    title: 'Connect Shopify',
  },
];

export default {
  components: {
    MultiStepDialog,
  },

  props: {
    profileId: {
      type: String,
      required: true,
    },
  },

  setup() {
    const isOpen = ref(false);

    return { isOpen, STEPS };
  },
};
</script>

<style lang="scss">
.commerce-dialog {
  display: flex;
  min-height: 375px;

  .v-card.v-card {
    display: flex;
    flex-direction: column;
  }

  .v-card__text {
    flex-grow: 1;
  }
}
</style>
