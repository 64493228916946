<template>
  <v-drawer v-model="show" right :title="title" lazy>
    <v-drawer-item v-if="showCustom" name="Custom" icon="PlusIcon" lazy>
      <template slot-scope="{ show }">
        <v-media-custom
          :show="show"
          :options="customMediaOptions"
          @select="select"
        />
      </template>
    </v-drawer-item>
    <v-drawer-item
      v-if="showInstagram"
      name="Instagram"
      icon="InstagramIcon"
      lazy
    >
      <template slot-scope="{ show }">
        <v-media-instagram
          v-if="hasInstagramAuthed"
          :show="show"
          @select="select"
        />
        <v-authorize-instagram v-else>
          <template v-if="instagramAuth"
            >We're having issues connecting to your Instagram account.
            Re-authorize to fix this issue.</template
          >
        </v-authorize-instagram>
      </template>
    </v-drawer-item>
    <v-drawer-item v-if="showIcons" name="Icons" icon="SmileOIcon" lazy>
      <template slot-scope="{ show }">
        <MediaIcons :show="show" @select="select" />
      </template>
    </v-drawer-item>
    <v-drawer-item v-if="showPatterns" name="Patterns" icon="PatternIcon" lazy>
      <template slot-scope="{ show }">
        <MediaPatterns :show="show" @select="select" />
      </template>
    </v-drawer-item>
    <v-drawer-item v-if="showUnsplash" name="Photos" icon="ImageIcon" lazy>
      <template slot-scope="{ show }">
        <v-media-unsplash :show="show" @select="select" />
      </template>
    </v-drawer-item>
    <v-drawer-item v-if="showCanva" name="Design" icon="CanvaIcon" lazy>
      <template slot-scope="{ show }">
        <v-media-canva
          :show="show"
          :options="canvaMediaOptions"
          @select="select"
        />
      </template>
    </v-drawer-item>

    <v-drawer-item v-if="showCustomFree" name="Custom" icon="PlusIcon" lazy>
      <Card>
        <CardTitle
          ><VText component="h2" variant="h4">Custom Media</VText></CardTitle
        >
        <Divider color="lightgray" />
        <CardText>
          <VText
            >Upload images from your device to use on your links! Upgrade to Pro
            to unlock this feature.</VText
          >
        </CardText>
        <CardActions>
          <Button
            color="success"
            variant="flat"
            to="/account/change-plan"
            @click.native="show = false"
            >Find out more</Button
          >
        </CardActions>
      </Card>
    </v-drawer-item>
    <v-drawer-item v-if="showUnsplashFree" name="Photos" icon="ImageIcon" lazy>
      <Card>
        <CardTitle
          ><VText component="h2" variant="h4">Unsplash</VText></CardTitle
        >
        <Divider color="lightgray" />
        <CardText>
          <VText
            >Select from thousands of stock photos on Unsplash! Upgrade to Pro
            to unlock this feature.</VText
          >
        </CardText>
        <CardActions>
          <Button
            color="success"
            variant="flat"
            to="/account/change-plan"
            @click.native="show = false"
            >Find out more</Button
          >
        </CardActions>
      </Card>
    </v-drawer-item>
    <v-drawer-item v-if="showCanvaFree" name="Design" icon="CanvaIcon" lazy>
      <Card>
        <CardTitle><VText component="h2" variant="h4">Canva</VText></CardTitle>
        <Divider color="lightgray" />
        <CardText>
          <VText
            >Select and manage images with the powerful tool Canva! Upgrade to
            Pro to unlock this feature.</VText
          >
        </CardText>
        <CardActions>
          <Button
            color="success"
            variant="flat"
            to="/account/change-plan"
            @click.native="show = false"
            >Find out more</Button
          >
        </CardActions>
      </Card>
    </v-drawer-item>
  </v-drawer>
</template>

<script>
import {
  Card,
  CardTitle,
  CardText,
  CardActions,
  Button,
  VText,
  Divider,
} from '@campsite-bio/component-lib';
import VDrawer from '../drawer/drawer.vue';
import VDrawerItem from '../drawer/drawer-item.vue';
import VAuthorizeInstagram from '../authorize-instagram.vue';
import VMediaCustom from './media-custom.vue';
import VMediaInstagram from './media-instagram.vue';
import VMediaUnsplash from './media-unsplash.vue';
import VMediaCanva from './media-canva.vue';
import MediaPatterns from './media-patterns';
import MediaIcons from './media-icons';
import { AUTHORIZATION_STATUS } from '../../config';
import { computed } from 'vue';
import { useCurrentProfile, useRoute } from '@/compositions';

export default {
  name: 'VMediaDialog',

  components: {
    VAuthorizeInstagram,
    VMediaCustom,
    VMediaInstagram,
    VMediaUnsplash,
    VMediaCanva,
    MediaPatterns,
    MediaIcons,
    VDrawer,
    VDrawerItem,
    Card,
    CardTitle,
    CardText,
    CardActions,
    Button,
    Divider,
    VText,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    media: {
      type: [Array, String],
      default: 'all',
    },

    mediaOptions: {
      type: Object,
      default: null,
    },

    onSelect: {
      type: Function,
      default: () => {},
    },

    onCancel: {
      type: Function,
      default: () => {},
    },

    title: {
      type: String,
      default: 'Select an image',
    },
  },

  setup(props) {
    const { isProfileOnFreePlan } = useCurrentProfile();
    const route = useRoute();

    const isOnOrg = computed(() => {
      return route.value.fullPath.includes('organization/');
    });

    const isFreePlan = computed(() => {
      return isOnOrg.value ? false : isProfileOnFreePlan.value;
    });

    return {
      isFreePlan,
      isOnOrg,
    };
  },

  data() {
    return {
      show: false,
      active: null,
    };
  },

  computed: {
    user() {
      return this.$store.getters.currentUser;
    },

    instagramAuth() {
      return this.$store.getters['profiles/instagramAuthorization'];
    },

    hasInstagramAuthed() {
      return (
        this.instagramAuth &&
        this.instagramAuth.status === AUTHORIZATION_STATUS.CONNECTED
      );
    },

    showAll() {
      return this.media === 'all';
    },

    showCustom() {
      if (
        (this.showAll || this.media.indexOf('custom') >= 0) &&
        this.mediaOptions &&
        this.mediaOptions.custom &&
        this.mediaOptions.custom.alwaysShow
      )
        return true;
      return (
        !this.isFreePlan && (this.showAll || this.media.indexOf('custom') >= 0)
      );
    },

    showCustomBackgrounds() {
      if (this.media.indexOf('backgrounds') >= 0) return true;
      return false;
    },

    showCustomFree() {
      return (
        this.isFreePlan &&
        (this.showAll || this.media.indexOf('custom') >= 0) &&
        !this.showCustom
      );
    },

    showInstagram() {
      return (
        !this.isOnOrg && (this.showAll || this.media.indexOf('instagram') >= 0)
      );
    },

    showIcons() {
      return this.showAll || this.media.indexOf('icons') >= 0;
    },

    showPatterns() {
      return !this.isFreePlan && this.media.indexOf('patterns') >= 0;
    },

    showUnsplash() {
      return (
        !this.isFreePlan &&
        (this.showAll || this.media.indexOf('unsplash') >= 0)
      );
    },

    showUnsplashFree() {
      return (
        this.isFreePlan && (this.showAll || this.media.indexOf('unsplash') >= 0)
      );
    },

    showCanva() {
      if (
        (this.showAll || this.media.indexOf('canva') >= 0) &&
        this.mediaOptions &&
        this.mediaOptions.canva &&
        this.mediaOptions.canva.alwaysShow
      )
        return true;
      return (
        !this.isFreePlan && (this.showAll || this.media.indexOf('canva') >= 0)
      );
    },

    showCanvaFree() {
      return (
        this.isFreePlan &&
        (this.showAll || this.media.indexOf('canva') >= 0) &&
        !this.showCanva
      );
    },

    customMediaOptions() {
      return this.mediaOptions?.custom || {};
    },

    canvaMediaOptions() {
      return this.mediaOptions?.canva || {};
    },
  },

  watch: {
    show(newValue) {
      this.$emit('input', newValue);
      if (!newValue) this.close();
    },

    value(newValue) {
      this.show = newValue;
    },
  },

  created() {
    this.show = this.value;
  },

  methods: {
    select(media) {
      this.show = false;
      this.onSelect(media);
    },

    close() {
      this.onCancel();
    },
  },
};
</script>

<style scoped></style>
