<template>
  <button class="v-add-btn" v-bind="$attrs" v-on="$listeners">
    <PlusIcon size="3rem" />
    Add
  </button>
</template>

<script>
import { PlusIcon } from '@campsite-bio/component-lib';

export default {
  components: {
    PlusIcon,
  },

  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.v-add-btn {
  background: var(--g-color-gray-100);
  border: 1px solid var(--g-color-gray-300);
  border-radius: var(--g-border-radius-standard);
  border-radius: min(
    var(--g-border-radius-standard),
    var(--g-border-radius-min)
  );
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  transition: all var(--g-transition-speed);

  &:hover,
  &:focus {
    background: var(--g-color-gray-200);
  }

  &:disabled {
    background: var(--g-color-gray-300);
    border-color: var(--g-color-gray-400);
    cursor: not-allowed;
  }
}
</style>
