export const DEFAULT_FILTER_OPTIONS = [
  'Referrer',
  'Device',
  'UTM Campaign',
  'Country',
];

export const FILTER_OPTION_MAP = {
  Referrer: 'Referral',
  Device: 'Device',
  Query: 'query_key_filters',
  Campaign: 'UTM Campaign',
  Medium: 'utm_medium',
  Source: 'utm_source',
  Country: 'Country',
  State: 'State',
  City: 'City',
};

export const DEFAULT_FILTER_OPERATORS = [
  {
    label: 'Is',
    value: '=',
    inputType: 'select',
  },
  {
    label: 'Is not',
    value: '!=',
    inputType: 'select',
  },
  // {
  //   label: 'Contains',
  //   value: '()',
  //   inputType: 'input',
  // },
  // {
  //   label: 'Does not contain',
  //   value: '!()',
  //   inputType: 'input',
  // },
];
