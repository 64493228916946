<template>
  <LinkWrapper v-bind="$props" @add-link="$emit('add-link', $event)">
    <LinkCard v-if="hasSetup">
      <LinkField v-if="hasApiWarning">
        <Alert>
          <InfoIcon slot="icon" />
          There was an authorization error with the previously connected
          provider. Please select another to enable this link.
          <Button
            slot="actions"
            variant="flat"
            color="white"
            style="width: 80px"
            @click="toggleExpand('default')"
          >
            Settings
          </Button>
        </Alert>
      </LinkField>

      <LinkField>
        <EmojiInput
          ref="labelRef"
          v-model="label"
          :input-id="'label' + _uid"
          border-color="gray200"
          autocomplete="off"
          maxlength="80"
          :placeholder="placeholder"
          aria-label="Link label"
          :show-emoji="isLargeScreen"
          :disabled="isReadonly"
          @focus="focusLink"
        >
          <IconButton
            slot="prepend"
            v-clipboard
            :data-clipboard-text="label"
            tabindex="-1"
            aria-label="Copy title"
          >
            <PencilIcon title="Copy title" />
          </IconButton>
        </EmojiInput>
      </LinkField>

      <v-media-selector
        slot="media"
        v-model="mediaImage"
        :has-future-image="hasFutureImage"
        class="media-selector--post"
        :disabled="isReadonly"
        :loading="isSavingImage"
        @select="selectMedia"
        @remove="imageRemove"
      />

      <template slot="btm">
        <LinkBtn
          v-if="!isStatsHidden"
          :active="isExpanded('stats')"
          :aria-label="`Link analytics - ${link.number_of_clicks} clicks`"
          :aria-controls="`stats-${id}`"
          @click="toggleExpand('stats')"
        >
          <LineChartIcon slot="icon" />
          <template v-if="link.number_of_clicks" slot="stat">
            {{ link.number_of_clicks | numberFormatter(1) }}
          </template>
        </LinkBtn>
        <LinkBtn
          v-if="!isDeleted && !isScheduleHidden"
          aria-label="Schedule link"
          :active="isExpanded('schedule')"
          :highlight="isExpanded('schedule')"
          :aria-controls="`schedule-${id}`"
          @click="toggleExpand('schedule')"
        >
          <CalendarIcon slot="icon" />
        </LinkBtn>
        <v-tooltip v-if="!isDeleted && !isPinnedHidden" top>
          <template #activator="{ on }">
            <LinkBtn
              v-if="!isDeleted"
              aria-label="Pin link"
              :pinned="pinned"
              :disabled="isPinnedDisabled"
              v-on="on"
              @click="pinned = !pinned"
            >
              <PinIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>{{ pinnedText }}</span>
        </v-tooltip>
        <LinkBtn
          v-if="!isDeleted && !isFreePlan && !isHighlightHidden"
          aria-label="Highlight link"
          :active="isExpanded('highlight')"
          :highlight="!!highlight"
          :aria-controls="`highlight-${id}`"
          @click="toggleExpand('highlight')"
        >
          <template slot="icon">
            <StarIcon v-if="highlight" />
            <StarOIcon v-else />
          </template>
        </LinkBtn>
        <v-tooltip v-if="!isFreePlan && !isDeleted && !isEventTagHidden" top>
          <template #activator="{ on }">
            <LinkBtn
              aria-label="Event tag"
              :active="isExpanded('event-tag')"
              :aria-controls="`event-label-${id}`"
              v-on="on"
              @click="toggleExpand('event-tag')"
            >
              <TagIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>Event tag</span>
        </v-tooltip>
        <v-tooltip v-if="isDeleted" top>
          <template #activator="{ on }">
            <LinkBtn
              class="link__btn--restore js-link-restore"
              aria-label="Restore link"
              :disabled="isRestoring"
              v-on="on"
              @click="handleRestore"
            >
              <RepeatIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>Restore link</span>
        </v-tooltip>
      </template>

      <template slot="actions">
        <HighlightExpand
          v-model="highlight"
          :is-open="isExpanded('highlight')"
          :link-enabled="link.enabled"
          @close="toggleExpand('highlight')"
        />
        <StatsExpand
          :is-open="isExpanded('stats')"
          @close="toggleExpand('stats')"
        />
        <ScheduleExpand
          :is-open="isExpanded('schedule')"
          :link="link"
          @close="toggleExpand('schedule')"
        />
        <EventLabelExpand
          v-if="!isFreePlan"
          :is-open="isExpanded('event-tag')"
          :link="link"
          @close="toggleExpand('event-tag')"
        />
        <component
          :is="expandComponent"
          :is-open="isExpanded('default')"
          :link-id="id"
          :commerce-options="options"
          @close="toggleExpand('default')"
          @save="saveOptions"
          @change:dirty="isOptionsDirty = $event"
        />
      </template>
    </LinkCard>
    <CommerceSetup
      v-else
      :link-id="id"
      :commerce-options="options"
      :is-deleting="isDeleting"
      :type="link.type"
      @remove="handleDelete"
    />
  </LinkWrapper>
</template>

<script>
import {
  Button,
  IconButton,
  EmojiInput,
  PencilIcon,
  LineChartIcon,
  CalendarIcon,
  PinIcon,
  StarIcon,
  StarOIcon,
  RepeatIcon,
  ArchiveIcon,
  TrashIcon,
  TagIcon,
  DonationIcon,
  RequestIcon,
  Alert,
  InfoIcon,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';

import CommerceSetup from './commerce-setup';
import {
  HighlightExpand,
  StatsExpand,
  ScheduleExpand,
  EventLabelExpand,
} from '../expand';
import { RequestExpand, SupportExpand } from './expands';
import { LinkBtn, LinkWrapper, LinkCard, LinkField } from '../components';
import { LinkProps } from '../link-props';
import { useLink } from '../compositions';

export default {
  components: {
    Button,
    IconButton,
    HighlightExpand,
    StatsExpand,
    ScheduleExpand,
    EventLabelExpand,
    EmojiInput,
    PencilIcon,
    TagIcon,
    LineChartIcon,
    CalendarIcon,
    PinIcon,
    StarIcon,
    StarOIcon,
    RepeatIcon,
    ArchiveIcon,
    TrashIcon,
    DonationIcon,
    RequestIcon,
    LinkBtn,
    Alert,
    InfoIcon,
    RequestExpand,
    SupportExpand,
    CommerceSetup,
    LinkWrapper,
    LinkCard,
    LinkField,
  },

  props: LinkProps,

  setup(props) {
    const {
      link,
      isSavingOptions,
      options,
      isOptionsDirty,
      saveOptions,
      hasSetup,
      hasApiWarning,
      ...rest
    } = useLink(props, {
      hasMedia: true,
      name: 'commerce',
      optionsName: 'commerce',
      expandOnSetup: 'default',
    });

    const expandComponent = computed(() => {
      switch (props.link.type) {
        case 'request':
          return 'RequestExpand';
        case 'support':
          return 'SupportExpand';
      }

      return null;
    });

    const placeholder = computed(() => {
      return props.link.type === 'support'
        ? 'Support me with a donation'
        : 'Request a service from me';
    });

    return {
      ...rest,
      options,
      placeholder,
      expandComponent,
      saveOptions,
      isSavingOptions,
      hasSetup,
      hasApiWarning,
      isOptionsDirty,
    };
  },
};
</script>

<style lang="scss" scoped></style>
