<template>
  <MultiStepDialog
    v-model="isOpen"
    max-width="450px"
    content-class="select-products-dialog"
    :steps="steps"
    scrollable
  >
    <template v-for="(index, name) in $scopedSlots" #[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
  </MultiStepDialog>
</template>

<script>
import { computed, ref } from 'vue';

import { MultiStepDialog } from '@/components/multi-step-dialog';
import { SelectType, SelectCollection, SelectProducts } from './steps';

export default {
  components: {
    MultiStepDialog,
  },

  props: {
    selectedProducts: {
      type: Array,
      default: () => [],
    },

    selectedCollection: {
      type: String,
      default: null,
    },

    linkId: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const isOpen = ref(false);

    const steps = computed(() => {
      return [
        {
          path: '',
          component: SelectType,
          title: 'Add from your Shopify',
        },
        {
          path: 'select-collection',
          component: SelectCollection,
          title: 'Add from your Shopify',
          props: {
            selectedCollection: props.selectedCollection,
            linkId: props.linkId,
          },
          on: {
            'update:collection': (value) => {
              emit('update:collection', value);
            },
          },
        },
        {
          path: 'select-products',
          component: SelectProducts,
          title: 'Add from your Shopify',
          props: {
            selectedProducts: props.selectedProducts,
            linkId: props.linkId,
          },
          on: {
            'update:products': (value) => {
              emit('update:products', value);
            },
          },
        },
      ];
    });

    return { isOpen, steps };
  },
};
</script>

<style lang="scss">
.select-products-dialog {
  display: flex;
  min-height: 400px;

  .v-card.v-card {
    display: flex;
    flex-direction: column;
  }

  .v-card__text {
    flex-grow: 1;
  }

  .scroll {
    overflow-y: auto;
    max-height: 400px;
  }
}
</style>
