<template>
  <div
    v-show="isActive"
    v-if="!lazyRender"
    class="drawer__item"
    :class="classes"
  >
    <slot :show="isActive"></slot>
  </div>
</template>

<script>
export default {
  name: 'DrawerItem',

  props: {
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    lazy: Boolean,
    scroll: Boolean,
  },

  data() {
    return {
      isDrawerItem: true,
      isActive: false,
      lazyRender: false,
    };
  },

  computed: {
    tabClasses() {
      return {
        active: this.isActive,
      };
    },

    classes() {
      return {
        'drawer__item--scroll': this.scroll,
      };
    },
  },

  watch: {
    isActive(newValue, oldValue) {
      if (newValue) {
        this.lazyRender = false;
      }
    },
  },

  created() {
    this.lazyRender = this.lazy;
  },
};
</script>

<style scoped></style>
