<template>
  <Alert v-if="!dismissed">
    <InfoIcon slot="icon" />
    You have a cover image enabled on this profile.<br />
    To make changes to it, head to the
    <router-link
      :to="`/profile/${id}/settings/appearance`"
      style="color: inherit"
      >appearance page</router-link
    >.

    <Button slot="actions" variant="flat" color="white" @click="dismiss"
      >Dismiss</Button
    >
  </Alert>
</template>

<script>
import { Alert, InfoIcon, Button } from '@campsite-bio/component-lib';
import { useCurrentProfile, useDismissable } from '@/compositions';

export default {
  components: {
    Alert,
    InfoIcon,
    Button,
  },

  setup() {
    const { id } = useCurrentProfile();

    const { dismissed, dismiss } = useDismissable(
      `cover-image-alert-${id.value}`,
      {
        storage: 'session',
      },
    );

    return { id, dismissed, dismiss };
  },
};
</script>

<style lang="scss" scoped></style>
