import { computed, onMounted, ref } from 'vue';

import { localAxios } from '@/apis';
import { CAN_MANAGE_ANALYTICS } from '@/config';
import { useStore } from '../use-store';

export function useProfilesWithAnalyticsLoader({
  organizationId = null,
  loadOnInit = true,
  onError,
} = {}) {
  const store = useStore();
  const profiles = computed({
    get: () => store.getters['analytics/getField']('profilesWithAppAuths'),
    set: (value) =>
      store.commit('analytics/updateField', {
        path: 'profilesWithAppAuths',
        value,
      }),
  });
  const isLoading = ref(false);

  async function loadProfiles() {
    isLoading.value = true;
    const profileParams = {
      enabled: true,
      include: ['app_authorizations', 'wrapped_data'],
      sort: 'username',
    };

    if (organizationId) {
      profileParams.organization_id = organizationId;
    } else {
      profileParams.type = 'owner_access';
    }
    try {
      const { data } = await localAxios.get(`/api/profiles/all`, {
        params: profileParams,
      });
      const ownerProfiles = data.profiles.filter(
        (p) => p.access.entitlements === 'owner',
      );
      const collabProfiles = data.profiles.filter(
        (p) => p.access.entitlements !== 'owner',
      );

      // Combine owner profiles with collaborator profiles that have the proper permissions
      profiles.value = [
        ...ownerProfiles,
        ...collabProfiles.filter(
          (p) =>
            p.access.entitlements.includes(CAN_MANAGE_ANALYTICS) &&
            p.addons.includes('analytics'),
        ),
      ];
    } catch (e) {
      console.error(e);
      if (onError) onError(e);
    }
    isLoading.value = false;
  }

  if (loadOnInit) onMounted(loadProfiles);

  return {
    profiles,
    isLoading,
    load: loadProfiles,
  };
}
