<template>
  <ValidationObserver ref="form">
    <CardText>
      <VText gutter-bottom style="font-size: 0.9rem"
        >Authorize your store with Campsite.bio.</VText
      >

      <ol style="font-size: 0.9rem">
        <li>Open the Campsite.bio Shopify App.</li>
        <li>Select <b>Install</b>.</li>
        <li>Select <b>Connect your Campsite.bio profile</b> inside the app.</li>
        <li>Authorize your profile.</li>
      </ol>

      <!-- <ValidationProvider
        v-slot="{ errors, ariaMsg, ariaInput }"
        :rules="{
          required: true,
          regex: /^([a-z0-9][a-z0-9\-]*[a-z0-9])$/i,
        }"
        slim
        name="store name"
      >
        <FormGroup>
          <Label for="store">Store name</Label>
          <Input
            id="store"
            v-model="storeName"
            v-bind="ariaInput"
            placeholder="my-store"
            suffix=".myshopify.com"
            autocomplete="off"
          />
          <FormGroupHelp v-if="!errors[0]"
            >Enter your .myshopify URL.
            <InlineLink
              href="https://support.campsite.bio/en/articles/8125995-how-do-i-find-my-shopify-store-name"
              target="_blank"
              >Where do I find this?</InlineLink
            ></FormGroupHelp
          >
          <FormGroupHelp color="error" v-bind="ariaMsg">{{
            errors[0]
          }}</FormGroupHelp>
        </FormGroup>
      </ValidationProvider> -->
    </CardText>
    <CardActions align="center" direction="column">
      <Button href="https://apps.shopify.com/campsite.bio" target="_blank">
        Install Campsite.bio Shopify App&nbsp;
        <ExternalLinkIcon />
      </Button>
      <InlineLink
        component="button"
        type="button"
        style="margin-top: 0.5rem"
        @click="handleNoAccount"
        >Sign up for Shopify</InlineLink
      >
    </CardActions>
  </ValidationObserver>
</template>

<script>
import {
  CardText,
  VText,
  CardActions,
  Button,
  InlineLink,
  FormGroup,
  FormGroupHelp,
  Input,
  Label,
  ExternalLinkIcon,
} from '@campsite-bio/component-lib';
import { computed, inject, nextTick, ref } from 'vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import { useShopifyAuthorization, useStore } from '@/compositions';
import bus from '@/bus';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    CardText,
    CardActions,
    VText,
    Button,
    InlineLink,
    FormGroup,
    FormGroupHelp,
    Input,
    Label,
    ExternalLinkIcon,
  },

  setup() {
    const store = useStore();
    const { handleStepChange, isOpen } = inject('multi-step-dialog');
    const form = ref(null);
    const storeName = ref('');

    function handleNoAccount() {
      handleStepChange('shopify-create-account');
    }

    const { authorize } = useShopifyAuthorization(
      computed(() => store.getters['profiles/currentProfileId']),
      {
        onCancel() {},
        async onSuccess(data) {
          isOpen.value = false;
          await store.dispatch('profiles/getAppAuthorizations');
          await nextTick();
          bus.$emit(`app-authorization:${data.authId}:open`);
        },
      },
    );

    async function beforeAuthorize() {
      const success = await form.value.validate();
      if (!success) return;

      authorize({
        shop: `${storeName.value}.myshopify.com`,
      });
    }

    return { form, storeName, beforeAuthorize, handleNoAccount };
  },
};
</script>

<style lang="scss" scoped></style>
