<template>
  <AccordionSection
    title="Links"
    subtitle="Here are some links we found on your website"
    :value="isOpen"
    :variant="variant"
    @input="$emit('update:open', $event)"
  >
    <template v-if="feed">
      <FormGroup style="margin-bottom: 2rem">
        <Label component="span" gutter-bottom>
          <RssIcon style="margin-right: 0.5em" /> Feed Detected
          <Badge style="margin-left: 0.5em" content="Pro" />
        </Label>
        <VText gutter-bottom>
          We've found a feed on your website. Our feed link pulls in your latest
          posts automatically.<br />
          <i>{{
            isFreePlan
              ? 'Upgrade to Pro to unlock this feature.'
              : 'An example of a feed link will be shown in the preview.'
          }}</i>
        </VText>
        <Toggle v-model="activateFeed" :disabled="isFreePlan"
          >Activate Feed Link</Toggle
        >
      </FormGroup>
    </template>

    <Label component="span" block gutter-bottom>Select your links</Label>
    <List>
      <ListItemGroup
        v-model="selectedLinks"
        multiple
        aria-label="Select your links"
      >
        <LinkItem
          v-for="(link, i) in linksToShow"
          :key="i"
          :value="i"
          :link="link"
          @input="updateLink($event)"
        />
      </ListItemGroup>
    </List>

    <div>
      <Button
        v-if="links.length > 10"
        variant="outline"
        size="small"
        :aria-expanded="showAllLinks.toString()"
        style="margin-top: 1rem"
        @click="showAllLinks = !showAllLinks"
      >
        {{ showAllLinks ? 'Hide' : 'Show' }} {{ links.length - 10 }} Link{{
          links.length - 10 !== 1 ? 's' : ''
        }}
      </Button>
    </div>

    <Button style="margin-top: 2rem" @click="$emit('next')">Next</Button>
  </AccordionSection>
</template>

<script>
import { computed, ref, toRefs, watch } from 'vue';
import {
  Button,
  List,
  ListItemGroup,
  AccordionSection,
  FormGroup,
  Label,
  RssIcon,
  Toggle,
  VText,
  Badge,
} from '@campsite-bio/component-lib';

import { useModel } from '../../../compositions';
import LinkItem from './link-item';

export default {
  components: {
    Button,
    List,
    ListItemGroup,
    AccordionSection,
    LinkItem,
    FormGroup,
    Label,
    RssIcon,
    Toggle,
    VText,
    Badge,
  },

  props: {
    feed: {
      type: String,
      default: null,
    },

    links: {
      type: Array,
      required: true,
    },

    limit: {
      type: Number,
      default: 10,
    },

    value: {
      type: Array,
      required: true,
    },

    isOpen: Boolean,
    isFreePlan: Boolean,

    variant: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { links, limit, value } = toRefs(props);
    const showAllLinks = ref(false);
    const activateFeed = ref(true);
    const selectedLinks = useModel(value, (val) => emit('input', val));

    const linksToShow = computed(() => {
      return showAllLinks.value
        ? links.value
        : links.value.slice(0, limit.value);
    });

    function updateLink(link) {
      const localLinks = [...links.value];
      const index = localLinks.findIndex((l) => l._id.$oid === link._id.$oid);
      localLinks.splice(index, 1, link);
      emit('links', localLinks);
    }

    watch(activateFeed, (val) => emit('update:feed', val));

    watch(selectedLinks, (val) => {
      const localLinks = [...links.value].map((l) => {
        l.enabled = val.includes(l._id.$oid);
        return l;
      });
      emit('links', localLinks);
    });

    return {
      showAllLinks,
      selectedLinks,
      linksToShow,
      updateLink,
      activateFeed,
    };
  },
};
</script>

<style lang="scss" scoped></style>
