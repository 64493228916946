<template>
  <div class="commerce-item">
    <Input
      v-model.number="price"
      class="commerce-item__price"
      :prefix="currency"
      type="number"
      aria-label="Price"
      max="9999"
      min="1"
      step="1"
    />
    <Input
      v-if="!hideName"
      v-model="name"
      class="commerce-item__name"
      maxlength="100"
      aria-label="Name"
    />
    <IconButton
      class="commerce-item__remove"
      :class="{ 'commerce-item__remove--hidden': hideRemove }"
      @click="$emit('remove')"
    >
      <TrashIcon style="font-size: 1.25rem" />
    </IconButton>
  </div>
</template>

<script>
import { Input, IconButton, TrashIcon } from '@campsite-bio/component-lib';
import { ref, watch } from 'vue';

export default {
  components: {
    Input,
    IconButton,
    TrashIcon,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },

    currency: {
      type: String,
      default: '$',
    },

    hideRemove: Boolean,
    hideName: Boolean,
  },

  setup(props, { emit }) {
    const price = ref(null);
    const name = ref(null);

    watch(
      () => props.item,
      (newItem) => {
        price.value = newItem.price;
        name.value = newItem.name;
      },
      { immediate: true },
    );

    watch([price, name], () => {
      const p = Math.min(Math.max(price.value, 1), 9999);
      emit('input', {
        ...props.item,
        price: p,
        name: name.value,
      });
    });

    return { price, name };
  },
};
</script>

<style lang="scss" scoped>
.commerce-item {
  display: flex;
  gap: 0.75rem;

  &__price {
    max-width: 150px;
  }

  &__remove {
    align-self: center;
    flex-shrink: 0;

    &--hidden {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
  }
}
</style>
