<template>
  <div>
    <ValidationProvider
      v-slot="{ errors, ariaInput, ariaMsg }"
      rules="required"
      name="form"
      slim
    >
      <Alert v-if="!hasAPIKey" style="margin-bottom: 1rem">
        <InfoIcon slot="icon" />
        We can't find a ConvertKit API key for your profile.
        <Button
          slot="actions"
          :to="`/profile/${profileId}/settings#integrations`"
          variant="flat"
          color="white"
        >
          Add&nbsp;<span style="font-size: 1.25em">→</span>
        </Button>
      </Alert>

      <template v-else>
        <FormGroup>
          <FormGroupHeader gutter-bottom>
            <Label :for="'convert_kit_form_id' + _uid">ConvertKit Form</Label>
            <FlexSpacer />
            <v-tooltip top>
              <template #activator="{ on }">
                <QuestionCircleOIcon style="font-size: 20px" v-on="on" />
              </template>
              <span>Choose the form sign ups will be added to</span>
            </v-tooltip>
          </FormGroupHeader>
          <Select
            :id="'convert_kit_form_id' + _uid"
            v-model="listId"
            v-bind="ariaInput"
          >
            <option :value="null">-- Select a form --</option>
            <option v-for="{ id, name } in lists" :key="id" :value="id">
              {{ name }}
            </option>
          </Select>
          <FormGroupHelp color="error" v-bind="ariaMsg">{{
            errors[0]
          }}</FormGroupHelp>
          <FormGroupHelp v-if="lists === null && !isLoadingLists">
            No forms have been returned with the provided API key. Double check
            it on
            <router-link :to="`/profile/${profileId}/settings`"
              >the settings page</router-link
            >.
          </FormGroupHelp>
        </FormGroup>
      </template>
    </ValidationProvider>
  </div>
</template>

<script>
import {
  FormGroup,
  FormGroupHeader,
  FormGroupHelp,
  Label,
  FlexSpacer,
  QuestionCircleOIcon,
  Alert,
  InfoIcon,
  Button,
  Select,
} from '@campsite-bio/component-lib';
import { computed, onMounted, ref, toRefs, watch } from 'vue';
import { ValidationProvider } from 'vee-validate';

import { useCurrentProfile, useStore } from '../../../../compositions';
import { getOptInLists } from '../../../../apis';
import toast from '../../../../utils/toast';
import { getMeta } from '../../../../utils';

export default {
  components: {
    FormGroup,
    FormGroupHeader,
    FormGroupHelp,
    Label,
    FlexSpacer,
    QuestionCircleOIcon,
    ValidationProvider,
    Alert,
    InfoIcon,
    Button,
    Select,
  },

  props: {
    link: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { link } = toRefs(props);
    const store = useStore();
    const lists = ref(null);
    const listId = ref(null);
    const isLoadingLists = ref(true);

    const { profile, id: profileId } = useCurrentProfile();

    const emailOptions = computed(() => {
      return link.value.email_options;
    });

    watch(
      emailOptions,
      ({ destination_id }) => {
        listId.value = destination_id;
      },
      { immediate: true },
    );

    const hasAPIKey = computed(() => {
      return !!getMeta('convert_kit_api_key', null, profile.value.meta);
    });

    const title = computed(() => {
      if (!listId.value || !lists.value) return null;
      return lists.value.find((l) => l.id === listId.value).name;
    });

    async function loadLists() {
      isLoadingLists.value = true;
      try {
        const { data } = await getOptInLists(profileId.value, 'convert_kit');

        lists.value = data.lists;
      } catch (e) {
        const { error_message } = e.response?.data || {};
        console.error(e);
        toast.error(
          error_message ||
            'There was an error trying to get your ConvertKit forms. Please try again soon.',
        );
      }
      isLoadingLists.value = false;
    }

    onMounted(() => {
      if (hasAPIKey.value) loadLists();
    });

    watch(listId, () => {
      emit('change', {
        id: listId.value,
        title: title.value,
      });
    });

    return { hasAPIKey, lists, listId, isLoadingLists, profileId };
  },
};
</script>

<style lang="scss" scoped></style>
