<template>
  <div class="v-card__text" style="padding: 0">
    <ValidationObserver
      v-slot="{ invalid, handleSubmit }"
      tag="div"
      class="item-editor"
    >
      <div class="item-editor__img">
        <span class="item-editor__img-loader">
          <CircularLoader size="2rem" />
        </span>
        <ImageWithHighlights
          :img-attrs="imageAttrs"
          :highlights="item.urls || []"
          :show-highlights="
            canAddMultipleUrls && item.urls && item.urls.length > 1
          "
          :edit-highlight="currentlyEditing ? currentlyEditing.index : null"
          :ratio="imageRatio"
          @edit="handlePositionChange"
        />
      </div>
      <form
        class="item-editor__content"
        novalidate
        @submit.prevent="handleSubmit(handleSave)"
      >
        <div class="item-editor__content__inner">
          <List>
            <ListItem>
              <ListItemContent>
                <ListItemTitle wrap>
                  <Label :for="`enabled${id}`">Show the image</Label>
                </ListItemTitle>
                <FormGroupHelp
                  v-if="
                    enabled &&
                    (!item.urls || (item.urls && item.urls.length === 0))
                  "
                  color="error"
                >
                  Your image won't show up without at least one URL added.
                </FormGroupHelp>
              </ListItemContent>
              <ListItemAction>
                <Toggle :id="`enabled${id}`" v-model="enabled" />
              </ListItemAction>
            </ListItem>
            <Divider thickness="1" color="gray200" />
            <ItemUrl
              v-if="!item.urls || (item.urls && item.urls.length <= 1)"
              :url-item="item.urls && item.urls[0]"
              hide-number
              only-url
              @change="handleUrlChange"
            />
            <template v-else>
              <template v-for="(url, i) in item.urls">
                <ItemUrl
                  :key="i"
                  :url-item="url"
                  :number="i + 1"
                  :hide-number="item.urls.length <= 1"
                  :disabled="currentlyEditing && currentlyEditing.index !== i"
                  :is-editing="currentlyEditing && currentlyEditing.index === i"
                  :can-delete="item.urls.length > 1"
                  :only-url="item.urls.length === 1"
                  @change="handleUrlChange"
                  @close="currentlyEditing = null"
                  @edit="handleUrlEdit(url, i)"
                  @remove="handleRemoveUrl(url)"
                />
                <Divider :key="`divider${i}`" thickness="1" color="gray100" />
              </template>
            </template>
            <ListItem>
              <ListItemContent>
                <span>
                  <template v-if="!canAddMultipleUrls"
                    ><LockIcon />&nbsp;</template
                  >
                  <InlineLink
                    :disabled="
                      !canAddMultipleUrls ||
                      (item.urls && item.urls.length >= 10) ||
                      isAddingUrl
                    "
                    @click="handleAddUrl"
                  >
                    Add another url +
                  </InlineLink>
                </span>

                <Alert
                  v-if="!canAddMultipleUrls"
                  variant="outline"
                  style="margin-top: 1rem"
                  color="gray600"
                >
                  Add up to ten URLs and automate your image grid with Pro
                  <Button
                    slot="actions"
                    to="/account/change-plan"
                    variant="flat"
                    style="font-size: 0.75rem"
                  >
                    Upgrade</Button
                  >
                </Alert>
              </ListItemContent>
            </ListItem>
          </List>
        </div>

        <Divider thickness="1" color="gray200" />
        <CardActions>
          <Button
            size="small"
            :disabled="!hasChanged || invalid"
            :loading="isSaving"
            type="submit"
            >Save Changes</Button
          >
        </CardActions>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { computed, inject, nextTick, onBeforeUnmount, ref, watch } from 'vue';
import {
  Toggle,
  List,
  ListItem,
  ListItemContent,
  ListItemTitle,
  ListItemAction,
  Label,
  Divider,
  convertImageUrlToEncodedUrl,
  CardActions,
  Button,
  InlineLink,
  ImageWithHighlights,
  LockIcon,
  Alert,
  CircularLoader,
  FormGroupHelp,
} from '@campsite-bio/component-lib';
import { ValidationObserver } from 'vee-validate';
import cloneDeep from 'lodash/cloneDeep';

import ItemUrl from '../item-url';
import { useStore } from '../../../../../../compositions';
import toast from '../../../../../../utils/toast';

export default {
  components: {
    Toggle,
    List,
    ListItem,
    ListItemContent,
    ListItemTitle,
    ListItemAction,
    Label,
    Divider,
    ItemUrl,
    CardActions,
    Button,
    InlineLink,
    ImageWithHighlights,
    ValidationObserver,
    LockIcon,
    Alert,
    CircularLoader,
    FormGroupHelp,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },

    linkId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();
    const enabled = computed({
      get() {
        return props.item.enabled;
      },
      set(val) {
        store.commit('linkItems/update', {
          linkId: props.linkId,
          itemId: id.value,
          enabled: val,
        });
      },
    });
    const hasChanged = ref(false);
    const currentlyEditing = ref(null);
    const isSaving = ref(false);
    const isAddingUrl = ref(false);
    const originalItem = ref(cloneDeep(props.item));

    const { handleBack, setPreventBack } = inject('multi-step-dialog');

    watch(hasChanged, (newVal) => {
      if (newVal) setPreventBack(true);
      else setPreventBack(false);
    });

    const imageAttrs = computed(() => {
      return { src: convertImageUrlToEncodedUrl(props.item.image.url, 640) };
    });

    const imageRatio = computed(() => {
      const { height, width } = props.item.image;
      return height && width ? width / height : null;
    });

    const id = computed(() => {
      return props.item._id.$oid;
    });

    // Disable multiple URLs for free users
    const canAddMultipleUrls = computed(() => {
      return !store.getters['profiles/isProfileOnFreePlan'];
    });

    watch(
      () => props.item,
      (item) => {
        enabled.value = item.enabled;
      },
      { immediate: true },
    );

    watch(
      () => props.item,
      (newItem) => {
        hasChanged.value = true;
      },
      { deep: true },
    );

    onBeforeUnmount(() => {
      // If they've went back and not saved, reset the item
      if (hasChanged.value)
        store.commit('linkItems/update', {
          linkId: props.linkId,
          itemId: props.item._id.$oid,
          ...originalItem.value,
        });
    });

    async function handleAddUrl() {
      if (!canAddMultipleUrls.value) {
        toast.error('You must be on a paid plan to add more than one url.');
        return;
      }

      isAddingUrl.value = true;
      currentlyEditing.value = null;

      try {
        await store.dispatch('linkItems/addUrl', {
          itemId: id.value,
          linkId: props.linkId,
        });
        currentlyEditing.value = {
          url: props.item.urls[props.item.urls.length - 1],
          index: props.item.urls.length - 1,
        };
        // Keep urls up-to-date
        originalItem.value.urls = props.item.urls;
      } catch (e) {
        console.error(e);
        const { error_message } = e.response?.data || {};
        toast.error(
          error_message || 'An error occurred while adding a new url',
        );
      }

      isAddingUrl.value = false;
    }

    async function handleRemoveUrl(url) {
      isAddingUrl.value = true;
      currentlyEditing.value = null;

      try {
        await store.dispatch('linkItems/removeUrl', {
          itemId: id.value,
          linkId: props.linkId,
          urlId: url._id.$oid,
        });
        // Keep urls up-to-date
        originalItem.value.urls = props.item.urls;
      } catch (e) {
        console.error(e);
        const { error_message } = e.response?.data || {};
        toast.error(
          error_message || 'An error occurred while removing the url',
        );
      }

      isAddingUrl.value = false;
    }

    async function handleSave() {
      isSaving.value = true;
      currentlyEditing.value = null;

      try {
        await store.dispatch('linkItems/update', {
          itemId: id.value,
          linkId: props.linkId,
          item: props.item,
        });

        // Make sure to reset prevent back in the dialog after saving
        setPreventBack(false);
        hasChanged.value = false;
        handleBack();
      } catch (e) {
        console.error(e);
        const { error_message } = e.response?.data || {};
        toast.error(
          error_message || 'An error occurred while saving your changes',
        );
        isSaving.value = false;
      }
    }

    function handleUrlEdit(url, i) {
      currentlyEditing.value = { url, index: i };
    }

    function handlePositionChange(attrs) {
      const { top, left } = attrs;
      store.commit('linkItems/updateUrl', {
        itemId: id.value,
        linkId: props.linkId,
        urlItem: currentlyEditing.value.url,
        top,
        left,
      });
    }

    async function handleUrlChange(url) {
      let editingUrl = currentlyEditing.value?.url;
      // There aren't any urls, so we're adding one
      if (!props.item.urls?.length) {
        store.commit('linkItems/addUrl', {
          itemId: id.value,
          linkId: props.linkId,
          ...url,
        });

        await nextTick();

        editingUrl = props.item.urls[0];

        // They added the first URL, let's enable the item for them
        enabled.value = true;
      } else if (props.item.urls && !editingUrl)
        editingUrl = props.item.urls[0];

      store.commit('linkItems/updateUrl', {
        itemId: id.value,
        linkId: props.linkId,
        urlItem: editingUrl,
        ...url,
      });

      hasChanged.value = true;
    }

    return {
      id,
      enabled,
      imageAttrs,
      imageRatio,
      hasChanged,
      handleAddUrl,
      handleSave,
      handleUrlEdit,
      currentlyEditing,
      handlePositionChange,
      handleUrlChange,
      isSaving,
      isAddingUrl,
      handleRemoveUrl,
      canAddMultipleUrls,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-card__text.v-card__text {
  @media (min-width: 992px) {
    max-height: 80vh;
  }
}

.item-editor {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 375px;
  }

  &__img {
    background: var(--g-color-gray-100);
    display: flex;
    align-items: center;
    position: relative;

    @media (min-width: 992px) {
      min-height: 425px;
    }

    > * {
      width: 100%;
    }
  }

  &__img-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto !important;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0 0;

    &__inner {
      flex-grow: 1;
      overflow-y: auto;
      padding-bottom: 1rem;
    }
  }
}
</style>
