import axios from 'axios';
import { ref, computed, watch, unref, onUnmounted } from 'vue';
import { DateTime } from 'luxon';

import { useStore } from '../../../compositions';
import { useSelectedDateGranularity } from '.';

/**
 * Load view and click related data
 * @param {String} storeName The name of the getter in the vuex store
 * @param {String} storeAction The name of the action in vuex that will load the data
 * @param {Object} params Parameters you want to pass to the data endpoint
 * @param {Object} options
 */
export function useLoadData(storeName, storeAction, params, options) {
  const store = useStore();
  const allData = computed(() => {
    return store.getters['analytics/getField'](unref(storeName));
  });
  const data = computed(() => (allData.value ? allData.value.data : null));
  const {
    onError,
    onBeforeLoad,
    immediate = false,
    useSelectedDate = false,
    useGranularity = false,
    granularity: granularityRef,
    selectedDateFilter: selectedDateFilterRef,
  } = options;
  const selectedDateFilter = selectedDateFilterRef
    ? selectedDateFilterRef
    : computed(() => store.getters['analytics/selectedDateFilter']);
  const filters = computed(() => store.getters['analytics/filters']);
  const cancelSource = ref(null);
  const isLoading = ref(false);
  const timezone = computed(() => DateTime.local().zoneName);
  const granularity = granularityRef || useSelectedDateGranularity();
  const timestamp = computed(() => (data.value ? data.value.timestamp : null));

  async function load() {
    if (onBeforeLoad) {
      const response = onBeforeLoad();
      // stop load if onBeforeLoad returns false
      if (response === false) return;
    }

    if (cancelSource.value) cancelSource.value.abort();
    cancelSource.value = new AbortController();
    isLoading.value = true;
    const queryParams = {
      ...Object.keys(params).reduce((accum, key) => {
        accum[key] = unref(params[key]);
        return accum;
      }, {}),
      filter: null,
    };
    try {
      if (useSelectedDate) {
        if (Array.isArray(selectedDateFilter.value)) {
          queryParams.range = 'custom';
          queryParams.start_date = selectedDateFilter.value[0];
          queryParams.end_date = selectedDateFilter.value[1];
        } else queryParams.range = selectedDateFilter.value;

        if (
          filters.value?.length &&
          // Only analytics report should use filters
          (!queryParams.report || queryParams.report === 'analytics')
        ) {
          queryParams.filters = filters.value.reduce(
            (accum, { filter, operator, value }) => {
              accum[filter.path] = {
                value,
                operator: operator.value,
              };
              return accum;
            },
            {},
          );
        }
      }
      if (useGranularity) queryParams.granularity = granularity.value;
      const viewData = await store.dispatch(`analytics/${storeAction}`, {
        cancelToken: cancelSource.value.signal,
        timezone: timezone.value,
        ...queryParams,
      });

      store.commit('analytics/updateField', {
        path: unref(storeName),
        value: viewData,
      });
    } catch (e) {
      if (axios.isCancel(e) || e.name === 'AbortError') return;
      console.error(e);
      if (onError) onError(e);
    }
    isLoading.value = false;
  }

  if (useSelectedDate) {
    watch([selectedDateFilter, filters], () => load());
  }

  if (useGranularity && granularityRef) {
    watch(granularity, () => load());
  }

  if (immediate) load();

  onUnmounted(() => {
    if (cancelSource.value) cancelSource.value.abort();
  });

  return {
    allData,
    data,
    isLoading,
    load,
    timestamp,
  };
}
