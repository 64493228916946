import { computed } from 'vue';
import { DateTime } from 'luxon';
import {
  convertImageUrlToEncodedUrl,
  MEDIA_SIZES,
} from '@campsite-bio/component-lib';

import { getAppSetting, getMeta } from '@/utils';
import { useStore } from '../use-store';

export function useCurrentUser() {
  const store = useStore();

  const user = computed(() => store.getters.currentUser);
  const id = computed(() => user.value._id.$oid);
  const customerId = computed(() => user.value.stripe_customer);

  const email = computed(() => {
    return user.value?.email;
  });

  const isEmailVerified = computed(() => {
    return user.value?.email_verified;
  });

  const hasAnalyticsAddon = computed(() => {
    const addons = getMeta('addons', [], user.value?.meta);
    return addons.includes('analytics');
  });

  const createdAt = computed(() => {
    return DateTime.fromISO(user.value?.created_at);
  });

  const isSpam = computed(() => {
    return store.getters.currentUser.spam;
  });

  const isAdmin = computed(() => {
    return store.getters.isAdmin;
  });

  const plan = computed(() => {
    return user.value.plan;
  });

  const planId = computed(() => {
    return user.value.plan_id;
  });

  const userCategories = computed(() => user.value.categories);

  const isNonProfit = computed(() => {
    return userCategories.value?.includes('Community Organization/Non-Profit');
  });
  const isBusiness = computed(() => {
    return userCategories.value?.includes('Business/Entrepreneur/Non-profit');
  });

  const profileImage = computed(() => {
    return user.value.profile_image;
  });

  const profileImageSrc = computed(() => {
    return convertImageUrlToEncodedUrl(
      profileImage.value,
      MEDIA_SIZES.profile_image,
    );
  });

  const hasDefaultProfileImage = computed(() => {
    return profileImage.value === getAppSetting('defaultProfileImage');
  });

  const numberOfProfiles = computed(() => {
    return store.getters.numberOfCampsites;
  });

  const numberOfProfilesEnabled = computed(() => {
    return store.getters.campsitesEnabled;
  });

  const numberOfUsers = computed(() => {
    return store.getters.numberOfUsers;
  });

  const freeAfter = computed(() => {
    return user.value.free_after_datetime;
  });

  const defaultCampsite = computed(() => {
    return user.value.default_campsite;
  });

  return {
    user,
    id,
    customerId,
    email,
    isEmailVerified,
    hasAnalyticsAddon,
    createdAt,
    isSpam,
    isAdmin,
    plan,
    planId,
    userCategories,
    isNonProfit,
    isBusiness,
    profileImage,
    profileImageSrc,
    hasDefaultProfileImage,
    numberOfProfiles,
    numberOfUsers,
    freeAfter,
    numberOfProfilesEnabled,
    defaultCampsite,
  };
}
