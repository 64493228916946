<template>
  <div class="multi-color-selector">
    <ColorOption
      v-for="(c, i) in value"
      :key="`${c}${i}`"
      :value="c"
      :index="i"
      :color-options="colorOptions"
      :show-add="i === value.length - 1 && value.length < limit"
      :hide-remove="value.length <= 2"
      @input="handleInput"
      @remove="handleRemove"
      @add="handleAdd"
    />
  </div>
</template>

<script>
import tinycolor from 'tinycolor2';

import ColorOption from './color-option';

export default {
  components: {
    ColorOption,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    colorOptions: {
      type: Object,
      required: true,
    },

    limit: {
      type: Number,
      default: 5,
    },
  },

  setup(props, { emit }) {
    function handleInput(value, index) {
      const values = [...props.value];
      values.splice(index, 1, value);
      emit('input', values);
    }

    function handleAdd() {
      const newColor = tinycolor(props.value[props.value.length - 1])
        .complement()
        .toHexString();
      emit('input', [...props.value, newColor]);
    }

    function handleRemove(colorToRemove) {
      emit(
        'input',
        props.value.filter((color) => color !== colorToRemove),
      );
    }

    return { handleInput, handleAdd, handleRemove };
  },
};
</script>

<style lang="scss" scoped>
.multi-color-selector {
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
}
</style>
