<template>
  <UserProfiles :limit="5" style="margin-bottom: 1.75rem">
    <div style="margin-top: 1rem">
      <Button to="/profiles" size="small">View all profiles</Button>
    </div>
  </UserProfiles>
</template>

<script>
import { Button } from '@campsite-bio/component-lib';

import { UserProfiles } from '../../profiles';

export default {
  components: {
    UserProfiles,
    Button,
  },
};
</script>

<style lang="scss" scoped></style>
