<template>
  <Select :id="inputId" v-model="zone" v-bind="$attrs">
    <option
      v-for="timezone in timezones"
      :key="timezone.value"
      :value="timezone.value"
    >
      {{ timezone.text }}
    </option>
  </Select>
</template>

<script>
import { Select } from '@campsite-bio/component-lib';

export default {
  components: {
    Select,
  },

  props: {
    value: String,
    inputId: String,
  },

  data() {
    return {
      zone: '',
    };
  },

  computed: {
    timezones() {
      return this.$store.getters.timezones;
    },
  },

  watch: {
    value(newValue) {
      this.zone = newValue;
    },

    zone(newValue) {
      this.$emit('input', newValue);
    },
  },

  created() {
    this.zone = this.value;
    this.$store.dispatch('getTimezones');
  },
};
</script>

<style scoped></style>
