<template>
  <Fragment>
    <StatCard
      v-for="{ count, rank, key, name } in highlights"
      :key="key"
      class="span-4"
      :title="name"
      :is-loading="isLoading"
    >
      {{ count | numberFormatter(2) }}
      <FlexSpacer />
      {{ getMedalEmojiFromRank(rank) }}
    </StatCard>
  </Fragment>
</template>

<script>
import { computed } from 'vue';
import { FlexSpacer, Fragment } from '@campsite-bio/component-lib';

import { StatCard } from '..';
import { getMedalEmojiFromRank } from '../../../utils';

const HIGHLIGHT_FIELDS = [
  { name: 'Views', key: 'views' },
  { name: 'Reach', key: 'reach' },
  { name: 'Clicks', key: 'clicks' },
  { name: 'Events', key: 'events' },
  { name: 'Action Score', key: 'action_score' },
];

export default {
  components: {
    Fragment,
    StatCard,
    FlexSpacer,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    ranking: {
      type: Object,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const highlights = computed(() => {
      return HIGHLIGHT_FIELDS.reduce((accum, { key, ...rest }) => {
        accum.push({
          count: props.data[key],
          rank: props.ranking[key],
          key,
          ...rest,
        });
        return accum;
      }, []);
    });

    return {
      highlights,
      getMedalEmojiFromRank,
    };
  },
};
</script>

<style lang="scss" scoped></style>
