<template>
  <Fragment>
    <CardText>
      <VText gutter-bottom style="font-size: 0.9rem"
        >Sync a collection and display up to six products. Any changes you make
        to your Shopify collection will be reflected on your profile.</VText
      >

      <List style="margin: 0 -1rem">
        <ListItemGroup
          v-model="selectedCollectionLocal"
          aria-label="Shopify collections"
        >
          <template v-if="isLoading">
            <ListItem v-for="i in 3" :key="i">
              <ListItemAction>
                <Checkbox disabled />
              </ListItemAction>
              <ListItemAvatar style="margin-right: 1rem; margin-left: -0.5rem">
                <Skeleton width="40px" height="40px" circle />
              </ListItemAvatar>
              <ListItemContent>
                <ListItemTitle><Skeleton width="100px" /></ListItemTitle>
              </ListItemContent>
            </ListItem>
          </template>
          <ListItem
            v-for="collection in collections"
            :key="collection.collection_id"
            :value="collection.collection_id"
          >
            <template #default="{ active }">
              <ListItemAction>
                <Checkbox :input-value="active" />
              </ListItemAction>
              <ListItemAvatar style="margin-right: 1rem; margin-left: -0.5rem">
                <img
                  :src="
                    collection.image
                      ? collection.image.src
                      : 'https://via.placeholder.com/150?text=No+Image'
                  "
                  :alt="collection.image ? collection.image.altText : ''"
                />
              </ListItemAvatar>
              <ListItemContent>
                <ListItemTitle>{{ collection.title }}</ListItemTitle>
              </ListItemContent>
            </template>
          </ListItem>
        </ListItemGroup>
      </List>
    </CardText>
    <CardActions>
      <Button color="error" variant="flat">Cancel</Button>
      <Button
        color="success"
        variant="flat"
        :disabled="!selectedCollectionLocal"
        @click="handleSelect"
        >Select</Button
      >
    </CardActions>
  </Fragment>
</template>

<script>
import {
  CardText,
  CardActions,
  List,
  ListItemGroup,
  ListItem,
  Checkbox,
  ListItemContent,
  ListItemTitle,
  ListItemAction,
  ListItemAvatar,
  Skeleton,
  Fragment,
  Button,
  VText,
} from '@campsite-bio/component-lib';
import { inject, onMounted, ref } from 'vue';
import { localAxios } from '@/apis';
import toast from '@/utils/toast';

export default {
  components: {
    CardText,
    CardActions,
    List,
    ListItemGroup,
    ListItem,
    Checkbox,
    ListItemContent,
    ListItemTitle,
    ListItemAction,
    ListItemAvatar,
    Skeleton,
    Fragment,
    Button,
    VText,
  },

  props: {
    selectedCollection: {
      type: String,
      default: null,
    },

    linkId: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const selectedCollectionLocal = ref(null);
    const collections = ref([]);
    const isLoading = ref(true);
    const { isOpen } = inject('multi-step-dialog');

    async function load() {
      isLoading.value = true;
      try {
        const { data } = await localAxios.get(
          `/api/links/${props.linkId}/shop/collections`,
        );
        // TODO: handle pagination
        collections.value = data.data.collection_listings;
      } catch (e) {
        console.error(e);
        toast.error('Failed to load collections');
      } finally {
        isLoading.value = false;
      }
    }

    function handleSelect() {
      emit('update:collection', selectedCollectionLocal.value);
      isOpen.value = false;
    }

    onMounted(load);

    return { selectedCollectionLocal, collections, isLoading, handleSelect };
  },
};
</script>

<style lang="scss" scoped></style>
