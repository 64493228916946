<template>
  <article class="inner-page" :class="classes" :style="styles">
    <div class="inner-page__grid" :class="gridClasses" :style="gridStyles">
      <div v-if="$slots.sidenav" class="inner-page__sidenav">
        <div class="inner-page__sidebar">
          <slot name="sidenav" />
        </div>
      </div>

      <div class="inner-page__content">
        <header class="inner-page__header">
          <div>
            <TitleWithSubmenuToggle
              v-if="titleHasSubmenuToggle"
              class="inner-page__title"
            >
              {{ title }}
            </TitleWithSubmenuToggle>
            <VText
              v-else
              class="inner-page__title"
              component="h1"
              variant="h2"
              >{{ title }}</VText
            >
            <slot name="text" />
          </div>

          <div v-if="$slots.actions" class="inner-page__header__actions">
            <slot name="actions" />
          </div>
        </header>

        <div ref="content">
          <slot />
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { VText } from '@campsite-bio/component-lib';
import { computed, ref } from 'vue';

import { TitleWithSubmenuToggle } from '@/components/layout';

export default {
  components: {
    VText,
    TitleWithSubmenuToggle,
  },

  props: {
    title: {
      type: String,
      default: 'Page Title',
    },

    includePadding: Boolean,
    /** Include the grid class that gives side padding */
    includeGrid: Boolean,
    /** Include ability to toggle submenu on desktop */
    titleHasSubmenuToggle: Boolean,

    maxWidth: {
      type: String,
      default: '1025px',
    },
  },

  setup(props, { slots }) {
    const content = ref(null);

    const gridStyles = computed(() => {
      return {
        maxWidth: props.maxWidth,
      };
    });

    const classes = computed(() => {
      return {
        'inner-page--include-padding': props.includePadding,
        'inner-page--has-sidenav': slots.sidenav,
      };
    });

    const styles = computed(() => {
      return {
        '--c-content-top': `${content.value?.offsetTop}px`,
      };
    });

    const gridClasses = computed(() => {
      return {
        grid: props.includeGrid,
      };
    });

    return { content, classes, styles, gridClasses, gridStyles };
  },
};
</script>

<style lang="scss" scoped>
.inner-page {
  &--include-padding {
    padding: 1.875rem 0 2rem;
  }

  &--has-sidenav {
    .inner-page__grid {
      @media (min-width: 1100px) {
        display: grid;
        grid-template-columns: 175px 1fr;
        gap: 1rem;
      }
    }
  }

  &__grid {
    margin-left: auto;
    margin-right: auto;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.825rem;
    justify-content: space-between;
  }

  &__title {
    + p {
      margin-top: 0.25rem;
    }
  }

  &__sidenav {
    margin-top: var(--c-content-top);
  }

  &__sidebar {
    align-self: flex-start;
    position: sticky;
    transition: all var(--g-transition-speed);
    top: calc(var(--profile-menu-height) + 0.5rem - 66px);

    &--profile-menu-hidden {
      top: calc(0.5rem - 66px);
    }
  }
}
</style>
