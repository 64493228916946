<template>
  <div class="filter-option">
    <FormControlLabel :label="label">
      <Radio :value="value" />
    </FormControlLabel>

    <div v-if="isSelected" class="filter-option__input-wrapper">
      <slot>
        <Input
          v-if="operator.inputType === 'input'"
          ref="input"
          v-model="inputValue"
        />
        <Select
          v-else-if="operator.inputType === 'select'"
          ref="input"
          v-model="inputValue"
          multiple
          :items="filterOptions.filter((o) => o._id)"
          item-value="_id"
          item-text="_id"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import { FormControlLabel, Radio, Input } from '@campsite-bio/component-lib';
import { computed, ref, toRefs, watch, nextTick } from 'vue';
import { Select } from '../../../vuetify';
import { useModel } from '../../../../compositions';

export default {
  components: {
    FormControlLabel,
    Radio,
    Input,
    Select,
  },

  props: {
    label: {
      type: String,
      required: true,
    },

    value: {
      type: [String, Array],
      default: null,
    },

    operator: {
      type: Object,
      default: null,
    },

    filterOptions: {
      type: Array,
      default: () => [],
    },

    filterValue: {
      type: [String, Array],
      default: null,
    },
  },

  setup(props, { emit }) {
    const { value, operator, filterValue } = toRefs(props);
    const input = ref(null);
    const inputValue = useModel(filterValue, (val) =>
      emit('input:filter-value', val),
    );

    const isSelected = computed(() => {
      if (!operator.value) return false;

      return operator.value.value === value.value;
    });

    watch(isSelected, async (newValue) => {
      if (input.value && newValue) {
        await nextTick();
        input.value.focus();
      }
    });

    return { input, isSelected, inputValue };
  },
};
</script>

<style lang="scss" scoped>
.filter-option {
  &__input-wrapper {
    margin-top: 0.5rem;
  }

  &::v-deep {
    .v-text-field__details {
      display: none;
    }
  }
}
</style>
