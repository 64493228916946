<template>
  <LinkExpander
    :id="`default-${link._id.$oid}`"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template slot="header">
      Opt-in Form
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <IconButton
            href="https://support.campsite.bio/en/articles/6840703-opt-in-form-link"
            target="_blank"
            aria-label="Opt-in form setup help"
            size="1.25rem"
            v-bind="attrs"
            v-on="on"
          >
            <QuestionCircleOIcon />
          </IconButton>
        </template>
        <span>Get some help setting up your opt-in form</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="form">
      <template v-if="isMailchimp">
        <VText component="h3" variant="h5" gutter-bottom
          >Mailchimp Options</VText
        >
        <DestinationMailchimp
          v-if="changeDestination"
          :link="link"
          @change="handleDestinationChange"
        />
        <FormGroup v-else>
          <SourceItem
            title="Mailchimp Audience"
            :subtitle="destinationTitleLocal"
            @click="changeDestination = true"
          />
        </FormGroup>

        <FormGroup style="margin-top: 1.5rem">
          <Toggle v-model="enableDoubleOptInLocal">
            Enable double opt-in
          </Toggle>
          <v-tooltip top style="vertical-align: middle">
            <template #activator="{ on }">
              <QuestionCircleOIcon style="font-size: 20px" v-on="on" />
            </template>
            <span>Make a new subscriber verify their email</span>
          </v-tooltip>
        </FormGroup>

        <FormGroup :gutter-bottom="false">
          <Label :for="`tags${_uid}`">Tags</Label>
          <Combobox
            :id="`tags${_uid}`"
            v-model="tagsLocal"
            multiple
            outlined
            dense
            small-chips
            deletable-chips
            hint="Tag a user when they're added to your audience"
            persistent-hint
            :items="['Prospect', 'Lead']"
          />
        </FormGroup>
      </template>
      <template v-else-if="isKlaviyo">
        <VText component="h3" variant="h5" gutter-bottom>Klaviyo Options</VText>
        <DestinationKlaviyo
          v-if="changeDestination"
          :link="link"
          @change="handleDestinationChange"
        />
        <FormGroup v-else>
          <SourceItem
            title="Klaviyo List"
            :subtitle="destinationTitleLocal"
            @click="changeDestination = true"
          />
        </FormGroup>
      </template>
      <template v-else-if="isActiveCampaign">
        <VText component="h3" variant="h5" gutter-bottom
          >ActiveCampaign Options</VText
        >
        <DestinationActiveCampaign
          v-if="changeDestination"
          :link="link"
          @change="handleDestinationChange"
        />
        <FormGroup v-else>
          <SourceItem
            :title="`ActiveCampaign ${
              destinationVariantLocal ? 'Tag' : 'List'
            }`"
            :subtitle="destinationTitleLocal"
            @click="changeDestination = true"
          />
        </FormGroup>
      </template>
      <template v-else-if="isMailerLite">
        <VText component="h3" variant="h5" gutter-bottom
          >MailerLite Options</VText
        >
        <DestinationMailerLite
          v-if="changeDestination"
          :link="link"
          @change="handleDestinationChange"
        />
        <FormGroup v-else>
          <SourceItem
            title="MailerLite List"
            :subtitle="destinationTitleLocal"
            @click="changeDestination = true"
          />
        </FormGroup>
      </template>
      <template v-else-if="isOmnisend">
        <VText component="h3" variant="h5" gutter-bottom
          >Omnisend Options</VText
        >
        <FormGroup :gutter-bottom="false">
          <Label :for="`tags${_uid}`">Tags</Label>
          <Combobox
            :id="`tags${_uid}`"
            v-model="tagsLocal"
            multiple
            outlined
            dense
            small-chips
            deletable-chips
            hint="Tag a user when they're added to your audience"
            persistent-hint
            :items="['prospect', 'lead']"
          />
        </FormGroup>
      </template>
      <template v-else-if="isConvertKit">
        <VText component="h3" variant="h5" gutter-bottom
          >ConvertKit Options</VText
        >
        <DestinationConvertKit
          v-if="changeDestination"
          :link="link"
          @change="handleDestinationChange"
        />
        <FormGroup v-else>
          <SourceItem
            title="ConvertKit Form"
            :subtitle="destinationTitleLocal"
            @click="changeDestination = true"
          />
        </FormGroup>
      </template>
      <template v-else-if="isGoogle">
        <VText component="h3" variant="h5" gutter-bottom>Sheet Options</VText>
        <DestinationGoogle :link="link" @change="handleDestinationChange" />
      </template>
      <template v-else-if="isZapier">
        <VText component="h3" variant="h5" gutter-bottom>Zapier Options</VText>
        <FormGroup>
          <SourceItem
            title="Webhook URL"
            :subtitle="destinationIdLocal"
            hide-action
          />
        </FormGroup>
      </template>

      <VText
        component="h3"
        variant="h5"
        gutter-bottom
        style="margin-top: 1.5rem"
        >Other Options</VText
      >

      <FormGroup>
        <Label :for="`description${_uid}`">Description (optional)</Label>
        <Input
          :id="`description${_uid}`"
          v-model="descriptionLocal"
          component="textarea"
          rows="2"
          placeholder="Describe what they are opting into"
          maxlength="240"
        />
      </FormGroup>

      <ValidationProvider
        v-slot="{ errors, ariaInput, ariaMsg }"
        rules="required|max:40"
        name="button text"
        slim
      >
        <FormGroup>
          <Label :for="`button${_uid}`" gutter-bottom>Button Text</Label>
          <Input
            :id="`button${_uid}`"
            v-model="buttonTextLocal"
            :has-error="errors.length > 0"
            maxlength="40"
            v-bind="ariaInput"
          />
          <FormGroupHelp color="error" v-bind="ariaMsg">{{
            errors[0]
          }}</FormGroupHelp>
          <FormGroupHelp>The text of the form button</FormGroupHelp>
        </FormGroup>
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors, ariaInput, ariaMsg }"
        rules="required"
        name="success message"
        slim
      >
        <FormGroup>
          <Label :for="`success${_uid}`" gutter-bottom>Success Message</Label>
          <Input
            :id="`success${_uid}`"
            v-model="successMessageLocal"
            placeholder="You're all set! Thanks for signing up"
            :has-error="errors.length > 0"
            v-bind="ariaInput"
          />
          <FormGroupHelp color="error" v-bind="ariaMsg">{{
            errors[0]
          }}</FormGroupHelp>
        </FormGroup>
      </ValidationProvider>

      <FormGroup>
        <Toggle v-model="showLegalInfoLocal"> Show Legal Info </Toggle>
      </FormGroup>

      <template v-if="showLegalInfoLocal">
        <div class="row">
          <div class="col col--md-6">
            <FormGroup>
              <Label :for="`legaltext${_uid}`" gutter-bottom>Legal Text</Label>
              <Input
                :id="`legaltext${_uid}`"
                v-model="legalTextLocal"
                placeholder="Privacy Policy"
              />
            </FormGroup>
          </div>
          <div class="col col--md-6">
            <ValidationProvider
              v-slot="{ errors, ariaInput, ariaMsg }"
              rules="url"
              name="url"
              slim
            >
              <FormGroup>
                <Label :for="`legalurl${_uid}`" gutter-bottom>Legal Url</Label>
                <Input
                  :id="`legalurl${_uid}`"
                  v-model="legalUrlLocal"
                  :has-error="errors.length > 0"
                  placeholder="https://mydomain.com/privacy"
                  v-bind="ariaInput"
                />
                <FormGroupHelp color="error" v-bind="ariaMsg">{{
                  errors[0]
                }}</FormGroupHelp>
              </FormGroup>
            </ValidationProvider>
          </div>
        </div>
      </template>
    </ValidationObserver>

    <merge-fields
      :link="link"
      :email-options="emailOptions"
      style="margin-top: 1.5rem"
    />
  </LinkExpander>
</template>

<script>
import {
  Label,
  FormGroup,
  FormGroupHelp,
  IconButton,
  QuestionCircleOIcon,
  Input,
  Toggle,
  VText,
} from '@campsite-bio/component-lib';
import { computed, nextTick, onBeforeUnmount, ref, toRefs, watch } from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import debounce from 'lodash/debounce';

import {
  DestinationMailchimp,
  DestinationGoogle,
  DestinationKlaviyo,
  DestinationActiveCampaign,
  DestinationMailerLite,
  DestinationConvertKit,
} from './destinations';
import { MergeFields } from './fields';
import LinkExpander from '../expand/link-expander';
import SourceItem from './source-item';
import { Combobox } from '../../vuetify';

export default {
  components: {
    Label,
    FormGroup,
    FormGroupHelp,
    IconButton,
    QuestionCircleOIcon,
    Input,
    Toggle,
    VText,
    MergeFields,
    LinkExpander,
    ValidationProvider,
    ValidationObserver,
    DestinationMailchimp,
    DestinationGoogle,
    DestinationKlaviyo,
    DestinationActiveCampaign,
    DestinationMailerLite,
    DestinationConvertKit,
    SourceItem,
    Combobox,
  },

  props: {
    isOpen: Boolean,
    emailOptions: {
      type: Object,
      required: true,
    },
    link: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { emailOptions } = toRefs(props);
    const form = ref(null);
    const changeDestination = ref(false);
    const ignoreChanges = ref(false);
    const dirty = ref(false);

    const destinationIdLocal = ref(null);
    const descriptionLocal = ref(null);
    const destinationVariantLocal = ref(null);
    const destinationTitleLocal = ref(null);
    const enableDoubleOptInLocal = ref(false);
    const successMessageLocal = ref(null);
    const buttonTextLocal = ref(null);
    const showLegalInfoLocal = ref(false);
    const legalTextLocal = ref(null);
    const legalUrlLocal = ref(null);
    const tagsLocal = ref(null);

    watch(
      emailOptions,
      async ({
        destination_id,
        description,
        variant,
        destination_title,
        enable_double_opt_in,
        success_message,
        show_legal_info,
        legal_text,
        legal_url,
        button_text,
        user_tags,
      }) => {
        ignoreChanges.value = true;
        destinationIdLocal.value = destination_id;
        descriptionLocal.value = description;
        destinationVariantLocal.value = variant;
        destinationTitleLocal.value = destination_title;
        enableDoubleOptInLocal.value = enable_double_opt_in;
        buttonTextLocal.value = button_text;
        successMessageLocal.value = success_message;
        showLegalInfoLocal.value = show_legal_info;
        legalTextLocal.value = legal_text;
        legalUrlLocal.value = legal_url;
        tagsLocal.value = user_tags;
        dirty.value = false;
        await nextTick();
        ignoreChanges.value = false;
      },
      { immediate: true },
    );

    const isMailchimp = computed(() => {
      return emailOptions.value.source === 'mailchimp';
    });

    const isGoogle = computed(() => {
      return emailOptions.value.source === 'google';
    });

    const isKlaviyo = computed(() => {
      return emailOptions.value.source === 'klaviyo';
    });

    const isActiveCampaign = computed(() => {
      return emailOptions.value.source === 'active_campaign';
    });

    const isMailerLite = computed(() => {
      return emailOptions.value.source === 'mailer_lite';
    });

    const isOmnisend = computed(() => {
      return emailOptions.value.source === 'omnisend';
    });

    const isConvertKit = computed(() => {
      return emailOptions.value.source === 'convert_kit';
    });

    const isZapier = computed(() => {
      return emailOptions.value.source === 'zapier';
    });

    const shouldLowerCaseTags = computed(() => {
      return isOmnisend.value;
    });

    // Lowercase the tags
    watch(tagsLocal, () => {
      if (
        shouldLowerCaseTags.value &&
        tagsLocal.value.some((t) => t !== t.toLowerCase())
      ) {
        tagsLocal.value = tagsLocal.value.map((t) => t.toLowerCase());
      }
    });

    watch(dirty, (newVal) => emit('change:dirty', newVal));

    async function save() {
      const success = await form.value.validate();
      if (!success) return;

      emit('save', {
        destination_id: destinationIdLocal.value,
        description: descriptionLocal.value,
        variant: destinationVariantLocal.value,
        destination_title: destinationTitleLocal.value,
        enable_double_opt_in: enableDoubleOptInLocal.value,
        success_message: successMessageLocal.value,
        show_legal_info: showLegalInfoLocal.value,
        legal_text: legalTextLocal.value,
        legal_url: legalUrlLocal.value,
        button_text: buttonTextLocal.value,
        user_tags: tagsLocal.value,
      });

      await nextTick();
      dirty.value = false;
    }

    const saveDebounced = debounce(save, 1000);

    function handleDestinationChange({ id, title, variant }) {
      changeDestination.value = false;
      destinationIdLocal.value = id;
      destinationVariantLocal.value = variant;
      destinationTitleLocal.value = title;
    }

    watch(
      [
        destinationIdLocal,
        descriptionLocal,
        destinationVariantLocal,
        destinationTitleLocal,
        enableDoubleOptInLocal,
        successMessageLocal,
        showLegalInfoLocal,
        legalTextLocal,
        legalUrlLocal,
        buttonTextLocal,
      ],
      () => {
        if (!ignoreChanges.value) {
          dirty.value = true;
          saveDebounced();
        }
      },
    );

    // Only save tags if they've actually changed
    watch(tagsLocal, (newValue, oldValue) => {
      if (!ignoreChanges.value && newValue.length !== oldValue?.length) {
        dirty.value = true;
        saveDebounced();
      }
    });

    onBeforeUnmount(() => {
      dirty.value = false;
    });

    return {
      form,
      isMailchimp,
      isGoogle,
      isKlaviyo,
      isActiveCampaign,
      isMailerLite,
      isOmnisend,
      isConvertKit,
      isZapier,
      destinationIdLocal,
      descriptionLocal,
      destinationVariantLocal,
      destinationTitleLocal,
      enableDoubleOptInLocal,
      successMessageLocal,
      showLegalInfoLocal,
      legalTextLocal,
      legalUrlLocal,
      buttonTextLocal,
      tagsLocal,
      changeDestination,
      handleDestinationChange,
    };
  },
};
</script>

<style lang="scss" scoped></style>
