import { useTheme } from '@campsite-bio/component-lib';
import { computed } from 'vue';
import tinycolor from 'tinycolor2';

// Get text colors that would be most readable on the data colors
export function useOnDataColors() {
  const theme = useTheme();

  return computed(() => {
    return theme.value.dataColors.map((color) => {
      return tinycolor
        .mostReadable(color, ['#fff', '#000'], {
          includeFallbackColors: true,
        })
        .toString();
    });
  });
}
