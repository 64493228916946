<template>
  <Fragment>
    <StatCard
      v-for="{ name, key } in HIGHLIGHT_FIELDS"
      :key="key"
      class="span-3"
      :title="name"
      :is-loading="isLoading"
      :badge="highlights[`${key}Ctr`]"
      :badge-color="highlights[`${key}CtrColor`]"
    >
      {{ highlights[key] | numberFormatter(2) }}
    </StatCard>
  </Fragment>
</template>

<script>
import { toRefs } from 'vue';
import { Fragment } from '@campsite-bio/component-lib';

import { StatCard } from '../';
import { useReportHighlights } from '../compositions/use-report-highlights';

const HIGHLIGHT_FIELDS = [
  { name: 'Views', key: 'total_views' },
  { name: 'Unique Views', key: 'unique_views' },
  { name: 'Sessions', key: 'total_sessions' },
  { name: 'Clicks', key: 'clicks' },
  { name: 'Events', key: 'events' },
  { name: 'Exits', key: 'exits' },
  {
    name: 'New Visitors',
    key: 'new_visitors',
  },
  {
    name: 'Returning Visitors',
    key: 'return_visitors',
  },
];

export default {
  components: {
    Fragment,
    StatCard,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const { data } = toRefs(props);

    const highlights = useReportHighlights(HIGHLIGHT_FIELDS, data, 'all_views');

    return {
      HIGHLIGHT_FIELDS,
      highlights,
    };
  },
};
</script>

<style lang="scss" scoped></style>
