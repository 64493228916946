import Vue from 'vue';
import Toast from '../components/toast.vue';
import vuetify from '../plugins/vuetify';

const colors = ['success', 'info', 'error'];

const defaultOptions = {
  text: '',
  color: 'info',
  timeout: 6000,
  dismissible: true,
  multiline: true,
};

let toastCmp = null;

function createToastCmp() {
  const cmp = new Vue({
    vuetify,
    render: (h) => h(Toast),
  });

  document.body.appendChild(cmp.$mount().$el);

  return cmp.$children[0];
}

function getToastCmp() {
  if (!toastCmp) toastCmp = createToastCmp();

  return toastCmp;
}

function show(options = {}) {
  getToastCmp().show({ ...defaultOptions, ...options });
}

function close() {
  getToastCmp().close();
}

function queue(toast) {
  let options = {
    text: toast.text,
  };
  if (toast.color) options.color = toast.color;
  let opts = {};
  if (toast.options) opts = toast.options;
  show({ ...opts, ...options });
}

function createShorthands() {
  const shorthands = {};

  colors.forEach(
    (color) =>
      (shorthands[color] = (text, options = {}) => {
        queue({ color, text, options });
      }),
  );

  return shorthands;
}

export default {
  show,
  close,
  getToastCmp,
  defaultOptions,
  ...createShorthands(),
};
