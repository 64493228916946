import { extend, setInteractionMode } from 'vee-validate';
import {
  required,
  email,
  min,
  min_value,
  max,
  max_value,
  is,
  regex,
  length,
} from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';

setInteractionMode('eager');

import {
  usernameRemote,
  emailRemote,
  subdomainRemote,
  customDomainRemote,
  validateHtml,
  url,
  validateEmbedType,
  eventTag,
  minArrayLength,
} from './custom-rules';
import { linkUrl } from '../components/links';
import { wizardUrl } from '../components/make-my-bio-link';

// Add the rules you need.
extend('regex', { ...regex, message: messages['regex'] });
extend('email', { ...email, message: messages['email'] });
extend('is', { ...is, message: messages['is'] });
extend('required', { ...required, message: messages['required'] });
extend('min', { ...min, message: messages['min'] });
extend('max', { ...max, message: messages['max'] });
extend('min_value', { ...min_value, message: messages['min_value'] });
extend('max_value', { ...max_value, message: messages['max_value'] });
extend('length', { ...length, message: messages['length'] });

// add rules
extend(usernameRemote.name, usernameRemote);
extend(emailRemote.name, emailRemote);
extend(subdomainRemote.name, subdomainRemote);
extend(customDomainRemote.name, customDomainRemote);
extend(validateHtml.name, validateHtml);
extend(url.name, url);
extend(validateEmbedType.name, validateEmbedType);
extend(eventTag.name, eventTag);
extend(linkUrl.name, linkUrl);
extend(wizardUrl.name, wizardUrl);
extend(minArrayLength.name, minArrayLength);
