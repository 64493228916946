import { computed, ref } from 'vue';
import { getMeta } from '@/utils';

/**
 *
 * @param {String} name
 * @param {Object} options
 * @returns Value of the meta field
 */
export function useMeta(name, options = {}) {
  const { defaultValue, itemRef, returnRef = false } = options;

  const metaValue = computed(() => {
    const item = itemRef.value || itemRef;
    return getMeta(name, defaultValue, item.meta);
  });

  const value = ref(metaValue.value);

  return returnRef ? value : metaValue;
}
