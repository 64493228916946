<template>
  <Alert style="margin: 1rem 0" variant="outline">
    <InfoIcon slot="icon" />
    <VText>
      To access {{ name }} you must have the
      <strong>Analytics add-on</strong> added to your subscription.
    </VText>
    <Button slot="actions" variant="flat" to="/account/change-plan"
      >Upgrade</Button
    >
  </Alert>
</template>

<script>
import { Alert, InfoIcon, VText, Button } from '@campsite-bio/component-lib';

export default {
  components: {
    Alert,
    InfoIcon,
    VText,
    Button,
  },

  props: {
    name: {
      type: String,
      default: 'reports',
    },
  },
};
</script>

<style lang="scss" scoped></style>
