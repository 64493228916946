<template>
  <ThemeProvider
    :theme="localTheme"
    class="divider-style"
    v-bind="$attrs"
    :style="styles"
    :class="classes"
    inject-locally
  >
    <input
      :id="inputId"
      class="divider-style__input sr-only"
      type="radio"
      :value="inputValue"
      :checked="isChecked"
      :disabled="disabled"
      :name="inputName"
      @change="handleClick"
    />
    <label class="divider-style__label" :for="inputId">
      <span class="divider-style__hr" :class="hrClasses">
        <ProfileDivider
          :link="{
            label: 'small',
          }"
          :variant="inputValue"
        />
        <CheckIcon class="divider-style__check" />
      </span>
      <span class="divider-style__label-text">
        <slot />
        <Badge v-if="showProBadge" class="divider-style__badge" content="Pro" />
      </span>
    </label>
  </ThemeProvider>
</template>

<script>
import {
  useGroupItem,
  CheckIcon,
  useTheme,
  Badge,
  ProfileDivider,
  ThemeProvider,
  createTheme,
} from '@campsite-bio/component-lib';
import { computed, provide, toRefs } from 'vue';
import uniqueId from 'lodash/uniqueId';
import tinycolor from 'tinycolor2';

import config from '../../../config';
import { useCurrentProfile } from '../../../compositions';

export default {
  components: {
    CheckIcon,
    Badge,
    ProfileDivider,
    ThemeProvider,
  },

  props: {
    inputValue: {
      type: [Number, String],
      default: null,
    },

    color: {
      type: String,
      default: '#222',
    },

    disabled: Boolean,
    showProBadge: Boolean,
  },

  setup(props, { emit }) {
    const { inputValue, disabled, color } = toRefs(props);
    const theme = useTheme();
    const { profile } = useCurrentProfile();
    provide('profile', profile);

    const inputId = computed(() => uniqueId('divider-style-'));

    const { isInGroup, isSelected, inputName, onToggle } = useGroupItem(
      inputValue,
      'style-group',
    );

    const isChecked = computed(() => {
      return isSelected.value;
    });

    const localTheme = computed(() => {
      return createTheme({
        profile: {
          ...theme.value.profile,
          dividerColor: color.value,
        },
      });
    });

    const classes = computed(() => {
      return {
        'divider-style--disabled': disabled.value,
      };
    });

    const hrClasses = computed(() => {
      return {
        [`divider-style__hr--default`]: !inputValue.value,
        [`divider-style__hr--${inputValue.value}`]: true,
      };
    });

    const styles = computed(() => {
      const primaryColor = tinycolor(theme.value.colors.primary);

      return {
        '--c-active-color': primaryColor.setAlpha(0.5).toRgbString(),
      };
    });

    function handleClick(e) {
      if (disabled.value) return;

      emit('click', e);

      if (isInGroup) onToggle(inputValue.value);
    }

    return {
      classes,
      hrClasses,
      isChecked,
      inputId,
      inputName,
      styles,
      config,
      handleClick,
      localTheme,
    };
  },
};
</script>

<style lang="scss" scoped>
.divider-style {
  --wrapper-height: 50px;
  text-align: center;

  &--disabled {
    opacity: 0.75;
    pointer-events: none;
  }

  &__input:checked + .divider-style__label,
  &__input:focus + .divider-style__label,
  &__label:hover {
    &:before {
      border-color: var(--c-active-color);
    }
  }

  &__input:checked + .divider-style__label {
    .divider-style__check {
      opacity: 1;
    }
  }

  &__label {
    display: block;
    position: relative;
    padding: 0 0.25rem;

    &:before {
      content: '';
      border: 0.125rem solid transparent;
      border-radius: var(--g-border-radius-standard);
      border-radius: min(
        var(--g-border-radius-standard),
        var(--g-border-radius-min)
      );
      position: absolute;
      left: 0;
      top: 0;
      height: var(--wrapper-height);
      width: 100%;
      transition: var(--g-transition-speed);
    }
  }

  &__check {
    opacity: 0;
    position: absolute;
    right: 0.075rem;
    top: 0.15rem;
  }

  &__hr {
    align-items: center;
    display: flex;
    font-size: 1.5rem;
    height: var(--wrapper-height);
    position: relative;
    overflow: hidden;

    &--vertical,
    &--default {
      justify-content: center;
    }

    &::v-deep .site__divider {
      margin: 0;
    }
  }

  &__badge {
    margin-left: 0.35rem;
  }

  &__label-text {
    align-items: center;
    display: inline-flex;
    font-weight: 600;
    margin-top: 0.5rem;
    font-size: 0.825rem;
  }
}
</style>
