import { ref } from 'vue';

const DISMISSED_KEY = 'dismissedKeys';

/**
 * Returns a ref that is true if the key is present in the storage
 * @param {String} key
 * @param {Object} {storage = 'local'}
 * @returns {Object} { dismissed, dismiss }
 */
export function useDismissable(key, options = {}) {
  const { storage = 'local' } = options;

  // Throw error if storage is not local or session
  if (storage !== 'local' && storage !== 'session') {
    throw new Error('Storage must be either local or session');
  }

  const db = window[storage + 'Storage'];

  const dismissedKeys = db[DISMISSED_KEY] ? JSON.parse(db[DISMISSED_KEY]) : [];
  const dismissed = ref(dismissedKeys.includes(key));

  function dismiss() {
    db.setItem(DISMISSED_KEY, JSON.stringify([...dismissedKeys, key]));
    dismissed.value = true;
  }

  return {
    dismissed,
    dismiss,
  };
}
