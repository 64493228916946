<template>
  <Card>
    <CardTitle>
      <VText component="h2" variant="h4">Instagram Authorization</VText>
    </CardTitle>
    <Divider color="lightgray" />
    <CardText>
      <slot>
        <VText
          >We need access to your Instagram to use your photos and enable the
          upcoming post feature. Click authorize to get started.</VText
        >
      </slot>
    </CardText>
    <CardActions>
      <Button v-if="showCancel" variant="flat" @click="$emit('cancel')"
        >Cancel</Button
      >
      <AuthorizeInstagramBtn color="success" variant="flat" />
    </CardActions>
  </Card>
</template>

<script>
import {
  Card,
  CardTitle,
  CardText,
  CardActions,
  Button,
  VText,
  Divider,
} from '@campsite-bio/component-lib';

import AuthorizeInstagramBtn from './authorize-instagram-btn';

export default {
  components: {
    Card,
    CardTitle,
    CardText,
    CardActions,
    Button,
    Divider,
    VText,
    AuthorizeInstagramBtn,
  },

  props: {
    showCancel: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
