<template>
  <ListItem :value="value">
    <template #default="{ active }">
      <ListItemAction>
        <Checkbox :input-value="active" />
      </ListItemAction>
      <ListItemContent>
        <ListItemTitle>{{ link.label }}</ListItemTitle>
        <ListItemSubtitle>{{ link.url }}</ListItemSubtitle>
      </ListItemContent>
      <ListItemIcon v-if="showIcon">
        <component :is="getLinkIconByType(link.type)" />
      </ListItemIcon>
      <ListItemAction v-if="labels.length > 1">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <IconButton v-bind="attrs" @click.stop="swap" v-on="on">
              <ExchangeIcon title="swap" />
            </IconButton>
          </template>
          <span>Swap labels between {{ labels.length }} results</span>
        </v-tooltip>
      </ListItemAction>
    </template>
  </ListItem>
</template>

<script>
import { computed, ref } from 'vue';
import {
  ListItem,
  ListItemTitle,
  ListItemAction,
  ListItemContent,
  ListItemSubtitle,
  ListItemIcon,
  Checkbox,
  IconButton,
  ExchangeIcon,
} from '@campsite-bio/component-lib';
import { LINK_ICONS, getLinkIconByType } from '../../links';

export default {
  components: {
    ListItem,
    ListItemTitle,
    ListItemAction,
    ListItemContent,
    ListItemSubtitle,
    ListItemIcon,
    Checkbox,
    IconButton,
    ExchangeIcon,
    ...LINK_ICONS,
  },

  props: {
    link: {
      type: Object,
      required: true,
    },

    value: {
      type: [String, Number],
      required: true,
    },

    showIcon: Boolean,
  },

  setup(props, { emit }) {
    const currentIndex = ref(0);

    const labels = computed(() => {
      return props.link.alt_labels || [];
    });

    function swap() {
      currentIndex.value =
        currentIndex.value === labels.value.length - 1
          ? 0
          : currentIndex.value + 1;
      emit('input', {
        ...props.link,
        label: labels.value[currentIndex.value],
      });
    }

    return { swap, labels, getLinkIconByType };
  },
};
</script>

<style lang="scss" scoped></style>
