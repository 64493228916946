<template>
  <ValidationObserver ref="form" slim>
    <form action="#" novalidate @submit.prevent="save">
      <CardText>
        <Alert v-if="item.disabled" color="info" style="margin-bottom: 1rem">
          <InfoIcon slot="icon" />
          This integration is disabled
        </Alert>
        <Alert v-else color="success" style="margin-bottom: 1rem">
          <CheckIcon slot="icon" />
          Connected to
          {{ item.provider_title }}
        </Alert>

        <FormGroup>
          <Label for="integration-title">Add a title</Label>
          <Input
            id="integration-title"
            v-model="title"
            :placeholder="`e.g. Main ${item.provider_title} Account`"
          />
          <FormGroupHelp>Only you can see this title.</FormGroupHelp>
        </FormGroup>

        <template v-if="isOpen && !item.disabled">
          <SquareFields
            v-if="item.provider === 'square'"
            v-model="locationId"
            :auth-id="item._id.$oid"
          />
          <InstagramBusinessFields
            v-if="item.provider === 'facebook'"
            v-model="accounts"
            :auth-id="item._id.$oid"
            :profile-id="item.campsite_id.$oid"
          />
        </template>
      </CardText>

      <CardActions>
        <IconButton aria-label="Delete" color="error" @click="$emit('remove')">
          <TrashIcon size=".9em" />
        </IconButton>

        <FlexSpacer />

        <Button
          variant="flat"
          color="success"
          :loading="isSaving"
          :disabled="isDeleting || item.disabled"
          type="submit"
          >Save</Button
        >
      </CardActions>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  CardText,
  CardActions,
  Button,
  IconButton,
  FormGroup,
  FormGroupHelp,
  Input,
  Label,
  Alert,
  CheckIcon,
  InfoIcon,
  TrashIcon,
  FlexSpacer,
} from '@campsite-bio/component-lib';
import { ref, watch } from 'vue';
import { ValidationObserver } from 'vee-validate';

import { useStore } from '../../../compositions';
import { localAxios } from '../../../apis';
import toast from '../../../utils/toast';
import { InstagramBusinessFields, SquareFields } from './provider-fields';

export default {
  components: {
    CardText,
    CardActions,
    Button,
    IconButton,
    FormGroup,
    FormGroupHelp,
    Input,
    Label,
    Alert,
    CheckIcon,
    InfoIcon,
    TrashIcon,
    FlexSpacer,
    ValidationObserver,
    InstagramBusinessFields,
    SquareFields,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },

    isDeleting: Boolean,
    isOpen: Boolean,
  },

  setup(props, { emit }) {
    const isSaving = ref(false);
    const store = useStore();
    const form = ref(null);
    const title = ref(null);
    const locationId = ref(null);
    const accounts = ref(null);

    watch(
      () => props.item,
      (item) => {
        title.value = item.title;
        locationId.value = item?.data?.location_id;
        accounts.value = Array.isArray(item?.accounts)
          ? [...item.accounts]
          : [];
      },
      { immediate: true },
    );

    async function save() {
      const success = await form.value.validate();
      if (!success) return;

      isSaving.value = true;

      try {
        const { data } = await localAxios.put(
          `/api/profiles/authorizations/${props.item.campsite_id.$oid}/${props.item._id.$oid}`,
          {
            title: title.value,
            location_id: locationId.value,
            accounts: accounts.value,
          },
        );
        store.commit('profiles/updateAuthorization', data);

        emit('save', data);
      } catch (e) {
        console.error(e);
        const { error_message } = e.response?.data || {};
        toast.error(
          error_message
            ? error_message
            : 'There was an error trying to save your changes.',
        );
      }

      isSaving.value = false;
    }

    return {
      form,
      save,
      isSaving,
      title,
      locationId,
      accounts,
    };
  },
};
</script>

<style lang="scss" scoped></style>
