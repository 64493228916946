<template>
  <div>
    <template v-if="!hasAPIKey">
      <ValidationProvider rules="required" name="audience" slim>
        <Alert style="margin-bottom: 1rem">
          <InfoIcon slot="icon" />
          We can't find an ActiveCampaign API key and API Url for your profile.
          <Button
            slot="actions"
            :to="`/profile/${profileId}/settings#integrations`"
            variant="flat"
            color="white"
          >
            Add&nbsp;<span style="font-size: 1.25em">→</span>
          </Button>
        </Alert>
      </ValidationProvider>
    </template>

    <template v-else>
      <FormGroup>
        <Label component="span" block gutter-bottom>Select a type</Label>
        <ButtonGroup v-model="variant">
          <Button :value="null">List</Button>
          <Button value="tag">Tag</Button>
        </ButtonGroup>
      </FormGroup>
      <ValidationProvider
        v-slot="{ errors, ariaInput, ariaMsg }"
        rules="required"
        name="audience"
        slim
      >
        <template v-if="variant === 'tag'">
          <FormGroup>
            <FormGroupHeader gutter-bottom>
              <Label :for="'active_campaign_list_id' + _uid"
                >ActiveCampaign Tag</Label
              >
              <FlexSpacer />
              <v-tooltip top>
                <template #activator="{ on }">
                  <QuestionCircleOIcon style="font-size: 20px" v-on="on" />
                </template>
                <span>Choose the tag that will be added to this user</span>
              </v-tooltip>
            </FormGroupHeader>
            <Select
              :id="'active_campaign_list_id' + _uid"
              v-model="listId"
              v-bind="ariaInput"
            >
              <option :value="null">-- Select a tag --</option>
              <option v-for="{ id, tag } in lists" :key="id" :value="id">
                {{ tag }}
              </option>
            </Select>
            <FormGroupHelp color="error" v-bind="ariaMsg">{{
              errors[0]
            }}</FormGroupHelp>
            <FormGroupHelp> Add a tag to the new contact </FormGroupHelp>
            <FormGroupHelp v-if="lists === null && !isLoadingLists">
              No tags have been returned with the provided API key. Double check
              it on
              <router-link :to="`/profile/${profileId}/settings`"
                >the settings page</router-link
              >.
            </FormGroupHelp>
          </FormGroup>
        </template>
        <template v-else>
          <FormGroup>
            <FormGroupHeader gutter-bottom>
              <Label :for="'active_campaign_list_id' + _uid"
                >ActiveCampaign List</Label
              >
              <FlexSpacer />
              <v-tooltip top>
                <template #activator="{ on }">
                  <QuestionCircleOIcon style="font-size: 20px" v-on="on" />
                </template>
                <span>Choose the list sign ups will be added to</span>
              </v-tooltip>
            </FormGroupHeader>
            <Select
              :id="'active_campaign_list_id' + _uid"
              v-model="listId"
              v-bind="ariaInput"
            >
              <option :value="null">-- Select a list --</option>
              <option v-for="{ id, name } in lists" :key="id" :value="id">
                {{ name }}
              </option>
            </Select>
            <FormGroupHelp color="error" v-bind="ariaMsg">{{
              errors[0]
            }}</FormGroupHelp>
            <FormGroupHelp>
              Add a new contact directly to a list
            </FormGroupHelp>
            <FormGroupHelp v-if="lists === null && !isLoadingLists">
              No lists have been returned with the provided API key. Double
              check it on
              <router-link :to="`/profile/${profileId}/settings`"
                >the settings page</router-link
              >.
            </FormGroupHelp>
          </FormGroup>
        </template>
      </ValidationProvider>
    </template>
  </div>
</template>

<script>
import {
  FormGroup,
  FormGroupHeader,
  FormGroupHelp,
  Label,
  FlexSpacer,
  QuestionCircleOIcon,
  Alert,
  InfoIcon,
  Button,
  Select,
  ButtonGroup,
} from '@campsite-bio/component-lib';
import { computed, onMounted, ref, toRefs, watch, watchEffect } from 'vue';
import { ValidationProvider } from 'vee-validate';
import axios from 'axios';

import { useCurrentProfile, useStore } from '../../../../compositions';
import { getOptInLists } from '../../../../apis';
import toast from '../../../../utils/toast';
import { getMeta } from '../../../../utils';

export default {
  components: {
    FormGroup,
    FormGroupHeader,
    FormGroupHelp,
    Label,
    FlexSpacer,
    QuestionCircleOIcon,
    ValidationProvider,
    Alert,
    InfoIcon,
    Button,
    Select,
    ButtonGroup,
  },

  props: {
    link: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { link } = toRefs(props);
    const lists = ref(null);
    const listId = ref(null);
    const variant = ref(null);
    const cancelSource = ref(null);
    const isLoadingLists = ref(true);

    const { profile, id: profileId } = useCurrentProfile();

    const emailOptions = computed(() => {
      return link.value.email_options;
    });

    watch(
      emailOptions,
      ({ destination_id, variant: emailOptionVariant }) => {
        listId.value = destination_id;
        variant.value = emailOptionVariant;
      },
      { immediate: true },
    );

    const hasAPIKey = computed(() => {
      return (
        !!getMeta('active_campaign_api_key', null, profile.value.meta) &&
        !!getMeta('active_campaign_api_url', null, profile.value.meta)
      );
    });

    const title = computed(() => {
      if (!listId.value || !lists.value) return null;
      const list = lists.value.find((l) => l.id === listId.value);
      return list.name || list.tag;
    });

    async function loadLists() {
      isLoadingLists.value = true;
      lists.value = null;

      if (cancelSource.value) cancelSource.value.abort();
      cancelSource.value = new AbortController();

      try {
        const { data } = await getOptInLists(
          profileId.value,
          'active_campaign',
          {
            params: {
              type: variant.value,
            },
            signal: cancelSource.value.signal,
          },
        );

        lists.value = data.lists;
      } catch (e) {
        if (axios.isCancel(e) || e.name === 'AbortError') return;
        const { error_message } = e.response?.data || {};
        console.error(e);
        toast.error(
          error_message ||
            'There was an error trying to get your ActiveCampaign data. Please try again soon.',
        );
      }

      isLoadingLists.value = false;
    }

    onMounted(() => {
      if (hasAPIKey.value) loadLists();
    });

    watch(variant, () => {
      if (hasAPIKey.value) loadLists();
    });

    watch(listId, () => {
      emit('change', {
        id: listId.value,
        variant: variant.value,
        title: title.value,
      });
    });

    return {
      hasAPIKey,
      lists,
      listId,
      variant,
      isLoadingLists,
      profileId,
    };
  },
};
</script>

<style lang="scss" scoped></style>
