<template>
  <Select v-model="localValue" v-bind="$attrs">
    <option :value="null">- Select a country</option>
    <option
      v-for="country in countries"
      :key="country.code"
      :value="country.code"
      :disabled="!country.code"
    >
      {{ country.name }}
    </option>
  </Select>
</template>

<script>
import { Select } from '@campsite-bio/component-lib';
import { computed, onMounted, ref, toRefs, watch } from 'vue';
import { useModel } from '../../compositions';
import toast from '../../utils/toast';

export default {
  components: {
    Select,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const allCountries = ref([]);

    const localValue = useModel(value, (val) => emit('input', val));
    const countries = computed(() => {
      const localCountries = [...allCountries.value].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      if (localCountries.length === 0) return localCountries;

      const usIndex = localCountries.findIndex(
        (country) => country.code === 'US',
      );
      const us = localCountries.splice(usIndex, 1)[0];
      localCountries.splice(0, 0, ...[us, { name: '---------' }]);
      return localCountries;
    });

    async function load() {
      try {
        const data = await import('../../data/countries.json');
        allCountries.value = data.countries;
      } catch (e) {
        console.error(e);
        toast.error('There was an error trying to load the countries.');
      }
    }

    watch([localValue, allCountries], () => {
      if (localValue.value && allCountries.value.length) {
        const country = allCountries.value.find(
          (country) => country.code === localValue.value,
        );

        emit('input:country', country || null);
      } else if (!localValue.value) {
        emit('input:country', null);
      }
    });

    onMounted(() => {
      load();
    });

    return {
      localValue,
      countries,
    };
  },
};
</script>

<style scoped></style>
