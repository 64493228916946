<template>
  <Fragment>
    <CardText>
      <VText gutter-bottom
        >Transactions will incur fees from both PayPal and Campsite.bio.</VText
      >
      <VText gutter-bottom>
        <strong>PayPal Fees:</strong><br />
        <a
          href="https://www.paypal.com/webapps/mpp/merchant-fees"
          target="_blank"
          >PayPal fees</a
        >
        are based on your PayPal account, region and currencies accepted.
      </VText>
      <VText>
        <strong>Campsite.bio Fees:</strong><br />
        Pro+ - 0.5%<br />
        Pro - 2%<br />
        Free - 8%
      </VText>
    </CardText>
    <CardActions>
      <Button variant="flat" @click="next">Next</Button>
    </CardActions>
  </Fragment>
</template>

<script>
import {
  CardText,
  VText,
  CardActions,
  Button,
} from '@campsite-bio/component-lib';
import { inject } from 'vue';
import { Fragment } from 'vue-fragment';

export default {
  components: {
    CardText,
    CardActions,
    VText,
    Button,
    Fragment,
  },

  setup() {
    const { handleStepChange } = inject('multi-step-dialog');

    function next() {
      handleStepChange('paypal-auth');
    }

    return { next };
  },
};
</script>

<style lang="scss" scoped></style>
