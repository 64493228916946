<template>
  <LinkExpander
    :id="`default-${linkId}`"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template slot="header">
      Carousel settings
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <IconButton
            href="https://support.campsite.bio/en/articles/6830835-adding-a-carousel-link"
            target="_blank"
            aria-label="Carousel setup help"
            size="1.25rem"
            v-bind="attrs"
            v-on="on"
          >
            <QuestionCircleOIcon />
          </IconButton>
        </template>
        <span>Get some help setting up your carousel link</span>
      </v-tooltip>
    </template>

    <FormGroup style="margin-top: 1.5rem">
      <Label component="span" gutter-bottom>Banner height</Label>
      <StyleGroup v-model="heightLocal" aria-label="Carousel height">
        <CarouselStyle variant="short" input-value="50">Short</CarouselStyle>
        <CarouselStyle input-value="69">Default</CarouselStyle>
        <CarouselStyle variant="square" input-value="100">Square</CarouselStyle>
      </StyleGroup>
    </FormGroup>

    <FormGroup>
      <Toggle v-model="isRandomLocal">Randomize carousel order</Toggle>
      <FormGroupHelp
        >Shuffle around your carousel banners on each page load</FormGroupHelp
      >
    </FormGroup>

    <VText component="h3" variant="h5">Controls</VText>
    <VText color="gray600" gutter-bottom style="font-size: 0.85rem"
      >Controls help make your carousel more accessible. Change the
      <router-link :to="`/profile/${profileId}/settings/appearance#carousel`"
        >control styles</router-link
      >
      on the appearance page.</VText
    >

    <FormGroup>
      <Toggle v-model="hideArrowsLocal">Hide control arrows</Toggle>
    </FormGroup>

    <FormGroup>
      <Toggle v-model="hideDotsLocal">Hide control dots</Toggle>
    </FormGroup>
  </LinkExpander>
</template>

<script>
import {
  QuestionCircleOIcon,
  IconButton,
  Label,
  FormGroup,
  FormGroupHelp,
  Toggle,
  VText,
} from '@campsite-bio/component-lib';
import debounce from 'lodash/debounce';
import uniqueId from 'lodash/uniqueId';
import { ref, watch } from 'vue';

import LinkExpander from '../expand/link-expander';
import { StyleGroup, CarouselStyle } from '../../appearance/components';
import { useCurrentProfile } from '../../../compositions';

export default {
  components: {
    LinkExpander,
    QuestionCircleOIcon,
    IconButton,
    Label,
    FormGroup,
    FormGroupHelp,
    Toggle,
    VText,
    StyleGroup,
    CarouselStyle,
  },

  props: {
    isOpen: Boolean,

    linkId: {
      type: String,
      required: true,
    },

    carouselOptions: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const id = ref(uniqueId('carousel'));

    const { id: profileId } = useCurrentProfile();

    const isRandomLocal = ref(null);
    const hideArrowsLocal = ref(false);
    const hideDotsLocal = ref(null);
    const heightLocal = ref(null);

    watch(
      () => props.carouselOptions,
      ({ is_carousel_random, hide_arrows, hide_dots, height_ratio }) => {
        isRandomLocal.value = is_carousel_random;
        hideArrowsLocal.value = hide_arrows;
        hideDotsLocal.value = hide_dots;
        heightLocal.value = height_ratio;
      },
      { immediate: true },
    );

    async function save() {
      emit('save', {
        is_carousel_random: isRandomLocal.value,
        hide_arrows: hideArrowsLocal.value,
        hide_dots: hideDotsLocal.value,
        height_ratio: heightLocal.value,
      });
    }

    watch(
      [isRandomLocal, hideArrowsLocal, hideDotsLocal, heightLocal],
      debounce(save, 1000),
    );

    return {
      id,
      profileId,
      isRandomLocal,
      hideArrowsLocal,
      hideDotsLocal,
      heightLocal,
    };
  },
};
</script>

<style lang="scss" scoped></style>
