<template>
  <div v-if="shouldShow" class="intro-section">
    <div class="grid grid--container">
      <VText variant="h3" component="h2" style="margin-bottom: 1rem"
        >Your quick start guide</VText
      >
      <HomeTourCard v-if="!hasTakenTour" style="margin-bottom: 1rem" />
      <GettingStartedChecklist
        v-if="gettingStartedIsActive"
        v-bind="gettingStartedAttrs"
        v-on="gettingStartedOn"
      />
    </div>
  </div>
</template>

<script>
import { VText } from '@campsite-bio/component-lib';
import { computed } from 'vue';

import { GettingStartedChecklist, useGettingStarted } from '../getting-started';
import { useCurrentUser, useStore } from '../../compositions';
import { useHomeTour } from '../../tours/home';
import HomeTourCard from './home-tour-card';

export default {
  components: {
    VText,
    GettingStartedChecklist,
    HomeTourCard,
  },

  setup() {
    const store = useStore();

    const { hasTakenTour } = useHomeTour();
    const { createdAt } = useCurrentUser();

    const {
      isActive: gettingStartedIsActive,
      attrs: gettingStartedAttrs,
      on: gettingStartedOn,
    } = useGettingStarted();

    const hasNoCampsites = computed(() => {
      return store.getters.hasNoCampsites;
    });

    const shouldShow = computed(() => {
      return (
        (gettingStartedIsActive.value && !hasNoCampsites.value) ||
        !hasTakenTour.value
      );
    });

    return {
      gettingStartedIsActive,
      gettingStartedAttrs,
      gettingStartedOn,
      shouldShow,
      hasTakenTour,
    };
  },
};
</script>

<style lang="scss" scoped>
.intro-section {
  background: var(--g-color-gray-100);
  padding: 2rem 0;

  @media (min-width: 992px) {
    padding: 3rem 0;
  }

  .grid {
    max-width: 930px;
  }
}
</style>
