import { getField, updateField } from 'vuex-map-fields';

import { localAxios } from '../apis';

export default {
  namespaced: true,

  state: {
    products: [],
    cards: [],
    taxIds: [],
    defaultCard: null,
  },

  getters: {
    products: (state) => state.products,
    pro: (state) => state.products.find((p) => p.internal_id === 'pro'),
    proPlus: (state) =>
      state.products.find((p) => p.internal_id === 'pro_plus'),
    proSeats: (state) =>
      state.products.find((p) => p.internal_id === 'pro_seats'),
    memberSeats: (state) =>
      state.products.find((p) => p.internal_id === 'member_seats'),
    proProfiles: (state) =>
      state.products.find((p) => p.internal_id === 'pro_profiles'),
    analyticsSeats: (state) =>
      state.products.find((p) => p.internal_id === 'analytics'),
    orgPro: (state) => state.products.find((p) => p.internal_id === 'org_pro'),
    orgProPlus: (state) =>
      state.products.find((p) => p.internal_id === 'org_pro_plus'),
    getField,
  },

  mutations: {
    updateField,
  },

  actions: {
    async getProducts({ commit, getters }, { force } = {}) {
      if (!getters.products.length || force) {
        const { data } = await localAxios.get(`/api/subscriptions/products`);
        commit('updateField', {
          path: 'products',
          value: data,
        });
      } else {
        return Promise.resolve(getters.products);
      }
    },

    async proratePreview(
      { commit },
      { subscriptionId, cancelToken, ...postData },
    ) {
      const { data } = await localAxios.post(
        `/api/subscriptions/${subscriptionId}/prorate-preview`,
        postData,
        {
          cancelToken,
        },
      );

      return data;
    },

    async update({ commit }, { subscriptionId, ...postData }) {
      const { data } = await localAxios.put(
        `/api/subscriptions/${subscriptionId}`,
        postData,
      );

      return data;
    },

    async downgrade({ commit }, { subscriptionId, ...postData }) {
      const { data } = await localAxios.put(
        `/api/subscriptions/${subscriptionId}/downgrade`,
        postData,
      );

      return data;
    },

    async downgradeCancel({ commit }, subscriptionId) {
      const { data } = await localAxios.put(
        `/api/subscriptions/${subscriptionId}/downgrade-cancel`,
      );

      return data;
    },

    async changeSelectedProfile(
      { commit },
      { subscriptionId, selectedProfileId },
    ) {
      const { data } = await localAxios.put(
        `/api/subscriptions/${subscriptionId}/selected-profile`,
        {
          selected_profile_id: selectedProfileId,
        },
      );

      return data;
    },
  },
};
