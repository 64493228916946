import store from '../store';

export const INTERCOM_ID = 'h8fjo8n2';

function getUserData() {
  const user = store.getters.currentUser;

  if (store.getters.isLoggedIn) {
    return {
      name: user.first_name,
      email: user.email,
      created_at: user.created_at,
      user_id: user._id.$oid,
      user_hash: user.hash,
      plan: user.plan,
    };
  }

  return {};
}

export async function intercomBoot() {
  if (window.Intercom) {
    const intercomSettings = {
      app_id: INTERCOM_ID,
    };

    window.Intercom('boot', {
      ...intercomSettings,
      ...getUserData(),
    });
  }
}

export async function intercomUpdate(data) {
  if (window.Intercom) {
    window.Intercom('update', data || getUserData());
  }
}

export async function intercomShutdown() {
  if (window.Intercom) {
    window.Intercom('shutdown');
  }
}

export async function intercomTour(id) {
  if (window.Intercom) {
    window.Intercom('startTour', id);
  }
}

export async function intercomTrackEvent(name) {
  if (window.Intercom) {
    window.Intercom('trackEvent', name);
  }
}

export async function intercomShowNewMessage(message) {
  if (window.Intercom) {
    window.Intercom('showNewMessage', message);
  }
}
