<template>
  <Card v-if="show" color="primary">
    <CardTitle>
      <VText component="h3" variant="h4" color="white"
        >Welcome to Campsite.bio! 🥳</VText
      >
      <FlexSpacer />
      <IconButton
        size="1.25rem"
        aria-label="dismiss"
        color="white"
        @click="dismiss"
      >
        <CloseIcon />
      </IconButton>
    </CardTitle>
    <CardText>
      <VText color="white">
        We're so glad you signed up.<br />
        Let's take 30 seconds to show you around.
      </VText>
    </CardText>
    <CardActions align="left">
      <Button color="secondary" @click="runTour">Let's go!</Button>
    </CardActions>
  </Card>
</template>

<script>
import {
  Card,
  CardTitle,
  CardText,
  CardActions,
  Button,
  VText,
  IconButton,
  CloseIcon,
  FlexSpacer,
} from '@campsite-bio/component-lib';
import { ref } from 'vue';

import { useHomeTour } from '../../tours/home';

export default {
  components: {
    Card,
    CardTitle,
    CardText,
    CardActions,
    Button,
    VText,
    IconButton,
    CloseIcon,
    FlexSpacer,
  },

  setup() {
    const show = ref(true);
    const { run, dismiss } = useHomeTour();

    function runTour() {
      run();
      show.value = false;
    }

    return {
      show,
      runTour,
      dismiss() {
        show.value = false;
        dismiss();
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
