<template>
  <div class="add-link-wrapper" :class="classes">
    <div class="add-link-wrapper__btn">
      <v-menu v-model="isMenuOpen" bottom max-height="500">
        <template #activator="{ on, attrs }">
          <v-tooltip right>
            <template #activator="{ on: tooltipOn }">
              <button
                class="add-link-btn"
                type="button"
                :disabled="isAddingLink"
                v-bind="attrs"
                v-on="{ ...on, ...tooltipOn }"
                @mouseenter="active = true"
                @mouseleave="active = false"
              >
                <PlusIcon />
                <span class="sr-only">Add link here</span>
              </button>
            </template>
            <span>Add link here</span>
          </v-tooltip>
        </template>
        <LinkList show-link @click="$emit('click', $event)" />
      </v-menu>
    </div>
  </div>
</template>

<script>
import { PlusIcon } from '@campsite-bio/component-lib';

import { LinkList } from '../link-list';

export default {
  components: {
    LinkList,
    PlusIcon,
  },

  props: {
    disabled: Boolean,
  },

  data() {
    return {
      active: false,
      isMenuOpen: false,
    };
  },

  computed: {
    isAddingLink: {
      get() {
        return this.$store.getters.isAddingLink;
      },
      set(value) {
        this.$store.commit('setIsAddingLink', value);
      },
    },

    classes() {
      return {
        'add-link-wrapper--active': this.active,
        'add-link-wrapper--disabled': this.disabled,
      };
    },
  },

  watch: {
    isMenuOpen(newValue) {
      if (!newValue) this.active = false;
    },
  },

  methods: {
    hovered() {
      this.active = true;
    },

    leave() {
      if (!this.isMenuOpen) this.active = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-link-wrapper {
  height: 0.75rem;
  position: relative;
  z-index: 1;

  &:before {
    background-color: var(--g-color-primary);
    border-radius: var(--g-border-radius-standard);
    border-radius: min(
      var(--g-border-radius-standard),
      var(--g-border-radius-min)
    );
    content: '';
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    transition: all 250ms;
    width: 0;
  }

  &:focus-within {
    &:before {
      width: 100%;
    }
  }

  &--active {
    &:before {
      width: 100%;
    }
  }

  &--disabled {
    .add-link-btn {
      display: none;
    }
  }

  &__btn {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: 425px) {
      left: 0;
    }
  }
}

.add-link-btn {
  align-items: center;
  background: rgba(#fff, 0.75);
  border: 1px solid rgba(#ccc, 0.8);
  border-radius: var(--g-border-radius-standard);
  border-radius: min(
    var(--g-border-radius-standard),
    var(--g-border-radius-min)
  );
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
  height: 1.25rem;
  width: 1.25rem;
  justify-content: center;
  padding: 0;
  transition: all 250ms;
  opacity: 0.75;
  transform: scale(0.85);

  &:before {
    content: '';
    height: 160%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 180%;
  }

  &:hover,
  &:focus {
    background: #fff;
    border-color: #ccc;
    opacity: 1;
    transform: scale(1);
  }
}
</style>
