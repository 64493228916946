import { colors, useTheme } from '@campsite-bio/component-lib';
import { inject } from 'vue';
import { useApexOptions } from './';

export function useLeaderboardChartOptions() {
  const dataColors = [colors.goldLight, colors.silver, colors.bronze];
  const dateFormatter = inject('dateFormatter');
  const theme = useTheme();

  const chartOptions = useApexOptions({
    backgroundColor: dataColors,
    borderColor: dataColors,
    indexAxis: 'y',
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          color: theme.value.colors.gray100,
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          callback(value) {
            return dateFormatter(this.getLabelForValue(value));
          },
        },
        position: 'right',
      },
    },
    datalabels: { display: true },
  });

  return chartOptions;
}
