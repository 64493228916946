<template>
  <v-tooltip :disabled="!tooltip || disabled" bottom>
    <template #activator="{ on, attrs }">
      <div
        class="box-input"
        :class="classes"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="on"
      >
        <input
          :id="inputId"
          v-model="modelValue"
          :name="name"
          type="radio"
          class="box-input__radio"
          :value="inputValue"
          :disabled="disabled"
        />
        <label class="box-input__label" :for="inputId">
          <span v-if="$slots.icon" class="box-input__icon">
            <slot name="icon" />
          </span>
          <slot />
          <span v-if="label" class="box-input__title" :class="titleClasses">{{
            label
          }}</span>
        </label>
      </div>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    inputId: {
      type: String,
      required: true,
    },

    inputValue: {
      validator: (prop) =>
        typeof prop === 'string' || prop === null || typeof prop === 'number',
      required: true,
    },

    wrapperClass: { type: String, default: null },
    name: { type: String, default: null },
    value: { type: [String, Number], default: null },
    label: { type: [String, Number], default: null },
    labelPosition: { type: String, default: null },
    tooltip: { type: String, default: null },
    isLabelLight: Boolean,
    isCheckered: Boolean,
    isLabelBoxed: Boolean,
    isSmall: Boolean,
    isLarge: Boolean,
    isWide: Boolean,
    disabled: Boolean,
  },

  computed: {
    modelValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      },
    },

    classes() {
      return {
        'box-input--checkered': this.isCheckered,
        'box-input--small': this.isSmall,
        'box-input--large': this.isLarge,
        'box-input--wide': this.isWide,
        'box-input--disabled': this.disabled,
        [this.wrapperClass]: true,
      };
    },

    titleClasses() {
      return {
        'box-input__title--btm-right': this.labelPosition === 'bottom-right',
        'box-input__title--top-right': this.labelPosition === 'top-right',
        'box-input__title--boxed': this.isLabelBoxed,
        'box-input__title--light': this.isLabelLight,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../theme';

.box-input {
  padding: 0;
  position: relative;

  &--checkered {
    .box-input__label {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAIUlEQVQ4jWN49uzZf3yYEGAYNWBYGEBIASELRg0YFgYAABpE1q4luwPVAAAAAElFTkSuQmCC');
      background-size: 7px 7px;
    }
  }

  &--small {
    .box-input__label {
      height: 40px;
      width: 40px;

      .box-input__icon {
        font-size: 1.5rem;
      }
    }
  }

  &--large {
    .box-input__label {
      height: 120px;
      width: 120px;

      .box-input__icon {
        font-size: 3.125rem;
      }
    }
  }

  &--wide {
    .box-input__label {
      width: 140px;
    }
  }

  &--disabled {
    .box-input__label {
      cursor: not-allowed;
      opacity: 0.75;
    }
  }
}

.box-input__radio {
  @include sr-only;

  &:focus + .box-input__label {
    border-color: rgba($color-mine-shaft, 0.5);
    box-shadow: 0 0 3px 1px rgba($color-mine-shaft, 0.3);
  }

  &:checked + .box-input__label {
    border-color: $color-mine-shaft;
    transform: scale(0.98);
  }
}

.box-input__label {
  align-items: center;
  border-radius: var(--g-border-radius-standard);
  border-radius: min(
    var(--g-border-radius-standard),
    var(--g-border-radius-min)
  );
  border: 2px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: center;
  position: relative;
  overflow: hidden;
  text-align: center;
  transition: all 300ms;
  width: 70px;

  &:hover {
    border-color: rgba($color-mine-shaft, 0.5);
  }

  * {
    pointer-events: none;
  }
}

.box-input__title {
  font-size: 12px;
  line-height: 1;
  user-select: none;

  &--btm-right {
    font-size: 11px;
    bottom: 5px;
    position: absolute;
    right: 5px;
  }

  &--top-right {
    font-size: 11px;
    position: absolute;
    right: 5px;
    top: 5px;
  }

  &--light {
    color: #fff;
  }

  &--boxed {
    background: #fff;
    box-shadow: -1px -1px 0 rgba(0, 0, 0, 0.2);
    bottom: 0;
    font-size: 13px;
    padding: 2px 6px 4px;
    right: 0;

    &.box-input__title--top-right {
      box-shadow: -1px 1px 0 rgba(0, 0, 0, 0.2);
      bottom: auto;
      top: 0;
    }
  }
}

.box-input__icon {
  display: inline-flex;
  font-size: 2.5rem;
  left: 50%;
  max-width: 2.5rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  ::v-deep svg {
    max-width: inherit;
  }
}
</style>
