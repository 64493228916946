<template>
  <router-link
    :to="switchProfileUrl"
    class="profile-switch-btn"
    :aria-label="`Current profile: ${username}; Click to switch profiles`"
    :title="username"
  >
    <span class="profile-switch-btn__avatar">
      <Skeleton v-if="isLoading" height="28px" width="28px" circle />
      <Avatar v-else size="28">
        <img :src="encodedImageSrc" alt="" height="28" width="28" />
      </Avatar>
    </span>

    <span v-if="username || isLoading" class="profile-switch-btn__username">
      <Skeleton v-if="isLoading" height="16px" width="50px" />
      <template v-else>{{ username }}</template>
    </span>

    <ExchangeIcon title="Switch" />
  </router-link>
</template>

<script>
import {
  Avatar,
  Skeleton,
  ExchangeIcon,
  convertImageUrlToEncodedUrl,
  MEDIA_SIZES,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';

import { useRoute } from '../../compositions';

export default {
  components: {
    Avatar,
    Skeleton,
    ExchangeIcon,
  },

  props: {
    isLoading: Boolean,

    username: {
      type: String,
      default: null,
    },

    imageSrc: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    const route = useRoute();

    const switchProfileUrl = computed(() => {
      const returnUrl =
        route.value.path.indexOf('/profiles') === -1
          ? encodeURIComponent(route.value.path)
          : '';
      return `/profiles?returnUrl=${returnUrl}`;
    });

    const encodedImageSrc = computed(() => {
      return convertImageUrlToEncodedUrl(
        props.imageSrc,
        MEDIA_SIZES.profile_image,
      );
    });

    return {
      switchProfileUrl,
      encodedImageSrc,
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-switch-btn {
  align-items: center;
  background: none;
  border: 1px solid var(--g-color-gray-200);
  color: var(--g-color-gray-700);
  display: inline-flex;
  text-decoration: none;
  border-radius: var(--g-border-radius-standard);
  align-self: center;
  padding: 0.2rem 0.65rem;
  font-size: 1.15rem;
  gap: 0.4rem;

  &:hover,
  &:focus {
    background: var(--c-menu-active-color);
  }

  &__avatar {
    background: var(--g-color-gray-100);
    display: inline-flex;
    border-radius: 50%;
  }

  &__username {
    font-size: 0.85rem;
    margin-right: 0.25rem;
    overflow: hidden;
    max-width: 40px;
    text-overflow: ellipsis;

    @media (min-width: 375px) {
      max-width: 50px;
    }
  }
}
</style>
