<template>
  <Fragment>
    <EventsDowCard
      v-for="(value, key) in currentData"
      :key="key"
      class="span-4"
      :title="EVENT_TO_LABEL[key] || key"
      :data="value"
    />
  </Fragment>
</template>

<script>
import { computed } from 'vue';
import { EVENT_TO_LABEL, Fragment } from '@campsite-bio/component-lib';

import EventsDowCard from './events-dow-card';

export default {
  components: {
    Fragment,
    EventsDowCard,
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    // Group the data by event
    const currentData = computed(() => {
      return (
        props.data
          // .filter((v) => v.dimensions.range === 'current')
          .reduce((acc, e) => {
            const eventName = e.dimensions.event_name;
            if (acc[eventName]) acc[eventName].push(e);
            else acc[eventName] = [e];

            return acc;
          }, {})
      );
    });

    return {
      currentData,
      EVENT_TO_LABEL,
    };
  },
};
</script>
