<template>
  <ListItem component="button" type="button" @click="$emit('click', 'request')">
    <ListItemAction>
      <RequestIcon size="1.5rem" />
    </ListItemAction>
    <ListItemContent>
      <ListItemTitle>Request</ListItemTitle>
      <ListItemSubtitle wrap
        >Allow your followers to send you requests.</ListItemSubtitle
      >
    </ListItemContent>
  </ListItem>
</template>

<script>
import {
  ListItem,
  ListItemAction,
  ListItemContent,
  ListItemSubtitle,
  ListItemTitle,
  RequestIcon,
} from '@campsite-bio/component-lib';

export default {
  components: {
    ListItem,
    ListItemAction,
    ListItemContent,
    ListItemSubtitle,
    ListItemTitle,
    RequestIcon,
  },
};
</script>

<style lang="scss" scoped></style>
