<template>
  <div class="media-inner">
    <div
      v-infinite-scroll="nextPage"
      class="media-inner__content"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="20"
      infinite-scroll-immediate-check="false"
    >
      <v-media-custom-breadcrumbs
        v-if="id"
        v-model="parent"
        :path="path"
        category="canva"
        :media-parent-id="id"
        :organization-id="organizationId"
      />
      <Divider color="lightgray" style="margin: 9px 0 12px" />

      <Alert
        v-if="hasRestrictions"
        variant="outline"
        style="margin-bottom: 8px"
      >
        <InfoIcon slot="icon" />
        <template v-if="options.minWidth && options.minHeight"
          >Minimum image size: {{ options.minWidth }}px X
          {{ options.minHeight }}px</template
        >
        <template v-else-if="options.minWidth"
          >Minimum image size: {{ options.minWidth }}px wide</template
        >
        <template v-else-if="options.minHeight"
          >Minimum image size: {{ options.minHeight }}px height</template
        >
      </Alert>

      <v-file-preview
        v-for="(file, index) in pendingMedia"
        :key="index"
        :status="file.status"
        :is-loading="file.isLoading"
        :is-rejected="file.isRejected"
        :filename="file.filename"
        :image-url="file.imageUrl"
        @remove="removePendingMedia(file)"
      />

      <details class="canva-templates" open>
        <summary>
          <span>Canva Design Templates</span>
          <a
            href="https://support.campsite.bio/en/articles/6830573-designing-images-with-canva"
            title="Canva support article"
          >
            <span class="sr-only">support</span>
            <QuestionCircleOIcon />
          </a>
        </summary>
        <div>
          <v-canva-button
            v-if="!isFreePlan"
            design-type="InstagramPost"
            @publish="onCanvaPublish"
            >Instagram Post - 1080x1080</v-canva-button
          >
          <v-canva-button
            design-type="Poster"
            design-width="1300"
            design-height="900"
            @publish="onCanvaPublish"
            >Carousel - 1300x900</v-canva-button
          >
          <v-canva-button
            design-type="Poster"
            design-width="1300"
            design-height="650"
            @publish="onCanvaPublish"
            >Carousel (short) - 1300x650</v-canva-button
          >
          <v-canva-button
            design-type="Poster"
            design-width="1300"
            design-height="1300"
            @publish="onCanvaPublish"
            >Carousel (square) - 1300x1300</v-canva-button
          >
          <v-canva-button
            v-if="!isFreePlan"
            design-type="Poster"
            design-width="500"
            design-height="500"
            @publish="onCanvaPublish"
            >Favicon - 500x500</v-canva-button
          >
          <v-canva-button
            v-if="!isFreePlan"
            design-type="Poster"
            design-width="1200"
            design-height="630"
            @publish="onCanvaPublish"
            >Open Graph - 1200x630</v-canva-button
          >
        </div>
      </details>

      <div class="media-items-row row row--no-gutters">
        <div
          v-for="item in folders"
          :key="item._id.$oid"
          class="col col--xs-6 col--no-gutters"
        >
          <v-media-folder
            :name="item.filename"
            :checked="
              !!selectedFolder && selectedFolder._id.$oid == item._id.$oid
            "
            @change="select(item)"
            @click="parent = item._id.$oid"
          ></v-media-folder>
        </div>
        <div
          v-for="(item, index) in images"
          :key="item._id.$oid"
          class="col col--xs-6 col--no-gutters"
        >
          <v-media-item
            ref="mediaItem"
            :checked="!!selected && selected.url === item.url"
            :index="index"
            :url="getCroppedMediaUrl(item.url)"
            :alt="item.filename"
            :disabled="disableCheck(item)"
            @select="select(item)"
            @double-click="doubleClick(item, index)"
          ></v-media-item>
        </div>
      </div>

      <div v-show="loading" class="text--xs-center">
        <CircularLoader size="2.5rem" style="margin: 15px auto" />
      </div>
    </div>
    <div class="media-inner__actions">
      <Divider color="lightgray" />
      <CardActions>
        <Button
          v-show="selected || selectedFolder"
          variant="outline"
          color="error"
          :loading="isDeleting"
          @click="deleteMedia"
          >Delete</Button
        >
        <v-canva-button
          v-if="selected"
          :design-id="selected.canva_id"
          @publish="onCanvaPublish"
          >Edit</v-canva-button
        >
        <FlexSpacer />
        <Button :disabled="!selected" @click="save">Select</Button>
      </CardActions>
    </div>
  </div>
</template>

<script>
import {
  CardActions,
  Button,
  Divider,
  FlexSpacer,
  Alert,
  InfoIcon,
  CircularLoader,
  QuestionCircleOIcon,
  getCroppedMediaUrl,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';

import VMediaItem from './media-item.vue';
import VMediaFolder from './media-folder.vue';
import VMediaCustomBreadcrumbs from './media-custom-breadcrumbs.vue';
import toast from '@/utils/toast';
import bus from '@/bus';
import VCanvaButton from '../buttons/canva-button.vue';
import VFilePreview from './file-preview.vue';
import { uploadWithUrl } from '@/utils/media';
import { useCurrentOrg, useCurrentProfile, useRoute } from '@/compositions';

export default {
  components: {
    VMediaItem,
    VMediaFolder,
    VMediaCustomBreadcrumbs,
    VCanvaButton,
    VFilePreview,
    CardActions,
    Button,
    FlexSpacer,
    Alert,
    InfoIcon,
    Divider,
    CircularLoader,
    QuestionCircleOIcon,
  },

  props: {
    options: {
      type: Object,
      default: null,
    },

    show: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { id: profileId, isProfileOnFreePlan } = useCurrentProfile();
    const { id: organizationId } = useCurrentOrg();
    const route = useRoute();

    // Determine if we could be loading org media instead of profile media
    const isOnOrg = computed(() => {
      return route.value.fullPath.includes('organization/');
    });

    const id = computed(() => {
      return isOnOrg.value ? organizationId.value : profileId.value;
    });

    const isFreePlan = computed(() => {
      return isOnOrg.value ? false : isProfileOnFreePlan.value;
    });

    return { id, organizationId, isOnOrg, getCroppedMediaUrl, isFreePlan };
  },

  data() {
    return {
      imageUrl: '',
      loading: true,
      disableLoad: false,
      isDeleting: false,
      count: 20,
      page: 1,
      selected: undefined,
      selectedFolder: undefined,
      pendingMedia: [],
    };
  },

  computed: {
    media() {
      return this.$store.getters['canvaMedia/getField'](`media.${this.id}`);
    },

    path() {
      return this.$store.getters['canvaMedia/getField'](`mediaPath.${this.id}`);
    },

    folders() {
      return this.media?.filter((item) => item.type === 'folder');
    },

    images() {
      return this.media?.filter((item) => item.type === 'image');
    },

    parent: {
      get() {
        return this.$store.getters['canvaMedia/getField'](
          `mediaParent.${this.id}`,
        );
      },
      set(newValue) {
        this.$store.commit('canvaMedia/setMediaParent', {
          id: this.id,
          value: newValue,
        });
      },
    },

    busy() {
      return this.loading || this.disableLoad;
    },

    showMediaDialog() {
      return this.$store.getters.showMediaDialog;
    },

    hasRestrictions() {
      return this.options && (this.options.minWidth || this.options.minHeight);
    },
  },

  watch: {
    page() {
      this.load();
    },

    parent() {
      this.selectedFolder = undefined;
      this.selected = undefined;
      this.disableLoad = false;
      if (this.page === 1) this.load();
      this.page = 1;
    },

    show(newValue) {
      if (newValue) this.load();
    },

    showMediaDialog(newValue) {
      if (newValue && this.show) this.load();
    },
  },

  created() {
    this.load();
  },

  methods: {
    nextPage() {
      this.page++;
    },

    async load() {
      this.loading = true;

      try {
        const data = await this.$store.dispatch('canvaMedia/all', {
          page: this.page,
          pageSize: this.count,
          parent: this.parent,
          id: this.id,
          organization_id: this.isOnOrg ? this.organizationId : null,
        });

        if (!data.has_more) this.disableLoad = true;
        this.loading = false;
      } catch (e) {
        toast.error('There was an error getting your media files');
      }

      this.loading = false;
    },

    disableCheck(media) {
      if (
        this.options &&
        this.options.minWidth &&
        media.width < this.options.minWidth
      )
        return true;
      if (
        this.options &&
        this.options.minHeight &&
        media.height < this.options.minHeight
      )
        return true;
      return false;
    },

    // if the user is clicking the same image twice, select the image
    doubleClick(item, index) {
      if (this.$refs.mediaItem[index].checked) {
        // this.select(item);
        this.save();
      }
    },

    select(item) {
      switch (item.type) {
        case 'folder':
          this.selected = undefined;
          this.selectedFolder = item;
          break;

        default:
          this.selected = item;
          this.selectedFolder = undefined;
          break;
      }
    },

    getExistingMediaByCanvaId(canvaId) {
      return this.media.find((m) => m.canva_id === canvaId);
    },

    onCanvaPublish(props) {
      const media = this.getExistingMediaByCanvaId(props.options.designId);
      if (media) this.updateExistingMedia(media, props);
      else this.createMedia(props);
    },

    async updateExistingMedia(media, props) {
      const imageUrl = props.options.exportUrl;
      const tempMedia = {
        imageUrl: imageUrl,
        filename: media.filename,
        status: 'Updating media...',
        isLoading: true,
      };
      this.pendingMedia.push(tempMedia);
      try {
        const data = await this.$store.dispatch('media/getFilename');

        media.filename = `${data.filename}.${media.extension}`;
        this.upload(media, tempMedia);
      } catch (e) {
        console.log(e);
        this.uploadError(tempMedia);
      }
    },

    createMedia(props) {
      const imageUrl = props.options.exportUrl,
        canvaId = props.options.designId;
      const parsedUrl = new URL(imageUrl).pathname.split('/');
      const tempMedia = {
        imageUrl: imageUrl,
        filename: parsedUrl[parsedUrl.length - 1],
        status: 'Creating media...',
        isLoading: true,
      };
      this.pendingMedia.push(tempMedia);
      this.$store
        .dispatch('canvaMedia/create', {
          filename: tempMedia.filename,
          type: 'image/png',
          height: +props.designHeight,
          width: +props.designWidth,
          canvaId,
          parent: this.parent,
          id: this.id,
          organization_id: this.organizationId,
        })
        .then((media) => {
          tempMedia.media = media;
          tempMedia.status = 'Uploading file...';
          this.upload(media, tempMedia);
        })
        .catch((err) => {
          this.uploadError(tempMedia);
          console.log(err);
        });
    },

    async upload(media, tempMedia) {
      try {
        await uploadWithUrl(tempMedia.imageUrl, `media/${media.filename}`);

        this.completeMedia(media, tempMedia);
      } catch (e) {
        this.uploadError(tempMedia);
        console.log(e);
      }
    },

    completeMedia(media, tempMedia) {
      this.$store
        .dispatch('canvaMedia/update', {
          mediaId: media._id.$oid,
          id: this.id,
          organization_id: this.organizationId,
          data: {
            completed: true,
            filename: media.filename,
          },
        })
        .then((newMedia) => {
          setTimeout(() => {
            const foundMedia = this.getExistingMediaByCanvaId(media.canva_id);
            if (foundMedia) {
              this.$store.commit('canvaMedia/updateMediaItem', {
                id: this.id,
                item: newMedia,
              });
              bus.$emit('media-custom-updated', newMedia);
            } else
              this.$store.commit('canvaMedia/addMediaItem', {
                id: this.id,
                item: newMedia,
                end: false,
              });
            this.removePendingMedia(tempMedia);
          }, 5000);
        })
        .catch((err) => {
          this.uploadError(tempMedia);
          console.log(err);
        });
    },

    async deleteMedia() {
      let item = this.selected ? this.selected : this.selectedFolder,
        itemId = item._id.$oid;
      this.isDeleting = true;

      try {
        await this.$store.dispatch('canvaMedia/remove', {
          mediaId: item._id.$oid,
          id: this.id,
          organization_id: this.organizationId,
        });

        this.selected = undefined;
        this.selectedFolder = undefined;
        toast.success('Media item deleted', { timeout: 3000 });
        bus.$emit('media-custom-deleted', itemId);
      } catch (err) {
        console.log(err);
        toast.error('There was an error trying to delete your media item', {
          timeout: 3000,
        });
      }

      this.isDeleting = false;
    },

    save() {
      this.$emit('select', {
        source: 'custom',
        mediaId: this.selected._id.$oid,
        media: this.selected,
      });

      this.selected = undefined;
    },

    uploadError(tempMedia) {
      tempMedia.isRejected = true;
      tempMedia.status =
        'There was an unrecoverable error when trying to upload';
      toast.error(
        `There was an error trying to upload ${tempMedia.filename}. Please retry soon.`,
      );
    },

    removePendingMedia(tempMedia) {
      const index = this.pendingMedia.findIndex(
        (t) => t.filename === tempMedia.filename,
      );
      this.pendingMedia.splice(index, 1);
    },
  },
};
</script>

<style scoped></style>
