import { onUnmounted, ref } from 'vue';
import { noop } from '@vueuse/core';

import bus from '../bus';
import { useStore } from './use-store';

export function useAuthorizationDialog(options) {
  const isAuthorizing = ref(false);
  const { onCancel = noop, onSuccess = noop, onOpen = noop } = options;

  const store = useStore();

  function open(data) {
    // console.log('open', data);
    onOpen();
    isAuthorizing.value = true;
    store.dispatch('showAuthorizationDialog', data);
    addListeners();
  }

  function addListeners() {
    bus.$on('authorization-dialog:cancel', handleCancel);
    bus.$on('authorization-dialog:success', handleSuccess);
  }

  function handleSuccess(data) {
    if (!isAuthorizing.value) return;

    isAuthorizing.value = false;
    removeListeners();
    onSuccess(data);
  }

  function handleCancel() {
    isAuthorizing.value = false;
    removeListeners();
    onCancel();
  }

  function removeListeners() {
    bus.$off('authorization-dialog:cancel', onCancel);
    bus.$off('authorization-dialog:success', onSuccess);
  }

  onUnmounted(() => removeListeners());

  return {
    open,
    isAuthorizing,
  };
}
