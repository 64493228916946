<template>
  <ListItem component="button" type="button" @click="$emit('click', 'embed')">
    <ListItemAction>
      <EmbedOutlineIcon size="1.25rem" />
    </ListItemAction>
    <ListItemContent>
      <ListItemTitle>Embed</ListItemTitle>
      <ListItemSubtitle wrap
        >Embed Youtube, Vimeo, Facebook, Twitch, or Spotify.</ListItemSubtitle
      >
    </ListItemContent>
  </ListItem>
</template>

<script>
import {
  ListItem,
  ListItemAction,
  ListItemContent,
  ListItemSubtitle,
  ListItemTitle,
  EmbedOutlineIcon,
} from '@campsite-bio/component-lib';

export default {
  components: {
    ListItem,
    ListItemAction,
    ListItemContent,
    ListItemSubtitle,
    ListItemTitle,
    EmbedOutlineIcon,
  },
};
</script>

<style lang="scss" scoped></style>
