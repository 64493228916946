<template>
  <LinkWrapper v-bind="$props" @add-link="$emit('add-link', $event)">
    <LinkCard>
      <ValidationObserver ref="form" slim>
        <ValidationProvider
          v-slot="{ errors, ariaInput, ariaMsg }"
          :rules="labelRules"
          name="label"
          immediate
          slim
        >
          <LinkField>
            <EmojiInput
              ref="labelRef"
              v-model="label"
              class="js-link-label"
              border-color="gray200"
              :has-error="errors.length > 0"
              autocomplete="off"
              maxlength="80"
              placeholder="Add your link label"
              :show-emoji="isLargeScreen"
              :disabled="isReadonly"
              aria-label="Link label"
              v-bind="ariaInput"
              @focus="focusLink"
            >
              <IconButton
                slot="prepend"
                v-clipboard
                :data-clipboard-text="label"
                tabindex="-1"
                aria-label="Copy label"
              >
                <PencilIcon title="Copy label" />
              </IconButton>
            </EmojiInput>
            <FormGroupHelp color="error" v-bind="ariaMsg">{{
              errors[0]
            }}</FormGroupHelp>
          </LinkField>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, ariaInput, ariaMsg }"
          :rules="urlRules"
          name="url"
          immediate
          slim
        >
          <LinkField>
            <InputUrl
              v-model="url"
              label="Link URL"
              border-color="gray200"
              :has-error="errors.length > 0"
              :disabled="isReadonly"
              v-bind="ariaInput"
              @focus="focusLink"
            />
            <FormGroupHelp color="error" v-bind="ariaMsg">{{
              errors[0]
            }}</FormGroupHelp>
          </LinkField>
        </ValidationProvider>
      </ValidationObserver>

      <v-media-selector
        slot="media"
        v-model="mediaImage"
        :has-future-image="hasFutureImage"
        class="media-selector--post"
        :disabled="isReadonly"
        :loading="isSavingImage"
        @select="selectMedia"
        @remove="imageRemove"
      />

      <template slot="btm">
        <LinkBtn
          v-if="!isStatsHidden"
          :active="isExpanded('stats')"
          :aria-label="`Link analytics - ${link.number_of_clicks} clicks`"
          :aria-controls="`stats-${id}`"
          @click="toggleExpand('stats')"
        >
          <LineChartIcon slot="icon" />
          <template v-if="link.number_of_clicks" slot="stat">
            {{ link.number_of_clicks | numberFormatter(1) }}
          </template>
        </LinkBtn>
        <LinkBtn
          v-if="!isDeleted && !isScheduleHidden"
          aria-label="Schedule link"
          :active="isExpanded('schedule')"
          :highlight="isExpanded('schedule')"
          :aria-controls="`schedule-${id}`"
          @click="toggleExpand('schedule')"
        >
          <CalendarIcon slot="icon" />
        </LinkBtn>
        <v-tooltip v-if="!isDeleted && !isPinnedHidden" top>
          <template #activator="{ on }">
            <LinkBtn
              v-if="!isDeleted"
              aria-label="Pin link"
              :pinned="pinned"
              :disabled="isPinnedDisabled"
              v-on="on"
              @click="pinned = !pinned"
            >
              <PinIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>{{ pinnedText }}</span>
        </v-tooltip>
        <LinkBtn
          v-if="!isDeleted && !isFreePlan && !isHighlightHidden"
          aria-label="Highlight link"
          :active="isExpanded('highlight')"
          :highlight="!!highlight"
          :aria-controls="`highlight-${id}`"
          @click="toggleExpand('highlight')"
        >
          <template slot="icon">
            <StarIcon v-if="highlight" />
            <StarOIcon v-else />
          </template>
        </LinkBtn>
        <v-tooltip v-if="isEmbedLink && !isDeleted" top>
          <template #activator="{ on }">
            <LinkBtn
              aria-label="Embed options"
              :active="isExpanded('embed')"
              :disabled="isUniversalLink"
              highlight
              :aria-controls="`embed-link-${id}`"
              v-on="on"
              @click="toggleExpand('embed')"
            >
              <component
                :is="embedComponentIcon"
                slot="icon"
                :color="embedComponentIconColor"
              />
            </LinkBtn>
          </template>
          <span>Embed Options</span>
        </v-tooltip>
        <v-tooltip v-if="!isDeleted && !isLockHidden" top>
          <template #activator="{ on }">
            <LinkBtn
              aria-label="Lock"
              :active="isExpanded('lock')"
              :highlight="hasLock"
              :aria-controls="`lock-${id}`"
              v-on="on"
              @click="toggleExpand('lock')"
            >
              <LockIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>Lock</span>
        </v-tooltip>
        <v-tooltip v-if="!isFreePlan && !isDeleted && !isEventTagHidden" top>
          <template #activator="{ on }">
            <LinkBtn
              aria-label="Event tag"
              :active="isExpanded('event-tag')"
              :aria-controls="`event-label-${id}`"
              v-on="on"
              @click="toggleExpand('event-tag')"
            >
              <TagIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>Event tag</span>
        </v-tooltip>
        <v-tooltip v-if="!isFreePlan && showMagicImage && !isDeleted" top>
          <template #activator="{ on }">
            <LinkBtn
              aria-label="Link image suggestions"
              :active="isExpanded('magic-image')"
              :badge="magicImages.length"
              :aria-controls="`magic-image-${id}`"
              v-on="on"
              @click="toggleExpand('magic-image')"
            >
              <WandIcon />
            </LinkBtn>
          </template>
          <span>Image suggestions</span>
        </v-tooltip>
        <v-tooltip v-if="isDeleted" top>
          <template #activator="{ on }">
            <LinkBtn
              class="link__btn--restore js-link-restore"
              aria-label="Restore link"
              :disabled="isRestoring"
              v-on="on"
              @click="handleRestore"
            >
              <RepeatIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>Restore link</span>
        </v-tooltip>
      </template>

      <template slot="actions">
        <HighlightExpand
          v-model="highlight"
          :is-open="isExpanded('highlight')"
          :link-enabled="link.enabled"
          @close="toggleExpand('highlight')"
        />
        <StatsExpand
          :is-open="isExpanded('stats')"
          @close="toggleExpand('stats')"
        />
        <ScheduleExpand
          :is-open="isExpanded('schedule')"
          :link="link"
          @close="toggleExpand('schedule')"
        />
        <EmbedLinkExpand
          v-if="embedOptions"
          v-model="videoEmbedType"
          :is-open="isExpanded('embed')"
          :embed-options="embedOptions"
          @close="toggleExpand('embed')"
        />
        <MagicImageExpand
          v-if="!isFreePlan && showMagicImage"
          :is-open="isExpanded('magic-image')"
          :images="magicImages"
          :link-id="id"
          @close="toggleExpand('magic-image')"
        />
        <EventLabelExpand
          v-if="!isFreePlan"
          :is-open="isExpanded('event-tag')"
          :link="link"
          @close="toggleExpand('event-tag')"
        />
        <LockExpand
          :is-open="isExpanded('lock')"
          :link="link"
          @close="toggleExpand('lock')"
        />
      </template>
    </LinkCard>
  </LinkWrapper>
</template>

<script>
import {
  Card,
  CardText,
  CardActions,
  VText,
  Alert,
  InfoIcon,
  Button,
  EmojiInput,
  IconButton,
  PencilIcon,
  VisibilityToggle,
  DragIcon,
  LineChartIcon,
  CalendarIcon,
  PinIcon,
  StarIcon,
  StarOIcon,
  RepeatIcon,
  ArchiveIcon,
  TrashIcon,
  WandIcon,
  TagIcon,
  CircularLoader,
  FlexSpacer,
  EMBED_ICONS,
  List,
  ListItem,
  ListItemAction,
  ListItemTitle,
  FormGroupHelp,
  LockIcon,
} from '@campsite-bio/component-lib';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import {
  HighlightExpand,
  StatsExpand,
  ScheduleExpand,
  EmbedLinkExpand,
  MagicImageExpand,
  EventLabelExpand,
  LockExpand,
} from '../expand';
import { InputUrl } from '../../form';
import { LinkProps } from '../link-props';
import { useLink } from '../compositions';
import { LinkBtn, LinkWrapper, LinkCard, LinkField } from '../components';

export default {
  components: {
    Card,
    CardText,
    CardActions,
    VText,
    Alert,
    InfoIcon,
    Button,
    EmojiInput,
    IconButton,
    PencilIcon,
    DragIcon,
    LineChartIcon,
    CalendarIcon,
    PinIcon,
    StarIcon,
    StarOIcon,
    RepeatIcon,
    ArchiveIcon,
    TrashIcon,
    WandIcon,
    TagIcon,
    LockIcon,
    HighlightExpand,
    StatsExpand,
    ScheduleExpand,
    EmbedLinkExpand,
    MagicImageExpand,
    EventLabelExpand,
    LockExpand,
    VisibilityToggle,
    LinkBtn,
    CircularLoader,
    FlexSpacer,
    InputUrl,
    ...EMBED_ICONS,
    List,
    ListItem,
    ListItemAction,
    ListItemTitle,
    FormGroupHelp,
    ValidationObserver,
    ValidationProvider,
    LinkWrapper,
    LinkCard,
    LinkField,
  },

  props: LinkProps,

  setup(props) {
    const { link, ...rest } = useLink(props, {
      hasMedia: true,
    });

    return {
      ...rest,
    };
  },
};
</script>

<style scoped></style>
