export default {
  data() {
    return {
      hasCropperLoaded: false,
    };
  },

  watch: {
    hasCropperLoaded() {
      if (this._cropperResolve) this._cropperResolve();
    },
  },

  created() {},

  methods: {
    hasCropperLoadedPromise() {
      if (this.hasCropperLoaded) return Promise.resolve();
      else {
        return new Promise((resolve) => {
          this._cropperResolve = resolve;
        });
      }
    },

    loadCropper() {
      return import('cropperjs').then((cropper) => {
        this.Cropper = cropper.default;
        this.hasCropperLoaded = true;
        return cropper;
      });
    },
  },
};
