import { unref } from 'vue';

import { useAuthorizationDialog } from '../use-authorization-dialog';

export function useShopifyAuthorization(
  profileId,
  authDialogOptions,
  openParams = {},
) {
  const { open, isAuthorizing } = useAuthorizationDialog(authDialogOptions);

  function authorize(params) {
    open({
      provider: 'shopify',
      providerTitle: 'Shopify',
      params: {
        profile_id: unref(profileId),
        ...openParams,
        ...params,
      },
    });
  }

  return {
    authorize,
    isAuthorizing,
  };
}
