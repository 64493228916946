import Vue from 'vue';
import { getField, updateField } from 'vuex-map-fields';

import {
  loadCampsiteFilters,
  loadReport,
  loadReportV1,
  localAxios,
} from '../apis';
import { CAN_MANAGE_ANALYTICS } from '../config';

function getOriginalState() {
  return {
    filters: [],
    todayViews: null,
    todayClicks: null,
    // reports
    analyticsReport: null,
    toplineReport: null,
    links: {},
  };
}

export default {
  namespaced: true,

  state: {
    selectedDateFilter: 'last_7',
    selectedProfile: null,
    trackingReport: {},
    eventsReport: {},
    insightsReport: {},
    leaderboardReport: {},
    profiles: {},
    ownerProfiles: [],
    allProfiles: [],
    channelReport: {},
    // Accounts a user has access to (their own any orgs)
    accounts: [],
    selectedAccount: null,
    channelAuths: [],
    selectedChannelAuths: null,
    profilesWithAppAuths: null,
    ...getOriginalState(),
  },

  getters: {
    selectedDateFilter: (state) => state.selectedDateFilter,
    filters: (state) => state.filters,
    todayViews: (state) => state.todayViews,
    todayClicks: (state) => state.todayClicks,
    // reports
    analyticsReport: (state) => state.analyticsReport,
    trackingReport: (state) => state.trackingReport,
    eventsReport: (state) => state.eventsReport,
    channelAuthAccounts: (state) => {
      return state.channelAuths?.reduce((accum, auth) => {
        if (auth.accounts) {
          accum = [
            ...accum,
            ...auth.accounts.map((account) => ({
              ...account,
              provider: auth.provider,
              app_authorization: auth,
              campsite: auth.campsite,
              wrapped_data: auth.wrapped_data?.find(
                (w) => w.account_id === account.uid,
              ),
            })),
          ];
        }
        return accum;
      }, []);
    },
    getField,
  },

  mutations: {
    resetState(state) {
      Object.assign(state, getOriginalState());
    },
    set(state, { name, value }) {
      Vue.set(state, name, value);
      // state[name] = value;
    },
    updateField(state, field) {
      // Make sure changes are tracked in vue
      if (field.path.split('.').length === 2) {
        const pieces = field.path.split('.');
        Vue.set(state[pieces[0]], pieces[1], field.value);
      } else {
        Vue.set(state, field.path, field.value);
      }
      updateField(state, field);
    },
  },

  actions: {
    async loadFilters({ rootGetters }, { cancelToken, ...params }) {
      const token = rootGetters['profiles/currentProfile'].token;

      return await loadCampsiteFilters({
        token,
        cancelToken,
        ...params,
      });
    },

    async loadAccounts({ commit }) {
      const { data } = await localAxios.get('/api/analytics/accounts');

      commit('updateField', { path: 'accounts', value: data.accounts });

      return data;
    },

    async loadChannelAuths({ commit }) {
      const { data } = await localAxios.get('/api/analytics/channel-auths');

      commit('updateField', { path: 'channelAuths', value: data.auths });

      return data;
    },

    async loadReport({ rootGetters }, { cancelToken, token, ...params }) {
      const campsite_token =
        token || rootGetters['profiles/currentProfile'].token;

      return await loadReport({
        campsite_token,
        cancelToken,
        ...params,
      });
    },

    async loadReportV1({ rootGetters }, { cancelToken, token, ...params }) {
      const campsite_token =
        token || rootGetters['profiles/currentProfile'].token;

      return await loadReportV1({
        campsite_token,
        cancelToken,
        ...params,
      });
    },

    async loadProfiles({ commit, getters }, { force = false } = {}) {
      if (!force && getters.getField('allProfiles').length > 0) return;

      const { data } = await localAxios.get(`/api/profiles/all`, {
        params: {
          type: 'all',
          enabled: true,
          sort: 'username',
          page_size: 1000,
        },
      });

      const ownerProfiles = data.profiles.filter(
        (p) => p.access.entitlements === 'owner' || p.access.role,
      );

      commit('updateField', {
        path: 'ownerProfiles',
        value: ownerProfiles,
      });
      // Combine owner profiles with collaborator profiles that have the proper permissions
      commit('updateField', {
        path: 'allProfiles',
        value: data.profiles.filter((p) => {
          if (p.access.entitlements === 'owner' || p.access.role) return true;
          return (
            Array.isArray(p.access.entitlements) &&
            p.access.entitlements.includes(CAN_MANAGE_ANALYTICS)
          );
        }),
      });
    },
  },
};
