<template>
  <div class="item-wrapper">
    <div class="item-wrapper__content">
      <slot />
      <div v-if="loading" class="item-wrapper__loader">
        <CircularLoader size="1.125rem" />
      </div>
      <div v-else class="item-wrapper__drag">
        <DragIcon class="icon" />
      </div>
      <div class="item-wrapper__btm">
        <slot name="bottom" />
      </div>
    </div>
    <div v-if="$slots.expand" class="item-wrapper__expand">
      <slot name="expand" />
    </div>
  </div>
</template>

<script>
import { DragIcon, CircularLoader } from '@campsite-bio/component-lib';

export default {
  components: {
    DragIcon,
    CircularLoader,
  },

  props: {
    loading: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.item-wrapper {
  margin: 0 -0.925rem;
  transform: translateZ(0);

  &:nth-child(even) {
    background-color: rgba(14, 30, 37, 0.05);
  }
}

.item-wrapper__content {
  padding: 0.625rem 1.875rem 0.925rem 3.125rem;
  position: relative;
}

.item-wrapper__loader {
  left: 0.925rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.item-wrapper__drag {
  background: none;
  border: none;
  bottom: 0;
  color: $color-mine-shaft;
  cursor: move;
  left: 0.925rem;
  position: absolute;
  top: 0.3125rem;
  width: 1.25rem;

  .icon {
    font-size: 1.375rem;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.item-wrapper__btm {
  align-items: center;
  display: flex;
  margin: 0 -0.5rem;

  &::v-deep .v-flex-spacer {
    display: none;

    @media (min-width: 425px) {
      display: block;
    }
  }

  &::v-deep .v-toggle {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
</style>
