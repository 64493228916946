<template>
  <BaseCard title="Add a new profile">
    <VText slot="subtitle" style="font-size: 0.8em">
      You're using {{ totalEnabledCampsites }}/{{ allowedCampsites }}
    </VText>
    <VText gutter-bottom>
      Add another profile to your account. Each profile gets it's own URL and
      theme.
    </VText>
    <Button
      size="small"
      :disabled="isAtLimit"
      :href="actionUrl"
      @click.prevent="$emit('add')"
    >
      Add Profile
    </Button>
  </BaseCard>
</template>

<script>
import { VText, Button } from '@campsite-bio/component-lib';

import BaseCard from './base-card';

export default {
  components: {
    VText,
    Button,
    BaseCard,
  },

  props: {
    totalCampsites: {
      type: Number,
      required: true,
    },

    totalEnabledCampsites: {
      type: Number,
      required: true,
    },

    allowedCampsites: {
      type: Number,
      required: true,
    },

    actionUrl: {
      type: String,
      required: true,
    },

    isAtLimit: Boolean,
  },
};
</script>

<style lang="scss" scoped></style>
