import Vue from 'vue';
import { DateTime } from 'luxon';
import { nextTick } from 'vue';
import { getField, updateField } from 'vuex-map-fields';

import { localAxios } from '@/apis';
import bus from '@/bus';

function getLinkFromGroup(state, linkId) {
  const groupIds = [
    ...Object.keys(state.group),
    ...Object.keys(state.universalLinkGroup),
  ];

  const groupLinks = groupIds.reduce((acc, key) => {
    const links = state.group[key] || state.universalLinkGroup[key];
    return [...acc, ...links];
  }, []);

  return groupLinks.find((l) => l._id.$oid === linkId);
}

function getLinkWithId(state, linkId) {
  const link = state.links.find((l) => l._id.$oid === linkId);
  if (link) return link;
  return getLinkFromGroup(state, linkId);
}

/** Given a link, determine which state we should get the list of links from */
function getLinkListFromLink(state, link) {
  let allLinks = state.links;
  if (link.group_id) {
    allLinks = state.group[link.group_id.$oid];
  } else if (link.universal_link_group_id) {
    allLinks = state.universalLinkGroup[link.universal_link_group_id.$oid];
  }

  return allLinks;
}

function getOriginalState() {
  return {
    links: [],
    linksPage: 1,
    hasMoreLinks: true,
    deletedLinks: [],
    deletedLinksPage: 1,
    hasMoreDeletedLinks: true,
    isLoadingLinks: false,
    isPinningLink: false,
    ignoreLinkChanges: false,
    isPinnedDisabled: false,

    // config
    isDragging: false,
    linkDragId: null,
    isSelectionModeEnabled: false,
    selectedLinks: [],
    selectedCheckbox: false,
    isActionLoading: false,

    // Group links
    group: {},

    // Universal link groups
    universalLinkGroup: {},
  };
}

export default {
  namespaced: true,

  state: getOriginalState(),

  getters: {
    links: (state) => state.links,
    linksPinned: (state) =>
      state.links.filter(
        (l) => l.pinned || l.universal_link_settings?.is_pinned,
      ),
    linksUnpinned: (state) =>
      state.links.filter(
        (l) =>
          !l.pinned &&
          (!l.universal_link_settings || !l.universal_link_settings?.is_pinned),
      ),
    links: (state) => state.links,
    deletedLinks: (state) => state.deletedLinks,
    linksPage: (state) => state.linksPage,
    deletedLinksPage: (state) => state.deletedLinksPage,
    hasMoreLinks: (state) => state.hasMoreLinks,
    hasMoreDeletedLinks: (state) => state.hasMoreDeletedLinks,
    isLoadingLinks: (state) => state.isLoadingLinks,
    isPinningLink: (state) => state.isPinningLink,
    ignoreLinkChanges: (state) => state.ignoreLinkChanges,

    allLinks: (state) => {
      const groupLinks = Object.keys(state.group).reduce((acc, key) => {
        return [...acc, ...state.group[key]];
      }, []);
      return [...state.links, ...state.deletedLinks, ...groupLinks];
    },

    getField,
  },

  mutations: {
    updateField,

    resetState(state) {
      Object.assign(state, getOriginalState());
    },

    setLinks(state, { links, merge }) {
      const mergedLinks = [...links];
      if (merge) {
        links.forEach((link, index) => {
          const matchedLink = state.links.find(
            (l) => l._id.$oid === link._id.$oid,
          );
          // only replace link if it's been edited
          if (matchedLink && matchedLink.dirty) {
            const index = mergedLinks.indexOf(link);
            mergedLinks.splice(index, 1, matchedLink);
          }
        });
      }

      state.links = mergedLinks;
    },

    setDeletedLinks(state, links) {
      state.deletedLinks = links;
    },

    setHasMoreLinks(state, value) {
      state.hasMoreLinks = value;
    },

    setHasMoreDeletedLinks(state, value) {
      state.hasMoreDeletedLinks = value;
    },

    setLinksPage(state, value) {
      state.linksPage = value;
    },

    setDeletedLinksPage(state, value) {
      state.deletedLinksPage = value;
    },

    addLinksPage(state) {
      state.linksPage++;
    },

    addDeletedLinksPage(state) {
      state.deletedLinksPage++;
    },

    setIsLoadingLinks(state, value) {
      state.isLoadingLinks = value;
    },

    setIsPinningLink(state, value) {
      state.isPinningLink = value;
    },

    setIgnoreLinkChanges(state, value) {
      state.ignoreLinkChanges = value;
    },

    addLink(state, { link, position }) {
      let links = state.links;

      if (link.group_id) {
        const groupId = link.group_id.$oid;
        if (!state.group[groupId]) state.group[groupId] = [];
        links = state.group[groupId];
      } else if (link.universal_link_group_id) {
        const groupId = link.universal_link_group_id.$oid;
        if (!state.universalLinkGroup[groupId])
          state.universalLinkGroup[groupId] = [];
        links = state.universalLinkGroup[groupId];
      }

      if (position === 0) links.unshift(link);
      else if (position > 0) links.splice(position, 0, link);
      else links.push(link);
    },

    addDeletedLink(state, { link, position }) {
      if (position === 0) state.deletedLinks.unshift(link);
      else if (position) state.deletedLinks.splice(position, 0, link);
      else state.deletedLinks.push(link);
    },

    moveLink(state, { newIndex, oldIndex, groupId, universalLinkGroupId }) {
      if (groupId) {
        const links = [...state.group[groupId]];
        links.splice(newIndex, 0, links.splice(oldIndex, 1)[0]);
        Vue.set(state.group, groupId, links);
      } else if (universalLinkGroupId) {
        const links = [...state.universalLinkGroup[universalLinkGroupId]];
        links.splice(newIndex, 0, links.splice(oldIndex, 1)[0]);
        Vue.set(state.universalLinkGroup, universalLinkGroupId, links);
      } else {
        const links = [...state.links];
        links.splice(newIndex, 0, links.splice(oldIndex, 1)[0]);
        Vue.set(state, 'links', links);
      }
    },

    moveLinkBanner(state, { linkId, newIndex, oldIndex }) {
      const link = getLinkWithId(state, linkId);
      link.banners.splice(newIndex, 0, link.banners.splice(oldIndex, 1)[0]);
    },

    moveLinkMergeField(state, { linkId, newIndex, oldIndex }) {
      const link = getLinkWithId(state, linkId);
      link.email_options.merge_fields.splice(
        newIndex,
        0,
        link.email_options.merge_fields.splice(oldIndex, 1)[0],
      );
    },

    archiveLink(state, link) {
      const id = link._id.$oid;
      const index = state.links.findIndex((l) => l._id.$oid === id);

      if (index >= 0) {
        state.links.splice(index, 1)[0];
        return;
      }
      // Object.assign(movingLink, link);
      // state.deletedLinks.unshift(movingLink);

      Object.keys(state.group).forEach((groupId) => {
        const index = state.group[groupId].findIndex((l) => l._id.$oid === id);
        if (index >= 0) {
          state.group[groupId].splice(index, 1);
          return;
        }
      });
    },

    restoreLink(state, link) {
      const index = state.deletedLinks.findIndex(
        (l) => l._id.$oid === link._id.$oid,
      );
      const movingLink = state.deletedLinks.splice(index, 1)[0];
      Object.assign(movingLink, link);
      state.links.splice(link.position, 0, link);
    },

    removeLink(state, id) {
      let index = state.deletedLinks.findIndex((l) => l._id.$oid === id);
      if (index >= 0) {
        state.deletedLinks.splice(index, 1);
        return;
      }
      index = state.links.findIndex((l) => l._id.$oid === id);
      if (index >= 0) {
        state.links.splice(index, 1);
        return;
      }

      Object.keys(state.group).forEach((groupId) => {
        const index = state.group[groupId].findIndex((l) => l._id.$oid === id);
        if (index >= 0) {
          state.group[groupId].splice(index, 1);
          return;
        }
      });

      Object.keys(state.universalLinkGroup).forEach((groupId) => {
        const index = state.universalLinkGroup[groupId].findIndex(
          (l) => l._id.$oid === id,
        );
        if (index >= 0) {
          state.universalLinkGroup[groupId].splice(index, 1);
          return;
        }
      });
    },

    updateLink(state, link) {
      const allLinks = getLinkListFromLink(state, link);
      const index = allLinks.findIndex((l) => l._id.$oid === link._id.$oid);
      Vue.set(allLinks, index, link);
    },

    updateLinkField(state, { name, value, id }) {
      const link = getLinkWithId(state, id);
      Vue.set(link, name, value);
    },

    updateLinkUrl(state, { value, id }) {
      const link = getLinkWithId(state, id);
      link.url = value;
    },

    updateLinkEnabled(state, { value, id }) {
      const link = getLinkWithId(state, id);
      link.enabled = value;
    },

    enableFirstLink(state) {
      const firstLink = state.links[0];
      firstLink.enabled = true;
    },

    updateLinkMedia(state, { value, id }) {
      const link = getLinkWithId(state, id);
      const index = link.meta?.findIndex((m) => m.name === 'media');

      if (value) {
        if (index >= 0) {
          Vue.set(link.meta, index, { name: 'media', value });
        } else {
          link.meta.push({
            name: 'media',
            value,
          });
        }
      } else {
        Vue.set(
          link,
          'meta',
          link.meta.filter((m) => m.name !== 'media'),
        );
      }
    },

    updateLinkStartTime(state, { value, id }) {
      const link = getLinkWithId(state, id);
      link.start_time = value;
    },

    updateLinkStartTimeTimezone(state, { value, id }) {
      const link = getLinkWithId(state, id);
      link.start_time_timezone = value;
    },

    updateLinkEndTime(state, { value, id }) {
      const link = getLinkWithId(state, id);
      link.end_time = value;
    },

    updateLinkPinned(state, { value, id }) {
      const link = getLinkWithId(state, id);
      link.pinned = value;
    },

    // link banner
    addLinkBanner(state, { id, banner }) {
      const link = getLinkWithId(state, id);
      link.banners.push(banner);
    },

    removeLinkBanner(state, { linkId, bannerId }) {
      const link = getLinkWithId(state, linkId);
      if (link.banners) {
        const index = link.banners.findIndex((b) => b._id.$oid === bannerId);
        link.banners.splice(index, 1);
      }
    },

    updateLinkBanner(state, { linkId, banner }) {
      const link = getLinkWithId(state, linkId);
      const index = link.banners.findIndex(
        (b) => b._id.$oid === banner._id.$oid,
      );
      Vue.set(link.banners, index, banner);
    },

    updateLinkBannerMedia(state, { value, linkId, bannerId }) {
      const link = getLinkWithId(state, linkId);
      const banner = link.banners.find((b) => b._id.$oid === bannerId);
      Vue.set(banner, 'media', value);
    },

    updateLinkBannerMediaUrl(state, { value, linkId, bannerId }) {
      const link = getLinkWithId(state, linkId);
      const banner = link.banners.find((b) => b._id.$oid === bannerId);
      Vue.set(banner, 'media_url', value);
    },

    updateLinkBannerUrl(state, { value, linkId, bannerId }) {
      const link = getLinkWithId(state, linkId);
      const banner = link.banners.find((b) => b._id.$oid === bannerId);
      Vue.set(banner, 'url', value);
    },

    updateLinkBannerImageAlt(state, { value, linkId, bannerId }) {
      const link = getLinkWithId(state, linkId);
      const banner = link.banners.find((b) => b._id.$oid === bannerId);
      Vue.set(banner, 'image_alt', value);
    },

    updateLinkBannerEnabled(state, { value, linkId, bannerId }) {
      const link = getLinkWithId(state, linkId);
      const banner = link.banners.find((b) => b._id.$oid === bannerId);
      Vue.set(banner, 'enabled', value);
    },

    updateLinkBannerMediaItem(state, { value, linkId, bannerId }) {
      const link = getLinkWithId(state, linkId);
      const banner = link.banners.find((b) => b._id.$oid === bannerId);
      Vue.set(banner, 'media_item', value);
    },

    // link hightlight
    removeLinksHighlight(state, linkId) {
      const link = state.links.find(
        (l) => l.highlight && l._id.$oid !== linkId,
      );
      if (link) link.highlight = null;
    },

    // link email merge fields
    addLinkMergeField(state, { id, field }) {
      const link = getLinkWithId(state, id);
      link.email_options.merge_fields.push(field);
    },

    removeLinkMergeField(state, { linkId, mergeFieldId }) {
      const link = getLinkWithId(state, linkId);
      if (link.email_options.merge_fields) {
        const index = link.email_options.merge_fields.findIndex(
          (b) => b._id.$oid === mergeFieldId,
        );
        link.email_options.merge_fields.splice(index, 1);
      }
    },

    updateLinkMergeField(state, { linkId, mergeField }) {
      const link = getLinkWithId(state, linkId);
      const index = link.email_options.merge_fields.findIndex(
        (b) => b._id.$oid === mergeField._id.$oid,
      );
      Vue.set(link.email_options.merge_fields, index, mergeField);
    },

    updateLinkMergeFieldLabel(state, { value, linkId, mergeFieldId }) {
      const link = getLinkWithId(state, linkId);
      const mergeField = link.email_options.merge_fields.find(
        (b) => b._id.$oid === mergeFieldId,
      );
      Vue.set(mergeField, 'label', value);
    },

    updateLinkMergeFieldName(state, { value, linkId, mergeFieldId }) {
      const link = getLinkWithId(state, linkId);
      const mergeField = link.email_options.merge_fields.find(
        (b) => b._id.$oid === mergeFieldId,
      );
      Vue.set(mergeField, 'name', value);
    },

    updateLinkMergeFieldHintText(state, { value, linkId, mergeFieldId }) {
      const link = getLinkWithId(state, linkId);
      const mergeField = link.email_options.merge_fields.find(
        (b) => b._id.$oid === mergeFieldId,
      );
      Vue.set(mergeField, 'hint_text', value);
    },

    updateLinkMergeFieldDefaultValue(state, { value, linkId, mergeFieldId }) {
      const link = getLinkWithId(state, linkId);
      const mergeField = link.email_options.merge_fields.find(
        (b) => b._id.$oid === mergeFieldId,
      );
      Vue.set(mergeField, 'default_value', value);
    },

    updateLinkMergeFieldRequired(state, { value, linkId, mergeFieldId }) {
      const link = getLinkWithId(state, linkId);
      const mergeField = link.email_options.merge_fields.find(
        (b) => b._id.$oid === mergeFieldId,
      );
      Vue.set(mergeField, 'required', value);
    },

    updateLinkOptions(state, { value, name, id }) {
      const link = getLinkWithId(state, id);
      Vue.set(link, name, value);
    },

    setGroupLinks(state, { linkId, links }) {
      Vue.set(state.group, linkId, links);
    },

    setUniversalLinkGroupLinks(state, { id, links }) {
      Vue.set(state.universalLinkGroup, id, links);
    },

    addLinkToGroup(state, { linkId, groupId, position = 0 }) {
      const linkIndex = state.links.findIndex((l) => l._id.$oid === linkId);
      const link = state.links.splice(linkIndex, 1)[0];
      if (!state.group[groupId]) {
        Vue.set(state.group, groupId, []);
      }
      state.group[groupId].splice(position, 0, link);
    },

    // When removing from the group
    removeLinkFromGroup(state, { linkId, groupId, position }) {
      const linkIndex = state.group[groupId].findIndex(
        (l) => l._id.$oid === linkId,
      );
      const link = state.group[groupId].splice(linkIndex, 1)[0];
      state.links.splice(position, 0, link);
    },
  },

  actions: {
    async getLinks(
      { getters, commit, rootGetters },
      {
        force,
        page,
        pageSize = 20,
        setIgnoreChanges = false,
        merge = false,
      } = {},
    ) {
      if (getters.hasMoreLinks || force) {
        page = page || getters.linksPage;
        commit('setIsLoadingLinks', true);
        try {
          const { data } = await localAxios.get(
            `/api/profiles/${rootGetters['profiles/currentProfileId']}/links`,
            {
              params: {
                page,
                page_size: pageSize,
              },
            },
          );
          commit('setIsLoadingLinks', false);
          commit('setHasMoreLinks', data.has_more);
          commit('setLinksPage', page);
          commit('updateField', {
            path: 'isPinnedDisabled',
            value: data.is_pinned_disabled,
          });
          // Add the link items passed to their own store
          data.links.forEach((link) => {
            commit(
              'linkItems/sync',
              {
                linkId: link._id.$oid,
                items: link.items,
              },
              { root: true },
            );
            delete link.items;
          });
          if (page > 1) {
            // Make sure the same link doesn't show up twice
            const deduppedLinks = data.links.filter(
              (l1) => !getters.links.find((l2) => l2._id.$oid === l1._id.$oid),
            );
            deduppedLinks.forEach((link) => commit('addLink', { link }));
          } else {
            if (setIgnoreChanges) commit('setIgnoreLinkChanges', true);
            commit('setLinks', { links: data.links, merge });
            await nextTick();
            commit('setIgnoreLinkChanges', false);
          }

          return data;
        } catch (e) {
          commit('setIsLoadingLinks', false);
          throw e;
        }
      }

      return getters.links;
    },

    async reloadAllLinks({ dispatch, getters, commit }) {
      const linksLength = getters.links.length;
      const linksPage = getters.linksPage;
      // refresh current links
      await dispatch('getLinks', {
        force: true,
        page: 1,
        pageSize: Math.max(linksLength, 20),
        setIgnoreChanges: true,
        merge: true,
      });
      // make sure links page is still correct
      commit('setLinksPage', linksPage);
    },

    async getDeletedLinks(
      { getters, commit, rootGetters },
      { page, pageSize = 20, force } = {},
    ) {
      if (getters.hasMoreDeletedLinks || force) {
        page = page || getters.deletedLinksPage;
        commit('setIsLoadingLinks', true);
        const { data } = await localAxios.get(
          `/api/profiles/${rootGetters['profiles/currentProfileId']}/links`,
          {
            params: {
              page,
              page_size: pageSize,
              deleted: true,
            },
          },
        );

        commit('setIsLoadingLinks', false);
        commit('setHasMoreDeletedLinks', data.has_more);
        commit('setDeletedLinksPage', page);
        // Add the link items passed to their own store
        data.links.forEach((link) => {
          commit(
            'linkItems/sync',
            {
              linkId: link._id.$oid,
              items: link.items,
            },
            { root: true },
          );
          delete link.items;
        });
        if (page > 1) {
          for (let i = 0; i < data.links.length; i++) {
            const link = data.links[i];
            commit('addDeletedLink', { link: link });
          }
        } else commit('setDeletedLinks', data.links);

        return data;
      }
    },

    async addLink({ commit }, payload) {
      const { data } = await localAxios.post(`/api/links`, payload);

      commit('setIgnoreLinkChanges', true);
      commit('addLink', {
        link: data.link,
        position: data.link.position,
      });
      // Keep the group link up-do-date
      if (data.group) commit('updateLink', data.group);
      await nextTick();
      commit('setIgnoreLinkChanges', false);

      bus.$emit('add-link', data.link);

      return data;
    },

    async updateLink({ commit }, { id, data, cancelToken }) {
      data = Object.assign({}, data);
      delete data.link_schedule_logs;
      delete data.meta;
      delete data.media_item;
      // format dates without timezones
      if (data.start_time) {
        if (typeof data.start_time === 'string')
          data.start_time = DateTime.fromISO(data.start_time);
        data.start_time = data.start_time.toFormat('yyyy-LL-dd HH:mm:00');
      }
      if (data.end_time) {
        if (typeof data.end_time === 'string')
          data.end_time = DateTime.fromISO(data.end_time);
        data.end_time = data.end_time.toFormat('yyyy-LL-dd HH:mm:00');
      }
      // remove highlight from other links
      if (data.highlight) commit('removeLinksHighlight', id);

      const { data: json } = await localAxios.put(`/api/links/${id}`, data, {
        cancelToken,
      });

      commit('setIgnoreLinkChanges', true);
      // Keep the group link up-do-date
      if (json.group) commit('updateLink', json.group);
      commit('updateLinkMedia', {
        value: json.link.meta.find((m) => m.name === 'media')?.value,
        id,
      });
      await nextTick();
      commit('setIgnoreLinkChanges', false);

      return json;
    },

    async updateLinkPosition(
      { commit, rootGetters },
      { link, position, groupId, universalLinkGroupId, pinned, cancelToken },
    ) {
      const { data } = await localAxios.put(
        `/api/links/${link._id.$oid}/position`,
        {
          position,
          pinned,
          group_id: groupId,
          universal_link_group_id: universalLinkGroupId,
        },
        { cancelToken },
      );

      return data;
    },

    async updateLinkEventTag({ commit }, { id, data, cancelToken }) {
      const { data: json } = await localAxios.put(
        `/api/links/${id}/event-tag`,
        data,
        {
          cancelToken,
        },
      );

      return json;
    },

    async updateLinkLock({ commit }, { id, data, cancelToken }) {
      const { data: json } = await localAxios.put(
        `/api/links/${id}/lock`,
        data,
        {
          cancelToken,
        },
      );

      return json;
    },

    async archiveLink({ commit }, { id }) {
      const { data } = await localAxios.delete(`/api/links/${id}/archive`);

      commit('archiveLink', data.link);
      if (data.group) commit('updateLink', data.group);

      return data;
    },

    async restoreLink({ commit }, { id }) {
      const { data } = await localAxios.put(`/api/links/${id}/restore`);

      commit('restoreLink', data.link);

      return data;
    },

    async deleteLink({ commit }, { id }) {
      const { data } = await localAxios.delete(`/api/links/${id}`);

      commit('setIgnoreLinkChanges', true);
      commit('removeLink', id);
      if (data.group) commit('updateLink', data.group);
      await nextTick();
      commit('setIgnoreLinkChanges', false);

      return data;
    },

    async uploadPostImage({ commit }, { id, url }) {
      const { data } = await localAxios.put(`/api/links/${id}/image`, { url });

      commit('setIgnoreLinkChanges', true);
      commit('updateLinkMedia', {
        value: data.link.meta.find((m) => m.name === 'media').value,
        id,
      });
      await nextTick();
      commit('setIgnoreLinkChanges', false);

      return data;
    },

    async updateOptions({ commit }, { id, name, ...rest }) {
      const { data } = await localAxios.put(
        `/api/links/${id}/${name}-options`,
        rest,
      );
      return data;
    },

    // link banners
    async updateLinkBannerPosition({ rootGetters }, { linkId, banners }) {
      const { data } = await localAxios.put(
        `/api/links/carousel/${linkId}/position`,
        {
          banners,
        },
      );
      return data;
    },

    async addLinkBanner({ rootGetters, commit }, { id }) {
      const { data } = await localAxios.post(`/api/links/carousel/${id}`);
      commit('addLinkBanner', { id, banner: data.banner });
      return data;
    },

    async updateLinkBanner({ rootGetters, commit }, { linkId, id, banner }) {
      const { data } = await localAxios.put(
        `/api/links/carousel/${linkId}/${id}`,
        banner,
      );
      return data;
    },

    async deleteLinkBanner({ rootGetters, commit }, { linkId, bannerId }) {
      const { data } = await localAxios.delete(
        `/api/links/carousel/${linkId}/${bannerId}`,
      );
      commit('removeLinkBanner', { linkId, bannerId });
      return data;
    },

    // email merge fields
    async updateLinkMergeFieldPosition(
      { rootGetters },
      { linkId, mergeFields },
    ) {
      const { data } = await localAxios.put(
        `/api/links/mergefield/${linkId}/position`,
        {
          merge_fields: mergeFields,
        },
      );
      return data;
    },

    async addLinkMergeField({ rootGetters, commit }, { id, ...rest }) {
      const { data } = await localAxios.post(
        `/api/links/mergefield/${id}`,
        rest,
      );
      commit('addLinkMergeField', { id, field: data.merge_field });
      return data;
    },

    async updateLinkMergeField({ rootGetters }, { linkId, id, mergeField }) {
      const { data } = await localAxios.put(
        `/api/links/mergefield/${linkId}/${id}`,
        mergeField,
      );
      return data;
    },

    async deleteLinkMergeField(
      { rootGetters, commit },
      { linkId, mergeFieldId },
    ) {
      const { data } = await localAxios.delete(
        `/api/links/mergefield/${linkId}/${mergeFieldId}`,
      );
      commit('removeLinkMergeField', { linkId, mergeFieldId });
      return data;
    },

    // Group links
    async getGroupLinks({ commit, getters }, { linkId }) {
      const { data } = await localAxios.get(`/api/links/${linkId}/group/links`);

      commit('setIgnoreLinkChanges', true);
      commit('setGroupLinks', { linkId, links: data });
      await nextTick();
      commit('setIgnoreLinkChanges', false);
    },

    async addLinkToGroup({ commit }, { linkId, groupId, position }) {
      const { data } = await localAxios.put(
        `/api/links/${groupId}/group/${linkId}`,
        {
          position,
        },
      );

      commit('setIgnoreLinkChanges', true);
      commit('addLinkToGroup', { linkId, groupId, position });
      // Update the link and group
      commit('updateLink', data.link);
      commit('updateLink', data.group);
      await nextTick();
      commit('setIgnoreLinkChanges', false);
    },

    async removeLinkFromGroup({ commit, state }, { linkId, position }) {
      const link = getLinkFromGroup(state, linkId);
      const { data } = await localAxios.delete(
        `/api/links/${link.group_id.$oid}/group/${linkId}`,
        {
          params: {
            position,
          },
        },
      );

      commit('setIgnoreLinkChanges', true);
      commit('removeLinkFromGroup', {
        linkId,
        groupId: link.group_id.$oid,
        position,
      });
      // Update the link and group
      commit('updateLink', data.link);
      commit('updateLink', data.group);
      await nextTick();
      commit('setIgnoreLinkChanges', false);
    },

    // Create a new group link that contains other links
    async bulkGroupLink({ dispatch }, { ids }) {
      // TODO: remove links from current links right away

      const data = await dispatch('addLink', {
        type: 'group',
        link_ids: ids,
      });

      // refresh current links
      await dispatch('reloadAllLinks');

      return data;
    },

    async bulkDeleteLinks({ dispatch, rootGetters, commit }, { ids }) {
      try {
        const { data } = await localAxios.post(
          `/api/links/${rootGetters['profiles/currentProfileId']}/bulk/delete`,
          {
            ids,
          },
        );
        ids.forEach((id) => {
          commit('removeLink', id);
        });
        return data;
      } catch (e) {
        throw e;
      } finally {
        // refresh current links
        await dispatch('reloadAllLinks');
      }
    },

    async bulkArchiveLinks({ dispatch, rootGetters, commit }, { ids }) {
      try {
        const { data } = await localAxios.post(
          `/api/links/${rootGetters['profiles/currentProfileId']}/bulk/archive`,
          {
            ids,
          },
        );

        ids.forEach((id) => {
          commit('removeLink', id);
        });
        return data;
      } catch (e) {
        throw e;
      } finally {
        // refresh current links
        await dispatch('reloadAllLinks');
      }
    },
  },
};
