<template>
  <ListItem component="button" type="button" @click="$emit('click', 'text')">
    <ListItemAction>
      <TextBoxOutlineIcon size="1.5rem" />
    </ListItemAction>
    <ListItemContent>
      <ListItemTitle>Text</ListItemTitle>
      <ListItemSubtitle wrap>Add a block of text.</ListItemSubtitle>
    </ListItemContent>
  </ListItem>
</template>

<script>
import {
  ListItem,
  ListItemAction,
  ListItemContent,
  ListItemSubtitle,
  ListItemTitle,
  TextBoxOutlineIcon,
} from '@campsite-bio/component-lib';

export default {
  components: {
    ListItem,
    ListItemAction,
    ListItemContent,
    ListItemSubtitle,
    ListItemTitle,
    TextBoxOutlineIcon,
  },
};
</script>

<style lang="scss" scoped></style>
