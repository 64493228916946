<template>
  <div class="settings-section-grid">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.settings-section-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
}
</style>
