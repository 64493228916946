<template>
  <div class="media-item">
    <input
      :id="id"
      class="image-select image-select--folder"
      type="radio"
      name="folder_select"
      :checked="checked"
      @change="$emit('change')"
    />
    <label :for="id" aria-label="Folder">
      <FolderOIcon />
    </label>
    <a
      href="#"
      class="media-item__caption media-item__caption--folder"
      @click.prevent="$emit('click')"
      >{{ name }}</a
    >
  </div>
</template>

<script>
import { FolderOIcon } from '@campsite-bio/component-lib';

export default {
  components: {
    FolderOIcon,
  },

  props: {
    checked: Boolean,
    name: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      id: null,
    };
  },

  created() {
    this.id = this._uid;
  },
};
</script>

<style scoped></style>
