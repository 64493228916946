export * from './percentage-change';
export * from './client-version';
export * from './get-single-param';
export * from './is-empty';
export * from './has-profile-entitlements';
export * from './auth-post';
export * from './is-valid-url';
export * from './meta';
export * from './ga';
export * from './theme';
export * from './social';
export * from './files';
export * from './video';
export * from './reports';
export * from './numbers';
export * from './analytics';
export * from './log';
export * from './links';
export * from './charts';
export * from './profiles';

import store from '../store/';
import { setClientVersion } from './client-version';

/**
 * Get a setting from the app
 * @param {String} key
 * @returns the setting value
 */
export function getAppSetting(key) {
  const state = store.state;
  // Check if the setting key exists, if not warn the user
  if (!state.appSettings.hasOwnProperty(key))
    console.warn(`App setting ${key} not found`);

  const setting = state.appSettings[key];
  return setting;
}

export function handleStatus(response) {
  if (response.headers && response.headers.get('X-Client-Version'))
    setClientVersion(response.headers.get('X-Client-Version'));
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') >= 0) {
    if (!response.ok) {
      if (response.status === 401) store.commit('setSessionEnded', true);
      return response.json().then((json) => {
        throw json;
      });
    }
    return response.json();
  }

  if (!response.ok)
    return response.text().then((text) => {
      throw text;
    });
  return response.text();
}

///

export function timeout(time) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), time);
  });
}

///

export function createSimpleFunctional(c, el = 'div', name) {
  name = name || c.replace(/__/g, '-');
  name = name.split('-')[0] === 'v' ? name : `v-${name}`;

  return {
    name: name,
    functional: true,

    render: (h, { data, children }) => {
      data.staticClass = `${c} ${data.staticClass || ''}`.trim();

      return h(el, data, children);
    },
  };
}

///

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

///

export function loadScript(src) {
  return new Promise((resolve, reject) => {
    let script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = function () {
      resolve();
    };
    script.onerror = function () {
      reject('Failed to load script ' + src);
    };
    document.head.appendChild(script);
  });
}

///

export function endOfPage(offset = 0, el) {
  if (
    (window.pageYOffset || document.body.scrollTop) + window.innerHeight >=
    el.offsetHeight - offset
  ) {
    return true;
  } else return false;
}

///

export function findTimezone(name) {
  let match;
  const zones = store.getters.timezones;
  for (let i = 0; i < zones.length; i++) {
    const zone = zones[i];
    if (zone.value === name) {
      match = zone;
      break;
    }
  }
  return match;
}

///

export function timezoneDetect() {
  let dtDate = new Date('1/1/' + new Date().getUTCFullYear());
  let intOffset = 10000; //set initial offset high so it is adjusted on the first attempt
  let intMonth;

  //go through each month to find the lowest offset to account for DST
  for (intMonth = 0; intMonth < 12; intMonth++) {
    //go to the next month
    dtDate.setUTCMonth(dtDate.getUTCMonth() + 1);

    //To ignore daylight saving time look for the lowest offset.
    //Since, during DST, the clock moves forward, it'll be a bigger number.
    if (intOffset > dtDate.getTimezoneOffset() * -1) {
      intOffset = dtDate.getTimezoneOffset() * -1;
    }
  }

  return intOffset;
}

///

// https://jsfiddle.net/abernh/twgj8bev/
export function simpleValidateHtmlStr(htmlStr) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlStr, 'text/html');
  const allnodes = doc.getElementsByTagName('*');
  for (let i = allnodes.length - 1; i >= 0; i--) {
    if (allnodes[i] instanceof HTMLUnknownElement) return false;
  }

  return true;
}
