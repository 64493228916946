import Vue from 'vue';
import { localAxios } from '../apis';
import { getField, updateField } from 'vuex-map-fields';
import bus from '../bus';
import { loadCert, requestCert } from '../apis/certificates';

function getOriginalState() {
  return {
    profiles: null,
    profilesHasMore: false,
    profilesPage: 1,
    currentId: null,
    currentProfile: {},
    lastSyncedAt: null,
    socialAccounts: [],
    isSaving: false,
    isLoadingProfile: false,
    checkOut: null,
    notifications: [],
  };
}

export default {
  namespaced: true,

  state: getOriginalState(),

  getters: {
    all: (state) => state.profiles,
    enabled: (state) => (state.profiles || []).filter((p) => p.enabled),
    accessProfiles: (state) => state.accessProfiles,
    currentId: (state) => state.currentId,
    currentProfile: (state) => state.currentProfile,
    currentProfileId: (state) =>
      state.currentProfile?._id?.$oid || state.currentId,
    hasProfile: (state) => Object.keys(state.currentProfile).length > 0,
    currentProfilePlan: (state) => state.currentProfile.plan,
    isProfileOnFreePlan: (state) => state.currentProfile.plan === 'free',
    socialAccounts: (state) => state.socialAccounts,
    isSaving: (state) => state.isSaving,
    isLoadingProfile: (state) => state.isLoadingProfile,
    checkOut: (state) => state.checkOut,
    authorizations: (state) => state.currentProfile?.app_authorizations || [],
    paymentAuthorizations: (state) =>
      state.currentProfile?.app_authorizations?.filter(
        (a) => a.provider === 'square' || a.provider === 'paypal',
      ),
    shopAuthorizations: (state) =>
      state.currentProfile?.app_authorizations?.filter(
        (a) => a.provider === 'shopify' || a.provider === 'spring',
      ),
    instagramAuthorization: (state) =>
      state.currentProfile?.app_authorizations?.find(
        (a) => a.provider === 'instagram',
      ),
    facebookAuthorization: (state) =>
      state.currentProfile?.app_authorizations?.find(
        (a) => a.provider === 'facebook',
      ),
    googleAuthorization: (state) =>
      state.currentProfile?.app_authorizations?.find(
        (a) => a.provider === 'google',
      ),
    googleAnalyticsAuthorization: (state) =>
      state.currentProfile?.app_authorizations?.find(
        (a) => a.provider === 'google_analytics_4',
      ),
    getField,
  },

  mutations: {
    updateField,

    resetState(state) {
      Object.assign(state, getOriginalState());
    },

    updateProfileField(state, { path, value }) {
      const pathSplit = path.split('.');
      state.currentProfile = {
        ...state.currentProfile,
        [pathSplit[pathSplit.length - 1]]: value,
      };
    },

    set(state, { name, value }) {
      state[name] = value;
    },

    setProfile(state, profile) {
      const index = state.profiles.findIndex(
        (p) => p._id.$oid === profile._id.$oid,
      );
      state.profiles.splice(index, 1, profile);
    },

    removeProfile(state, id) {
      const index = state.profiles?.findIndex((p) => p._id.$oid === id);
      if (index >= 0) state.profiles.splice(index, 1);
    },

    removeAuthorization(state, id) {
      if (state.currentProfile) {
        state.currentProfile.app_authorizations =
          state.currentProfile.app_authorizations.filter(
            (p) => p._id.$oid !== id,
          );
      }
    },

    updateAuthorization(state, item) {
      if (!state.currentProfile.app_authorizations)
        state.currentProfile.app_authorizations = [];
      const index = state.currentProfile.app_authorizations.findIndex(
        (p) => p._id.$oid === item._id.$oid,
      );
      if (index > -1) {
        state.currentProfile.app_authorizations.splice(index, 1, item);
      } else {
        state.currentProfile.app_authorizations.push(item);
      }
    },

    setBGImage(state, value) {
      Vue.set(state.currentProfile, 'site_bg_image', value);
    },

    setTheme(state, value) {
      state.currentProfile.theme = value;
    },

    setGoogleFont(state, googleFont) {
      Vue.set(state.currentProfile, 'google_font', googleFont);
    },

    setGoogleFontHasWeight(state, hasWeight) {
      Vue.set(state.currentProfile, 'google_font_has_weight', hasWeight);
    },

    setFontFamilyDetails(state, value) {
      Vue.set(state.currentProfile, 'site_font_family_details', value);
    },

    setButtonFontFamilyDetails(state, value) {
      Vue.set(state.currentProfile, 'site_button_font_family_details', value);
    },

    setUserProfileImage(state, url) {
      state.currentProfile.profile_image = url;
    },

    addSocialAccount(state, account) {
      state.socialAccounts.push(account);
    },

    updateSocialAccount(state, account) {
      const index = state.socialAccounts.indexOf(account);
      Vue.set(state.socialAccounts, index, account);
    },

    moveSocialAccount(state, { newIndex, oldIndex }) {
      const socialAccounts = [...state.socialAccounts];
      socialAccounts.splice(newIndex, 0, socialAccounts.splice(oldIndex, 1)[0]);
      state.socialAccounts = socialAccounts;
    },

    updateSocialAccountsPosition(state) {
      const accounts = [...state.socialAccounts];
      accounts.forEach((account, index) => (account.position = index));
      state.socialAccounts = accounts;
    },

    // update the given link position
    updateSocialAccountPosition(state, { account, position }) {
      account.position = position;
    },

    removeSocialAccount(state, account) {
      const index = state.socialAccounts.indexOf(account);
      state.socialAccounts.splice(index, 1);
    },

    updateSocialField(state, { name, value, id }) {
      const index = state.socialAccounts.findIndex((l) => l._id.$oid === id);
      state.socialAccounts.splice(index, 1, {
        ...state.socialAccounts[index],
        [name]: value,
      });
    },
  },

  actions: {
    async touch(store, id) {
      localAxios.put(`/api/profiles/${id}/touch`);
    },

    async add({ commit }, params) {
      const { data } = await localAxios.post('/api/profiles', params);
      commit('setUser', data.user, { root: true });
      if (data.org)
        commit(
          'organizations/updateField',
          { path: 'currentOrganization', value: data.org },
          { root: true },
        );
      return data;
    },

    async enable({ commit }, id) {
      const { data } = await localAxios.put(`/api/profiles/${id}/enable`);
      return data;
    },

    async all({ commit, state }, params = {}) {
      const { data } = await localAxios.get(`/api/profiles/all`, { params });
      const page = params.page || 1;
      const stateName = 'profiles';

      if (page === 1) {
        commit('set', {
          name: stateName,
          value: data.profiles,
        });
      } else {
        const existingProfiles = state[stateName];
        commit('set', {
          name: stateName,
          value: [...existingProfiles, ...data.profiles],
        });
      }
      commit('set', {
        name: 'profilesHasMore',
        value: data.has_more,
      });

      return data;
    },

    async get({ getters, commit }) {
      const profileId = getters.currentProfileId;

      if (!profileId) return;

      commit('setIsLoading', true, { root: true });
      commit('set', { name: 'isLoadingProfile', value: true });

      try {
        const { data } = await localAxios.get(`/api/profiles/${profileId}`);

        commit('set', {
          name: 'currentProfile',
          value: data.profile,
        });
        commit('set', {
          name: 'socialAccounts',
          value: data.social_accounts,
        });
        commit('setIsLoading', false, { root: true });
        commit('set', { name: 'isLoadingProfile', value: false });
        commit('set', { name: 'lastSyncedAt', value: new Date() });

        return data;
      } catch (e) {
        commit('setIsLoading', false, { root: true });
        commit('set', { name: 'isLoadingProfile', value: false });
        throw e;
      }
    },

    async updateSettings({ getters, commit }, postData) {
      commit('setIsLoading', true, { root: true });
      commit('set', { name: 'isSaving', value: true });

      // remove data that doesn't need to get sent
      delete postData.certificate;
      delete postData.entitlements;
      delete postData.addons;
      delete postData.app_authorizations;

      try {
        const { data } = await localAxios.put(
          `/api/profiles/${getters.currentProfileId}/settings`,
          postData,
        );

        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
        commit('set', { name: 'isSaving', value: false });
      }
    },

    async updateAppearance({ getters, commit }, postData) {
      commit('setIsLoading', true, { root: true });
      commit('set', { name: 'isSaving', value: true });

      // remove data that doesn't need to get sent
      delete postData.certificate;
      delete postData.entitlements;
      delete postData.addons;
      delete postData.app_authorizations;

      try {
        const { data } = await localAxios.put(
          `/api/profiles/${getters.currentProfileId}/appearance`,
          postData,
        );

        // set bg image after it's been generated by api
        if (
          data.profile.site_bg_image &&
          data.profile.site_bg_image.media_item &&
          data.profile.site_bg_image.mobile
        ) {
          commit('setBGImage', data.profile.site_bg_image);
        }
        commit('setTheme', data.profile.theme);

        bus.$emit('appearance-update');

        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
        commit('set', { name: 'isSaving', value: false });
      }
    },

    async swapDomain({ getters, commit, dispatch }) {
      const currentDomain = getters.currentProfile.domain;

      const data = await dispatch('updateDomain', {
        domain:
          currentDomain === 'campsite.bio' ? 'campsite.to' : 'campsite.bio',
      });

      commit('updateField', {
        path: 'currentProfile.domain',
        value: data.profile.domain,
      });

      return data;
    },

    async updateDomain({ getters, commit }, postData) {
      commit('setIsLoading', true, { root: true });
      commit('set', { name: 'isSaving', value: true });

      // remove data that doesn't need to get sent
      delete postData.certificate;
      delete postData.entitlements;
      delete postData.addons;
      delete postData.app_authorizations;

      try {
        const { data } = await localAxios.put(
          `/api/profiles/${getters.currentProfileId}/domain`,
          postData,
        );

        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
        commit('set', { name: 'isSaving', value: false });
      }
    },

    async addCertificate({ getters, commit }) {
      commit('setIsLoading', true, { root: true });
      commit('set', { name: 'isSaving', value: true });

      try {
        const data = await requestCert(getters.currentProfileId);
        commit('updateProfileField', {
          path: 'certificate',
          value: data.certificate,
        });

        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
        commit('set', { name: 'isSaving', value: false });
      }
    },

    async loadCertificate({ getters, commit }) {
      try {
        const data = await loadCert(getters.currentProfileId);
        commit('updateProfileField', {
          path: 'certificate',
          value: data.certificate,
        });

        return data;
      } catch (e) {
        throw e;
      }
    },

    async updateProfileImage({ getters, commit }, postData) {
      try {
        await localAxios.put(
          `/api/profiles/${getters.currentProfileId}/profile-image`,
          postData,
        );

        commit('setUserProfileImage', postData.url);

        bus.$emit('profile-image-update');
      } catch (e) {
        throw e;
      }
    },

    async speedySetup({ getters, commit }, postData) {
      try {
        const { data } = await localAxios.put(
          `/api/profiles/${getters.currentId}/setup/speedy-setup`,
          postData,
        );

        return data;
      } catch (e) {
        throw e;
      }
    },

    async magicSetup({ getters, commit }, postData) {
      try {
        const { data } = await localAxios.put(
          `/api/profiles/${getters.currentId}/setup/magic`,
          postData,
        );

        commit('set', { name: 'currentProfile', value: data.profile });

        return data;
      } catch (e) {
        throw e;
      }
    },

    async updateIntegrations({ getters, commit }, postData) {
      commit('setIsLoading', true, { root: true });
      try {
        const { data } = await localAxios.put(
          `/api/profiles/${getters.currentProfileId}/integrations`,
          postData,
        );

        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
      }
    },

    async updateMeta({ getters, commit }, postData) {
      commit('setIsLoading', true, { root: true });
      try {
        const { data } = await localAxios.put(
          `/api/profiles/${getters.currentProfileId}/meta`,
          postData,
        );

        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
      }
    },

    async updateDomainMeta({ getters, commit }, postData) {
      commit('setIsLoading', true, { root: true });
      try {
        const { data } = await localAxios.put(
          `/api/profiles/${getters.currentProfileId}/domain-meta`,
          postData,
        );
        commit('set', { name: 'currentProfile', value: data.profile });

        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
      }
    },

    async updateUsername({ commit, getters }, username) {
      const { data } = await localAxios.post(
        `/api/profiles/${getters.currentProfileId}/username`,
        {
          username,
        },
      );

      commit('set', { name: 'currentProfile', value: data.profile });

      return data.profile;
    },

    async updateSubdomain({ commit, getters }, subdomain) {
      const { data } = await localAxios.post(
        `/api/profiles/${getters.currentProfileId}/subdomain`,
        {
          subdomain,
        },
      );

      commit('set', { name: 'currentProfile', value: data.profile });

      return data.profile;
    },

    async removeSubdomain({ commit, getters }) {
      const { data } = await localAxios.delete(
        `/api/profiles/${getters.currentProfileId}/subdomain`,
      );

      commit('set', { name: 'currentProfile', value: data.profile });

      return data.profile;
    },

    async removeCustomDomainCertificate({ commit, getters }) {
      const { data } = await localAxios.delete(
        `/api/profiles/${getters.currentProfileId}/certificate`,
      );

      commit('set', { name: 'currentProfile', value: data.profile });

      return data.profile;
    },

    async removeCustomDomain({ commit, getters }) {
      const { data } = await localAxios.delete(
        `/api/profiles/${getters.currentProfileId}/custom-domain`,
      );

      commit('set', { name: 'currentProfile', value: data.profile });

      return data.profile;
    },

    async updateCustomDomain({ commit, getters }, customDomain) {
      const { data } = await localAxios.post(
        `/api/profiles/${getters.currentProfileId}/custom-domain`,
        {
          custom_domain: customDomain,
        },
      );

      commit('set', { name: 'currentProfile', value: data.profile });

      return data.profile;
    },

    async addExisting({ commit }, data) {
      return localAxios.post('/api/profiles/existing', data);
    },

    async delete({ commit }, id) {
      const { data } = await localAxios.delete(`/api/profiles/${id}`);

      commit('removeProfile', id);

      return data;
    },

    async removeProvider({ getters, commit }, provider) {
      const { data } = await localAxios.delete(
        `/api/profiles/${getters.currentProfileId}/provider/${provider}`,
      );
      commit('set', { name: 'currentProfile', value: data.profile });

      return data;
    },

    async addSocialAccount({ getters, commit }, postData) {
      const { data } = await localAxios.post(
        `/api/profiles/social/${getters.currentProfileId}`,
        postData,
      );

      commit('addSocialAccount', data);

      return data;
    },

    async updateSocialAccount({ getters }, { id, data }) {
      return localAxios.put(
        `/api/profiles/social/${getters.currentProfileId}/${id}`,
        data,
      );
    },

    async updateSocialAccountPosition({ getters }, { id, position }) {
      return localAxios.put(
        `/api/profiles/social/${getters.currentProfileId}/${id}/position`,
        { position },
      );
    },

    async deleteSocialAccount({ getters }, { id }) {
      return localAxios.delete(
        `/api/profiles/social/${getters.currentProfileId}/${id}`,
      );
    },

    async getAppAuthorizations({ getters, commit }) {
      const { data } = await localAxios.get(
        `/api/profiles/authorizations/${getters.currentProfileId}`,
      );

      commit('updateField', {
        path: 'currentProfile.app_authorizations',
        value: data,
      });
    },

    async testAppAuthorization({ getters, commit }, { _id }) {
      const { data } = await localAxios.get(
        `/api/profiles/authorizations/${getters.currentProfileId}/${_id.$oid}/test`,
      );

      commit('updateAuthorization', data);
    },

    async getGoogleAuth({ getters, commit }, { _id }) {
      const { data } = await localAxios.get(
        `/api/profiles/authorizations/${getters.currentProfileId}/${_id.$oid}/google-auth`,
      );

      return data;
    },

    async checkOut({ getters, commit }, postData) {
      try {
        const { data } = await localAxios.put(
          `/api/profiles/${getters.currentProfileId}/check-out`,
          postData,
        );

        commit('updateField', { path: 'checkOut', value: data });

        return data;
      } catch (e) {
        if (e.response && e.response.data)
          commit('updateField', { path: 'checkOut', value: e.response.data });

        throw e;
      }
    },

    async checkIn({ getters, commit }) {
      try {
        const { data } = await localAxios.put(
          `/api/profiles/${getters.currentProfileId}/check-in`,
        );

        commit('updateField', { path: 'checkOut', value: data });

        return data;
      } catch (e) {
        throw e;
      }
    },

    async getLifeTimeStats({ getters, commit }) {
      try {
        const { data } = await localAxios.get(
          `/api/profiles/${getters.currentProfileId}/lifetime-stats`,
        );

        commit('set', {
          name: 'currentProfile',
          value: {
            ...getters.currentProfile,
            lifetime_clicks: data.lifetime_clicks,
            lifetime_views: data.lifetime_views,
            lifetime_revenue: data.lifetime_revenue,
          },
        });

        return data;
      } catch (e) {
        throw e;
      }
    },

    async getNotifications({ getters, commit }) {
      try {
        const { data } = await localAxios.get(
          `/api/notifications/profiles/${getters.currentProfileId}`,
        );

        commit('set', {
          name: 'notifications',
          value: data,
        });

        return data;
      } catch (e) {
        throw e;
      }
    },
  },
};
