<template>
  <div>
    <button ref="dropzone" class="button" :disabled="!hasDropzoneLoaded">
      Upload Images
    </button>

    <div ref="preview" style="margin-top: 0.625rem"></div>
  </div>
</template>

<script>
import { captureException } from '@sentry/browser';

import DropzoneMixin from '../../mixins/dropzone';
import toast from '../../utils/toast';
import {
  dataURItoBlob,
  getOrientation,
  resetOrientation,
} from '../../utils/files';

export default {
  mixins: [DropzoneMixin],

  props: {
    /** The profile or org who owns the media */
    mediaParentId: {
      type: String,
      required: true,
    },

    organizationId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      thumbNumber: 3,
      isProcessingFile: false,
    };
  },

  computed: {
    mediaParent() {
      return this.$store.getters['media/getField'](
        `mediaParent.${this.mediaParentId}`,
      );
    },
  },

  watch: {
    hasDropzoneLoaded(newValue) {
      this.createDZ();
    },
  },

  created() {
    this.loadDropzone();
    this.unloadBind = this.unload.bind(this);

    window.addEventListener('beforeunload', this.unloadBind);
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.unloadBind);
  },

  methods: {
    unload(e) {
      if (
        this.isProcessingFile &&
        confirm(
          'Your files are uploading, if you close the window they may not finish uploading.',
        )
      ) {
        e.preventDefault();
      }
    },

    createDZ() {
      // TODO: test with caching enabled to see if get called before mounted
      this.createDropzone(this.$refs.dropzone, {
        dictDefaultMessage: '',
        resizeWidth: null,
        resizeHeight: null,
        previewsContainer: this.$refs.preview,
        resizeQuality: 1,
        previewTemplate: `<div class="file-preview">
            <div class="file-preview__content">
              <img class="file-preview__img" data-dz-thumbnail />
              <div class="file-preview__details">
                <div class="file-preview__filename"><span data-dz-name></span></div>
                <div class="file-preview__status">Waiting to upload...</div>
              </div>
              <button class="file-preview__close" type="button" data-dz-remove>
                <svg class="icon icon-close" viewBox="0 0 24 24"><path d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z"></path></svg>
                <span class="sr-only">Remove</span>
              </button>
            </div>
            <div class="file-preview__progress"><span class="file-preview__upload" data-dz-uploadprogress></span></div>
            <div class="file-preview__error"><span data-dz-errormessage></span></div>
          </div>`,
        addRemoveLinks: false,
        maxFiles: null,
      });

      // Set signed upload URL for each file
      this.dropzone.on('processing', (file) => {
        this.dropzone.options.url = file.uploadURL;
      });

      this.dropzone.on('thumbnail', (file) => {
        // ignore files which were already cropped and re-rendered
        // to prevent infinite loop
        if (file.cropped) {
          return;
        }
        if (file.width < 200 || file.height < 200) {
          file.rejected = true;
          // validate width to prevent too small files to be uploaded
          file.previewElement.classList.add('file-preview--rejected');
          file.previewElement.querySelector('.file-preview__status').innerHTML =
            'Image must be AT LEAST 200x200px';
          return;
        }
        if (!file.accepted) {
          file.previewElement.querySelector('.file-preview__status').innerHTML =
            'Image not accepted';
          file.rejected = true;
          file.previewElement.classList.add('file-preview--rejected');
          return;
        }

        if (file.orientated) this.processNextFile();
        else this.processFile(file);
      });

      this.dropzone.on('success', (file) => {
        file.previewElement.classList.add('.file-preview--completed'); // add completed class to original file preview
        file.previewElement.querySelector('.file-preview__status').innerHTML =
          'Cropping and processing...';
        // try and load the newly created file
        this.completeMediaFile(file);
        this.dropzone.removeFile(file);
        this.isProcessingFile = false;
        this.processNextFile();
      });
    },

    async processFile(file) {
      if (!file.rejected) {
        const newFile = await this.checkImageOrientation(file);
        this.dropzone.removeFile(file);
        newFile.orientated = true;
        newFile.originalFile = file;
        newFile.name = file.name;
        this.dropzone.addFile(newFile);
      }
    },

    processNextFile() {
      if (!this.isProcessingFile) {
        var files = this.dropzone.getQueuedFiles();
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (file.orientated) {
            this.isProcessingFile = true;
            this.createMediaFile(file);
          }
          break;
        }
      }
    },

    createMediaFile(file) {
      file.previewElement.classList.add('file-preview--loading');
      file.previewElement.querySelector(
        '.file-preview__status',
      ).innerHTML = `Uploading file...`;
      this.$store
        .dispatch('media/create', {
          file,
          parent: this.mediaParent,
          id: this.mediaParentId,
          organization_id: this.organizationId,
        })
        .then((media) => {
          file.path = media.path;
          file.media = media;
          this.upload(file);
        })
        .catch((err) => {
          this.fileError(file, err);
        });
    },

    upload(file) {
      this.getSignedUrl(file, file.path)
        .then((data) => {
          file.previewElement.classList.remove('file-preview--loading');
          // Manually process each file
          setTimeout(() => this.dropzone.processFile(file));
        })
        .catch((err) => {
          this.fileError(file, err);
        });
    },

    checkImageOrientation(originalFile) {
      return new Promise((resolve) => {
        getOrientation(originalFile).then((orientation) => {
          if (orientation < 0) {
            resolve(dataURItoBlob(originalFile.dataURL, originalFile.type));
          } else {
            resetOrientation(originalFile, orientation).then((dataUrl) => {
              resolve(dataURItoBlob(dataUrl, originalFile.type));
            });
          }
        });
      });
    },

    completeMediaFile(file) {
      let media = file.media;
      this.$store
        .dispatch('media/update', {
          id: this.mediaParentId,
          organization_id: this.organizationId,
          mediaId: media._id.$oid,
          data: {
            completed: true,
          },
        })
        .then((media) => {
          this.$store.commit('media/addMediaItem', {
            item: media,
            end: false,
            id: this.mediaParentId,
          });
        })
        .catch((err) => {
          this.fileError(file, err);
        });
    },

    fileError(file, error) {
      console.error(error);
      file.previewElement.classList.remove('file-preview--loading');
      file.previewElement.classList.add('dz-error');
      file.previewElement.querySelector('.file-preview__status').innerHTML =
        'Upload error';
      toast.error(
        `There was an error trying to upload ${file.name}. Please retry soon.`,
      );
      if (error) captureException(error);
    },

    // TODO prevent users from closing window when uploading media
  },
};
</script>

<style lang="scss" scoped>
.button {
  background: var(--g-color-primary);
  border: 2px solid var(--g-color-primary);
  border-radius: var(--g-border-radius-standard);
  color: var(--g-color-on-primary);
  cursor: pointer;
  display: block;
  font-family: var(--g-font-button-family);
  font-size: 1.25rem;
  font-weight: var(--g-button-font-weight);
  letter-spacing: var(--g-button-letter-spacing);
  line-height: normal;
  padding: 0.45em 1.25em;
  text-align: center;
  transition: all var(--g-transition-speed);
  width: 100%;

  &:hover,
  &:focus {
    background: var(--g-color-secondary);
  }

  &:disabled {
    background: var(--g-color-gray-100);
    border-color: var(--g-color-gray-400);
    color: var(--g-color-gray-400);
  }
}
</style>
