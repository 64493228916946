<template>
  <FancyAd dismissible-key="hide-upgrade-to-pro">
    <picture slot="img">
      <source
        srcset="~images/ads/pro-users-vertical.png"
        media="(min-width: 768px)"
      />
      <img
        class="upgrade-to-pro__img"
        src="~images/ads/pro-users.png"
        alt="Campsite.bio pro profiles"
        loading="lazy"
      />
    </picture>

    <template v-if="isNonProfit">
      <VText component="h2" variant="h4" gutter-bottom
        >Increase your impact</VText
      >
      <ul v-if="isNonProfit">
        <li>
          Get 50% off any plan with our non-profit discount. *Proof of
          non-profit status required*
        </li>
        <li>Manage multiple profiles under a single account.</li>
        <li>Invite your teammates to manage your profiles.</li>
        <li>Unlock premium link types, like the opt-in form and feed.</li>
      </ul>
    </template>
    <template v-else-if="isBusiness">
      <VText component="h2" variant="h4" gutter-bottom
        >Create a seamless experience for your customers</VText
      >
      <ul>
        <li>
          Automate your links with the feed and image grid. <br />Pull in your
          latest content automatically.
        </li>
        <li>Connect your own domain.</li>
        <li>Get access to analytics data to analyze your performance.</li>
        <li>Unlock premium link types, like the opt-in form and feed.</li>
        <li>Hide the Campsite branding.</li>
      </ul>
    </template>
    <template v-else>
      <VText component="h2" variant="h4" gutter-bottom>Join the Pros</VText>
      <ul>
        <li>
          Automate your links with the feed and image grid. <br />Pull in your
          latest content automatically.
        </li>
        <li>Connect your own domain.</li>
        <li>Get access to analytics data to analyze your performance.</li>
        <li>Unlock premium link types, like the opt-in form and feed.</li>
      </ul>
    </template>

    <InlineLink component="span">Start your free trial</InlineLink>
  </FancyAd>
</template>

<script>
import { VText, InlineLink } from '@campsite-bio/component-lib';
import { computed } from 'vue';

import FancyAd from './fancy-ad';
import { useStore } from '../../compositions';

export default {
  components: {
    FancyAd,
    VText,
    InlineLink,
  },

  setup() {
    const store = useStore();

    const userCategories = computed(() => store.getters.currentUser.categories);

    const isNonProfit = computed(() => {
      return userCategories.value?.includes(
        'Community Organization/Non-Profit',
      );
    });
    const isBusiness = computed(() => {
      return userCategories.value?.includes('Business/Entrepreneur/Non-profit');
    });

    return { isBusiness, isNonProfit };
  },
};
</script>

<style lang="scss" scoped></style>
