import { computed, nextTick, ref } from 'vue';

import { getMeta } from '@/utils';

export function useLinkActions(link) {
  const expand = ref(null);

  const isExpandOpen = computed(() => expand.value !== null);
  const isScheduled = computed(
    () => link.value.start_time || link.value.end_time,
  );
  const isHighlighted = computed(() => link.value.highlight);
  const hasImage = computed(() => !!(link.value.image || link.value.media));
  const isPinned = computed(() => link.value.pinned);
  const hasEventTag = computed(
    () => !!getMeta('event-tag', null, link.value.meta),
  );

  function isExpanded(name) {
    return expand.value === name;
  }

  async function toggleExpand(name) {
    await nextTick();

    if (expand.value === name) expand.value = null;
    else {
      expand.value = name;
    }
  }

  function closeExpand() {
    expand.value = null;
  }

  return {
    expand,
    isScheduled,
    isHighlighted,
    hasImage,
    isPinned,
    hasEventTag,
    isExpandOpen,
    isExpanded,
    toggleExpand,
    closeExpand,
  };
}
