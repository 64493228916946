<template>
  <div class="image-grid">
    <ItemImg
      v-for="item in itemsToShow"
      :key="item._id.$oid"
      :item="item"
      :link-id="link._id.$oid"
    />
    <ItemsDialog
      :items="items"
      :link="link"
      :link-id="link._id.$oid"
      :total-items="totalItems"
    >
      <template #activator="{ on, attrs }">
        <v-tooltip top :disabled="disabled">
          <template #activator="{ on: tOn, attrs: tAttrs }">
            <button
              class="image-grid__edit"
              v-bind="{ ...attrs, ...tAttrs }"
              aria-label="edit grid items"
              :disabled="disabled"
              v-on="{ ...on, ...tOn }"
            >
              <PencilIcon />
            </button>
          </template>
          <span>Edit grid items</span>
        </v-tooltip>
      </template>
    </ItemsDialog>
  </div>
</template>

<script>
import { computed } from 'vue';
import { PencilIcon } from '@campsite-bio/component-lib';

import { ItemsDialog } from './items-dialog';
import ItemImg from './item-img';

export default {
  components: {
    ItemImg,
    ItemsDialog,
    PencilIcon,
  },

  props: {
    link: {
      type: Object,
      required: true,
    },

    items: {
      type: Array,
      default: () => [],
    },

    disabled: Boolean,
  },

  setup(props) {
    const itemsToShow = computed(() => {
      return props.items.slice(0, 3);
    });

    const totalItems = computed(() => {
      return props.link.item_count || 0;
    });

    return {
      itemsToShow,
      totalItems,
    };
  },
};
</script>

<style lang="scss" scoped>
.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.25rem;
  max-width: 400px;

  &__edit {
    background: none;
    border: 0.125rem dashed var(--g-color-gray-400);
    color: var(--g-color-gray-400);
    border-radius: 0;
    align-items: center;
    font-size: 2rem;
    display: flex;
    padding: 0;
    justify-content: center;
    transition: all var(--g-transition-speed);

    &:hover,
    &:focus {
      border-color: var(--g-color-primary);
      color: var(--g-color-primary);
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.8;
    }
  }
}
</style>
