import { computed, ref, nextTick } from 'vue';
import snakeCase from 'lodash/snakeCase';
import kebabCase from 'lodash/kebabCase';

import { useStore } from '../../../compositions';
import toast from '../../../utils/toast';

export function useLinkOptions({ link, linkId, optionsName, ignoreUpdate }) {
  const isSavingOptions = ref(false);
  const isOptionsDirty = ref(false);
  const store = useStore();

  const snakeCaseOptionsName = `${snakeCase(optionsName)}_options`;

  const options = computed(() => {
    return link.value[snakeCaseOptionsName];
  });

  const hasSetup = computed(() => {
    return options.value.has_setup;
  });

  const hasApiWarning = computed(() => {
    return options.value.show_api_warning;
  });

  async function saveOptions(options) {
    isSavingOptions.value = true;

    try {
      const { link: newLink } = await store.dispatch('links/updateOptions', {
        id: linkId.value,
        name: kebabCase(optionsName),
        show_api_warning: hasApiWarning.value,
        ...options,
      });

      // Only commit changes if there aren't any new changes that will be saved shortly
      if (!isOptionsDirty.value) {
        ignoreUpdate.value = true;
        delete newLink[snakeCaseOptionsName].show_api_warning;
        store.commit('links/updateLinkOptions', {
          id: linkId.value,
          value: newLink[snakeCaseOptionsName],
          name: snakeCaseOptionsName,
        });
        await nextTick();
        ignoreUpdate.value = false;
      }
    } catch (e) {
      console.error(e);
      toast.error('There was an error trying to update your link');
    }

    isSavingOptions.value = false;
  }

  return {
    isSavingOptions,
    isOptionsDirty,
    saveOptions,
    options,
    hasSetup,
    hasApiWarning,
  };
}
