<template>
  <div class="box-input-group">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.box-input-group {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;

  ::v-deep .box-input {
    margin: 5px;
  }
}
</style>
