import Vue from 'vue';
import { localAxios } from '../apis';
import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,

  state: {
    items: {},
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,

    set(state, { linkId, items, append = false, prepend = false }) {
      const existingItems = state.items[linkId] || [];
      let newItems;
      // Go through items and make sure they aren't already in existingItems
      const filteredItems = items.filter((item) => {
        return !existingItems.find((existingItem) => {
          return existingItem._id.$oid === item._id.$oid;
        });
      });

      if (append) {
        newItems = [...existingItems, ...filteredItems];
      } else if (prepend) {
        newItems = [...filteredItems, ...existingItems];
      } else {
        newItems = items;
      }

      Vue.set(state.items, linkId, newItems);
    },

    sync(state, { linkId, items }) {
      if (!state.items[linkId]) {
        Vue.set(state.items, linkId, items);
      } else {
        const existingItems = state.items[linkId];
        // Get any items that are not in the existing items
        const newItems = items.filter(
          (item) =>
            existingItems.find(
              (existingItem) => existingItem._id.$oid === item._id.$oid,
            ) === undefined,
        );
        if (newItems.length) {
          Vue.set(state.items, linkId, [...newItems, ...existingItems]);
        }
      }
    },

    update(state, { linkId, itemId, overwrite, ...attrs }) {
      if (overwrite) {
        const index = state.items[linkId].findIndex(
          (i) => i._id.$oid === itemId,
        );
        const item = { ...state.items[linkId][index], ...attrs };
        Vue.set(state.items[linkId], index, item);
      } else {
        const item = state.items[linkId].find((i) => i._id.$oid === itemId);
        Object.keys(attrs).forEach((key) => {
          Vue.set(item, key, attrs[key]);
        });
      }
    },

    addUrl(state, { linkId, itemId, ...newUrl }) {
      const item = state.items[linkId].find((i) => i._id.$oid === itemId);
      const newItem = {
        ...item,
        urls: [...(item.urls || []), newUrl],
      };
      Vue.set(state.items[linkId], state.items[linkId].indexOf(item), newItem);
    },

    removeUrl(state, { linkId, itemId, urlId }) {
      const item = state.items[linkId].find((i) => i._id.$oid === itemId);
      const newItem = {
        ...item,
        urls: item.urls.filter((u) => u._id.$oid !== urlId),
      };
      Vue.set(state.items[linkId], state.items[linkId].indexOf(item), newItem);
    },

    updateUrl(state, { linkId, itemId, urlItem, ...attrs }) {
      const item = state.items[linkId].find((i) => i._id.$oid === itemId);
      const existingUrl = urlItem._id
        ? item.urls.find((u) => u._id?.$oid === urlItem._id.$oid)
        : item.urls[0];
      const newUrl = {
        ...existingUrl,
        ...attrs,
      };
      Vue.set(item.urls, item.urls.indexOf(existingUrl), newUrl);
    },
  },

  actions: {
    async get({ commit, getters }, { linkId, page, pageSize, force }) {
      const currentItems = getters[`getField`](`items.${linkId}`);

      if (!currentItems || force) {
        const { data } = await localAxios.get(`/api/links/items/${linkId}`, {
          params: {
            page,
            page_size: pageSize,
          },
        });

        commit('set', { linkId, items: data, append: page > 1 });
      } else {
        return currentItems;
      }
    },

    async sync({ commit }, { linkId }) {
      const { data } = await localAxios.get(`/api/links/items/${linkId}/sync`);

      if (data.new_items?.length) {
        commit('set', {
          linkId,
          items: data.new_items,
          prepend: true,
        });
      }

      // Update the sync time
      commit(
        'links/updateLinkOptions',
        {
          id: linkId,
          value: data.image_grid_options,
          name: 'image_grid_options',
        },
        { root: true },
      );

      return data;
    },

    async update({ commit }, { linkId, itemId, item }) {
      const { data: newItem } = await localAxios.put(
        `/api/linkitems/${itemId}`,
        item,
      );
      commit('update', { linkId, itemId, overwrite: true, ...newItem });
    },

    async addUrl({ commit }, { linkId, itemId, ...rest }) {
      const { data } = await localAxios.post(
        `/api/linkitems/urls/${itemId}`,
        rest,
      );
      commit('addUrl', { linkId, itemId, ...data });
    },

    async removeUrl({ commit }, { linkId, itemId, urlId }) {
      await localAxios.delete(`/api/linkitems/urls/${itemId}/${urlId}`);
      commit('removeUrl', { linkId, itemId, urlId });
    },
  },
};
