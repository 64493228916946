<template>
  <ChartCard
    class="leaderboard-views-reach-card"
    :title="`${startCase(metric)} Top 3`"
    :is-loading="isLoading"
  >
    <ChartWrapper
      width="100%"
      height="300"
      type="bar"
      :options="chartOptions"
      :data="series"
    />
    <ChartCardButton slot="actions" v-model="metric" :items="menuItems">
      {{ metric }}
    </ChartCardButton>
  </ChartCard>
</template>

<script>
import { computed, ref } from 'vue';
import startCase from 'lodash/startCase';

import { ChartCard, ChartCardButton } from '..';
import { useLeaderboardChartOptions } from '../../compositions';
import { ChartWrapper } from '../../';

export default {
  components: {
    ChartCard,
    ChartCardButton,
    ChartWrapper,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const metric = ref('reach');

    const categories = computed(() => {
      return props.data.views.slice(0, 3).map((item) => item.occurrences);
    });

    const series = computed(() => {
      const data = props.data[metric.value].slice(0, 3);

      return data.reduce(
        (acc, stat, i) => {
          acc.datasets[0].data.push(stat.count);
          return acc;
        },
        {
          datasets: [
            {
              label: startCase(metric.value),
              data: [],
            },
          ],
          labels: categories.value,
        },
      );
    });

    const chartOptions = useLeaderboardChartOptions();

    return {
      chartOptions,
      series,
      metric,
      startCase,
      menuItems: [
        { name: 'Reach', value: 'reach' },
        {
          name: 'Views',
          value: 'views',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.leaderboard-views-reach-card {
  min-height: unset;
}
</style>
