<template>
  <Fragment>
    <CardText>
      <Alert color="error" style="margin-bottom: 1rem">
        <ErrorIcon slot="icon" />
        Connection error with
        {{ item.provider_title }}
      </Alert>

      <VText
        >We're having trouble connecting with this integration. Click "Retry" to
        fix this issue and re-authorize your account.</VText
      >
    </CardText>

    <CardActions>
      <Button
        variant="flat"
        color="error"
        :loading="isDeleting"
        @click="$emit('remove')"
      >
        Delete
      </Button>
      <Button
        variant="flat"
        :disabled="isDeleting || item.disabled"
        :loading="isLoading"
        @click="authorize"
        >Retry</Button
      >
    </CardActions>
  </Fragment>
</template>

<script>
import {
  CardText,
  CardActions,
  Button,
  VText,
  Alert,
  ErrorIcon,
  Fragment,
} from '@campsite-bio/component-lib';
import { nextTick, ref } from 'vue';

import {
  useFacebookAuthorization,
  useGoogleAuthorization,
  useInstagramAuthorization,
  usePayPalAuthorization,
  useSquareAuthorization,
  useStore,
} from '../../../compositions';
import bus from '../../../bus';

export default {
  components: {
    Fragment,
    CardText,
    CardActions,
    Button,
    VText,
    Alert,
    ErrorIcon,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },

    isDeleting: Boolean,
  },

  setup(props, { emit }) {
    const store = useStore();
    const isLoading = ref(false);

    const authorizationParams = {
      onOpen() {
        isLoading.value = true;
      },
      onCancel() {
        isLoading.value = false;
      },
      async onSuccess(data) {
        store.dispatch('profiles/getAppAuthorizations');
        isLoading.value = false;
        emit('success');
        await nextTick();
        bus.$emit(`app-authorization:${data.authId}:open`);
      },
    };

    let authorization;
    switch (props.item.provider) {
      case 'instagram':
        authorization = useInstagramAuthorization(authorizationParams, {
          type: 'reauthorize',
        });
        break;
      case 'facebook':
        authorization = useFacebookAuthorization(
          props.item.campsite_id.$oid,
          authorizationParams,
          {
            type: 'reauthorize',
          },
        );
        break;
      case 'google':
        authorization = useGoogleAuthorization(
          authorizationParams,
          {
            type: 'reauthorize',
          },
          'google_sheets',
        );
        break;
      case 'square':
        authorization = useSquareAuthorization(authorizationParams, {
          type: 'reauthorize',
        });
        break;
      case 'paypal':
        authorization = usePayPalAuthorization(
          {
            type: 'reauthorize',
          },
          props.item.data.currency,
        );
        break;
    }

    return { isLoading, ...authorization };
  },
};
</script>

<style lang="scss" scoped></style>
