<template>
  <tr :key="item.permalink">
    <td class="text-start">
      <div class="post">
        <div class="post__img">
          <img :src="imageUrl" :alt="item.caption" width="100" loading="lazy" />
          <InstagramCarouselIcon v-if="item.media_type === 'CAROUSEL_ALBUM'" />
          <InstagramVideoIcon v-else-if="item.media_type === 'VIDEO'" />
        </div>
        <div class="post__content">
          <div class="post__title">
            {{ item.date }}
          </div>
          <p class="post__caption" :title="item.caption">{{ item.caption }}</p>
        </div>
      </div>
    </td>
    <td :style="getCellColor(item.engagement_rate, 'engagement_rate')">
      {{ item.engagement_rate | numberFormatter(2) }}%
      {{
        getCellColor(item.engagement_rate, 'engagement_rate').backgroundColor
      }}
    </td>
    <td :style="getCellColor(item.impressions, 'impressions')">
      <template v-if="item.media_type === 'VIDEO'">N/A</template>
      <template v-else>{{ item.impressions | numberFormatter(2) }}</template>
    </td>
    <td :style="getCellColor(item.reach, 'reach')">
      {{ item.reach | numberFormatter(2) }}
    </td>
    <td :style="getCellColor(item.like_count, 'like_count')">
      {{ item.like_count | numberFormatter(2) }}
    </td>
    <td :style="getCellColor(item.comments_count, 'comments_count')">
      {{ item.comments_count | numberFormatter(2) }}
    </td>
    <td :style="getCellColor(item.saved, 'saved')">
      {{ item.saved | numberFormatter(2) }}
    </td>
    <td :style="getCellColor(item.plays, 'plays')">
      <template v-if="item.media_type === 'VIDEO'">{{
        item.plays | numberFormatter(2)
      }}</template>
      <template v-else>N/A</template>
    </td>
    <td class="text-end">
      <div class="print-hide">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <IconButton v-bind="attrs" v-on="on">
              <DotsVerticalIcon />
            </IconButton>
          </template>
          <List>
            <ListItem :href="item.permalink" target="_blank">
              <ListItemIcon>
                <ExternalLinkIcon />
              </ListItemIcon>
              <ListItemTitle>View Post</ListItemTitle>
            </ListItem>
            <ListItem :href="item.cdn_media_url" target="_blank" download>
              <ListItemIcon>
                <FileDownloadIcon />
              </ListItemIcon>
              <ListItemTitle>Download Image</ListItemTitle>
            </ListItem>
            <ListItem
              v-if="item.cdn_video_url"
              :href="item.cdn_video_url"
              target="_blank"
              download
            >
              <ListItemIcon>
                <FileDownloadIcon />
              </ListItemIcon>
              <ListItemTitle>Download Video</ListItemTitle>
            </ListItem>
          </List>
        </v-menu>
      </div>
    </td>
  </tr>
</template>

<script>
import {
  IconButton,
  ExternalLinkIcon,
  DotsVerticalIcon,
  InstagramCarouselIcon,
  InstagramVideoIcon,
  FileDownloadIcon,
  List,
  ListItem,
  ListItemTitle,
  ListItemIcon,
  convertImageUrlToEncodedUrl,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';

export default {
  components: {
    IconButton,
    ExternalLinkIcon,
    DotsVerticalIcon,
    InstagramCarouselIcon,
    InstagramVideoIcon,
    FileDownloadIcon,
    List,
    ListItem,
    ListItemTitle,
    ListItemIcon,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },

    getCellColor: {
      type: Function,
      required: true,
    },
  },

  setup(props) {
    const imageUrl = computed(() => {
      return convertImageUrlToEncodedUrl(props.item.cdn_media_url, 200);
    });

    return { imageUrl };
  },
};
</script>

<style lang="scss" scoped>
td {
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

.post {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  width: 11rem;

  &__img {
    flex-shrink: 0;
    position: relative;

    img {
      background: var(--g-color-gray-100);
      display: block;
      width: 4.5rem;
      height: 4.5rem;
      object-fit: cover;
      overflow: hidden;
    }

    &::v-deep {
      .v-svg-icon {
        display: inline-flex;
        color: #fff;
        position: absolute;
        top: 0.25rem;
        right: 0.25rem;
        font-size: 1rem;
      }
    }
  }

  &__content {
    flex: 1;
    min-width: 0;
  }

  &__title {
    font-weight: 700;
    margin-bottom: 0.25rem;
  }

  &__caption {
    display: block;
    overflow: hidden;
    height: 3.25em;
    margin: 0;
    font-size: 0.75rem;
  }
}
</style>
