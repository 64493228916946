<template>
  <v-menu offset-y max-width="220px" max-height="400px">
    <template #activator="{ on, attrs }">
      <FilterBtn :disabled="menuItems.length === 0" v-bind="attrs" v-on="on">
        <span style="display: inline-flex; gap: 0.25rem; align-items: center">
          <InstagramIcon v-if="auth && auth.provider === 'facebook'" />
          <YoutubeIcon v-if="auth && auth.provider === 'youtube'" />
          <span>{{ auth ? auth.name : '-' | truncate(20) }}</span>
        </span>
        <ChevronIcon />
      </FilterBtn>
    </template>

    <AppAuthSelectorList v-model="selectedAuth" :items="menuItems" />
  </v-menu>
</template>

<script>
import {
  ChevronIcon,
  YoutubeIcon,
  InstagramIcon,
} from '@campsite-bio/component-lib';
import { computed, toRefs } from 'vue';

import FilterBtn from './filter-btn';
import { AppAuthSelectorList } from '../../app-authorizations';
import { useModel, useStore } from '../../../compositions';

export default {
  components: {
    FilterBtn,
    ChevronIcon,
    YoutubeIcon,
    InstagramIcon,
    AppAuthSelectorList,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const { value } = toRefs(props);
    const selectedAuth = useModel(value, (val) => emit('input', val));
    const auths = computed(
      () => store.getters['analytics/channelAuthAccounts'],
    );

    const auth = computed(() => {
      return auths.value?.find((p) => p._id.$oid === selectedAuth.value);
    });

    const menuItems = computed(() => {
      return auths.value
        ?.map((p) => ({
          name: p.name,
          value: p._id.$oid,
          provider: 'instagram',
        }))
        .sort((a, b) => a.name?.localeCompare(b.name));
    });

    return {
      selectedAuth,
      auth,
      menuItems,
    };
  },
};
</script>

<style lang="scss" scoped></style>
