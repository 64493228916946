<template>
  <LinkWrapper v-bind="$props" @add-link="$emit('add-link', $event)">
    <LinkCard v-if="hasSetup">
      <ValidationObserver ref="form">
        <LinkField v-if="hasApiWarning">
          <Alert>
            <InfoIcon slot="icon" />
            The API key for your {{ options.source }} integration has changed.
            Make sure your opt-in form still works.
            <Button
              slot="actions"
              variant="flat"
              color="white"
              style="width: 80px"
              :loading="isSavingOptions"
              @click="clearAPIWarning"
            >
              Got it
            </Button>
          </Alert>
        </LinkField>

        <ValidationProvider
          v-slot="{ errors, ariaInput, ariaMsg }"
          :rules="labelRules"
          name="title"
          immediate
          slim
        >
          <LinkField>
            <Label :for="'label' + _uid" class="sr-only"
              >Opt-in Form label</Label
            >
            <EmojiInput
              :id="'label' + _uid"
              ref="labelRef"
              v-model="label"
              :has-error="errors.length > 0"
              border-color="gray200"
              autocomplete="off"
              maxlength="80"
              placeholder="Sign up for our Emails!"
              :show-emoji="isLargeScreen"
              :disabled="isReadonly"
              v-bind="ariaInput"
              @focus="focusLink"
            >
              <PencilIcon slot="prepend" title="Edit" />
            </EmojiInput>
            <FormGroupHelp color="error" v-bind="ariaMsg">{{
              errors[0]
            }}</FormGroupHelp>
          </LinkField>
        </ValidationProvider>

        <div>
          <Label block gutter-bottom
            >{{ startCase(options.type) }} Destination</Label
          >
          <VText component="span" class="source">
            <component :is="emailType.iconComponent" />
            {{ destinationTitle }}
          </VText>
        </div>
      </ValidationObserver>

      <v-media-selector
        slot="media"
        v-model="mediaImage"
        :has-future-image="hasFutureImage"
        class="media-selector--post"
        :disabled="isReadonly"
        :loading="isSavingImage"
        @select="selectMedia"
        @remove="imageRemove"
      />

      <template slot="btm">
        <LinkBtn
          v-if="!isStatsHidden"
          :active="isExpanded('stats')"
          :aria-label="`Link analytics - ${link.number_of_clicks} clicks`"
          :aria-controls="`stats-${id}`"
          @click="toggleExpand('stats')"
        >
          <LineChartIcon slot="icon" />
          <template v-if="link.number_of_clicks" slot="stat">
            {{ link.number_of_clicks | numberFormatter(1) }}
          </template>
        </LinkBtn>
        <LinkBtn
          v-if="!isDeleted && !isScheduleHidden"
          aria-label="Schedule link"
          :active="isExpanded('schedule')"
          :highlight="isExpanded('schedule')"
          :aria-controls="`schedule-${id}`"
          @click="toggleExpand('schedule')"
        >
          <CalendarIcon slot="icon" />
        </LinkBtn>
        <v-tooltip v-if="!isDeleted && !isPinnedHidden" top>
          <template #activator="{ on }">
            <LinkBtn
              v-if="!isDeleted"
              aria-label="Pin link"
              :pinned="pinned"
              :disabled="isPinnedDisabled"
              v-on="on"
              @click="pinned = !pinned"
            >
              <PinIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>{{ pinnedText }}</span>
        </v-tooltip>
        <LinkBtn
          v-if="!isDeleted && !isFreePlan && !isHighlightHidden"
          aria-label="Highlight link"
          :active="isExpanded('highlight')"
          :highlight="!!highlight"
          :aria-controls="`highlight-${id}`"
          @click="toggleExpand('highlight')"
        >
          <template slot="icon">
            <StarIcon v-if="highlight" />
            <StarOIcon v-else />
          </template>
        </LinkBtn>
        <v-tooltip v-if="!isFreePlan && !isDeleted && !isEventTagHidden" top>
          <template #activator="{ on }">
            <LinkBtn
              aria-label="Event tag"
              :active="isExpanded('event-tag')"
              :aria-controls="`event-label-${id}`"
              v-on="on"
              @click="toggleExpand('event-tag')"
            >
              <TagIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>Event tag</span>
        </v-tooltip>
        <v-tooltip v-if="isDeleted" top>
          <template #activator="{ on }">
            <LinkBtn
              class="link__btn--restore js-link-restore"
              aria-label="Restore link"
              :disabled="isRestoring || isFreePlan"
              v-on="on"
              @click="handleRestore"
            >
              <RepeatIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>{{ isFreePlan ? 'Upgrade to restore' : 'Restore link' }}</span>
        </v-tooltip>
      </template>

      <template slot="actions">
        <HighlightExpand
          v-model="highlight"
          :is-open="isExpanded('highlight')"
          :link-enabled="link.enabled"
          @close="toggleExpand('highlight')"
        />
        <StatsExpand
          :is-open="isExpanded('stats')"
          @close="toggleExpand('stats')"
        />
        <ScheduleExpand
          :is-open="isExpanded('schedule')"
          :link="link"
          @close="toggleExpand('schedule')"
        />
        <EventLabelExpand
          v-if="!isFreePlan"
          :is-open="isExpanded('event-tag')"
          :link="link"
          @close="toggleExpand('event-tag')"
        />
        <EmailSettingsExpand
          :is-open="isExpanded('default')"
          :link="link"
          :email-options="options"
          @close="toggleExpand('default')"
          @save="saveOptions"
          @change:dirty="isOptionsDirty = $event"
        />
      </template>
    </LinkCard>
    <EmailSetup
      v-else
      :link-id="id"
      :link="link"
      :email-options="options"
      :is-deleting="isDeleting"
      @setup-complete="toggleExpand('email')"
      @remove="handleDelete"
    />
  </LinkWrapper>
</template>

<script>
import {
  Alert,
  InfoIcon,
  FlexSpacer,
  VisibilityToggle,
  EmailIcon,
  PhoneIcon,
  DragIcon,
  LineChartIcon,
  CalendarIcon,
  PinIcon,
  StarIcon,
  StarOIcon,
  RepeatIcon,
  ArchiveIcon,
  TrashIcon,
  TagIcon,
  CircularLoader,
  Label,
  FormGroupHelp,
  EmojiInput,
  PencilIcon,
  VText,
  Button,
} from '@campsite-bio/component-lib';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { computed } from 'vue';
import startCase from 'lodash/startCase';

import {
  HighlightExpand,
  StatsExpand,
  ScheduleExpand,
  EventLabelExpand,
} from '../expand';

import EmailSetup from './email-setup';
import EmailSettingsExpand from './settings-expand';
import { EMAIL_TYPES, EMAIL_ICONS } from './email-types';
import { LinkBtn, LinkWrapper, LinkCard, LinkField } from '../components';
import { LinkProps } from '../link-props';
import { useLink } from '../compositions';

export default {
  components: {
    Alert,
    InfoIcon,
    FlexSpacer,
    HighlightExpand,
    StatsExpand,
    ScheduleExpand,
    EventLabelExpand,
    VisibilityToggle,
    EmailIcon,
    PhoneIcon,
    DragIcon,
    LineChartIcon,
    CalendarIcon,
    PinIcon,
    StarIcon,
    StarOIcon,
    RepeatIcon,
    ArchiveIcon,
    TrashIcon,
    TagIcon,
    CircularLoader,
    FlexSpacer,
    LinkBtn,
    Label,
    FormGroupHelp,
    EmojiInput,
    PencilIcon,
    VText,
    Alert,
    InfoIcon,
    Button,
    ValidationObserver,
    ValidationProvider,
    EmailSettingsExpand,
    EmailSetup,
    ...EMAIL_ICONS,
    LinkWrapper,
    LinkCard,
    LinkField,
  },

  props: LinkProps,

  setup(props) {
    const {
      link,
      isSavingOptions,
      isOptionsDirty,
      options,
      hasSetup,
      saveOptions,
      hasApiWarning,
      ...rest
    } = useLink(props, {
      hasMedia: true,
      optionsName: 'email',
      expandOnSetup: 'default',
    });

    const emailType = computed(() => {
      if (!options.value.source) return null;
      return EMAIL_TYPES.find((type) => type.source === options.value.source);
    });

    const destinationTitle = computed(() => {
      return options.value.destination_title;
    });

    function clearAPIWarning() {
      saveOptions({
        ...options.value,
        show_api_warning: null,
      });
    }

    return {
      ...rest,
      options,
      hasSetup,
      isSavingOptions,
      saveOptions,
      emailType,
      destinationTitle,
      startCase,
      hasApiWarning,
      clearAPIWarning,
      isOptionsDirty,
    };
  },
};
</script>

<style lang="scss" scoped>
.source {
  align-items: center;
  display: inline-flex;

  > * {
    margin-right: 0.5rem;
  }
}
</style>
