<template>
  <Fragment>
    <VSelect v-bind="{ ...$attrs, ...$props }" outlined v-on="$listeners">
      <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />
      <template v-for="(index, name) in $scopedSlots" #[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
    </VSelect>
    <ListItem v-show="false" />
  </Fragment>
</template>

<script>
import { ListItem, Fragment } from '@campsite-bio/component-lib';
import { VSelect } from 'vuetify/lib';

export default {
  components: {
    VSelect,
    ListItem,
    Fragment,
  },

  props: {
    ...VSelect.props,
  },
};
</script>

<style lang="scss">
@import '../../styles/v-input';
@import '../../styles/v-text-field';
@import '../../styles/v-list';

.theme--light.v-select .v-select__selections {
  color: rgba(0, 0, 0, 0.87);
  min-height: 10px;
}
.theme--light.v-select.v-input--is-disabled .v-select__selections {
  color: rgba(0, 0, 0, 0.38);
}
.theme--light.v-select .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme--light.v-select.v-text-field--solo-inverted.v-input--is-focused
  .v-select__selections {
  color: #ffffff;
}

.theme--light.v-text-field--outlined
  > .v-input__control
  > .v-input__slot
  fieldset {
  background: white;
}

.v-select {
  position: relative;
}
.v-select:not(.v-select--is-multi).v-text-field--single-line
  .v-select__selections {
  flex-wrap: nowrap;
}
.v-select > .v-input__control > .v-input__slot {
  cursor: pointer;
}
.v-select .v-chip {
  flex: 0 1 auto;
  margin: 4px;
}
.v-select .v-chip--selected:after {
  opacity: 0.22;
}
.v-select .fade-transition-leave-active {
  position: absolute;
  left: 0;
}
.v-select.v-input--is-dirty ::-moz-placeholder {
  color: transparent !important;
}
.v-select.v-input--is-dirty :-ms-input-placeholder {
  color: transparent !important;
}
.v-select.v-input--is-dirty ::placeholder {
  color: transparent !important;
}
.v-select:not(.v-input--is-dirty):not(.v-input--is-focused)
  .v-text-field__prefix {
  line-height: 20px;
  top: 7px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-select.v-text-field--enclosed:not(.v-text-field--single-line):not(
    .v-text-field--outlined
  )
  .v-select__selections {
  padding-top: 20px;
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: 8px 0;
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line).v-input--dense
  .v-select__selections {
  padding: 4px 0;
}
.v-select.v-text-field input {
  border: none;
  flex: 1 1;
  min-width: 0;
  pointer-events: none;
  position: relative;
}
.v-select.v-text-field:not(.v-text-field--single-line) input {
  margin-top: 0;
}
.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(180deg);
}
.v-select.v-select--chips input {
  margin: 0;
}
.v-select.v-select--chips .v-select__selections {
  min-height: 42px;
}
.v-select.v-select--chips.v-input--dense .v-select__selections {
  min-height: 40px;
}
.v-select.v-select--chips .v-chip--select.v-chip--active::before {
  opacity: 0.2;
}
.v-select.v-select--chips.v-select--chips--small .v-select__selections {
  min-height: 26px;
}
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box
  .v-select__selections,
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed
  .v-select__selections {
  min-height: 68px;
}
.v-select.v-select--chips:not(
    .v-text-field--single-line
  ).v-text-field--box.v-input--dense
  .v-select__selections,
.v-select.v-select--chips:not(
    .v-text-field--single-line
  ).v-text-field--enclosed.v-input--dense
  .v-select__selections {
  min-height: 40px;
}
.v-select.v-select--chips:not(
    .v-text-field--single-line
  ).v-text-field--box.v-select--chips--small
  .v-select__selections,
.v-select.v-select--chips:not(
    .v-text-field--single-line
  ).v-text-field--enclosed.v-select--chips--small
  .v-select__selections {
  min-height: 26px;
}
.v-select.v-select--chips:not(
    .v-text-field--single-line
  ).v-text-field--box.v-select--chips--small.v-input--dense
  .v-select__selections,
.v-select.v-select--chips:not(
    .v-text-field--single-line
  ).v-text-field--enclosed.v-select--chips--small.v-input--dense
  .v-select__selections {
  min-height: 38px;
}
.v-select.v-text-field--reverse .v-select__slot,
.v-select.v-text-field--reverse .v-select__selections {
  flex-direction: row-reverse;
}
.v-select__selections {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  line-height: 18px;
  max-width: 100%;
  min-width: 0;
}
.v-select__selection {
  max-width: 90%;
}
.v-select__selection--comma {
  margin: 7px 4px 7px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.v-select.v-input--dense .v-select__selection--comma {
  margin: 5px 4px 3px 0;
}
.v-select.v-input--dense .v-chip {
  margin: 0 4px 0 4px;
}
.v-select__slot {
  position: relative;
  align-items: center;
  display: flex;
  max-width: 100%;
  min-width: 0;
  width: 100%;
}
.v-select:not(.v-text-field--single-line):not(.v-text-field--outlined)
  .v-select__slot
  > input {
  align-self: flex-end;
}
</style>
