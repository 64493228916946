<template>
  <div class="media-inner">
    <div class="media-inner__toolbar">
      <FormGroup>
        <Label for="search"> Search Patterns </Label>
        <Input id="search" v-model="query" placeholder="Search by keyword">
          <IconButton
            slot="prepend"
            aria-label="Search"
            :loading="isLoading"
            @click="searchDebounce"
          >
            <SearchIcon />
          </IconButton>
        </Input>
      </FormGroup>
      <Divider color="lightgray" />
    </div>
    <div
      v-infinite-scroll="nextPage"
      class="media-inner__content"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="100"
      infinite-scroll-immediate-check="false"
    >
      <div class="media-items-row row row--no-gutters">
        <div
          v-for="(image, index) in results"
          :key="image.id"
          class="col col--xs-6 col--no-gutters"
        >
          <v-media-item
            ref="mediaItem"
            :checked="selected && selected._id.$oid == image._id.$oid"
            :index="index"
            :url="image.asset_url"
            :alt="image.title"
            :caption="getCaption(image)"
            pattern
            @select="selected = image"
            @double-click="doubleClick(image, index)"
          />
        </div>
      </div>

      <div v-show="isLoading" class="text--xs-center">
        <CircularLoader size="2.5rem" style="margin: 1rem auto" />
      </div>
    </div>
    <div class="media-inner__actions">
      <Divider color="lightgray" />
      <CardActions>
        <Button :disabled="!selected" @click="save">Select</Button>
      </CardActions>
    </div>
  </div>
</template>

<script>
import {
  CardActions,
  Button,
  Divider,
  CircularLoader,
  FormGroup,
  Label,
  Input,
  SearchIcon,
  IconButton,
} from '@campsite-bio/component-lib';
import VMediaItem from './media-item.vue';
import debounce from 'lodash/debounce';
import toast from '../../utils/toast';
import { localAxios } from '../../apis';

export default {
  components: {
    VMediaItem,
    CardActions,
    Button,
    Divider,
    CircularLoader,
    FormGroup,
    Label,
    Input,
    SearchIcon,
    IconButton,
  },

  props: {
    show: Boolean,
  },

  data() {
    return {
      query: '',
      previousQuery: '',
      page: 1,
      pageSize: 30,
      results: [],
      selected: undefined,
      isLoading: true,
      disableLoad: false,
    };
  },

  computed: {
    busy() {
      return this.isLoading || this.disableLoad;
    },

    showMediaDialog() {
      return this.$store.getters.showMediaDialog;
    },
  },

  watch: {
    query() {
      if (this.query !== this.previousQuery) this.resetPaging();
      this.previousQuery = this.query;

      this.searchDebounce();
    },

    page() {
      this.searchDebounce();
    },

    show(newValue) {
      if (newValue) this.search();
    },

    showMediaDialog(newValue) {
      if (newValue && this.show) this.search();
    },
  },

  created() {
    this.searchDebounce = debounce(this.search, 1000);
    this.search();
  },

  methods: {
    getCaption(image) {
      return `<a href="${image.author_url}" target="_blank">${image.title} - ${image.author}</a>`;
    },

    resetPaging() {
      this.page = 1;
    },

    async search() {
      this.isLoading = true;

      try {
        const { data } = await localAxios.get('/api/assets/patterns', {
          params: {
            page: this.page,
            pageSize: this.pageSize,
            query: this.query,
          },
        });

        if (!data.has_more) this.disableLoad = true;

        if (this.page === 1) this.results = data.patterns;
        else this.results = this.results.concat(data.patterns);
      } catch (e) {
        console.error(e);
        toast.error(
          'There was an error getting background patterns. Please try again soon.',
        );
      }

      this.isLoading = false;
    },

    nextPage() {
      this.page++;
    },

    // if the user is clicking the same image twice, select the image
    doubleClick: function (item, index) {
      if (this.$refs.mediaItem[index].checked) {
        this.selected = item;
        this.save();
      }
    },

    save() {
      this.$emit('select', {
        source: 'pattern',
        url: this.selected.asset_url,
        media: this.selected,
      });

      localAxios.put(`/api/assets/patterns/${this.selected._id.$oid}/download`);

      this.selected = undefined;
    },
  },
};
</script>

<style scoped></style>
