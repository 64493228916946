<template>
  <div class="colors-legend" v-bind="$attrs" v-on="$listeners">
    <div class="colors-legend__title">
      <div class="colors-legend__label">LOW</div>
      <span>{{ title }}</span>
      <div class="colors-legend__label">HIGH</div>
    </div>
    <div class="colors-legend__items">
      <div
        v-for="({ backgroundColor }, index) in colors"
        :key="index"
        class="colors-legend__item"
        :style="{ backgroundColor }"
      >
        <div class="sr-only">
          {{ backgroundColor }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    colors: {
      type: Array,
      required: true,
    },

    title: {
      type: String,
      default: 'Performance',
    },
  },

  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.colors-legend {
  display: inline-block;

  &__title {
    color: var(--g-color-gray-500);
    font-weight: 500;
    font-size: 0.85rem;
    display: flex;
    justify-items: space-between;
    align-items: flex-end;
    gap: 2.5rem;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1rem, 1fr));
    flex-shrink: 0;
  }

  &__item {
    height: 1rem;
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  &__label {
    color: var(--g-color-gray-700);
    font-weight: 700;
    font-size: 0.75rem;
  }
}
</style>
