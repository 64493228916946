<template>
  <VDataTable v-bind="{ ...$attrs, ...$props }" v-on="$listeners">
    <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />
    <template v-for="(index, name) in $scopedSlots" #[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
  </VDataTable>
</template>

<script>
import { VDataTable } from 'vuetify/lib';

export default {
  components: {
    VDataTable,
  },

  props: {
    ...VDataTable.props,
  },
};
</script>

<style lang="scss">
@import '../../styles/v-progress';

.v-data-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.75rem;
  padding: 0 8px;
}
.v-data-footer .v-btn {
  color: inherit;
}

.v-application--is-ltr .v-data-footer__icons-before .v-btn:last-child {
  margin-right: 7px;
}
.v-application--is-rtl .v-data-footer__icons-before .v-btn:last-child {
  margin-left: 7px;
}

.v-application--is-ltr .v-data-footer__icons-after .v-btn:first-child {
  margin-left: 7px;
}
.v-application--is-rtl .v-data-footer__icons-after .v-btn:first-child {
  margin-right: 7px;
}

.v-data-footer__pagination {
  display: block;
  text-align: center;
}
.v-application--is-ltr .v-data-footer__pagination {
  margin: 0 32px 0 24px;
}
.v-application--is-rtl .v-data-footer__pagination {
  margin: 0 24px 0 32px;
}

.v-data-footer__select {
  display: flex;
  align-items: center;
  flex: 0 0 0;
  justify-content: flex-end;
  white-space: nowrap;
}
.v-application--is-ltr .v-data-footer__select {
  margin-left: auto;
  margin-right: 14px;
}
.v-application--is-rtl .v-data-footer__select {
  margin-left: 14px;
  margin-right: auto;
}
.v-data-footer__select .v-select {
  flex: 0 1 0;
  padding: 0;
  position: initial;
}
.v-application--is-ltr .v-data-footer__select .v-select {
  margin: 13px 0 13px 34px;
}
.v-application--is-rtl .v-data-footer__select .v-select {
  margin: 13px 34px 13px 0;
}
.v-data-footer__select .v-select__selections {
  flex-wrap: nowrap;
}
.v-data-footer__select .v-select__selections .v-select__selection--comma {
  font-size: 0.75rem;
}
.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #f5f5f5;
}
.theme--light.v-data-table .v-row-group__header,
.theme--light.v-data-table .v-row-group__summary {
  background: #eeeeee;
}
.theme--light.v-data-table .v-data-footer {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table .v-data-table__empty-wrapper {
  color: rgba(0, 0, 0, 0.38);
}

.theme--dark.v-data-table tbody tr.v-data-table__selected {
  background: #505050;
}
.theme--dark.v-data-table .v-row-group__header,
.theme--dark.v-data-table .v-row-group__summary {
  background: #616161;
}
.theme--dark.v-data-table .v-data-footer {
  border-top: thin solid rgba(255, 255, 255, 0.12);
}
.theme--dark.v-data-table .v-data-table__empty-wrapper {
  color: rgba(255, 255, 255, 0.5);
}

.v-data-table {
  border-radius: var(--g-border-radius-standard);
  border-radius: min(
    var(--g-border-radius-standard),
    var(--g-border-radius-min)
  );
}
.v-data-table--mobile > .v-data-table__wrapper tbody {
  display: flex;
  flex-direction: column;
}
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded {
  border-bottom: 0;
}
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: inset 0px 4px 8px -5px rgba(50, 50, 50, 0.75),
    inset 0px -4px 8px -5px rgba(50, 50, 50, 0.75);
}
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr:first-child:hover
  td:first-child {
  border-top-left-radius: 4px;
}
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr:first-child:hover
  td:last-child {
  border-top-right-radius: 4px;
}
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr:last-child:hover
  td:first-child {
  border-bottom-left-radius: 4px;
}
.v-data-table > .v-data-table__wrapper tbody tr:last-child:hover td:last-child {
  border-bottom-right-radius: 4px;
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  display: initial;
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  height: initial;
  min-height: 48px;
}

.v-data-table__empty-wrapper {
  text-align: center;
}

.v-data-table__mobile-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.v-data-table__mobile-row__header {
  font-weight: 600;
}
.v-application--is-ltr .v-data-table__mobile-row__header {
  padding-right: 16px;
}
.v-application--is-rtl .v-data-table__mobile-row__header {
  padding-left: 16px;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
  text-align: right;
}
.v-application--is-rtl .v-data-table__mobile-row__cell {
  text-align: left;
}

.v-row-group__header td,
.v-row-group__summary td {
  height: 35px;
}

.v-data-table__expand-icon {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.v-data-table__expand-icon--active {
  transform: rotate(-180deg);
}
.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: rgba(0, 0, 0, 0.38);
}
.theme--light.v-data-table .v-data-table-header th.sortable:hover,
.theme--light.v-data-table .v-data-table-header th.sortable.active {
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-data-table
  .v-data-table-header
  th.sortable.active
  .v-data-table-header__icon {
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-data-table .v-data-table-header__sort-badge {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: rgba(255, 255, 255, 0.5);
}
.theme--dark.v-data-table .v-data-table-header th.sortable:hover,
.theme--dark.v-data-table .v-data-table-header th.sortable.active {
  color: #ffffff;
}
.theme--dark.v-data-table
  .v-data-table-header
  th.sortable.active
  .v-data-table-header__icon {
  color: #ffffff;
}
.theme--dark.v-data-table .v-data-table-header__sort-badge {
  background-color: rgba(255, 255, 255, 0.12);
  color: #ffffff;
}

.v-data-table-header th.sortable {
  pointer-events: auto;
  cursor: pointer;
  outline: 0;
}
.v-data-table-header th.sortable .v-data-table-header__icon {
  line-height: 0.9;
}
.v-data-table-header th.active .v-data-table-header__icon,
.v-data-table-header th:hover .v-data-table-header__icon {
  transform: none;
  opacity: 1;
}
.v-data-table-header th.desc .v-data-table-header__icon {
  transform: rotate(-180deg);
}

.v-data-table-header__icon {
  display: inline-block;
  opacity: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-data-table-header__sort-badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  border-radius: 50%;
  min-width: 18px;
  min-height: 18px;
  height: 18px;
  width: 18px;
}

.v-data-table-header-mobile th {
  height: initial;
}

.v-data-table-header-mobile__wrapper {
  display: flex;
}
.v-data-table-header-mobile__wrapper .v-select {
  margin-bottom: 8px;
}
.v-data-table-header-mobile__wrapper .v-select .v-chip {
  height: 24px;
}
.v-data-table-header-mobile__wrapper .v-select .v-chip__close.desc .v-icon {
  transform: rotate(-180deg);
}

.v-data-table-header-mobile__select {
  min-width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.theme--light.v-data-table {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-data-table .v-data-table__divider {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #ffffff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: rgba(0, 0, 0, 0.6);
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.active {
  background: #f5f5f5;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #eeeeee;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tfoot
  > tr
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tfoot
  > tr
  > th:not(.v-data-table__mobile-row) {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}

.v-data-table {
  line-height: 1.5;
  max-width: 100%;
}
.v-data-table > .v-data-table__wrapper > table {
  width: 100%;
  border-spacing: 0;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 16px;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.75rem;
  height: 48px;
}
.v-application--is-ltr
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > th,
.v-application--is-ltr
  .v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th,
.v-application--is-ltr
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tfoot
  > tr
  > th {
  text-align: left;
}
.v-application--is-rtl
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > th,
.v-application--is-rtl
  .v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th,
.v-application--is-rtl
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tfoot
  > tr
  > th {
  text-align: right;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 0.875rem;
  height: 48px;
}

.v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

.v-data-table__progress {
  height: auto !important;
}
.v-data-table__progress th {
  height: auto !important;
  border: none !important;
  padding: 0;
  position: relative;
}

.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 32px;
}
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > th {
  height: 32px;
}

.v-data-table--has-top
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:first-child:hover
  > td:first-child {
  border-top-left-radius: 0;
}
.v-data-table--has-top
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:first-child:hover
  > td:last-child {
  border-top-right-radius: 0;
}

.v-data-table--has-bottom
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:last-child:hover
  > td:first-child {
  border-bottom-left-radius: 0;
}
.v-data-table--has-bottom
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:last-child:hover
  > td:last-child {
  border-bottom-right-radius: 0;
}

.v-data-table--fixed-height .v-data-table__wrapper {
  overflow-y: auto;
}

.v-data-table--fixed-header > .v-data-table__wrapper {
  overflow-y: auto;
}
.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: 0px !important;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}
.v-data-table--fixed-header
  > .v-data-table__wrapper
  > table
  > thead
  > tr:nth-child(2)
  > th {
  top: 48px;
}
.v-application--is-ltr .v-data-table--fixed-header .v-data-footer {
  margin-right: 17px;
}
.v-application--is-rtl .v-data-table--fixed-header .v-data-footer {
  margin-left: 17px;
}

.v-data-table--fixed-header.v-data-table--dense
  > .v-data-table__wrapper
  > table
  > thead
  > tr:nth-child(2)
  > th {
  top: 32px;
}
</style>
