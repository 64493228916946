import { computed, unref } from 'vue';
import { DateTime } from 'luxon';

export function useCategories({ allData, data }) {
  const timezone = computed(() => DateTime.local().zoneName);
  const allDataRef = computed(() => unref(allData));
  const currentData = computed(() =>
    unref(data)?.filter(({ _id }) => _id.range === 'current'),
  );
  const pastData = computed(() =>
    unref(data)?.filter(({ _id }) => _id.range === 'past'),
  );

  function parse(data) {
    if (allDataRef.value.granularity === 'hour') {
      const categories = [];
      const date = DateTime.fromISO(DateTime.local().toISODate());
      for (let i = 0; i < 24; i++) {
        const dateFormatted = date
          .plus({ hours: i })
          .toLocaleString(DateTime.TIME_SIMPLE);
        categories.push(dateFormatted);
      }
      return categories;
    }

    if (allDataRef.value.granularity === 'month') {
      return data.reduce((accum, stat) => {
        const date = DateTime.fromISO(stat._id.date, { zone: timezone.value });
        accum.push(date.toFormat('MMM'));
        return accum;
      }, []);
    }

    return data.reduce((accum, stat) => {
      const date = DateTime.fromISO(stat._id.date, { zone: timezone.value });
      accum.push(date.toFormat('MMM d'));
      return accum;
    }, []);
  }

  const categories = computed(() => {
    if (!currentData?.value || !allDataRef.value) return [];

    return parse(currentData.value);
  });
  const pastCategories = computed(() => {
    if (!pastData?.value || !allDataRef.value) return [];

    return parse(pastData.value);
  });

  return {
    categories,
    pastCategories,
  };
}
