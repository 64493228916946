<template>
  <button
    class="canva-btn"
    type="button"
    :disabled="isDisabled"
    @click="handleClick"
  >
    <CanvaIcon />
    <slot />
  </button>
</template>

<script>
import { CanvaIcon } from '@campsite-bio/component-lib';

export default {
  components: {
    CanvaIcon,
  },

  props: {
    designId: {
      type: String,
      default: null,
    },

    designType: {
      type: String,
      default: '',
    },

    designHeight: {
      type: [Number, String],
      default: 1080,
    },

    designWidth: {
      type: [Number, String],
      default: 1080,
    },
  },

  computed: {
    hasLoadedCanva() {
      return this.$store.getters['canvaMedia/hasLoadedCanva'];
    },

    isDisabled() {
      return !this.hasLoadedCanva;
    },
  },

  created() {
    this.$store.dispatch('canvaMedia/loadCanvaJS');
  },

  methods: {
    handleClick(e) {
      if (this.designId) {
        window.CanvaButtonApi.editDesign({
          design: {
            id: this.designId,
          },
          onDesignPublish: (options) => {
            this.$emit('publish', Object.assign({ options }, this.$props));
          },
        });
      } else {
        window.CanvaButtonApi.createDesign({
          design: {
            type: this.designType,
            dimensions: {
              width: this.designWidth,
              height: this.designHeight,
            },
          },
          onDesignPublish: (options) => {
            this.$emit('publish', Object.assign({ options }, this.$props));
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.canva-btn {
  align-items: center;
  background: rgb(0, 196, 204);
  border: none;
  border-radius: var(--g-border-radius-standard);
  cursor: pointer;
  color: #fff;
  display: inline-flex;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4;
  padding: 5px 6px;

  &:hover,
  &:focus {
    background: rgb(0, 217, 225);
  }

  &:disabled {
    background: #e0e0e0;
    box-shadow: none;
    color: #666;
    cursor: not-allowed;
    opacity: 0.6;
    pointer-events: none;

    &:hover,
    &:focus {
      background: var(--g-color-gray-100);
      color: #999;
    }
  }

  &::v-deep .v-svg-icon {
    font-size: 0.925rem;
    margin-right: 0.3em;
  }
}
</style>
