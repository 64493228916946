<template>
  <Fragment>
    <template v-for="{ name, key } in HIGHLIGHT_FIELDS">
      <StatCard
        v-if="!highlights[`${key}Hide`]"
        :key="key"
        class="span-3"
        :title="name"
        :is-loading="isLoading"
        :badge="highlights[`${key}Ctr`]"
        :badge-color="highlights[`${key}CtrColor`]"
      >
        {{ highlights[key] | numberFormatter(2) }}
      </StatCard>
    </template>
  </Fragment>
</template>

<script>
import { toRefs } from 'vue';
import { Fragment } from '@campsite-bio/component-lib';

import { StatCard } from '../..';
import { useReportHighlights } from '../../compositions/use-report-highlights';

const HIGHLIGHT_FIELDS = [
  { name: 'Bio Link Clicks', key: 'website_clicks' },
  { name: 'Media Impressions', key: 'impressions' },
  { name: 'Followers Gained', key: 'followers_gained' },
  { name: 'Reach', key: 'reach' },
];

export default {
  components: {
    Fragment,
    StatCard,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const { data } = toRefs(props);

    const highlights = useReportHighlights(
      HIGHLIGHT_FIELDS,
      data,
      'ig_topline',
    );

    return {
      HIGHLIGHT_FIELDS,
      highlights,
    };
  },
};
</script>

<style lang="scss" scoped></style>
