import { useScriptTag } from '@vueuse/core';
import { defaultWindow } from '@campsite-bio/component-lib';
import { computed, ref } from 'vue';

const CHURNKEY_URL = 'https://assets.churnkey.co/js/app.js?appId=vhucptk71';

let hasLoaded = false;

export function useChurnkey() {
  const window = defaultWindow;
  const hasLoadedLocal = ref(hasLoaded);
  const isLoading = ref(false);
  let loadedResolve = null;

  const churnkey = computed(() => {
    const loaded = hasLoadedLocal.value;
    return window.churnkey;
  });

  const loadedPromise = new Promise((resolve) => {
    loadedResolve = resolve;
  });

  const load = () => {
    if (hasLoaded) return Promise.resolve(window.churnkey);

    return loadedPromise;
  };

  // only load the SDK once
  if (hasLoaded && window?.churnkey?.created) {
    loadedResolve(window.churnkey);
  } else {
    isLoading.value = true;
    window.churnkey = { created: true };
    useScriptTag(CHURNKEY_URL, () => {
      hasLoaded = true;
      hasLoadedLocal.value = true;
      isLoading.value = false;
      loadedResolve(window.churnkey);
    });
  }

  return { churnkey, hasLoaded: hasLoadedLocal, isLoading, load };
}
