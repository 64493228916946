import { computed, ref, watch, unref } from 'vue';

import { localAxios } from '../../apis';
import { useStore } from '../use-store';

/** Will load a profile given an id */
export function useProfileLoader(profileId) {
  const store = useStore();
  const isLoading = ref(false);
  const profile = ref(null);
  const appAuths = ref(null);

  const id = computed(() => unref(profileId));

  async function load() {
    profile.value = null;
    appAuths.value = null;

    if (!id.value) return;

    // Use the store if the profile is already loaded
    const storedProfile = store.getters['profiles/currentProfile'];
    if (storedProfile?._id?.$oid === id.value) {
      profile.value = storedProfile;
      appAuths.value = storedProfile.app_authorizations;
      return;
    }

    isLoading.value = true;

    try {
      const { data } = await localAxios.get(`/api/profiles/${id.value}`);
      profile.value = data.profile;
      appAuths.value = data.app_authorizations;
    } finally {
      isLoading.value = false;
    }
  }

  watch(id, load, { immediate: true });

  return {
    isLoading,
    profile,
    appAuths,
  };
}
