<template>
  <LinkExpander
    :id="`banners-${linkId}`"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template slot="header">
      Carousel banners
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <IconButton
            href="https://support.campsite.bio/en/articles/6830835-adding-a-carousel-link"
            target="_blank"
            aria-label="Carousel setup help"
            size="1.25rem"
            v-bind="attrs"
            v-on="on"
          >
            <QuestionCircleOIcon />
          </IconButton>
        </template>
        <span>Get some help setting up your carousel link</span>
      </v-tooltip>
    </template>

    <draggable
      :options="{
        draggable: '.item-wrapper',
        handle: '.item-wrapper__drag',
      }"
      :animation="200"
      @update="onSortUpdate"
    >
      <BannerItem
        v-for="banner in banners"
        :key="banner._id.$oid"
        :link="link"
        :banner="banner"
        :carousel-options="carouselOptions"
      />
    </draggable>

    <Button
      class="add-margin-top"
      :loading="isBannerLoading"
      :disabled="isBannerAddDisabled"
      @click="$emit('add')"
      >Add Banner +</Button
    >

    <Alert v-if="isFreePlan" variant="outline" style="margin-top: 12px">
      <LightningBoltIcon slot="icon" />
      Unlock the full potential of the Carousel with Campsite Pro
      <Button slot="actions" to="/account/change-plan" variant="flat"
        >Upgrade</Button
      >
    </Alert>
  </LinkExpander>
</template>

<script>
import draggable from 'vuedraggable';
import {
  Alert,
  LightningBoltIcon,
  QuestionCircleOIcon,
  Button,
  IconButton,
} from '@campsite-bio/component-lib';
import { computed, ref } from 'vue';

import LinkExpander from '../expand/link-expander';
import BannerItem from './banner-item';
import { useStore } from '../../../compositions';

export default {
  components: {
    LinkExpander,
    draggable,
    Alert,
    LightningBoltIcon,
    QuestionCircleOIcon,
    Button,
    IconButton,
    BannerItem,
  },

  props: {
    isOpen: Boolean,

    linkId: {
      type: String,
      required: true,
    },

    link: {
      type: Object,
      required: true,
    },

    carouselOptions: {
      type: Object,
      required: true,
    },

    isBannerLoading: Boolean,
    isBannerAddDisabled: Boolean,
  },

  setup(props) {
    const store = useStore();

    const banners = computed(() => {
      return props.link.banners;
    });

    const isFreePlan = computed(() => {
      return store.getters['profiles/isProfileOnFreePlan'];
    });

    function onSortUpdate(e) {
      const newIndex = e.newIndex,
        oldIndex = e.oldIndex;

      store.commit('links/moveLinkBanner', {
        linkId: props.linkId,
        newIndex: newIndex,
        oldIndex: oldIndex,
      });
      // save banner position
      store.dispatch('links/updateLinkBannerPosition', {
        linkId: props.linkId,
        banners: banners.value,
      });
    }

    return { onSortUpdate, banners, isFreePlan };
  },
};
</script>

<style lang="scss" scoped></style>
