<template>
  <v-dialog
    v-model="isOpen"
    fullscreen
    scrollable
    transition="dialog-bottom-transition"
  >
    <Card class="wrapped">
      <CardTitle>
        <VText component="h2" variant="h4">Share</VText>
        <FlexSpacer />
        <IconButton size="1.25rem" aria-label="Close" @click="isOpen = false">
          <CloseIcon />
        </IconButton>
      </CardTitle>
      <Divider color="gray200" />
      <CardText>
        <div class="wrapped__imgs">
          <InputBtnGroup v-model="selectedShareImage" aria-label="Share images">
            <InputBtn
              v-for="(img, i) in wrappedData.share_image_urls"
              :key="i"
              ref="inputBtns"
              :value="img"
              class="wrapped__img"
              :content-height="contentHeight"
            >
              <img slot="content" :src="imageUrl(img)" />
              <span class="sr-only">screen {{ i + 1 }}</span>
            </InputBtn>
          </InputBtnGroup>
        </div>
      </CardText>
      <CardActions>
        <Button :href="selectedShareImage" download variant="flat"
          >Download</Button
        >
      </CardActions>
    </Card>
  </v-dialog>
</template>

<script>
import { computed, ref, toRefs } from 'vue';
import {
  Card,
  CardTitle,
  CardText,
  CardActions,
  Button,
  VText,
  Divider,
  FlexSpacer,
  IconButton,
  CloseIcon,
  InputBtnGroup,
  InputBtn,
  convertImageUrlToEncodedUrl,
} from '@campsite-bio/component-lib';
import { useResizeObserver } from '@vueuse/core';

import { useModel } from '../../../../compositions';

export default {
  components: {
    Card,
    CardTitle,
    CardText,
    CardActions,
    Button,
    VText,
    Divider,
    FlexSpacer,
    IconButton,
    CloseIcon,
    InputBtnGroup,
    InputBtn,
  },

  props: {
    value: Boolean,

    wrappedData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const isOpen = useModel(value, (val) => emit('input', val));
    const selectedShareImage = ref(props.wrappedData.share_image_urls[0]);
    const inputBtns = ref(null);
    const contentHeight = ref(null);

    const inputBtn = computed(() =>
      inputBtns.value ? inputBtns.value[0] : null,
    );

    useResizeObserver(inputBtn, ([{ contentRect }]) => {
      contentHeight.value = `${contentRect.width * 1.77}px`;
    });

    function imageUrl(image) {
      return convertImageUrlToEncodedUrl(image, 400);
    }

    return { isOpen, imageUrl, selectedShareImage, contentHeight, inputBtns };
  },
};
</script>

<style lang="scss" scoped>
.wrapped.wrapped {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.wrapped {
  &__imgs {
    flex-grow: 1;
    padding: 1rem;
  }

  &__img {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
