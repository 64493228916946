<template>
  <ValidationObserver v-slot="{ handleSubmit }" slim>
    <form action="#" novalidate @submit.prevent="handleSubmit(accept)">
      <Alert :color="stepColor" variant="outline" style="margin-bottom: 1.5rem">
        <InfoIcon slot="icon" />
        <VText :color="stepColor"
          >Your profile can be modified after you've accepted.</VText
        >
      </Alert>

      <VText
        component="h2"
        variant="h3"
        :color="stepColor"
        style="margin-bottom: 0.75rem"
        >Step 3.</VText
      >
      <AccordionSection
        v-model="isOpen"
        title="Review"
        subtitle="Is everything to your liking? Then let's finish this!"
      >
        <FormGroup>
          <Label for="feedback">Do you have any feedback to share?</Label>
          <Input
            id="feedback"
            v-model="feedback"
            component="textarea"
            rows="3"
            placeholder="It would be great if you included X. The colors didn't match."
          />
        </FormGroup>

        <template v-if="hasToPay">
          <VText
            component="h3"
            variant="h5"
            gutter-bottom
            style="margin-top: 1rem"
            >Your Billing Information</VText
          >

          <Label component="span" gutter-bottom>Total</Label>
          <Table>
            <TableItem>
              <td>Make My Bio Link Wizard</td>

              <td>{{ (price / 100) | currency }}</td>
            </TableItem>
          </Table>

          <ValidationProvider
            v-slot="{ errors, ariaInput, ariaMsg }"
            :rules="{ required: { allowFalse: false } }"
            name="terms"
            slim
          >
            <FormGroup>
              <FormControlLabel>
                <Checkbox v-model="terms" v-bind="ariaInput" />
                <template slot="label">
                  You agree to our&nbsp;<a
                    href="https://campsite.bio/terms"
                    target="_blank"
                    >Terms of Service and Refund Policy</a
                  >.
                </template>
              </FormControlLabel>
              <FormGroupHelp
                v-show="errors[0]"
                color="error"
                v-bind="ariaMsg"
                >{{ errors[0] }}</FormGroupHelp
              >
            </FormGroup>
          </ValidationProvider>
        </template>

        <CardActions class="manager__actions" align="left">
          <Button
            size="large"
            :loading="isSaving"
            :disabled="disabled || isCancelling"
            type="submit"
            >{{ hasToPay ? 'Checkout' : 'Accept' }}</Button
          >
          <InlineLink
            v-if="showCancelButton"
            :disabled="disabled || isSaving || isCancelling"
            @click="handleCancel"
            >No Thanks</InlineLink
          >
        </CardActions>

        <VText
          v-if="showThemeWarning"
          color="gray600"
          style="margin-top: 1rem; font-size: 0.85em"
          ><em
            >Running this tool will overwrite your custom theme settings.</em
          ></VText
        >
      </AccordionSection>

      <SettingsSection v-show="isSaving" ref="loadingSection">
        <SettingsSectionHeader>
          <SettingsSectionTitle>
            The magic is happening... 🪄
          </SettingsSectionTitle>
          <VText>This will take ~10-20 seconds.</VText>
        </SettingsSectionHeader>

        <ul v-if="isSaving">
          <li v-for="(activity, i) in uploadActivities" :key="activity">
            {{ activity }}{{ i + 1 === uploadActivities.length ? '...' : '' }}
          </li>
        </ul>

        <RatioBox :ratio="480 / 353">
          <video
            class="wizards"
            src="https://media.giphy.com/media/9jX28wQ7uV82I/giphy.mp4"
            playsInline
            autoplay
            muted
            loop
          />
        </RatioBox>
      </SettingsSection>
    </form>
  </ValidationObserver>
</template>

<script>
import { computed, nextTick, ref, toRefs, watch } from 'vue';
import {
  VText,
  Button,
  Alert,
  InfoIcon,
  CardActions,
  InlineLink,
  RatioBox,
  FormGroup,
  FormGroupHelp,
  Label,
  FormControlLabel,
  Checkbox,
  AccordionSection,
  Input,
} from '@campsite-bio/component-lib';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { captureException } from '@sentry/browser';

import {
  SettingsSection,
  SettingsSectionTitle,
  SettingsSectionHeader,
} from '../../settings-section';
import { localAxios } from '@/apis';
import toast from '@/utils/toast';
import {
  createMediaWithUrl,
  getImageInfoFromUrl,
  getMediaFilename,
  getProfileImagePath,
  uploadWithUrl,
} from '@/utils/media';
import { useStore } from '@/compositions';
import { Table, TableItem } from '../../change-plan/cards/summary';
import { getAppSetting } from '@/utils';

export default {
  components: {
    VText,
    Button,
    Alert,
    InfoIcon,
    CardActions,
    InlineLink,
    RatioBox,
    SettingsSection,
    SettingsSectionTitle,
    SettingsSectionHeader,
    FormGroup,
    FormGroupHelp,
    Label,
    ValidationObserver,
    ValidationProvider,
    Table,
    TableItem,
    FormControlLabel,
    Checkbox,
    AccordionSection,
    Input,
  },

  props: {
    configurationState: {
      type: Object,
      required: true,
    },

    site: {
      type: String,
      required: true,
    },

    campsiteId: {
      type: String,
      required: true,
    },

    designId: {
      type: String,
      required: true,
    },

    stepColor: {
      type: String,
      required: true,
    },

    disabled: Boolean,
    showCancelButton: Boolean,
    hasToPay: Boolean,
    showThemeWarning: Boolean,
  },

  setup(props, { emit }) {
    const { configurationState, designId, campsiteId, hasToPay } =
      toRefs(props);
    const isSaving = ref(false);
    const isOpen = ref(false);
    const isCancelling = ref(false);
    const loadingSection = ref(null);
    const terms = ref(false);
    const feedback = ref(null);
    const uploadActivities = ref([]);

    watch(isSaving, (newValue) => emit('update:loading', newValue));

    const price = 500;

    const cartItems = computed(() => {
      return [
        {
          price: getAppSetting('wizardPriceId'),
          quantity: 1,
        },
      ];
    });

    async function accept() {
      uploadActivities.value.length = 0;
      isSaving.value = true;

      // Use the users card to create a stripe payment token
      if (hasToPay.value) {
        sessionStorage['wizard:configuration'] = JSON.stringify(
          configurationState.value,
        );
        sessionStorage['wizard:site'] = props.site;
        sessionStorage['wizard:design:id'] = props.designId;

        try {
          const { data } = await localAxios.post(
            '/api/checkout/charges/create-checkout-session',
            {
              line_items: cartItems.value,
              success_return_path: `/api/checkout/charges/wizard?return_url=${encodeURIComponent(
                `${window.location.pathname}?paid=1`,
              )}`,
              cancel_return_path: window.location.pathname,
              promo_code: props.initialPromoCode,
            },
          );

          window.location = data.redirect_url;
        } catch (e) {
          isSaving.value = false;
          console.error(e);
          captureException(e);
          const { error_message } = e.response?.data || {};
          toast.error(
            error_message ||
              'There was an error trying to save your design. Please try again soon.',
            { timeout: 5000 },
          );
          return;
        }
      }

      await nextTick();

      loadingSection.value.$el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });

      try {
        const localConfigState = { ...configurationState.value };
        // TODO: retry requests in this block if they fail?

        // Upload and create media for images
        if (configurationState.value.profileImage) {
          uploadActivities.value.push('Uploading profile image');
          const data = await getMediaFilename();
          const imageInfo = await getImageInfoFromUrl(
            configurationState.value.profileImage,
          );
          const filename = `${data.filename}.${imageInfo.type}`;
          const { url } = await uploadWithUrl(
            configurationState.value.profileImage,
            getProfileImagePath(filename),
          );
          localConfigState.profileImage = url;
        }
        if (configurationState.value.metaFavicon) {
          uploadActivities.value.push('Uploading favicon');
          const media = await createMediaWithUrl(
            configurationState.value.metaFavicon,
            campsiteId.value,
          );
          localConfigState.metaFavicon = media;
        }
        if (configurationState.value.metaImage) {
          uploadActivities.value.push('Uploading open graph image');
          const media = await createMediaWithUrl(
            configurationState.value.metaImage,
            campsiteId.value,
          );
          localConfigState.metaImage = media;
        }
        // Update profile with new design
        uploadActivities.value.push('Updating your profile design');
        const { data } = await localAxios.put(
          `/api/design/${designId.value}/update`,
          {
            configuration: localConfigState,
            profile_id: campsiteId.value,
            feedback: feedback.value,
          },
        );

        emit('success', data.profile);
      } catch (e) {
        uploadActivities.value.length = 0;
        console.error(e);
        captureException(e);
        const { error_message } = e.response?.data || {};
        toast.error(
          error_message ||
            'There was an error trying to save your design. Please try again soon.',
          { timeout: 5000 },
        );
      }

      isSaving.value = false;
    }

    async function handleCancel() {
      isCancelling.value = true;
      try {
        await localAxios.put(`/api/design/${designId.value}/decline`, {
          feedback: feedback.value,
        });
      } catch (e) {
        console.error(e);
        toast.error(
          'There was an error trying to cancel your design. Please try again soon.',
        );
      }

      emit('cancel');

      isCancelling.value = false;
    }

    function open() {
      isOpen.value = true;
    }

    return {
      isOpen,
      open,
      accept,
      isSaving,
      loadingSection,
      terms,
      uploadActivities,
      feedback,
      handleCancel,
      isCancelling,
      price,
    };
  },
};
</script>

<style lang="scss" scoped>
.manager__actions.manager__actions {
  margin-left: -0.5rem;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.wizards {
  height: 100%;
  width: 100%;
}
</style>
