import { localAxios } from '../apis';
import { configureUser } from '../utils/sentry';
import { getMeta } from '../utils';

export default {
  state: {
    user: {},
    isLoadingUser: false,
    nextInvoice: {},
    customer: null,
    referralData: null,
    sessionEnded: false,
    isGettingSetupOpen: false,
    orgProfileData: null,
  },

  getters: {
    currentUser: (state) => state.user,
    currentUserId: (state) => (state.user ? state.user._id.$oid : null),
    isUser: (state) => Object.keys(state.user).length,
    isLoadingUser: (state) => state.isLoadingUser,
    isAccountCreated: (state) => !!state.user.account_created,
    hasAccountCreateStep: (state) => !!state.user.account_create_step,
    isAtStepSocial: (state) => state.user.account_create_step === 'social',
    isAtStepConfirmEmail: (state) => state.user.account_create_step === 'email',
    isAtStepInfo: (state) => state.user.account_create_step === 'info',
    isAtStepPlan: (state) =>
      state.user.account_create_step === 'plan' ||
      state.user.account_create_step === 'plan_org',
    isAtStepMakeBio: (state) => state.user.account_create_step === 'make-bio',
    isLoggedIn: (state) => Object.keys(state.user).length > 0,
    isSetupComplete: (state) => !!state.user.email,
    isFreePlan: (state) => state.user.plan === 'free',
    isProPlan: (state) =>
      Object.keys(state.user).length > 0 && state.user.plan !== 'free',
    isAdmin: (state) => state.user?.role === 'admin',
    sessionEnded: (state) => state.sessionEnded,
    emailVerified: (state) => state.user.email_verified,
    isStripeCustomer: (state) => !!state.user.stripe_customer,
    hasHadSubscription: (state) =>
      state.customer && state.customer.has_had_subscription,
    hasNoCampsites: (state) =>
      Object.keys(state.user).length &&
      state.user.number_of_campsites_created === 0 &&
      state.user.number_of_campsites_access === 0,
    nextInvoice: (state) => state.nextInvoice,
    customer: (state) => state.customer,
    referralData: (state) => state.referralData,
    validSources: (state) => {
      if (!state.customer) return [];
      const today = new Date();
      return state.customer.sources.filter(
        (source) =>
          source.exp_year > today.getFullYear() ||
          (source.exp_year >= today.getFullYear() &&
            source.exp_month > today.getMonth() &&
            source.address_country),
      );
    },
    campsitesCreated: (state) => state.user.number_of_campsites_created,
    campsitesEnabled: (state) => state.user.number_of_campsites_enabled,
    numberOfCampsites: (state) => state.user.number_of_campsites,
    numberOfUsers: (state) => state.user.number_of_users,
    hasAnalyticsAddon: (state) => {
      if (!Object.keys(state.user).length) return false;

      const addons = getMeta('addons', [], state.user.meta) || [];
      return addons.includes('analytics');
    },
    isGettingSetupOpen: (state) => state.isGettingSetupOpen,
    orgProfileData: (state) => state.orgProfileData,
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
      configureUser(user);
    },

    setIsLoadingUser(state, value) {
      state.isLoadingUser = value;
    },

    setNextInvoice(state, value) {
      state.nextInvoice = value;
    },

    setCustomer(state, value) {
      state.customer = value;
    },

    setReferralData(state, value) {
      state.referralData = value;
    },

    setUserEmail(state, email) {
      state.user.email = email;
    },

    setUserEmailVerfied(state, value) {
      state.user.email_verified = value;
    },

    setUserEmailVerfiedAgain(state, value) {
      state.user.email_verified_again = value;
    },

    setSessionEnded(state, value) {
      state.sessionEnded = value;
      state.user = {};
    },

    setOrgProfileData(state, value) {
      state.orgProfileData = value;
    },
  },

  actions: {
    async getUserData({ getters, commit }, { force } = {}) {
      if (!Object.keys(getters.currentUser).length || force) {
        commit('setIsLoadingUser', true);

        try {
          const { data } = await localAxios.get('/api/users/me');

          commit('setUser', data || {});
          commit('setIsLoadingUser', false);
          return data;
        } catch (e) {
          if (e.response && e.response.status === 401)
            commit('setSessionEnded', true);

          commit('setIsLoadingUser', false);
          throw e;
        }
      } else {
        return Promise.resolve(getters.currentUser);
      }
    },

    async updateUserPreferences({ getters, dispatch }, newPreferences) {
      const preferences = getMeta('preferences', {}, getters.currentUser.meta);

      const data = await dispatch('updateUserMeta', {
        items: [
          {
            name: 'preferences',
            value: {
              ...preferences,
              ...newPreferences,
            },
          },
        ],
      });
      return data;
    },

    async updateUserMeta({ getters, commit }, postData) {
      const { data } = await localAxios.put(
        `/api/users/${getters.currentUser._id.$oid}/meta`,
        postData,
      );

      commit('setUser', data || {});
      return data;
    },

    async getCustomerData({ getters, commit }) {
      const { data } = await localAxios.get(
        `/api/users/${getters.currentUser._id.$oid}/customer`,
      );

      commit('setCustomer', data);

      return data;
    },

    async getReferralData({ getters, commit }) {
      const { data } = await localAxios.get(
        `/api/users/${getters.currentUser._id.$oid}/referral-data`,
      );

      commit('setReferralData', data);

      return data;
    },

    async getEmailPreferences({ getters, commit }) {
      const { data } = await localAxios.get(
        `/api/users/${getters.currentUser._id.$oid}/email-preferences`,
      );

      return data;
    },

    async getOrgProfileData({ getters, commit }) {
      const { data } = await localAxios.get(
        `/api/users/${getters.currentUser._id.$oid}/profiles-data`,
      );

      commit('setOrgProfileData', data.orgs);

      return data;
    },

    async updateEmailPreferences({ getters, commit }, postData) {
      const { data } = await localAxios.put(
        `/api/users/${getters.currentUser._id.$oid}/email-preferences`,
        postData,
      );

      return data;
    },

    async login({ commit }, postData) {
      const { data } = await localAxios.post('/api/users/login', postData);

      return data;
    },

    async createAccount({ commit }, postData) {
      const { data } = await localAxios.post(
        '/api/users/create-user-account',
        postData,
      );

      return data;
    },

    async updateUserInformation({ commit, getters }, postData) {
      const { data } = await localAxios.post(
        `/api/users/${getters.currentUser._id.$oid}/update-user-information`,
        postData,
      );

      return data;
    },

    async updateUserPlan({ getters }, postData) {
      const { data } = await localAxios.post(
        `/api/users/${getters.currentUser._id.$oid}/update-user-plan`,
        postData,
      );

      return data;
    },

    async cancelUserOrgCreate({ getters }) {
      const { data } = await localAxios.post(
        `/api/users/${getters.currentUser._id.$oid}/cancel-org-create`,
      );

      return data;
    },

    async updateUserBio({ getters }, postData) {
      const { data } = await localAxios.post(
        `/api/users/${getters.currentUser._id.$oid}/update-user-bio`,
        postData,
      );

      return data;
    },

    async updatePassword({ rootGetters, commit }, postData) {
      const { data } = await localAxios.post(
        '/api/users/reset-password',
        postData,
      );

      return data;
    },

    async updateUser({ getters, commit }, postData) {
      const { data } = await localAxios.post(
        `/api/users/${getters.currentUser._id.$oid}/update-user-settings`,
        postData,
      );

      commit('setUserEmailVerfied', data.user.email_verified);
      commit('setUserEmailVerfiedAgain', data.user.email_verified_again);

      return data;
    },

    resendEmailConfirmation({ getters }) {
      return localAxios.post(
        `/api/users/${getters.currentUser._id.$oid}/resend-email-confirmation`,
      );
    },

    async cancelEmailChange({ getters, commit }) {
      const { data } = await localAxios.put(
        `/api/users/${getters.currentUser._id.$oid}/cancel-email-change`,
      );
      commit('setUser', data);
      return data;
    },

    async updateGettingStarted({ getters, commit }, postData) {
      const { data } = await localAxios.put(
        `/api/users/${getters.currentUser._id.$oid}/getting-started`,
        postData,
      );

      commit('setUser', data.user);

      return data;
    },

    async deleteGettingStarted({ getters, commit }, params) {
      const { data } = await localAxios.delete(
        `/api/users/${getters.currentUser._id.$oid}/getting-started`,
        {
          params,
        },
      );

      // filter out getting started meta
      const user = { ...getters.currentUser };
      user.meta = user.meta.filter((item) => item.name !== 'getting_started');

      commit('setUser', user);

      return data;
    },

    async updateTourOption({ commit, getters }, option) {
      const postData = {
        name: option,
        value: true,
      };
      const { data } = await localAxios.put(
        `/api/users/${getters.currentUser._id.$oid}/tour-option`,
        postData,
      );

      commit('setUser', {
        ...getters.currentUser,
        meta: [
          ...getters.currentUser.meta.filter((m) => m.name !== 'tours'),
          data,
        ],
      });
    },

    async updateBillingSettings({ commit, getters }, postData) {
      const { data } = await localAxios.put(
        `/api/users/${getters.currentUser._id.$oid}/billing-settings`,
        postData,
      );

      commit('setUser', data.user);

      return data;
    },

    async deleteUser({ getters }) {
      await localAxios.delete(`/api/users/${getters.currentUser._id.$oid}`);
    },

    async updateProfileImage({ getters, commit }, url) {
      commit('setIsLoading', true, { root: true });

      try {
        const { data } = await localAxios.put(
          `/api/users/${getters.currentUserId}/profile-image`,
          {
            url,
          },
        );

        commit('setUser', data);
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
      }
    },
  },
};
