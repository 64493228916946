<template>
  <button
    class="color-palette__option"
    :class="classes"
    :title="color"
    :aria-label="color"
    @click="$emit('select', color)"
  >
    <span
      class="color-palette__option--inner"
      :style="{ 'background-color': color }"
    />
  </button>
</template>

<script>
import tinyColor from 'tinycolor2';

export default {
  props: {
    color: {
      type: String,
      required: true,
    },
    isActive: Boolean,
  },
  data() {
    return {
      tinyColor: undefined,
    };
  },

  computed: {
    classes() {
      return {
        'color-palette__option--active': this.isActive,
        'color-palette__option--dark': this.tinyColor.isDark(),
        'color-palette__option--light': this.tinyColor.isLight(),
      };
    },
  },

  created() {
    this.tinyColor = tinyColor(this.color);
  },
};
</script>

<style lang="scss" scoped>
.color-palette__option {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAIUlEQVQ4jWN49uzZf3yYEGAYNWBYGEBIASELRg0YFgYAABpE1q4luwPVAAAAAElFTkSuQmCC');
  border: solid 1px #d0d0d0;
  padding: 0;
  height: 24px;
  margin: 2px;
  position: relative;
  width: 24px;
}

.color-palette__option--inner {
  background-repeat: no-repeat;
  background-position: center;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.color-palette__option--light.color-palette__option--active
  .color-palette__option--inner {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIVJREFUeNpiYBhsgJFMffxAXABlN5JruT4Q3wfi/0DsT64h8UD8HmpIPCWG/KemIfOJCUB+Aoacx6EGBZyHBqI+WsDCwuQ9mhxeg2A210Ntfo8klk9sOMijaURm7yc1UP2RNCMbKE9ODK1HM6iegYLkfx8pligC9lCD7KmRof0ZhjQACDAAceovrtpVBRkAAAAASUVORK5CYII=);
}

.color-palette__option--dark.color-palette__option--active
  .color-palette__option--inner {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAAMdJREFUOE+tkgsNwzAMRMugEAahEAahEAZhEAqlEAZhEAohEAYh81X2dIm8fKpEspLGvudPOsUYpxE2BIJCroJmEW9qJ+MKaBFhEMNabSy9oIcIPwrB+afvAUFoK4H0tMaQ3XtlrggDhOVVMuT4E5MMG0FBbCEYzjYT7OxLEvIHQLY2zWwQ3D+9luyOQTfKDiFD3iUIfPk8VqrKjgAiSfGFPecrg6HN6m/iBcwiDAo7WiBeawa+Kwh7tZoSCGLMqwlSAzVDhoK+6vH4G0P5wdkAAAAASUVORK5CYII=);
}
</style>
