var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{staticClass:"profiles-card"},[_c('CardTitle',{staticStyle:{"display":"block"}},[_c('VText',{attrs:{"component":"h2","variant":"h4"}},[_vm._v("Add a profile")]),_vm._v(" "),_c('VText',{staticStyle:{"font-size":"0.9rem"}},[_vm._v("Each profile gets it's own URL and theme.")])],1),_vm._v(" "),_c('List',{staticStyle:{"padding":"0"}},[_c('div',{staticClass:"profile-item"},[_c('ListItem',[_c('ListItemTitle',{staticClass:"profile-item__title"},[_vm._v("Personal "),_c('Badge',{attrs:{"content":_vm.planName}})],1),_vm._v(" "),_c('ListItemAction',{staticClass:"profile-item__count"},[_vm._v(_vm._s(_vm.numberOfProfilesEnabled)+" /\n          "+_vm._s(_vm.numberOfProfiles))])],1),_vm._v(" "),(_vm.isFreePlan)?_c('ListItem',{staticClass:"profile-item__actions"},[_c('ListItemContent',{staticStyle:{"padding":"0"}},[_c('ListItemSubtitle',[_vm._v("Manage more profiles with Pro")]),_vm._v(" "),_c('div',{staticStyle:{"margin-top":"0.25rem"}},[_c('Button',{attrs:{"to":"/account/change-plan","size":"small","variant":"outline"}},[_vm._v("Upgrade Now")])],1)],1)],1):(_vm.isAtLimit)?_c('ListItem',{staticClass:"profile-item__actions"},[_c('Button',{attrs:{"to":"/account/change-plan","size":"small","variant":"outline"}},[_vm._v("Add more profiles")])],1):_vm._e()],1),_vm._v(" "),_c('Divider',{attrs:{"color":"gray200","thickness":"1"}}),_vm._v(" "),_vm._l((_vm.orgs),function({
        _id,
        name,
        number_of_campsites,
        number_of_campsites_enabled,
        member_role,
      }){return [_c('div',{key:`org${_id.$oid}`,staticClass:"profile-item"},[_c('ListItem',[_c('ListItemContent',[_c('ListItemTitle',{staticClass:"profile-item__title"},[_c('InlineLink',{attrs:{"to":`/organization/${_id.$oid}`}},[_vm._v(_vm._s(name))])],1),_vm._v(" "),_c('ListItemSubtitle',[_vm._v("\n              "+_vm._s(member_role)+"\n            ")])],1),_vm._v(" "),_c('ListItemAction',{staticClass:"profile-item__count"},[_vm._v("\n            "+_vm._s(number_of_campsites_enabled)+" / "+_vm._s(number_of_campsites)+"\n          ")])],1)],1),_vm._v(" "),_c('Divider',{key:`divider${_id.$oid}`,attrs:{"color":"gray200","thickness":"1"}})]})],2),_vm._v(" "),_c('CardActions',[(_vm.orgs.length > 0)?_c('v-menu',{attrs:{"offset-y":"","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('Button',_vm._g(_vm._b({attrs:{"variant":"flat","disabled":_vm.areAddButtonsDisabled}},'Button',attrs,false),on),[_vm._v("Add New")])]}}],null,false,2306536744)},[_vm._v(" "),_c('List',[_c('ListItem',{staticClass:"v-profile-card__action-item",attrs:{"to":"/profile/new","disabled":_vm.isAtLimit}},[_c('ListItemTitle',[_vm._v("Add to personal")])],1),_vm._v(" "),_vm._l((_vm.orgs),function({
            _id,
            name,
            number_of_campsites_enabled,
            number_of_campsites,
          }){return _c('ListItem',{key:_id.$oid,staticClass:"v-profile-card__action-item",attrs:{"to":`/profile/new?organization_id=${_id.$oid}`,"disabled":number_of_campsites_enabled >= number_of_campsites}},[_c('ListItemTitle',[_vm._v("Add to "+_vm._s(name))])],1)})],2)],1):_c('Button',{attrs:{"variant":"flat","disabled":_vm.areAddButtonsDisabled,"to":"/profile/new"}},[_vm._v("Add New")]),_vm._v(" "),(_vm.orgs.length > 0)?_c('v-menu',{attrs:{"offset-y":"","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('Button',_vm._g(_vm._b({attrs:{"variant":"flat","disabled":_vm.areAddButtonsDisabled}},'Button',attrs,false),on),[_vm._v("Transfer")])]}}],null,false,3489825406)},[_vm._v(" "),_c('List',[_c('ListItem',{staticClass:"v-profile-card__action-item",attrs:{"component":"button","type":"button","disabled":_vm.isAtLimit},on:{"click":function($event){return _vm.addExistingCampsite(null)}}},[_c('ListItemTitle',[_vm._v("Transfer to personal")])],1),_vm._v(" "),_vm._l((_vm.orgs),function(org){return _c('ListItem',{key:org._id.$oid,staticClass:"v-profile-card__action-item",attrs:{"component":"button","type":"button","title":`Transfer to ${org.name}`,"disabled":org.number_of_campsites_enabled >= org.number_of_campsites},on:{"click":function($event){return _vm.addExistingCampsite(org)}}},[_c('ListItemTitle',[_vm._v("Transfer to "+_vm._s(org.name))])],1)})],2)],1):_c('Button',{attrs:{"variant":"flat","disabled":_vm.areAddButtonsDisabled},on:{"click":function($event){return _vm.addExistingCampsite(null)}}},[_vm._v("Transfer")])],1),_vm._v(" "),_c('AddExistingCampsiteDialog',{attrs:{"organization-id":_vm.addExistingOrg ? _vm.addExistingOrg._id.$oid : null,"organization":_vm.addExistingOrg},model:{value:(_vm.showAddExistingDialog),callback:function ($$v) {_vm.showAddExistingDialog=$$v},expression:"showAddExistingDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }