import { useRoute, useRouter } from '../../../compositions';
import { timeout } from '../../../utils';

export function useScrollToRoute(targetClass) {
  const router = useRouter();
  const route = useRoute();

  async function handleClick(e) {
    const linkPath = new URL(e.currentTarget.href).pathname;
    if (route.value.path !== linkPath) {
      await router.push(linkPath);
      await timeout(1000);
    }

    scrollToSection();
  }

  function scrollToSection() {
    const target = document.querySelector(targetClass);
    if (target) {
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  return { handleClick };
}
