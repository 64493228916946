<template>
  <Card>
    <CardTitle class="card-title">
      <ColorsLegend v-if="dataFormat === 'colors'" :colors="colors" />
      <FlexSpacer />

      <ButtonGroup v-model="dataFormat" class="print-hide">
        <Button size="small" value="trends" aria-label="trends">
          Trends
        </Button>
        <Button size="small" value="colors" aria-label="colors">
          Performance
        </Button>
      </ButtonGroup>
    </CardTitle>

    <Divider color="gray100" />

    <DataTable
      class="overview-table"
      :class="{ [`overview-table--${dataFormat}`]: true }"
      :headers="tableHeaders"
      :items="formattedData"
      :items-per-page="999"
      hide-default-footer
      :loading="isLoading"
      fixed-header
    >
      <template #item="{ item }">
        <tr :key="item.profile.username">
          <td class="text-start">
            <div class="overview-table__profile">
              <Avatar size="45">
                <img
                  :src="getImageUrl(item.profile.profile_image)"
                  alt="profile image"
                />
              </Avatar>
              {{ item.profile.username }}
            </div>
          </td>
          <td :style="getCellColor(item.views, 'views')">
            {{ item.views | numberFormatter(2) }}
            <template v-if="dataFormat === 'trends'">
              &nbsp;
              <Badge
                :color="item.viewsTrend.color"
                :content="item.viewsTrend.differenceFormatted"
              />
            </template>
          </td>
          <td :style="getCellColor(item.clicks, 'clicks')">
            {{ item.clicks | numberFormatter(2) }}
            <template v-if="dataFormat === 'trends'">
              &nbsp;
              <Badge
                :color="item.clicksTrend.color"
                :content="item.clicksTrend.differenceFormatted"
              />
            </template>
          </td>
          <td :style="getCellColor(item.ctr, 'ctr')">
            {{ item.ctr.toFixed(1) }}%
            <template v-if="dataFormat === 'trends'">
              &nbsp;
              <Badge
                :color="item.ctrTrend.color"
                :content="item.ctrTrend.differenceFormatted"
              />
            </template>
          </td>
          <td :style="getCellColor(item.reach, 'reach')">
            {{ item.reach | numberFormatter(2) }}
            <template v-if="dataFormat === 'trends'">
              &nbsp;
              <Badge
                :color="item.reachTrend.color"
                :content="item.reachTrend.differenceFormatted"
              />
            </template>
          </td>
          <td :style="getCellColor(item.events, 'events')">
            {{ item.events | numberFormatter(2) }}
            <template v-if="dataFormat === 'trends'">
              &nbsp;
              <Badge
                :color="item.eventsTrend.color"
                :content="item.eventsTrend.differenceFormatted"
              />
            </template>
          </td>
          <td class="text-end">
            <div class="print-hide">
              <v-tooltip left>
                <template #activator="{ on, attrs }">
                  <IconButton
                    :href="`/profile/${item.profile._id.$oid}/analytics?period=${selectedDateFilter}`"
                    target="_blank"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <ExternalLinkIcon />
                  </IconButton>
                </template>
                <span>View profile analytics</span>
              </v-tooltip>
            </div>
          </td>
        </tr>
      </template>
    </DataTable>
  </Card>
</template>

<script>
import { computed, ref } from 'vue';
import {
  Card,
  CardTitle,
  ButtonGroup,
  Button,
  Avatar,
  IconButton,
  ExternalLinkIcon,
  convertImageUrlToEncodedUrl,
  Badge,
  Divider,
  FlexSpacer,
  useTheme,
  MEDIA_SIZES,
} from '@campsite-bio/component-lib';

import { ColorsLegend } from '../components';
import { DataTable } from '../../vuetify';
import { getBadgeData } from '../../../utils';
import { useColorGrading } from '../compositions';

export default {
  components: {
    Card,
    CardTitle,
    ButtonGroup,
    Button,
    Avatar,
    DataTable,
    IconButton,
    ExternalLinkIcon,
    ColorsLegend,
    Badge,
    Divider,
    FlexSpacer,
  },

  props: {
    allData: {
      type: Object,
      required: true,
    },

    data: {
      type: Array,
      required: true,
    },

    selectedDateFilter: {
      type: String,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const dataFormat = ref('trends');
    const theme = useTheme();

    const tableHeaders = ref([
      { text: 'Profile', value: 'profile', align: 'start' },
      { text: 'Views', value: 'views' },
      { text: 'Clicks', value: 'clicks' },
      { text: 'CTR', value: 'ctr' },
      { text: 'Reach', value: 'reach' },
      { text: 'Events', value: 'events' },
      { text: '', value: 'actions', align: 'end', sortable: false },
    ]);

    const formattedData = computed(() => {
      if (!props.data || (props.data && !props.data[0].profile)) return [];

      function getTrend(topline, metric) {
        const current = topline.find((t) => t._id === 'current');
        const past = topline.find((t) => t._id === 'past');
        return getBadgeData(current[metric], past[metric]);
      }

      return props.data
        .map((profile, i) => {
          const { views, clicks, events, reach } = profile.topline.find(
            (d) => d._id === 'current',
          );
          const { views: pastViews, clicks: pastClicks } = profile.topline.find(
            (d) => d._id === 'past',
          );
          const ctr = views > 0 ? (clicks / views) * 100 : 0;
          const pastCtr = (pastClicks / pastViews) * 100;
          return {
            profile: profile.profile,
            views,
            viewsTrend: getTrend(profile.topline, 'views'),
            clicks,
            clicksTrend: getTrend(profile.topline, 'clicks'),
            events,
            eventsTrend: getTrend(profile.topline, 'events'),
            reach,
            reachTrend: getTrend(profile.topline, 'reach'),
            ctr,
            ctrTrend: getBadgeData(ctr, pastCtr),
          };
        })
        .sort((a, b) => b.views - a.views);
    });

    const statsIndex = computed(() => {
      return ['views', 'clicks', 'events', 'reach', 'ctr'].reduce(
        (accum, metric) => {
          accum[metric] = formattedData.value
            .map((d) => d[metric])
            .sort((a, b) => a - b);
          return accum;
        },
        {},
      );
    });

    const { getCellColor: getColor, colors } = useColorGrading();

    function getCellColor(stat, metric) {
      if (dataFormat.value === 'colors') {
        return getColor(stat, statsIndex.value[metric]);
      }

      return '';
    }

    function getImageUrl(src) {
      return convertImageUrlToEncodedUrl(src, MEDIA_SIZES.profile_image);
    }

    return {
      dataFormat,
      tableHeaders,
      formattedData,
      getImageUrl,
      getCellColor,
      colors,
    };
  },
};
</script>

<style lang="scss" scoped>
.overview-table {
  &::v-deep {
    > .v-data-table__wrapper > table > tbody > tr > td {
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
    }
  }

  td {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  &--colors {
    &::v-deep {
      > .v-data-table__wrapper > table > tbody > tr > td {
        border-left: thin solid rgba(0, 0, 0, 0.12);
        border-right: thin solid rgba(0, 0, 0, 0.12);

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  &__profile {
    align-items: center;
    display: flex;
    gap: 0.75rem;
    font-weight: 600;
  }
}

.card-title.card-title {
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}
</style>
