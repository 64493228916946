import { computed } from 'vue';

import { useSelectionMode } from '../../config-links';

export function useLinkSelectionMode({ linkId }) {
  const { isSelectionModeEnabled, selectedLinks, toggleLinkSelection } =
    useSelectionMode();

  function handleLinkSelectionToggle() {
    toggleLinkSelection(linkId.value);
  }

  const isLinkSelected = computed(() => {
    return selectedLinks.value.includes(linkId.value);
  });

  return {
    isSelectionModeEnabled,
    handleLinkSelectionToggle,
    isLinkSelected,
  };
}
