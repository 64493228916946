<template>
  <ChartCard
    class="channel-posts-table"
    title="Post Performance"
    :is-loading="isLoading"
  >
    <ColorsLegend class="channel-posts-table__legend" :colors="colors" />

    <div class="posts-table-wrapper">
      <DataTable
        class="posts-table"
        :headers="tableHeaders"
        :items="formattedData"
        :items-per-page="999"
        hide-default-footer
        :loading="isLoading"
        fixed-header
      >
        <template #item="{ item }">
          <PostsTableItem :item="item" :get-cell-color="getCellColor" />
        </template>
      </DataTable>
    </div>
  </ChartCard>
</template>

<script>
import { computed, ref } from 'vue';
import { DateTime } from 'luxon';

import { DataTable } from '../../../vuetify';
import ChartCard from '../../cards/chart-card';
import PostsTableItem from './posts-table-item';
import { useColorGrading } from '../../compositions';
import { ColorsLegend } from '../../components';

export default {
  components: {
    ChartCard,
    DataTable,
    PostsTableItem,
    ColorsLegend,
  },

  props: {
    allData: {
      type: Object,
      required: true,
    },

    data: {
      type: Array,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const dataFormat = ref('trends');

    const tableHeaders = ref([
      { text: 'Post', value: 'timestamp', align: 'start' },
      { text: 'Engagement Rate', value: 'engagement_rate' },
      { text: 'Impressions', value: 'impressions' },
      { text: 'Reach', value: 'reach' },
      { text: 'Likes', value: 'like_count' },
      { text: 'Comments', value: 'comments_count' },
      { text: 'Saves', value: 'saved' },
      { text: 'Video Views', value: 'plays' },
      { text: '', value: 'actions', align: 'end', sortable: false },
    ]);

    const formattedData = computed(() => {
      if (!props.data) return [];

      return props.data
        .filter(
          (m) => m.range === 'current' && m.media_product_type !== 'STORY',
        )
        .map((media, i) => {
          return {
            ...media,
            ...media.data,
            date: DateTime.fromISO(media.timestamp).toLocaleString(
              DateTime.DATE_MED,
            ),
          };
        });
    });

    const statsIndex = computed(() => {
      return [
        'engagement_rate',
        'impressions',
        'reach',
        'like_count',
        'comments_count',
        'saved',
        'plays',
      ].reduce((accum, metric) => {
        accum[metric] = formattedData.value
          .map((d) => d[metric])
          .sort((a, b) => a - b);
        return accum;
      }, {});
    });

    const { getCellColor: getColor, colors } = useColorGrading();

    function getCellColor(stat, metric) {
      return getColor(stat, statsIndex.value[metric]);
    }

    return {
      dataFormat,
      tableHeaders,
      formattedData,
      getCellColor,
      colors,
    };
  },
};
</script>

<style lang="scss" scoped>
.channel-posts-table {
  min-height: unset;

  &__legend {
    @media (min-width: 544px) {
      position: absolute;
      top: -1.5rem;
      right: 0;
    }
  }
}

.posts-table-wrapper {
  margin: 1.5rem -1rem 0;
}

.posts-table {
  &::v-deep {
    > .v-data-table__wrapper > table > tbody > tr > td {
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
    }
  }

  &::v-deep {
    > .v-data-table__wrapper > table > tbody > tr > td {
      border-left: thin solid rgba(0, 0, 0, 0.12);
      border-right: thin solid rgba(0, 0, 0, 0.12);

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}
</style>
