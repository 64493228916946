import { computed, unref } from 'vue';

import { getAppSetting } from '@/utils';
import { useStore } from '../';

export function useSniperLink() {
  const store = useStore();

  const email = computed(() => store.getters.currentUser.email);

  const emailProvider = computed(
    () => store.getters.currentUser.email_provider,
  );

  const url = computed(() => {
    const emailValue = unref(email);
    const senderEmailAddress = getAppSetting('senderEmailAddress');

    if (
      ['google', 'g-suite'].includes(emailProvider.value) ||
      emailValue.match(/@gmail|@google/)
    ) {
      return {
        url: `https://mail.google.com/mail/u/${emailValue}/#search/from%3A(${encodeURIComponent(
          senderEmailAddress,
        )})+in%3Aanywhere+newer_than%3A1h`,
        text: 'Open in Gmail',
      };
    } else if (
      emailProvider.value === 'microsoft' ||
      emailValue.match(/@outlook|@live/)
    ) {
      return {
        url: `https://outlook.live.com/mail/?login_hint=${encodeURIComponent(
          emailValue,
        )}`,
        text: 'Open in Outlook',
      };
    } else if (emailValue.match(/@icloud/)) {
      return {
        url: `https://www.icloud.com/mail/`,
        text: 'Open in iCloud',
      };
    } else if (emailProvider.value === 'yahoo' || emailValue.match(/@yahoo/)) {
      return {
        url: `https://mail.yahoo.com/d/search/keyword=from%253A${encodeURIComponent(
          senderEmailAddress,
        )}`,
        text: 'Open in Yahoo',
      };
    } else if (
      emailProvider.value === 'proton' ||
      emailValue.match(/@proton/)
    ) {
      return {
        url: `https://mail.proton.me/u/0/all-mail#from=${encodeURIComponent(
          senderEmailAddress,
        )}`,
        text: 'Open in ProtonMail',
      };
    }

    return null;
  });

  return url;
}
