<template>
  <v-dialog
    v-model="localValue"
    max-width="400"
    @click:outside="close"
    @keydown.esc="close"
  >
    <Card>
      <CardTitle>
        <VText component="h2" variant="h4">Add a Filter</VText>
        <FlexSpacer />
        <IconButton size="1.25rem" aria-label="Close" @click="close">
          <CloseIcon />
        </IconButton>
      </CardTitle>
      <Divider color="gray200" />
      <template v-if="selectedFilter">
        <SingleSelector
          :filter-label="selectedFilter.name"
          :filter-set="currentFilterSet"
          cancel-button-text="Back"
          @add="addFilter"
          @cancel="handleBack"
        />
      </template>

      <template v-else>
        <List>
          <template v-for="(option, i) in formattedFilters">
            <ListItem
              :key="option.name"
              :disabled="isFilterDisabled(option)"
              component="button"
              type="button"
              @click="selectedFilter = option"
            >
              <ListItemTitle>
                <b>{{ option.name }}</b>
              </ListItemTitle>
            </ListItem>
            <Divider :key="i" color="gray200" thickness="1" />
          </template>
        </List>
      </template>
    </Card>
  </v-dialog>
</template>

<script>
import {
  Card,
  CardTitle,
  Divider,
  VText,
  List,
  ListItem,
  ListItemTitle,
  FlexSpacer,
  IconButton,
  CloseIcon,
} from '@campsite-bio/component-lib';
import { computed, ref, toRefs } from 'vue';

import { useModel } from '../../../../compositions';
import SingleSelector from './single-selector';
import {
  DEFAULT_FILTER_OPTIONS,
  DEFAULT_FILTER_OPERATORS,
} from '../../filters';

export default {
  components: {
    Card,
    CardTitle,
    Divider,
    VText,
    List,
    ListItem,
    ListItemTitle,
    FlexSpacer,
    IconButton,
    CloseIcon,
    SingleSelector,
  },

  props: {
    value: Boolean,

    filters: {
      type: Object,
      default: null,
    },

    currentFilters: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const { value, filters } = toRefs(props);
    const localValue = useModel(value, (val) => emit('input', val));
    const selectedFilter = ref(null);
    const filterOperator = ref(null);
    const filterValue = ref(null);

    const formattedFilters = computed(() => {
      if (!props.filters) {
        return [];
      }

      return Object.keys(props.filters).map((key) => {
        const filter = props.filters[key];

        return {
          name: key,
          path: filter.v2_path,
        };
      });
    });

    const currentFilterSet = computed(() => {
      if (!filters.value) return [];

      const matchedFilters = filters.value[selectedFilter.value.name];
      return matchedFilters ? matchedFilters.values : [];
    });

    function close() {
      localValue.value = false;

      setTimeout(() => {
        selectedFilter.value = null;
      }, 250);
    }

    function addFilter({ operator, value }) {
      const selectedOperator = DEFAULT_FILTER_OPERATORS.find(
        (o) => o.value === operator,
      );

      emit('add', {
        filter: selectedFilter.value,
        operator: selectedOperator,
        value: value,
      });

      close();
    }

    function handleBack() {
      selectedFilter.value = null;
    }

    function isFilterDisabled(filter) {
      return props.currentFilters.some((f) => f.filter.name === filter.name);
    }

    return {
      DEFAULT_FILTER_OPTIONS,
      localValue,
      selectedFilter,
      close,
      filterOperator,
      DEFAULT_FILTER_OPERATORS,
      filterValue,
      addFilter,
      currentFilterSet,
      handleBack,
      formattedFilters,
      isFilterDisabled,
    };
  },
};
</script>

<style lang="scss" scoped></style>
