<template>
  <Card border-color="primary">
    <CardTitle>
      <VText component="h2" variant="h5">Setup your embed link</VText>
      <FlexSpacer />
      <IconButton
        size="1.25rem"
        :loading="isDeleting"
        title="Delete"
        aria-label="Delete"
        @click="$emit('remove')"
      >
        <CloseIcon />
      </IconButton>
    </CardTitle>
    <Divider color="primary" />
    <CardText>
      <ValidationObserver v-slot="{ invalid, handleSubmit }" ref="form" slim>
        <form @submit.prevent="handleSubmit(handleSetup)">
          <FormGroup :gutter-bottom="!!selectedSource">
            <Label component="span" block gutter-bottom
              >Choose your embed source</Label
            >
            <BoxInputGroup>
              <BoxInput
                v-for="(
                  { name, source, iconComponent, color }, index
                ) in EMBED_TYPES"
                :key="source"
                v-model="selectedSource"
                :name="`embed${linkId}`"
                :input-id="`embed${index}${linkId}`"
                :input-value="source"
                :tooltip="name"
              >
                <component :is="iconComponent" slot="icon" :color="color" />
              </BoxInput>
            </BoxInputGroup>
          </FormGroup>

          <template v-if="selectedSource">
            <ValidationProvider
              v-slot="{ errors, ariaMsg, ariaInput }"
              ref="urlProvider"
              :rules="{
                required: true,
                url: true,
                validate_embed_type: {
                  source: selectedSourceDetails.source,
                  partial: selectedSourceDetails.matchesMultiple,
                },
              }"
              name="embed url"
              :debounce="100"
              mode="aggressive"
            >
              <FormGroup>
                <Label :for="`embedurl${linkId}`"
                  >Enter {{ selectedSourceDetails.name }} URL</Label
                >
                <Input
                  :id="`embedurl${linkId}`"
                  v-model="url"
                  :placeholder="selectedSourceDetails.placeholder"
                  v-bind="ariaInput"
                />
                <FormGroupHelp color="error" v-bind="ariaMsg">{{
                  errors[0]
                }}</FormGroupHelp>
              </FormGroup>
            </ValidationProvider>
            <Button type="submit" :loading="isSaving" :disabled="invalid"
              >Finish Setup</Button
            >
          </template>
        </form>
      </ValidationObserver>
    </CardText>
  </Card>
</template>

<script>
import {
  Card,
  CardText,
  CardTitle,
  Button,
  Divider,
  Input,
  Label,
  FormGroup,
  FormGroupHelp,
  VText,
  IconButton,
  CloseIcon,
  FlexSpacer,
  getEmbedType,
  EMBED_ICONS,
} from '@campsite-bio/component-lib';
import { computed, nextTick, ref, watch } from 'vue';
import { EMBED_TYPES } from './embed-types';
import { useStore } from '../../../compositions';
import { BoxInput, BoxInputGroup } from '../../box-input';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import toast from '../../../utils/toast';

export default {
  components: {
    Card,
    CardText,
    CardTitle,
    Button,
    Divider,
    Input,
    FormGroup,
    FormGroupHelp,
    Label,
    VText,
    FlexSpacer,
    IconButton,
    CloseIcon,
    BoxInput,
    BoxInputGroup,
    ValidationProvider,
    ValidationObserver,
    ...EMBED_ICONS,
  },

  props: {
    embedOptions: {
      type: Object,
      required: true,
    },

    linkId: {
      type: String,
      required: true,
    },

    isDeleting: Boolean,
  },

  setup(props, { emit }) {
    const store = useStore();
    const selectedSource = ref(null);
    const url = ref(null);
    const isSaving = ref(false);
    const form = ref(null);

    const selectedSourceDetails = computed(() => {
      if (!selectedSource.value) return null;
      return EMBED_TYPES.find((type) => type.source === selectedSource.value);
    });

    watch(selectedSource, async () => {
      url.value = null;
      if (form.value) {
        await nextTick();
        form.value.reset();
      }
    });

    async function handleSetup() {
      isSaving.value = true;

      try {
        // Save the result to the feed options
        const { link } = await store.dispatch('links/updateOptions', {
          id: props.linkId,
          name: 'embed',
          source: selectedSource.value,
          has_setup: true,
        });

        store.commit('links/updateLinkUrl', {
          id: props.linkId,
          value: url.value,
        });
        // Show the link now that it's been setup
        store.commit('links/updateLinkOptions', {
          id: props.linkId,
          value: link.embed_options,
          name: 'embed_options',
        });
        emit('setup-complete');
      } catch (e) {
        console.error(e);
        toast.error('There was an error trying to get your embed source');
      }

      isSaving.value = false;
    }

    return {
      EMBED_TYPES,
      selectedSource,
      selectedSourceDetails,
      url,
      handleSetup,
      isSaving,
      form,
    };
  },
};
</script>

<style lang="scss" scoped></style>
