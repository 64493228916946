// clipboard picker directive
import Vue from 'vue';
import ClipboardJS from 'clipboard';
import toast from '../utils/toast';

function getClipboardEl(el) {
  if (
    el.tagName.toLowerCase() === 'button' ||
    el.getAttribute('data-clipboard-text')
  )
    return el;
  else return el.querySelector('button');
}

const ClipboardDirective = {
  twoWay: true,
  inserted: function (el, binding, vnode) {
    el.clipboard = new ClipboardJS(getClipboardEl(el));

    el.clipboard.on('success', function (e) {
      toast.success('Copied to clipboard', { timeout: 2000 });

      e.clearSelection();
    });

    el.clipboard.on('error', function (e) {
      toast.success('Error copying to clipboard', { timeout: 3000 });
    });
  },
  unbind: function (el, binding, vnode) {
    el.clipboard.destroy();
  },
};

Vue.directive('clipboard', ClipboardDirective);
