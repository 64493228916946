import { computed, provide, ref } from 'vue';
import { useStore } from './use-store';
import toast from '@/utils/toast';

export function usePlans() {
  const store = useStore();
  const isLoadingPlans = ref(true);
  const plan = ref('free');
  const duration = ref('month');

  async function load() {
    try {
      isLoadingPlans.value = true;
      await store.dispatch('subscriptions/getProducts');
      isLoadingPlans.value = false;

      if (
        selectedStripePlan.value &&
        selectedStripePlan.value.interval === 'year'
      )
        duration.value = 'year';
    } catch (e) {
      console.error(e);
      toast.error(
        'There was an error getting Campsite plans. Please try again soon.',
      );
    }
  }

  load();

  const products = computed(() => {
    return store.getters['subscriptions/products'];
  });

  const pro = computed(() => {
    return store.getters['subscriptions/pro'];
  });

  const proPlus = computed(() => {
    return store.getters['subscriptions/proPlus'];
  });

  const orgPro = computed(() => {
    return store.getters['subscriptions/orgPro'];
  });

  const orgProPlus = computed(() => {
    return store.getters['subscriptions/orgProPlus'];
  });

  const proSeats = computed(() => {
    return store.getters['subscriptions/proSeats'];
  });

  const memberSeats = computed(() => {
    return store.getters['subscriptions/memberSeats'];
  });

  const proProfiles = computed(() => {
    return store.getters['subscriptions/proProfiles'];
  });

  const analyticsSeats = computed(() => {
    return store.getters['subscriptions/analyticsSeats'];
  });

  const seatPrice = computed(() =>
    proSeats.value.prices.find((p) => p.interval === planInterval.value),
  );

  const memberSeatPrice = computed(() =>
    memberSeats.value.prices.find((p) => p.interval === planInterval.value),
  );
  const profilePrice = computed(() =>
    proProfiles.value.prices.find((p) => p.interval === planInterval.value),
  );
  const analyticsPrice = computed(() => {
    return analyticsSeats.value.prices.find(
      (p) => p.interval === planInterval.value,
    );
  });

  const selectedStripePlan = computed(() => {
    if (!pro.value) return null;

    const prices = products.value.reduce((acc, product) => {
      if (product.prices) acc.push(...product.prices);
      return acc;
    }, []);

    return prices.find((p) => p.stripe_id === plan.value);
  });

  const planInterval = computed(() => {
    return selectedStripePlan.value?.interval;
  });

  const planCost = computed(() => {
    return selectedStripePlan.value?.price;
  });

  const provideData = {
    isLoadingPlans,
    plan,
    duration,
    selectedStripePlan,
    planInterval,
    planCost,
    pro,
    proPlus,
    proSeats,
    memberSeats,
    proProfiles,
    analyticsSeats,
    seatPrice,
    memberSeatPrice,
    profilePrice,
    analyticsPrice,
    orgPro,
    orgProPlus,
  };

  provide('planData', provideData);

  return provideData;
}
