<template>
  <Dialog
    v-model="isOpen"
    max-width="450px"
    :title="`Edit your ${item.provider_title} integration`"
    :disabled="disabled"
  >
    <template #activator="data">
      <slot name="activator" v-bind="data" />
    </template>

    <ConnectedAuthorization
      v-if="isConnected"
      :item="item"
      :is-open="isOpen"
      :is-deleting="isDeleting"
      @remove="isDeleteOpen = true"
      @save="handleSave"
    />
    <DisconnectedAuthorization
      v-else
      :item="item"
      :is-deleting="isDeleting"
      @remove="isDeleteOpen = true"
      @success="isOpen = false"
    />

    <v-dialog v-model="isDeleteOpen" max-width="400px" persistent>
      <Card>
        <CardText>
          <VText
            >Are you sure you want to remove this integration? Any link using it
            will no longer work.</VText
          >
        </CardText>
        <CardActions>
          <Button
            variant="flat"
            :disabled="isDeleting"
            @click="isDeleteOpen = false"
          >
            Cancel
          </Button>
          <Button
            variant="flat"
            color="error"
            :loading="isDeleting"
            @click="remove"
          >
            Delete
          </Button>
        </CardActions>
      </Card>
    </v-dialog>
  </Dialog>
</template>

<script>
import {
  Card,
  CardText,
  CardActions,
  Button,
  VText,
} from '@campsite-bio/component-lib';
import { computed, ref, toRefs } from 'vue';

import { useModel, useStore } from '../../compositions';
import { localAxios } from '../../apis';
import { AUTHORIZATION_STATUS } from '../../config';
import toast from '../../utils/toast';
import { Dialog } from '../dialogs';
import {
  ConnectedAuthorization,
  DisconnectedAuthorization,
} from './components';
import bus from '../../bus';

export default {
  components: {
    Dialog,
    Card,
    VText,
    CardText,
    CardActions,
    Button,
    ConnectedAuthorization,
    DisconnectedAuthorization,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },

    value: Boolean,
    disabled: Boolean,
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const store = useStore();
    const isOpen = useModel(value, (val) => emit('input', val));
    const isDeleteOpen = ref(false);

    const isDeleting = ref(false);

    const isConnected = computed(() => {
      return props.item.status === AUTHORIZATION_STATUS.CONNECTED;
    });

    async function remove() {
      isDeleting.value = true;

      try {
        await localAxios.delete(
          `/api/profiles/authorizations/${props.item.campsite_id.$oid}/${props.item._id.$oid}`,
        );
        store.commit('profiles/removeAuthorization', props.item._id.$oid);

        isOpen.value = false;
      } catch (e) {
        console.error(e);
        toast.error('There was an error trying to delete your integration.');
      }

      isDeleting.value = false;
    }

    function handleSave(auth) {
      isOpen.value = false;
      bus.$emit('app-authorization:save', auth);
    }

    return {
      isOpen,
      isDeleteOpen,
      isDeleting,
      remove,
      isConnected,
      handleSave,
    };
  },
};
</script>

<style lang="scss" scoped></style>
