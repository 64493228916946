<template>
  <NotificationCard
    ref="root"
    :title="title"
    :text="message"
    :dismiss-props="{ loading: isDismissing }"
    :style="styles"
    @dismiss="dismiss"
  >
    <component :is="icon" v-if="icon" slot="icon" />
    <template v-if="actions">
      <InlineLink
        v-for="({ text, ...rest }, i) in actions"
        :key="i"
        v-bind="rest"
        @click.native="logClick"
        >{{ text }}</InlineLink
      >
    </template>
  </NotificationCard>
</template>

<script>
import {
  NotificationCard,
  InlineLink,
  useTheme,
  getThemeColor,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';

import { useNotification } from '../../compositions';

export default {
  components: {
    NotificationCard,
    InlineLink,
  },

  props: {
    borderColor: {
      type: String,
      default: 'secondary',
    },

    notification: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const {
      root,
      title,
      message,
      icon,
      actions,
      dismiss,
      logClick,
      isDismissing,
    } = useNotification(props.notification, context);
    const theme = useTheme();

    const styles = computed(() => {
      return {
        borderColor: getThemeColor(props.borderColor, theme),
      };
    });

    return {
      root,
      title,
      message,
      icon,
      actions,
      dismiss,
      logClick,
      isDismissing,
      styles,
    };
  },
};
</script>

<style lang="scss" scoped></style>
