<template>
  <div ref="root" class="period-selector">
    <FilterBtn :disabled="disabled">
      <template v-if="currentStartEndDate">
        {{ currentStartEndDate }}
      </template>
      <Skeleton v-else width="87px" />
    </FilterBtn>
  </div>
</template>

<script>
import { Skeleton } from '@campsite-bio/component-lib';
import { computed } from 'vue';
import { DateTime } from 'luxon';

import FilterBtn from '../filter-btn';

export default {
  components: {
    FilterBtn,
    Skeleton,
  },

  props: {
    startDate: {
      type: String,
      default: null,
    },

    endDate: {
      type: String,
      default: null,
    },

    disabled: Boolean,
  },

  setup(props) {
    // Get the dates to display in the date picker
    const currentStartEndDate = computed(() => {
      if (!props.startDate) return null;

      const dateFormat = 'MMM d';
      return `${DateTime.fromISO(props.startDate).toFormat(
        dateFormat,
      )} - ${DateTime.fromISO(props.endDate).toFormat(dateFormat)}`;
    });

    return {
      currentStartEndDate,
    };
  },
};
</script>

<style lang="scss" scoped></style>
