<template>
  <Fragment>
    <CardText>
      <VText gutter-bottom
        >Grant us access to your Instagram and unlock these features:</VText
      >
      <ul>
        <li>Access to your posts and their media for your images</li>
        <li>Use our image grid link</li>
        <li>Enable the upcoming post feature</li>
      </ul>
    </CardText>
    <CardActions align="center" direction="column">
      <Button @click="authorize">Connect Instagram Account</Button>
    </CardActions>
  </Fragment>
</template>

<script>
import {
  CardText,
  VText,
  CardActions,
  Button,
} from '@campsite-bio/component-lib';
import { inject, nextTick } from 'vue';
import { Fragment } from 'vue-fragment';

import { useInstagramAuthorization, useStore } from '../../../../compositions';
import bus from '../../../../bus';

export default {
  components: {
    CardText,
    CardActions,
    VText,
    Button,
    Fragment,
  },

  setup() {
    const store = useStore();
    const { isOpen } = inject('multi-step-dialog');

    const { authorize } = useInstagramAuthorization({
      onCancel() {},
      async onSuccess(data) {
        isOpen.value = false;
        await store.dispatch('profiles/getAppAuthorizations');
        await nextTick();
        bus.$emit(`app-authorization:${data.authId}:open`);
      },
    });

    return { authorize };
  },
};
</script>

<style lang="scss" scoped></style>
