<template>
  <ChartCard
    class="clicks-card"
    title="Link Clicks"
    :is-loading="isLoading"
    :highlights="highlights"
  >
    <ChartWrapper
      width="100%"
      height="200"
      type="line"
      :options="chartOptions"
      :data="series"
    />
  </ChartCard>
</template>

<script>
import { computed, toRefs } from 'vue';
import { useTheme } from '@campsite-bio/component-lib';

import ChartCard from './chart-card';
import { ChartWrapper } from '../';
import { useApexOptions, useCategories, useDataColors } from '../compositions';

export default {
  components: {
    ChartWrapper,
    ChartCard,
  },

  props: {
    allData: {
      type: Object,
      default: null,
    },

    data: {
      type: Array,
      default: null,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const { allData, data } = toRefs(props);
    const theme = useTheme();
    const dataColors = useDataColors();

    const currentData = computed(() => {
      return props.data?.filter(({ _id }) => _id.range === 'current');
    });

    const pastData = computed(() => {
      return props.data?.filter(({ _id }) => _id.range === 'past');
    });

    const series = computed(() => {
      if (!currentData.value) return null;

      const currentStats = {
        label: 'Clicks',
        data: [],
        backgroundColor: dataColors.value[0],
        borderColor: dataColors.value[0],
        pointBackgroundColor: dataColors.value[0],
        pointBorderColor: dataColors.value[0],
      };
      const pastStats = {
        label: 'Past Clicks',
        data: [],
        backgroundColor: theme.value.colors.gray200,
        borderColor: theme.value.colors.gray200,
        pointRadius: 0,
      };
      currentData.value.forEach((stat, index) => {
        currentStats.data.push(stat.count);
      });
      pastData.value.forEach((stat, index) => {
        pastStats.data.push(stat.count);
      });
      return {
        datasets: [currentStats, pastStats],
        labels: categories.value,
      };
    });

    const { categories } = useCategories({ allData, data });

    const chartOptions = useApexOptions();

    const highlights = computed(() => {
      if (!series.value) return null;

      return [
        {
          title: series.value.datasets[0].data.reduce((accum, stat) => {
            accum += stat;
            return accum;
          }, 0),
          subtitle: 'Clicks',
          color: dataColors.value[0],
        },
      ];
    });

    return {
      chartOptions,
      highlights,
      series,
      categories,
    };
  },
};
</script>

<style lang="scss" scoped>
.clicks-card {
  min-height: unset;
}

#chart .apexcharts-tooltip {
  display: none;
}
</style>
