<template>
  <div class="analytics-grid">
    <slot />
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
@media print {
  .analytics-grid {
    display: block !important;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    > *,
    .v-chart-card {
      float: left;
      margin: 0 0.5rem 1rem;
      width: calc(33.333% - 1rem);
    }

    > .analytics-grid__title {
      margin-bottom: 0.5rem;
      margin-top: 1rem;
    }
  }
}
</style>

<style lang="scss" scoped>
.analytics-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: minmax(0, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(12, 1fr);
  }

  &::v-deep {
    .span-3 {
      @media (min-width: 768px) {
        grid-column: span 3;

        @media print {
          width: calc(33% - 1rem);
        }
      }
    }

    .span-4 {
      @media (min-width: 768px) {
        grid-column: span 4;
      }

      @media print {
        width: calc(50% - 1rem);
      }
    }

    .span-6 {
      @media (min-width: 768px) {
        grid-column: span 6;
      }

      @media print {
        width: calc(50% - 1rem);
      }
    }

    .span-8 {
      @media (min-width: 768px) {
        grid-column: span 12;
      }

      @media (min-width: 992px) {
        grid-column: span 8;
      }

      @media print {
        width: calc(100% - 1rem);
      }
    }

    .span-12 {
      @media (min-width: 768px) {
        grid-column: span 12;
      }

      @media print {
        width: calc(100% - 1rem);
      }
    }
  }
}
</style>
