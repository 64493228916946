<template>
  <LinkWrapper v-bind="$props" @add-link="$emit('add-link', $event)">
    <LinkCard>
      <div class="carousel-items">
        <div v-for="banner in banners" :key="banner._id.$oid">
          <button
            class="link-carousel-item"
            type="button"
            :disabled="isReadonly"
            @click="openBanner(banner)"
          >
            <ImageIcon />
            <img
              v-if="banner.media_item"
              :src="getCroppedMediaUrl(banner.media_item.url)"
              :alt="banner.image_alt"
            />
            <span class="sr-only">Edit banner</span>
          </button>
        </div>
        <div>
          <button
            class="link-carousel-item link-carousel-item--dashed"
            type="button"
            title="Add banner"
            :disabled="isBannerLoading || isBannerAddDisabled || isReadonly"
            @click="addBanner"
          >
            <PlusIcon />
            <span class="sr-only">Add banner</span>
          </button>
        </div>
      </div>

      <template slot="btm">
        <LinkBtn
          v-if="!isStatsHidden"
          :active="isExpanded('stats')"
          :aria-label="`Link analytics - ${link.number_of_clicks} clicks`"
          :aria-controls="`stats-${id}`"
          @click="toggleExpand('stats')"
        >
          <LineChartIcon slot="icon" />
          <template v-if="link.number_of_clicks" slot="stat">
            {{ link.number_of_clicks | numberFormatter(1) }}
          </template>
        </LinkBtn>
        <LinkBtn
          v-if="!isDeleted && !isScheduleHidden"
          aria-label="Schedule link"
          :active="isExpanded('schedule')"
          :highlight="isExpanded('schedule')"
          :aria-controls="`schedule-${id}`"
          @click="toggleExpand('schedule')"
        >
          <CalendarIcon slot="icon" />
        </LinkBtn>
        <v-tooltip v-if="!isDeleted && !isPinnedHidden" top>
          <template #activator="{ on }">
            <LinkBtn
              v-if="!isDeleted"
              aria-label="Pin link"
              :pinned="pinned"
              :disabled="isPinnedDisabled"
              v-on="on"
              @click="pinned = !pinned"
            >
              <PinIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>{{ pinnedText }}</span>
        </v-tooltip>
        <v-tooltip v-if="!isDeleted && !isReadonly" top>
          <template #activator="{ on }">
            <LinkBtn
              :active="isExpanded('banners')"
              :highlight="isExpanded('banners')"
              :aria-controls="`banners-${id}`"
              v-on="on"
              @click="toggleExpand('banners')"
            >
              <ImageIcon slot="icon" />
              Banners
            </LinkBtn>
          </template>
          <span>Edit banners</span>
        </v-tooltip>
        <v-tooltip v-if="isDeleted" top>
          <template #activator="{ on }">
            <LinkBtn
              aria-label="Restore carousel"
              class="link__btn--restore js-link-restore"
              :disabled="isRestoring || isCarouselRestoreDisabled"
              v-on="on"
              @click="handleRestore"
            >
              <RepeatIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>{{
            isCarouselRestoreDisabled
              ? 'Upgrade to restore carousels with more than one banner'
              : 'Restore carousel'
          }}</span>
        </v-tooltip>
      </template>

      <template slot="actions">
        <StatsExpand
          :is-open="isExpanded('stats')"
          @close="toggleExpand('stats')"
        />
        <ScheduleExpand
          :is-open="isExpanded('schedule')"
          :link="link"
          @close="toggleExpand('schedule')"
        />
        <CarouselBannersExpand
          :is-open="isExpanded('banners')"
          :link="link"
          :link-id="id"
          :is-banner-loading="isBannerLoading"
          :is-banner-add-disabled="isBannerAddDisabled"
          :carousel-options="options"
          @close="toggleExpand('banners')"
          @add="addBanner"
        />
        <CarouselSettingsExpand
          :link-id="id"
          :is-open="isExpanded('default')"
          :carousel-options="options"
          @close="toggleExpand('default')"
          @save="saveOptions"
        />
      </template>
    </LinkCard>
  </LinkWrapper>
</template>

<script>
import {
  ImageIcon,
  LineChartIcon,
  CalendarIcon,
  PinIcon,
  RepeatIcon,
  PlusIcon,
  getCroppedMediaUrl,
} from '@campsite-bio/component-lib';
import { computed, nextTick, ref } from 'vue';

import toast from '../../../utils/toast';
import CarouselBannersExpand from './banners-expand';
import CarouselSettingsExpand from './settings-expand';
import { LinkBtn, LinkWrapper, LinkCard } from '../components';
import { StatsExpand, ScheduleExpand } from '../expand';
import { useStore } from '../../../compositions';
import { LinkProps } from '../link-props';
import { useLink } from '../compositions';

export default {
  components: {
    StatsExpand,
    ScheduleExpand,
    ImageIcon,
    LineChartIcon,
    CalendarIcon,
    PinIcon,
    RepeatIcon,
    LinkBtn,
    PlusIcon,
    CarouselBannersExpand,
    CarouselSettingsExpand,
    LinkWrapper,
    LinkCard,
  },

  props: LinkProps,

  setup(props) {
    const {
      root,
      link,
      isSavingOptions,
      options,
      saveOptions,
      toggleExpand,
      ...rest
    } = useLink(props, {
      name: 'carousel',
      expandOnAdd: 'banners',
      optionsName: 'carousel',
    });
    const store = useStore();
    const isBannerLoading = ref(false);

    const banners = computed(() => {
      return props.link.banners;
    });

    const isFreePlan = computed(() => {
      return store.getters['profiles/isProfileOnFreePlan'];
    });

    const isBannerAddDisabled = computed(() => {
      return isFreePlan.value && banners.value && banners.value.length >= 1;
    });

    const isCarouselRestoreDisabled = computed(() => {
      return isFreePlan.value && banners.value && banners.value.length > 1;
    });

    function addBanner() {
      isBannerLoading.value = true;

      store
        .dispatch('links/addLinkBanner', { id: props.link._id.$oid })
        .then((json) => {
          if (json.success) isBannerLoading.value = false;
          else toast.error(json.error_message);
        })
        .catch((e) => {
          console.error(e);
          isBannerLoading.value = false;
          toast.error('There was an error trying to add a banner');
        });
    }

    async function openBanner(banner) {
      toggleExpand('banners');
      await nextTick();
      await nextTick();
      root.value?.querySelector(`#banner${banner._id.$oid}`).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }

    return {
      ...rest,
      banners,
      getCroppedMediaUrl,
      options,
      saveOptions,
      isSavingOptions,
      isBannerLoading,
      addBanner,
      isBannerAddDisabled,
      isCarouselRestoreDisabled,
      openBanner,
      toggleExpand,
    };
  },
};
</script>

<style lang="scss" scoped>
.carousel-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  gap: 0.5rem;
}

.link-carousel-item {
  align-items: center;
  background: #fff;
  border: 3px solid #ccc;
  border-radius: var(--g-border-radius-standard);
  border-radius: min(
    var(--g-border-radius-standard),
    var(--g-border-radius-min)
  );
  display: flex;
  font-size: 2.5rem;
  height: 0;
  padding: 0;
  padding-bottom: calc(100% - 10px);
  position: relative;
  justify-content: center;
  overflow: hidden;
  transition: all 250ms;
  width: 100%;

  &:hover,
  &:focus {
    border-color: var(--g-color-primary);
  }

  &:disabled {
    opacity: 0.6;

    &:hover,
    &:focus {
      border-color: #ccc;
    }
  }

  &--dashed {
    border-style: dashed;
  }

  .v-svg-icon.v-svg-icon {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    left: 0;
    height: 100%;
    pointer-events: none;
    position: absolute;
    object-fit: cover;
    top: 0;
    width: 100%;
  }
}
</style>
