<template>
  <ListItem class="v-profile-item">
    <ListItemAvatar>
      <img
        :src="
          convertImageUrlToEncodedUrl(
            campsite.profile_image,
            MEDIA_SIZES.profile_image,
          )
        "
        alt=""
      />
    </ListItemAvatar>

    <ListItemContent>
      <ListItemTitle class="v-profile-item__title">
        {{ campsite.username }}
      </ListItemTitle>
      <ListItemSubtitle v-if="subtitleToShow">
        <InlineLink
          v-if="subtitleLink"
          :to="subtitleLink"
          style="font-weight: 400"
          >{{ subtitleToShow }}</InlineLink
        >
        <template v-else>
          {{ subtitleToShow }}
        </template>
      </ListItemSubtitle>
    </ListItemContent>

    <slot name="actions">
      <ListItemAction v-if="campsite.enabled && hasAccess">
        <v-tooltip top>
          <template #activator="{ on }">
            <IconButton aria-label="Open profile" :to="openUrl" v-on="on">
              <LoginIcon size="1.25rem" />
            </IconButton>
          </template>
          <span>Open this profile</span>
        </v-tooltip>
      </ListItemAction>
      <ListItemAction v-else-if="!campsite.enabled">
        <v-tooltip top>
          <template #activator="{ on }">
            <IconButton disabled aria-label="Locked" v-on="on">
              <LockIcon size="1.15rem" />
            </IconButton>
          </template>
          <span>This profile is disabled</span>
        </v-tooltip>
      </ListItemAction>
      <ListItemAction v-if="canDelete && isOwner">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <IconButton
              aria-label="Open menu"
              :loading="isEnabling"
              v-bind="attrs"
              v-on="on"
            >
              <DotsVerticalIcon size="1.15rem" />
            </IconButton>
          </template>
          <Card>
            <List>
              <ListItem v-if="campsite.enabled" :to="openUrl">
                <ListItemIcon>
                  <LoginIcon size="1.15rem" />
                </ListItemIcon>
                <ListItemTitle>Open profile</ListItemTitle>
              </ListItem>
              <ListItem
                v-else-if="canEnable"
                component="button"
                type="button"
                @click="handleEnable"
              >
                <ListItemIcon>
                  <RepeatIcon size="1.15rem" />
                </ListItemIcon>
                <ListItemTitle>Enable profile</ListItemTitle>
              </ListItem>
              <ListItem
                v-if="canEnable"
                component="button"
                type="button"
                @click.stop="$emit('clone', campsite)"
              >
                <ListItemIcon>
                  <CopyIcon size="1.15rem" title="Delete" />
                </ListItemIcon>
                <ListItemTitle>Clone profile</ListItemTitle>
              </ListItem>
              <ListItem
                component="button"
                type="button"
                @click.stop="deleteDialog = true"
              >
                <ListItemIcon>
                  <TrashIcon size="1.15rem" title="Delete" />
                </ListItemIcon>
                <ListItemTitle>Delete profile</ListItemTitle>
              </ListItem>
            </List>
          </Card>
        </v-menu>
      </ListItemAction>
    </slot>

    <v-dialog
      v-if="canDelete"
      v-model="deleteDialog"
      max-width="300"
      @keydown.esc="deleteDialog = false"
    >
      <Card>
        <CardText
          ><VText
            >The <b>{{ campsite.username }}</b> profile and its data will be
            permanently deleted. This cannot be undone.</VText
          ></CardText
        >
        <CardActions>
          <Button
            variant="flat"
            :disabled="isDeleting"
            @click="deleteDialog = false"
            >Cancel</Button
          >
          <Button
            color="error"
            variant="flat"
            :loading="isDeleting"
            @click="handleDelete"
            >Confirm</Button
          >
        </CardActions>
      </Card>
    </v-dialog>
  </ListItem>
</template>

<script>
import {
  Card,
  CardText,
  CardActions,
  Button,
  IconButton,
  TrashIcon,
  CopyIcon,
  LockIcon,
  LoginIcon,
  DotsVerticalIcon,
  RepeatIcon,
  VText,
  List,
  ListItem,
  ListItemAction,
  ListItemAvatar,
  ListItemContent,
  ListItemTitle,
  ListItemSubtitle,
  ListItemIcon,
  convertImageUrlToEncodedUrl,
  MEDIA_SIZES,
  InlineLink,
} from '@campsite-bio/component-lib';
import { computed, ref } from 'vue';

import toast from '@/utils/toast';
import { timeout } from '@/utils';
import { useRoute, useStore } from '@/compositions';

export default {
  components: {
    Card,
    CardText,
    CardActions,
    Button,
    IconButton,
    TrashIcon,
    CopyIcon,
    LockIcon,
    LoginIcon,
    DotsVerticalIcon,
    RepeatIcon,
    VText,
    List,
    ListItem,
    ListItemAction,
    ListItemAvatar,
    ListItemContent,
    ListItemTitle,
    ListItemSubtitle,
    ListItemIcon,
    InlineLink,
  },

  props: {
    campsite: {
      type: Object,
      required: true,
    },

    canDelete: Boolean,
    canEnable: Boolean,

    subtitle: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const deleteDialog = ref(false);
    const isDeleting = ref(false);
    const isEnabling = ref(false);
    const store = useStore();
    const route = useRoute();

    const isOrgProfile = computed(() => {
      return !!props.campsite.organization_id;
    });

    const hasAccess = computed(() => {
      return props.campsite.access !== null;
    });

    const isOwner = computed(() => {
      if (props.campsite.access?.is_owner) return true;
      else return props.campsite.access?.entitlements === 'owner';
    });

    const subtitleToShow = computed(() => {
      if (props.subtitle) return props.subtitle;

      if (props.campsite.organization_name) {
        return props.campsite.organization_name;
      } else if (!isOwner.value) {
        return 'Collaborator';
      }

      return null;
    });

    const subtitleLink = computed(() => {
      if (!props.subtitle && props.campsite.organization_id) {
        return `/organization/${props.campsite.organization_id.$oid}`;
      }

      return null;
    });

    const openUrl = computed(() => {
      let returnUrl = route.value.query.returnUrl;
      const currentId = store.getters['profiles/currentProfileId'];
      // replace the profile id in the return url with the new one
      if (returnUrl) {
        returnUrl = returnUrl.replace(currentId, props.campsite._id.$oid);
      }
      return returnUrl ? returnUrl : `/profile/${props.campsite._id.$oid}`;
    });

    function handleClick() {
      if (props.campsite.enabled) {
        emit('click', props.campsite);
      }
    }

    async function handleEnable() {
      if (isEnabling.value) return;

      isEnabling.value = true;
      try {
        await store.dispatch('profiles/enable', props.campsite._id.$oid);

        toast.success(`${props.campsite.username} has been enabled`);

        emit('enabled');
      } catch (e) {
        console.error(e);
        const { error_message } = e.response?.data || {};
        toast.error(
          error_message
            ? error_message
            : 'There was an error trying to enable your profile',
        );
      }
      isEnabling.value = false;
    }

    async function handleDelete() {
      if (isDeleting.value) return;

      isDeleting.value = true;
      try {
        const profileId = props.campsite._id.$oid;
        const currentProfileId = store.getters['profiles/currentId'];
        await store.dispatch('profiles/delete', profileId);

        toast.success(`${props.campsite.username} has been deleted.`);

        // only reload the page if the current profile was removed
        if (profileId === currentProfileId) {
          await timeout(500);
          window.location.reload();
        } else {
          if (isOrgProfile.value) {
            store.dispatch('organizations/get');
          } else {
            store.dispatch('getUserData', { force: true });
          }
        }

        deleteDialog.value = false;
        emit('deleted');
      } catch (e) {
        isDeleting.value = false;
        console.error(e);
        const { error_message } = e.response?.data || {};
        toast.error(
          error_message
            ? error_message
            : 'There was an error trying to delete your Campsite',
        );
      }
    }

    return {
      isOwner,
      hasAccess,
      deleteDialog,
      isDeleting,
      isEnabling,
      handleClick,
      handleEnable,
      handleDelete,
      convertImageUrlToEncodedUrl,
      MEDIA_SIZES,
      openUrl,
      subtitleToShow,
      subtitleLink,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-profile-item {
  &__title {
    font-weight: 600;
  }

  &__stats {
    display: flex;
    margin-top: 0.15rem;

    &__stat {
      width: 75px;

      @media (min-width: 500px) {
        width: 100px;
      }
    }

    &__label {
      display: block;
      font-weight: 600;

      &::v-deep .v-badge {
        display: none;
        font-size: 65%;

        @media (min-width: 500px) {
          display: unset;
        }

        @media (min-width: 768px) {
          font-size: 70%;
        }
      }
    }
  }
}
</style>
