import { getEmbedType } from '@campsite-bio/component-lib';
import { localAxios } from '../apis';
import bus from '../bus';
import { simpleValidateHtmlStr, isValidUrl } from '../utils/';

export const minArrayLength = {
  name: 'min_array_length',

  params: ['min'],

  validate(value, { min }) {
    return value.length >= min || `You must select at least ${min} items`;
  },
};

export const url = {
  name: 'url',

  message: 'The {_field_} field is not a valid URL',

  validate(value) {
    const { isAccepted, reason } = isValidUrl(value);

    if (!isAccepted)
      return `The {_field_} field is not a valid URL (${reason})`;

    return true;
  },
};

export const usernameRemote = {
  name: 'username',

  async validate(value) {
    try {
      await localAxios.post('/api/profiles/check-username', {
        username: value,
      });

      return true;
    } catch (e) {
      const { error_message } = e.response?.data || {};
      return error_message || 'Something went wrong';
    }
  },
};

export const subdomainRemote = {
  name: 'subdomain',

  async validate(value) {
    try {
      await localAxios.post('/api/profiles/check-subdomain', {
        subdomain: value,
      });

      return true;
    } catch (e) {
      const { error_message } = e.response?.data || {};
      return error_message || 'Something went wrong';
    }
  },
};

export const customDomainRemote = {
  name: 'custom_domain',

  async validate(value) {
    try {
      const { data } = await localAxios.post('/api/profiles/check-domain', {
        custom_domain: value,
      });

      bus.$emit('custom-domain-check', data);

      return true;
    } catch (e) {
      const { error_message } = e.response?.data || {};
      return error_message || 'Something went wrong';
    }
  },
};

export const emailRemote = {
  name: 'email_remote',

  async validate(value) {
    try {
      await localAxios.post('/api/users/check-email', {
        email: value,
      });

      return true;
    } catch (e) {
      const { error_message } = e.response?.data || {};

      return error_message || 'Something went wrong';
    }
  },
};

export const validateHtml = {
  name: 'validate_html',

  message: 'There is a syntax error in your custom code',

  validate(value) {
    if (simpleValidateHtmlStr(value)) {
      return true;
    }

    return 'There is a syntax error in your custom code';
  },
};

export const validateEmbedType = {
  name: 'validate_embed_type',

  message: "This isn't a valid embed URL",

  params: ['source', 'partial'],

  validate(value, params) {
    const embedType = getEmbedType(value);
    if (embedType) {
      let matches = params.partial
        ? embedType.id.indexOf(params.source) === 0
        : embedType.id === params.source;
      if (matches) {
        return true;
      }
    }

    return "This isn't a valid embed URL";
  },
};

export const eventTag = {
  name: 'event_tag',

  validate(value) {
    if (value.indexOf('-') >= 0) return true;

    return 'You must have at least two words (ex. contact-form)';
  },
};
