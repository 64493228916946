<template>
  <BaseCard title="Upgrade to Pro">
    <VText gutter-bottom>Manage more profiles with Pro.</VText>
    <Button :to="actionUrl" size="small">Upgrade Now</Button>
  </BaseCard>
</template>

<script>
import { VText, Button } from '@campsite-bio/component-lib';

import BaseCard from './base-card';

export default {
  components: {
    VText,
    Button,
    BaseCard,
  },

  props: {
    actionUrl: {
      type: String,
      default: '/account/change-plan',
    },
  },
};
</script>

<style lang="scss" scoped></style>
