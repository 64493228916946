<template>
  <LinkWrapper v-bind="$props" @add-link="$emit('add-link', $event)">
    <LinkCard v-if="hasSetup">
      <LinkField>
        <Label block gutter-bottom>Feed Source</Label>
        <VText component="span" class="source">
          <component :is="feedType.iconComponent" />
          <a :href="url" target="_blank" rel="noopener noreferrer">
            <span>{{ feedTitle || feedType.name }}</span>
            <ExternalLinkIcon />
          </a>
        </VText>
      </LinkField>

      <LinkField>
        <Label :for="'label' + _uid">Feed Title</Label>
        <EmojiInput
          ref="labelRef"
          v-model="label"
          :input-id="'label' + _uid"
          border-color="gray200"
          autocomplete="off"
          maxlength="80"
          placeholder="My feed link"
          :show-emoji="isLargeScreen"
          :disabled="isReadonly"
          @focus="focusLink"
        >
          <IconButton
            slot="prepend"
            v-clipboard
            :data-clipboard-text="label"
            tabindex="-1"
            aria-label="Copy title"
          >
            <PencilIcon title="Copy title" />
          </IconButton>
        </EmojiInput>
      </LinkField>

      <template slot="btm">
        <LinkBtn
          v-if="!isStatsHidden"
          :active="isExpanded('stats')"
          :aria-label="`Link analytics - ${link.number_of_clicks} clicks`"
          :aria-controls="`stats-${id}`"
          @click="toggleExpand('stats')"
        >
          <LineChartIcon slot="icon" />
          <template v-if="link.number_of_clicks" slot="stat">
            {{ link.number_of_clicks | numberFormatter(1) }}
          </template>
        </LinkBtn>
        <LinkBtn
          v-if="!isDeleted && !isScheduleHidden"
          aria-label="Schedule link"
          :active="isExpanded('schedule')"
          :highlight="isExpanded('schedule')"
          :aria-controls="`schedule-${id}`"
          @click="toggleExpand('schedule')"
        >
          <CalendarIcon slot="icon" />
        </LinkBtn>
        <v-tooltip v-if="!isDeleted && !isPinnedHidden" top>
          <template #activator="{ on }">
            <LinkBtn
              v-if="!isDeleted"
              aria-label="Pin link"
              :pinned="pinned"
              :disabled="isPinnedDisabled"
              v-on="on"
              @click="pinned = !pinned"
            >
              <PinIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>{{ pinnedText }}</span>
        </v-tooltip>
        <LinkBtn
          v-if="!isDeleted && !isFreePlan && !isHighlightHidden"
          aria-label="Highlight link"
          :active="isExpanded('highlight')"
          :highlight="!!highlight"
          :aria-controls="`highlight-${id}`"
          @click="toggleExpand('highlight')"
        >
          <template slot="icon">
            <StarIcon v-if="highlight" />
            <StarOIcon v-else />
          </template>
        </LinkBtn>
        <v-tooltip v-if="!isFreePlan && !isDeleted && !isEventTagHidden" top>
          <template #activator="{ on }">
            <LinkBtn
              aria-label="Event tag"
              :active="isExpanded('event-tag')"
              :aria-controls="`event-label-${id}`"
              v-on="on"
              @click="toggleExpand('event-tag')"
            >
              <TagIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>Event tag</span>
        </v-tooltip>
        <v-tooltip v-if="isDeleted" top>
          <template #activator="{ on }">
            <LinkBtn
              class="link__btn--restore js-link-restore"
              aria-label="Restore link"
              :disabled="isRestoring || isFreePlan"
              v-on="on"
              @click="handleRestore"
            >
              <RepeatIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>{{ isFreePlan ? 'Upgrade to restore' : 'Restore link' }}</span>
        </v-tooltip>
      </template>

      <template slot="actions">
        <HighlightExpand
          v-model="highlight"
          :is-open="isExpanded('highlight')"
          :link-enabled="link.enabled"
          @close="toggleExpand('highlight')"
        >
          <template slot="alert">
            <Alert variant="outline" style="margin-bottom: 0.75rem">
              <InfoIcon slot="icon" />
              This will only highlight the first link in your feed
            </Alert>
          </template>
        </HighlightExpand>
        <StatsExpand
          :is-open="isExpanded('stats')"
          @close="toggleExpand('stats')"
        />
        <ScheduleExpand
          :is-open="isExpanded('schedule')"
          :link="link"
          @close="toggleExpand('schedule')"
        />
        <EventLabelExpand
          v-if="!isFreePlan"
          :is-open="isExpanded('event-tag')"
          :link="link"
          @close="toggleExpand('event-tag')"
        />
        <FeedSettingsExpand
          :is-open="isExpanded('default')"
          :feed-options="options"
          :feed-type="feedType"
          :link-id="id"
          @close="toggleExpand('default')"
          @save="saveOptions"
        />
      </template>
    </LinkCard>
    <FeedSetup
      v-else
      :link-id="id"
      :feed-options="options"
      :is-deleting="isDeleting"
      @setup-complete="toggleExpand('feed')"
      @remove="handleDelete"
    />
  </LinkWrapper>
</template>

<script>
import {
  Card,
  CardText,
  CardActions,
  VText,
  Alert,
  InfoIcon,
  Button,
  IconButton,
  Label,
  EmojiInput,
  PencilIcon,
  YoutubeIcon,
  WordpressIcon,
  BloggerIcon,
  SquarespaceIcon,
  ShopifyIcon,
  WixLogoIcon,
  TumblrIcon,
  MediumIcon,
  SubstackIcon,
  ExternalLinkIcon,
  QuestionCircleOIcon,
  RssIcon,
  VisibilityToggle,
  DragIcon,
  LineChartIcon,
  CalendarIcon,
  PlayCircleOIcon,
  PinIcon,
  StarIcon,
  StarOIcon,
  RepeatIcon,
  ArchiveIcon,
  TrashIcon,
  TagIcon,
  CircularLoader,
  FlexSpacer,
  List,
  ListItem,
  ListItemAction,
  ListItemTitle,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';

import {
  HighlightExpand,
  StatsExpand,
  ScheduleExpand,
  EventLabelExpand,
} from '../expand';
import FeedSetup from './feed-setup';
import { FEED_TYPES } from './feed-types';
import FeedSettingsExpand from './settings-expand';
import { useStore } from '../../../compositions';
import { LinkBtn, LinkWrapper, LinkCard, LinkField } from '../components';
import { LinkProps } from '../link-props';
import { useLink } from '../compositions';

export default {
  components: {
    Card,
    CardText,
    CardActions,
    VText,
    Alert,
    InfoIcon,
    Button,
    IconButton,
    HighlightExpand,
    StatsExpand,
    ScheduleExpand,
    EventLabelExpand,
    FeedSetup,
    Label,
    EmojiInput,
    PencilIcon,
    YoutubeIcon,
    WordpressIcon,
    BloggerIcon,
    SquarespaceIcon,
    ShopifyIcon,
    WixLogoIcon,
    TumblrIcon,
    MediumIcon,
    SubstackIcon,
    ExternalLinkIcon,
    RssIcon,
    TagIcon,
    QuestionCircleOIcon,
    FeedSettingsExpand,
    VisibilityToggle,
    DragIcon,
    LineChartIcon,
    CalendarIcon,
    PlayCircleOIcon,
    PinIcon,
    StarIcon,
    StarOIcon,
    RepeatIcon,
    ArchiveIcon,
    TrashIcon,
    CircularLoader,
    FlexSpacer,
    LinkBtn,
    List,
    ListItem,
    ListItemAction,
    ListItemTitle,
    LinkWrapper,
    LinkCard,
    LinkField,
  },

  props: LinkProps,

  setup(props) {
    const { link, isSavingOptions, options, hasSetup, saveOptions, ...rest } =
      useLink(props, {
        name: 'feed link',
        optionsName: 'feed',
        expandOnSetup: 'default',
      });
    const store = useStore();

    const feedTitle = computed(() => {
      return options.value.title;
    });

    const feedType = computed(() => {
      if (!options.value.source) return null;
      return FEED_TYPES.find((type) => type.source === options.value.source);
    });

    return {
      ...rest,
      options,
      feedTitle,
      hasSetup,
      feedType,
      saveOptions,
      isSavingOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.source,
.source > a {
  align-items: center;
  display: inline-flex;
  word-break: break-all;

  > * {
    margin-right: 0.5rem;
  }
}
</style>
