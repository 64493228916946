import { computed, onMounted, ref, watch } from 'vue';

import { useStore, useCurrentUser } from '..';
import { useSubscription } from '../subscription';
import { trackPurchase } from '@/utils';

export function useCurrentUserSubscription(shouldLoad = true) {
  const store = useStore();
  const isLoading = ref(false);

  const {
    numberOfProfiles,
    numberOfUsers,
    hasAnalyticsAddon,
    plan,
    freeAfter,
    customerId,
    defaultCampsite,
  } = useCurrentUser();
  const customer = computed(() => store.getters.customer);
  const churnkeyHash = computed(() => customer.value?.churnkey_hash);

  const {
    address,
    customerData,
    isFreePlan,
    planName,
    planInterval,
    subscription,
    subscriptionStatus,
    isTrialing,
    trialEndDate,
    isPastDue,
    isAnalyticsAddonIncluded,
    nextInvoice,
    nextPaymentAttemptDate,
    hasFreeAfter,
    freeAfterDate,
    ...rest
  } = useSubscription({ plan, customer, freeAfter });

  watch([hasFreeAfter, plan, isFreePlan], () => {
    if (shouldLoad) load();
  });

  async function load() {
    isLoading.value = true;

    await store.dispatch('getCustomerData');

    isLoading.value = false;
  }

  async function track() {
    if (!subscription.value) await load();
    if (subscription.value)
      trackPurchase(subscription.value.stripe_id, subscription.value.items);
  }

  onMounted(() => {
    if (shouldLoad) load();
  });

  return {
    ...rest,
    customerId,
    address,
    plan,
    customerData,
    isFreePlan,
    planName,
    planInterval,
    numberOfProfiles,
    numberOfUsers,
    hasFreeAfter,
    freeAfterDate,
    nextInvoice,
    nextPaymentAttemptDate,
    subscription,
    subscriptionStatus,
    isTrialing,
    trialEndDate,
    isPastDue,
    hasAnalyticsAddon,
    isAnalyticsAddonIncluded,
    load,
    isLoading,
    track,
    churnkeyHash,
    defaultCampsite,
  };
}
