import Shepherd from 'shepherd.js';
import { offset } from '@floating-ui/dom';

import router from '@/router';

const defaultOptions = {
  enableRouterBeforeEach: true,
};

export default class Tour {
  constructor(options = {}) {
    if (!options) options = {};
    this.options = Object.assign({}, defaultOptions, options);
    this.tour = new Shepherd.Tour({
      defaultStepOptions: {
        scrollTo: { behavior: 'smooth', block: 'center' },
        cancelIcon: {
          enabled: true,
          label: 'Close',
        },
        floatingUIOptions: {
          middleware: [offset(10)],
        },
        useModalOverlay: true,
        buttons: [
          {
            text: 'Next',
            classes: 'btn btn--small',
            action: () => {
              this.tour.next();
            },
          },
        ],
      },
    });
    Shepherd.once('cancel', () => {
      this.cancel();
    });
    if (this.options.enableRouterBeforeEach) {
      this.beforeEach = router.beforeEach((to, from, next) => {
        if (this.onBeforeEach) this.onBeforeEach(to, from);
        else this.stop();
        next();
      });
    }
    this.start();
  }

  start() {
    throw new Error('Tour start function not implemented');
  }

  cancel() {
    if (this.onCancel) this.onCancel();
    if (this.options.callbacks && this.options.callbacks.onCancelOrStop)
      this.options.callbacks.onCancelOrStop();
  }

  stop() {
    if (this.options.callbacks && this.options.callbacks.onCancelOrStop)
      this.options.callbacks.onCancelOrStop();
    if (this.tour) this.tour.complete();
    if (this.onComplete) this.onComplete();
  }
}
