import { YOUTUBE_VIDEO_OPTIONS } from '@campsite-bio/component-lib';

export const FEED_TYPES = [
  {
    source: 'blogger',
    name: 'Blogger',
    iconComponent: 'BloggerIcon',
    color: '#fc4f08',
    limit: 10,
    hasImages: true,
  },
  {
    source: 'medium',
    name: 'Medium',
    iconComponent: 'MediumIcon',
    color: '#000',
    limit: 10,
    hasImages: true,
  },
  {
    source: 'shopify',
    name: 'Shopify',
    iconComponent: 'ShopifyIcon',
    color: '#96bf48',
    limit: 10,
    hasImages: true,
  },
  {
    source: 'squarespace',
    name: 'Squarespace',
    iconComponent: 'SquarespaceIcon',
    color: '#121212',
    limit: 10,
    hasImages: true,
  },
  {
    source: 'tumblr',
    name: 'Tumblr',
    iconComponent: 'TumblrIcon',
    color: '#34526f',
    limit: 10,
    hasImages: true,
  },
  {
    source: 'substack',
    name: 'Substack',
    iconComponent: 'SubstackIcon',
    color: '#f76518',
    limit: 10,
    hasImages: true,
  },
  {
    source: 'wix',
    name: 'Wix',
    iconComponent: 'WixLogoIcon',
    color: '#0C6EFC',
    limit: 10,
    hasImages: true,
  },
  {
    source: 'wordpress',
    name: 'WordPress',
    iconComponent: 'WordpressIcon',
    color: '#21759b',
    limit: 10,
    hasImages: true,
  },
  {
    source: 'youtube',
    name: 'Youtube',
    iconComponent: 'YoutubeIcon',
    color: '#c4302b',
    limit: 15,
    hasImages: true,
    embedOptions: YOUTUBE_VIDEO_OPTIONS,
  },
  // {
  //   source: 'custom',
  //   name: 'Custom Feed',
  //   iconComponent: 'RssIcon',
  //   color: '#222',
  //   limit: 15,
  //   hasImages: true,
  // },
];
