import { computed } from 'vue';

import { useBillingPortal } from '../subscription';
import { useCurrentOrg } from './use-current-org';

export function useOrgBillingPortal(options) {
  const { id } = useCurrentOrg();

  return useBillingPortal(
    computed(() => `/api/organizations/${id.value}/create-portal-session`),
    options,
  );
}
