import { computed } from 'vue';
import tinycolor from 'tinycolor2';

import { useDataColors } from './use-data-colors';

export function useGradingColors(numberOfColors = 5) {
  const dataColors = useDataColors();

  return computed(() => {
    const startColor = dataColors.value[1];

    // Create shades of colors going from white to the startColor based on the number of profiles
    return Array.from({ length: numberOfColors }, (_, i) => {
      const color = tinycolor(startColor)
        .lighten((i * 85) / numberOfColors / 2)
        .toHexString();
      return {
        backgroundColor: color,
        color: tinycolor(color).isDark() ? '#fff' : '#000',
      };
    }).reverse();
  });
}
