<template>
  <BoxInput
    v-model="modelValue"
    name="dotsType"
    :input-id="`dotsType${index}`"
    :input-value="type"
    :label="startCase(type)"
    label-position="bottom-right"
    is-label-boxed
    is-checkered
    is-large
  >
    <img :src="require(`images/qr/${type}.png`)" alt="" />
  </BoxInput>
</template>

<script>
import startCase from 'lodash/startCase';
import { BoxInput } from '../box-input';

export default {
  components: { BoxInput },

  props: {
    index: Number,
    value: String,
    type: String,
  },

  setup() {
    return { startCase };
  },

  computed: {
    modelValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', this.type);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
</style>
