import { computed, ref } from 'vue';
import axios from 'axios';

import { useStore } from '../../../compositions';

export function useLinkFields(link, { ignoreUpdate, ignoreLinkChanges }) {
  const store = useStore();
  const cancelToken = ref(null);

  const id = computed(() => link.value._id.$oid);
  const groupId = computed(() => link.value.group_id?.$oid);

  const label = computed({
    get() {
      return link.value.label;
    },
    set(value) {
      store.commit('links/updateLinkField', {
        name: 'label',
        value,
        id: id.value,
        groupId: groupId.value,
      });
    },
  });

  const url = computed({
    get() {
      return link.value.url;
    },
    set(value) {
      store.commit('links/updateLinkField', {
        name: 'url',
        value,
        id: id.value,
        groupId: groupId.value,
      });
    },
  });

  const enabled = computed({
    get() {
      return link.value.enabled;
    },
    set(value) {
      store.commit('links/updateLinkField', {
        name: 'enabled',
        value,
        id: id.value,
        groupId: groupId.value,
      });
    },
  });

  const pinned = computed({
    get() {
      return link.value.pinned;
    },
    set(value) {
      store.commit('links/updateLinkField', {
        name: 'pinned',
        value,
        id: id.value,
        groupId: groupId.value,
      });
      // watch wouldn't fire for some reason, this is a workaround
      pinnedChange(value);
    },
  });

  const highlight = computed({
    get() {
      return link.value.highlight;
    },
    set(value) {
      store.commit('links/updateLinkField', {
        name: 'highlight',
        value,
        id: id.value,
        groupId: groupId.value,
      });
    },
  });

  const videoEmbedType = computed({
    get() {
      return link.value.video_embed_type;
    },
    set(value) {
      store.commit('links/updateLinkField', {
        name: 'video_embed_type',
        value,
        id: id.value,
        groupId: groupId.value,
      });
    },
  });

  const labelRules = computed(() => {
    if (!link.value.enabled) return {};

    return {
      required: true,
    };
  });

  const urlRules = computed(() => {
    if (!link.value.enabled) return { linkUrl: true };

    return {
      required: true,
      linkUrl: true,
    };
  });

  async function pinnedChange(value) {
    if (!ignoreUpdate.value && !ignoreLinkChanges.value) {
      const position = store.getters['links/links'].findIndex(
        ({ _id }) => _id.$oid === id.value,
      );
      store.commit('links/setIsPinningLink', true);
      if (cancelToken.value) cancelToken.value.cancel();
      cancelToken.value = axios.CancelToken.source();

      try {
        const { new_index, is_pinned_disabled } = await store.dispatch(
          'links/updateLinkPosition',
          {
            link: link.value,
            pinned: value,
            cancelToken: cancelToken.value.token,
          },
        );

        store.commit('links/moveLink', {
          newIndex: new_index,
          oldIndex: position,
        });
        store.commit('links/updateField', {
          path: 'isPinnedDisabled',
          value: is_pinned_disabled,
        });
      } catch (e) {
        if (axios.isCancel(e) || e.name === 'AbortError') return;
        console.error(e);
      }

      store.commit('links/setIsPinningLink', false);
    }
  }

  return {
    label,
    url,
    enabled,
    pinned,
    highlight,
    videoEmbedType,
    labelRules,
    urlRules,
  };
}
