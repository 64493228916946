<template>
  <item-wrapper
    :id="`banner${banner._id.$oid}`"
    :loading="isSaving || isDeleting"
  >
    <div class="row">
      <div class="col col--md-9">
        <FormGroup>
          <Label :for="`${_uid}banner-label`">Image Alt Text</Label>
          <Input
            :id="`${_uid}banner-label`"
            v-model="image_alt"
            autocomplete="off"
            maxlength="80"
            placeholder="A description of the selected image"
          />
          <FormGroupHelp
            >Give a description or enter the text if there is any in the
            image</FormGroupHelp
          >
        </FormGroup>
        <FormGroup>
          <InputUrl v-model="url" show-label label="Url" />
        </FormGroup>
      </div>
      <div class="col col--md-3">
        <FormGroup>
          <v-media-selector
            v-model="imageSource"
            :has-error="enabled && !media"
            :media="['custom', 'canva']"
            :media-options="{
              custom: { minWidth: 650, minHeight: minHeight, alwaysShow: true },
              canva: { minWidth: 650, minHeight: minHeight, alwaysShow: true },
            }"
            @select="selectMedia"
            @remove="media = null"
          />
        </FormGroup>
      </div>
    </div>

    <template slot="bottom">
      <v-tooltip top>
        <template #activator="{ on }">
          <div v-on="on">
            <VisibilityToggle v-model="enabled" label="Enable banner" />
          </div>
        </template>
        <span>Show or hide banner</span>
      </v-tooltip>
      <LinkBtn class="not-btn" component="div" disabled>
        <LineChartIcon slot="icon" />
        {{ banner.number_of_clicks | numberFormatter(1) }} clicks
      </LinkBtn>

      <FlexSpacer />

      <v-dialog
        v-model="dialog"
        persistent
        max-width="300"
        @keydown.esc="dialog = false"
      >
        <template #activator="{ on }">
          <LinkBtn type="button" aria-label="Delete banner" v-on="on">
            <TrashIcon slot="icon" />
          </LinkBtn>
        </template>
        <Card>
          <CardText
            ><VText
              >This banner and its data will be permanently deleted.</VText
            ></CardText
          >
          <CardActions>
            <Button variant="flat" @click="dialog = false">Cancel</Button>
            <Button
              color="error"
              variant="flat"
              :loading="isDeleting"
              @click="remove"
              >Confirm</Button
            >
          </CardActions>
        </Card>
      </v-dialog>
    </template>
  </item-wrapper>
</template>

<script>
import {
  Card,
  CardText,
  CardActions,
  Button,
  VText,
  VisibilityToggle,
  Input,
  FormGroup,
  Label,
  FormGroupHelp,
  TrashIcon,
  LineChartIcon,
  FlexSpacer,
} from '@campsite-bio/component-lib';
import debounce from 'lodash/debounce';

import { InputUrl } from '../../form';
import toast from '@/utils/toast';
import { LinkBtn, ItemWrapper } from '../components';
import { computed } from 'vue';
import bus from '@/bus';

export default {
  components: {
    InputUrl,
    Card,
    CardText,
    CardActions,
    Button,
    VText,
    VisibilityToggle,
    Input,
    FormGroup,
    Label,
    FormGroupHelp,
    LinkBtn,
    TrashIcon,
    LineChartIcon,
    FlexSpacer,
    ItemWrapper,
  },

  props: {
    link: {
      type: Object,
      required: true,
    },
    banner: {
      type: Object,
      required: true,
    },
    carouselOptions: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const minHeight = computed(() => {
      switch (props.carouselOptions.height_ratio) {
        case '50':
          return 325;
        case '100':
          return 650;
        default:
          return 450;
      }
    });

    return {
      minHeight,
    };
  },

  data() {
    return {
      isSaving: false,
      isDeleting: false,
      dialog: false,
      ignoreUpdate: false,
    };
  },

  computed: {
    id() {
      return this.banner._id.$oid;
    },

    media: {
      get() {
        return this.banner.media;
      },
      set(value) {
        this.$store.commit('links/updateLinkBannerMedia', {
          value: value,
          bannerId: this.id,
          linkId: this.link._id.$oid,
        });
      },
    },

    mediaUrl: {
      get() {
        return this.banner.media_url;
      },
      set(value) {
        this.$store.commit('links/updateLinkBannerMediaUrl', {
          value: value,
          bannerId: this.id,
          linkId: this.link._id.$oid,
        });
      },
    },

    url: {
      get() {
        return this.banner.url;
      },
      set(value) {
        this.$store.commit('links/updateLinkBannerUrl', {
          value: value,
          bannerId: this.id,
          linkId: this.link._id.$oid,
        });
      },
    },

    image_alt: {
      get() {
        return this.banner.image_alt;
      },
      set(value) {
        this.$store.commit('links/updateLinkBannerImageAlt', {
          value: value,
          bannerId: this.id,
          linkId: this.link._id.$oid,
        });
      },
    },

    enabled: {
      get() {
        return this.banner.enabled;
      },
      set(value) {
        this.$store.commit('links/updateLinkBannerEnabled', {
          value: value,
          bannerId: this.id,
          linkId: this.link._id.$oid,
        });
      },
    },

    imageSource() {
      return this.banner.media_item ? this.banner.media_item.url : '';
    },
  },

  watch: {
    banner: {
      deep: true,
      handler() {
        if (!this.ignoreUpdate) this.savedDebounce();
      },
    },
  },

  created() {
    this.savedDebounce = debounce(this.save.bind(this), 1000);
  },

  methods: {
    selectMedia(media) {
      this.media = media.mediaId;
      this.mediaUrl = media.media.url;
    },

    async save() {
      this.isSaving = true;

      try {
        const json = await this.$store.dispatch('links/updateLinkBanner', {
          linkId: this.link._id.$oid,
          id: this.id,
          banner: this.banner,
        });

        this.ignoreUpdate = true;
        this.$store.commit('links/updateLinkBanner', {
          banner: json.banner,
          linkId: this.link._id.$oid,
        });
        await this.$nextTick();
        this.ignoreUpdate = false;

        bus.$emit('link-updated', { link: this.link });
      } catch (e) {
        console.error(e);
        const { error_message, banner } = (e.response && e.response.data) || {};
        if (banner) {
          this.ignoreUpdate = true;
          this.$store.commit('links/updateLinkBanner', {
            linkId: this.link._id.$oid,
            banner: banner,
          });
          await this.$nextTick();
          this.ignoreUpdate = false;
        }
        toast.error(
          error_message
            ? error_message
            : 'There was an error trying to update your banner',
          {
            timeout:
              e.response.status === 403 || e.response.status === 400
                ? 8000
                : 3000,
          },
        );
      }

      this.isSaving = false;
    },

    async remove() {
      this.isDeleting = true;
      try {
        await this.$store.dispatch('links/deleteLinkBanner', {
          linkId: this.link._id.$oid,
          bannerId: this.id,
        });
      } catch (e) {
        console.error(e);
        toast.error('There was an error trying to remove the banner');
      }
      this.isDeleting = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
