<template>
  <div ref="root" class="items-root v-card__text">
    <Toolbar flat>
      <VText style="font-size: 0.85rem"> Last synced {{ lastSynced }} </VText>
      <FlexSpacer />
      <IconButton
        aria-label="Sync new items"
        :loading="isSyncing"
        :disabled="disableSync || hasAuthWarning"
        @click="sync"
      >
        <RefreshIcon />
      </IconButton>
    </Toolbar>
    <Divider color="gray100" thickness="1" />
    <CardText>
      <Alert
        v-if="!isFreePlan && !isLinkEnabled"
        style="margin-bottom: 1rem"
        variant="outline"
      >
        <InfoIcon slot="icon" />
        Your image grid isn't actively synced when it's disabled
      </Alert>
      <div class="image-grid">
        <Item
          v-for="item in items"
          :key="item._id.$oid"
          :item="item"
          :link-id="linkId"
          @click="handleClick(item)"
        />

        <template v-if="loadingItemsCount">
          <LoadingItem
            v-for="i in loadingItemsCount"
            :key="i"
            @is-in-view="handleScroll"
          />
        </template>
      </div>
    </CardText>
  </div>
</template>

<script>
import {
  CardText,
  Toolbar,
  IconButton,
  RefreshIcon,
  FlexSpacer,
  Divider,
  VText,
  Alert,
  InfoIcon,
} from '@campsite-bio/component-lib';
import { computed, inject, onBeforeUnmount, onMounted, ref } from 'vue';
import { useTimeAgo } from '@vueuse/core';

import Item from '../../item';
import LoadingItem from '../../loading-item';
import { useStore } from '../../../../../../compositions';
import toast from '../../../../../../utils/toast';

const ScrollPosition = {};
const ITEMS_PER_PAGE = 9;

export default {
  components: {
    CardText,
    Item,
    Toolbar,
    IconButton,
    RefreshIcon,
    FlexSpacer,
    Divider,
    VText,
    Alert,
    InfoIcon,
    LoadingItem,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },

    link: {
      type: Object,
      required: true,
    },

    linkId: {
      type: String,
      required: true,
    },

    onSelect: {
      type: Function,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();
    const page = ref(Math.ceil(props.items?.length / ITEMS_PER_PAGE));
    const isLoading = ref(false);
    const isSyncing = ref(false);
    const disableSync = ref(false);
    const disableSyncTimeout = ref(null);
    const root = ref(null);

    const { hasAuthWarning = false } = inject('imageGrid', {});

    const { handleStepChange } = inject('multi-step-dialog');

    const hasMore = computed(() => {
      return props.items?.length < props.link.item_count;
    });

    const lastCheckedAt = computed(() => {
      return props.link.image_grid_options.last_checked_at;
    });

    const lastSynced = useTimeAgo(lastCheckedAt);

    const isFreePlan = computed(() => {
      return store.getters['profiles/isProfileOnFreePlan'];
    });

    const isLinkEnabled = computed(() => {
      return props.link.enabled;
    });

    const loadingItemsCount = computed(() => {
      return props.link.item_count - props.items.length;
    });

    function handleScroll() {
      if (!isLoading.value && hasMore.value) {
        page.value++;
        load();
      }
    }

    async function load() {
      if (props.items.length >= props.link.item_count || isLoading.value)
        return;

      isLoading.value = true;

      try {
        await store.dispatch('linkItems/get', {
          linkId: props.link._id.$oid,
          page: page.value,
          pageSize: ITEMS_PER_PAGE,
          force: true,
        });
      } catch (e) {
        console.error(e);
        const { error_message } = e.response?.data || {};
        toast.error(
          error_message ||
            'An error occurred while trying to load more grid items',
        );
        page.value--;
      }

      isLoading.value = false;
    }

    function handleClick(item) {
      ScrollPosition[props.linkId] = root.value.scrollTop;
      props.onSelect(item);
      handleStepChange('editor');
    }

    onMounted(() => {
      if (ScrollPosition[props.linkId]) {
        root.value.scrollTop = ScrollPosition[props.linkId];
        delete ScrollPosition[props.linkId];
      }
    });

    async function sync() {
      isSyncing.value = true;

      try {
        const data = await store.dispatch('linkItems/sync', {
          linkId: props.linkId,
        });

        if (data.new_items?.length) {
          toast.success(
            `${data.new_items.length} new item${
              data.new_items.length > 1 ? 's' : ''
            } synced`,
          );
          // Reset page
          page.value = Math.max(Math.floor(props.items.length / 9), 1);
        } else {
          toast.success('No new items found');
        }

        // Disable the sync button for 15 seconds
        disableSync.value = true;
        disableSyncTimeout.value = setTimeout(() => {
          disableSync.value = false;
        }, 15000);
      } catch (e) {
        console.error(e);
        const { error_message } = e.response?.data || {};
        toast.error(
          error_message ||
            'An error occurred while trying to sync your grid items',
        );
      }

      isSyncing.value = false;
    }

    onBeforeUnmount(() => {
      clearTimeout(disableSyncTimeout.value);
    });

    return {
      root,
      isLoading,
      handleClick,
      lastSynced,
      sync,
      isSyncing,
      disableSync,
      hasAuthWarning,
      isFreePlan,
      isLinkEnabled,
      loadingItemsCount,
      handleScroll,
    };
  },
};
</script>

<style lang="scss" scoped>
.items-root {
  overflow-y: auto;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.25rem;
}
</style>
