import { localAxios } from '../apis';

function getOriginalState() {
  return {
    code: null,
    isQrDialogOpen: false,
  };
}

export default {
  namespaced: true,

  state: getOriginalState(),

  getters: {
    code: (state) => state.code,
    isQrDialogOpen: (state) => state.isQrDialogOpen,
  },

  mutations: {
    setCode(state, value) {
      state.code = value;
    },

    setIsQrDialogOpen(state, value) {
      state.isQrDialogOpen = value;
    },

    resetState(state) {
      Object.assign(state, getOriginalState());
    },
  },

  actions: {
    async findOrCreate({ rootGetters, commit }) {
      const campsiteId = rootGetters['profiles/currentProfileId'];

      const { data } = await localAxios.get(
        `/api/profiles/${campsiteId}/qr-code`,
      );

      commit('setCode', data.qr_code);

      return data.qr_code;
    },

    async update({ rootGetters, getters, commit }, qrData) {
      const campsiteId = rootGetters['profiles/currentProfileId'];
      const qrCodeId = getters.code._id.$oid;

      const { data } = await localAxios.put(
        `/api/profiles/${campsiteId}/qr-code/${qrCodeId}`,
        qrData,
      );

      commit('setCode', data.qr_code);

      return data.qr_code;
    },

    async trackDownload({ rootGetters, getters, commit }) {
      const campsiteId = rootGetters['profiles/currentProfileId'];
      const qrCodeId = getters.code._id.$oid;

      const { data } = await localAxios.put(
        `/api/profiles/${campsiteId}/qr-code/${qrCodeId}/track`,
      );

      commit('setCode', data.qr_code);

      return data.qr_code;
    },
  },
};
