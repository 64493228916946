<template>
  <div class="file-preview" :class="classes">
    <div class="file-preview__content">
      <img v-if="imageUrl" class="file-preview__img" :src="imageUrl" />
      <div class="file-preview__details">
        <div class="file-preview__filename" :title="filename">
          {{ filename | truncate(30) }}
        </div>
        <div class="file-preview__status">{{ status }}</div>
      </div>
      <button
        class="file-preview__close"
        type="button"
        aria-label="Remove"
        @click="$emit('remove', $event)"
      >
        <CloseIcon />
      </button>
    </div>
    <div class="file-preview__progress">
      <span class="file-preview__upload"></span>
    </div>
  </div>
</template>

<script>
import { CloseIcon } from '@campsite-bio/component-lib';

export default {
  components: {
    CloseIcon,
  },

  props: {
    imageUrl: {
      type: String,
      default: null,
    },
    filename: {
      type: String,
      default: null,
    },
    status: {
      type: String,
      default: null,
    },
    isRejected: Boolean,
    isLoading: Boolean,
    isCompleted: Boolean,
  },

  computed: {
    classes() {
      return {
        'file-preview--rejected': this.isRejected,
        'file-preview--loading':
          this.isLoading && !this.isRejected && !this.isCompleted,
        'file-preview--completed': this.isCompleted,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
