<template>
  <div>
    <div
      v-for="(value, campaign) in campaigns"
      :key="campaign"
      class="tracking-campaign"
    >
      <VText variant="h5" component="h3">{{ campaign }}</VText>
      <TrackingCampaign :data="value" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { VText } from '@campsite-bio/component-lib';

import TrackingCampaign from './tracking-campaign.vue';

export default {
  components: {
    TrackingCampaign,
    VText,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const campaigns = computed(() => {
      if (!props.data) return null;

      return props.data.all_views.reduce((accum, item) => {
        if (accum[item.dimensions.campaign]) {
          accum[item.dimensions.campaign].push(item);
        } else {
          accum[item.dimensions.campaign] = [item];
        }

        return accum;
      }, {});
    });

    return {
      campaigns,
    };
  },
};
</script>

<style lang="scss" scoped>
.tracking-campaign {
  margin-bottom: 1rem;
  margin-top: 1.5rem;

  h3 {
    margin-bottom: 0.75rem;
  }
}
</style>
