<template>
  <Dialog
    v-model="isOpen"
    persistent
    max-width="500px"
    title="Confirm your details"
    hide-close
  >
    <CardText>
      <VText gutter-bottom>
        Confirm the details of your account are up-to-date.
      </VText>
      <VText gutter-bottom>
        <LabelWithText
          label="First name / Company name"
          :text="user.first_name"
        />
        <LabelWithText label="Email" :text="user.email" />
      </VText>
    </CardText>
    <CardActions>
      <Button
        variant="flat"
        to="/account"
        :disabled="isUpdating"
        @click.stop="update(true)"
        >Update <ChevronIcon direction="right"
      /></Button>
      <Button
        color="success"
        type="submit"
        variant="flat"
        :loading="isUpdating"
        @click="update()"
        >Looks good</Button
      >
    </CardActions>
  </Dialog>
</template>

<script>
import {
  CardText,
  CardActions,
  Button,
  VText,
  ChevronIcon,
} from '@campsite-bio/component-lib';
import { ref, watch } from 'vue';
import { DateTime } from 'luxon';

import {
  useCurrentUser,
  useStore,
  useRouter,
  useDismissable,
} from '@/compositions';
import Dialog from './dialog';
import { LabelWithText } from '@/components/account';
import { getMeta } from '@/utils';
import toast from '@/utils/toast';

export default {
  components: {
    CardText,
    CardActions,
    Button,
    VText,
    Dialog,
    LabelWithText,
    ChevronIcon,
  },

  setup() {
    const isUpdating = ref(false);
    const isOpen = ref(false);
    const { user, createdAt } = useCurrentUser();
    const store = useStore();
    const router = useRouter();
    const { dismissed, dismiss } = useDismissable(
      'update_user_details_dialog',
      {
        storage: 'session',
      },
    );

    watch(
      user,
      () => {
        if (user.value && !dismissed.value) {
          const lastUpdatedDetails = DateTime.fromISO(
            getMeta('last_updated_details', null, user.value.meta),
          );
          const now = DateTime.local();
          const diffLastUpdated = now
            .diff(lastUpdatedDetails, 'months')
            .toObject();
          const diffCreated = now.diff(createdAt.value, 'months').toObject();

          // If the user has never updated their details, or it's been more than 6 months since they last updated their details, show the dialog
          if (
            (lastUpdatedDetails.isValid && diffLastUpdated.months >= 6) ||
            (!lastUpdatedDetails.isValid && diffCreated.months >= 6)
          ) {
            isOpen.value = true;
          }
        }
      },
      { immediate: true },
    );

    async function update(goToAccount = false) {
      isUpdating.value = true;
      try {
        await store.dispatch('updateUserMeta', {
          items: [
            { name: 'last_updated_details', value: DateTime.utc().toISO() },
          ],
        });

        isOpen.value = false;

        if (goToAccount) {
          router.push('/account');
        }
      } catch (e) {
        console.error(e);
        toast.error('Something went wrong. Please try again.');
      } finally {
        isUpdating.value = false;
        // Make it doesn't keep showing up in a session
        dismiss();
      }
    }

    return {
      isOpen,
      isUpdating,
      update,
      user,
    };
  },
};
</script>

<style lang="scss" scoped></style>
