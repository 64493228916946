import { getField, updateField } from 'vuex-map-fields';
import { nextTick } from 'vue';

import { localAxios } from '@/apis';

function getOriginalState() {
  return {
    linkGroups: [],
    group: null,
    organizationCampsiteCount: 0,
  };
}

export default {
  namespaced: true,

  state: {
    ...getOriginalState(),
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,

    resetState(state) {
      Object.assign(state, getOriginalState());
    },
  },

  actions: {
    async getAll({ rootGetters, commit }) {
      commit('setIsLoading', true, { root: true });

      try {
        const { data } = await localAxios.get(
          `/api/universal-links/all?organization_id=${rootGetters[
            'organizations/getField'
          ]('currentId')}`,
        );

        commit('updateField', {
          path: 'linkGroups',
          value: data.groups || [],
        });
        commit('updateField', {
          path: 'organizationCampsiteCount',
          value: data.org_campsite_count,
        });

        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
      }
    },

    async get({ rootGetters, commit }, { id, setOnlyGroup = false }) {
      commit('setIsLoading', true, { root: true });

      try {
        const { data } = await localAxios.get(
          `/api/universal-links/${id}?organization_id=${rootGetters[
            'organizations/getField'
          ]('currentId')}`,
        );

        commit('updateField', {
          path: 'group',
          value: data.group,
        });

        if (!setOnlyGroup) {
          commit('updateField', {
            path: 'organizationCampsiteCount',
            value: data.org_campsite_count,
          });
          // This prevents the links from trying to save their state once inserted into the DOM
          commit('links/setIgnoreLinkChanges', true, { root: true });
          commit(
            'links/setUniversalLinkGroupLinks',
            {
              id,
              links: data.links,
            },
            { root: true },
          );
          await nextTick();
          commit('links/setIgnoreLinkChanges', false, { root: true });
        }

        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
      }
    },

    async create({ rootGetters, commit }, params) {
      commit('setIsLoading', true, { root: true });

      try {
        const { data } = await localAxios.post(
          `/api/universal-links?organization_id=${rootGetters[
            'organizations/getField'
          ]('currentId')}`,
          params,
        );

        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
      }
    },

    async update({ rootGetters, commit }, { id, params }) {
      commit('setIsLoading', true, { root: true });

      try {
        const { data } = await localAxios.put(
          `/api/universal-links/${id}?organization_id=${rootGetters[
            'organizations/getField'
          ]('currentId')}`,
          params,
        );

        commit('updateField', {
          path: 'group',
          value: data,
        });

        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
      }
    },

    async publish({ rootGetters, commit }, id) {
      commit('setIsLoading', true, { root: true });

      try {
        const { data } = await localAxios.post(
          `/api/universal-links/${id}/publish?organization_id=${rootGetters[
            'organizations/getField'
          ]('currentId')}`,
        );

        commit('updateField', {
          path: 'group',
          value: data,
        });

        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
      }
    },

    async remove({ rootGetters, commit }, id) {
      commit('setIsLoading', true, { root: true });

      try {
        await localAxios.delete(
          `/api/universal-links/${id}?organization_id=${rootGetters[
            'organizations/getField'
          ]('currentId')}`,
        );

        commit('updateField', {
          path: 'group',
          value: null,
        });
        commit('updateField', {
          path: 'links',
          value: null,
        });
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
      }
    },
  },
};
