<template>
  <Card>
    <DataTable
      :headers="tableHeaders"
      :items="formattedData"
      :items-per-page="999"
      hide-default-footer
    />
  </Card>
</template>

<script>
import { computed, ref, toRefs } from 'vue';
import { Card } from '@campsite-bio/component-lib';
import { DateTime } from 'luxon';

import { DataTable } from '../../vuetify';

export default {
  components: {
    DataTable,
    Card,
  },

  props: {
    data: {
      type: Array,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const { data } = toRefs(props);

    const tableHeaders = ref([
      { text: 'Medium / Source', value: 'name', align: 'start' },
      { text: 'Views', value: 'total_views' },
      { text: 'Unique Views', value: 'unique_views' },
      { text: 'Sessions', value: 'total_sessions' },
      { text: 'Clicks', value: 'clicks' },
      { text: 'Events', value: 'events' },
      { text: 'Exits', value: 'exits' },
    ]);

    const formattedData = computed(() => {
      if (!data.value) return null;

      return data.value
        .filter(({ dimensions }) => dimensions.range === 'current')
        .reverse()
        .map((item) => {
          return {
            name: `${item.dimensions.medium} / ${item.dimensions.source}`,
            ...item,
          };
        });
    });

    function formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
    }

    return {
      tableHeaders,
      formattedData,
      formatDate,
    };
  },
};
</script>

<style lang="scss" scoped></style>
