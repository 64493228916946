import { computed } from 'vue';
import { useMeta } from '../use-meta';
import { useStore } from '../use-store';

export function useUserPreferences(defaultValue = {}) {
  const store = useStore();
  const user = computed(() => store.getters.currentUser);
  const userPreferences = useMeta('preferences', {
    defaultValue,
    itemRef: user,
  });

  async function save(params) {
    const preferences = {
      ...userPreferences.value,
      ...params,
    };

    // Check if preferences is different from current preferences
    if (JSON.stringify(preferences) === JSON.stringify(userPreferences.value)) {
      return;
    }

    try {
      await store.dispatch('updateUserMeta', {
        items: [{ name: 'preferences', value: preferences }],
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  return {
    userPreferences,
    save,
  };
}
