import { computed, ref, watch, onMounted, onUnmounted } from 'vue';
import {
  convertImageUrlToEncodedUrl,
  MEDIA_SIZES,
} from '@campsite-bio/component-lib';
import { DateTime } from 'luxon';

import { localAxios } from '@/apis';
import store from '@/store';
import { useCurrentUserProfileEntitlements } from '../use-current-user-profile-entitlements';
import { useRouter } from '../use-router';
import { useRoute } from '../use-route';
import toast from '@/utils/toast';
import bus from '@/bus';

export function useAuthorizationForReports() {
  const route = useRoute();
  const router = useRouter();
  const isLoadingAuth = ref(false);
  const isLoadingProfile = ref(false);
  const showNewAuthMessageTimeout = ref(null);
  const showNewAuthMessage = ref(false);
  const selectedAuthAccount = computed({
    get: () => store.getters['analytics/getField']('selectedChannelAuths'),
    set: (value) =>
      store.commit('analytics/updateField', {
        path: 'selectedChannelAuths',
        value,
      }),
  });

  const isLoading = computed(
    () => isLoadingAuth.value || isLoadingProfile.value,
  );

  const appAuthAccounts = computed(
    () => store.getters['analytics/channelAuthAccounts'],
  );

  const currentAuth = computed(() => {
    return appAuthAccounts.value?.find(
      (a) => a._id.$oid === selectedAuthAccount.value,
    );
  });

  const profile = ref(null);
  const { hasAnalyticsAddon: hasAnalyticsAddonProfile, canManageAnalytics } =
    useCurrentUserProfileEntitlements(profile, false);

  const token = computed(() => {
    return profile.value?.token;
  });

  const hasAnalyticsAddon = computed(() => {
    return hasAnalyticsAddonProfile.value;
  });

  const profileImage = computed(() => {
    return convertImageUrlToEncodedUrl(
      profile.value?.profile_image,
      MEDIA_SIZES.profile_image,
    );
  });

  const showNoAuthsMessage = computed(() => {
    return !selectedAuthAccount.value && !isLoadingAuth.value;
  });

  function checkShowNewAuthMessage() {
    if (showNewAuthMessageTimeout.value)
      clearTimeout(showNewAuthMessageTimeout.value);
    const createdAt = DateTime.fromISO(currentAuth.value?.created_at);
    showNewAuthMessage.value =
      DateTime.local().minus({ minutes: 1 }) < createdAt;

    if (showNewAuthMessage.value) {
      showNewAuthMessageTimeout.value = setTimeout(() => {
        checkShowNewAuthMessage();
      }, 5000);
    }
  }

  // Set the initially selected auth from the currently loaded profile
  async function setInitialAuth() {
    if (selectedAuthAccount.value) return;

    const currentProfileId = store.getters['profiles/currentId'];
    let selected = appAuthAccounts.value.find(
      (a) => a.app_authorization.campsite._id.$oid === currentProfileId,
    );
    if (!selected) selected = appAuthAccounts.value[0];
    selectedAuthAccount.value = selected?._id.$oid;
  }

  watch(
    route,
    () => {
      if (route.value.params.id) {
        // Reset the loaded profile if the route id changes
        if (route.value.params.id !== selectedAuthAccount.value) {
          profile.value = null;
          selectedAuthAccount.value = route.value.params.id;
        }
      }
    },
    { immediate: true },
  );

  watch(
    selectedAuthAccount,
    () => {
      if (!selectedAuthAccount.value) return;

      // Update the route if the selected auth changes
      const currentId = route.value.params.id;
      if (currentId !== selectedAuthAccount.value)
        router.push({ params: { id: selectedAuthAccount.value } });
    },
    { immediate: true },
  );

  watch(
    currentAuth,
    () => {
      // Load the profile with the new id
      if (currentAuth.value) {
        checkShowNewAuthMessage();
        loadProfile(currentAuth.value.app_authorization.campsite._id.$oid);
      }
    },
    { immediate: true },
  );

  async function loadProfile(id) {
    isLoadingProfile.value = true;

    try {
      const { data } = await localAxios.get(`/api/profiles/${id}`);
      profile.value = data.profile;
    } catch (e) {
      console.error(e);
      // TODO: catch 403/404 error
    }

    isLoadingProfile.value = false;
  }

  async function load() {
    isLoadingAuth.value = true;

    try {
      await store.dispatch('analytics/loadChannelAuths');
      setInitialAuth();
    } catch (e) {
      console.error(e);
      toast.error(
        'There was an error trying to load your channels. Please try again.',
      );
    }

    isLoadingAuth.value = false;
  }

  onMounted(() => {
    load();
    bus.$on('app-authorization:save', load);
  });

  onUnmounted(() => {
    bus.$off('app-authorization:save', load);
  });

  return {
    isLoading,
    selectedAuth: selectedAuthAccount,
    profile,
    profileImage,
    hasAnalyticsAddon,
    canManageAnalytics,
    token,
    appAuthAccounts,
    currentAuth,
    showNewAuthMessage,
    showNoAuthsMessage,
  };
}
