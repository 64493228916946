import { computed } from 'vue';
import { useProfile } from './use-profile';

/**
 * Get the URL for the current account upgrade page
 * @returns {ComputedRef<string>}
 */
export function useProfileUpgradeUrl() {
  const { isOrgProfile, organizationId } = useProfile();

  return computed(() => {
    if (isOrgProfile.value) {
      return `/organization/${organizationId.value}/billing/change-plan`;
    } else {
      return '/account/change-plan';
    }
  });
}
