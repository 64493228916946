<template>
  <Paper
    v-if="!dismissed"
    class="notice"
    border-color="gray200"
    border-width="1"
    elevate-on-hover
    hide-static-shadow
  >
    <div class="notice__content">
      <slot />
    </div>

    <div class="notice__dismiss">
      <IconButton
        color="white"
        aria-label="Dismiss"
        size="1.25rem"
        filled
        @click="dismiss"
      >
        <CloseIcon />
      </IconButton>
    </div>
  </Paper>
</template>

<script>
import { Paper, IconButton, CloseIcon } from '@campsite-bio/component-lib';

import { useDismissable } from '../../compositions';

export default {
  components: {
    Paper,
    IconButton,
    CloseIcon,
  },

  props: {
    storageKey: {
      type: String,
      required: true,
    },

    storage: {
      type: String,
      default: 'local',
      validator: (value) => ['local', 'session'].includes(value),
    },
  },

  setup(props) {
    const { dismissed, dismiss } = useDismissable(props.storageKey, {
      storage: props.storage,
    });

    return { dismissed, dismiss };
  },
};
</script>

<style lang="scss" scoped>
.notice {
  border: 0.125rem solid #fff;
  background: #fff;
  position: relative;

  &__content {
    padding: 2rem 1.5rem 1.25rem 1.5rem;
  }

  &__dismiss {
    position: absolute;
    top: 0.25rem;
    right: 0.2rem;
  }
}
</style>
