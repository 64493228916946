<template>
  <BaseCard title="Add an existing profile">
    <VText gutter-bottom>
      Request to transfer a profile from another account by username.
    </VText>
    <Button size="small" :disabled="isAtLimit" @click="$emit('add')">
      Add Existing Profile
    </Button>
  </BaseCard>
</template>

<script>
import { VText, Button } from '@campsite-bio/component-lib';

import BaseCard from './base-card.vue';

export default {
  components: {
    VText,
    Button,
    BaseCard,
  },

  props: {
    isAtLimit: Boolean,
  },
};
</script>

<style lang="scss" scoped></style>
