<template>
  <v-dialog v-model="isOpen" v-bind="$props">
    <template #activator="data">
      <slot name="activator" v-bind="data" />
    </template>

    <Card class="dialog">
      <CardTitle class="dialog__title">
        <VText component="h2" variant="h4">{{ title }}</VText>
        <FlexSpacer />
        <IconButton
          v-if="!hideClose"
          size="1.25rem"
          aria-label="Close"
          @click="handleClose"
        >
          <CloseIcon />
        </IconButton>
      </CardTitle>
      <Divider color="gray200" />

      <slot />
    </Card>
  </v-dialog>
</template>

<script>
import {
  Card,
  CardTitle,
  IconButton,
  CloseIcon,
  FlexSpacer,
  VText,
  Divider,
} from '@campsite-bio/component-lib';
import { VDialog } from 'vuetify/lib';
import { toRefs } from 'vue';

import { useModel } from '../../compositions';

export default {
  components: {
    Card,
    CardTitle,
    IconButton,
    CloseIcon,
    FlexSpacer,
    VText,
    Divider,
  },

  props: {
    ...VDialog.options.props,

    value: Boolean,

    title: {
      type: String,
      required: true,
    },

    hideClose: Boolean,
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const isOpen = useModel(value, (val) => emit('input', val));

    function handleClose() {
      emit('close');
      isOpen.value = false;
    }

    return {
      isOpen,
      handleClose,
    };
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .v-card__title.v-card__title {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .v-card__title {
    .v-icon-btn {
      &:last-child {
        margin-right: -0.3em;
      }
    }
  }
}

.dialog__title.dialog__title {
  flex-wrap: nowrap;
}
</style>
