<template>
  <div ref="root" class="wrapped-intro">
    <div class="wrapped-intro__close">
      <IconButton
        color="white"
        size="1.5rem"
        aria-label="Close"
        @click="$emit('close')"
      >
        <CloseIcon />
      </IconButton>
    </div>
    <div class="wrapped-intro__text">
      <span>We're working on loading your wrapped data</span>
      <span>We're analyzing your data and posts from the past two years</span>
    </div>
  </div>
</template>

<script>
import { inject, onBeforeUnmount, onMounted, ref } from 'vue';
import { IconButton, CloseIcon } from '@campsite-bio/component-lib';
import anime from 'animejs';
import { captureException } from '@sentry/browser';

import { localAxios } from '../../../../apis';
import { useStore } from '../../../../compositions';
import toast from '../../../../utils/toast';

export default {
  components: {
    IconButton,
    CloseIcon,
  },

  props: {
    wrappedData: {
      type: Object,
      default: null,
    },

    /** The id of the Instagram or Youtube account */
    accountId: {
      type: String,
      default: null,
    },

    profileId: {
      type: String,
      required: true,
    },

    authId: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const root = ref(null);
    const isCreating = ref(false);
    const localWrappedData = ref(null);
    const timeout = ref(null);

    const { loadProfiles, loadAuths } = inject('wrapped-page');

    const timeline = anime.timeline({
      easing: 'linear',
      loop: true,
    });

    async function createWrappedData() {
      isCreating.value = true;

      try {
        const { data } = await localAxios.post(
          `/api/profiles/wrapped/${props.profileId}/${
            props.authId ? props.authId : ''
          }`,
          {
            account_id: props.accountId,
          },
        );

        localWrappedData.value = data;
        isCreating.value = false;
        timeout.value = setTimeout(track, 5000);

        timeout.value = setTimeout(() => {
          store.dispatch('analytics/loadChannelAuths');
        }, 1000);
      } catch (e) {
        console.error(e);
        captureException(e);
        toast.error('Something went wrong. Please try again later.');
      }
    }

    async function track() {
      try {
        const id = props.wrappedData
          ? props.wrappedData._id.$oid
          : localWrappedData.value._id.$oid;
        const { data } = await localAxios.get(
          `/api/profiles/wrapped/${props.profileId}/${id}`,
        );

        if (data.topline && data.by_month) {
          loadProfiles();
          loadAuths();
          emit('synced', data);
          clearTimeout(timeout.value);
          return;
        }
      } catch (e) {
        console.error(e);
        captureException(e);
      }

      timeout.value = setTimeout(track, 5000);
    }

    onMounted(() => {
      if (props.wrappedData) track();
      else createWrappedData();

      const text = root.value.querySelectorAll('.wrapped-intro__text span');
      text.forEach((el) => {
        timeline.add({
          targets: el,
          keyframes: [
            {
              opacity: 1,
              duration: 1000,
            },
            {
              opacity: 0,
              duration: 1000,
              delay: 2500,
            },
          ],
        });
      });
    });

    onBeforeUnmount(() => {
      timeline.pause();
      clearTimeout(timeout.value);
    });

    return { root, isCreating };
  },
};
</script>

<style lang="scss" scoped>
.wrapped-intro {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;

  &__close {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  }

  &__text {
    position: relative;
    width: 100%;

    span {
      font-size: 1.5rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      max-width: 90%;
      width: 100%;
      text-align: center;
    }
  }
}
</style>
