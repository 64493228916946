var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{attrs:{"border-color":"primary"}},[_c('CardTitle',[_c('VText',{attrs:{"component":"h2","variant":"h5"}},[_vm._v("Setup your form link")]),_vm._v(" "),_c('FlexSpacer'),_vm._v(" "),_c('IconButton',{attrs:{"size":"1.25rem","loading":_vm.isDeleting,"title":"Delete","aria-label":"Delete"},on:{"click":function($event){return _vm.$emit('remove')}}},[_c('CloseIcon')],1)],1),_vm._v(" "),_c('Divider',{attrs:{"color":"primary"}}),_vm._v(" "),_c('CardText',[_c('ValidationObserver',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ invalid, handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleSetup)}}},[_c('FormGroup',{attrs:{"gutter-bottom":!!_vm.selectedSource}},[_c('Label',{attrs:{"component":"span","block":"","gutter-bottom":""}},[_vm._v("Choose your form destination")]),_vm._v(" "),_c('BoxInputGroup',_vm._l((_vm.FORM_TYPES),function({ name, source, iconComponent, color },index){return _c('BoxInput',{key:source,attrs:{"name":`form${_vm.linkId}`,"input-id":`form${index}${_vm.linkId}`,"input-value":source,"tooltip":name},model:{value:(_vm.selectedSource),callback:function ($$v) {_vm.selectedSource=$$v},expression:"selectedSource"}},[_c(iconComponent,{tag:"component",attrs:{"slot":"icon","color":color},slot:"icon"})],1)}),1)],1),_vm._v(" "),(_vm.selectedSource)?[_c('ValidationProvider',{ref:"urlProvider",attrs:{"rules":{
              required: true,
              url: true,
              validate_embed_type: {
                source: _vm.selectedSourceDetails.source,
                partial: _vm.selectedSourceDetails.matchesMultiple,
              },
            },"name":"form url","debounce":100,"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, ariaMsg, ariaInput }){return [_c('FormGroup',[_c('Label',{attrs:{"for":`formurl${_vm.linkId}`}},[_vm._v("Enter "+_vm._s(_vm.selectedSourceDetails.name)+" URL")]),_vm._v(" "),_c('Input',_vm._b({attrs:{"id":`formurl${_vm.linkId}`,"placeholder":_vm.selectedSourceDetails.placeholder},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}},'Input',ariaInput,false)),_vm._v(" "),_c('FormGroupHelp',_vm._b({attrs:{"color":"error"}},'FormGroupHelp',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_vm._v(" "),_c('Button',{attrs:{"type":"submit","loading":_vm.isSaving,"disabled":invalid}},[_vm._v("Finish Setup")])]:_vm._e()],2)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }