<template>
  <FormGroup>
    <Label gutter-bottom>Enter the URL of your {{ sourceName }} blog</Label>
    <Input
      v-model="feedUrl"
      placeholder="https://www.myblog.com"
      type="url"
      name="feedUrl"
      :has-error="!!error"
    />
    <FormGroupHelp color="error">{{ error }}</FormGroupHelp>
  </FormGroup>
</template>

<script>
import {
  Input,
  Label,
  FormGroup,
  FormGroupHelp,
} from '@campsite-bio/component-lib';
import { toRefs } from 'vue';
import { useModel } from '../../../../compositions';

export default {
  components: {
    Input,
    Label,
    FormGroup,
    FormGroupHelp,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    sourceName: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);

    const feedUrl = useModel(value, (val) => emit('input', val));

    return { feedUrl };
  },
};
</script>

<style lang="scss" scoped></style>
