export const ORG_ROLE_OWNER = 'owner';
export const ORG_ROLE_MEMBER = 'member';
export const ORG_ROLE_COLLABORATOR = 'collaborator';

export const ORG_ROLES = [
  {
    value: ORG_ROLE_OWNER,
    label: 'Owner',
    description: 'Has full administrative access to the entire organization.',
  },
  {
    value: ORG_ROLE_MEMBER,
    label: 'Member',
    description:
      'Can see every member in the organization, and can access profiles specified by the default member role. Can create profiles if allowed.',
  },
  {
    value: ORG_ROLE_COLLABORATOR,
    label: 'Outside collaborator',
    description:
      "Cannot see other members in the organization, and can only access profiles that they've been given direct access to. Cannot create new profiles.",
  },
];
