<template>
  <Card>
    <CardTitle style="display: block">
      <VText component="h2" variant="h4">{{ title }}</VText>
      <slot name="subtitle" />
    </CardTitle>
    <CardText>
      <slot />
    </CardText>
  </Card>
</template>

<script>
import { Card, CardTitle, CardText, VText } from '@campsite-bio/component-lib';

export default {
  components: {
    Card,
    CardTitle,
    CardText,
    VText,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
