<template>
  <FormGroup>
    <FormGroupHeader gutter-bottom>
      <VText component="h3" variant="h5">{{ title }}&nbsp;</VText>
      <v-tooltip top>
        <template #activator="{ on }">
          <QuestionCircleOIcon style="font-size: 20px" v-on="on" />
        </template>
        <span>Add custom fields to your opt-in form</span>
      </v-tooltip>
    </FormGroupHeader>

    <ValidationObserver ref="form" slim>
      <draggable
        :options="{ draggable: '.item-wrapper', handle: '.item-wrapper__drag' }"
        :animation="200"
        @update="onSortUpdate"
      >
        <MergeField
          v-for="field in mergeFields"
          :key="field._id.$oid"
          :link="link"
          :merge-field="field"
          v-bind="mergeFieldProps"
        />
      </draggable>
    </ValidationObserver>

    <div
      v-if="defaultMergeFields.length || canAddCustomField"
      style="margin: 0.5rem 0 0"
    >
      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <Button
            size="small"
            :disabled="isAddDisabled || isLoading"
            v-bind="attrs"
            v-on="on"
          >
            Add Field +
          </Button>
        </template>
        <List>
          <ListItem
            v-for="field in defaultMergeFields"
            :key="field.name"
            component="button"
            type="button"
            @click="add(field)"
          >
            <ListItemTitle>{{ field.label }}</ListItemTitle>
          </ListItem>
          <ListItem
            v-if="canAddCustomField"
            component="button"
            type="button"
            @click="add"
          >
            <ListItemTitle>Add a custom field</ListItemTitle>
          </ListItem>
        </List>
      </v-menu>
    </div>
  </FormGroup>
</template>

<script>
import {
  FormGroup,
  FormGroupHeader,
  QuestionCircleOIcon,
  Button,
  VText,
  List,
  ListItem,
  ListItemTitle,
} from '@campsite-bio/component-lib';
import draggable from 'vuedraggable';
import { computed, ref, toRefs } from 'vue';
import { ValidationObserver } from 'vee-validate';

import toast from '../../../../utils/toast';
import MergeField from './merge-field';
import { useStore } from '../../../../compositions';
import { getMergeFieldsBySource } from './merge-field-data';

export default {
  components: {
    MergeField,
    draggable,
    FormGroup,
    FormGroupHeader,
    VText,
    QuestionCircleOIcon,
    Button,
    List,
    ListItem,
    ListItemTitle,
    ValidationObserver,
  },

  props: {
    link: {
      type: Object,
      required: true,
    },

    emailOptions: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { link, emailOptions } = toRefs(props);
    const isLoading = ref(false);
    const form = ref(null);
    const store = useStore();

    const mergeFieldData = computed(() => {
      return getMergeFieldsBySource(emailOptions.value.source);
    });

    const mergeFieldProps = computed(() => {
      const { defaults, canAddCustomField, ...props } = mergeFieldData.value;
      return props;
    });

    const canAddCustomField = computed(() => {
      return mergeFieldData.value.canAddCustomField;
    });

    const title = computed(() => {
      return emailOptions.value.source === 'mailchimp'
        ? 'Merge Fields'
        : 'Form Fields';
    });

    const mergeFields = computed(() => {
      return link.value.email_options.merge_fields;
    });

    const isAddDisabled = computed(() => {
      return (
        isLoading.value || (mergeFields.value && mergeFields.value.length >= 5)
      );
    });

    const defaultMergeFields = computed(() => {
      return mergeFieldData.value.defaults.filter((field) => {
        return (
          !mergeFields.value.find((f) => f.name === field.name) &&
          (field.type ? emailOptions.value.type === field.type : true)
        );
      });
    });

    async function add(fieldData) {
      isLoading.value = true;

      try {
        await store.dispatch('links/addLinkMergeField', {
          id: link.value._id.$oid,
          ...fieldData,
        });
      } catch (e) {
        console.error(e);
        const { error_message } = e.response.data;
        toast.error(error_message);
      }

      isLoading.value = false;
    }

    function onSortUpdate(e) {
      const newIndex = e.newIndex,
        oldIndex = e.oldIndex;

      store.commit('links/moveLinkMergeField', {
        linkId: link.value._id.$oid,
        newIndex: newIndex,
        oldIndex: oldIndex,
      });
      // save position
      store.dispatch('links/updateLinkMergeFieldPosition', {
        linkId: link.value._id.$oid,
        mergeFields: mergeFields.value,
      });
    }

    return {
      isLoading,
      form,
      title,
      mergeFields,
      isAddDisabled,
      add,
      onSortUpdate,
      defaultMergeFields,
      mergeFieldProps,
      canAddCustomField,
    };
  },
};
</script>

<style lang="scss" scoped></style>
