<template>
  <LeaderboardTable
    class="leaderboard-card"
    :stat-name="startCase(metric)"
    :stat-key="metric"
    v-bind="$props"
  >
    <ChartCardButton slot="actions" v-model="metric" :items="menuItems">
      {{ metric }}
    </ChartCardButton>
  </LeaderboardTable>
</template>

<script>
import { ref } from 'vue';
import startCase from 'lodash/startCase';

import LeaderboardTable from './leaderboard-table';
import { ChartCardButton } from '..';

export default {
  components: {
    LeaderboardTable,
    ChartCardButton,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const metric = ref('reach');

    return {
      metric,
      startCase,
      menuItems: [
        { name: 'Reach', value: 'reach' },
        {
          name: 'Views',
          value: 'views',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
