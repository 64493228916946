<template>
  <AccordionSectionItem title="Confirm your email address" :variant="variant">
    <span slot="icon">{{ step }}</span>

    <VText>
      We've sent an email confirmation to <b>{{ user.email }}</b
      >. Open your email client and click the link inside to confirm your email
      address and enable your profile.<br /><br />
      <Button
        :loading="isSendingConfirmation"
        :disabled="!isEmailResendEnabled || isEmailVerified"
        @click="resendEmailConfirmation"
        >{{ resendEmailButtonCopy }}</Button
      >
    </VText>
  </AccordionSectionItem>
</template>

<script>
import {
  AccordionSectionItem,
  VText,
  Button,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';

import {
  useCurrentUser,
  useResendEmailConfirmation,
  useStore,
} from '@/compositions';

export default {
  components: {
    AccordionSectionItem,
    VText,
    Button,
  },

  props: {
    step: {
      type: [String, Number],
      required: true,
    },

    variant: {
      type: String,
      default: null,
    },
  },

  setup() {
    const store = useStore();
    const { isEmailVerified } = useCurrentUser();
    const {
      isSendingConfirmation,
      isEmailResendEnabled,
      emailResendTimePassed,
      resendEmailButtonCopy,
      resendEmailConfirmation,
    } = useResendEmailConfirmation();

    const user = computed(() => store.getters.currentUser);

    return {
      isEmailVerified,
      isSendingConfirmation,
      isEmailResendEnabled,
      emailResendTimePassed,
      resendEmailButtonCopy,
      resendEmailConfirmation,
      user,
    };
  },
};
</script>

<style lang="scss" scoped></style>
