<template>
  <LinkExpander
    :id="`highlight-${id}`"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template slot="header">
      Highlight link
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <IconButton
            href="https://support.campsite.bio/en/articles/6846481-how-to-highlight-a-link"
            target="_blank"
            aria-label="Highlight link help"
            size="1.25rem"
            v-bind="attrs"
            v-on="on"
          >
            <QuestionCircleOIcon />
          </IconButton>
        </template>
        <span>Get some help with your link highlight</span>
      </v-tooltip>
    </template>
    <p>Show off one of your links with a highlight animation.</p>
    <Alert v-if="!linkEnabled" variant="outline" style="margin-bottom: 0.75rem">
      <InfoIcon slot="icon" />
      Enable this link to preview your highlight changes
    </Alert>
    <slot name="alert" />

    <ChipGroup v-model="highlight" aria-label="Select a highlight">
      <Chip :value="null" selectable>None</Chip>
      <Chip
        v-for="(item, x) in highlightAnimations"
        :key="x"
        :value="item.value"
        selectable
        >{{ item.name }}</Chip
      >
    </ChipGroup>
  </LinkExpander>
</template>

<script>
import {
  Alert,
  InfoIcon,
  IconButton,
  QuestionCircleOIcon,
  ChipGroup,
  Chip,
} from '@campsite-bio/component-lib';
import LinkExpander from './link-expander.vue';
import { HIGHLIGHT_OPTIONS } from '../highlight-options';
import { inject, toRefs } from 'vue';
import { useModel } from '../../../compositions';

export default {
  components: {
    LinkExpander,
    Alert,
    InfoIcon,
    IconButton,
    QuestionCircleOIcon,
    ChipGroup,
    Chip,
  },

  props: {
    isOpen: Boolean,
    linkEnabled: Boolean,
    value: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const { id } = inject('link');
    const highlight = useModel(value, (val) => emit('input', val));

    return {
      id,
      highlight,
      highlightAnimations: HIGHLIGHT_OPTIONS,
    };
  },
};
</script>

<style lang="scss" scoped></style>
