import { computed, watch } from 'vue';

import router from '@/router';
import { useRoute } from '@/compositions/use-route';
import toast from '@/utils/toast';
import { useCurrentOrg } from './use-current-org';

export function useOrgEntitlements() {
  const route = useRoute();
  const { organization, hasOrganization, id } = useCurrentOrg();
  const to = computed(() => {
    return `/organization/${id.value}`;
  });

  watch(
    [hasOrganization, organization, route],
    ([value]) => {
      if (!value) return;

      const entitlements = route.value.meta.entitlements;
      const userRole = organization.value.role;
      const hasAccess = (
        Array.isArray(entitlements) ? entitlements : [entitlements]
      ).includes(userRole);

      if (!hasAccess) {
        router.push(to.value);
        toast.error(
          'You do not have the required entitlements to view this page.',
        );
      }
    },
    { immediate: true },
  );
}
