<template>
  <AccordionSectionItem title="Update your profile image" :variant="variant">
    <span slot="icon">{{ step }}</span>

    <VText gutter-bottom
      >Upload an image for your profile. The minimum requirement is a 300x300px
      image.</VText
    >

    <Button
      :href="`/profile/${id}/settings/appearance`"
      @click.prevent="handleClick"
    >
      Open Appearance Page
    </Button>
  </AccordionSectionItem>
</template>

<script>
import {
  AccordionSectionItem,
  VText,
  Button,
} from '@campsite-bio/component-lib';

import { useScrollToRoute } from './use-scroll-to-route';
import { useCurrentProfile } from '../../../compositions';

export default {
  components: {
    AccordionSectionItem,
    VText,
    Button,
  },

  props: {
    step: {
      type: [String, Number],
      required: true,
    },

    variant: {
      type: String,
      default: null,
    },
  },

  setup() {
    const { handleClick } = useScrollToRoute('.js-site-details');
    const { id } = useCurrentProfile();

    return { handleClick, id };
  },
};
</script>

<style lang="scss" scoped></style>
