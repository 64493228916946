const PRO = {
  name: 'Pro',
  id: 'pro',
  number_of_profiles: 1,
  number_of_users: 0,
  profiles_limit: 10,
  users_limit: 5,
  addons: [],
};

export const PLAN_DATA = {
  pro: PRO,

  pro_legacy: {
    ...PRO,
    id: 'pro_legacy',
    number_of_profiles: 3,
  },

  pro_plus: {
    name: 'Pro+',
    id: 'pro_plus',
    number_of_profiles: 6,
    number_of_users: 4,
    profiles_limit: 18,
    users_limit: 8,
    addons: ['analytics'],
  },

  org_pro: {
    name: 'Org Pro',
    id: 'org_pro',
    number_of_profiles: 1,
    number_of_users: 2,
    profiles_limit: 19,
    users_limit: 18,
    addons: [],
  },

  org_pro_plus: {
    name: 'Org Pro+',
    id: 'pro_plus',
    number_of_profiles: 1,
    number_of_users: 3,
    profiles_limit: 39,
    users_limit: 27,
    addons: ['analytics'],
  },
};

export function getPlanData(priceId) {
  switch (priceId) {
    case 'pro':
    case 'plan_FM6AntXt5ARGfQ':
    // Prod mode ids
    case 'plan_FLNSIRCFVFY0EH':
      return PLAN_DATA['pro_legacy'];
    case 'price_1NBLk2AfaVfIMl4218taUS2O':
    case 'price_1NBLk2AfaVfIMl427EvWzjSl':
    // Prod mode ids
    case 'price_1NHALXAfaVfIMl42DLKOMDNw':
    case 'price_1NHALXAfaVfIMl42gBazn9nL':
      return PLAN_DATA['pro'];
    case 'price_1KlGGaAfaVfIMl42R0nJ0DDL':
    case 'price_1KlGGpAfaVfIMl42IdK6jHtj':
    // Prod mode ids
    case 'price_1KlGHqAfaVfIMl42x48Xbc6v':
    case 'price_1KlGHzAfaVfIMl42qcvOqVNb':
      return PLAN_DATA['pro_plus'];
    case 'price_1NGNAOAfaVfIMl42G28B0AWj':
    case 'price_1NGNAEAfaVfIMl4220Eb4vbs':
    // Prod mode ids
    case 'price_1NHALGAfaVfIMl42UY3GDf6K':
    case 'price_1NHALGAfaVfIMl42hIXR1X2I':
      return PLAN_DATA['org_pro'];
    case 'price_1N1Z0MAfaVfIMl42qDFJzKA1':
    case 'price_1N1Z0MAfaVfIMl422y68NBS0':
    // Prod mode ids
    case 'price_1NHAL6AfaVfIMl42ZgoTddlB':
    case 'price_1NHAL6AfaVfIMl42geivKOSa':
      return PLAN_DATA['org_pro_plus'];
  }

  return null;
}
