<template>
  <Badge :color="color" :content="value" />
</template>

<script>
import { Badge } from '@campsite-bio/component-lib';
import { computed } from 'vue';

import { percentageChange } from '../../../utils';

/**
 * A badge that displays the percentage change between two numbers.
 */
export default {
  components: {
    Badge,
  },

  props: {
    current: {
      type: Number,
      default: 0,
    },

    past: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const percentChange = computed(() => {
      return percentageChange(props.past, props.current);
    });

    const value = computed(() => {
      return typeof percentChange.value === 'number'
        ? `${percentChange.value.toFixed(1)}%`
        : percentChange.value;
    });

    const color = computed(() => {
      return percentChange.value > 0 ? 'success' : 'info';
    });

    return {
      value,
      color,
    };
  },
};
</script>

<style lang="scss" scoped></style>
