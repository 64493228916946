<template>
  <LinkWrapper v-bind="$props" @add-link="$emit('add-link', $event)">
    <div class="link-group" :class="classes">
      <LinkCard :link-stack-shadow="!isGroupLinksOpen">
        <ValidationObserver ref="form" slim>
          <ValidationProvider
            v-slot="{ errors, ariaInput, ariaMsg }"
            :rules="labelRules"
            name="label"
            immediate
            slim
          >
            <LinkField>
              <EmojiInput
                ref="labelRef"
                v-model="label"
                class="js-link-label"
                border-color="gray200"
                :has-error="errors.length > 0"
                autocomplete="off"
                maxlength="80"
                placeholder="Add your group label"
                :show-emoji="isLargeScreen"
                :disabled="isReadonly"
                aria-label="Link label"
                v-bind="ariaInput"
                @focus="focusLink"
              >
                <IconButton
                  slot="prepend"
                  v-clipboard
                  :data-clipboard-text="label"
                  tabindex="-1"
                  aria-label="Copy label"
                >
                  <PencilIcon title="Copy label" />
                </IconButton>
              </EmojiInput>
              <FormGroupHelp color="error" v-bind="ariaMsg">{{
                errors[0]
              }}</FormGroupHelp>
            </LinkField>
          </ValidationProvider>
        </ValidationObserver>

        <v-media-selector
          slot="media"
          v-model="mediaImage"
          :has-future-image="hasFutureImage"
          class="media-selector--post"
          :disabled="isReadonly"
          :loading="isSavingImage"
          @select="selectMedia"
          @remove="imageRemove"
        />

        <template slot="readonly-btm">
          <LinkBtn component="div">
            <LinkIcon slot="icon" />
            {{ numberOfLinks }} link{{ numberOfLinks !== 1 ? 's' : '' }}
          </LinkBtn>
        </template>

        <template slot="btm">
          <LinkBtn
            v-if="!isStatsHidden"
            :active="isExpanded('stats')"
            :aria-label="`Link analytics - ${link.number_of_clicks} clicks`"
            :aria-controls="`stats-${id}`"
            @click="toggleExpand('stats')"
          >
            <LineChartIcon slot="icon" />
            <template v-if="link.number_of_clicks" slot="stat">
              {{ link.number_of_clicks | numberFormatter(1) }}
            </template>
          </LinkBtn>
          <LinkBtn
            v-if="!isDeleted && !isScheduleHidden"
            aria-label="Schedule link"
            :active="isExpanded('schedule')"
            :highlight="isExpanded('schedule')"
            :aria-controls="`schedule-${id}`"
            @click="toggleExpand('schedule')"
          >
            <CalendarIcon slot="icon" />
          </LinkBtn>
          <v-tooltip v-if="!isDeleted" top>
            <template #activator="{ on }">
              <LinkBtn
                v-if="!isDeleted"
                aria-label="Pin link"
                :pinned="pinned"
                :disabled="isPinnedDisabled"
                v-on="on"
                @click="pinned = !pinned"
              >
                <PinIcon slot="icon" />
              </LinkBtn>
            </template>
            <span>{{ pinnedText }}</span>
          </v-tooltip>
          <!-- <v-tooltip v-if="!isDeleted && !isLockHidden" top>
            <template #activator="{ on }">
              <LinkBtn
                aria-label="Lock"
                :active="isExpanded('lock')"
                :highlight="hasLock"
                v-on="on"
                @click="toggleExpand('lock')"
              >
                <LockIcon slot="icon" />
              </LinkBtn>
            </template>
            <span>Lock</span>
          </v-tooltip> -->
          <v-tooltip v-if="!isFreePlan && !isDeleted && !isEventTagHidden" top>
            <template #activator="{ on }">
              <LinkBtn
                aria-label="Event tag"
                :active="isExpanded('event-tag')"
                :aria-controls="`event-label-${id}`"
                v-on="on"
                @click="toggleExpand('event-tag')"
              >
                <TagIcon slot="icon" />
              </LinkBtn>
            </template>
            <span>Event tag</span>
          </v-tooltip>
          <v-tooltip v-if="!isDeleted" top>
            <template #activator="{ on }">
              <LinkBtn
                :active="isGroupLinksOpen"
                :aria-controls="`group-${id}`"
                v-on="on"
                @click="isGroupLinksOpen = !isGroupLinksOpen"
              >
                <LinkIcon slot="icon" />
                {{ numberOfLinks }} link{{ numberOfLinks === 1 ? '' : 's' }}
              </LinkBtn>
            </template>
            <span>{{ isGroupLinksOpen ? 'Hide' : 'Show' }} links</span>
          </v-tooltip>
          <v-tooltip v-if="isDeleted" top>
            <template #activator="{ on }">
              <LinkBtn
                class="link__btn--restore js-link-restore"
                aria-label="Restore link"
                :disabled="isRestoring"
                v-on="on"
                @click="handleRestore"
              >
                <RepeatIcon slot="icon" />
              </LinkBtn>
            </template>
            <span>Restore link</span>
          </v-tooltip>
        </template>

        <template slot="actions">
          <StatsExpand
            :is-open="isExpanded('stats')"
            @close="toggleExpand('stats')"
          />
          <ScheduleExpand
            :is-open="isExpanded('schedule')"
            :link="link"
            @close="toggleExpand('schedule')"
          />
          <EventLabelExpand
            v-if="!isFreePlan"
            :is-open="isExpanded('event-tag')"
            :link="link"
            @close="toggleExpand('event-tag')"
          />
          <LockExpand
            :is-open="isExpanded('lock')"
            :link="link"
            @close="toggleExpand('lock')"
          />
          <GroupSettingsExpand
            :is-open="isExpanded('default')"
            :link-id="id"
            :group-options="options"
            @close="toggleExpand('default')"
            @save="saveOptions"
          />
        </template>
      </LinkCard>

      <GroupLinks v-if="!isReadonly" />

      <div v-if="showDropzone" class="link-group__dropzone">
        <span class="link-group__dropzone__text">
          <GroupIcon /> Add link to this group ({{ numberOfLinks }})
        </span>
        <draggable
          group="links"
          :swap-threshold="0.75"
          fallback-on-body
          direction="vertical"
          @add="handleLinkDrop"
        />
      </div>
    </div>

    <template slot="delete-dialog"
      >This group and it's {{ numberOfLinks }} child link{{
        numberOfLinks !== 1 ? 's' : ''
      }}
      will be permanently deleted. It cannot be undone.</template
    >
  </LinkWrapper>
</template>

<script>
import {
  EmojiInput,
  IconButton,
  PencilIcon,
  LineChartIcon,
  CalendarIcon,
  PinIcon,
  RepeatIcon,
  TagIcon,
  EMBED_ICONS,
  FormGroupHelp,
  LockIcon,
  GroupIcon,
  LinkIcon,
} from '@campsite-bio/component-lib';
import draggable from 'vuedraggable';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { computed, nextTick, provide, ref, watch } from 'vue';
import { useResizeObserver } from '@vueuse/core';

import {
  StatsExpand,
  ScheduleExpand,
  EventLabelExpand,
  LockExpand,
} from '../expand';
import { LinkProps } from '../link-props';
import { useLink } from '../compositions';
import { LinkBtn, LinkWrapper, LinkCard, LinkField } from '../components';
import { useStore } from '@/compositions';
import GroupLinks from './group-links';
import GroupSettingsExpand from './settings-expand';
import toast from '@/utils/toast';

export default {
  components: {
    EmojiInput,
    IconButton,
    PencilIcon,
    LineChartIcon,
    CalendarIcon,
    PinIcon,
    RepeatIcon,
    TagIcon,
    LockIcon,
    StatsExpand,
    ScheduleExpand,
    EventLabelExpand,
    LockExpand,
    LinkBtn,
    ...EMBED_ICONS,
    FormGroupHelp,
    ValidationObserver,
    ValidationProvider,
    LinkWrapper,
    LinkCard,
    LinkField,
    draggable,
    GroupIcon,
    GroupLinks,
    LinkIcon,
    GroupSettingsExpand,
  },

  props: LinkProps,

  setup(props) {
    const store = useStore();
    const isLoading = ref(false);
    const isAddingLink = ref(false);
    const isLoadingLinks = ref(false);
    const linksRef = ref(null);
    const lastLinkHeight = ref(0);

    const {
      link,
      id,
      isSavingOptions,
      options,
      hasSetup,
      saveOptions,
      isBeingDragged,
      isExpanded,
      closeExpand,
      ...rest
    } = useLink(props, {
      name: 'group',
      hasMedia: true,
      optionsName: 'group',
      isLoading: computed(
        () => isLoading.value || isLoadingLinks.value || isAddingLink.value,
      ),
    });

    const isDragging = computed({
      get: () => store.getters['links/getField']('isDragging'),
      set: (value) =>
        store.commit('links/updateField', { path: 'isDragging', value }),
    });
    const isGroupBeingDragged = computed(() => {
      const linkDragId = store.getters['links/getField']('linkDragId');
      const link = store.getters['links/links'].find(
        (l) => l._id.$oid === linkDragId,
      );
      return (
        isDragging.value &&
        (link.type === 'group' || link.type === 'image-grid')
      );
    });

    const classes = computed(() => {
      return {
        'link-group--dragging':
          isDragging.value &&
          // Don't show the dragging state if the group is being dragged or another is
          !isBeingDragged.value &&
          !isGroupBeingDragged.value,
        'link-group--hide-card':
          isDragging.value &&
          !isGroupLinksOpen.value &&
          !isGroupBeingDragged.value,
      };
    });

    const lastLinkRef = computed(() => {
      return linksRef.value
        ? linksRef.value[linksRef.value?.length - 1]?.$el
        : null;
    });

    useResizeObserver(lastLinkRef, ([{ contentRect }]) => {
      lastLinkHeight.value = contentRect.height;
    });

    const isGroupLinksOpen = ref(false);
    const numberOfLinks = computed(() => {
      return options.value.link_ids?.length || 0;
    });
    const links = computed(() => {
      return store.getters['links/getField'](`group.${id.value}`) || [];
    });
    // Only allow 20 links per group
    const isAtLimit = computed(() => {
      return numberOfLinks.value >= 20;
    });

    watch(isGroupLinksOpen, (value) => {
      if (value && isExpanded.value) {
        closeExpand();
      }
    });

    // Show the dropzone if there are no links in the group, or if the group links are closed
    const showDropzone = computed(() => {
      return (
        isDragging.value &&
        (numberOfLinks.value === 0 || !isGroupLinksOpen.value) &&
        !isAtLimit.value &&
        // We don't want to show the dropzone if the group is being dragged or if another group is being dragged
        !isBeingDragged.value &&
        !isGroupBeingDragged.value
      );
    });

    // Close the group links when the group is being dragged
    watch(isBeingDragged, (value) => {
      if (value) {
        isGroupLinksOpen.value = false;
      }
    });

    async function loadLinks() {
      isLoadingLinks.value = true;
      try {
        await store.dispatch('links/getGroupLinks', {
          linkId: id.value,
        });
      } catch (e) {
        console.error(e);
        toast.error('There was an error loading group links');
      }
      isLoadingLinks.value = false;
    }

    async function addLink(type) {
      isAddingLink.value = true;
      try {
        await store.dispatch('links/addLink', {
          type,
          profile_id: link.value.campsite_id.$oid,
          group_id: id.value,
        });
        // focus recently added link input
        await nextTick();
        linksRef.value[0].focus();
        linksRef.value[0].justAdded();
      } catch (e) {
        const { error_message } = e.response?.data || {};
        console.error(e);
        toast.error(error_message || 'Error adding a new link');
      }

      isAddingLink.value = false;
    }

    async function handleLinkDrop(e) {
      isLoading.value = true;
      try {
        const linkId = e.item.dataset.id;
        await store.dispatch('links/addLinkToGroup', {
          linkId,
          groupId: id.value,
          position: e.newIndex,
        });
        isGroupLinksOpen.value = true;
      } catch (e) {
        const { error_message } = e.response?.data || {};
        console.error(e);
        toast.error(error_message || 'Error adding link to group');
      }
      isLoading.value = false;
    }

    async function onSortUpdate(e) {
      const newIndex = e.newIndex,
        oldIndex = e.oldIndex;

      isLoading.value = true;
      const link = store.getters['links/getField'](`group.${id.value}`)[
        oldIndex
      ];
      store.commit('links/moveLink', {
        newIndex: newIndex,
        oldIndex: oldIndex,
        groupId: id.value,
      });
      // save link position
      try {
        await store.dispatch('links/updateLinkPosition', {
          link,
          position: newIndex,
          groupId: id.value,
        });
      } catch (e) {
        if (axios.isCancel(e) || e.name === 'AbortError') return;
        console.error(e);
      }

      isLoading.value = false;
    }

    provide('group', {
      numberOfLinks,
      isAddingLink,
      isLoadingLinks,
      loadLinks,
      links,
      addLink,
      isGroupLinksOpen,
      isAtLimit,
      onSortUpdate,
      handleLinkDrop,
      linksRef,
      lastLinkHeight,
      isDragging,
      isGroupBeingDragged,
    });

    return {
      ...rest,
      id,
      isExpanded,
      isDragging,
      classes,
      numberOfLinks,
      isGroupLinksOpen,
      isAtLimit,
      handleLinkDrop,
      showDropzone,
      options,
      saveOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.link-group {
  position: relative;

  &--hide-card {
    &::v-deep > .link-card-wrapper > .link {
      opacity: 0;
    }
  }

  &__dropzone {
    border: 0.125rem dashed var(--g-color-gray-300);
    border-radius: var(--g-border-radius-standard);
    border-radius: min(
      var(--g-border-radius-standard),
      var(--g-border-radius-min)
    );
    display: flex;
    position: absolute;
    padding: 1rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 4;

    &__text {
      align-items: center;
      color: var(--g-color-gray-700);
      display: inline-flex;
      gap: 0.25rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    > div {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
