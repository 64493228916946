<template>
  <Fragment>
    <SettingsSection v-for="i in count" :key="i">
      <SettingsSectionTitle>
        <Skeleton width="200px" />
      </SettingsSectionTitle>

      <Skeleton :count="6" />
    </SettingsSection>
  </Fragment>
</template>

<script>
import { Skeleton, Fragment } from '@campsite-bio/component-lib';

import SettingsSection from '@/components/settings-section/settings-section';
import SettingsSectionTitle from '@/components/settings-section/settings-section-title';

export default {
  components: {
    Skeleton,
    SettingsSection,
    SettingsSectionTitle,
    Fragment,
  },

  props: {
    count: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped></style>
