<template>
  <tr :class="`${highlight ? 'summary-table__highlight' : ''}`">
    <slot />
  </tr>
</template>

<script>
export default {
  props: {
    highlight: Boolean,
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.summary-table__highlight {
  font-weight: 700;
}
</style>
