<template>
  <component
    :is="component"
    class="header__nav-link"
    :class="classes"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from '../../compositions';

export default {
  props: {
    component: {
      type: String,
      default: 'router-link',
    },

    muted: Boolean,
    /** Route has to be an exact match to show active state */
    exact: Boolean,
  },

  setup(props, { attrs }) {
    const route = useRoute();

    const classes = computed(() => {
      return {
        'header__nav-link--muted': props.muted,
        'header__nav-link--active': props.exact
          ? route.value.path === attrs.to
          : route.value.path.startsWith(attrs.to),
      };
    });

    return {
      classes,
    };
  },
};
</script>

<style lang="scss" scoped>
.header__nav-link {
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: var(--g-color-gray-800);
  display: inline-flex;
  font-size: 1.9rem;
  padding: 0.5rem;
  position: relative;
  transition: all var(--g-transition-speed);

  &--active {
    background: var(--c-bg-active-color);
  }

  @media (min-width: 992px) {
    display: flex;
    height: 4rem;
  }

  &--muted {
    color: var(--g-color-gray-500);
    font-size: 1.6rem;
    height: unset;
  }

  &:hover,
  &:focus {
    color: var(--g-color-primary);
  }
}
</style>
