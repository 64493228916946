<template>
  <div class="link-wrapper" :data-id="id">
    <slot />

    <div v-if="isDeleted" style="height: 0.75rem" />
    <AddLinkBtn
      v-else
      :disabled="isAddLinkDisabled || disabled || isDragging"
      @click="$emit('add-link', $event)"
    />

    <DeleteDialog
      v-if="!disabled"
      v-model="isDeleteDialogOpen"
      :name="name"
      :is-deleting="isDeleting"
      @remove="handleDelete"
    >
      <slot name="delete-dialog" />
    </DeleteDialog>
  </div>
</template>

<script>
import { computed, inject } from 'vue';

import DeleteDialog from './delete-dialog';
import AddLinkBtn from './add-link-btn';
import { useStore } from '../../../compositions';

export default {
  components: { AddLinkBtn, DeleteDialog },

  props: {
    disabled: Boolean,
    isAddLinkDisabled: Boolean,
  },

  setup() {
    const {
      id,
      name,
      isDeleted,
      isDeleting,
      isDeleteDialogOpen,
      handleDelete,
    } = inject('link');
    const store = useStore();
    const isDragging = computed(() => {
      return store.getters['links/getField']('isDragging');
    });

    return {
      id,
      name,
      isDeleted,
      isDeleting,
      isDeleteDialogOpen,
      handleDelete,
      isDragging,
    };
  },
};
</script>

<style lang="scss" scoped>
.link-wrapper {
  scroll-margin: calc(var(--header-height) + 4.5rem);

  @media (min-width: 992px) {
    scroll-margin: calc(var(--profile-menu-height) + 4.5rem);
  }

  > .v-dialog__container {
    display: none;
  }
}
</style>
