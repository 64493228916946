<template>
  <v-dialog v-model="localValue" max-width="500" @keydown.esc="close">
    <ValidationObserver v-slot="{ handleSubmit }" slim>
      <form action="#" @submit.prevent="handleSubmit(save)">
        <Card>
          <CardTitle
            ><VText component="h2" variant="h4"
              >Your Campsite.bio Username</VText
            ></CardTitle
          >
          <Divider color="lightgray" />
          <CardText>
            <VText gutter-bottom
              >Changing your username will immedietely change the link to your
              Campsite</VText
            >
            <ValidationProvider
              v-slot="{ errors, ariaMsg, ariaInput }"
              :rules="usernameRules"
              :debounce="500"
              name="username"
              slim
            >
              <FormGroup>
                <Label for="username">Username</Label>
                <Input
                  id="username"
                  v-model="usernameLocal"
                  type="text"
                  name="username"
                  required
                  placeholder="myusername"
                  autocomplete="off"
                  autocapitalize="off"
                  minlength="3"
                  maxlength="30"
                  prefix="campsite.bio/"
                  :has-error="errors.length > 0"
                  v-bind="ariaInput"
                >
                  <template slot="append">
                    <CheckIcon
                      v-show="
                        errors.length === 0 &&
                        usernameLocal &&
                        usernameLocal !== username
                      "
                      color="success"
                    />
                    <ErrorOutlineIcon
                      v-show="errors.length > 0"
                      color="error"
                    />
                  </template>
                </Input>
                <FormGroupHelp color="error" v-bind="ariaMsg">{{
                  errors[0]
                }}</FormGroupHelp>
              </FormGroup>
            </ValidationProvider>
          </CardText>
          <CardActions>
            <Button variant="flat" @click="close">Cancel</Button>
            <Button
              color="success"
              variant="flat"
              type="submit"
              :loading="isSaving"
              :disabled="!usernameLocal"
              >Save</Button
            >
          </CardActions>
        </Card>
      </form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  Card,
  CardTitle,
  CardText,
  CardActions,
  Button,
  VText,
  Divider,
  FormGroup,
  Label,
  Input,
  FormGroupHelp,
  ErrorOutlineIcon,
  CheckIcon,
} from '@campsite-bio/component-lib';
import { computed, ref, toRefs, watch } from 'vue';

import toast from '../../utils/toast';
import { useModel, useStore } from '../../compositions';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Card,
    CardTitle,
    CardText,
    CardActions,
    Button,
    Divider,
    VText,
    FormGroup,
    Label,
    Input,
    FormGroupHelp,
    ErrorOutlineIcon,
    CheckIcon,
  },

  props: {
    value: Boolean,
    username: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { username, value } = toRefs(props);
    const isSaving = ref(false);
    const usernameLocal = ref(null);
    const store = useStore();
    const localValue = useModel(value, (val) => emit('input', val));

    watch(
      username,
      (val) => {
        usernameLocal.value = val;
      },
      { immediate: true },
    );

    const usernameRules = computed(() => {
      if (
        usernameLocal.value &&
        usernameLocal.value.toLowerCase() !== username.value &&
        value.value // is dialog open
      ) {
        return {
          required: true,
          min: 3,
          max: 30,
          regex:
            /^[A-Za-z0-9_](?![_.])(?!.*[_.]{2})(?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,30}(?:[A-Za-z0-9_]))$/,
          username: true,
        };
      }

      return {};
    });

    function close() {
      emit('input', false);
    }

    async function save() {
      if (isSaving.value) return;
      if (username.value === usernameLocal.value) return close();

      isSaving.value = true;

      try {
        await store.dispatch(
          'profiles/updateUsername',
          usernameLocal.value.toLowerCase(),
        );

        toast.success(
          'The link to your campsite profile has changed. Make sure to update your bio links!',
          { timeout: -1 },
        );
        close();
      } catch (e) {
        console.error(e);
        toast.error('Error trying to update your username', {
          timeout: 3000,
        });
      }

      isSaving.value = false;
    }

    return {
      isSaving,
      usernameLocal,
      usernameRules,
      save,
      close,
      localValue,
    };
  },
};
</script>

<style scoped></style>
