import { computed, watch } from 'vue';
import { useStore, useUserPreferences } from '../../../compositions';

export function useSelectedDateFilter() {
  const store = useStore();
  const { userPreferences } = useUserPreferences();

  const selectedDateFilter = computed({
    get() {
      return store.getters['analytics/selectedDateFilter'];
    },
    set(newValue) {
      store.commit('analytics/set', {
        name: 'selectedDateFilter',
        value: newValue,
      });
    },
  });

  // Update user preferences when selectedDateFilter changes
  watch(selectedDateFilter, (newValue, oldValue) => {
    if (
      typeof newValue === 'string' &&
      userPreferences.value.analytics_date_filter !== newValue
    ) {
      store.dispatch('updateUserPreferences', {
        analytics_date_filter: newValue,
      });
    }
  });

  return selectedDateFilter;
}
