<template>
  <div class="media-item">
    <input
      :id="name"
      ref="input"
      class="image-select"
      type="radio"
      name="post_select"
      :checked="checked"
      :disabled="disabled"
      @change="select"
    />
    <label :for="name" :style="styles" @click="doubleClick">
      <img v-if="!pattern" :src="url" :alt="alt" />
    </label>
    <div v-if="caption" class="media-item__caption" v-html="caption"></div>
  </div>
</template>

<script>
export default {
  props: {
    checked: Boolean,
    disabled: Boolean,
    pattern: Boolean,
    url: String,
    alt: String,
    caption: String,
  },

  computed: {
    name() {
      return `item${Math.random() * 10000}`;
    },

    styles() {
      return {
        background: this.pattern ? `#fff url('${this.url}') repeat` : '',
      };
    },
  },

  methods: {
    select() {
      this.$emit('select');
    },

    doubleClick() {
      if (this.checked) this.$emit('double-click');
    },
  },
};
</script>

<style scoped></style>
