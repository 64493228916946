<template>
  <AccordionItemGroup>
    <AccordionItem title="How is an action score calculated?">
      <p>
        Action score is a weighted measurement that looks at more than just
        clicks. It weighs things like events "higher" and further weights the
        score by your reach percentile the given week per referrer.
      </p>

      <p>
        The higher the action score, the better the referrer. The highest score
        possible is 400.
      </p>
    </AccordionItem>
    <AccordionItem title="What events are included in the action score?">
      <p>The action score includes a number of different events.</p>

      <ul>
        <li>Form submits</li>
        <li>Opt-ins</li>
        <li>Carousel swipe</li>
        <li>Video starts</li>
        <li>Custom event tags you've added to your links</li>
      </ul>

      <p>
        If you don't want your custom events to change your action score, you
        can remove the event tag from your link.
      </p>
    </AccordionItem>
  </AccordionItemGroup>
</template>

<script>
import { AccordionItem, AccordionItemGroup } from '@campsite-bio/component-lib';

export default {
  components: {
    AccordionItemGroup,
    AccordionItem,
  },
};
</script>

<style lang="scss" scoped></style>
