<template>
  <div :id="id" :aria-hidden="(!isOpen).toString()">
    <div v-if="isOpen" class="link-expander">
      <div class="link-expander__header">
        <VText component="h2" variant="h5" class="link-expander__title">
          <slot name="header" />
        </VText>
        <FlexSpacer />
        <IconButton
          class="link-expander__close"
          aria-label="close"
          size="1.25rem"
          @click="$emit('close')"
        >
          <CloseIcon />
        </IconButton>
      </div>

      <slot />
    </div>
  </div>
</template>

<script>
import {
  FlexSpacer,
  IconButton,
  CloseIcon,
  VText,
} from '@campsite-bio/component-lib';

export default {
  components: {
    FlexSpacer,
    IconButton,
    CloseIcon,
    VText,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    isOpen: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.link-expander {
  border-top: 1px solid var(--g-color-gray-200);
  padding: 1.25rem 0.925rem;
}

.link-expander__header {
  background: var(--g-color-gray-100);
  align-items: center;
  display: flex;
  margin: -1.25rem -0.925rem 0.925rem;
  padding: 0.3125rem 0.925rem;
}

.link-expander__title {
  align-items: center;
  display: flex;

  > ::v-deep span {
    margin-left: 0.25rem;
  }
}

.link-expander__close {
  background: none;
  border: none;
  color: var(--g-color-primary);
  font-size: 1.75rem;
  padding: 0;

  svg {
    vertical-align: top;
  }
}
</style>
