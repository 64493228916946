<template>
  <AccordionItemGroup>
    <AccordionItem title="How is an engagement rate calculated?">
      <p>
        Engagement rate is a measurement that lets you know how engaged your
        followers are with your content. The higher the rate, the better the
        engagement.
      </p>

      <p>Engagement Rate = (Total Engagements / Reach ) * 100</p>
    </AccordionItem>
  </AccordionItemGroup>
</template>

<script>
import { AccordionItem, AccordionItemGroup } from '@campsite-bio/component-lib';

export default {
  components: {
    AccordionItemGroup,
    AccordionItem,
  },
};
</script>

<style lang="scss" scoped></style>
