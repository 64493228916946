import { localAxios } from './axios';

export function createGoogleSheet(linkId, data) {
  return localAxios.post(`/api/links/${linkId}/google-sheet`, data);
}

export function getAllGoogleSheets(profileId, q) {
  return localAxios.get(`/api/profiles/${profileId}/google-sheet/all`, {
    q,
  });
}

export function getGoogleSheet(profileId, id) {
  return localAxios.get(`/api/profiles/${profileId}/google-sheet/${id}`);
}
