<template>
  <div ref="root" class="wrapped-intro">
    <div class="wrapped-intro__close">
      <IconButton
        color="white"
        size="1.5rem"
        aria-label="Close"
        @click="$emit('close')"
      >
        <CloseIcon />
      </IconButton>
    </div>
    <div class="wrapped-intro__text">
      <span>We're analyzing your data</span>
      <span>We'll be wrapped up in a moment</span>
    </div>
    <div class="stories">
      <template v-for="(story, i) in stories">
        <div v-if="story.skip !== true" :key="i" class="stories__story">
          <component :is="story.component" static v-bind="story.data">
            <template slot="header">
              <Logo
                v-if="type === 'profile'"
                color="white"
                only-mark
                size="1em"
              />
              <InstagramIcon v-else-if="type === 'instagram'" />
              {{ name }}
            </template>
          </component>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { inject, onBeforeUnmount, onMounted, ref } from 'vue';
import {
  Logo,
  InstagramIcon,
  IconButton,
  CloseIcon,
} from '@campsite-bio/component-lib';
import anime from 'animejs';
import { toPng } from 'html-to-image';
import axios from 'axios';
import { captureException } from '@sentry/browser';

import { localAxios } from '../../../../apis';
import { useStore } from '../../../../compositions';
import { dataURItoBlob, getAppSetting, timeout } from '../../../../utils';
import { useDataFromWrapped } from './use-data-from-wrapped';
import { FONT_EMBED } from './fonts';
import { getSignedUrl } from '../../../../utils/media';
import toast from '../../../../utils/toast';

export default {
  components: {
    Logo,
    InstagramIcon,
    IconButton,
    CloseIcon,
  },

  props: {
    profileId: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const root = ref(null);
    const isCreating = ref(false);
    const timeoutValue = ref(null);

    const { wrappedData, name, type } = inject('wrapped');

    const { stories } = useDataFromWrapped({
      wrappedData,
      name,
    });

    const timeline = anime.timeline({
      easing: 'linear',
      loop: true,
    });

    async function track() {
      try {
        const { data } = await localAxios.get(
          `/api/profiles/wrapped/${props.profileId}/${wrappedData.value._id.$oid}`,
        );

        if (data.synced) {
          store.dispatch('analytics/loadChannelAuths');
          emit('synced', data);
          return;
        }
      } catch (e) {
        console.error(e);
      }

      timeoutValue.value = setTimeout(track, 5000);
    }

    async function createShareScreens() {
      await timeout(500);
      try {
        const stories = Array.from(
          root.value.querySelectorAll('.stories__story'),
        );
        const fontEmbedCss = FONT_EMBED;
        // Generate share images
        const imgPromises = stories.map((story) => {
          return toPng(story, { fontEmbedCss, quality: 0.85 });
        });
        const images = await Promise.all(imgPromises);
        // Upload images to S3
        const uploadPromises = images.map((image, i) => {
          return uploadToS3(image, i);
        });
        const urls = await Promise.all(uploadPromises);
        // Update wrapped data with share image urls
        const { data } = await localAxios.put(
          `/api/profiles/wrapped/${props.profileId}/${wrappedData.value._id.$oid}`,
          {
            share_image_urls: urls,
          },
        );
        emit('synced', data);
      } catch (e) {
        console.error(e);
        captureException(e);
        toast.error('Something went wrong. Please try again.');
      }
    }

    async function uploadToS3(imageDataUrl, index) {
      const path = `wrapped/${wrappedData.value._id.$oid}-${index}.png`;
      const url = await getSignedUrl(path, 'image/png');
      const blob = dataURItoBlob(imageDataUrl, 'image/png');
      await axios.put(url, blob, {
        headers: {
          'Content-Type': 'image/png',
        },
      });
      return `${getAppSetting('assetCdn')}/${path}`;
    }

    onMounted(() => {
      if (wrappedData.value.share_image_urls.length > 0) track();
      else createShareScreens();

      const text = root.value.querySelectorAll('.wrapped-intro__text span');
      text.forEach((el) => {
        timeline.add({
          targets: el,
          keyframes: [
            {
              opacity: 1,
              duration: 1000,
            },
            {
              opacity: 0,
              duration: 1000,
              delay: 2500,
            },
          ],
        });
      });
    });

    onBeforeUnmount(() => {
      timeline.pause();
      clearTimeout(timeoutValue.value);
    });

    return { root, isCreating, stories, name, type };
  },
};
</script>

<style lang="scss" scoped>
.wrapped-intro {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: hidden;

  &__close {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  }

  &__text {
    position: relative;
    width: 100%;

    span {
      font-size: 1.5rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      max-width: 90%;
      width: 100%;
      text-align: center;
    }
  }
}

.stories {
  display: flex;
  position: fixed;
  left: 110%;

  &__story {
    width: 1080px;
    height: 1920px;
  }
}
</style>
