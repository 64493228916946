<template>
  <div class="profile-picture">
    <div class="profile-picture__img">
      <Avatar size="100">
        <img
          :src="profileImageSrc"
          alt="Profile image"
          height="100"
          width="100"
        />
      </Avatar>
    </div>
    <div class="profile-picture__btns">
      <Button
        size="small"
        class="js-profile-btn"
        @click="changeProfileImage = true"
        >Select an image</Button
      >
      <Button
        v-if="hasProfileImage"
        size="small"
        color="gray300"
        @click="handleRemove"
        >Remove</Button
      >
    </div>
    <UploadCropper
      v-model="changeProfileImage"
      title="Upload Profile Picture"
      base-file-path="media/profile-images/"
      @uploaded="handleChange"
    />
  </div>
</template>

<script>
import {
  Avatar,
  Button,
  convertImageUrlToEncodedUrl,
} from '@campsite-bio/component-lib';
import { computed, ref, watch } from 'vue';

import UploadCropper from '../upload-cropper';
import { getAppSetting } from '@/utils';

export default {
  components: {
    Avatar,
    Button,
    UploadCropper,
  },

  props: {
    profileImage: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const changeProfileImage = ref(false);

    const profileImageSrc = computed(() => {
      return convertImageUrlToEncodedUrl(props.profileImage);
    });

    const hasProfileImage = computed(() => {
      return props.profileImage !== getAppSetting('defaultProfileImage');
    });

    function handleChange(url) {
      emit('change', url);
    }

    function handleRemove() {
      emit('remove', getAppSetting('defaultProfileImage'));
    }

    return {
      changeProfileImage,
      handleChange,
      profileImageSrc,
      handleRemove,
      hasProfileImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-picture {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  max-width: 500px;

  &__img {
    &::v-deep .v-avatar {
      border: 1px solid var(--g-color-gray-200);
    }
  }

  &__btns {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    column-gap: 1rem;
    row-gap: 0.5rem;
    flex-grow: 1;
  }
}
</style>
