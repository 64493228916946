<template>
  <section class="feature-section" :style="styles" :class="classes">
    <div class="feature-section__container" :class="containerClasses">
      <div class="feature-section__content">
        <VText class="feature-section__title" variant="h2">{{ title }}</VText>
        <div class="feature-section__text">
          <slot name="text" />
        </div>
        <div v-if="$slots.cta" class="feature-section__cta">
          <slot name="cta" />
        </div>
      </div>

      <FeatureGrid>
        <slot />
      </FeatureGrid>
    </div>
  </section>
</template>

<script>
import { VText, useTheme, getThemeColor } from '@campsite-bio/component-lib';
import { computed } from 'vue';

import FeatureGrid from './feature-grid';

export default {
  components: {
    VText,
    FeatureGrid,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    bgColor: {
      type: String,
      default: 'white',
    },

    addContainerClasses: Boolean,
    /** Change the padding of the section to act as if there aren't multiple stacked */
    single: Boolean,
  },

  setup(props) {
    const theme = useTheme();

    const styles = computed(() => ({
      '--c-bg-color': getThemeColor(props.bgColor, theme.value),
    }));

    const classes = computed(() => ({
      'feature-section--single': props.single,
    }));

    const containerClasses = computed(() => ({
      grid: props.addContainerClasses,
      'grid--container': props.addContainerClasses,
    }));

    return { styles, classes, containerClasses };
  },
};
</script>

<style lang="scss" scoped>
.feature-section {
  background-color: var(--c-bg-color);

  &__container {
    padding-bottom: 5.5rem;
    padding-top: 6rem;
    max-width: 1140px;

    .feature-section--single & {
      padding-bottom: 1.5rem;
      padding-top: 1.5rem;
    }
  }

  &__content {
    max-width: 780px;
    margin: 0 auto 3rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  &__text {
    ::v-deep p {
      font-size: 1.25rem;
    }
  }

  &__cta {
    margin-top: 1.5rem;
  }
}

.feature-section__title.feature-section__title {
  margin-bottom: 1rem;
}
</style>
