import { unref } from 'vue';
import { useGradingColors } from './use-grading-colors';

/**
 * Convert an array of numbers into a color grading
 * @param {Array} colors
 * @returns The colors used and a function to get the color for a given index
 */
export function useColorGrading(colors = null) {
  const defaultColors = useGradingColors();

  function getCellColor(stat, allStats) {
    if (stat === 0 || isNaN(stat) || !Array.isArray(unref(allStats))) return '';

    const filteredStats = unref(allStats).filter((s) => s !== 0 && !isNaN(s));

    const index = filteredStats.indexOf(stat);
    const length = filteredStats.length;
    const colors = unref(colors || defaultColors);

    // Get the color to use
    const colorIndex = Math.floor((index / length) * colors.length);
    const color = colors[colorIndex];

    return `background-color: ${color?.backgroundColor}; color: ${color?.color}`;
  }

  return {
    colors: defaultColors,
    getCellColor,
  };
}
