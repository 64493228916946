<template>
  <div class="contents">
    <GridTitle class="print-hide">
      <InstagramIcon />
      {{ appAuth.name }}
      Stats
      <span slot="text">
        This channel has been authorized on the
        <router-link
          :to="`/profile/${appAuth.campsite._id.$oid}/settings`"
          style="color: inherit; text-decoration: none"
        >
          <Avatar size="20">
            <img :src="profileImageSrc" alt="" />
          </Avatar>
          <b>{{ appAuth.app_authorization.campsite.username }}</b>
        </router-link>
        profile.
      </span>
    </GridTitle>
    <ChannelHighlights :data="data" :is-loading="isLoading" />
    <ChannelEngagementScoreCard
      class="span-12"
      :all-data="allData"
      :data="data"
      :is-loading="isLoading"
    />
    <ChannelLineCard
      class="span-12"
      title="Reach and Impressions"
      subtitle="Number of times your Instagram content has been viewed during the selected period"
      :all-data="allData"
      :data="data.ig_profile_data"
      :is-loading="isLoading"
      :metrics="[
        { name: 'Reach', value: 'reach' },
        { name: 'Impressions', value: 'impressions' },
      ]"
    />
    <ChannelLineCard
      class="span-12"
      title="Views and Bio Clicks"
      subtitle="Number of times your Instagram profile has been viewed during the selected period"
      :all-data="allData"
      :data="data.ig_profile_data"
      :is-loading="isLoading"
      :metrics="[
        { name: 'Profile Views', value: 'profile_views' },
        { name: 'Bio link clicks', value: 'website_clicks' },
      ]"
    />
    <ChannelLineCard
      class="span-12"
      title="Followers"
      subtitle="Number of followers for the selected period"
      :all-data="allData"
      :data="data.ig_profile_data"
      :is-loading="isLoading"
      :metrics="{ name: 'Followers', value: 'followers_count' }"
      :annotations="{
        data: data.ig_media_data,
        dateKey: 'timestamp',
      }"
      totals-method="last"
      yaxis-start-at-lowest
    >
      <template slot="no-data"
        >Follower count may not be available until after several days.</template
      >
    </ChannelLineCard>
    <ChannelPostsTable
      class="span-12"
      :all-data="allData"
      :data="data.ig_media_data"
      :is-loading="isLoading"
    />
  </div>
</template>

<script>
import {
  Avatar,
  InstagramIcon,
  convertImageUrlToEncodedUrl,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';

import { GridTitle, ChannelLineCard } from '../..';
import ChannelHighlights from './channel-highlights';
import ChannelPostsTable from './channel-posts-table';
import ChannelEngagementScoreCard from './channel-engagement-score-card';

export default {
  components: {
    Avatar,
    InstagramIcon,
    GridTitle,
    ChannelHighlights,
    ChannelLineCard,
    ChannelPostsTable,
    ChannelEngagementScoreCard,
  },

  props: {
    allData: {
      type: Object,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },

    appAuth: {
      type: Object,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const profileImageSrc = computed(() =>
      convertImageUrlToEncodedUrl(
        props.appAuth.app_authorization.campsite.profile_image,
      ),
    );

    return {
      profileImageSrc,
    };
  },
};
</script>

<style lang="scss" scoped></style>
