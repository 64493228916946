<template>
  <LinkExpander
    :id="`magic-image-${linkId}`"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template slot="header">Magic Images</template>
    <Card style="border: none">
      <VText gutter-bottom
        >We've found these images available on the URL you've provided.</VText
      >
      <div class="magic-grid">
        <MediaItem
          v-for="(image, i) in images"
          :key="i"
          :url="image"
          :alt="`Image ${i + 1}`"
          :checked="selected === image"
          @select="selected = image"
        />
      </div>
      <CardActions style="padding: 1rem 0 0; margin: 0 -0.25rem" align="left">
        <Button :disabled="!selected" :loading="isSaving" @click="save"
          >Use Image</Button
        >
      </CardActions>
    </Card>
  </LinkExpander>
</template>

<script>
import { VText, Card, CardActions, Button } from '@campsite-bio/component-lib';
import { ref, toRefs } from 'vue';

import LinkExpander from './link-expander.vue';
import { useStore } from '../../../compositions';
import MediaItem from '../../media/media-item';
import toast from '../../../utils/toast';

export default {
  components: {
    LinkExpander,
    VText,
    MediaItem,
    Card,
    CardActions,
    Button,
  },

  props: {
    isOpen: Boolean,
    linkId: {
      type: String,
      required: true,
    },

    images: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  setup(props, { emit }) {
    const { linkId } = toRefs(props);
    const store = useStore();
    const selected = ref(null);
    const isSaving = ref(false);

    async function save() {
      isSaving.value = true;

      try {
        await store.dispatch('links/uploadPostImage', {
          url: selected.value,
          id: linkId.value,
        });

        selected.value = null;
        emit('close');
      } catch (e) {
        console.error(e);
        store.commit('links/updateLinkImage', {
          value: null,
          id: linkId.value,
        });
        toast.error('There was an error trying to upload your link image');
      }

      isSaving.value = false;
    }

    return { selected, save, isSaving };
  },
};
</script>

<style lang="scss" scoped>
.magic-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 240px));
  gap: 0.5rem;
}
</style>
