<template>
  <v-menu
    v-model="display"
    :close-on-content-click="false"
    persistent
    :max-width="width"
    style="width: 100%"
    offset-y
  >
    <template #activator="{ on, attrs }">
      <Input
        :id="inputId"
        :value="formattedDatetime"
        readonly
        v-bind="attrs"
        v-on="on"
      >
        <CalendarIcon slot="prepend" />
      </Input>
    </template>

    <Card>
      <CardText style="padding: 0">
        <Tabs v-model="activeTab" fixed-tabs>
          <TabBar aria-label="tabs">
            <Tab value="calendar">
              <EventIcon size="1.5rem" />
            </Tab>
            <Tab value="timer" :disabled="!dateSelected">
              <AccessTimeIcon size="1.5rem" />
            </Tab>
          </TabBar>
          <TabPanel value="calendar">
            <v-date-picker
              v-model="datePart"
              scrollable
              :locale="locale"
              show-current
              :min="minDate"
              actions
            />
          </TabPanel>
          <TabPanel value="timer">
            <v-time-picker
              ref="timer"
              v-model="timePart"
              class="v-time-picker-custom"
              scrollable
              :min="minTime"
              :format="timePickerFormat"
              actions
            />
          </TabPanel>
        </Tabs>
      </CardText>
      <CardActions>
        <Button variant="flat" @click="clearHandler">Clear</Button>
        <Button color="success" variant="flat" @click="okHandler">OK</Button>
      </CardActions>
    </Card>
  </v-menu>
</template>

<script>
// https://github.com/darrenfang/vuetify-datetime-picker/blob/master/src/components/DatetimePicker.vue
import { DateTime } from 'luxon';

import {
  Card,
  CardText,
  CardActions,
  Button,
  Input,
  CalendarIcon,
  Tabs,
  TabBar,
  Tab,
  TabPanel,
  EventIcon,
  AccessTimeIcon,
} from '@campsite-bio/component-lib';

const DEFAULT_TIME_FORMAT = 'HH:mm';
const DEFAULT_TIME = '00:00';

export default {
  components: {
    Card,
    CardText,
    CardActions,
    Button,
    Input,
    CalendarIcon,
    Tabs,
    TabBar,
    Tab,
    TabPanel,
    EventIcon,
    AccessTimeIcon,
  },

  props: {
    inputId: {
      type: String,
      default: undefined,
    },
    value: {
      type: String,
      default: undefined,
    },
    width: {
      type: Number,
      default: 320,
    },
    format: {
      type: String,
      default: 'yyyy-MM-dd hh:mm a',
    },
    timePickerFormat: {
      type: String,
      default: 'ampm',
    },
    locale: {
      type: String,
      default: 'en-us',
    },
    minDate: {
      type: String,
      default: '',
    },
    minTime: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      display: false,
      dateSelected: false,
      timeSelected: false,
      activeTab: 'calendar',
      selectedDatetime: undefined,
    };
  },

  computed: {
    datePart: {
      get() {
        let val = this.selectedDatetime
          ? this.selectedDatetime.toISODate()
          : this.minDate
          ? this.minDate
          : DateTime.local().toISODate();
        if (this.selectedDatetime) this.dateSelected = true;
        return val;
      },
      set(val) {
        this.dateSelected = true;
        this.activeTab = 'timer';
        let hours = this.selectedDatetime ? this.selectedDatetime.hour : 0;
        let minutes = this.selectedDatetime ? this.selectedDatetime.minute : 0;
        let date = DateTime.fromISO(
          `${val}T${hours < 10 ? '0' : ''}${hours}:${
            minutes < 10 ? '0' : ''
          }${minutes}`,
        );
        this.selectedDatetime = date;
      },
    },

    timePart: {
      get() {
        let val = this.selectedDatetime
          ? this.selectedDatetime.toFormat(DEFAULT_TIME_FORMAT)
          : DEFAULT_TIME;
        return val;
      },
      set(val) {
        this.timeSelected = true;
        this.selectedDatetime = DateTime.fromISO(`${this.datePart}T${val}:00`);
      },
    },

    formattedDatetime() {
      return this.selectedDatetime
        ? this.selectedDatetime.toFormat(this.format)
        : '';
    },
  },

  watch: {
    value(newValue) {
      this.selectedDatetime = newValue ? DateTime.fromISO(newValue) : newValue;
    },

    display(newValue) {
      if (!newValue)
        this.$emit(
          'input',
          this.selectedDatetime
            ? this.selectedDatetime.toFormat("yyyy-MM-dd'T'HH:mm")
            : this.selectedDatetime,
        );
    },
  },

  created() {
    this.selectedDatetime = this.value
      ? DateTime.fromISO(this.value)
      : this.value;
  },

  methods: {
    okHandler() {
      this.display = false;
      if (this.selectedDatetime) {
        this.activeTab = 'calendar';
        if (this.$refs.timer) {
          this.$refs.timer.selectingHour = true;
        }
        this.$emit(
          'input',
          this.selectedDatetime.toFormat("yyyy-MM-dd'T'HH:mm"),
        );
      }
    },

    clearHandler() {
      this.selectedDatetime = undefined;
      this.display = false;
      this.activeTab = 'calendar';
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
      this.dateSelected = false;
      this.timeSelected = false;
    },

    clear() {
      this.clearHandler();
    },
  },
};
</script>

<style>
.v-date-picker-title__date > div {
  top: -4px;
}
</style>

<style lang="scss" scoped>
.v-card__actions .v-button {
  margin-bottom: 0;
  margin-top: 0;
}
</style>
