<template>
  <div class="three-columns__item">
    <div class="three-columns__item__icon">
      <FancyIconCircle size="1.25rem">
        <slot name="icon" />
      </FancyIconCircle>
    </div>
    <div class="three-columns__item__content">
      <VText class="three-columns__item__title" component="h3" variant="h4">
        <slot />
      </VText>
      <slot name="text" />
    </div>
  </div>
</template>

<script>
import { FancyIconCircle, VText } from '@campsite-bio/component-lib';

export default {
  components: {
    FancyIconCircle,
    VText,
  },
};
</script>

<style lang="scss" scoped>
.three-columns {
  &__item {
    display: flex;

    &__icon {
      flex-shrink: 0;
      margin-right: 0.75rem;
    }
  }
}

.three-columns__item__title.three-columns__item__title {
  margin-bottom: 0.25rem;
}
</style>
