<template>
  <ItemImg
    component="button"
    type="button"
    v-bind="$props"
    aria-label="edit image"
    @click="$emit('click', $event)"
  />
</template>

<script>
import { convertImageUrlToEncodedUrl } from '@campsite-bio/component-lib';
import { computed, ref } from 'vue';

import ItemImg from './item-img';

export default {
  components: {
    ItemImg,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },

    linkId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const isOpen = ref(false);

    const imageUrl = computed(() => {
      return convertImageUrlToEncodedUrl(props.item.image.url);
    });

    return { isOpen, imageUrl };
  },
};
</script>

<style lang="scss" scoped></style>
