<template>
  <div class="color-option">
    <ColorPicker
      v-model="localValue"
      :input-id="_uid"
      :options="colorOptions"
    />

    <v-tooltip v-if="!hideRemove" right>
      <template #activator="{ on, attrs }">
        <IconButton
          :aria-label="`Remove color: ${localValue}`"
          v-bind="attrs"
          v-on="on"
          @click="$emit('remove', value)"
        >
          <MinusIcon />
        </IconButton>
      </template>
      <span>Remove color</span>
    </v-tooltip>
    <v-tooltip right>
      <template #activator="{ on, attrs }">
        <IconButton
          :class="{ 'hide-icon': !showAdd }"
          aria-label="Add color"
          v-bind="attrs"
          v-on="on"
          @click="$emit('add')"
        >
          <PlusIcon />
        </IconButton>
      </template>
      <span>Add color</span>
    </v-tooltip>
  </div>
</template>

<script>
import { toRefs } from 'vue';
import { IconButton, MinusIcon, PlusIcon } from '@campsite-bio/component-lib';

import { useModel } from '../../../compositions';
import ColorPicker from '../../form/color-picker';

export default {
  components: {
    IconButton,
    MinusIcon,
    PlusIcon,
    ColorPicker,
  },

  props: {
    value: {
      type: String,
      default: null,
    },

    index: {
      type: Number,
      required: true,
    },

    colorOptions: {
      type: Object,
      required: true,
    },

    hideRemove: Boolean,
    showAdd: Boolean,
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const localValue = useModel(value, (val) =>
      emit('input', val, props.index),
    );

    return { localValue };
  },
};
</script>

<style lang="scss" scoped>
.color-option {
  align-items: center;
  display: flex;
  gap: 0.25rem;

  &::v-deep .v-icon-btn {
    flex-shrink: 0;
  }
}

.hide-icon.hide-icon {
  opacity: 0;
  pointer-events: none;
}
</style>
