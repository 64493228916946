<template>
  <div class="media-selector" :class="allClasses">
    <button
      class="media-selector__btn"
      title="Select an Image"
      type="button"
      :disabled="disabled || loading"
      :style="styles"
      @click="showModal"
    >
      <ImageIcon v-show="!hasPostImage && !hasFutureImage" />
      <div v-if="isIcon" class="media-selector__icon" v-html="value" />
      <img
        v-else-if="hasPostImage && !pattern"
        class="media-selector__img"
        :src="imageUrl"
        alt="Link image"
      />
      <span v-if="hasFutureImage" class="media-selector__future">
        <span class="media-selector__future-inner">
          <UpdateIcon />
          <span>Upcoming post</span>
        </span>
      </span>
      <span v-if="loading" class="media-selector__loader">
        <CircularLoader size="2rem" />
      </span>
    </button>
    <button
      v-if="(hasPostImage || hasFutureImage) && !disabled"
      class="media-selector__remove"
      aria-label="Remove image"
      type="button"
      :disabled="loading"
      @click.stop="$emit('remove')"
    >
      <CloseIcon />
    </button>
  </div>
</template>

<script>
import {
  ImageIcon,
  UpdateIcon,
  CloseIcon,
  CircularLoader,
  convertImageUrlToEncodedUrl,
  isImageIcon,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';

export default {
  name: 'VMediaSelector',

  components: {
    ImageIcon,
    UpdateIcon,
    CloseIcon,
    CircularLoader,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
    hasFutureImage: {
      type: Boolean,
      default: false,
    },
    media: { type: [String, Array], default: 'all' },
    mediaOptions: { type: Object, default: null },
    classes: { type: Object, default: null },
    title: { type: String, default: null },
    disabled: Boolean,
    hasError: Boolean,
    pattern: Boolean,
    loading: Boolean,
  },

  setup(props) {
    const isIcon = computed(() => isImageIcon(props.value));
    const imageUrl = computed(() => {
      return convertImageUrlToEncodedUrl(props.value);
    });

    return {
      isIcon,
      imageUrl,
    };
  },

  computed: {
    hasPostImage() {
      return !this.hasFutureImage && !!this.value;
    },

    allClasses() {
      return {
        'media-selector--selected': this.hasPostImage,
        'media-selector--future': this.hasFutureImage,
        'media-selector--error': this.hasError,
        ...this.classes,
      };
    },

    styles() {
      return {
        background: this.pattern ? `#fff url('${this.value}') repeat` : '',
      };
    },
  },

  methods: {
    showModal: function () {
      this.$store.dispatch('openMediaDialog', {
        media: this.media,
        title: this.title,
        mediaOptions: this.mediaOptions,
        onSelect: this.onSelect,
        onCancel: this.onCancel,
      });
    },

    onSelect(media) {
      this.$emit('select', media);
    },

    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.media-selector {
  height: 6.25rem;
  position: relative;
  width: 6.25rem;
}

.media-selector__btn {
  align-items: center;
  background: none;
  border: 2px dashed var(--g-color-gray-400);
  border-radius: var(--g-border-radius-standard);
  border-radius: min(
    var(--g-border-radius-standard),
    var(--g-border-radius-min)
  );
  cursor: pointer;
  color: var(--g-color-gray-400);
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0;
  transition: all 250ms;
  width: 100%;
  overflow: hidden;

  &:hover {
    border-color: var(--g-color-primary);
    color: var(--g-color-primary);
  }

  &:disabled {
    border-color: var(--g-color-gray-300);
    cursor: default;
    color: var(--g-color-gray-300);
    opacity: 0.75;
  }

  &::v-deep .v-svg-icon {
    font-size: 1.875rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.media-selector--selected .media-selector__btn,
.media-selector--future .media-selector__btn {
  border-color: var(--g-color-primary);
  border-style: solid;
  color: var(--g-color-primary);
}

.media-selector--error .media-selector__btn {
  border-color: var(--g-color-error);
  color: var(--g-color-error);
}

.media-selector__img,
.media-selector__future,
.media-selector__icon {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: relative;
  pointer-events: none;
}

.media-selector__img,
.media-selector__icon,
.media-selector__icon ::v-deep svg {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.media-selector__icon ::v-deep svg {
  display: inline-block;
  color: var(--g-color-on-bg);
  stroke-width: 2;
  fill: none;
}

.media-selector__icon {
  padding: 1rem;
}

.media-selector__future {
  background: var(--g-color-gray-200);
  border-radius: inherit;
  bottom: 2px;
  font-size: 0.6875rem;
  left: 2px;
  position: absolute;
  text-align: center;
  right: 2px;
  top: 2px;

  .v-svg-icon.v-svg-icon {
    color: var(--g-color-primary);
    font-size: 1.875rem;
    position: static;
    transform: none;
  }

  span {
    display: block;
    margin-top: 0.125rem;
    padding: 0 0.125rem;
  }
}

.media-selector__future-inner {
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.media-selector__remove {
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  border: none;
  cursor: pointer;
  color: var(--g-color-error);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  height: 1.5rem;
  padding: 0;
  position: absolute;
  right: 0.175rem;
  top: 0.175rem;
  transition: all 300ms;
  width: 1.5rem;

  &:hover,
  &:focus {
    background: darken(#fff, 10%);
  }

  &:before {
    content: '';
    height: 2.25rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2.25rem;
  }

  &::v-deep .v-svg-icon {
    font-size: 1rem;
    position: relative;
  }
}

.media-selector__loader {
  display: inline-flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
