import { computed, unref } from 'vue';

import store from '@/store';
import { ORG_ROLE_OWNER } from '@/config';

/**
 * Get the current user's organization entitlements
 * @param {Object} organization
 * @param {Boolean} useStore
 * @returns {Object}
 */
export function useCurrentUserOrgEntitlements(
  organization = null,
  useStore = true,
) {
  const currentOrganization = computed(() => {
    return unref(useStore)
      ? store.getters['organizations/getField']('currentOrganization') || {}
      : unref(organization);
  });

  const isOwner = computed(() => {
    return currentOrganization.value?.role === ORG_ROLE_OWNER;
  });

  return {
    isOwner,
  };
}
