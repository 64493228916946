import { default as StandardIcon } from './standard-icon';
import { default as PopupIcon } from './popup-icon';
import { default as SliderIcon } from './slider-icon';
import { default as ExpandIcon } from './expand-icon';

export const LAYOUT_ICONS = {
  StandardIcon,
  PopupIcon,
  SliderIcon,
  ExpandIcon,
};
