import { isValidUrl } from '../../utils';

export const linkUrl = {
  name: 'linkUrl',

  message: 'The {_field_} field is not a valid URL',

  validate(value) {
    const { isAccepted, reason } = isValidUrl(value, {
      protocols: ['https:', 'http:', 'mailto:', 'sms:', 'tel:'],
    });

    if (!isAccepted)
      return `The {_field_} field is not a valid URL (${reason})`;

    return true;
  },
};
