import { computed } from 'vue';
import { DateTime } from 'luxon';
import { useStore } from '../../../compositions';

export function useSelectedDateGranularity() {
  const store = useStore();
  const selectedDateFilter = computed(
    () => store.getters['analytics/selectedDateFilter'],
  );

  return computed(() => {
    // Handle custom date selection granularity
    if (Array.isArray(selectedDateFilter.value)) {
      const startDate = DateTime.fromISO(selectedDateFilter.value[0]);
      const endDate = DateTime.fromISO(selectedDateFilter.value[1]);
      const diff = endDate.diff(startDate, ['days', 'months']);

      if (diff.months > 3) {
        return 'month';
      } else if (diff.days < 2) {
        return 'hour';
      }

      return 'date';
    }

    if (['yesterday', 'today'].includes(selectedDateFilter.value))
      return 'hour';

    // if greater than three months, show month granularity
    if (['ytd'].includes(selectedDateFilter.value)) {
      const now = DateTime.local();
      // start of year
      const startOfYear = DateTime.fromObject({
        year: now.year,
        month: 1,
        day: 1,
      });
      const diff = now.diff(startOfYear, 'months');
      if (diff.months > 3) {
        return 'month';
      }
    }

    return 'date';
  });
}
