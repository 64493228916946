<template>
  <div class="news">
    <div class="grid grid--container">
      <div class="news__top">
        <VText variant="h3" component="h2">The latest news and updates</VText>
      </div>

      <div ref="grid" class="news__grid">
        <template v-if="isLoading">
          <Skeleton v-for="i in 8" :key="i" width="100%" height="280px" />
        </template>
        <template v-else>
          <NewsItem
            v-for="newsItem in newsItems"
            :key="newsItem.guid"
            :item="newsItem"
          />
        </template>
      </div>

      <VText
        variant="h3"
        component="h2"
        style="margin-top: 3rem; margin-bottom: 1rem"
        >Resources</VText
      >
      <div class="resources">
        <div>
          <InlineLink href="https://campsite.bio/blog">
            Blog&nbsp;<ExternalLinkIcon />
          </InlineLink>
          <VText>Check out our latest blog posts</VText>
        </div>
        <div>
          <InlineLink href="https://headwayapp.co/campsite-bio-changelog">
            Changelog&nbsp;<ExternalLinkIcon />
          </InlineLink>
          <VText>Keep up to date with our latest changes</VText>
        </div>
        <div>
          <InlineLink href="https://support.campsite.bio/en/">
            Help Articles&nbsp;<ExternalLinkIcon />
          </InlineLink>
          <VText>Advice and answers from the Campsite.bio team</VText>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import {
  VText,
  InlineLink,
  ExternalLinkIcon,
  Skeleton,
} from '@campsite-bio/component-lib';
import { useIntersectionObserver } from '@vueuse/core';

import NewsItem from './news-item';
import { localAxios } from '../../apis';

export default {
  components: {
    VText,
    NewsItem,
    InlineLink,
    ExternalLinkIcon,
    Skeleton,
  },

  setup() {
    const newsItems = ref(null);
    const isLoading = ref(true);
    const grid = ref(null);

    const { stop } = useIntersectionObserver(
      grid,
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          stop();
          load();
        }
      },
      {
        threshold: 0.1,
      },
    );

    async function load() {
      isLoading.value = true;
      try {
        const { data } = await localAxios.get('/api/news');
        newsItems.value = data.items;
      } catch (e) {
        console.error(e);
      }
      isLoading.value = false;
    }

    return { grid, newsItems, isLoading };
  },
};
</script>

<style lang="scss" scoped>
.news {
  background: var(--g-color-gray-100);
  padding-bottom: 2rem;
  padding-top: 2rem;
  flex-grow: 1;

  @media (min-width: 992px) {
    padding-bottom: 3rem;
    padding-top: 3rem;
  }

  &__top {
    margin-bottom: 1.5rem;
  }

  &__grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

.resources {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
