<template>
  <div v-if="showNotifications" class="notifications">
    <Skeleton v-if="isLoading" width="100%" height="118px" />
    <template v-else>
      <transition name="fade" mode="out-in">
        <template v-for="(notification, i) in notifications">
          <NotificationCard
            v-if="i === notificationIndex"
            :key="
              typeof notification._id === 'object'
                ? notification._id.$oid
                : notification._id
            "
            :notification="notification"
            @dismissed="load"
          />
        </template>
      </transition>
    </template>

    <div v-if="!isLoading" class="notifications__nav">
      <IconButton
        class="notifications__nav__btn"
        :disabled="isPrevDisabled"
        size="1.25rem"
        @click="prev"
      >
        <ChevronIcon direction="left" />
      </IconButton>
      <span class="notifications__nav__current"
        >{{ notificationIndex + 1 }} / {{ notifications.length }}</span
      >
      <IconButton
        class="notifications__nav__btn"
        :disabled="isNextDisabled"
        size="1.25rem"
        @click="next"
      >
        <ChevronIcon direction="right" />
      </IconButton>
    </div>
  </div>
</template>

<script>
import { IconButton, ChevronIcon, Skeleton } from '@campsite-bio/component-lib';
import { computed, ref, onMounted, watch } from 'vue';

import { NotificationCard } from '../../../notifications';
import { useStore } from '../../../../compositions';

export default {
  components: {
    NotificationCard,
    IconButton,
    ChevronIcon,
    Skeleton,
  },

  setup() {
    const notificationIndex = ref(0);
    const isLoading = ref(false);

    const store = useStore();
    const notifications = computed(() =>
      store.getters['profiles/getField']('notifications'),
    );

    const isNextDisabled = computed(
      () => notificationIndex.value >= notifications.value.length - 1,
    );
    const isPrevDisabled = computed(() => notificationIndex.value <= 0);

    const showNotifications = computed(
      () => notifications.value.length > 0 || isLoading.value,
    );

    async function load() {
      isLoading.value = true;

      try {
        await store.dispatch('profiles/getNotifications');
      } catch (e) {
        console.error(e);
      } finally {
        isLoading.value = false;
      }
    }

    function next() {
      if (notificationIndex.value < notifications.value.length - 1) {
        notificationIndex.value += 1;
      }
    }

    function prev() {
      if (notificationIndex.value > 0) {
        notificationIndex.value -= 1;
      }
    }

    // Reset the notification index when the notifications change
    watch(notifications, () => {
      notificationIndex.value = 0;
    });

    onMounted(load);

    return {
      isLoading,
      load,
      notifications,
      notificationIndex,
      next,
      prev,
      isNextDisabled,
      isPrevDisabled,
      showNotifications,
    };
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  position: relative;

  &__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 0.5rem;
    font-size: 0.825rem;

    position: absolute;
    bottom: 0.125rem;
    right: 0.125rem;
    z-index: 1;
  }
}

.notifications__nav__btn.notifications__nav__btn {
  &[disabled] {
    background: none !important;
  }
}
</style>
