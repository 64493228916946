import { captureException } from '@sentry/browser';

import { localAxios } from '../apis';

export const USER_ACTIVITY_TYPES = {
  REPORT_VIEW: 'REPORT_VIEW',
  PAGE_VIEW: 'PAGE_VIEW',
  EXPORT_DATA: 'EXPORT_DATA',
};

export const PROFILE_ACTIVITY_TYPES = {
  REPORT_VIEW: 'REPORT_VIEW',
  ITW_PLAY: 'ITW_PLAY',
  EXPORT_DATA: 'EXPORT_DATA',
};

async function log(endpoint, type, data) {
  try {
    await localAxios.post(endpoint, {
      type,
      data,
    });
  } catch (e) {
    console.error(e);
    captureException(e);
  }
}

export async function userLog(userId, type, data) {
  // Make sure type is valid
  if (!Object.values(USER_ACTIVITY_TYPES).includes(type)) {
    throw new Error(`Invalid activity type: ${type}`);
  }

  const endpoint = `/api/users/${userId}/log`;

  await log(endpoint, type, data);
}
