<template>
  <div class="grid grid--container">
    <div class="row">
      <div class="col col--centered" :style="`max-width: ${maxWidth}`">
        <Card>
          <CardText class="card__text--padded">
            <slot />
          </CardText>
          <slot name="actions" />
        </Card>
        <slot name="after" />
      </div>
    </div>
  </div>
</template>

<script>
import { Card, CardText } from '@campsite-bio/component-lib';

export default {
  components: {
    Card,
    CardText,
  },

  props: {
    maxWidth: {
      type: String,
      default: '600px',
    },
  },
};
</script>

<style lang="scss" scoped></style>
