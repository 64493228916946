import Vue from 'vue';
import VueMeta from 'vue-meta';
Vue.use(VueMeta, {
  keyName: 'head',
  tagIDKeyName: 'hid',
});
import {
  installFilters,
  PREVIEW_COMPONENT_MAPPING,
} from '@campsite-bio/component-lib';
import vuetify from '../src/plugins/vuetify';
import App from '../src/app.vue';
import router from '../src/router';
import store from '../src/store';
import FormActions from '../src/components/form/form-actions.vue';
import VMediaDialog from '../src/components/media/media-dialog.vue';
import VMediaSelector from '../src/components/media/media-selector.vue';
import OptInFormWrapper from '../src/components/preview/opt-in-form-wrapper';
import VCode from '../src/components/code.vue';
import '../src/utils/cookies';
// directives
import '../src/directives/clipboard';
import '../src/directives/focus';
import '../src/directives/infinite-scroll';
// filters
import filters from '../src/filters/';
Vue.use(filters);
Vue.use(installFilters);
import 'dropzone/dist/dropzone.css';
import 'cropperjs/dist/cropper.min.css';
// polyfill
import '../src/utils/cssrelpreload';
import { initSentry } from '../src/utils/sentry';
import '../src/apis/axios';
import { startup } from '../src/startup';
import '../scss/app.scss';

Vue.config.productionTip = false;
Vue.component(FormActions.name, FormActions);
Vue.component(VMediaDialog.name, VMediaDialog);
Vue.component(VMediaSelector.name, VMediaSelector);
Vue.component(VCode.name, VCode);

// Make the preview use our opt in form wrapper
Vue.component(OptInFormWrapper.name, OptInFormWrapper);
PREVIEW_COMPONENT_MAPPING['opt-in-form'] = OptInFormWrapper.name;

initSentry();

function bootApp() {
  new Vue({
    el: '#app',
    store,
    router,
    vuetify,
    render: (h) => h(App),
  });

  startup();
}

async function initApp() {
  store.commit('setCSRFToken');
  try {
    // fetch user data before starting app
    await store.dispatch('init');

    bootApp();
  } catch (e) {
    if (e.response?.status !== 401) console.error(e);
    if (e.response.status === 401) bootApp();
  }
}
initApp();
