import { computed } from 'vue';

export function useSubscriptionCost({
  invoice,
  planCost,
  seatPrice,
  profilePrice,
  analyticsPrice,
  users,
  profiles,
  analyticsAddon,
  isAnalyticsAddonIncluded,
}) {
  const taxRate = computed(() => {
    if (!invoice?.value?.tax) return null;

    const taxAmounts = invoice.value?.total_tax_amounts;
    return taxAmounts?.length ? taxAmounts[0].tax_rate : null;
  });

  const taxPercentage = computed(() => {
    return taxRate.value?.percentage || 0;
  });

  const subscriptionCostBeforeTaxes = computed(() => {
    return (
      planCost.value +
      seatPrice.value.price * users.value +
      profilePrice.value.price * profiles.value +
      (analyticsAddon?.value && !isAnalyticsAddonIncluded.value
        ? analyticsPrice.value.price
        : 0)
    );
  });

  const subscriptionTaxes = computed(() => {
    if (!taxPercentage.value)
      return {
        name: 'No taxes',
        amount: 0,
      };

    return {
      name: `${taxRate.value?.display_name}: ${taxPercentage.value}%`,
      amount: Math.round(
        subscriptionCostBeforeTaxes.value * (taxPercentage.value / 100),
      ),
    };
  });

  const subscriptionCost = computed(() => {
    return subscriptionCostBeforeTaxes.value + subscriptionTaxes.value.amount;
  });

  return {
    subscriptionCostBeforeTaxes,
    subscriptionCost,
    subscriptionTaxes,
  };
}
