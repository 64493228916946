const mergeFieldDefaults = {
  canEditFieldName: false,
  hideFieldDefault: true,
  canAddCustomField: false,
  fieldName: {
    label: 'Name',
    helpText: null,
    rules: {
      max: 20,
    },
    pattern: null,
  },
  defaults: [],
};

const MAILCHIMP_MERGE_FIELDS = {
  ...mergeFieldDefaults,
  canEditFieldName: true,
  hideFieldDefault: false,
  canAddCustomField: true,
  fieldName: {
    label: 'Field Tag',
    helpText: 'Only alpha and numeric characters allowed (ex. FNAME)',
    rules: {
      max: 10,
      regex: /^[A-Z0-9]+/,
    },
    pattern: '[A-Z0-9]+',
  },
  defaults: [
    {
      label: 'First Name',
      name: 'FNAME',
      required: true,
    },
    {
      label: 'Last Name',
      name: 'LNAME',
      required: true,
    },
    {
      label: 'Phone Number',
      name: 'PHONE',
      required: true,
      type: 'email',
    },
    {
      label: 'Email Address',
      name: 'EMAIL',
      required: true,
      type: 'sms',
    },
  ],
};

const KLAVIYO_MERGE_FIELDS = {
  ...mergeFieldDefaults,
  defaults: [
    {
      label: 'First Name',
      name: '$first_name',
      required: true,
    },
    {
      label: 'Last Name',
      name: '$last_name',
      required: true,
    },
    {
      label: 'Phone Number',
      name: '$phone_number',
      required: true,
      type: 'email',
    },
    {
      label: 'Email Address',
      name: '$email',
      required: true,
      type: 'sms',
    },
  ],
};

const ACTIVE_CAMPAIGN_MERGE_FIELDS = {
  ...mergeFieldDefaults,
  defaults: [
    {
      label: 'First Name',
      name: 'firstName',
      required: true,
    },
    {
      label: 'Last Name',
      name: 'lastName',
      required: true,
    },
    {
      label: 'Phone Number',
      name: 'phone',
      required: true,
    },
  ],
};

const MAILER_LITE_MERGE_FIELDS = {
  ...mergeFieldDefaults,
  defaults: [
    {
      label: 'First Name',
      name: 'name',
      required: true,
    },
    {
      label: 'Last Name',
      name: 'last_name',
      required: true,
    },
    {
      label: 'Phone Number',
      name: 'phone',
      required: true,
    },
  ],
};

const CONVERT_KIT_MERGE_FIELDS = {
  ...mergeFieldDefaults,
  defaults: [
    {
      label: 'First Name',
      name: 'first_name',
      required: true,
    },
  ],
};

const OMNISEND_MERGE_FIELDS = {
  ...mergeFieldDefaults,
  defaults: [
    {
      label: 'First Name',
      name: 'firstName',
      required: true,
    },
    {
      label: 'Last Name',
      name: 'lastName',
      required: true,
    },
    {
      label: 'Phone Number',
      name: 'phone',
      required: true,
      type: 'email',
    },
    {
      label: 'Email Address',
      name: 'email',
      required: true,
      type: 'sms',
    },
  ],
};

const ZAPIER_MERGE_FIELDS = {
  ...mergeFieldDefaults,
  defaults: [
    {
      label: 'First Name',
      name: 'firstName',
      required: true,
    },
    {
      label: 'Last Name',
      name: 'lastName',
      required: true,
    },
    {
      label: 'Phone Number',
      name: 'phone',
      required: true,
      type: 'email',
    },
    {
      label: 'Email Address',
      name: 'email',
      required: true,
      type: 'sms',
    },
  ],
};

export function getMergeFieldsBySource(source) {
  switch (source) {
    case 'mailchimp':
    case 'google':
      return MAILCHIMP_MERGE_FIELDS;
    case 'klaviyo':
      return KLAVIYO_MERGE_FIELDS;
    case 'active_campaign':
      return ACTIVE_CAMPAIGN_MERGE_FIELDS;
    case 'mailer_lite':
      return MAILER_LITE_MERGE_FIELDS;
    case 'omnisend':
      return OMNISEND_MERGE_FIELDS;
    case 'convert_kit':
      return CONVERT_KIT_MERGE_FIELDS;
    case 'zapier':
      return ZAPIER_MERGE_FIELDS;
    default:
      return mergeFieldDefaults;
  }
}
