<template>
  <MultiStepDialog
    v-model="isOpen"
    max-width="850px"
    :steps="steps"
    scrollable
    :fullscreen="!isLargeScreen"
  >
    <template #activator="data">
      <slot name="activator" v-bind="data" />
    </template>
  </MultiStepDialog>
</template>

<script>
import { computed, ref, toRefs } from 'vue';
import { useMediaQuery } from '@vueuse/core';

import { useModel } from '../../../../../compositions';
import { Items, ItemEditor } from './steps';

import { MultiStepDialog } from '../../../../multi-step-dialog';

export default {
  components: {
    MultiStepDialog,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },

    totalItems: {
      type: Number,
      required: true,
    },

    link: {
      type: Object,
      required: true,
    },

    linkId: {
      type: String,
      required: true,
    },

    value: Boolean,
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const isOpen = useModel(value, (val) => emit('input', val));
    const selectedItemId = ref(null);
    const selectedItem = computed(() => {
      return props.items.find((item) => item._id.$oid === selectedItemId.value);
    });
    const isLargeScreen = useMediaQuery('(min-width: 544px)');

    const steps = computed(() => {
      return [
        {
          path: '',
          component: Items,
          title: 'Edit Items',
          props: {
            items: props.items,
            link: props.link,
            linkId: props.linkId,
            onSelect: (item) => {
              selectedItemId.value = item._id.$oid;
            },
          },
        },
        {
          path: 'editor',
          component: ItemEditor,
          title: 'Edit item',
          props: {
            linkId: props.linkId,
            item: selectedItem,
          },
        },
      ];
    });

    return {
      isOpen,
      steps,
      isLargeScreen,
    };
  },
};
</script>

<style lang="scss" scoped>
.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.25rem;
  padding: 1rem;
}
</style>
