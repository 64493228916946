<template>
  <Story ref="root" @on-ready="start">
    <div class="circle" />
    <div class="circle" />
    <div class="circle" />
    <div class="circle" />
    <div class="circle" />
    <div class="last notlast spin">
      <svg viewBox="0 0 398 395.66">
        <path
          d="M329.78,247.53c-2.41-5.08-4.51-10.17-6.28-15.57,4.17-3.6,8.38-6.87,13.18-10.17,17.03-11.66,37.7-19.47,61.32-23.36-23.33-3.6-43.95-11.66-61.01-23.63-4.51-2.71-9-6.28-12.86-9.88,1.46-5.08,3.57-10.17,5.96-15.27,8.68-17.94,22.15-35.01,40.11-49.67-17.66,6.28-35.6,9.58-52.95,9.58-3.6,0-6.9,0-10.17-.61-5.69-.29-11.08-1.2-16.19-2.39-1.46-5.37-2.37-10.77-2.37-16.16-1.5-20.05,1.78-41.59,9.85-63.74-14.64,17.66-31.68,31.44-50.26,40.41-4.8,2.39-9.88,4.19-14.96,5.99-3.85-3.9-7.47-8.38-10.47-12.88-11.41-16.49-19.2-36.83-23.06-60.19-3.92,23.36-11.98,43.41-23.35,59.85-3.3,4.51-6.58,8.68-10.49,12.59-5.08-1.51-10.17-3.6-14.96-5.99-18.53-8.97-35.3-22.76-50.28-40.7,8.06,22.15,11.38,44,9.59,64.03-.29,5.4-1.2,10.79-2.41,15.87-5.37,1.5-10.79,2.39-16.48,2.39-2.98,.61-5.96,.61-8.94,.61-17.66,0-35.63-3.3-53.9-9.88,17.66,14.96,31.12,31.73,39.81,49.67,2.1,5.1,4.21,10.18,5.99,15.27-3.89,3.6-8.38,7.17-13.18,10.49-17.07,11.38-37.42,19.44-61.03,23.04,23.33,3.89,43.96,11.7,60.73,23.65,4.8,3.01,9,6.28,13.15,10.17-1.75,5.12-3.85,10.2-6.24,15.28-8.7,17.94-22.15,34.72-40.09,49.69,17.66-6.6,35.6-9.88,52.95-9.88,3.6,0,6.9,0,10.17,.59,5.67,.29,11.08,1.2,16.16,2.39,1.5,5.08,2.1,10.47,2.39,15.87,1.78,20.35-1.78,41.88-9.88,64.33,14.96-18.23,32.03-32.01,50.29-40.68,4.8-2.41,9.88-4.21,14.96-5.99,3.85,3.89,7.47,8.38,10.47,12.86,11.38,16.44,19.14,36.83,23.03,60.16,3.92-23.33,11.98-43.37,23.35-59.85,3.3-4.46,6.58-8.65,10.5-12.56,5.08,1.51,10.17,3.6,14.96,5.99,18.53,8.97,35.3,22.74,50.28,40.72-8.06-22.15-11.39-43.98-9.59-64.06,.29-5.37,1.19-10.76,2.41-15.84,5.37-1.5,10.76-2.39,16.44-2.39,3.01-.62,5.99-.62,8.97-.62,17.66,0,35.92,3.3,53.9,9.88-17.66-14.68-31.16-31.46-39.5-49.4h0Z"
        />
      </svg>
    </div>
    <div class="last">
      <svg viewBox="0 0 398 398">
        <path
          d="M170.46,370.03c-18.98-19-28.45-28.21-29.58-28.74-1.59-.75-3.42-.78-42.01-.79-30.83,0-40.46-.12-40.85-.51-.37-.37-.5-10.03-.51-40.85-.02-38.6-.05-40.43-.79-42.01-.53-1.12-9.73-10.6-28.74-29.58C12.59,212.18,0,199.34,0,199s12.59-13.18,27.97-28.54c19-18.98,28.21-28.45,28.74-29.58,.75-1.59,.78-3.42,.79-42.01,0-30.83,.12-40.46,.51-40.85,.37-.37,10.03-.5,40.85-.51,38.6-.02,40.43-.05,42.01-.79,1.12-.53,10.6-9.73,29.58-28.74C185.82,12.59,198.66,0,199,0s13.18,12.59,28.54,27.97c18.98,19,28.45,28.21,29.58,28.74,1.59,.75,3.42,.78,42.01,.79,30.83,0,40.46,.12,40.85,.51,.37,.37,.5,10.03,.51,40.85,.02,38.6,.05,40.43,.79,42.01,.53,1.12,9.73,10.6,28.74,29.58,15.37,15.36,27.97,28.2,27.97,28.54s-12.59,13.18-27.97,28.54c-19,18.98-28.21,28.45-28.74,29.58-.75,1.59-.78,3.42-.79,42.01,0,30.83-.12,40.46-.51,40.85-.37,.37-10.03,.5-40.85,.51-38.6,.02-40.43,.05-42.01,.79-1.12,.53-10.6,9.73-29.58,28.74-15.36,15.37-28.2,27.97-28.54,27.97s-13.18-12.59-28.54-27.97Z"
        />
      </svg>
    </div>

    <div class="text">
      <span>2022 Wrapped by Campsite.bio</span>
      <span
        >Welcome to your
        <span class="name" :class="nameClass">{{ name }}</span> wrapped</span
      >
    </div>
  </Story>
</template>

<script>
import anime from 'animejs';
import { computed } from 'vue';

import { useTimelineWithStory } from './use-timeline-with-story';

import Story from './story';

export default {
  components: {
    Story,
  },

  props: {
    isPaused: Boolean,

    name: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { root, timeline } = useTimelineWithStory(props);

    const nameClass = computed(() => {
      return {
        'name--small': props.name.length > 15,
      };
    });

    async function start() {
      const { height, width, $el } = root.value;

      anime({
        targets: $el.querySelector('.spin svg'),
        duration: 10000,
        rotate: [0, 180],
        easing: 'linear',
        loop: true,
      });

      timeline.add({
        targets: $el.querySelectorAll('.circle'),
        delay: anime.stagger(500),
        duration: 1500,
        height: `+=${height * 2}`,
        width: `+=${height * 2}`,
      });

      timeline.add(
        {
          targets: $el.querySelectorAll('.last'),
          delay: anime.stagger(250),
          duration: 1000,
          height: `+=${width * 0.95}`,
          width: `+=${width * 0.95}`,
        },
        '-=1000',
      );

      timeline.add(
        {
          targets: $el.querySelector('.text > span:first-child'),
          keyframes: [
            {
              opacity: 0,
              scale: 0,
              translateX: '-50%',
              translateY: '-50%',
              duration: 0,
            },
            {
              opacity: 1,
              scale: '+=1',
              translateX: '-50%',
              translateY: '-50%',
              duration: 500,
            },
            {
              opacity: 0,
              scale: '-=1',
              translateX: '-50%',
              translateY: '-50%',
              duration: 500,
              delay: 3000,
            },
          ],
        },
        '-=500',
      );

      timeline.add({
        targets: $el.querySelector('.text > span:last-child'),
        keyframes: [
          {
            opacity: 0,
            scale: 0,
            translateX: '-50%',
            translateY: '-50%',
            duration: 0,
          },
          {
            opacity: 1,
            scale: '+=1',
            translateX: '-50%',
            translateY: '-50%',
            duration: 500,
          },
        ],
      });
    }

    return { root, nameClass, start };
  },
};
</script>

<style lang="scss" scoped>
.circle,
.last,
.text > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: top left;
}

.text {
  font-family: var(--g-font-header-family);
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.4;
  color: var(--g-color-on-primary);
  text-align: center;

  > span {
    opacity: 0;
    width: 75%;
    max-width: 450px;
  }
}

.circle,
.last {
  height: 0;
  width: 0;
  border-radius: 50%;

  &:nth-child(1) {
    background: var(--g-color-yellow);
  }

  &:nth-child(2) {
    background: var(--g-color-dark-green);
  }

  &:nth-child(3) {
    background: var(--g-color-orange);
  }

  &:nth-child(4) {
    background: var(--g-color-light-green);
  }

  &:nth-child(5) {
    background: var(--g-color-light-blue);
  }
}

.last svg {
  fill: var(--g-color-primary);
  height: 100%;
  width: 100%;
}

.notlast svg {
  fill: var(--g-color-orange);
  scale: 1.5;
}

.name {
  &--small {
    font-size: 1.25rem;
  }
}
</style>
