<template>
  <List>
    <ListItem
      type="button"
      component="button"
      @click="next('select-collection')"
    >
      <ListItemContent>
        <ListItemTitle>Collection</ListItemTitle>
        <ListItemSubtitle wrap
          >Sync a collection and display up to six products.</ListItemSubtitle
        >
      </ListItemContent>
      <ListItemAction>
        <ChevronIcon size="1.25em" direction="right" />
      </ListItemAction>
    </ListItem>
    <Divider color="gray200" thickness="1" />
    <ListItem type="button" component="button" @click="next('select-products')">
      <ListItemContent>
        <ListItemTitle>Products</ListItemTitle>
        <ListItemSubtitle wrap
          >Display a mix of six individual products.</ListItemSubtitle
        >
      </ListItemContent>
      <ListItemAction>
        <ChevronIcon size="1.25em" direction="right" />
      </ListItemAction>
    </ListItem>
    <Divider color="gray200" thickness="1" />
  </List>
</template>

<script>
import {
  Divider,
  List,
  ListItem,
  ListItemContent,
  ListItemTitle,
  ListItemSubtitle,
  ListItemAction,
  ChevronIcon,
} from '@campsite-bio/component-lib';
import { inject } from 'vue';

export default {
  components: {
    Divider,
    List,
    ListItem,
    ListItemContent,
    ListItemTitle,
    ListItemSubtitle,
    ListItemAction,
    ChevronIcon,
  },

  setup() {
    const { handleStepChange } = inject('multi-step-dialog');

    function next(next) {
      handleStepChange(next);
    }

    return { next };
  },
};
</script>

<style lang="scss" scoped></style>
