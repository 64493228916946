import { load as loadReCaptcha } from 'recaptcha-v3';
import { ref, onMounted } from 'vue';
import config from '../config';

let instance;

async function load() {
  if (!instance) {
    instance = await loadReCaptcha(config.RECAPTCHA_SITE_KEY, {
      useRecaptchaNet: true,
      autoHideBadge: true,
    });
  }
  return instance;
}

export function useCaptcha() {
  const instance = ref(null);

  onMounted(async () => {
    instance.value = await load();
  });

  async function execute(action) {
    await load();
    return instance.value.execute(action);
  }

  return { instance, execute };
}

// import { ref, watchEffect, onMounted } from 'vue';
// import config from '../config';

// export function useCaptcha() {
//   const captcha = ref(null);
//   const captchaEl = ref(null);
//   const captchaProvider = ref(null);

//   function captchaResponse(code) {
//     captcha.value = code;
//     captchaProvider.value.applyResult({
//       errors: [],
//       valid: true,
//       failedRules: {},
//     });
//   }

//   function clearCaptcha() {
//     captcha.value = null;
//     captchaProvider.value.applyResult({
//       errors: [],
//       valid: false,
//       failedRules: {},
//     });
//   }

//   function resetCaptcha() {
//     clearCaptcha();
//     captchaEl.value.reset();
//   }

//   watchEffect(() => {
//     if (captchaProvider.value) captchaProvider.value.syncValue(captcha.value);
//   });

//   onMounted(() => {
//     if (process.env.NODE_ENV !== 'production') {
//       captcha.value = 'test';
//     }
//   });

//   return {
//     captcha,
//     captchaEl,
//     captchaProvider,
//     RECAPTCHA_SITE_KEY: config.RECAPTCHA_SITE_KEY,
//     captchaResponse,
//     clearCaptcha,
//     resetCaptcha,
//   };
// }
