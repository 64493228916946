import { getField, updateField } from 'vuex-map-fields';
import { nextTick } from 'vue';

import { localAxios } from '../apis';
import { ORG_ROLE_COLLABORATOR } from '@/config';

function getOriginalState() {
  return {
    currentId: null,
    currentOrganization: null,
    isLoading: false,
    profiles: [],
    profilesHasMore: false,
    profilesPage: 1,
    members: [],
    lastSyncedAt: null,
    customer: null,
  };
}

export default {
  namespaced: true,

  state: {
    organizations: [],
    ...getOriginalState(),
  },

  getters: {
    hasOrganization: (state) => !!state.currentOrganization,
    campsitesCreated: (state) =>
      state.currentOrganization?.number_of_campsites_created,
    campsitesEnabled: (state) =>
      state.currentOrganization?.number_of_campsites_enabled,
    numberOfCampsites: (state) =>
      state.currentOrganization?.number_of_campsites,
    numberOfUsers: (state) => state.currentOrganization?.number_of_users,
    isFreePlan: (state) => state.currentOrganization?.plan === 'free',
    hasAccountCreateStep: (state) =>
      !!state.currentOrganization?.account_create_step,
    members: (state) =>
      state.members.filter((m) => m.role !== ORG_ROLE_COLLABORATOR),
    collaborators: (state) =>
      state.members.filter((m) => m.role === ORG_ROLE_COLLABORATOR),
    getField,
  },

  mutations: {
    updateField,

    resetState(state) {
      Object.assign(state, getOriginalState());
    },
  },

  actions: {
    switch({ commit }, id) {
      commit('resetState');
      commit('linkSet/resetState', null, { root: true });
      commit('updateField', { path: 'currentId', value: id });
    },

    unset({ commit }) {
      commit('updateField', { path: 'currentOrganization', value: null });
    },

    async add({ commit }, params) {},

    async all({ commit }, params = {}) {
      const { data } = await localAxios.get(`/api/organizations`, { params });

      commit('updateField', {
        path: 'organizations',
        value: data.organizations,
      });

      return data;
    },

    async get({ getters, commit }) {
      commit('setIsLoading', true, { root: true });
      commit('updateField', { path: 'isLoading', value: true });

      try {
        const { data } = await localAxios.get(
          `/api/organizations/${getters.getField('currentId')}`,
        );

        commit('updateField', {
          path: 'currentOrganization',
          value: data,
        });
        commit('updateField', { path: 'lastSyncedAt', value: new Date() });
        await nextTick();

        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
        commit('updateField', { path: 'isLoading', value: false });
      }
    },

    async update({ getters, commit }, params) {
      commit('setIsLoading', true, { root: true });

      try {
        const { data } = await localAxios.put(
          `/api/organizations/${getters.getField('currentId')}`,
          params,
        );

        commit('updateField', {
          path: 'currentOrganization',
          value: data,
        });

        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
      }
    },

    async delete({ getters, commit }) {
      commit('setIsLoading', true, { root: true });

      try {
        await localAxios.delete(
          `/api/organizations/${getters.getField('currentId')}`,
        );

        commit('updateField', {
          path: 'currentOrganization',
          value: null,
        });
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
      }
    },

    async updateProfileImage({ getters, commit }, url) {
      commit('setIsLoading', true, { root: true });

      try {
        const { data } = await localAxios.put(
          `/api/organizations/${getters.getField('currentId')}/profile-image`,
          {
            url,
          },
        );

        commit('updateField', {
          path: 'currentOrganization',
          value: data,
        });
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
      }
    },

    resendEmailConfirmation({ getters }) {
      return localAxios.post(
        `/api/organizations/${getters.getField(
          'currentId',
        )}/resend-email-confirmation`,
      );
    },

    async cancelEmailChange({ getters, commit }) {
      const { data } = await localAxios.put(
        `/api/organizations/${getters.getField(
          'currentId',
        )}/cancel-email-change`,
      );
      commit('updateField', { path: 'currentOrganization', value: data });
      return data;
    },

    async allProfiles({ commit, state }, params = {}) {
      params.organization_id = state.currentId;
      const { data } = await localAxios.get(`/api/profiles/all`, { params });
      const page = params.page || 1;

      if (page === 1) {
        commit('updateField', {
          path: 'profiles',
          value: data.profiles,
        });
      } else {
        const existingProfiles = state['profiles'];
        commit('updateField', {
          path: 'profiles',
          value: [...existingProfiles, ...data.profiles],
        });
      }
      commit('updateField', {
        path: 'profilesHasMore',
        value: data.has_more,
      });

      return data;
    },

    async members({ getters, commit }) {
      commit('setIsLoading', true, { root: true });

      try {
        const { data } = await localAxios.get(
          `/api/organizations/${getters.getField('currentId')}/members`,
        );

        commit('updateField', {
          path: 'members',
          value: data.members,
        });

        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
      }
    },

    async member({ getters, commit }, id) {
      commit('setIsLoading', true, { root: true });

      try {
        const { data } = await localAxios.get(
          `/api/organizations/${getters.getField('currentId')}/members/${id}`,
        );

        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
      }
    },

    async getCustomerData({ getters, commit }) {
      const { data } = await localAxios.get(
        `/api/organizations/${getters.getField('currentId')}/customer`,
      );

      commit('updateField', { path: 'customer', value: data });

      return data;
    },

    async changeMemberRole({ getters, commit }, { id, role }) {
      commit('setIsLoading', true, { root: true });

      try {
        const { data } = await localAxios.put(
          `/api/organizations/${getters.getField('currentId')}/members/${id}`,
          {
            role,
          },
        );

        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setIsLoading', false, { root: true });
      }
    },
  },
};
