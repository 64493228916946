<template>
  <TabPanel class="panel" value="devices">
    <DevicesTable
      :is-loading="isLoading"
      :data="deviceData"
      :has-results="hasResults"
      :series="series"
      data-title="Clicks"
      hide-unique
    />
  </TabPanel>
</template>

<script>
import { TabPanel } from '@campsite-bio/component-lib';
import { computed } from 'vue';

import { DevicesTable } from '../../analytics/tables';

export default {
  components: {
    TabPanel,
    DevicesTable,
  },

  props: {
    period: {
      type: Number,
      required: true,
    },

    linkId: {
      type: String,
      required: true,
    },

    data: {
      type: Object,
      default: null,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const deviceData = computed(() => {
      if (!props.data) return [];

      const stats = props.data.clicks_by_device
        .filter((i) => i._id.range === 'current')
        .map((item) => {
          return [item._id.device, item.count, 0];
        });

      return stats.sort((a, b) => b[1] - a[1]).slice(0, 10);
    });

    const hasResults = computed(() => deviceData.value.length > 0);

    const series = computed(() => {
      return {
        labels: deviceData.value.map(([name]) => name),
        datasets: [
          {
            data: deviceData.value.map(([, views]) => views),
          },
        ],
      };
    });

    return { deviceData, hasResults, series };
  },
};
</script>

<style lang="scss" scoped>
.panel {
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 1rem;
}
</style>
