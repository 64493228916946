<template>
  <Card style="border: none">
    <Tabs v-model="currentTab">
      <div class="link__chart-top">
        <TabBar class="link__chart-tab-bar" aria-label="Analytics tabs">
          <Tab class="link__chart-tab" value="clicks">Overview</Tab>
          <Tab class="link__chart-tab" value="referrals">Top Referrals</Tab>
          <Tab class="link__chart-tab" value="countries">Top Countries</Tab>
          <Tab class="link__chart-tab" value="devices">Devices</Tab>
        </TabBar>
        <FlexSpacer />
        <ButtonGroup v-model="period" class="link__chart-period">
          <Button size="small" :value="7">7 Days</Button>
          <Button size="small" :value="28">28 days</Button>
        </ButtonGroup>
      </div>

      <ClicksTabPanel v-bind="tabProps" />
      <ReferralsTabPanel v-bind="tabProps" />
      <CountriesTabPanel v-bind="tabProps" />
      <DevicesTabPanel v-bind="tabProps" />
    </Tabs>
  </Card>
</template>

<script>
import {
  Card,
  Button,
  ButtonGroup,
  Tabs,
  Tab,
  TabBar,
  FlexSpacer,
} from '@campsite-bio/component-lib';
import { computed, inject, onMounted, ref } from 'vue';

import {
  ClicksTabPanel,
  ReferralsTabPanel,
  CountriesTabPanel,
  DevicesTabPanel,
} from './stats';
import { useLoadData } from '../analytics';
import toast from '../../utils/toast';

export default {
  components: {
    Card,
    Button,
    ButtonGroup,
    ClicksTabPanel,
    ReferralsTabPanel,
    CountriesTabPanel,
    DevicesTabPanel,
    Tabs,
    Tab,
    TabBar,
    FlexSpacer,
  },

  setup(props) {
    const { link, id: linkId, numberOfClicks } = inject('link');
    const period = ref(7);
    const currentTab = ref('clicks');

    const range = computed(() => `last_${period.value}`);

    const { allData, data, load, isLoading } = useLoadData(
      `links.${linkId.value}_all`,
      'loadReport',
      {
        report: 'link_click_data',
        link_id: linkId.value,
      },
      {
        useSelectedDate: true,
        selectedDateFilter: range,
        onError() {
          toast.error('There was an error loading your link data');
        },
      },
    );

    onMounted(() => {
      load();
    });

    const tabProps = computed(() => {
      return {
        period: period.value,
        allData: allData.value,
        data: data.value,
        isLoading: isLoading.value,
        link: link.value,
        linkId: linkId.value,
        numberOfClicks: numberOfClicks.value,
      };
    });

    return { period, currentTab, tabProps };
  },
};
</script>

<style lang="scss" scoped>
.link__chart {
  position: relative;

  &__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.link__chart-top {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;

  @media (min-width: 544px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.link__chart-tab-bar.link__chart-tab-bar {
  padding-bottom: 0.35rem;
  white-space: nowrap;
  overflow-x: auto;

  @media (min-width: 544px) {
    overflow-x: unset;
    padding-bottom: 0;
  }
}

.link__chart-tab.link__chart-tab {
  font-size: 0.85rem;
}

.link__chart-period {
  align-self: center;
}
</style>
