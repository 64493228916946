import store from '../store';

export function createFormAndRedirect(to, document = window.document) {
  const form = document.createElement('form');
  form.setAttribute('action', to);
  form.setAttribute('method', 'post');
  const token = document.createElement('input');
  token.type = 'hidden';
  token.name = 'authenticity_token';
  token.value = store.getters['csrfToken'];
  form.appendChild(token);
  document.body.appendChild(form);
  form.submit();
}
