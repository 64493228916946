<template>
  <ListItem
    component="button"
    type="button"
    :disabled="disabled"
    @click.prevent="$emit('click', 'form')"
  >
    <ListItemAction>
      <LockIcon v-if="disabled" size="1.25rem" />
      <DynamicFormIcon v-else size="1.25rem" />
    </ListItemAction>
    <ListItemContent>
      <ListItemTitle>Form</ListItemTitle>
      <ListItemSubtitle wrap
        >Embed Jotform or Typeform on your profile.</ListItemSubtitle
      >
    </ListItemContent>
  </ListItem>
</template>

<script>
import {
  ListItem,
  ListItemAction,
  ListItemContent,
  ListItemSubtitle,
  ListItemTitle,
  LockIcon,
  DynamicFormIcon,
} from '@campsite-bio/component-lib';

export default {
  components: {
    ListItem,
    ListItemAction,
    ListItemContent,
    ListItemSubtitle,
    ListItemTitle,
    LockIcon,
    DynamicFormIcon,
  },

  props: {
    disabled: Boolean,
  },
};
</script>

<style lang="scss" scoped></style>
