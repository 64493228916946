import { getField, updateField } from 'vuex-map-fields';
import Vue from 'vue';

import { localAxios } from '@/apis';

function getOriginalState() {
  return {
    media: {},
    mediaPath: {},
    mediaParent: {},
  };
}

export default {
  namespaced: true,

  state: getOriginalState(),

  getters: {
    getField,
  },

  mutations: {
    resetState(state) {
      Object.assign(state, getOriginalState());
    },

    setMedia(state, { id, value }) {
      Vue.set(state.media, id, value);
    },

    setMediaPath(state, { id, value }) {
      Vue.set(state.mediaPath, id, value);
    },

    setMediaParent(state, { id, value }) {
      Vue.set(state.mediaParent, id, value);
    },

    addMediaItem(state, { id, item, end = true }) {
      if (end) Vue.set(state.media, id, [...state.media[id], item]);
      else Vue.set(state.media, id, [item, ...state.media[id]]);
    },

    addMediaItems(state, { items, id }) {
      Vue.set(state.media, id, [...state.media[id], ...items]);
    },

    deleteMediaItem(state, { id, mediaId }) {
      Vue.set(
        state.media,
        id,
        state.media[id].filter((i) => i._id.$oid !== mediaId),
      );
    },

    updateField,
  },

  actions: {
    async all({ commit }, { page, pageSize, parent, organization_id, id }) {
      const { data } = await localAxios.get(`/api/media/${id}/all`, {
        params: {
          page,
          page_size: pageSize,
          parent,
          organization_id,
        },
      });

      commit('setMediaPath', {
        id,
        value: data.path,
      });
      if (page === 1) {
        commit('setMedia', {
          id,
          value: data.media,
        });
      } else {
        commit('addMediaItems', { items: data.media, id });
      }

      return data;
    },

    async create({ rootGetters }, { id, file, parent, organization_id }) {
      const { data } = await localAxios.post(`/api/media/${id}`, {
        height: file.height,
        width: file.width,
        file_size: file.size,
        file_type: file.type,
        filename: file.name,
        parent,
        organization_id,
      });

      return data;
    },

    async getFilename() {
      const { data } = await localAxios.get(`/api/media/filename`);
      return data;
    },

    async createFolder(
      { rootGetters },
      { id, organization_id, data: folderData },
    ) {
      const { data } = await localAxios.post(
        `/api/media/${id}/folder`,
        folderData,
        {
          params: {
            organization_id,
          },
        },
      );

      return data;
    },

    async update(
      { rootGetters },
      { id, mediaId, data: mediaData, organization_id },
    ) {
      const { data } = await localAxios.put(
        `/api/media/${id}/${mediaId}`,
        mediaData,
        {
          params: {
            organization_id,
          },
        },
      );

      return data;
    },

    async remove({ commit }, { id, mediaId, organization_id }) {
      const { data } = await localAxios.delete(`/api/media/${id}/${mediaId}`, {
        params: {
          organization_id,
        },
      });
      commit('deleteMediaItem', { id, mediaId });

      return data;
    },
  },
};
