<template>
  <v-dialog v-model="isOpen" fullscreen>
    <template #activator="data">
      <slot name="activator" v-bind="data" />
    </template>

    <div v-if="isOpen" class="wrapped">
      <WrappedIntro
        v-if="step === 0"
        :auth-id="channel ? channel.app_authorization._id.$oid : null"
        :profile-id="profile ? profile._id.$oid : channel.campsite._id.$oid"
        :account-id="channel ? channel.uid : null"
        @synced="handleIntroSynced"
        @close="handleClose"
      />
      <WrappedShareCreate
        v-else-if="step === 1"
        :profile-id="profile ? profile._id.$oid : channel.campsite._id.$oid"
        @synced="handleShareCreate"
        @close="handleClose"
      />
      <WrappedStories
        v-else
        :is-share-open="isShareOpen"
        @close="handleClose"
        @share="isShareOpen = true"
      />
    </div>

    <WrappedShareDialog
      v-if="wrappedData && wrappedData.share_image_urls.length"
      v-model="isShareOpen"
      :wrapped-data="wrappedData"
    />
  </v-dialog>
</template>

<script>
import { computed, provide, ref, toRefs, watch } from 'vue';

import { useModel } from '../../../../compositions';
import WrappedIntro from './wrapped-intro';
import WrappedShareCreate from './wrapped-share-create';
import WrappedStories from './wrapped-stories';
import WrappedShareDialog from './wrapped-share-dialog';
import { intercomUpdate } from '../../../../utils/intercom';

export default {
  components: {
    WrappedStories,
    WrappedShareCreate,
    WrappedIntro,
    WrappedShareDialog,
  },

  props: {
    value: Boolean,

    channel: {
      type: Object,
      default: null,
    },

    profile: {
      type: Object,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const isOpen = useModel(value, (val) => emit('input', val));
    const step = ref(0);
    const wrappedDataLocal = ref(null);
    const isShareOpen = ref(false);

    const wrappedData = computed(() => {
      return (
        wrappedDataLocal.value ||
        props.channel?.wrapped_data ||
        props.profile?.wrapped_data
      );
    });

    provide('wrapped', {
      wrappedData,
      name: computed(() => {
        return props.channel?.name || props.profile?.username;
      }),
      type: computed(() => {
        return props.channel ? props.channel.page_type : 'profile';
      }),
    });

    function determineStep() {
      if (wrappedData.value?.share_image_urls.length > 0) {
        step.value = 2;
      } else if (wrappedData.value?.synced) {
        step.value = 1;
      } else {
        step.value = 0;
      }
    }

    watch(isOpen, (val) => {
      if (val) {
        determineStep();
      }

      if (val) intercomUpdate({ hide_default_launcher: true });
      else intercomUpdate({ hide_default_launcher: false });
    });

    function handleShareCreate(wrapped) {
      wrappedDataLocal.value = wrapped;
      step.value = 2;
    }

    function handleIntroSynced(wrapped) {
      wrappedDataLocal.value = wrapped;
      step.value = 1;
    }

    function handleClose() {
      isOpen.value = false;
      step.value = 0;
    }

    return {
      isOpen,
      step,
      handleIntroSynced,
      handleShareCreate,
      handleClose,
      wrappedData,
      isShareOpen,
    };
  },
};
</script>

<style lang="scss">
.vue-insta-stories .header {
  right: 16px;
}
</style>

<style lang="scss" scoped>
.wrapped {
  background: var(--g-color-primary);
  color: var(--g-color-on-primary);
  height: 100%;
}
</style>
