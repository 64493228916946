import { computed, nextTick, unref } from 'vue';
import { useRoute, useRouter } from '.';

let queue = {};

// https://github.com/vueuse/vueuse/blob/main/packages/router/useRouteQuery/index.ts
/**
 * Reactive route query
 * @param {String} name
 * @param {any} defaultValue
 * @param {Object} defaultOptions
 * @returns The reactive query value
 */
export function useRouteQuery(name, defaultValue, defaultOptions = {}) {
  const {
    mode = 'replace',
    route = useRoute(),
    router = useRouter(),
  } = defaultOptions;

  return computed({
    get() {
      const data = route.value.query[name];
      if (data == null) return defaultValue ?? null;
      if (Array.isArray(data)) return data.filter(Boolean);
      return data;
    },
    async set(v) {
      queue[name] = v === defaultValue || v === null ? undefined : v;

      await nextTick();
      router[unref(mode)]({
        ...route.value,
        query: { ...route.value.query, ...queue },
      });
      nextTick(() => (queue = {}));
    },
  });
}
