<template>
  <div class="v-chart-card__numbers__item">
    <VText
      class="v-chart-card__numbers__item__title"
      component="span"
      variant="inherit"
      :color="color"
    >
      {{ titleFormatted }}
    </VText>
    <VText
      class="v-chart-card__numbers__item__subtitle"
      component="span"
      variant="inherit"
      :color="color"
    >
      {{ subtitle }}&nbsp;
    </VText>
    <Badge
      v-if="badge"
      class="v-chart-card__numbers__item__badge"
      :color="badgeColor"
      :content="badgeFormatted"
    />
  </div>
</template>

<script>
import { VText, Badge, numberFormatter } from '@campsite-bio/component-lib';
import { computed } from 'vue';

export default {
  components: {
    VText,
    Badge,
  },

  props: {
    title: {
      type: [String, Number],
      required: true,
    },

    subtitle: {
      type: String,
      default: null,
    },

    color: {
      type: String,
      default: VText.props.color,
    },

    badge: {
      type: [Number, String],
      default: null,
    },

    badgeColor: {
      type: String,
      default: 'success',
    },
  },

  setup(props) {
    const titleFormatted = computed(() => {
      return typeof props.title === 'number'
        ? numberFormatter(props.title, 2)
        : props.title;
    });

    const badgeFormatted = computed(() => {
      return typeof props.badge === 'number'
        ? `${numberFormatter(props.badge, 1)}%`
        : props.badge;
    });

    return {
      titleFormatted,
      badgeFormatted,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-chart-card {
  &__numbers {
    &__item {
      display: flex;
      flex-direction: column;

      &__subtitle.v-text {
        font-size: 0.5em;
        white-space: nowrap;
        margin-top: -0.35rem;
        min-width: 5ch;
      }

      &__badge {
        margin-top: 0.25rem;
        opacity: 0.8;
      }
    }
  }
}
</style>
