<template>
  <List>
    <template v-if="providers.includes(PROVIDERS.instagram)">
      <ListItem
        type="button"
        component="button"
        :disabled="hasInstagram"
        @click="next('instagram')"
      >
        <ListItemIcon>
          <InstagramIcon />
        </ListItemIcon>
        <ListItemTitle>Instagram</ListItemTitle>
        <ListItemAction>
          <ChevronIcon size="1.25em" direction="right" />
        </ListItemAction>
      </ListItem>
      <Divider color="gray200" thickness="1" />
    </template>
    <template v-if="providers.includes(PROVIDERS.facebook)">
      <ListItem
        type="button"
        component="button"
        :disabled="hasFacebook || !hasAnalyticsAddon"
        @click="next('facebook')"
      >
        <ListItemIcon>
          <FacebookLetterFIcon />
        </ListItemIcon>
        <ListItemContent>
          <ListItemTitle wrap>Facebook (Instagram Business)</ListItemTitle>
          <ListItemSubtitle>
            <Badge content="Pro+ / Analytics add-on" />
          </ListItemSubtitle>
        </ListItemContent>
        <ListItemAction>
          <ChevronIcon size="1.25em" direction="right" />
        </ListItemAction>
      </ListItem>
      <Divider color="gray200" thickness="1" />
    </template>
    <template v-if="providers.includes(PROVIDERS.google)">
      <ListItem
        type="button"
        component="button"
        :disabled="hasGoogle"
        @click="next('google')"
      >
        <ListItemIcon>
          <GoogleIcon />
        </ListItemIcon>
        <ListItemTitle>Google</ListItemTitle>
        <ListItemAction>
          <ChevronIcon size="1.25em" direction="right" />
        </ListItemAction>
      </ListItem>
      <Divider color="gray200" thickness="1" />
    </template>
    <template v-if="providers.includes(PROVIDERS.google_analytics) && isAdmin">
      <ListItem
        type="button"
        component="button"
        :disabled="hasGoogleAnalytics"
        @click="next('google-analytics')"
      >
        <ListItemIcon>
          <GoogleIcon />
        </ListItemIcon>
        <ListItemTitle>Google Analytics 4</ListItemTitle>
        <ListItemAction>
          <ChevronIcon size="1.25em" direction="right" />
        </ListItemAction>
      </ListItem>
      <Divider color="gray200" thickness="1" />
    </template>
    <template v-if="providers.includes(PROVIDERS.youtube)">
      <ListItem
        v-if="providers.includes(PROVIDERS.youtube)"
        type="button"
        component="button"
        disabled
        @click="next('youtube')"
      >
        <ListItemIcon>
          <YoutubeIcon />
        </ListItemIcon>
        <ListItemContent>
          <ListItemTitle>YouTube</ListItemTitle>
          <ListItemSubtitle> Coming soon </ListItemSubtitle>
        </ListItemContent>
        <ListItemAction>
          <ChevronIcon size="1.25em" direction="right" />
        </ListItemAction>
      </ListItem>
      <Divider color="gray200" thickness="1" />
    </template>
  </List>
</template>

<script>
import {
  Badge,
  Divider,
  List,
  ListItem,
  ListItemContent,
  ListItemTitle,
  ListItemSubtitle,
  ListItemIcon,
  ListItemAction,
  InstagramIcon,
  FacebookLetterFIcon,
  GoogleIcon,
  YoutubeIcon,
  ChevronIcon,
} from '@campsite-bio/component-lib';
import { inject } from 'vue';

import { PROVIDERS } from '../../../../config';
import { useCurrentUser } from '../../../../compositions';

export default {
  components: {
    Badge,
    Divider,
    List,
    ListItem,
    ListItemContent,
    ListItemTitle,
    ListItemSubtitle,
    ListItemIcon,
    ListItemAction,
    InstagramIcon,
    FacebookLetterFIcon,
    GoogleIcon,
    YoutubeIcon,
    ChevronIcon,
  },

  props: {
    hasAnalyticsAddon: Boolean,
    hasInstagram: Boolean,
    hasFacebook: Boolean,
    hasGoogle: Boolean,
    hasGoogleAnalytics: Boolean,

    providers: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const { handleStepChange } = inject('multi-step-dialog');
    const { isAdmin } = useCurrentUser();

    function next(provider) {
      handleStepChange(`${provider}-auth`);
    }

    return {
      next,
      PROVIDERS,
      isAdmin,
    };
  },
};
</script>

<style lang="scss" scoped></style>
