import { percentageChange } from './percentage-change';

export function getBadgeData(current, past) {
  const difference = percentageChange(past, current);
  const differenceFormatted =
    typeof difference === 'number' ? `${difference.toFixed(1)}%` : difference;
  const color = difference > 0 ? 'success' : 'info';

  return {
    difference,
    differenceFormatted,
    color,
  };
}

/**
 * Track plan changes in Google Analytics and Facebook Pixel
 * @param {String} stripeCustomer
 * @param {Array} subscriptionItems
 */
export function trackPurchase(transactionId, subscriptionItems) {
  const totalValue = subscriptionItems.reduce(
    (total, { quantity, price }) => total + price.unit_amount * quantity,
    0,
  );

  if (window.gtag) {
    gtag('event', 'purchase', {
      transaction_id: transactionId,
      affiliation: 'Campsite',
      value: totalValue / 100,
      currency: 'USD',
      tax: 0,
      shipping: 0,
      items: subscriptionItems.map(({ price, quantity }) => ({
        id: price.id,
        name: price.product,
        brand: '',
        category: '',
        quantity,
        price: price.unit_amount / 100,
      })),
    });
  }

  if (window.fbq) {
    fbq(
      'track',
      'Purchase',
      {
        value: totalValue / 100,
        currency: 'USD',
      },
      {
        eventId: transactionId,
      },
    );
  }
}
