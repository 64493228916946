<template>
  <Card border-color="primary">
    <CardTitle>
      <VText component="h2" variant="h5">Setup your {{ type }} link</VText>
      <FlexSpacer />
      <IconButton
        size="1.25rem"
        :loading="isDeleting"
        title="Delete"
        aria-label="Delete"
        @click="$emit('remove')"
      >
        <CloseIcon />
      </IconButton>
    </CardTitle>
    <Divider color="primary" />
    <CardText>
      <ValidationObserver v-slot="{ invalid, handleSubmit }" ref="form" slim>
        <form @submit.prevent="handleSubmit(handleSetup)">
          <template v-if="hasProviders">
            <ValidationProvider
              v-slot="{ errors, ariaInput, ariaMsg }"
              ref="authProvider"
              :rules="{ required: true }"
              name="provider"
              slim
            >
              <FormGroup>
                <Label component="span" block gutter-bottom
                  >Choose your payment provider</Label
                >
                <AppAuthorizationsSelect
                  v-model="selectedAuth"
                  required
                  v-bind="ariaInput"
                  store-key="profiles/paymentAuthorizations"
                  @change:auth="handleAuthChange"
                  @update:authorizations="authorizations = $event"
                />
                <FormGroupHelp color="error" v-bind="ariaMsg">{{
                  errors[0]
                }}</FormGroupHelp>
                <FormGroupHelp>
                  <InlineLink :to="`/profile/${profileId}/settings#commerce`"
                    >Add a new provider</InlineLink
                  >
                </FormGroupHelp>
              </FormGroup>
            </ValidationProvider>

            <Button
              key="submit"
              type="submit"
              :loading="isSaving"
              :disabled="invalid"
              >Continue Setup</Button
            >
          </template>
          <template v-else>
            <AppAuthorizationsSelect
              class="hidden"
              store-key="profiles/paymentAuthorizations"
              @change:auth="handleAuthChange"
              @update:authorizations="authorizations = $event"
            />
            <VText gutter-bottom
              >You haven't set up any payment providers yet. It only takes ~1
              minute to connect one.</VText
            >
            <Button key="add" :to="`/profile/${profileId}/settings#commerce`"
              >Add a New Provider</Button
            >
          </template>
        </form>
      </ValidationObserver>
    </CardText>
  </Card>
</template>

<script>
import {
  Card,
  CardText,
  CardTitle,
  Button,
  Divider,
  Label,
  FormGroup,
  FormGroupHelp,
  VText,
  IconButton,
  CloseIcon,
  FlexSpacer,
  InlineLink,
} from '@campsite-bio/component-lib';
import { computed, ref, watch } from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { AppAuthorizationsSelect } from '../../form';
import { useCurrentProfile, useStore } from '@/compositions';
import toast from '@/utils/toast';

export default {
  components: {
    Card,
    CardText,
    CardTitle,
    Button,
    Divider,
    FormGroup,
    FormGroupHelp,
    Label,
    VText,
    FlexSpacer,
    InlineLink,
    IconButton,
    CloseIcon,
    AppAuthorizationsSelect,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    commerceOptions: {
      type: Object,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },

    linkId: {
      type: String,
      required: true,
    },

    isDeleting: Boolean,
  },

  setup(props, { emit }) {
    const store = useStore();
    const selectedAuth = ref(null);
    const isSaving = ref(false);
    const error = ref(null);
    const form = ref(null);
    const authData = ref(null);
    const authProvider = ref(null);
    const authorizations = ref(null);
    const { id: profileId } = useCurrentProfile();

    watch(selectedAuth, async (newVal) => {
      if (authProvider.value) {
        authProvider.value.syncValue(newVal);
        await authProvider.value.validate();
      }
    });

    const hasProviders = computed(() => {
      return authorizations.value?.length > 0;
    });

    async function handleSetup() {
      error.value = null;
      isSaving.value = true;

      try {
        // Save the result to the commerce options
        const { link } = await store.dispatch('links/updateOptions', {
          id: props.linkId,
          name: 'commerce',
          app_authorization_id: selectedAuth.value,
          has_setup: true,
          currency: authData.value.data.currency,
          source: authData.value.provider,
        });

        // Show the link now that it's been setup
        store.commit('links/updateLinkOptions', {
          id: props.linkId,
          value: link.commerce_options,
          name: 'commerce_options',
        });
        emit('setup-complete');
      } catch (e) {
        console.error(e);
        toast.error('There was an error trying to save your changes.');
      }

      isSaving.value = false;
    }

    function handleAuthChange(auth) {
      authData.value = auth;
    }

    return {
      profileId,
      selectedAuth,
      handleSetup,
      isSaving,
      error,
      form,
      handleAuthChange,
      authProvider,
      authorizations,
      hasProviders,
    };
  },
};
</script>

<style lang="scss" scoped></style>
