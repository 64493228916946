import { localAxios } from './axios';

export function validateInvite(token) {
  return localAxios.get(`/api/profiles/user-access/invite/${token}`);
}

export function acceptInvite(token) {
  return localAxios.put(`/api/profiles/user-access/invite/${token}/accept`);
}

export function declineInvite(token) {
  return localAxios.put(`/api/profiles/user-access/invite/${token}/decline`);
}
