<template>
  <header class="header js-header-desktop" :style="styles">
    <nav class="header__nav header__nav--top" aria-label="Main menu">
      <ul class="header__nav-list">
        <li>
          <v-tooltip right>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <HeaderNavLink
                  class="header__nav-link--home js-header-nav-home"
                  aria-label="Home"
                  to="/"
                  exact
                >
                  <div
                    class="header__logo-wrapper"
                    :class="{ 'header__logo-wrapper--loading': isLoading }"
                  >
                    <Logo size="30" class="header__logo" only-mark />
                    <span class="header__loader">
                      <CircularLoader
                        v-if="isLoading"
                        size="3rem"
                        :thickness="2"
                      />
                    </span>
                  </div>
                </HeaderNavLink>
              </span>
            </template>
            <span>Home</span>
          </v-tooltip>
        </li>
        <template v-if="!hasNoCampsites">
          <li>
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <HeaderNavLink
                    class="js-header-nav-profile"
                    aria-label="Profile"
                    :to="`/profile/${profileId}`"
                  >
                    <Avatar v-if="hasProfile" size="30">
                      <img :src="profileImageSrc" alt="profile image" />
                    </Avatar>
                    <LinkIcon v-else title="Profile" />
                  </HeaderNavLink>
                </span>
              </template>
              <span>Profile</span>
            </v-tooltip>
          </li>
          <li>
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <HeaderNavLink aria-label="Profiles" to="/profiles">
                    <AccountGroupIcon title="Profiles" />
                  </HeaderNavLink>
                </span>
              </template>
              <span>Profiles</span>
            </v-tooltip>
          </li>
          <li>
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <HeaderNavLink aria-label="Reports" to="/reports">
                    <BarChartAltIcon title="Reports" />
                  </HeaderNavLink>
                </span>
              </template>
              <span>Reports</span>
            </v-tooltip>
          </li>
        </template>
      </ul>
    </nav>

    <FlexSpacer />

    <nav
      v-if="isLoggedIn"
      class="header__nav header__nav--btm"
      aria-label="Account menu"
    >
      <ul class="header__nav-list">
        <li>
          <v-menu right offset-x nudge-right="5">
            <template #activator="{ on, attrs }">
              <HeaderNavLink
                component="button"
                type="button"
                aria-label="Support"
                v-bind="attrs"
                muted
                v-on="on"
              >
                <QuestionCircleOIcon title="Support" />
              </HeaderNavLink>
            </template>

            <List>
              <ListItem href="https://support.campsite.bio/en/" target="_blank">
                <ListItemTitle>Help Articles</ListItemTitle>
              </ListItem>
              <ListItem href="https://campsite.bio/contact-us" target="_blank">
                <ListItemTitle>Contact Us</ListItemTitle>
              </ListItem>
            </List>
          </v-menu>
        </li>
        <li>
          <HeaderNavLink
            component="button"
            class="js-changelog"
            type="button"
            aria-label="Changelog"
            muted
          >
            <MegaphoneIcon title="Changelog" />
          </HeaderNavLink>
        </li>
        <li>
          <v-menu right offset-x nudge-right="5">
            <template #activator="{ on, attrs }">
              <v-tooltip right>
                <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                  <span v-bind="tooltipAttrs" v-on="tooltipOn">
                    <HeaderNavLinkAccount
                      class="js-header-nav-account"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <PersonIcon
                        v-if="hasDefaultProfileImage"
                        title="Account"
                      />
                      <img v-else :src="userProfileImageSrc" alt="" />
                    </HeaderNavLinkAccount>
                  </span>
                </template>
                <span>Account</span>
              </v-tooltip>
            </template>

            <List>
              <ListItem to="/account">
                <ListItemTitle>My Account</ListItemTitle>
              </ListItem>
              <ListItem to="/account/change-plan">
                <ListItemTitle>Change Plan</ListItemTitle>
              </ListItem>
              <ListItem
                v-if="isStripeCustomer"
                component="button"
                type="button"
                :disabled="isPortalLoading"
                @click="getBillingPortal"
              >
                <ListItemTitle>Billing</ListItemTitle>
              </ListItem>
              <ListItem to="/account/referrals">
                <ListItemTitle>Referrals</ListItemTitle>
              </ListItem>
              <ListItem to="/account/organizations">
                <ListItemTitle>Organizations</ListItemTitle>
              </ListItem>
              <ListItem
                component="button"
                type="button"
                @click="$emit('logout')"
              >
                <ListItemTitle>Log out</ListItemTitle>
              </ListItem>
            </List>
          </v-menu>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import {
  MegaphoneIcon,
  PersonIcon,
  AccountGroupIcon,
  QuestionCircleOIcon,
  FlexSpacer,
  List,
  ListItem,
  ListItemTitle,
  CircularLoader,
  BarChartAltIcon,
  LinkIcon,
  Avatar,
  convertImageUrlToEncodedUrl,
  useTheme,
  Logo,
  MEDIA_SIZES,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';
import tinycolor from 'tinycolor2';

import { HeaderNavLink, HeaderNavLinkAccount } from '.';
import { useCurrentUser, useStore, useUserBillingPortal } from '@/compositions';

export default {
  components: {
    Logo,
    MegaphoneIcon,
    QuestionCircleOIcon,
    PersonIcon,
    AccountGroupIcon,
    FlexSpacer,
    List,
    ListItem,
    ListItemTitle,
    HeaderNavLink,
    HeaderNavLinkAccount,
    CircularLoader,
    BarChartAltIcon,
    LinkIcon,
    Avatar,
  },

  setup() {
    const theme = useTheme();
    const store = useStore();
    const { isLoading: isPortalLoading, getBillingPortal } =
      useUserBillingPortal();

    const {
      hasDefaultProfileImage,
      profileImageSrc: userProfileImageSrc,
      isAdmin,
    } = useCurrentUser();

    const profile = computed(() => {
      return store.getters['profiles/currentProfile'];
    });

    const profileId = computed(() => {
      return store.getters['profiles/currentProfileId'];
    });

    const hasProfile = computed(() => {
      return store.getters['profiles/hasProfile'];
    });

    const isLoadingProfile = computed(() => {
      return store.getters['profiles/isLoadingProfile'];
    });

    const profileImageSrc = computed(() => {
      return convertImageUrlToEncodedUrl(
        profile.value.profile_image,
        MEDIA_SIZES.profile_image,
      );
    });

    const isStripeCustomer = computed(() => {
      return store.getters.isStripeCustomer;
    });

    const isLoggedIn = computed(() => {
      return store.getters.isLoggedIn;
    });

    const isLoading = computed(() => {
      return store.getters.isLoading;
    });

    const hasNoCampsites = computed(() => {
      return store.getters.hasNoCampsites;
    });

    const styles = computed(() => {
      return {
        '--c-bg-color': tinycolor(theme.value.colors.gray100)
          .darken(5)
          .toRgbString(),
        '--c-bg-active-color': theme.value.colors.gray100,
      };
    });

    return {
      hasProfile,
      profile,
      profileId,
      isLoadingProfile,
      isStripeCustomer,
      isLoggedIn,
      isLoading,
      profileImageSrc,
      styles,
      hasNoCampsites,
      hasDefaultProfileImage,
      userProfileImageSrc,
      isPortalLoading,
      getBillingPortal,
      isAdmin,
    };
  },
};
</script>

<style lang="scss">
#HW_badge_cont {
  bottom: 0;
  position: absolute !important;
  left: 0;

  @media (min-width: 768px) {
    left: auto;
    right: 0;
  }
}

#HW_badge {
  animation: none !important;
  left: unset !important;
  right: -2px;
  top: -7px !important;
}
</style>

<style lang="scss" scoped>
.header {
  align-items: center;
  background: var(--c-bg-color);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.75rem 0;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 101;
  width: var(--header-width);

  @media print {
    display: none;
  }

  &__logo-wrapper {
    display: inline-flex;
    position: relative;
    transform: translateZ(0);

    &--loading .header__logo {
      transform: scale(0.7);
    }
  }

  &__loader {
    display: inline-flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__nav {
    &--top {
      margin-top: -0.75rem;
      width: 100%;
    }

    &--btm {
      li:last-child {
        margin-top: 0.25rem;
      }

      .header__nav-list {
        gap: 0.5rem;
      }
    }
  }

  &__nav-list {
    align-items: center;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      width: 100%;

      > * {
        width: 100%;
      }
    }
  }
}

.header__nav-link--home {
  color: var(--g-color-primary);
}
</style>
