<template>
  <div class="quickview__stat">
    <div class="quickview__stat__header">
      <VText class="quickview__stat__title" component="span" variant="h6">{{
        title
      }}</VText>
      <v-tooltip v-if="helpText" left>
        <template #activator="{ on, attrs }">
          <QuestionCircleOIcon
            class="quickview__stat__help"
            :title="helpText"
            size=".75em"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <span>{{ helpText }}</span>
      </v-tooltip>
      <slot name="badge" />
    </div>
    <VText class="quickview__stat__value" component="span"><slot /></VText>
  </div>
</template>

<script>
import { VText, QuestionCircleOIcon } from '@campsite-bio/component-lib';

export default {
  components: {
    VText,
    QuestionCircleOIcon,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    helpText: {
      type: String,
      default: null,
    },
  },

  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.quickview__stat {
  border-left: 1px solid #e9e9e9;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  &:last-child {
    padding-right: 0;
  }

  @media (min-width: 544px) {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  @media (min-width: 768px) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  &__header {
    align-items: center;
    display: flex;
  }

  &__help {
    display: inline-flex;
    margin-right: 0.35rem;

    ::v-deep span {
      display: inline-flex;
    }
  }
}

.quickview__stat__title.quickview__stat__title {
  display: block;
  font-size: 0.65rem;
  margin-right: 0.35rem;

  @media (min-width: 544px) {
    font-size: 0.75rem;
  }
}
</style>
