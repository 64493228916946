<template>
  <GoogleBtn
    v-bind="$attrs"
    :disabled="isAuthorizing"
    v-on="$listeners"
    @click.prevent="authorize"
  />
</template>

<script>
import { useGoogleAuthorization, useStore } from '../compositions';
import { GoogleBtn } from './buttons';

export default {
  components: {
    GoogleBtn,
  },

  props: {
    authType: {
      type: String,
      default: 'authorize',
    },
  },

  setup(props) {
    const store = useStore();

    const { authorize, isAuthorizing } = useGoogleAuthorization(
      {
        onCancel() {},
        onSuccess() {
          store.dispatch('profiles/getAppAuthorizations');
        },
      },
      {
        type: props.authType,
      },
      'google_sheets',
    );

    return { authorize, isAuthorizing };
  },
};
</script>

<style scoped></style>
