<template>
  <div>
    <FormGroup>
      <Label for="line_1">Address Line 1</Label>
      <Input
        id="line_1"
        v-model="addressLine1"
        autocomplete="address-line1"
        placeholder="123 Main St"
        :disabled="isLoading"
      />
    </FormGroup>
    <FormGroup>
      <Label for="line_2">Address Line 2</Label>
      <Input
        id="line_2"
        v-model="addressLine2"
        autocomplete="address-line2"
        placeholder="Apartment 1, suite, etc. (optional)"
        :disabled="isLoading"
      />
    </FormGroup>
    <FormGroup>
      <Label for="city">City</Label>
      <Input
        id="city"
        v-model="city"
        autocomplete="address-level2"
        placeholder="Beverly Hills"
        :disabled="isLoading"
      />
    </FormGroup>

    <FormGroup>
      <Label for="country">Country</Label>
      <CountrySelect
        id="country"
        :value="country && country.code"
        autocomplete="country"
        :disabled="isLoading"
        @input:country="country = $event"
      />
    </FormGroup>

    <div class="address-grid">
      <FormGroup>
        <Label for="state">State</Label>
        <StateSelect
          id="state"
          :value="state && state.code"
          autocomplete="address-level1"
          :country-code="country && country.code"
          :disabled="isLoading"
          @input:state="state = $event"
        />
      </FormGroup>
      <ValidationProvider
        v-slot="{ errors, ariaMsg, ariaInput }"
        :rules="postalCodeRules"
        name="postal code"
        slim
      >
        <FormGroup>
          <Label for="postal-code">Postal Code</Label>
          <Input
            id="postal-code"
            v-model="postalCode"
            autocomplete="postal-code"
            placeholder="90210"
            required
            v-bind="ariaInput"
            :disabled="isLoading"
          />
          <FormGroupHelp color="error" v-bind="ariaMsg">{{
            errors[0]
          }}</FormGroupHelp>
        </FormGroup>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import {
  FormGroup,
  Label,
  Input,
  FormGroupHelp,
} from '@campsite-bio/component-lib';
import { computed, inject, watch } from 'vue';

import { CountrySelect, StateSelect } from '../form';

export default {
  components: {
    ValidationProvider,
    FormGroup,
    Label,
    Input,
    FormGroupHelp,
    CountrySelect,
    StateSelect,
  },

  setup() {
    const {
      address,
      addressLine1,
      addressLine2,
      city,
      state,
      postalCode,
      country,
      isLoading,
    } = inject('address');

    const postalCodeRules = computed(() => {
      if (country.value === 'US') {
        return {
          regex: /^\d{5}(-\d{4})?$/,
        };
      } else if (country.value === 'CA') {
        return {
          regex: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
        };
      } else {
        return null;
      }
    });

    watch(
      address,
      (a) => {
        addressLine1.value = a?.line1;
        addressLine2.value = a?.line2;
        city.value = a?.city;
        state.value = a?.state;
        postalCode.value = a?.postal_code;
        country.value = a?.country;
      },
      { immediate: true },
    );

    // Reset state when country changes
    watch(country, (newValue, oldValue) => {
      if (newValue?.code !== oldValue?.code) state.value = null;
    });

    return {
      addressLine1,
      addressLine2,
      city,
      state,
      country,
      postalCode,
      postalCodeRules,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.address-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  column-gap: 1rem;
}
</style>
