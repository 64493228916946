<template>
  <Item>
    <ListItemContent>
      <ListItemTitle>
        <strong>Campsite {{ planName }}</strong
        >&nbsp;&nbsp;
        <Badge
          v-if="subscriptionStatus === 'active'"
          color="success"
          content="Active"
        />
        <Badge
          v-else-if="subscriptionStatus === 'past_due'"
          color="error"
          content="Past due"
        />
        <Badge
          v-if="hasFreeAfter"
          color="gray200"
          :content="`Cancels on ${freeAfterDate}`"
        />
        <Badge
          v-else-if="isTrialing"
          color="info"
          :content="`Trial ends ${trialEndDate}`"
        />
      </ListItemTitle>
      <ListItemSubtitle v-if="!isFreePlan" wrap>
        Billing {{ planInterval }}
        <template v-if="nextInvoice && nextPaymentAttemptDate">
          • Next invoice on {{ nextPaymentAttemptDate }} for
          {{ (nextInvoice.total / 100) | currency }}
        </template>
        <template v-else>• No future invoices</template>
      </ListItemSubtitle>
      <ListItemSubtitle>
        {{ numberOfProfiles }} profile{{ numberOfProfiles !== 1 ? 's' : '' }} •
        {{ numberOfUsers }} {{ isOrg ? 'member' : 'collaborator'
        }}{{ numberOfUsers !== 1 ? 's' : '' }}
        <template v-if="hasAnalyticsAddon && !isAnalyticsAddonIncluded"
          >• Analytics add-on</template
        >
      </ListItemSubtitle>
    </ListItemContent>
  </Item>
</template>

<script>
import {
  Item,
  ListItemContent,
  ListItemTitle,
  ListItemSubtitle,
  Badge,
} from '@campsite-bio/component-lib';

export default {
  components: {
    Item,
    ListItemContent,
    ListItemTitle,
    ListItemSubtitle,
    Badge,
  },

  props: {
    planName: {
      type: String,
      required: true,
    },

    planInterval: {
      type: String,
      required: true,
    },

    numberOfProfiles: {
      type: Number,
      required: true,
    },

    numberOfUsers: {
      type: Number,
      required: true,
    },

    subscriptionStatus: {
      type: String,
      default: null,
    },

    freeAfterDate: {
      type: String,
      default: null,
    },

    trialEndDate: {
      type: String,
      default: null,
    },

    nextInvoice: {
      type: Object,
      default: null,
    },

    nextPaymentAttemptDate: {
      type: String,
      default: null,
    },

    hasFreeAfter: Boolean,
    hasAnalyticsAddon: Boolean,
    isAnalyticsAddonIncluded: Boolean,
    isFreePlan: Boolean,
    isTrialing: Boolean,
    isPastDue: Boolean,
    isOrg: Boolean,
  },
};
</script>

<style lang="scss" scoped></style>
