import { computed } from 'vue';

import { useStore } from '../use-store';
import { useProfile } from './use-profile';

// Returns the current profile from the store
export function useCurrentProfile() {
  const store = useStore();

  const hasProfile = computed(() => {
    return store.getters['profiles/hasProfile'];
  });

  const profile = computed(() => {
    return store.getters['profiles/currentProfile'];
  });

  return { hasProfile, ...useProfile(profile) };
}
