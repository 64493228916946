<template>
  <Card :style="styles">
    <DataTable
      :headers="tableHeaders"
      :items="formattedData"
      :items-per-page="999"
      hide-default-footer
      :item-class="itemClass"
      :loading="isLoading"
    >
      <template #item.action_score="{ item }">
        {{ item.action_score.toFixed(2) }}
      </template>
      <template #item.reach_percentile="{ item }">
        {{ item.reach_percentile.toFixed(2) }}%
      </template>
    </DataTable>

    <CardText>
      <div class="insights-sessions__label">
        <Shape width="45px" height="30px" :color="pastColor" />
        Previous period
      </div>
      <LabelWithText
        label="Reach"
        text="Unique users who have visited your profile"
      />
    </CardText>
  </Card>
</template>

<script>
import { computed, ref, toRefs } from 'vue';
import { Card, CardText, useTheme, Shape } from '@campsite-bio/component-lib';
import { DateTime } from 'luxon';
import tinycolor from 'tinycolor2';

import { DataTable } from '../../vuetify';
import { LabelWithText } from '../../account';

export default {
  components: {
    DataTable,
    Card,
    CardText,
    LabelWithText,
    Shape,
  },

  props: {
    data: {
      type: Array,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const theme = useTheme();
    const { data } = toRefs(props);

    const pastColor = computed(() => {
      return tinycolor(theme.value.colors.secondary).setAlpha(0.1).toString();
    });

    const styles = computed(() => ({
      '--c-past-bg-color': pastColor.value,
    }));

    const tableHeaders = ref([
      { text: 'Rank', value: 'rank', align: 'start' },
      { text: 'Referrer', value: 'referrer' },
      { text: 'Action Score', value: 'action_score' },
      { text: 'Clicks', value: 'clicks' },
      { text: 'Events', value: 'events' },
      { text: 'Reach', value: 'reach' },
    ]);

    function itemClass(item) {
      return item._id.range === 'past' ? 'insight-sessions__past-row' : '';
    }

    const formattedData = computed(() => {
      if (!data.value) return null;

      return [...data.value]
        .sort((a, b) => b.action_score - a.action_score)
        .map((item, i) => {
          return {
            rank: `#${i + 1}`,
            referrer: item._id.referrer,
            ...item,
          };
        });
    });

    function formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
    }

    return {
      pastColor,
      tableHeaders,
      formattedData,
      formatDate,
      itemClass,
      styles,
    };
  },
};
</script>

<style lang="scss">
.insight-sessions__past-row {
  background-color: var(--c-past-bg-color);
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
}
</style>

<style lang="scss" scoped>
.insights-sessions__label {
  align-items: center;
  display: flex;
  gap: 0.5em;

  &::v-deep .v-shape {
    border: 1px solid var(--g-color-gray-300);
  }
}
</style>
