import { ref, unref } from 'vue';

export function useHoveredValues({
  series,
  categories,
  pastCategories = [],
  formatValue,
}) {
  const hoveredValues = ref(null);

  function mouseMove(e, chartContext, { dataPointIndex }) {
    if (!series.value.length) return;

    const pc = unref(pastCategories);
    if (dataPointIndex >= 0 && pc?.length) {
      const currentValue = series.value[1].data[dataPointIndex];

      hoveredValues.value = {
        current:
          currentValue === null || currentValue === undefined
            ? '-'
            : currentValue,
        currentSubtitle:
          currentValue === null || currentValue === undefined
            ? ''
            : categories.value[dataPointIndex],
        past: series.value[0].data[dataPointIndex],
        pastSubtitle: pc[dataPointIndex],
      };
    } else if (dataPointIndex >= 0) {
      hoveredValues.value = series.value.reduce((accum, { name, data }, i) => {
        const currentValue = data[dataPointIndex];
        accum.push({
          title: formatValue ? formatValue(currentValue, name) : currentValue,
          subtitle: i === 0 ? categories.value[dataPointIndex] : null,
        });
        return accum;
      }, []);
    } // else resetHoveredValues();
  }

  function resetHoveredValues() {
    hoveredValues.value = null;
  }

  return {
    hoveredValues,
    mouseMove,
    resetHoveredValues,
  };
}
