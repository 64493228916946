<template>
  <SvgIcon viewBox="0 0 30 28" fill="none" style="stroke-width: unset">
    <rect
      x="0.5"
      y="0.5"
      width="29"
      height="27"
      rx="1.5"
      stroke="#BBB"
      style="fill: none"
    ></rect
    ><rect
      x="6"
      y="6"
      width="18"
      height="16"
      rx="0.5"
      fill="var(--g-color-primary)"
      stroke="none"
    ></rect>
  </SvgIcon>
</template>

<script>
import { SvgIcon } from '@campsite-bio/component-lib';

export default {
  components: {
    SvgIcon,
  },
};
</script>

<style lang="scss" scoped></style>
