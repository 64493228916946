import Tour from './base';
import store from '../store';

export * from './compositions';

export default class HomeTour extends Tour {
  constructor(options) {
    super(options);
  }

  start() {
    const isDesktop = window.innerWidth > 768;
    const hasGettingStartedList = document.querySelector(
      '.js-getting-started-checklist',
    );

    if (isDesktop) {
      this.tour.addStep({
        title: 'Navigation',
        text: `Get back to the home page by selecting the Campsite.bio logo.`,
        attachTo: {
          element: '.js-header-nav-home',
          on: 'right',
        },
        buttons: [
          {
            text: 'Next',
            classes: 'btn btn--small',
            action: () => {
              this.tour.next();
            },
          },
        ],
      });

      this.tour.addStep({
        title: 'Navigation',
        text: `Your profile is located here, this is where you will spend most of your time.`,
        attachTo: {
          element: '.js-header-nav-profile',
          on: 'right',
        },
        buttons: [
          {
            text: 'Next',
            classes: 'btn btn--small',
            action: () => {
              this.tour.next();
            },
          },
        ],
      });

      this.tour.addStep({
        title: 'Navigation',
        text: `Access your account settings, change your plan, and view your billing settings here.`,
        attachTo: {
          element: '.js-header-nav-account',
          on: 'right',
        },
        buttons: [
          {
            text: 'Next',
            classes: 'btn btn--small',
            action: () => {
              this.tour.next();
            },
          },
        ],
      });
    } else {
      this.tour.addStep({
        title: 'Navigation',
        text: `You can find the site navigation on the top of the screen. On larger screens, it will be on the left hand side.`,
        attachTo: {
          element: '.js-header-mobile',
          on: 'bottom',
        },
        buttons: [
          {
            text: 'Next',
            classes: 'btn btn--small',
            action: () => {
              this.tour.next();
            },
          },
        ],
      });
    }

    if (hasGettingStartedList) {
      this.tour.addStep({
        title: 'Profile',
        text: `Use our handy checklist to help get your profile set up and answer any questions you may have.`,
        attachTo: {
          element: '.js-getting-started-checklist',
          on: 'bottom',
        },
        buttons: [
          {
            text: 'Next',
            classes: 'btn btn--small',
            action: () => {
              this.tour.next();
            },
          },
        ],
      });
    }

    this.tour.addStep({
      title: 'Profile',
      text: `This is your profile. Open it up and start by adding some links or changing its appearance.`,
      attachTo: {
        element: '.js-profile-item',
        on: 'top',
      },
      buttons: [
        {
          text: 'Finish',
          classes: 'btn btn--small',
          action: () => {
            this.stop();
            this.clear();
          },
        },
      ],
    });

    this.tour.start();
  }

  onBeforeEach(to, from) {
    if (to.path !== '/') {
      this.stop();
      this.clear();
    } else this.tour.next();
  }

  onCancel() {
    this.clear();
  }

  clear() {
    store.dispatch('updateTourOption', 'home_page');
  }
}
