<template>
  <Fragment>
    <VText
      component="h2"
      variant="h3"
      :color="stepColor"
      style="margin-bottom: 0.75rem"
      >Step 1.</VText
    >
    <SettingsSection>
      <SettingsSectionTitle>Provide your website</SettingsSectionTitle>

      <ValidationObserver v-slot="{ handleSubmit }" slim>
        <form action="#" novalidate @submit.prevent="handleSubmit(kickoff)">
          <ValidationProvider
            v-slot="{ errors, ariaMsg, ariaInput }"
            rules="required|wizardUrl"
            name="website"
            slim
          >
            <FormGroup>
              <Label for="website">Your Website</Label>
              <Input
                id="website"
                v-model="website"
                name="website"
                type="url"
                placeholder="https://mywebsite.com"
                :has-error="errors.length > 0"
                :disabled="disabled || isLoading"
                v-bind="ariaInput"
              />
              <FormGroupHelp color="error" v-bind="ariaMsg">{{
                errors[0]
              }}</FormGroupHelp>
            </FormGroup>
          </ValidationProvider>

          <CardActions class="setup__actions" align="left">
            <Button
              v-if="step === 1"
              key="continue"
              type="submit"
              :disabled="disabled"
              :loading="isLoading"
              >Get Started 🪄</Button
            >
            <Button
              v-else
              key="over"
              variant="outline"
              @click="$emit('restart')"
              >Start Over</Button
            >
          </CardActions>

          <VText v-if="step === 1" color="gray600" style="margin-top: 1rem"
            >By clicking "Get Started", you represent and warrant that you own
            or have permission to use all of the content from the provided
            website.</VText
          >
        </form>
      </ValidationObserver>
    </SettingsSection>

    <SettingsSection v-show="isLoading" ref="loadingSection">
      <SettingsSectionHeader>
        <SettingsSectionTitle>
          Give us a moment, our wizards are working their magic 🪄
        </SettingsSectionTitle>
        <VText>This will take ~10-15 seconds.</VText>
      </SettingsSectionHeader>

      <RatioBox :ratio="480 / 322">
        <video
          class="wizards"
          src="https://media.giphy.com/media/3oxRmoTueKDmUguzL2/giphy.mp4"
          playsInline
          autoplay
          muted
          loop
        />
      </RatioBox>
    </SettingsSection>
  </Fragment>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { ref, nextTick, toRefs, watch } from 'vue';
import {
  Label,
  FormGroup,
  Input,
  FormGroupHelp,
  Button,
  RatioBox,
  VText,
  CardActions,
} from '@campsite-bio/component-lib';
import { Fragment } from 'vue-fragment';

import {
  SettingsSection,
  SettingsSectionTitle,
  SettingsSectionHeader,
} from '../../settings-section';
import { localAxios } from '../../../apis';
import toast from '../../../utils/toast';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    Label,
    FormGroup,
    Input,
    FormGroupHelp,
    Button,
    SettingsSection,
    SettingsSectionTitle,
    SettingsSectionHeader,
    Fragment,
    RatioBox,
    VText,
    CardActions,
  },

  props: {
    disabled: Boolean,

    step: {
      type: Number,
      required: true,
    },

    stepColor: {
      type: String,
      required: true,
    },

    campsiteId: {
      type: String,
      required: true,
    },

    site: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { campsiteId } = toRefs(props);
    const website = ref(null);
    const isLoading = ref(false);
    const loadingSection = ref(null);

    watch(
      () => props.site,
      (val) => {
        website.value = val;
      },
      { immediate: true },
    );

    async function kickoff() {
      isLoading.value = true;

      await nextTick();

      loadingSection.value.$el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });

      try {
        const { data } = await localAxios.post('/api/design/kickoff', {
          site: website.value,
          profile_id: campsiteId.value,
        });

        emit('kickoff', data.design_id, website.value);
      } catch (e) {
        console.error(e);
        const { error_message } = e.response?.data || {};
        toast.error(
          error_message ||
            'There was an error trying to get your website data. Please try again soon.',
          { timeout: 5000 },
        );
      }

      await nextTick();
      isLoading.value = false;
    }

    return { website, kickoff, isLoading, loadingSection };
  },
};
</script>

<style lang="scss" scoped>
.wizards {
  height: auto;
  width: 100%;
}

.setup__actions.setup__actions {
  margin-left: -0.5rem;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}
</style>
