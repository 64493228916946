import { render, staticRenderFns } from "./upgrade-to-pro-analytics.vue?vue&type=template&id=622f78f3&scoped=true&"
import script from "./upgrade-to-pro-analytics.vue?vue&type=script&lang=js&"
export * from "./upgrade-to-pro-analytics.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "622f78f3",
  null
  
)

export default component.exports