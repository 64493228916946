import { render, staticRenderFns } from "./add-link-btn.vue?vue&type=template&id=0ed90150&scoped=true&"
import script from "./add-link-btn.vue?vue&type=script&lang=js&"
export * from "./add-link-btn.vue?vue&type=script&lang=js&"
import style0 from "./add-link-btn.vue?vue&type=style&index=0&id=0ed90150&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ed90150",
  null
  
)

export default component.exports