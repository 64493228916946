<template>
  <div
    class="drawer"
    :class="classes"
    role="dialog"
    :aria-labeledby="`${_uid}drawer-title`"
    @click.stop
  >
    <div class="drawer__header">
      <span :id="`${_uid}drawer-title`" class="drawer__headline">{{
        title
      }}</span>
      <FlexSpacer />
      <IconButton
        class="drawer__close"
        size="1.25rem"
        aria-label="Close"
        @click="show = false"
      >
        <CloseIcon />
      </IconButton>
    </div>
    <div v-if="!lazyRender" class="drawer__container">
      <ul v-if="contentItems.length > 1" class="drawer__nav">
        <li
          v-for="(item, index) in contentItems"
          :key="index"
          class="drawer__nav-item"
          :class="item.tabClasses"
        >
          <button type="button" @click.prevent="select(item)">
            <component :is="item.icon" class="icon" />
            {{ item.name }}
          </button>
        </li>
      </ul>
      <div class="drawer__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import {
  FlexSpacer,
  IconButton,
  CloseIcon,
  InstagramIcon,
  ImageIcon,
  CanvaIcon,
  PlusIcon,
  PatternIcon,
  SmileOIcon,
} from '@campsite-bio/component-lib';
import { computed, toRefs } from 'vue';

import { useModel, useRoute } from '@/compositions';

export default {
  components: {
    FlexSpacer,
    IconButton,
    CloseIcon,
    InstagramIcon,
    ImageIcon,
    CanvaIcon,
    PlusIcon,
    PatternIcon,
    SmileOIcon,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    right: Boolean,
    left: Boolean,
    title: {
      type: String,
      default: null,
    },
    lazy: Boolean,
    wide: Boolean,
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const route = useRoute();
    const show = useModel(value, (val) => emit('input', val));

    const classes = computed(() => {
      return {
        'drawer--open': show.value,
        'drawer--right': props.right,
        'drawer--left': props.left,
        'drawer--wide': props.wide,
        'drawer--on-profile': route.value.path.includes('profile/'),
      };
    });

    return {
      classes,
      show,
    };
  },

  data() {
    return {
      contentItems: [],
      lazyRender: false,
      active: undefined,
    };
  },

  watch: {
    value(newValue) {
      this.show = newValue;
    },

    show(newValue) {
      this.$emit('input', newValue);
      if (newValue) {
        this.lazyRender = false;
        this.windowTop =
          window.pageYOffset || document.documentElement.scrollTop;
        this.body.style.position = 'fixed';
        this.body.style.width = '100%';
        this.body.style.overflowY = 'scroll';
        this.body.style.top = -this.windowTop + 'px';
        this.addListeners();

        setTimeout(() => {
          this.body.addEventListener('click', this.bodyClick);
        }, 50);

        this.$nextTick().then(() => {
          this.contentItems = this.$children.filter((el) => el.isDrawerItem);
          if (
            !this.active ||
            !this.contentItems.some((item) => item._uid === this.active._uid)
          )
            this.contentItems[0].isActive = true;
        });
      } else {
        this.body.removeEventListener('click', this.bodyClick);
        this.body.style.position = '';
        this.body.style.width = '';
        this.body.style.top = '';
        this.body.style.overflowY = '';
        window.scrollTo(0, this.windowTop);
        this.removeListeners();
      }
    },
  },

  created() {
    this.keydownBind = this.keydown.bind(this);
    this.lazyRender = this.lazy;
    this.show = this.value;
    this.contentItems = this.$children.filter((el) => el.isDrawerItem);
    this.body = document.querySelector('body');
  },

  mounted() {
    if (this.contentItems.length) this.contentItems[0].isActive = true;
  },

  beforeDestroy() {
    this.removeListeners();
  },

  methods: {
    select(selected) {
      this.contentItems.forEach((item) => {
        if (item._uid === selected._uid) {
          item.isActive = true;
          this.active = item;
        } else item.isActive = false;
      });
    },

    bodyClick(e) {
      // console.log('body click', e);
      if (!e.target.classList.contains('dz-hidden-input')) this.show = false;
    },

    scroll(e) {
      e.preventDefault();
    },

    addListeners() {
      document.addEventListener('keydown', this.keydownBind, false);
    },

    keydown(e) {
      switch (e.which) {
        // esc
        case 27:
          this.show = false;
          break;
      }
    },

    removeListeners() {
      document.removeEventListener('keydown', this.keydownBind, false);
    },
  },
};
</script>

<style lang="scss">
.drawer {
  background: #edeff0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  max-width: 31.25rem;
  top: 0;
  transition: all 350ms;
  transform: translateX(-100%);
  visibility: hidden;
  width: 95%;
  z-index: 102;

  &--open {
    visibility: visible;
    transform: translateX(0);
  }

  &--wide {
    max-width: 50rem;
  }
}

.drawer--right {
  left: auto;
  transform: translateX(100%);
  right: 0;

  &.drawer--open {
    transform: translateX(0);
  }
}

.drawer__header {
  border-bottom: 1px solid #d6dadc;
  align-items: center;
  display: flex;
  flex-shrink: 0;
  padding: 0.625rem 0.925rem;
}

.drawer__headline {
  font-family: $font-header;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}

.drawer__container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}

.drawer__content {
  display: flex;
  flex-grow: 1;
}

.drawer__item {
  width: 100%;

  &--content {
    padding: 0.625rem 0.925rem;
  }

  &--scroll {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.drawer__nav {
  background: #3b3f3b;
  color: #fff;
  display: flex;
  flex-shrink: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.drawer__nav-item {
  border-bottom: 1px solid rgba(#fff, 0.2);
  position: relative;
  width: 5.625rem;

  &:after {
    border-left: 0.3125rem solid transparent;
    border-top: 0.3125rem solid #3b3f3b;
    border-right: 0.3125rem solid transparent;
    content: '';
    position: absolute;
    opacity: 0;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
  }

  &.active {
    &:after {
      opacity: 1;
    }

    button {
      background: rgba(#fff, 0.1);
    }
  }

  button {
    background: none;
    border: none;
    padding: 0;
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 0.8125rem;
    line-height: 1.5;
    height: 3.75rem;
    justify-content: center;
    width: 100%;

    &:hover {
      background: rgba(#fff, 0.1);
    }
  }

  .v-svg-icon.v-svg-icon {
    font-size: 1.375rem;
  }
}

@media (min-width: 544px) {
  .drawer--on-profile {
    top: var(--header-height);
    z-index: 100;
  }

  .drawer__headline {
    font-size: 1.25rem;
  }

  .drawer__container {
    flex-direction: row;
  }

  .drawer__content {
    order: 1;
  }

  .drawer__nav {
    flex-direction: column;
    width: 5.625rem;
    order: 2;
  }

  .drawer__nav-item {
    &:after {
      border: none;
      border-bottom: 0.3125rem solid transparent;
      border-right: 0.3125rem solid #3b3f3b;
      border-top: 0.3125rem solid transparent;
      left: auto;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
    }

    button {
      height: 5rem;
    }

    .v-svg-icon.v-svg-icon {
      font-size: 1.5rem;
    }
  }
}

@media (min-width: 992px) {
  .drawer--on-profile {
    top: var(--profile-menu-height);
    z-index: 99;
  }
}
</style>
