import {
  FacebookLetterFIcon,
  GoogleIcon,
  TwitterIcon,
  InstagramIcon,
  LinkedinIcon,
  SnapchatIcon,
  TiktokIcon,
  YoutubeIcon,
  WhatsappIcon,
  QrCodeIcon,
  ThreadsIcon,
} from '@campsite-bio/component-lib';

export const REFERRER_ICONS = {
  FacebookLetterFIcon,
  GoogleIcon,
  TwitterIcon,
  InstagramIcon,
  LinkedinIcon,
  SnapchatIcon,
  TiktokIcon,
  YoutubeIcon,
  WhatsappIcon,
  QrCodeIcon,
  ThreadsIcon,
};

export function convertReferrerToIcon(name) {
  switch (name) {
    case 'Facebook':
      return FacebookLetterFIcon.name;
    case 'Google':
      return GoogleIcon.name;
    case 'Twitter':
      return TwitterIcon.name;
    case 'Instagram':
      return InstagramIcon.name;
    case 'LinkedIn':
      return LinkedinIcon.name;
    case 'Snapchat':
      return SnapchatIcon.name;
    case 'TikTok':
      return TiktokIcon.name;
    case 'YouTube':
      return YoutubeIcon.name;
    case 'WhatsApp':
      return WhatsappIcon.name;
    case 'CS QR Code':
      return QrCodeIcon.name;
    case 'Threads':
      return ThreadsIcon.name;

    default:
      return null;
  }
}
