import {
  convertImageUrlToEncodedUrl,
  MEDIA_SIZES,
} from '@campsite-bio/component-lib';
import { computed, ref, unref } from 'vue';

import { getAppSetting } from '@/utils';
import { useStore } from '@/compositions';
import toast from '@/utils/toast';
import { ORG_ROLE_COLLABORATOR, ORG_ROLE_OWNER } from '@/config';

export function useMember(member = null, options) {
  const { returnRef = false, memberId = null } = options || {};
  let localMember;
  const isLoading = ref(true);
  const store = useStore();

  if (returnRef) {
    localMember = ref(unref(member));
  } else {
    localMember = computed(() => {
      return unref(member);
    });
  }

  const id = computed(() => {
    return localMember.value?._id.$oid;
  });

  const userId = computed(() => {
    return localMember.value?.user_id.$oid;
  });

  const email = computed(() => {
    return localMember.value?.email;
  });

  const name = computed(() => {
    return localMember.value?.first_name || email.value;
  });

  const profileImage = computed(() => {
    return (
      localMember.value?.profile_image || getAppSetting('defaultProfileImage')
    );
  });

  const profileImageSrc = computed(() => {
    return convertImageUrlToEncodedUrl(
      profileImage.value,
      MEDIA_SIZES.profile_image,
    );
  });

  const isActive = computed(() => {
    return localMember.value?.status === 'accepted';
  });

  const isPending = computed(() => {
    return localMember.value?.status === 'pending';
  });

  const isDeclined = computed(() => {
    return localMember.value?.status === 'declined';
  });

  const organizationId = computed(() => {
    return localMember.value?.organization_id.$oid;
  });

  const invitedAt = computed(() => {
    return localMember.value?.invited_at;
  });

  const declinedAt = computed(() => {
    return localMember.value?.declined_at;
  });

  const role = computed(() => {
    return localMember.value?.role;
  });

  const isOwner = computed(() => {
    return role.value === ORG_ROLE_OWNER;
  });

  const isCollaborator = computed(() => {
    return role.value === ORG_ROLE_COLLABORATOR;
  });

  const disabled = computed(() => {
    return localMember.value?.disabled;
  });

  const meta = computed(() => {
    return localMember.value?.meta;
  });

  async function load() {
    isLoading.value = true;
    try {
      const data = await store.dispatch(
        'organizations/member',
        unref(memberId),
      );
      localMember.value = data;
      isLoading.value = false;
    } catch (e) {
      console.error(e);
      const { error_message } = e.response?.data || {};
      toast.error(
        error_message || 'There was an error trying to load the member',
      );
    }
  }

  return {
    member: localMember,
    id,
    name,
    email,
    profileImage,
    profileImageSrc,
    isActive,
    isPending,
    isDeclined,
    organizationId,
    invitedAt,
    declinedAt,
    role,
    isOwner,
    isCollaborator,
    disabled,
    userId,
    load,
    isLoading,
    meta,
  };
}
