<template>
  <SvgIcon viewBox="0 0 750 750">
    <defs
      ><clipPath id="6b633dd522"
        ><path
          d="M 26.191406 263.15625 L 249.875 263.15625 L 249.875 486.84375 L 26.191406 486.84375 Z M 26.191406 263.15625 "
          clip-rule="nonzero" /></clipPath
      ><clipPath id="15d6fc6ff2"
        ><path
          d="M 138.03125 263.15625 C 76.265625 263.15625 26.191406 313.230469 26.191406 375 C 26.191406 436.769531 76.265625 486.84375 138.03125 486.84375 C 199.800781 486.84375 249.875 436.769531 249.875 375 C 249.875 313.230469 199.800781 263.15625 138.03125 263.15625 "
          clip-rule="nonzero" /></clipPath
      ><clipPath id="88ed33ce47"
        ><path
          d="M 291.0625 285.894531 L 723.910156 285.894531 L 723.910156 369.75 L 291.0625 369.75 Z M 291.0625 285.894531 "
          clip-rule="nonzero" /></clipPath
      ><clipPath id="bc6d910028"
        ><path
          d="M 309.0625 285.894531 L 705.808594 285.894531 C 710.582031 285.894531 715.164062 287.792969 718.539062 291.167969 C 721.914062 294.542969 723.808594 299.121094 723.808594 303.894531 L 723.808594 351.550781 C 723.808594 356.324219 721.914062 360.902344 718.539062 364.277344 C 715.164062 367.652344 710.582031 369.550781 705.808594 369.550781 L 309.0625 369.550781 C 304.289062 369.550781 299.710938 367.652344 296.332031 364.277344 C 292.957031 360.902344 291.0625 356.324219 291.0625 351.550781 L 291.0625 303.894531 C 291.0625 299.121094 292.957031 294.542969 296.332031 291.167969 C 299.710938 287.792969 304.289062 285.894531 309.0625 285.894531 "
          clip-rule="nonzero" /></clipPath
      ><clipPath id="bc1738d2de"
        ><path
          d="M 454.871094 405.238281 L 513.738281 405.238281 L 513.738281 464.105469 L 454.871094 464.105469 Z M 454.871094 405.238281 "
          clip-rule="nonzero" /></clipPath
      ><clipPath id="fdbb963105"
        ><path
          d="M 469.871094 405.238281 L 498.738281 405.238281 C 507.019531 405.238281 513.738281 411.953125 513.738281 420.238281 L 513.738281 449.105469 C 513.738281 457.390625 507.019531 464.105469 498.738281 464.105469 L 469.871094 464.105469 C 461.585938 464.105469 454.871094 457.390625 454.871094 449.105469 L 454.871094 420.238281 C 454.871094 411.953125 461.585938 405.238281 469.871094 405.238281 "
          clip-rule="nonzero" /></clipPath
      ><clipPath id="fa8860b199"
        ><path
          d="M 295.929688 405.238281 L 354.796875 405.238281 L 354.796875 464.105469 L 295.929688 464.105469 Z M 295.929688 405.238281 "
          clip-rule="nonzero" /></clipPath
      ><clipPath id="7291749cee"
        ><path
          d="M 310.929688 405.238281 L 339.796875 405.238281 C 348.082031 405.238281 354.796875 411.953125 354.796875 420.238281 L 354.796875 449.105469 C 354.796875 457.390625 348.082031 464.105469 339.796875 464.105469 L 310.929688 464.105469 C 302.644531 464.105469 295.929688 457.390625 295.929688 449.105469 L 295.929688 420.238281 C 295.929688 411.953125 302.644531 405.238281 310.929688 405.238281 "
          clip-rule="nonzero" /></clipPath
      ><clipPath id="3dd631d2a5"
        ><path
          d="M 375.398438 405.238281 L 434.265625 405.238281 L 434.265625 464.105469 L 375.398438 464.105469 Z M 375.398438 405.238281 "
          clip-rule="nonzero" /></clipPath
      ><clipPath id="fc8524a673"
        ><path
          d="M 390.398438 405.238281 L 419.265625 405.238281 C 427.550781 405.238281 434.265625 411.953125 434.265625 420.238281 L 434.265625 449.105469 C 434.265625 457.390625 427.550781 464.105469 419.265625 464.105469 L 390.398438 464.105469 C 382.113281 464.105469 375.398438 457.390625 375.398438 449.105469 L 375.398438 420.238281 C 375.398438 411.953125 382.113281 405.238281 390.398438 405.238281 "
          clip-rule="nonzero" /></clipPath
      ><clipPath id="271102f685"
        ><path
          d="M 534.535156 405.238281 L 593.402344 405.238281 L 593.402344 464.105469 L 534.535156 464.105469 Z M 534.535156 405.238281 "
          clip-rule="nonzero" /></clipPath
      ><clipPath id="ab20e466d0"
        ><path
          d="M 549.535156 405.238281 L 578.402344 405.238281 C 586.683594 405.238281 593.402344 411.953125 593.402344 420.238281 L 593.402344 449.105469 C 593.402344 457.390625 586.683594 464.105469 578.402344 464.105469 L 549.535156 464.105469 C 541.25 464.105469 534.535156 457.390625 534.535156 449.105469 L 534.535156 420.238281 C 534.535156 411.953125 541.25 405.238281 549.535156 405.238281 "
          clip-rule="nonzero" /></clipPath
      ><clipPath id="8e767fb5a8"
        ><path
          d="M 614.199219 405.238281 L 673.066406 405.238281 L 673.066406 464.105469 L 614.199219 464.105469 Z M 614.199219 405.238281 "
          clip-rule="nonzero" /></clipPath
      ><clipPath id="40b05994d9"
        ><path
          d="M 629.199219 405.238281 L 658.066406 405.238281 C 666.347656 405.238281 673.066406 411.953125 673.066406 420.238281 L 673.066406 449.105469 C 673.066406 457.390625 666.347656 464.105469 658.066406 464.105469 L 629.199219 464.105469 C 620.914062 464.105469 614.199219 457.390625 614.199219 449.105469 L 614.199219 420.238281 C 614.199219 411.953125 620.914062 405.238281 629.199219 405.238281 "
          clip-rule="nonzero" /></clipPath></defs
    ><g clip-path="url(#6b633dd522)"
      ><g clip-path="url(#15d6fc6ff2)"
        ><path
          d="M 26.191406 263.15625 L 249.875 263.15625 L 249.875 486.84375 L 26.191406 486.84375 Z M 26.191406 263.15625 "
          fill-opacity="1"
          fill-rule="nonzero" /></g></g
    ><g clip-path="url(#88ed33ce47)"
      ><g clip-path="url(#bc6d910028)"
        ><path
          d="M 291.0625 285.894531 L 723.910156 285.894531 L 723.910156 369.449219 L 291.0625 369.449219 Z M 291.0625 285.894531 "
          fill-opacity="1"
          fill-rule="nonzero" /></g></g
    ><g clip-path="url(#bc1738d2de)"
      ><g clip-path="url(#fdbb963105)"
        ><path
          d="M 454.871094 405.238281 L 513.738281 405.238281 L 513.738281 464.105469 L 454.871094 464.105469 Z M 454.871094 405.238281 "
          fill-opacity="1"
          fill-rule="nonzero" /></g></g
    ><g clip-path="url(#fa8860b199)"
      ><g clip-path="url(#7291749cee)"
        ><path
          d="M 295.929688 405.238281 L 354.796875 405.238281 L 354.796875 464.105469 L 295.929688 464.105469 Z M 295.929688 405.238281 "
          fill-opacity="1"
          fill-rule="nonzero" /></g></g
    ><g clip-path="url(#3dd631d2a5)"
      ><g clip-path="url(#fc8524a673)"
        ><path
          d="M 375.398438 405.238281 L 434.265625 405.238281 L 434.265625 464.105469 L 375.398438 464.105469 Z M 375.398438 405.238281 "
          fill-opacity="1"
          fill-rule="nonzero" /></g></g
    ><g clip-path="url(#271102f685)"
      ><g clip-path="url(#ab20e466d0)"
        ><path
          d="M 534.535156 405.238281 L 593.402344 405.238281 L 593.402344 464.105469 L 534.535156 464.105469 Z M 534.535156 405.238281 "
          fill-opacity="1"
          fill-rule="nonzero" /></g></g
    ><g clip-path="url(#8e767fb5a8)"
      ><g clip-path="url(#40b05994d9)"
        ><path
          d="M 614.199219 405.238281 L 673.066406 405.238281 L 673.066406 464.105469 L 614.199219 464.105469 Z M 614.199219 405.238281 "
          fill-opacity="1"
          fill-rule="nonzero" /></g
    ></g>
  </SvgIcon>
</template>

<script>
import { SvgIcon } from '@campsite-bio/component-lib';

export default {
  components: {
    SvgIcon,
  },
};
</script>

<style lang="scss" scoped></style>
