import { captureException } from '@sentry/browser';
import axios from 'axios';
import store from '../store';
import { setClientVersion } from '../utils';
import Cookies from 'cookies-js';

export const localAxios = axios.create({
  withCredentials: true,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

localAxios.interceptors.request.use((config) => {
  config.headers['X-CSRF-Token'] = store.getters.csrfToken;

  return config;
});

localAxios.interceptors.response.use(
  (response) => {
    checkHeaders(response);

    if (Cookies.get('campsite_profile'))
      store.commit('profiles/set', {
        name: 'currentId',
        value: Cookies.get('campsite_profile'),
      });

    return response;
  },
  (error) => {
    checkHeaders(error.response);

    return Promise.reject(error);
  },
);

axios.interceptors.response.use(undefined, (error) => {
  if (!axios.isCancel(error) || error.name !== 'AbortError')
    captureException(error);

  return Promise.reject(error);
});

function checkHeaders(response) {
  if (!response || !response.headers) return;

  if (response.headers['x-client-version'])
    setClientVersion(response.headers['x-client-version']);
  if (response.headers['x-maintenance-mode'])
    store.dispatch(
      'tryUpdateMaintenanceMode',
      response.headers['x-maintenance-mode'] === 'true' ? true : false,
    );
}
