<template>
  <div>
    <ValidationProvider
      ref="provider"
      rules="required"
      name="sheet"
      :detect-input="false"
      slim
    >
      <template v-if="!hasGoogleSheetsAuthed">
        <VText style="margin: 15px 0 8px; font-size: 14px">
          <strong
            >We need to authorize your account with Google Sheets before you can
            select or create one. Please click below to get started.</strong
          >
        </VText>
        <GoogleBtn
          style="margin-bottom: 1rem"
          :auth-type="googleAuth ? 'reauthorize' : 'authorize'"
        />
      </template>

      <template v-else>
        <FormGroup v-if="sheetId">
          <SourceItem
            title="Google Sheet"
            :subtitle="sheetTitle"
            @click="clearSelected"
          />
        </FormGroup>

        <template v-else>
          <FormGroup>
            <Label component="span" block gutter-bottom>
              Select a Google Sheet
            </Label>
            <Button
              :disabled="!hasLoadedGDrive"
              :loading="isLoadingPicker"
              @click="openPicker"
              >Select Sheet</Button
            >
            <FormGroupHelp gutter-bottom
              >New sign ups are appended to the existing Google
              sheet</FormGroupHelp
            >
          </FormGroup>

          <Alert style="margin-bottom: 2rem" variant="outline">
            <InfoIcon slot="icon" />
            Learn how to setup a new Google Sheet for your opt-in form.
            <Button
              slot="actions"
              href="https://support.campsite.bio/en/articles/8337568-how-to-setup-your-google-sheet-for-an-opt-in-form"
              target="_blank"
              variant="flat"
              >View</Button
            >
          </Alert>
        </template>
      </template>
    </ValidationProvider>
  </div>
</template>

<script>
import {
  FormGroup,
  FormGroupHelp,
  Label,
  VText,
  Button,
  Alert,
  InfoIcon,
} from '@campsite-bio/component-lib';
import { computed, onMounted, ref, toRefs, watch } from 'vue';
import { ValidationProvider } from 'vee-validate';

import { useGdrivePicker, useStore } from '@/compositions';
import GoogleBtn from '../../../../authorize-google-btn';
import SourceItem from '../../source-item';
import { AUTHORIZATION_STATUS } from '@/config';
import { getAppSetting } from '@/utils';

export default {
  components: {
    FormGroup,
    FormGroupHelp,
    Label,
    VText,
    Button,
    Alert,
    InfoIcon,
    ValidationProvider,
    GoogleBtn,
    SourceItem,
  },

  props: {
    link: {
      type: Object,
      required: true,
    },

    type: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { link, type } = toRefs(props);
    const store = useStore();
    const provider = ref(null);
    const sheetId = ref(null);
    const sheetTitle = ref(null);
    const isLoadingPicker = ref(false);
    const picker = ref(false);
    const { hasLoaded: hasLoadedGDrive } = useGdrivePicker();

    const emailOptions = computed(() => {
      return link.value.email_options;
    });

    const googleAuth = computed(() => {
      return store.getters['profiles/googleAuthorization'];
    });

    const hasGoogleSheetsAuthed = computed(() => {
      return googleAuth.value?.status === AUTHORIZATION_STATUS.CONNECTED;
    });

    // After auth success, reload sheets
    watch(hasGoogleSheetsAuthed, (newVal) => {
      if (newVal) {
        loadSheets();
      }
    });

    watch(
      emailOptions,
      ({ destination_id, destination_title }) => {
        sheetId.value = destination_id;
        sheetTitle.value = destination_title;
      },
      { immediate: true },
    );

    async function openPicker() {
      isLoadingPicker.value = true;
      // Get the google access token from the current auth
      const { access_token } = await store.dispatch(
        'profiles/getGoogleAuth',
        googleAuth.value,
      );

      if (!picker.value) {
        await new Promise((res, rej) => {
          gapi.load('picker', { callback: res, onerror: rej });
        });
      }

      picker.value = new google.picker.PickerBuilder()
        .addView(google.picker.ViewId.SPREADSHEETS)
        .setOAuthToken(access_token)
        .setAppId(getAppSetting('googleAppId'))
        .setDeveloperKey(getAppSetting('googleApiKey'))
        .setCallback(pickerSelect)
        .build();
      picker.value.setVisible(true);
      isLoadingPicker.value = false;
    }

    function pickerSelect(data) {
      if (data.action === google.picker.Action.PICKED) {
        const doc = data.docs[0];
        sheetId.value = doc.id;
        sheetTitle.value = doc.name;
      }
    }

    function clearSelected() {
      sheetId.value = null;
      sheetTitle.value = null;
    }

    onMounted(() => {
      if (sheetId.value) provider.value.syncValue(sheetId.value);
    });

    watch(sheetId, () => {
      provider.value.syncValue(sheetId.value);
      provider.value.applyResult({
        errors: [],
        valid: sheetId.value,
        failedRules: {},
      });

      if (sheetId.value) {
        emit('change', {
          id: sheetId.value,
          title: sheetTitle.value,
        });
      }
    });

    return {
      provider,
      googleAuth,
      hasGoogleSheetsAuthed,
      sheetId,
      sheetTitle,
      openPicker,
      isLoadingPicker,
      clearSelected,
      hasLoadedGDrive,
    };
  },
};
</script>

<style lang="scss" scoped></style>
