<template>
  <Card>
    <CardTitle>
      <VText component="h2" variant="h4">Your profile is new</VText>
    </CardTitle>
    <CardText>
      <VText>
        We don't have enough data collected on your profile to provide you with
        any report data at this time. Please check back in a few weeks!
      </VText>
    </CardText>
    <CardActions>
      <Button :to="`/profile/${profileId}/analytics`">Back</Button>
    </CardActions>
  </Card>
</template>

<script>
import {
  Card,
  CardText,
  CardTitle,
  CardActions,
  VText,
  Button,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';

import { useStore } from '../../../compositions';

export default {
  components: {
    Card,
    CardText,
    CardTitle,
    CardActions,
    VText,
    Button,
  },

  setup() {
    const store = useStore();

    const profileId = computed(() =>
      store.getters['analytics/getField']('selectedProfile'),
    );

    return {
      profileId,
    };
  },
};
</script>

<style lang="scss" scoped></style>
