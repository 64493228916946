<template>
  <Input v-model="localValue" v-bind="$attrs">
    <template v-if="items.length > 1" slot="append">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <IconButton v-bind="attrs" @click="swap" v-on="on">
            <ExchangeIcon title="swap" />
          </IconButton>
        </template>
        <span>Swap between {{ items.length }} results</span>
      </v-tooltip>
    </template>
  </Input>
</template>

<script>
import { Input, IconButton, ExchangeIcon } from '@campsite-bio/component-lib';
import { ref, toRefs } from 'vue';

import { useModel } from '../../../compositions';

export default {
  components: {
    Input,
    IconButton,
    ExchangeIcon,
  },

  props: {
    value: {
      type: String,
      default: null,
    },

    items: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { value, items } = toRefs(props);
    const currentIndex = ref(0);

    const localValue = useModel(value, (val) => emit('input', val));

    function swap() {
      currentIndex.value =
        currentIndex.value === items.value.length - 1
          ? 0
          : currentIndex.value + 1;
      localValue.value = items.value[currentIndex.value];
    }

    return { localValue, swap };
  },
};
</script>

<style lang="scss" scoped></style>
