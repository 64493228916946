<template>
  <Dialog v-model="isOpen" title="Add a custom domain" max-width="500">
    <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }" slim>
      <form novalidate @submit.prevent="handleSubmit(save)">
        <CardText>
          <VText gutter-bottom>
            Access your profile from a non Campsite.bio domain.
          </VText>

          <ValidationProvider
            v-slot="{ errors, ariaMsg, ariaInput }"
            :rules="{
              required: true,
              min: 6,
              max: 100,
              regex: /^([a-zA-Z0-9-_]*\.){1,}([a-zA-Z]{2,})+$/,
              custom_domain: true,
            }"
            name="custom domain"
            slim
          >
            <FormGroup>
              <Label for="customdomain">Custom Domain</Label>
              <Input
                id="customdomain"
                v-model="customDomainLocal"
                type="text"
                name="customDomain"
                placeholder="ex. social.mydomain.com"
                autocomplete="off"
                autocapitalize="off"
                maxlength="100"
                v-bind="ariaInput"
                :has-error="errors.length > 0"
                required
              >
                <template slot="append">
                  <CheckIcon
                    v-show="
                      errors.length === 0 &&
                      customDomainLocal &&
                      customDomainLocal !== customDomain
                    "
                    color="success"
                  />
                  <ErrorOutlineIcon v-show="errors.length > 0" color="error" />
                </template>
              </Input>
              <FormGroupHelp color="error" v-bind="ariaMsg">{{
                errors[0]
              }}</FormGroupHelp>
              <FormGroupHelp v-show="errors.length === 0 && !customDomainLocal"
                >You can enter a subdomain or a root domain.</FormGroupHelp
              >
            </FormGroup>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors, ariaMsg, ariaInput }"
            :rules="confirmCustomDomainRules"
            name="confirm custom domain"
            slim
          >
            <FormGroup v-if="customDomainLocal">
              <Label for="confirmcustomdomain">Confirm Custom Domain</Label>
              <Input
                id="confirmcustomdomain"
                v-model="confirmCustomDomainLocal"
                type="text"
                name="confirmCustomDomain"
                autocomplete="off"
                autocapitalize="off"
                maxlength="100"
                v-bind="ariaInput"
                :has-error="errors.length > 0"
                required
              />
              <FormGroupHelp color="error" v-bind="ariaMsg">{{
                errors[0]
              }}</FormGroupHelp>
            </FormGroup>
          </ValidationProvider>

          <ValidationProvider
            v-if="isApexDomain"
            v-slot="{ errors, ariaMsg, ariaInput }"
            :rules="{ required: { allowFalse: false } }"
            name="terms"
            slim
          >
            <FormGroup>
              <FormControlLabel
                label="I understand that I'm transferring control of my DNS to Campsite.bio and am responsible for adding any preexisting DNS records"
              >
                <Checkbox v-model="terms" required v-bind="ariaInput" />
              </FormControlLabel>
              <FormGroupHelp color="error" v-bind="ariaMsg">{{
                errors[0]
              }}</FormGroupHelp>
            </FormGroup>
          </ValidationProvider>
        </CardText>
        <CardActions>
          <Button
            variant="flat"
            color="error"
            :disabled="isSaving"
            @click="close"
            >Cancel</Button
          >
          <Button
            color="success"
            variant="flat"
            type="submit"
            :loading="isSaving"
            >Start setup</Button
          >
        </CardActions>
      </form>
    </ValidationObserver>
  </Dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  CardText,
  CardActions,
  Button,
  VText,
  FormGroup,
  Label,
  Input,
  FormGroupHelp,
  ErrorOutlineIcon,
  CheckIcon,
  FormControlLabel,
  Checkbox,
} from '@campsite-bio/component-lib';
import { computed, onBeforeUnmount, onMounted, ref, toRefs, watch } from 'vue';

import { useModel, useStore } from '../../compositions';
import toast from '../../utils/toast';
import Dialog from './dialog';
import bus from '../../bus';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Dialog,
    CardText,
    CardActions,
    Button,
    VText,
    FormGroup,
    Label,
    Input,
    FormGroupHelp,
    ErrorOutlineIcon,
    CheckIcon,
    FormControlLabel,
    Checkbox,
  },

  props: {
    value: Boolean,
    customDomain: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const { customDomain, value } = toRefs(props);
    const isSaving = ref(false);
    const form = ref(null);
    const customDomainLocal = ref(null);
    const confirmCustomDomainLocal = ref(null);
    const isApexDomain = ref(false);
    const terms = ref(false);
    const isOpen = useModel(value, (val) => emit('input', val));

    watch(
      customDomain,
      (val) => {
        customDomainLocal.value = val;
        confirmCustomDomainLocal.value = null;
        isApexDomain.value = false;
        form.value?.reset();
      },
      { immediate: true },
    );

    const confirmCustomDomainRules = computed(() => {
      if (customDomainLocal.value) {
        return { required: true, is: customDomainLocal.value };
      }

      return {};
    });

    function close() {
      emit('input', false);
    }

    async function save() {
      if (isSaving.value) return;

      isSaving.value = true;

      try {
        await store.dispatch(
          'profiles/updateCustomDomain',
          customDomainLocal.value.toLowerCase(),
        );

        close();
        emit('save');
      } catch (e) {
        console.error(e);
        const { error_message } = e.response?.data || {};
        toast.error(error_message || 'Error trying to update your settings');
      }

      isSaving.value = false;
    }

    onMounted(() => {
      bus.$on('custom-domain-check', (data) => {
        isApexDomain.value = data.type === 'apex';
      });
    });

    onBeforeUnmount(() => {
      bus.$off('custom-domain-check');
    });

    return {
      form,
      confirmCustomDomainLocal,
      customDomainLocal,
      confirmCustomDomainRules,
      terms,
      save,
      isSaving,
      close,
      isOpen,
      isApexDomain,
    };
  },
};
</script>

<style lang="scss" scoped></style>
