import { useScriptTag } from '@vueuse/core';
import { defaultWindow, noop } from '@campsite-bio/component-lib';
import { ref } from 'vue';

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';

let hasLoaded = false;

export function useGdrivePicker(onLoaded = noop) {
  const window = defaultWindow;
  const hasLoadedLocal = ref(hasLoaded);

  // only load the SDK once
  if (hasLoaded && window && window.gapi) onLoaded(window.gapi);
  else {
    useScriptTag(GOOGLE_SDK_URL, () => {
      hasLoaded = true;
      hasLoadedLocal.value = true;
      onLoaded(window.gapi);
    });
  }

  return { hasLoaded: hasLoadedLocal };
}
