import {
  Link,
  LinkTitle,
  LinkDivider,
  LinkFeed,
  LinkEmbed,
  LinkOptInForm,
  LinkForm,
  LinkCarousel,
  LinkCommerce,
  LinkImageGrid,
  LinkText,
  LinkContactDetails,
  LinkGroup,
  LinkShop,
} from '@/components/links';

/**
 * Returns the correct link component based on the type
 * @param {String} type
 * @returns {Vue.Component}
 */
export function getLinkComponent(type) {
  switch (type) {
    case 'title':
      return LinkTitle;
    case 'delimiter':
      return LinkDivider;
    case 'feed':
      return LinkFeed;
    case 'embed':
      return LinkEmbed;
    case 'opt-in-form':
      return LinkOptInForm;
    case 'form':
      return LinkForm;
    case 'carousel':
      return LinkCarousel;
    case 'request':
    case 'support':
      return LinkCommerce;
    case 'image-grid':
      return LinkImageGrid;
    case 'text':
      return LinkText;
    case 'contact-details':
      return LinkContactDetails;
    case 'group':
      return LinkGroup;
    case 'shop':
      return LinkShop;
    default:
      return Link;
  }
}
