import { ref, watch } from 'vue';

// A vue composition to use pagination
export function usePagination(options = {}) {
  const {
    page: startPage = 1,
    pageSize = 20,
    onPageChange = () => {},
  } = options;
  const page = ref(startPage);

  function nextPage() {
    page.value += 1;
  }

  function prevPage() {
    page.value -= 1;
  }

  watch(page, (newPage) => {
    onPageChange(newPage);
  });

  return {
    page,
    pageSize,
    nextPage,
    prevPage,
  };
}
