import { computed, ref, watch } from 'vue';
import axios from 'axios';

import { isValidUrl } from '@/utils';
import { getMagicImages } from '@/utils/media';

export function useMagicImage(options) {
  const { url, isDisabled = false, immediate = false } = options;
  const images = ref(null);
  const data = ref(null);
  const previousUrl = ref(immediate ? null : url.value);
  const cancelSource = ref(null);
  const isLoading = ref(false);

  const validUrl = computed(() => {
    if (isDisabled) return null;

    return isValidUrl(url.value);
  });

  const show = computed(() => {
    return images.value && images.value.length > 0;
  });

  watch(
    validUrl,
    () => {
      if (isDisabled) return;

      // Load open graph images if a valid URL
      if (
        validUrl.value.isAccepted &&
        validUrl.value.isLink &&
        previousUrl.value !== url.value
      ) {
        loadImages();
      }
      previousUrl.value = url.value;
    },
    { immediate },
  );

  async function loadImages() {
    images.value = null;
    isLoading.value = true;

    try {
      if (cancelSource.value) cancelSource.value.cancel();
      cancelSource.value = axios.CancelToken.source();

      const responseData = await getMagicImages({
        url: url.value,
        cancelToken: cancelSource.value.token,
      });

      data.value = responseData;
      if (responseData && responseData.images)
        images.value = responseData.images;
    } catch (e) {
      if (axios.isCancel(e) || e.name === 'AbortError') return;
      console.error(e);
    }

    isLoading.value = false;
  }

  return { images, show, isLoading, data };
}
