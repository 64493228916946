import { computed } from 'vue';
import { DateTime } from 'luxon';
import {
  convertImageUrlToEncodedUrl,
  MEDIA_SIZES,
} from '@campsite-bio/component-lib';

import { getAppSetting, getMeta } from '../../utils';
import { useStore } from '../use-store';
import {
  ORG_ROLE_COLLABORATOR,
  ORG_ROLE_MEMBER,
  ORG_ROLE_OWNER,
} from '@/config';

export function useCurrentOrg() {
  const store = useStore();

  const hasOrganization = computed(
    () => store.getters['organizations/hasOrganization'],
  );

  const organization = computed(() =>
    store.getters['organizations/getField']('currentOrganization'),
  );
  const id = computed(() => organization.value?._id.$oid);
  const customerId = computed(() => organization.value?.stripe_customer);

  const isLoadingOrg = computed(() => {
    return store.getters['organizations/getField']('isLoading');
  });

  const isOwner = computed(() => {
    return organization.value?.role === ORG_ROLE_OWNER;
  });

  const isMember = computed(() => {
    return organization.value?.role === ORG_ROLE_MEMBER;
  });

  const isCollaborator = computed(() => {
    return organization.value?.role === ORG_ROLE_COLLABORATOR;
  });

  const hasAnalyticsAddon = computed(() => {
    const addons = getMeta('addons', [], organization.value?.meta);
    return addons.includes('analytics');
  });

  const createdAt = computed(() => {
    return DateTime.fromISO(organization.value?.created_at);
  });

  const name = computed(() => {
    return organization.value?.name;
  });

  const email = computed(() => {
    return organization.value?.email;
  });

  const isEmailVerified = computed(() => {
    return organization.value?.email_verified;
  });

  const isSpam = computed(() => {
    return organization.value?.is_spam;
  });

  const plan = computed(() => {
    return organization.value?.plan;
  });

  const planId = computed(() => {
    return organization.value?.plan_id;
  });

  const profileImage = computed(() => {
    return organization.value?.profile_image;
  });

  const profileImageSrc = computed(() => {
    return convertImageUrlToEncodedUrl(
      profileImage.value,
      MEDIA_SIZES.profile_image,
    );
  });

  const hasDefaultProfileImage = computed(() => {
    return profileImage.value === getAppSetting('defaultProfileImage');
  });

  const numberOfUsers = computed(() => {
    return organization.value?.number_of_users;
  });

  const numberOfUsersUsed = computed(() => {
    return organization.value?.number_of_users_used;
  });

  const numberOfProfiles = computed(() => {
    return organization.value?.number_of_campsites;
  });

  const freeAfter = computed(() => {
    return organization.value?.free_after_datetime;
  });

  const defaultMemberRole = computed(() => {
    return organization.value?.default_member_role;
  });

  const membersManageUniversalLinks = computed(() => {
    return organization.value?.members_manage_universal_links;
  });

  const canMembersCreateProfiles = computed(() => {
    return organization.value?.members_create_profiles;
  });

  const memberAccess = computed(() => {
    return organization.value?.member_access;
  });

  return {
    hasOrganization,
    organization,
    isLoadingOrg,
    id,
    customerId,
    hasAnalyticsAddon,
    createdAt,
    plan,
    planId,
    profileImage,
    profileImageSrc,
    hasDefaultProfileImage,
    name,
    email,
    isEmailVerified,
    isSpam,
    isOwner,
    isMember,
    isCollaborator,
    numberOfUsers,
    numberOfUsersUsed,
    numberOfProfiles,
    freeAfter,
    canMembersCreateProfiles,
    memberAccess,
    defaultMemberRole,
    membersManageUniversalLinks,
  };
}
