import { render, staticRenderFns } from "./wrapped-stories.vue?vue&type=template&id=134447ae&scoped=true&"
import script from "./wrapped-stories.vue?vue&type=script&lang=js&"
export * from "./wrapped-stories.vue?vue&type=script&lang=js&"
import style0 from "./wrapped-stories.vue?vue&type=style&index=0&id=134447ae&prod&lang=scss&"
import style1 from "./wrapped-stories.vue?vue&type=style&index=1&id=134447ae&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "134447ae",
  null
  
)

export default component.exports