<template>
  <Button
    class="filter-btn"
    variant="outline"
    size="small"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  >
    <slot />
  </Button>
</template>

<script>
import { Button, ButtonProps } from '@campsite-bio/component-lib';

export default {
  components: {
    Button,
  },

  props: ButtonProps,
};
</script>

<style lang="scss" scoped>
.filter-btn.filter-btn {
  background: #fff;
  padding: 0.25em 0.75em;

  &:disabled:not(.v-button--loading) {
    border-color: var(--g-color-gray-400);
    background: #fff;
    color: var(--c-bg-color);
    opacity: 1;
  }

  &::v-deep {
    .v-button__content {
      gap: 0.25rem;
    }

    .v-svg-icon {
      font-size: 1em;
    }
  }
}
</style>
