<template>
  <LinkWrapper v-bind="$props" @add-link="$emit('add-link', $event)">
    <LinkCard v-if="hasSetup">
      <LinkField>
        <Label block gutter-bottom>Embed Source</Label>
        <VText v-if="embedType" component="span" class="source">
          <component :is="embedType.iconComponent" />
          <span>{{ embedType.name }}</span>
        </VText>
        <VText v-if="embedType" component="span" class="source">
          <a :href="url" target="_blank" rel="noopener noreferrer" :title="url">
            <span>{{ formattedUrl }}</span>
            <ExternalLinkIcon />
          </a>
        </VText>
      </LinkField>

      <template slot="btm">
        <LinkBtn
          v-if="!isStatsHidden"
          :active="isExpanded('stats')"
          :aria-label="`Link analytics - ${link.number_of_clicks} clicks`"
          :aria-controls="`stats-${id}`"
          @click="toggleExpand('stats')"
        >
          <LineChartIcon slot="icon" />
          <template v-if="link.number_of_clicks" slot="stat">
            {{ link.number_of_clicks | numberFormatter(1) }}
          </template>
        </LinkBtn>
        <LinkBtn
          v-if="!isDeleted && !isScheduleHidden"
          aria-label="Schedule link"
          :active="isExpanded('schedule')"
          :highlight="isExpanded('schedule')"
          :aria-controls="`schedule-${id}`"
          @click="toggleExpand('schedule')"
        >
          <CalendarIcon slot="icon" />
        </LinkBtn>
        <v-tooltip v-if="!isDeleted && !isPinnedHidden" top>
          <template #activator="{ on }">
            <LinkBtn
              v-if="!isDeleted"
              aria-label="Pin link"
              :pinned="pinned"
              :disabled="isPinnedDisabled"
              v-on="on"
              @click="pinned = !pinned"
            >
              <PinIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>{{ pinnedText }}</span>
        </v-tooltip>
        <v-tooltip v-if="isDeleted" top>
          <template #activator="{ on }">
            <LinkBtn
              class="link__btn--restore js-link-restore"
              aria-label="Restore link"
              :disabled="isRestoring"
              v-on="on"
              @click="handleRestore"
            >
              <RepeatIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>Restore link</span>
        </v-tooltip>
      </template>

      <template slot="actions">
        <StatsExpand
          :is-open="isExpanded('stats')"
          @close="toggleExpand('stats')"
        />
        <ScheduleExpand
          :is-open="isExpanded('schedule')"
          :link="link"
          @close="toggleExpand('schedule')"
        />
        <EmbedSettingsExpand
          :is-open="isExpanded('default')"
          :embed-options="options"
          :embed-type="embedType"
          :link-embed-options="linkEmbedOptions"
          :link-id="id"
          @close="toggleExpand('default')"
          @save="saveOptions"
        />
      </template>
    </LinkCard>
    <EmbedSetup
      v-else
      :link-id="id"
      :embed-options="options"
      :is-deleting="isDeleting"
      @setup-complete="toggleExpand('embed')"
      @remove="handleDelete"
    />
  </LinkWrapper>
</template>

<script>
import {
  Card,
  CardText,
  CardActions,
  VText,
  Alert,
  InfoIcon,
  Button,
  Input,
  IconButton,
  VisibilityToggle,
  DragIcon,
  LineChartIcon,
  CalendarIcon,
  PinIcon,
  StarIcon,
  StarOIcon,
  RepeatIcon,
  ArchiveIcon,
  TrashIcon,
  ExternalLinkIcon,
  EmbedOutlineIcon,
  CircularLoader,
  FlexSpacer,
  Label,
  getEmbedType,
  EMBED_ICONS,
  List,
  ListItem,
  ListItemAction,
  ListItemTitle,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';

import { LinkBtn, LinkWrapper, LinkCard, LinkField } from '../components';
import { StatsExpand, ScheduleExpand } from '../expand';
import EmbedSetup from './embed-setup';
import EmbedSettingsExpand from './settings-expand.vue';
import { InputUrl } from '../../form';
import { EMBED_TYPES, EMBED_TYPES_WITH_CLICKS } from './embed-types';
import { LinkProps } from '../link-props';
import { useLink } from '../compositions';

export default {
  components: {
    Card,
    CardText,
    CardActions,
    VText,
    Alert,
    InfoIcon,
    Button,
    Input,
    IconButton,
    DragIcon,
    LineChartIcon,
    CalendarIcon,
    PinIcon,
    StarIcon,
    StarOIcon,
    RepeatIcon,
    ArchiveIcon,
    TrashIcon,
    ExternalLinkIcon,
    EmbedOutlineIcon,
    StatsExpand,
    ScheduleExpand,
    EmbedSettingsExpand,
    VisibilityToggle,
    LinkBtn,
    CircularLoader,
    FlexSpacer,
    InputUrl,
    Label,
    EmbedSetup,
    ...EMBED_ICONS,
    List,
    ListItem,
    ListItemAction,
    ListItemTitle,
    LinkWrapper,
    LinkCard,
    LinkField,
  },

  props: LinkProps,

  setup(props) {
    const { link, isSavingOptions, options, saveOptions, ...rest } = useLink(
      props,
      {
        name: 'embed',
        optionsName: 'embed',
      },
    );

    const embedType = computed(() => {
      if (!options.value.source) return null;
      return EMBED_TYPES.find((type) => type.source === options.value.source);
    });

    const showLinkStats = computed(() => {
      if (!options.value.source) return false;

      return EMBED_TYPES_WITH_CLICKS.includes(options.value.source);
    });

    const hasSetup = computed(() => {
      return options.value.has_setup;
    });

    const linkEmbedOptions = computed(() => {
      if (!link.value.url) return null;

      return getEmbedType(link.value.url);
    });

    const formattedUrl = computed(() => {
      if (!link.value.url) return null;

      const url = link.value.url.replace('https://', '');

      return url.length > 50 ? `${url.slice(0, 50)}...` : url;
    });

    return {
      ...rest,
      options,
      embedType,
      saveOptions,
      hasSetup,
      linkEmbedOptions,
      isSavingOptions,
      formattedUrl,
      showLinkStats,
    };
  },
};
</script>

<style lang="scss" scoped>
.source,
.source > a {
  align-items: center;
  display: flex;
  word-break: break-all;

  > * {
    margin-right: 0.5rem;
  }
}
</style>
