<template>
  <div :id="`group-${id}`" :aria-hidden="(!isGroupLinksOpen).toString()">
    <div v-if="isGroupLinksOpen" class="link-group__links" :style="styles">
      <div class="link-group__links__header">
        <span class="link-group__links__header-expand">
          <span> {{ numberOfLinks }} / 20</span> links
        </span>

        <FlexSpacer />

        <v-menu bottom max-height="500">
          <template #activator="{ on, attrs }">
            <Button
              v-bind="attrs"
              variant="flat"
              size="small"
              :loading="isAddingLink"
              :disabled="isLoadingLinks"
              v-on="on"
            >
              Add link
            </Button>
          </template>
          <LinkList
            show-link
            :exclude="['group', 'image-grid']"
            @click="addLink"
          />
        </v-menu>
        <Button
          variant="flat"
          size="small"
          :disabled="isAddingLink"
          @click="isGroupLinksOpen = !isGroupLinksOpen"
        >
          <VisibilityOffIcon />
          &nbsp; Hide links
        </Button>
      </div>

      <div class="link-group__links-container">
        <draggable
          group="links"
          draggable=".link-wrapper"
          handle=".link__drag"
          :animation="200"
          :swap-threshold="0.75"
          fallback-on-body
          :disabled="(isAtLimit && isDragging) || isGroupBeingDragged"
          direction="vertical"
          @update="onSortUpdate"
          @add="handleLinkDrop"
          @start="isGroupDragging = true"
          @end="isGroupDragging = false"
        >
          <template v-for="link in links">
            <component
              :is="getLinkComponent(link.type)"
              :key="link._id.$oid"
              ref="linksRef"
              :link="link"
              :hide-actions="['pinned', 'highlight']"
              is-add-link-disabled
            />
          </template>
        </draggable>

        <template v-if="isLoadingLinks && !links.length">
          <LoadingLink v-for="i in Math.max(numberOfLinks, 1)" :key="i" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref, watch } from 'vue';
import {
  IconButton,
  ChevronIcon,
  VisibilityIcon,
  VisibilityOffIcon,
  Button,
  FlexSpacer,
} from '@campsite-bio/component-lib';
import draggable from 'vuedraggable';

import { LinkList } from '../link-list';
import LoadingLink from '../loading-link';
import { getLinkComponent } from '@/utils';

export default {
  components: {
    IconButton,
    ChevronIcon,
    VisibilityIcon,
    VisibilityOffIcon,
    Button,
    FlexSpacer,
    LinkList,
    LoadingLink,
    draggable,
  },

  setup() {
    const isGroupDragging = ref(false);
    const { id, options } = inject('link');
    const { isGroupLinksOpen, loadLinks, lastLinkHeight, ...rest } =
      inject('group');

    const styles = computed(() => {
      return {
        '--last-link-height': `${lastLinkHeight.value}px`,
      };
    });

    watch(isGroupLinksOpen, (value) => {
      if (value) {
        loadLinks();
      }
    });

    return {
      id,
      isGroupDragging,
      isGroupLinksOpen,
      options,
      getLinkComponent,
      styles,
      ...rest,
    };
  },
};
</script>

<style lang="scss" scoped>
.link-group__links {
  --connector-color: var(--g-color-gray-200);

  padding-left: 1rem;
  padding-top: 0.25rem;
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      width: 1rem;
      height: 0.15rem;
      background-color: var(--connector-color);
      top: 50%;
      transform: translateY(-50%);
      left: -0.5rem;
    }

    &:after {
      position: absolute;
      left: -0.5rem;
      content: '';
      height: 100%;
      width: 0.15rem;
      background-color: var(--connector-color);
      top: -0.5rem;
    }
  }

  &__header-expand {
    color: var(--g-color-gray-600);
    padding-left: 1rem;
  }

  &-container {
    position: relative;

    &:before {
      position: absolute;
      left: -0.5rem;
      content: '';
      height: calc(100% - (var(--last-link-height) / 2) + 0.15rem);
      width: 0.15rem;
      background-color: var(--connector-color);
      top: -0.5rem;
    }

    &::v-deep {
      .link-wrapper {
        position: relative;

        &:before {
          position: absolute;
          content: '';
          width: 1rem;
          height: 0.15rem;
          background-color: var(--connector-color);
          top: calc(50% - 0.4rem);
          transform: translateY(-50%);
          left: -0.5rem;
        }
      }
    }
  }
}
</style>
