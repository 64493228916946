<template>
  <TabPanel class="panel" value="clicks">
    <ChartCard
      class="link__chart-card"
      title="Link clicks"
      :is-loading="isLoading"
      :percent-difference="percentDifference"
      :highlights="highlights"
    >
      <Badge
        v-if="percentDifference || typeof percentDifference === 'number'"
        slot="badge"
        :color="badgeColor"
        :content="percentDifferenceFormatted"
      />

      <ChartWrapper
        class="link__chart"
        width="100%"
        height="150"
        type="line"
        :options="chartOptions"
        :data="series"
      />
    </ChartCard>

    <div v-if="hasItemStats" class="link__item-wrapper">
      <VText component="h3" variant="h5">Item breakdown</VText>
      <VText gutter-bottom
        >Get a look at which items in your link are generating clicks.</VText
      >
      <ItemClicksTable
        :data="data ? data.clicks_by_item : null"
        :link-id="linkId"
        :is-loading="isLoading"
        :items="itemsToPass"
        :type="link.type"
      />
    </div>
  </TabPanel>
</template>

<script>
import { computed, toRefs } from 'vue';
import { TabPanel, useTheme, VText, Badge } from '@campsite-bio/component-lib';
import { DateTime } from 'luxon';

import {
  useApexOptions,
  ChartCard,
  useDataColors,
  ChartWrapper,
  useCategories,
} from '../../analytics';
import ItemClicksTable from './item-clicks-table';
import { percentageChange } from '@/utils';

export default {
  components: {
    ChartWrapper,
    ChartCard,
    TabPanel,
    ItemClicksTable,
    VText,
    Badge,
  },

  props: {
    period: {
      type: Number,
      required: true,
    },

    link: {
      type: Object,
      required: true,
    },

    linkId: {
      type: String,
      required: true,
    },

    numberOfClicks: {
      type: Number,
      required: true,
    },

    data: {
      type: Object,
      default: null,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const { data } = toRefs(props);
    const theme = useTheme();
    const dataColors = useDataColors();

    const stats = computed(() => {
      return props.data?.clicks_by_date || [];
    });

    const currentData = computed(() => {
      return stats.value?.filter(({ _id }) => _id.range === 'current');
    });

    const pastData = computed(() => {
      return stats.value?.filter(({ _id }) => _id.range === 'past');
    });

    const hasItemStats = computed(() => {
      return ['image-grid', 'carousel', 'feed'].includes(props.link.type);
    });

    const itemsToPass = computed(() => {
      if (props.link.type === 'carousel') return props.link.banners;
      if (props.link.type === 'feed') return props.link.feed_options.items;
      return null;
    });

    const series = computed(() => {
      if (!currentData.value) return null;

      const currentStats = {
        label: 'Clicks',
        data: [],
        backgroundColor: dataColors.value[0],
        borderColor: dataColors.value[0],
        pointBackgroundColor: dataColors.value[0],
        pointBorderColor: dataColors.value[0],
      };
      const pastStats = {
        label: 'Past Clicks',
        data: [],
        backgroundColor: theme.value.colors.gray200,
        borderColor: theme.value.colors.gray200,
        pointRadius: 0,
      };
      currentData.value.forEach((stat, index) => {
        currentStats.data.push(stat.count);
      });
      pastData.value.forEach((stat, index) => {
        pastStats.data.push(stat.count);
      });
      return {
        datasets: [currentStats, pastStats],
        labels: categories.value,
      };
    });

    const { categories } = useCategories({ allData: data, data: stats });

    const chartOptions = useApexOptions();

    const totalClicks = computed(() => {
      const totalClicks = currentData.value.reduce((a, b) => a + b.count, 0);
      return totalClicks || 0;
    });

    const totalPastClicks = computed(() => {
      const totalClicks = pastData.value.reduce((a, b) => a + b.count, 0);
      return totalClicks || 0;
    });

    const highlights = computed(() => {
      return [
        {
          title: totalClicks.value,
          subtitle: 'Clicks',
          color: dataColors.value[0],
        },
        {
          title: props.numberOfClicks,
          subtitle: 'Lifetime clicks',
          color: theme.value.colors.secondary,
        },
      ];
    });

    const startDate = computed(() => {
      return DateTime.fromISO(currentData.value[0]?._id.date).toFormat('MMM d');
    });

    const endDate = computed(() => {
      return DateTime.fromISO(
        currentData.value[currentData.value.length - 1]?._id.date,
      ).toFormat('MMM d');
    });

    const percentDifference = computed(() => {
      return percentageChange(totalPastClicks.value, totalClicks.value);
    });

    const badgeColor = computed(() => {
      return percentDifference.value >= 0 ? 'success' : 'info';
    });

    const percentDifferenceFormatted = computed(() => {
      if (typeof percentDifference.value === 'number')
        return `${percentDifference.value.toFixed(1)}%`;
      return percentDifference.value;
    });

    return {
      series,
      totalClicks,
      chartOptions,
      hasItemStats,
      highlights,
      startDate,
      endDate,
      percentDifference,
      percentDifferenceFormatted,
      badgeColor,
      itemsToPass,
    };
  },
};
</script>

<style lang="scss" scoped>
.panel {
  padding-top: 0.5rem;
}

.link__item-wrapper {
  margin-top: 1.75rem;
}

.link__chart-card.link__chart-card {
  border: none;
  min-height: unset;

  &::v-deep .v-chart-card__inner {
    padding: 0;
  }
}
</style>
