<template>
  <ListItem :disabled="disabled">
    <ListItemContent v-if="onlyUrl">
      <ItemUrlField v-model="url" />
    </ListItemContent>
    <template v-else-if="isEditing">
      <ListItemContent>
        <FormGroup :gutter-bottom="false" style="margin-bottom: 0.5rem">
          <Label :for="`title${_uid}`">Title</Label>
          <Input
            :id="`title${_uid}`"
            v-model="title"
            placeholder="The name of the product or item"
            maxlength="50"
          />
        </FormGroup>

        <ItemUrlField v-model="url" />
      </ListItemContent>
      <ListItemAction class="item-url-action">
        <IconButton aria-label="save url" @click="$emit('close')">
          <CheckIcon />
        </IconButton>
        <IconButton
          v-if="canDelete"
          aria-label="delete url"
          color="error"
          @click="$emit('remove')"
        >
          <TrashIcon />
        </IconButton>
      </ListItemAction>
    </template>
    <template v-else>
      <ListItemIcon v-if="!hideNumber" style="margin-right: 1.25rem">
        <FancyIconCircle size="1rem">{{ number }}</FancyIconCircle>
      </ListItemIcon>
      <ListItemContent>
        <ListItemTitle :title="urlItem.title || displayUrl">{{
          urlItem.title || displayUrl
        }}</ListItemTitle>
        <ListItemSubtitle v-if="urlItem.title" :title="displayUrl">
          {{ displayUrl }}
        </ListItemSubtitle>
        <ValidationProvider slim rules="url"
          ><input v-model="url" type="hidden"
        /></ValidationProvider>
      </ListItemContent>
      <ListItemAction>
        <IconButton
          aria-label="edit url"
          :disabled="disabled"
          @click="$emit('edit')"
        >
          <PencilIcon />
        </IconButton>
      </ListItemAction>
    </template>
  </ListItem>
</template>

<script>
import { computed, ref, watch } from 'vue';
import {
  ListItem,
  ListItemIcon,
  ListItemContent,
  ListItemTitle,
  ListItemSubtitle,
  ListItemAction,
  IconButton,
  PencilIcon,
  CheckIcon,
  TrashIcon,
  FormGroup,
  Label,
  Input,
  FancyIconCircle,
} from '@campsite-bio/component-lib';
import { ValidationProvider } from 'vee-validate';

import ItemUrlField from './item-url-field';

export default {
  components: {
    ListItem,
    ListItemIcon,
    ListItemContent,
    ListItemTitle,
    ListItemSubtitle,
    ListItemAction,
    IconButton,
    PencilIcon,
    CheckIcon,
    TrashIcon,
    FormGroup,
    Label,
    Input,
    FancyIconCircle,
    ItemUrlField,
    ValidationProvider,
  },

  props: {
    urlItem: {
      type: Object,
      default: null,
    },

    number: {
      type: Number,
      default: null,
    },

    hideNumber: Boolean,
    disabled: Boolean,
    isEditing: Boolean,
    canDelete: Boolean,
    onlyUrl: Boolean,
  },

  setup(props, { emit }) {
    const title = ref(null);
    const url = ref(null);

    const displayUrl = computed(() => {
      return props.urlItem.url
        ? props.urlItem.url.replace(/^https?:\/\//, '')
        : null;
    });

    watch(
      () => props.urlItem,
      (urlItem) => {
        if (urlItem) {
          title.value = urlItem.title;
          url.value = urlItem.url;
        }
      },
      { immediate: true },
    );

    watch([title, url], () => {
      emit('change', { title: title.value, url: url.value });
    });

    return { title, url, displayUrl };
  },
};
</script>

<style lang="scss" scoped>
.item-url-action {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
