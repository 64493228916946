<template>
  <div class="home">
    <IntroSection />
    <ProfilesSection />
    <NewsSection />
  </div>
</template>

<script>
import { NewsSection, IntroSection, ProfilesSection } from '../components/home';

export default {
  components: {
    NewsSection,
    IntroSection,
    ProfilesSection,
  },

  head: {
    title: 'Home',
  },
};
</script>

<style lang="scss" scoped>
.home {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
</style>
