<template>
  <div class="page" :class="{ 'page--menu-closed': !isSubmenuOpen }">
    <slot />
    <div class="page__content">
      <div class="grid grid--container">
        <transition name="fade" mode="out-in">
          <slot name="content">
            <router-view v-bind="routeProps" />
          </slot>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

import { useStore } from '../../compositions';

export default {
  props: {
    routeProps: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const store = useStore();

    const isSubmenuOpen = computed(() =>
      store.getters['getField']('isSubmenuOpen'),
    );

    return { isSubmenuOpen };
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  --submenu-width: 230px;

  @media (min-width: 1200px) {
    --submenu-width: 200px;
  }

  @media (min-width: 992px) {
    flex-direction: row;

    &--menu-closed {
      .page__content {
        margin-left: calc(var(--submenu-width) * -1);
      }
    }
  }

  &__content {
    background: var(--g-color-gray-100);
    flex-grow: 1;
    padding-bottom: 3rem;
    padding-top: 1rem;
    position: relative;
    transition: all var(--g-transition-speed);

    @media (min-width: 992px) {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05),
        0 4px 24px 2px rgba(0, 0, 0, 0.1);
      padding-left: 1.125rem;
      z-index: 102;
    }

    @media print {
      box-shadow: none;
      padding-left: 0;
    }
  }
}
</style>
