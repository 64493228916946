import { computed, unref } from 'vue';
import { numberFormatter, useTheme } from '@campsite-bio/component-lib';
import tinycolor from 'tinycolor2';

import { useDataColors } from './use-data-colors';
import { formatTooltipLabels } from '@/utils';
import { useOnDataColors } from './use-on-data-colors';

export function useApexOptions(options = {}) {
  const theme = useTheme();
  const dataColors = useDataColors();
  const onDataColors = useOnDataColors();

  return computed(() => {
    const {
      layout,
      scales,
      legend,
      tooltip,
      plugins,
      datalabels,
      elements,
      ...rest
    } = unref(options);

    return {
      responsive: true,
      maintainAspectRatio: false,
      backgroundColor: dataColors.value,
      borderColor: dataColors.value,
      fonts: {
        family: theme.value.font.bodyFamily,
      },
      layout: {
        padding: {
          top: 10,
          bottom: 10,
        },
        ...layout,
      },
      interaction: {
        mode: 'index',
        intersect: false,
      },
      elements: {
        ...elements,
      },
      scales: {
        x: {
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            maxRotation: 0,
          },
        },
        y: {
          border: {
            display: false,
          },
          grid: {
            color: theme.value.colors.gray100,
          },
        },
        ...scales,
      },
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
          ...legend,
        },
        tooltip: {
          backgroundColor: tinycolor(theme.value.colors.bg)
            .setAlpha(0.95)
            .toString(),
          titleColor: theme.value.colors.onBg,
          bodyColor: theme.value.colors.onBg,
          padding: 10,
          boxPadding: 3,
          bodySpacing: 5,
          xAlign: 'right',
          callbacks: {
            label: formatTooltipLabels,
          },
          ...tooltip,
        },
        datalabels: {
          display: false,
          color: onDataColors.value,
          formatter(value) {
            return value ? numberFormatter(value, 2) : null;
          },
          ...datalabels,
        },
        ...plugins,
      },
      ...rest,
    };
  });
}
