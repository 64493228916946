<template>
  <ReferralsTable
    :is-loading="isLoading"
    :data="referralData"
    :has-results="hasResults"
    :data-title="startCase(type)"
  />
</template>

<script>
import { computed } from 'vue';
import startCase from 'lodash/startCase';

import { convertReferrerToIcon } from '../referrer-icons';
import { ReferralsTable } from '../tables';

export default {
  components: {
    ReferralsTable,
  },

  props: {
    allData: {
      type: Object,
      default: null,
    },

    data: {
      type: Array,
      default: null,
    },

    isLoading: Boolean,

    /**
     * clicks|views
     */
    type: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const referralData = computed(() => {
      if (!props.data) return [];

      const stats = props.data.reduce((acc, { _id, count, unique }) => {
        acc.push([_id, count, unique, convertReferrerToIcon(_id)]);
        return acc;
      }, []);

      return stats.sort((a, b) => b[1] - a[1]).slice(0, 10);
    });

    const hasResults = computed(() => referralData.value.length > 0);

    return { referralData, hasResults, startCase };
  },
};
</script>

<style lang="scss" scoped></style>
