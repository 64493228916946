<template>
  <FancyAd :dismissible="false">
    <picture slot="img">
      <source
        srcset="~images/ads/bulk-selection-vertical.png"
        media="(min-width: 768px)"
      />
      <img
        class="upgrade-to-pro__img"
        src="~images/ads/bulk-selection.png"
        alt="Bulk selection tools"
        loading="lazy"
      />
    </picture>

    <VText component="h2" variant="h4" gutter-bottom
      >Streamline Your Link Management with Bulk Selection</VText
    >
    <ul>
      <li>
        Select multiple links at once to easily delete, archive, or group them.
      </li>
      <li>
        Group multiple links under one group link to save space on your profile.
      </li>
      <li>
        Save time and effort by managing your links in bulk instead of
        individually.
      </li>
    </ul>

    <InlineLink component="span">Start your free trial</InlineLink>
  </FancyAd>
</template>

<script>
import { VText, InlineLink } from '@campsite-bio/component-lib';

import FancyAd from './fancy-ad';

export default {
  components: {
    FancyAd,
    VText,
    InlineLink,
  },
};
</script>

<style lang="scss" scoped></style>
