<template>
  <div>
    <ValidationProvider
      v-slot="{ errors, ariaMsg }"
      ref="provider"
      :rules="{ required: { allowFalse: false } }"
      name="instagram accounts"
      slim
      :detect-input="false"
    >
      <FormGroup>
        <FormGroupHeader>
          <Label component="span">Instagram Accounts</Label>
          <FlexSpacer />
          <InlineLink
            v-if="accounts.length > 0"
            style="font-size: 0.875rem"
            :disabled="!isProfileOwner"
            @click="isMappingDialogOpen = true"
          >
            Map accounts
          </InlineLink>
        </FormGroupHeader>
        <List class="instagram-list">
          <template v-if="isLoading">
            <ListItem>
              <ListItemAction>
                <Skeleton height="25px" width="25px" />
              </ListItemAction>
              <ListItemTitle><Skeleton width="75px" /></ListItemTitle>
            </ListItem>
          </template>
          <template v-else-if="accounts.length === 0">
            <ListItem>
              <ListItemTitle>No accounts found.</ListItemTitle>
            </ListItem>
          </template>

          <ListItemGroup
            v-model="selectedAccounts"
            multiple
            :multiple-limit="accountLimit"
            aria-label="Instagram Accounts"
          >
            <InstagramListItem
              v-for="{ instagramAccount, account } in accounts"
              :key="instagramAccount.id"
              :value="instagramAccount.id"
              :disabled="addedAccountUidsMerged.includes(instagramAccount.id)"
              :disable-mapping="!isProfileOwner"
              :instagram-account="instagramAccount"
              :account="account"
              @map="isMappingDialogOpen = true"
            />
          </ListItemGroup>
        </List>
        <FormGroupHelp color="error" v-bind="ariaMsg" gutter-bottom>{{
          errors[0]
        }}</FormGroupHelp>
        <FormGroupHelp v-if="!isProfileOwner" color="error" gutter-bottom
          >Only a profile owner can map these Instagram accounts to a
          profile.</FormGroupHelp
        >
        <FormGroupHelp
          >Select which Instagram accounts to sync. You're using
          {{ addedInstagramAccounts }} /
          {{ totalInstagramAccounts }}.</FormGroupHelp
        >
      </FormGroup>
    </ValidationProvider>

    <AppAuthAccountMappingDialog
      v-if="isProfileOwner"
      v-model="isMappingDialogOpen"
      :accounts="accountsToMap"
      @save="handleMappingSave"
    />
  </div>
</template>

<script>
import {
  FormGroup,
  FormGroupHeader,
  FlexSpacer,
  InlineLink,
  FormGroupHelp,
  Skeleton,
  Label,
  List,
  ListItemGroup,
  ListItem,
  ListItemAction,
  ListItemTitle,
} from '@campsite-bio/component-lib';
import { ValidationProvider } from 'vee-validate';
import { computed, onMounted, ref, toRefs, watch, watchEffect } from 'vue';

import {
  useCurrentUserProfileEntitlements,
  useModel,
  useProfileLoader,
  useStore,
} from '../../../../compositions';
import toast from '../../../../utils/toast';
import { localAxios } from '../../../../apis';
import InstagramListItem from './instagram-list-item';
import { AppAuthAccountMappingDialog } from '../app-auth-account-mapping-dialog';

export default {
  components: {
    FormGroup,
    FormGroupHelp,
    FormGroupHeader,
    FlexSpacer,
    InlineLink,
    Skeleton,
    Label,
    List,
    ListItemGroup,
    ListItem,
    ListItemAction,
    ListItemTitle,
    InstagramListItem,
    ValidationProvider,
    AppAuthAccountMappingDialog,
  },

  props: {
    authId: {
      type: String,
      required: true,
    },

    value: {
      type: Array,
      default: null,
    },

    profileId: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const { value } = toRefs(props);
    const localValue = useModel(value, (val) => emit('input', val));
    const accounts = ref([]);
    const selectedAccounts = ref([]);
    const isLoading = ref(false);
    const isMappingDialogOpen = ref(false);
    const provider = ref(null);
    const addedInstagramAccounts = ref(0);
    const addedAccountUids = ref([]);
    const { profile } = useProfileLoader(props.profileId);
    const { isProfileOwner } = useCurrentUserProfileEntitlements(
      profile,
      false,
    );

    // Total number of accounts that the user can add (equal to their profile account)
    const totalInstagramAccounts = computed(() => {
      return store.getters.numberOfCampsites;
    });

    // How many we should limit the selection too
    const accountLimit = computed(() => {
      return totalInstagramAccounts.value - addedInstagramAccounts.value;
    });

    // Remove any of the returned UIDs that have already been synced in this auth
    const addedAccountUidsMerged = computed(() => {
      return addedAccountUids.value.filter((uid) => {
        return !accounts.value?.find((account) => {
          return account.account?.uid === uid;
        });
      });
    });

    // Only map accounts that are enabled
    const accountsToMap = computed(() => {
      return accounts.value.filter(({ instagramAccount }) => {
        return !addedAccountUidsMerged.value.includes(instagramAccount.id);
      });
    });

    watchEffect(() => {
      if (!provider.value) return;
      if (isLoading.value) provider.value.syncValue(false);
      else provider.value.syncValue(selectedAccounts.value.length > 0);
    });

    watch(
      localValue,
      () => {
        const mappedValues = localValue.value?.map(({ uid }) => uid) || [];
        // If the value has changed, update the selectedAccounts
        if (mappedValues.join(',') !== selectedAccounts.value.join(',')) {
          selectedAccounts.value = mappedValues;
        }
      },
      { immediate: true },
    );

    watch(selectedAccounts, () => {
      localValue.value = selectedAccounts.value.map((id) => {
        const account = accounts.value.find(
          (a) => a.instagramAccount.id === id,
        );
        const matchingAccount =
          localValue.value?.find((a) => a.uid === id) || {};
        return {
          ...matchingAccount,
          uid: account.instagramAccount.id,
          name: account.instagramAccount.username,
          campsite_id: account.account?.campsite_id,
        };
      });
    });

    async function loadAccounts() {
      isLoading.value = true;

      try {
        const { data } = await localAxios.get(
          `/api/profiles/authorizations/${props.profileId}/${props.authId}/instagram-accounts`,
        );
        addedInstagramAccounts.value = data.number_of_ig_accounts;
        addedAccountUids.value = data.account_uids;
        accounts.value = data.accounts.map((account) => {
          const matchingAccount = localValue.value?.find(
            (a) => a.uid === account.id,
          );

          return {
            instagramAccount: account,
            account: matchingAccount,
          };
        });
      } catch (e) {
        console.error(e);
        toast.error(
          'There was an error trying to load your instagram business accounts.',
        );
      }

      isLoading.value = false;
    }

    onMounted(loadAccounts);

    function handleMappingSave(mappedAccounts) {
      console.log('mappedAccounts', mappedAccounts);
      // Update the accounts with the new values
      accounts.value = accounts.value.map((account) => {
        const matchingAccount = mappedAccounts.find(
          (a) => a.instagramAccount.id === account.instagramAccount.id,
        );

        if (!matchingAccount) return account;

        return {
          account: matchingAccount.account,
          instagramAccount: account.instagramAccount,
        };
      });
      // Update the selected accounts (this in turn updates the localValue)
      selectedAccounts.value = accounts.value.reduce(
        (accum, { account, instagramAccount }) => {
          const matchingAccount =
            mappedAccounts.find(
              (a) => a.instagramAccount.id === instagramAccount.id,
            ) || {};
          // If the account is already selected, or the matching account has a campsite_id add it to the list
          if (account || matchingAccount?.campsite_id) {
            accum.push(instagramAccount.id);
          }
          return accum;
        },
        [],
      );
      console.log('selectedAccounts', selectedAccounts.value);
    }

    return {
      localValue,
      accounts,
      selectedAccounts,
      isLoading,
      provider,
      addedInstagramAccounts,
      totalInstagramAccounts,
      accountLimit,
      addedAccountUidsMerged,
      isMappingDialogOpen,
      accountsToMap,
      handleMappingSave,
      isProfileOwner,
    };
  },
};
</script>

<style lang="scss" scoped>
.instagram-list.instagram-list {
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0;
}
</style>
