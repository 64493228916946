<template>
  <ListItem
    component="button"
    type="button"
    @click="$emit('click', 'carousel')"
  >
    <ListItemAction>
      <CarouselIcon size="1.5rem" />
    </ListItemAction>
    <ListItemContent>
      <ListItemTitle>Carousel</ListItemTitle>
      <ListItemSubtitle wrap
        >A swipe-able carousel of your images.</ListItemSubtitle
      >
    </ListItemContent>
  </ListItem>
</template>

<script>
import {
  ListItem,
  ListItemAction,
  ListItemContent,
  ListItemSubtitle,
  ListItemTitle,
  CarouselIcon,
} from '@campsite-bio/component-lib';

export default {
  components: {
    ListItem,
    ListItemAction,
    ListItemContent,
    ListItemSubtitle,
    ListItemTitle,
    CarouselIcon,
  },
};
</script>

<style lang="scss" scoped></style>
