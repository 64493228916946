<template>
  <item-wrapper :loading="isSaving || isDeleting" class="merge-field">
    <div class="merge-field__content">
      <ValidationProvider
        v-slot="{ errors, ariaInput, ariaMsg }"
        rules="required|max:50"
        name="label"
        slim
        immediate
      >
        <FormGroup>
          <Label :for="`${_uid}label`">Field Label *</Label>
          <Input
            :id="`${_uid}label`"
            v-model="label"
            autocomplete="off"
            maxlength="50"
            placeholder="First Name"
            v-bind="ariaInput"
          />
          <FormGroupHelp color="error" v-bind="ariaMsg">{{
            errors[0]
          }}</FormGroupHelp>
        </FormGroup>
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors, ariaInput, ariaMsg }"
        :rules="validationRules"
        name="name"
        slim
        immediate
      >
        <FormGroup>
          <Label :for="`${_uid}tag`">{{ fieldName.label }} *</Label>
          <Input
            :id="`${_uid}tag`"
            v-model="name"
            autocomplete="off"
            :maxlength="fieldName.rules.max.toString()"
            placeholder="FNAME"
            :pattern="isDefault ? fieldName.pattern : null"
            :disabled="!canEditFieldName || isDefault"
            v-bind="ariaInput"
          />
          <FormGroupHelp color="error" v-bind="ariaMsg">{{
            errors[0]
          }}</FormGroupHelp>
          <FormGroupHelp v-if="isDefault ? null : fieldName.helpText">{{
            fieldName.helpText
          }}</FormGroupHelp>
        </FormGroup>
      </ValidationProvider>

      <FormGroup>
        <Label :for="`${_uid}hint`">Hint Text</Label>
        <Input :id="`${_uid}hint`" v-model="hintText" autocomplete="off" />
        <FormGroupHelp>
          Enter a hint that will be displayed in the field
        </FormGroupHelp>
      </FormGroup>

      <FormGroup v-if="!hideFieldDefault && !isDefault">
        <Label :for="`${_uid}default`">Default Value</Label>
        <Input
          :id="`${_uid}default`"
          v-model="defaultValue"
          autocomplete="off"
          maxlength="255"
        />
        <FormGroupHelp
          >The default value is used when a value isn't set</FormGroupHelp
        >
      </FormGroup>
    </div>

    <template v-if="!isDefault" slot="bottom">
      <Toggle v-model="required">Required field</Toggle>

      <FlexSpacer />

      <v-dialog
        v-model="dialog"
        persistent
        max-width="300"
        @keydown.esc="dialog = false"
      >
        <template #activator="{ on, attrs }">
          <LinkBtn
            type="button"
            aria-label="Delete merge field"
            v-bind="attrs"
            v-on="on"
          >
            <TrashIcon slot="icon" />
          </LinkBtn>
        </template>
        <Card>
          <CardText
            ><VText
              >This merge field will be permanently deleted.</VText
            ></CardText
          >
          <CardActions>
            <Button variant="flat" @click="dialog = false">Cancel</Button>
            <Button
              color="error"
              variant="flat"
              :loading="isDeleting"
              @click="remove"
              >Confirm</Button
            >
          </CardActions>
        </Card>
      </v-dialog>
    </template>
  </item-wrapper>
</template>

<script>
import {
  Card,
  CardText,
  CardActions,
  VText,
  Button,
  FlexSpacer,
  Toggle,
  Input,
  FormGroup,
  FormGroupHelp,
  Label,
  TrashIcon,
} from '@campsite-bio/component-lib';
import { ValidationProvider } from 'vee-validate';
import debounce from 'lodash/debounce';
import { computed, toRefs } from 'vue';

import toast from '../../../../utils/toast';
import { LinkBtn } from '../../components';
import ItemWrapper from '../../components/item-wrapper';

export default {
  components: {
    Card,
    CardText,
    CardActions,
    VText,
    Button,
    FlexSpacer,
    Toggle,
    Input,
    FormGroup,
    FormGroupHelp,
    Label,
    LinkBtn,
    ItemWrapper,
    TrashIcon,
    ValidationProvider,
  },

  props: {
    link: { type: Object, required: true },
    mergeField: { type: Object, required: true },
    canEditFieldName: Boolean,
    hideFieldDefault: Boolean,
    fieldName: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { mergeField, fieldName } = toRefs(props);

    const isDefault = computed(() => {
      return mergeField.value.is_default;
    });

    const validationRules = computed(() => {
      if (isDefault.value) {
        return 'required';
      }

      return {
        required: true,
        ...fieldName.value.rules,
      };
    });

    return {
      isDefault,
      validationRules,
    };
  },

  data() {
    return {
      isSaving: false,
      isDeleting: false,
      dialog: false,
      ignoreUpdate: false,
    };
  },

  computed: {
    id() {
      return this.mergeField._id.$oid;
    },

    label: {
      get() {
        return this.mergeField.label;
      },
      set(value) {
        this.$store.commit('links/updateLinkMergeFieldLabel', {
          value,
          mergeFieldId: this.id,
          linkId: this.link._id.$oid,
        });
      },
    },

    name: {
      get() {
        return this.mergeField.name;
      },
      set(value) {
        this.$store.commit('links/updateLinkMergeFieldName', {
          value,
          mergeFieldId: this.id,
          linkId: this.link._id.$oid,
        });
      },
    },

    hintText: {
      get() {
        return this.mergeField.hint_text;
      },
      set(value) {
        this.$store.commit('links/updateLinkMergeFieldHintText', {
          value,
          mergeFieldId: this.id,
          linkId: this.link._id.$oid,
        });
      },
    },

    defaultValue: {
      get() {
        return this.mergeField.default_value;
      },
      set(value) {
        this.$store.commit('links/updateLinkMergeFieldDefaultValue', {
          value,
          mergeFieldId: this.id,
          linkId: this.link._id.$oid,
        });
      },
    },

    required: {
      get() {
        return this.mergeField.required;
      },
      set(value) {
        this.$store.commit('links/updateLinkMergeFieldRequired', {
          value,
          mergeFieldId: this.id,
          linkId: this.link._id.$oid,
        });
      },
    },
  },

  watch: {
    'mergeField.name': function (newValue) {
      this.name = newValue.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
    },

    mergeField: {
      deep: true,
      handler() {
        if (!this.ignoreUpdate) this.saveDebounce();
      },
    },
  },

  created() {
    this.saveDebounce = debounce(this.save.bind(this), 750);
  },

  methods: {
    save() {
      this.isSaving = true;

      this.$store
        .dispatch('links/updateLinkMergeField', {
          linkId: this.link._id.$oid,
          id: this.id,
          mergeField: this.mergeField,
        })
        .then((json) => {
          this.isSaving = false;
          if (!json.success) {
            toast.error(
              json.error_message ||
                'There was an error trying to update your merge field',
              { timeout: 3000 },
            );
          }
        })
        .catch((error) => {
          this.isSaving = false;
          toast.error('There was an error trying to save the merge field');
        });
    },

    remove() {
      this.isDeleting = true;

      this.$store
        .dispatch('links/deleteLinkMergeField', {
          linkId: this.link._id.$oid,
          mergeFieldId: this.id,
        })
        .then((json) => {
          this.isDeleting = false;
        })
        .catch((error) => {
          this.isDeleting = false;
          toast.error('There was an error trying to remove the merge field');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.merge-field {
  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    align-items: flex-start;
  }
}
</style>
