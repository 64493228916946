<template>
  <ValidationObserver ref="form" slim>
    <Fragment>
      <CardText>
        <VText gutter-bottom
          >Grant us access to your Facebook to get your Instagram account posts,
          stories, and other stats available in our channels report.</VText
        >

        <ValidationProvider
          v-if="!profileId"
          v-slot="{ errors, ariaMsg, ariaInput }"
          rules="required"
          name="profile"
          slim
        >
          <FormGroup>
            <Label for="profile">Select a profile</Label>
            <Select id="profile" v-model="selectedProfileId" v-bind="ariaInput">
              <option :value="null">- Select a profile -</option>
              <optgroup label="Profiles">
                <option
                  v-for="profile in profilesSorted.enabled"
                  :key="profile._id.$oid"
                  :value="profile._id.$oid"
                >
                  {{ profile.username }}
                </option>
              </optgroup>
              <optgroup
                v-if="profilesSorted.disabled && profilesSorted.disabled.length"
                label="Already authorized"
              >
                <option
                  v-for="profile in profilesSorted.disabled"
                  :key="profile._id.$oid"
                  :value="profile._id.$oid"
                  disabled
                >
                  {{ profile.username }}
                </option>
              </optgroup>
            </Select>
            <FormGroupHelp v-if="!errors.length"
              >Attach this account to one of your profiles</FormGroupHelp
            >
            <FormGroupHelp color="error" v-bind="ariaMsg">{{
              errors[0]
            }}</FormGroupHelp>
          </FormGroup>
        </ValidationProvider>
      </CardText>
      <CardActions align="center" direction="column">
        <Button @click="handleAuthorize">Connect Facebook</Button>
      </CardActions>
    </Fragment>
  </ValidationObserver>
</template>

<script>
import {
  CardText,
  VText,
  CardActions,
  Button,
  FormGroup,
  FormGroupHelp,
  Label,
  Select,
  Fragment,
} from '@campsite-bio/component-lib';
import { computed, inject, nextTick, ref } from 'vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import {
  useFacebookAuthorization,
  useProfilesWithAnalyticsLoader,
  useStore,
} from '@/compositions';
import bus from '@/bus';
import { PROVIDERS } from '@/config';

export default {
  components: {
    CardText,
    CardActions,
    VText,
    Button,
    FormGroup,
    FormGroupHelp,
    Label,
    Select,
    Fragment,
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    profileId: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    const store = useStore();
    const { isOpen } = inject('multi-step-dialog');
    const selectedProfileId = ref(props.profileId);
    const form = ref(null);
    const { profiles, load } = useProfilesWithAnalyticsLoader({
      loadOnInit: false,
    });

    const profilesSorted = computed(() => {
      return {
        enabled: profiles.value?.filter(
          (p) =>
            !p.app_authorizations?.find(
              (a) => a.provider === PROVIDERS.facebook,
            ),
        ),
        disabled: profiles.value?.filter((p) =>
          p.app_authorizations?.find((a) => a.provider === PROVIDERS.facebook),
        ),
      };
    });

    if (!props.profileId) {
      load();
    }

    const { authorize } = useFacebookAuthorization(selectedProfileId, {
      onCancel() {},
      async onSuccess(data) {
        isOpen.value = false;
        if (props.profileId) {
          // Reload the profiles authorizations
          await store.dispatch('profiles/getAppAuthorizations');
          await nextTick();
        }
        bus.$emit(`app-authorization:${data.authId}:open`);
        bus.$emit(`app-authorization:authorize`, data);
      },
    });

    async function handleAuthorize() {
      const success = await form.value.validate();

      if (success) authorize();
    }

    return { form, profilesSorted, handleAuthorize, selectedProfileId };
  },
};
</script>

<style lang="scss" scoped></style>
