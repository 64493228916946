<template>
  <ChartCard
    class="overview-card"
    :title="title"
    :is-loading="isLoading"
    :highlights="highlights"
  >
    <ChartWrapper
      width="100%"
      height="300"
      type="line"
      :options="chartOptions"
      :data="series"
    />

    <ChartCardButton
      slot="actions"
      v-model="selectedProfile"
      :items="menuItems"
    >
      {{ selectedProfile | truncate(20) }}
    </ChartCardButton>
  </ChartCard>
</template>

<script>
import { computed, ref } from 'vue';
import { DateTime } from 'luxon';
import { useTheme, numberFormatter } from '@campsite-bio/component-lib';
import tinycolor from 'tinycolor2';

import {
  useApexOptions,
  useDataColors,
  useOnDataColors,
} from '../compositions';
import { ChartCard, ChartCardButton } from '..';
import { ChartWrapper } from '../../analytics';
import { formatTooltipLabels, percentageChange } from '@/utils';

export default {
  components: {
    ChartWrapper,
    ChartCard,
    ChartCardButton,
  },

  props: {
    allData: {
      type: Object,
      required: true,
    },

    data: {
      type: Array,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const selectedProfile = ref('all');
    const dataColors = useDataColors();
    const onDataColors = useOnDataColors();

    const menuItems = computed(() => {
      const items = [
        {
          name: 'All',
          value: 'all',
        },
      ];

      let profiles = [];
      if (props.data && !props.isLoading) {
        props.data.forEach((item) => {
          profiles.push({
            name: item.profile?.username,
            value: item.profile?.username,
          });
        });
        profiles = profiles.sort((a, b) => a.name?.localeCompare(b.name));
      }

      return [...items, ...profiles];
    });

    const dataToUse = computed(() => {
      const allData = {
        Views: [],
        Clicks: [],
        CTR: [],
      };

      function combineData(sourceData, targetData) {
        sourceData.forEach((d) => {
          const existingData = targetData.find(
            (v) => v._id.date === d._id.date && v._id.range === d._id.range,
          );

          if (existingData) {
            existingData.count += d.count;
          } else {
            targetData.push({ ...d });
          }
        });
      }

      let dataToUse = props.data;
      if (selectedProfile.value !== 'all')
        dataToUse = props.data.filter(
          (d) => d.profile.username === selectedProfile.value,
        );

      dataToUse.forEach((profile) => {
        combineData(profile.views_by_date, allData.Views);
        combineData(profile.clicks_by_date, allData.Clicks);
      });

      allData.Views.forEach((views) => {
        const date = views._id.date;
        const clicks = allData.Clicks.find(
          (c) => c._id.date === date && c._id.range === views._id.range,
        );

        allData.CTR.push({
          _id: {
            date,
            range: clicks._id.range,
          },
          count:
            clicks && views.count > 0 ? (clicks.count / views.count) * 100 : 0,
        });
      });

      return allData;
    });

    function getSeriesData(range = 'current') {
      return {
        datasets: Object.keys(dataToUse.value).map((key, i) => {
          const data = dataToUse.value[key]
            .filter((d) => d._id.range === range)
            .map((d) => d.count);

          if (key === 'CTR') {
            return {
              label: key,
              type: 'line',
              data,
              backgroundColor: dataColors.value[i],
              borderColor: dataColors.value[i],
              xAxisID: 'x',
              yAxisID: 'y2',
              tension: 0.1,
              order: 0,
            };
          }

          return {
            label: key,
            type: 'bar',
            data,
            backgroundColor: dataColors.value[i],
            borderColor: dataColors.value[i],
            barPercentage: 1.0,
            order: 1,
          };
        }, []),
        labels: categories.value,
      };
    }

    const categories = computed(() => {
      return dataToUse.value.Views.filter((d) => d._id.range === 'current').map(
        (stat) => {
          return DateTime.fromISO(stat._id.date).toFormat('MMM d');
        },
      );
    });

    const series = computed(() => {
      if (!dataToUse.value) return {};
      // Bring in dependencies
      const categoriesLocal = categories.value;

      return getSeriesData();
    });

    const highlights = computed(() => {
      if (!dataToUse.value) return [];

      const pastSeries = getSeriesData('past');

      return series.value.datasets.map(
        ({ label, data, backgroundColor }, i) => {
          const count = data.reduce((acc, stat) => {
            return acc + stat;
          }, 0);
          const pastCount = pastSeries.datasets[i].data.reduce((acc, stat) => {
            return acc + stat;
          }, 0);

          let title = count;
          let value = count;
          let change = percentageChange(pastCount, count);
          if (label === 'CTR') {
            const ctr = count / data.length;
            const pastCtr = pastCount / pastSeries.datasets[i].data.length;
            change = percentageChange(pastCtr, ctr);
            title = `${ctr > 0 ? numberFormatter(ctr, 1) : ctr}%`;
            value = ctr;
          }

          return {
            title,
            subtitle: label,
            color: backgroundColor,
            badge: change,
            badgeColor: change > 0 ? 'success' : 'info',
            value,
          };
        },
      );
    });

    const theme = useTheme();
    const chartOptions = useApexOptions(
      computed(() => ({
        scales: {
          x: {
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              maxRotation: 0,
            },
            min: 0,
          },
          y: {
            border: {
              display: false,
            },
            grid: {
              color: theme.value.colors.gray100,
            },
            min: 0,
          },
          y2: {
            position: 'right',
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              display: false,
              step: 20,
              callback: function (value) {
                return (value / 100).toLocaleString('en-US', {
                  style: 'percent',
                });
              },
            },
            min: 0,
          },
        },
        tooltip: {
          callbacks: {
            label(context) {
              if (context.dataset.label === 'CTR')
                return `CTR: ${numberFormatter(context.parsed.y, 2)}%`;
              return formatTooltipLabels(context);
            },
          },
          itemSort(a, b) {
            return a.datasetIndex - b.datasetIndex;
          },
        },
        plugins: {
          annotation: {
            annotations: {
              ctrLine: {
                type: 'line',
                mode: 'horizontal',
                yMin: ctrAverage.value,
                yMax: ctrAverage.value,
                borderColor: dataColors.value[2],
                borderWidth: 1,
                yScaleID: 'y2',
                borderDash: [5, 5],
                label: {
                  content: `CTR ${numberFormatter(ctrAverage.value, 1)}%`,
                  position: 'end',
                  display: true,
                  backgroundColor: tinycolor(dataColors.value[2])
                    .setAlpha(0.7)
                    .toString(),
                  color: onDataColors.value[2],
                  font: {
                    size: 10,
                  },
                },
              },
            },
          },
        },
      })),
    );

    const ctrAverage = computed(() => {
      if (!dataToUse.value) return 0;

      return highlights.value.find((h) => h.subtitle === 'CTR').value;
    });

    return {
      chartOptions,
      series,
      selectedProfile,
      menuItems,
      highlights,
    };
  },
};
</script>

<style lang="scss" scoped>
.overview-card {
  min-height: unset;
}
</style>
