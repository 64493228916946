<template>
  <Fragment>
    <CardText>
      <VText gutter-bottom style="font-size: 0.9rem"
        >Select 1 to 6 products. Any change you make to these products within
        Shopify will automatically stay in sync with your profile.</VText
      >

      <FormGroup>
        <Input ref="input" v-model="search" placeholder="Search by title">
          <template slot="prepend">
            <CircularLoader v-if="isLoading" :thickness="4" />
            <slot v-else name="icon">
              <SearchIcon size="inherit" />
            </slot>
          </template>
        </Input>
      </FormGroup>

      <List class="scroll" style="margin: -0.75rem -1rem 0">
        <ListItemGroup
          v-model="selectedProductsLocal"
          multiple
          :multiple-limit="6"
          aria-label="Shopify products"
        >
          <template v-if="isLoading && !products.length">
            <ListItem v-for="i in 3" :key="i">
              <ListItemAction>
                <Checkbox disabled />
              </ListItemAction>
              <ListItemAvatar style="margin-right: 1rem; margin-left: -0.5rem">
                <Skeleton width="40px" height="40px" circle />
              </ListItemAvatar>
              <ListItemContent>
                <ListItemTitle><Skeleton width="100px" /></ListItemTitle>
              </ListItemContent>
            </ListItem>
          </template>
          <ListItem
            v-for="product in products"
            :key="product.product_id"
            :value="product.product_id"
          >
            <template #default="{ active }">
              <ListItemAction>
                <Checkbox :input-value="active" />
              </ListItemAction>
              <ListItemAvatar style="margin-right: 1rem; margin-left: -0.5rem">
                <img
                  :src="
                    product.images.length
                      ? product.images[0].src
                      : 'https://via.placeholder.com/150?text=No+Image'
                  "
                  :alt="''"
                />
              </ListItemAvatar>
              <ListItemContent>
                <ListItemTitle>{{ product.title }}</ListItemTitle>
              </ListItemContent>
            </template>
          </ListItem>
        </ListItemGroup>
      </List>
    </CardText>
    <CardActions>
      <Button color="error" variant="flat">Cancel</Button>
      <Button
        color="success"
        variant="flat"
        :disabled="!selectedProductsLocal.length"
        @click="handleSelect"
        >Select</Button
      >
    </CardActions>
  </Fragment>
</template>

<script>
import {
  CardText,
  CardActions,
  List,
  ListItemGroup,
  ListItem,
  Checkbox,
  ListItemContent,
  ListItemTitle,
  ListItemAction,
  ListItemAvatar,
  Skeleton,
  Fragment,
  Button,
  VText,
  FormGroup,
  Input,
  CircularLoader,
  SearchIcon,
} from '@campsite-bio/component-lib';
import { inject, onMounted, ref, watch } from 'vue';
import debounce from 'lodash/debounce';
import axios from 'axios';

import { localAxios } from '@/apis';
import toast from '@/utils/toast';

export default {
  components: {
    CardText,
    CardActions,
    List,
    ListItemGroup,
    ListItem,
    Checkbox,
    ListItemContent,
    ListItemTitle,
    ListItemAction,
    ListItemAvatar,
    Skeleton,
    Fragment,
    Button,
    VText,
    FormGroup,
    Input,
    CircularLoader,
    SearchIcon,
  },

  props: {
    selectedProducts: {
      type: Array,
      default: null,
    },

    linkId: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const selectedProductsLocal = ref([]);
    const products = ref([]);
    const isLoading = ref(true);
    const { isOpen } = inject('multi-step-dialog');
    const search = ref(null);
    const cancelToken = ref(null);

    async function load() {
      isLoading.value = true;
      try {
        if (cancelToken.value) {
          cancelToken.value.cancel();
        }
        cancelToken.value = axios.CancelToken.source();

        const { data } = await localAxios.get(
          `/api/links/${props.linkId}/shop/products`,
          {
            params: {
              title: search.value,
            },
            cancelToken: cancelToken.value.token,
          },
        );
        // TODO: handle pagination
        products.value = data.data.product_listings;
      } catch (e) {
        if (axios.isCancel(e)) {
          return;
        }
        console.error(e);
        toast.error('Failed to load products');
      } finally {
        isLoading.value = false;
      }
    }
    const loadDebounced = debounce(load, 500);

    function handleSelect() {
      emit('update:products', selectedProductsLocal.value);
      isOpen.value = false;
    }

    watch(search, loadDebounced);

    onMounted(load);

    return { selectedProductsLocal, products, isLoading, handleSelect, search };
  },
};
</script>

<style lang="scss" scoped></style>
