import { ref, unref } from 'vue';
import { localAxios } from '@/apis';
import toast from '@/utils/toast';

export function useBillingPortal(endpoint, options = {}) {
  const { returnPath = window.location.pathname, flowDataType = null } =
    options;
  const isLoading = ref(false);

  async function getBillingPortal(params = {}) {
    isLoading.value = true;

    try {
      const { data } = await localAxios.post(unref(endpoint), {
        return_path: unref(returnPath),
        flow_data_type: unref(flowDataType),
        ...params,
      });
      window.location = data.redirect_url;
    } catch (e) {
      console.error(e);
      toast.error('Something went wrong. Please try again.');
    } finally {
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    getBillingPortal,
  };
}
