<template>
  <div>
    <VText component="h2" variant="h4" style="margin-bottom: 0.5rem">
      Profiles
    </VText>
    <List class="profiles">
      <template v-if="isLoading && (!profiles || profiles.length === 0)">
        <template v-for="i in loaderCount">
          <ListItem :key="`list${_uid}${i}`">
            <ListItemAvatar>
              <Skeleton circle height="40px" width="40px" block />
            </ListItemAvatar>

            <ListItemContent>
              <ListItemTitle><Skeleton width="100px" /></ListItemTitle>
            </ListItemContent>
          </ListItem>
          <Divider
            v-if="i !== loaderCount - 1"
            :key="`divider${_uid}${i}`"
            color="gray200"
            :thickness="1"
          />
        </template>
      </template>
      <ListItem v-else-if="profiles.length === 0">
        <ListItemTitle>We don't have any profiles to show here.</ListItemTitle>
      </ListItem>
      <template v-for="(campsite, index) in profiles" v-else>
        <CampsiteSelectorListItem
          :key="`item${campsite.username}`"
          :campsite="campsite"
          :subtitle="`${startCase(campsite.access.role)} on this profile`"
          @deleted="load"
        >
          <ListItemAction slot="actions"> </ListItemAction>
        </CampsiteSelectorListItem>
        <Divider
          v-if="index < profiles.length - 1"
          :key="`divider${campsite.username}`"
          color="gray200"
          :thickness="1"
        />
      </template>
    </List>
    <template v-if="hasMore">
      <Divider color="gray200" :thickness="1" />
      <ListItem
        component="button"
        type="button"
        :disabled="isLoading"
        @click="next"
      >
        <ListItemTitle>Load more...</ListItemTitle>
      </ListItem>
    </template>
  </div>
</template>

<script>
import {
  List,
  ListItem,
  ListItemTitle,
  ListItemAvatar,
  ListItemContent,
  ListItemAction,
  Skeleton,
  Divider,
  VText,
} from '@campsite-bio/component-lib';
import { computed, inject, ref, watch } from 'vue';
import debounce from 'lodash/debounce';
import startCase from 'lodash/startCase';

import CampsiteSelectorListItem from './campsite-selector-list-item';
import { useCurrentOrg } from '@/compositions';
import toast from '@/utils/toast';
import { localAxios } from '@/apis';

export default {
  components: {
    List,
    ListItem,
    ListItemTitle,
    ListItemAvatar,
    ListItemContent,
    ListItemAction,
    Skeleton,
    Divider,
    VText,
    CampsiteSelectorListItem,
  },

  props: {
    canEnable: Boolean,
    canSearch: Boolean,
    canSort: Boolean,
    canDelete: Boolean,

    limit: {
      type: Number,
      default: null,
    },
  },

  setup(props) {
    const isLoading = ref(true);
    const search = ref(null);
    const profiles = ref([]);
    const hasMore = ref(false);
    const page = ref(1);

    const { id: orgId } = useCurrentOrg();
    const member = inject('member');

    const loaderCount = computed(() => {
      return Math.min(10, props.limit || 10);
    });

    async function load() {
      isLoading.value = true;
      try {
        const { data } = await localAxios.get(`/api/profiles/all`, {
          params: {
            search: search.value,
            sort: 'username',
            page: page.value,
            organization_id: orgId.value,
            member_user_id: member.value.user_id.$oid,
          },
        });
        const currentPage = page.value || 1;

        if (currentPage === 1) {
          profiles.value = data.profiles;
        } else {
          profiles.value = [...profiles.value, ...data.profiles];
        }
        hasMore.value = data.has_more;
      } catch (e) {
        console.error(e);
        toast.error('Failed to load profiles');
      }
      isLoading.value = false;
    }
    const loadDebounced = debounce(load, 500);

    function next() {
      page.value += 1;
      load();
    }

    load();

    watch([search], () => {
      page.value = 1;
      loadDebounced();
    });

    return {
      profiles,
      isLoading,
      search,
      next,
      hasMore,
      loaderCount,
      load,
      startCase,
    };
  },
};
</script>

<style lang="scss" scoped>
.profiles {
  border: 1px solid var(--g-color-gray-200);
  padding: 0;
  border-radius: var(--g-border-radius-standard);
  border-radius: min(
    var(--g-border-radius-standard),
    var(--g-border-radius-min)
  );
}
</style>
