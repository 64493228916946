<template>
  <div :style="styles" v-on="$listeners">
    <component
      :is="`Chart${startCase(type).replace(/ /g, '')}`"
      v-if="
        hasLoadedChartJs && data && data.datasets && data.datasets.length > 0
      "
      :options="options"
      :data="data"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import { convertToUnit } from '@campsite-bio/component-lib';
import { computed, nextTick, onMounted, ref } from 'vue';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotation from 'chartjs-plugin-annotation';

import {
  Bar as ChartBar,
  Line as ChartLine,
  Doughnut as ChartDoughnut,
  PolarArea as ChartPolarArea,
} from 'vue-chartjs';
import startCase from 'lodash/startCase';

let hasLoadedChartJsGlobal = false;

ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  ChartAnnotation,
);

export default {
  components: {
    ChartBar,
    ChartLine,
    ChartDoughnut,
    ChartPolarArea,
  },

  props: {
    type: {
      type: String,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },

    options: {
      type: Object,
      required: true,
    },
  },

  setup(props, { attrs }) {
    const hasLoadedChartJs = ref(true);

    const styles = computed(() => {
      return {
        minHeight: convertToUnit(attrs.height),
      };
    });

    // Lazy load ChartJS and it's plugins
    // async function loadChartJs() {
    //   if (hasLoadedChartJs.value) return;

    //   const {
    //     Chart: ChartJS,
    //     Title,
    //     Tooltip,
    //     Legend,
    //     BarElement,
    //     CategoryScale,
    //     LinearScale,
    //     PointElement,
    //     LineElement,
    //     ArcElement,
    //   } = await import('chart.js');
    //   const ChartDataLabels = await import('chartjs-plugin-datalabels');
    //   const annotationPlugin = await import('chartjs-plugin-annotation');

    //   ChartJS.register(
    //     CategoryScale,
    //     LinearScale,
    //     BarElement,
    //     PointElement,
    //     LineElement,
    //     ArcElement,
    //     Title,
    //     Tooltip,
    //     Legend,
    //     ChartDataLabels,
    //     annotationPlugin,
    //   );

    //   await nextTick();
    //   hasLoadedChartJs.value = true;
    //   hasLoadedChartJsGlobal = true;
    // }

    // onMounted(() => {
    //   loadChartJs();
    // });

    // const categoriesLength = computed(() => {
    //   const categories =
    //     props.options.xaxis?.categories || props.options.labels;
    //   const categoriesLength = categories
    //     ? categories.length
    //     : props.series[0].data.length;

    //   return categoriesLength;
    // });

    // // Take the series we get passed and make sure none of the data is longer than the length of the categories
    // const formattedSeries = computed(() => {
    //   if (attrs.type !== 'line') return props.series;

    //   return props.series.map((series) => {
    //     return {
    //       ...series,
    //       data: series.data.slice(0, categoriesLength.value),
    //     };
    //   });
    // });

    return { styles, startCase, hasLoadedChartJs };
  },
};
</script>

<style lang="scss" scoped></style>
