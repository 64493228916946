var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ChartCard',{staticClass:"goals-card",attrs:{"title":"Your Goals","is-loading":_vm.isLoading,"padding-bottom":false}},[_c('SimpleTable',{attrs:{"fixed-header":"","gutter-top":""}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Goal")]),_vm._v(" "),_c('th',[_vm._v("Per")]),_vm._v(" "),_c('th',[_vm._v("Account")]),_vm._v(" "),_c('th',[_vm._v("Set on")]),_vm._v(" "),_c('th',[_vm._v("Streak")]),_vm._v(" "),_c('th')])]),_vm._v(" "),_c('tbody',[(_vm.isLoading)?_vm._l((3),function(i){return _c('tr',{key:i},[_c('td',[_c('Skeleton',{attrs:{"width":"120px"}})],1),_vm._v(" "),_c('td',[_c('Skeleton',{attrs:{"width":"30px"}})],1),_vm._v(" "),_c('td',[_c('Skeleton',{attrs:{"width":"30px"}})],1),_vm._v(" "),_c('td',[_c('Skeleton',{attrs:{"width":"30px"}})],1),_vm._v(" "),_c('td',[_c('Skeleton',{attrs:{"width":"30px"}})],1),_vm._v(" "),_c('td',[_c('Skeleton',{attrs:{"width":"30px"}})],1)])}):(_vm.goals.length === 0)?[_c('GoalsTableItem',{key:"example-item",attrs:{"hide-actions":"","item":{
            channel: 'instagram',
            metric: 'posts',
            frequency: 'week',
            tally: 3,
            account_name: 'myusername',
            created_at: new Date().toISOString(),
          }}}),_vm._v(" "),_c('tr',{key:"example"},[_c('td',{attrs:{"colspan":"5"}},[_vm._v("\n            Above is an example goal. Add your own using the \"Add a goal\"\n            button above.\n          ")])])]:_vm._e(),_vm._v(" "),_vm._l((_vm.goals),function(goal){return _c('GoalsTableItem',{key:goal._id.$oid,attrs:{"item":goal},on:{"delete":_vm.deleteGoal}})})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }