<template>
  <div class="profiles-section">
    <div class="grid grid--container">
      <NoProfileAlert v-if="hasNoCampsites" />
      <template v-else>
        <ProfileCard :profile="profile" style="margin-bottom: 1.75rem" />
        <AllProfilesCard />
        <UpgradeToPro v-if="isFreePlan" style="margin-top: 1.75rem" />
      </template>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue';

import { useCurrentUser, useStore } from '@/compositions';
import { NoProfileAlert } from '../profiles';
import ProfileCard from './profiles-sections/profile-card';
import AllProfilesCard from './profiles-sections/all-profiles-card';
import { UpgradeToPro } from '@/components/ads';

export default {
  components: {
    ProfileCard,
    AllProfilesCard,
    UpgradeToPro,
    NoProfileAlert,
  },

  setup() {
    const store = useStore();
    const { isFreePlan } = useCurrentUser();

    const profile = computed(() => {
      return store.getters['profiles/currentProfile'];
    });

    const hasProfile = computed(() => {
      return store.getters['profiles/hasProfile'];
    });

    const isProPlan = computed(() => {
      return store.getters.isProPlan;
    });

    const hasNoCampsites = computed(() => {
      return store.getters.hasNoCampsites;
    });

    async function loadCurrentProfile() {
      if (!hasNoCampsites.value) await store.dispatch('profiles/get');
    }

    onMounted(loadCurrentProfile);

    return { isProPlan, hasNoCampsites, profile, hasProfile, isFreePlan };
  },
};
</script>

<style lang="scss" scoped>
.profiles-section {
  padding: 2rem 0;

  @media (min-width: 992px) {
    padding: 3rem 0;
  }

  .grid {
    max-width: 930px;
  }
}
</style>
