<template>
  <div class="subhead">
    <div class="subhead__title"><slot /></div>
    <div class="subhead__actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.subhead {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  margin-bottom: 1rem;

  &__title {
    flex: 1 1 auto;
  }

  &__actions {
    align-self: center;
    flex: 0 1 auto;
    margin: 0.25rem 0 0.25rem 0.25rem;
  }
}
</style>
