<template>
  <ListItem component="button" type="button" @click="$emit('click', '')">
    <ListItemAction>
      <LinkIcon size="1.5rem" />
    </ListItemAction>
    <ListItemContent>
      <ListItemTitle>Link</ListItemTitle>
    </ListItemContent>
  </ListItem>
</template>

<script>
import {
  ListItem,
  ListItemAction,
  ListItemContent,
  ListItemTitle,
  LinkIcon,
} from '@campsite-bio/component-lib';

export default {
  components: {
    ListItem,
    ListItemAction,
    ListItemContent,
    ListItemTitle,
    LinkIcon,
  },
};
</script>

<style lang="scss" scoped></style>
