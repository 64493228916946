<template>
  <transition name="fade">
    <AccordionSection
      v-model="localIsOpen"
      class="js-getting-started-checklist"
      title="Getting set up checklist"
      :variant="areAllStepsComplete ? 'success' : null"
    >
      <ListIcon slot="icon" />
      <div slot="actions" class="actions">
        <b
          >{{ stepInfo.stepsLeft }} step{{
            stepInfo.stepsLeft != 1 ? 's' : ''
          }}
          left</b
        >
        <span class="actions__dot">·</span>
        About {{ stepInfo.timeLeft }} min
      </div>

      <ConfirmEmailItem
        step="1"
        :variant="stepInfo.isEmailVerified ? 'success' : null"
      />

      <AddLinkItem
        step="2"
        :variant="stepInfo.has_added_link ? 'success' : null"
      />

      <ProfileImageItem
        step="3"
        :variant="stepInfo.has_updated_profile_image ? 'success' : null"
      />

      <ThemeItem
        step="4"
        :variant="stepInfo.has_customized_theme ? 'success' : null"
      />

      <BioLinkItem
        step="5"
        :variant="stepInfo.has_updated_bio_link ? 'success' : null"
        @complete="handleBioLinkDone"
      />

      <div class="dismiss">
        <InlineLink color="gray500" @click="$emit('dismiss')">
          No thanks
        </InlineLink>
      </div>
    </AccordionSection>
  </transition>
</template>

<script>
import {
  AccordionSection,
  ListIcon,
  InlineLink,
} from '@campsite-bio/component-lib';
import { computed, onMounted, onUnmounted, toRefs, watch } from 'vue';
import {
  ConfirmEmailItem,
  AddLinkItem,
  ProfileImageItem,
  ThemeItem,
  BioLinkItem,
} from './items';

import { useModel, useStore } from '../../compositions';
import bus from '../../bus';

export default {
  components: {
    AccordionSection,
    ListIcon,
    InlineLink,
    ConfirmEmailItem,
    AddLinkItem,
    ProfileImageItem,
    ThemeItem,
    BioLinkItem,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { data } = toRefs(props);
    const store = useStore();
    const isEmailVerified = computed(() => store.getters.emailVerified);

    const isOpen = computed(() => {
      return store.state.isGettingStartedOpen;
    });

    const localIsOpen = useModel(isOpen, (val) =>
      store.commit('updateField', { path: 'isGettingStartedOpen', value: val }),
    );
    const localData = useModel(data, () => {});

    const stepInfo = computed(() => {
      const {
        has_added_link,
        has_customized_theme,
        has_updated_profile_image,
        has_updated_bio_link,
      } = data.value;
      let timeLeft = 0;
      let stepsLeft = 0;

      if (!isEmailVerified.value) {
        timeLeft += 1;
        stepsLeft += 1;
      }
      if (!has_added_link) {
        timeLeft += 1;
        stepsLeft += 1;
      }
      if (!has_customized_theme) {
        timeLeft += 5;
        stepsLeft += 1;
      }
      if (!has_updated_profile_image) {
        timeLeft += 1;
        stepsLeft += 1;
      }
      if (!has_updated_bio_link) {
        timeLeft += 2;
        stepsLeft += 1;
      }

      return {
        ...data.value,
        isEmailVerified: isEmailVerified.value,
        timeLeft,
        stepsLeft,
      };
    });

    const areAllStepsComplete = computed(() => {
      return Object.keys(stepInfo.value)
        .filter((k) => typeof stepInfo.value[k] === 'boolean')
        .every((key) => stepInfo.value[key] === true);
    });

    watch(
      areAllStepsComplete,
      (newVal) => {
        if (newVal) {
          localIsOpen.value = false;
          setTimeout(() => {
            emit('complete');
          }, 3000);
        }
      },
      { immediate: true },
    );

    function handleAddLink() {
      localData.value.has_added_link = true;
      emit('update:data', localData.value);
    }
    function handleAppearanceChange() {
      localData.value.has_customized_theme = true;
      emit('update:data', localData.value);
    }
    function handleProfileImageChange() {
      localData.value.has_updated_profile_image = true;
      emit('update:data', localData.value);
    }
    function handleBioLinkDone() {
      localData.value.has_updated_bio_link = true;
      emit('update:data', localData.value);
    }

    onMounted(() => {
      bus.$on('add-link', handleAddLink);
      bus.$on('appearance-update', handleAppearanceChange);
      bus.$on('profile-image-update', handleProfileImageChange);
    });

    onUnmounted(() => {
      bus.$off('add-link', handleAddLink);
      bus.$off('appearance-update', handleAppearanceChange);
      bus.$off('profile-image-update', handleProfileImageChange);
    });

    return {
      isEmailVerified,
      stepInfo,
      localIsOpen,
      areAllStepsComplete,
      handleBioLinkDone,
    };
  },
};
</script>

<style lang="scss" scoped>
.actions {
  font-size: 0.75rem;
  text-align: right;
  white-space: nowrap;

  @media (min-width: 544px) {
    font-size: 0.85rem;
    text-align: left;
  }

  &__dot {
    display: none;

    @media (min-width: 544px) {
      display: unset;
    }
  }

  b {
    display: block;

    @media (min-width: 544px) {
      display: unset;
    }
  }
}

.dismiss {
  font-size: 0.825rem;
  padding-right: 0.75rem;
  margin-bottom: -0.25em;
  margin-top: 1rem;
  text-align: right;
}
</style>
