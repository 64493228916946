<template>
  <List>
    <ListItem type="button" component="button" @click="next('paypal-fees')">
      <ListItemIcon>
        <PaypalIcon />
      </ListItemIcon>
      <ListItemTitle>PayPal</ListItemTitle>
      <ListItemAction>
        <ChevronIcon size="1.25em" direction="right" />
      </ListItemAction>
    </ListItem>
    <Divider color="gray200" thickness="1" />
    <template v-if="isAdmin">
      <ListItem
        type="button"
        component="button"
        @click="next('shopify-details')"
      >
        <ListItemIcon>
          <ShopifyIcon />
        </ListItemIcon>
        <ListItemTitle>Shopify</ListItemTitle>
        <ListItemAction>
          <ChevronIcon size="1.25em" direction="right" />
        </ListItemAction>
      </ListItem>
      <Divider color="gray200" thickness="1" />
    </template>
  </List>
</template>

<script>
import {
  Divider,
  List,
  ListItem,
  ListItemTitle,
  ListItemIcon,
  ListItemAction,
  SquareIcon,
  PaypalIcon,
  ShopifyIcon,
  ChevronIcon,
} from '@campsite-bio/component-lib';
import { inject } from 'vue';
import { useCurrentUser } from '@/compositions';

export default {
  components: {
    Divider,
    List,
    ListItem,
    ListItemTitle,
    ListItemIcon,
    ListItemAction,
    SquareIcon,
    ShopifyIcon,
    PaypalIcon,
    ChevronIcon,
  },

  setup() {
    const { handleStepChange } = inject('multi-step-dialog');

    const { isAdmin } = useCurrentUser();

    function next(next) {
      handleStepChange(next);
    }

    return { next, isAdmin };
  },
};
</script>

<style lang="scss" scoped></style>
