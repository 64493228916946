<template>
  <div>
    <ProfilesList
      v-model="search"
      title="Profiles"
      :profiles="profiles || []"
      :is-loading="isLoading"
      :can-enable="canEnable"
      :can-delete="canDelete"
      :can-search="canSearch"
      :can-sort="canSort"
      :sort="sort"
      :loader-count="loaderCount"
      @update:sort="sort = $event"
      @clone="$emit('clone', $event)"
      @enabled="handleEnable"
    >
      <template v-if="hasMore && limit === null">
        <Divider color="gray200" :thickness="1" />
        <ListItem component="button" type="button" @click="next">
          <ListItemTitle>Load more...</ListItemTitle>
        </ListItem>
      </template>
    </ProfilesList>
    <slot />
  </div>
</template>

<script>
import { ListItem, ListItemTitle, Divider } from '@campsite-bio/component-lib';
import { computed, ref, watch } from 'vue';
import debounce from 'lodash/debounce';

import { useStore, useUserPreferences } from '@/compositions';
import ProfilesList from './profiles-list';
import toast from '@/utils/toast';

export default {
  components: {
    ProfilesList,
    ListItem,
    ListItemTitle,
    Divider,
  },

  props: {
    canEnable: Boolean,
    canSearch: Boolean,
    canSort: Boolean,
    canDelete: Boolean,
    collaboratorId: {
      type: String,
      default: null,
    },

    limit: {
      type: Number,
      default: null,
    },
  },

  setup(props) {
    const store = useStore();
    const isLoading = ref(true);
    const search = ref(null);
    const { userPreferences, save: saveUserPreferences } = useUserPreferences();
    const sort = ref(userPreferences.value.profile_sort);

    const profiles = computed(() => {
      const all = store.getters['organizations/getField']('profiles') || [];
      return props.limit ? all.slice(0, props.limit) : all;
    });

    const hasMore = computed(() => {
      return store.getters['organizations/getField']('profilesHasMore');
    });

    const page = computed(() => {
      return store.getters['organizations/getField']('profilesPage');
    });

    const loaderCount = computed(() => {
      return Math.min(
        store.getters['organizations/getField']('campsitesCreated') || 3,
        10,
        props.limit || 10,
      );
    });

    async function load() {
      isLoading.value = true;
      try {
        await store.dispatch('organizations/allProfiles', {
          search: search.value,
          sort: sort.value,
          page: page.value,
          member_user_id: props.collaboratorId,
        });
      } catch (e) {
        console.error(e);
        toast.error('Failed to load profiles');
      }
      isLoading.value = false;
    }
    const loadDebounced = debounce(load, 500);

    function next() {
      store.commit('organizations/updateField', {
        path: 'profilesPage',
        value: page.value + 1,
      });
      load();
    }

    load();

    watch([search, sort], () => {
      store.commit('organizations/updateField', {
        path: 'profilesPage',
        value: 1,
      });
      loadDebounced();
    });

    watch(sort, () => {
      saveUserPreferences({ profile_sort: sort.value });
    });

    function handleEnable() {
      reset();
      emit('enabled');
    }

    function reset() {
      store.commit('organizations/updateField', {
        path: 'profilesPage',
        value: 1,
      });
      load();
    }

    return {
      profiles,
      isLoading,
      search,
      sort,
      next,
      hasMore,
      loaderCount,
      load,
      reset,
      handleEnable,
    };
  },
};
</script>

<style lang="scss" scoped></style>
