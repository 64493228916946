<template>
  <div class="not-found">
    <div class="not-found__inner">
      <VText class="not-found__title" variant="h2" component="h1">{{
        title
      }}</VText>
      <VText gutter-bottom>{{ description }}</VText>
      <div class="not-found__actions">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
import { VText } from '@campsite-bio/component-lib';

export default {
  components: {
    VText,
  },

  props: {
    title: {
      type: String,
      default: 'Page not found',
    },
    description: {
      type: String,
      default: 'The page you are looking for does not exist.',
    },
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  display: flex;
  text-align: center;

  &__inner {
    max-width: 600px;
    margin: 4rem auto;
  }
}

.not-found__title.not-found__title {
  margin-bottom: 1rem;
}
</style>
