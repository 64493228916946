<template>
  <HeaderNavLink
    class="header__nav-link--account"
    component="button"
    type="button"
    aria-label="Account"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </HeaderNavLink>
</template>

<script>
import HeaderNavLink from './header-nav-link';

export default {
  components: {
    HeaderNavLink,
  },
};
</script>

<style lang="scss" scoped>
.header__nav-link--account {
  background: var(--g-color-gray-300);
  color: var(--g-color-primary);
  border-radius: 50%;
  font-size: 2.25rem;
  padding: 0;
  height: 2.75rem;
  width: 2.75rem;
  overflow: hidden;

  &:hover,
  &:focus {
    background: var(--g-color-primary);
    color: var(--g-color-on-primary);
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
</style>
