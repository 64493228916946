<template>
  <pre ref="pre" class="code" :class="classes"><div class="code-actions">
      <button v-clipboard class="code-actions__btn" type="button" :data-clipboard-text="code" title="Copy to clipboard" aria-label="Copy code to clipboard" :disabled="disabled">
        <CopyIcon />
      </button>
    </div><code ref="code"></code>
  </pre>
</template>

<script>
import { CopyIcon } from '@campsite-bio/component-lib';

export default {
  name: 'VCode',

  components: {
    CopyIcon,
  },

  props: {
    code: {
      type: String,
      default: '',
    },
    disabled: Boolean,
  },

  computed: {
    classes() {
      return {
        'code--disabled': this.disabled,
      };
    },
  },

  watch: {
    code(newValue, oldValue) {
      this.update();
    },
  },

  mounted() {
    this.update();
  },

  methods: {
    update() {
      this.$refs.code.textContent = this.code;
    },
  },
};
</script>

<style lang="scss" scoped>
.code {
  position: relative;

  &--disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.code-actions {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
}

.code-actions__btn {
  background: rgba(#000, 0.55);
  border: none;
  color: #fff;
  cursor: pointer;
  height: 36px;
  font-size: 18px;
  position: relative;
  transition: all 0.2s;
  width: 36px;

  &:hover,
  &:focus {
    background: rgba(#000, 0.9);
  }

  &:disabled {
    background: rgba(#000, 0.55);
  }

  .v-svg-icon.v-svg-icon {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
