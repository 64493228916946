<template>
  <div>
    <CardText>
      <Label class="filter-label" component="span" gutter-bottom>{{
        filterLabel
      }}</Label>
      <RadioGroup v-model="selectedOperator" aria-label="Filter options">
        <FilterOption
          v-for="(operator, i) in DEFAULT_FILTER_OPERATORS"
          :key="i"
          :label="operator.label"
          :value="operator.value"
          :operator="currentFilterOperator"
          :filter-options="filterSet"
          :filter-value="filterValue"
          @input:filter-value="filterValue = $event"
        />
      </RadioGroup>
    </CardText>
    <CardActions>
      <Button variant="outline" @click="$emit('cancel')">{{
        cancelButtonText
      }}</Button>
      <Button :disabled="!selectedOperator || !filterValue" @click="addFilter"
        >Apply Filter</Button
      >
    </CardActions>
  </div>
</template>

<script>
import {
  CardText,
  CardActions,
  Button,
  Label,
  RadioGroup,
} from '@campsite-bio/component-lib';
import { computed, onMounted, ref, watch } from 'vue';

import FilterOption from './filter-option';
import { DEFAULT_FILTER_OPERATORS } from '../../filters';

export default {
  components: {
    CardText,
    CardActions,
    Button,
    Label,
    RadioGroup,
    FilterOption,
  },

  props: {
    filterLabel: {
      type: String,
      required: true,
    },

    filterSet: {
      type: Array,
      default: null,
    },

    operator: {
      type: String,
      default: null,
    },

    values: {
      type: Array,
      default: null,
    },

    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
  },

  setup(props, { emit }) {
    const selectedOperator = ref(null);
    const filterValue = ref(null);

    watch(
      () => [props.operator, props.values],
      () => {
        selectedOperator.value = props.operator;
        filterValue.value = props.values;
      },
      { immediate: true },
    );

    const currentFilterOperator = computed(() => {
      return DEFAULT_FILTER_OPERATORS.find(
        (operator) => operator.value === selectedOperator.value,
      );
    });

    function addFilter() {
      emit('add', {
        operator: selectedOperator.value,
        value: filterValue.value,
      });
    }

    return {
      DEFAULT_FILTER_OPERATORS,
      selectedOperator,
      filterValue,
      addFilter,
      currentFilterOperator,
    };
  },
};
</script>

<style lang="scss" scoped>
.filter-label.filter-label {
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}
</style>
