<template>
  <Fragment>
    <ChartCard
      ref="target"
      class="stat span-3"
      title="Page Views"
      :is-loading="isLoading"
    >
      <Badge
        slot="badge"
        class="v-chart-card__badge"
        :color="viewBadgeColor"
        :content="viewDifferenceFormatted"
      />
      <VText class="analytics-number" component="span">{{
        currentData ? currentData.views : 0 | numberFormatter(2)
      }}</VText>
    </ChartCard>
    <ChartCard class="stat span-3" title="Link Clicks" :is-loading="isLoading">
      <Badge
        slot="badge"
        class="v-chart-card__badge"
        :color="clicksBadgeColor"
        :content="clicksDifferenceFormatted"
      />
      <VText class="analytics-number" component="span">{{
        currentData ? currentData.clicks : 0 | numberFormatter(2)
      }}</VText>
    </ChartCard>
    <ChartCard
      class="stat span-3"
      title="CTR"
      help-text="The percentage of views that resulted in a click"
      :is-loading="isLoading"
    >
      <Badge
        slot="badge"
        class="v-chart-card__badge"
        :color="ctrBadgeColor"
        :content="ctrDifferenceFormatted"
      />
      <VText class="analytics-number" component="span"
        >{{ currentCtr.toFixed(1) }}%</VText
      >
    </ChartCard>
    <ChartCard class="stat span-3" title="Events" :is-loading="isLoading">
      <Badge
        slot="badge"
        class="v-chart-card__badge"
        :color="eventsBadgeColor"
        :content="eventsDifferenceFormatted"
      />
      <VText class="analytics-number" component="span">{{
        currentData ? currentData.events : 0 | numberFormatter(2)
      }}</VText>
    </ChartCard>
    <ChartCard
      class="stat span-3"
      title="Total Reach"
      help-text="Unique users who visited your profile"
      :is-loading="isLoading"
    >
      <Badge
        slot="badge"
        class="v-chart-card__badge"
        :color="reachBadgeColor"
        :content="reachDifferenceFormatted"
      />
      <VText class="analytics-number" component="span">{{
        currentData ? currentData.reach : 0 | numberFormatter(2)
      }}</VText>
    </ChartCard>
    <ChartCard
      class="stat span-3"
      title="Sessions"
      help-text="A group of user interactions with your website that take place within a the same tab or browser window"
      :is-loading="isLoading"
    >
      <Badge
        slot="badge"
        class="v-chart-card__badge"
        :color="sessionBadgeColor"
        :content="sessionDifferenceFormatted"
      />
      <VText class="analytics-number" component="span">{{
        currentData ? currentData.sessions : 0 | numberFormatter(2)
      }}</VText>
    </ChartCard>
    <ChartCard
      class="stat span-3"
      title="Sessions Per User"
      :is-loading="isLoading"
    >
      <Badge
        slot="badge"
        class="v-chart-card__badge"
        :color="sessionPerUserBadgeColor"
        :content="sessionPerUserDifferenceFormatted"
      />
      <VText class="analytics-number" component="span">{{
        sessionsPerUser | numberFormatter(2)
      }}</VText>
    </ChartCard>
  </Fragment>
</template>

<script>
import { VText, Badge, Fragment } from '@campsite-bio/component-lib';
import { computed } from 'vue';

import { ChartCard } from './cards';
import { percentageChange } from '../../utils';
import { useCtr } from './compositions';

export default {
  components: { ChartCard, Fragment, VText, Badge },

  props: {
    data: {
      type: Array,
      default: null,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const currentData = computed(() => {
      return props.data?.find(({ _id }) => _id === 'current');
    });
    const pastData = computed(() => {
      return props.data?.find(({ _id }) => _id === 'past');
    });

    const viewDifference = computed(() => {
      if (!currentData.value) return 0;
      return percentageChange(pastData.value.views, currentData.value.views);
    });

    const viewDifferenceFormatted = computed(() => {
      if (typeof viewDifference.value === 'number')
        return `${viewDifference.value.toFixed(1)}%`;
      return viewDifference.value;
    });

    const viewBadgeColor = computed(() => {
      return viewDifference.value > 0 ? 'success' : 'info';
    });

    ///

    const reachDifference = computed(() => {
      if (!currentData.value) return 0;
      return percentageChange(pastData.value.reach, currentData.value.reach);
    });

    const reachDifferenceFormatted = computed(() => {
      if (typeof reachDifference.value === 'number')
        return `${reachDifference.value.toFixed(1)}%`;
      return reachDifference.value;
    });

    const reachBadgeColor = computed(() => {
      return reachDifference.value > 0 ? 'success' : 'info';
    });

    ///

    const eventsDifference = computed(() => {
      if (!currentData.value) return 0;
      return percentageChange(pastData.value.events, currentData.value.events);
    });

    const eventsDifferenceFormatted = computed(() => {
      if (typeof eventsDifference.value === 'number')
        return `${eventsDifference.value.toFixed(1)}%`;
      return eventsDifference.value;
    });

    const eventsBadgeColor = computed(() => {
      return eventsDifference.value > 0 ? 'success' : 'info';
    });

    ///

    const sessionDifference = computed(() => {
      if (!currentData.value) return 0;
      return percentageChange(
        pastData.value.sessions,
        currentData.value.sessions,
      );
    });

    const sessionDifferenceFormatted = computed(() => {
      if (typeof sessionDifference.value === 'number')
        return `${sessionDifference.value.toFixed(1)}%`;
      return sessionDifference.value;
    });

    const sessionBadgeColor = computed(() => {
      return sessionDifference.value > 0 ? 'success' : 'info';
    });

    ///

    const sessionsPerUser = computed(() => {
      if (!currentData.value || currentData.value.reach === 0) return 0;
      return currentData.value.sessions / currentData.value.reach;
    });

    const pastSessionsPerUser = computed(() => {
      if (!pastData.value) return 0;
      return pastData.value.sessions / pastData.value.reach;
    });

    const sessionPerUserDifference = computed(() => {
      if (!sessionsPerUser.value || !pastSessionsPerUser.value) return 0;
      return percentageChange(pastSessionsPerUser.value, sessionsPerUser.value);
    });

    const sessionPerUserDifferenceFormatted = computed(() => {
      if (typeof sessionPerUserDifference.value === 'number')
        return `${sessionPerUserDifference.value.toFixed(1)}%`;
      return sessionPerUserDifference.value;
    });

    const sessionPerUserBadgeColor = computed(() => {
      return sessionPerUserDifference.value > 0 ? 'success' : 'info';
    });

    ///

    const clicksDifference = computed(() => {
      if (!currentData.value) return 0;
      return percentageChange(pastData.value.clicks, currentData.value.clicks);
    });

    const clicksDifferenceFormatted = computed(() => {
      if (typeof clicksDifference.value === 'number')
        return `${clicksDifference.value.toFixed(1)}%`;
      return clicksDifference.value;
    });

    const clicksBadgeColor = computed(() => {
      return clicksDifference.value > 0 ? 'success' : 'info';
    });

    ///

    const { currentCtr, ctrDifferenceFormatted, ctrBadgeColor } = useCtr({
      totalViews: computed(() => {
        return currentData.value?.views || 0;
      }),
      totalPastViews: computed(() => {
        return pastData.value?.views || 0;
      }),
      totalClicks: computed(() => {
        return currentData.value?.clicks || 0;
      }),
      totalPastClicks: computed(() => {
        return pastData.value?.clicks || 0;
      }),
    });

    return {
      currentData,
      viewDifferenceFormatted,
      viewBadgeColor,
      clicksDifferenceFormatted,
      clicksBadgeColor,
      currentCtr,
      ctrDifferenceFormatted,
      ctrBadgeColor,
      reachDifferenceFormatted,
      reachBadgeColor,
      sessionDifferenceFormatted,
      sessionBadgeColor,
      sessionsPerUser,
      pastSessionsPerUser,
      sessionPerUserDifferenceFormatted,
      sessionPerUserBadgeColor,
      eventsDifferenceFormatted,
      eventsBadgeColor,
    };
  },
};
</script>

<style lang="scss" scoped>
.stat {
  min-height: unset;
}

.analytics-number.analytics-number {
  display: block;
  font-size: 1.5rem;
  margin-top: 0.25rem;
}
</style>
