import tinycolor from 'tinycolor2';

// Get a unique color palette provided a list of colors
// Transparent colors will be filtered out
export function getUniqueColorPalette(
  defaultColor,
  otherColors = [],
  defaultColors = ['#fff', '#000'],
) {
  const colors = [defaultColor, ...defaultColors, ...Array.from(otherColors)]
    .filter((c) => tinycolor(c).getAlpha() > 0)
    .map((c) => tinycolor(c).toRgbString());

  return Array.from(new Set(colors));
}

// Get a color with a decent contrast ratio for the social account
export function getSocialAccountInitialColor(
  backgroundColor,
  brandColor,
  socialAccountColors,
  colorPalette,
) {
  const acceptableColors = getUniqueColorPalette(brandColor, colorPalette, [])
    .map((c) => {
      return {
        color: tinycolor(c).toRgbString(),
        readability: tinycolor.readability(backgroundColor, c),
      };
    })
    .filter((c) => c.readability > 2);

  if (acceptableColors.length) return acceptableColors[0].color;

  return tinycolor
    .mostReadable(backgroundColor, socialAccountColors)
    .toRgbString();
}

// Compare button colors with the background to get the best contrast
export function getInitialButtonStyle(backgroundColor, buttonStyles) {
  const buttonReadability = buttonStyles
    .map((button, i) => {
      const isBgTransparent = tinycolor(button.background).getAlpha() === 0;

      return {
        readability: tinycolor.readability(
          backgroundColor,
          isBgTransparent ? button.color : button.background,
        ),
        isBgTransparent,
        id: i,
      };
    })
    // Buttons with a transparent BG need higher contrast
    .filter((c) =>
      c.isBgTransparent ? c.readability > 3 : c.readability > 1.2,
    );

  if (buttonReadability.length) return buttonReadability[0].id;

  return 0;
}
