export const FREE_PERIODS = [
  {
    name: 'Today',
    value: 'today',
  },
  {
    name: 'Yesterday',
    value: 'yesterday',
  },
  {
    name: 'Last 7 Days',
    value: 'last_7',
  },
];

export const DEFAULT_PERIODS = [
  {
    name: 'Today',
    value: 'today',
  },
  {
    name: 'Yesterday',
    value: 'yesterday',
  },
  {
    name: 'Last Week',
    value: 'last_week',
  },
  {
    name: 'Last Month',
    value: 'last_month',
  },
  {
    name: 'Last 7 Days',
    value: 'last_7',
  },
  {
    name: 'Last 4 Weeks',
    value: 'last_28',
  },
];

export const DEFAULT_PERIODS_MOBILE = [
  {
    name: 'Today',
    value: 'today',
  },
  {
    name: '1W',
    value: 'last_7',
  },
  {
    name: '4W',
    value: 'last_28',
  },
];

export const ENHANCED_DEFAULT_PERIODS = [
  {
    name: 'Today',
    value: 'today',
  },
  {
    name: 'Yesterday',
    value: 'yesterday',
  },
  {
    name: 'Last Week',
    value: 'last_week',
  },
  {
    name: 'Last Month',
    value: 'last_month',
  },
  {
    name: 'Last 7 Days',
    value: 'last_7',
  },
  {
    name: 'Last 4 Weeks',
    value: 'last_28',
  },
  {
    name: 'Week to Date',
    value: 'wtd',
  },
  {
    name: 'Month to Date',
    value: 'mtd',
  },
  {
    name: 'Quarter to Date',
    value: 'qtd',
  },
  {
    name: 'Year to Date',
    value: 'ytd',
  },
];

export const ENHANCED_DEFAULT_PERIODS_MOBILE = [
  {
    name: 'Today',
    value: 'today',
  },
  {
    name: '1W',
    value: 'last_7',
  },
  {
    name: '4W',
    value: 'last_28',
  },
  {
    name: 'MTD',
    value: 'mtd',
  },
  {
    name: 'QTD',
    value: 'qtd',
  },
  {
    name: 'YTD',
    value: 'ytd',
  },
];

export const CHANNEL_REPORT_PERIODS = [
  {
    name: 'Last Week',
    value: 'last_week',
  },
  {
    name: 'Last Month',
    value: 'last_month',
  },
  {
    name: 'Last 7 Days',
    value: 'last_7',
  },
  {
    name: 'Last 4 Weeks',
    value: 'last_28',
  },
  {
    name: 'Week to Date',
    value: 'wtd',
  },
  {
    name: 'Month to Date',
    value: 'mtd',
  },
];

export const INSIGHTS_REPORT_PERIODS = [
  {
    name: 'Last Week',
    value: 'last_week',
  },
  {
    name: 'Last Month',
    value: 'last_month',
  },
];

export const GOAL_REPORT_PERIODS = [
  {
    name: 'Daily',
    value: 'daily',
  },
  {
    name: 'Weekly',
    value: 'weekly',
  },
  {
    name: 'Monthly',
    value: 'monthly',
  },
];

export const OVERVIEW_REPORT_PERIODS = CHANNEL_REPORT_PERIODS;
