<template>
  <div class="box-input__button" />
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.box-input__button {
  background: #ccc;
  border: 2px solid #000;
  border-right: none;
  border-bottom: none;
  bottom: 0;
  height: 86%;
  position: absolute;
  right: 0;
  transition: all 400ms;
  width: 86%;

  &--w-img {
    border-bottom: 2px solid #000;
    bottom: 7%;
  }
}
</style>
