<template>
  <LinkExpander
    :id="`default-${linkId}`"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template slot="header"
      >Form options

      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <IconButton
            href="https://support.campsite.bio/en/articles/6846373-adding-a-form"
            target="_blank"
            aria-label="Form help"
            size="1.25rem"
            v-bind="attrs"
            v-on="on"
          >
            <QuestionCircleOIcon title="help" />
          </IconButton>
        </template>
        <span>Get some help with your form</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="form">
      <TypeformSettings
        v-if="formOptions.source === 'typeform'"
        :form-options="formOptions"
        :form-type="formType"
        @input-layout="layoutLocal = $event"
        @input-height="heightLocal = $event"
        @input-button-text="buttonTextLocal = $event"
        @input-remove-bg="removeBackgroundLocal = $event"
      />
      <JotformSettings
        v-else-if="formOptions.source === 'jotform'"
        :form-options="formOptions"
        :form-type="formType"
        @input-layout="layoutLocal = $event"
        @input-button-text="buttonTextLocal = $event"
        @input-remove-bg="removeBackgroundLocal = $event"
      />
    </ValidationObserver>
  </LinkExpander>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { IconButton, QuestionCircleOIcon } from '@campsite-bio/component-lib';
import LinkExpander from '../expand/link-expander.vue';
import { ref, toRefs, watch } from 'vue';
import debounce from 'lodash/debounce';
import uniqueId from 'lodash/uniqueId';
import { TypeformSettings, JotformSettings } from './settings';

export default {
  components: {
    ValidationObserver,
    LinkExpander,
    IconButton,
    QuestionCircleOIcon,
    TypeformSettings,
    JotformSettings,
  },

  props: {
    isOpen: Boolean,
    linkId: {
      type: String,
      required: true,
    },
    formOptions: {
      type: Object,
      required: true,
    },
    formType: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { formOptions } = toRefs(props);
    const form = ref(null);
    const id = ref(uniqueId('form'));

    const removeBackgroundLocal = ref(false);
    const layoutLocal = ref(null);
    const heightLocal = ref(null);
    const buttonTextLocal = ref(null);

    watch(
      formOptions,
      ({ remove_bg, layout, height, button_text }) => {
        removeBackgroundLocal.value = remove_bg;
        layoutLocal.value = layout;
        heightLocal.value = height;
        buttonTextLocal.value = button_text;
      },
      { immediate: true },
    );

    async function save() {
      const success = await form.value.validate();
      if (!success) return;

      emit('save', {
        remove_bg: removeBackgroundLocal.value,
        layout: layoutLocal.value,
        height: heightLocal.value,
        button_text: buttonTextLocal.value,
      });
    }

    watch(
      [removeBackgroundLocal, layoutLocal, heightLocal, buttonTextLocal],
      debounce(save, 1000),
    );

    return {
      id,
      form,
      removeBackgroundLocal,
      layoutLocal,
      heightLocal,
      buttonTextLocal,
    };
  },
};
</script>

<style lang="scss"></style>
