<template>
  <Select
    v-model="localValue"
    class="min-age-select"
    v-bind="{ ...$attrs, ...$props }"
  >
    <slot />
    <option :value="null">- Select age</option>
    <option v-for="i in 87" :key="i" :value="i + 11">
      {{ i + 11 }}
    </option>
  </Select>
</template>

<script>
import { Select } from '@campsite-bio/component-lib';
import { toRefs } from 'vue';

import { useModel } from '../../../compositions';

export default {
  components: {
    Select,
  },

  props: {
    ...Select.props,

    value: {
      type: [String, Number],
      default: null,
    },
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const localValue = useModel(value, (val) => emit('input', val));

    return { localValue };
  },
};
</script>

<style lang="scss" scoped>
.min-age-select {
  max-width: 100%;
  width: 10rem;
}
</style>
