<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="300"
    @keydown.esc="dialog = false"
  >
    <Card>
      <CardText>
        <slot>
          <VText
            >This {{ name }} and its data will be permanently deleted.</VText
          >
        </slot>
      </CardText>
      <CardActions>
        <Button variant="flat" @click="dialog = false">Cancel</Button>
        <Button
          color="error"
          variant="flat"
          :loading="isDeleting"
          @click="$emit('remove')"
          >Delete</Button
        >
      </CardActions>
    </Card>
  </v-dialog>
</template>

<script>
import { toRefs } from 'vue';
import {
  Card,
  CardText,
  CardActions,
  VText,
  Button,
} from '@campsite-bio/component-lib';

import { useModel } from '../../../compositions';

export default {
  components: {
    Card,
    CardText,
    CardActions,
    VText,
    Button,
  },

  props: {
    value: Boolean,
    isDeleting: Boolean,
    name: {
      type: String,
      default: 'link',
    },
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const dialog = useModel(value, (val) => emit('input', val));

    return { dialog };
  },
};
</script>

<style lang="scss" scoped></style>
