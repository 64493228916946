<template>
  <LinkExpander
    :id="`event-label-${id}`"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template slot="header">
      Event Tag
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <IconButton
            href="https://support.campsite.bio/en/articles/6848849-how-to-use-our-analytics#h_55019f8bd6"
            target="_blank"
            aria-label="Event tag help"
            size="1.25rem"
            v-bind="attrs"
            v-on="on"
          >
            <QuestionCircleOIcon />
          </IconButton>
        </template>
        <span>Learn more about the event tag</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="form" slim>
      <ValidationProvider
        v-slot="{ errors, ariaInput, ariaMsg }"
        rules="event_tag|max:50"
        name="source"
        slim
      >
        <FormGroup :gutter-bottom="false">
          <Label :for="`event${_uid}`">Event Label</Label>
          <Input
            :id="`event${_uid}`"
            v-model="eventLabelLocal"
            placeholder="ex. support-page-visit"
            maxlength="50"
            :has-error="errors.length > 0"
            v-bind="ariaInput"
          />
          <FormGroupHelp color="error" v-bind="ariaMsg">{{
            errors[0]
          }}</FormGroupHelp>
          <FormGroupHelp
            >Add a unique event name to your clicks for easier event
            tracking.</FormGroupHelp
          >
        </FormGroup>
      </ValidationProvider>
    </ValidationObserver>
  </LinkExpander>
</template>

<script>
import {
  Input,
  FormGroup,
  FormGroupHelp,
  Label,
  IconButton,
  QuestionCircleOIcon,
} from '@campsite-bio/component-lib';
import { inject, ref, toRefs, watch } from 'vue';
import debounce from 'lodash/debounce';
import kebabCase from 'lodash/kebabCase';
import axios from 'axios';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import LinkExpander from './link-expander';
import { useMeta, useStore } from '../../../compositions';
import toast from '../../../utils/toast';

export default {
  components: {
    LinkExpander,
    Input,
    FormGroup,
    FormGroupHelp,
    Label,
    IconButton,
    QuestionCircleOIcon,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    isOpen: Boolean,
    link: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { link } = toRefs(props);
    const { id } = inject('link');
    const eventLabelLocal = ref(null);
    const cancelSource = ref(null);
    const eventLabel = useMeta('event-tag', {
      defaultValue: null,
      itemRef: link,
    });
    const store = useStore();
    const form = ref(null);

    watch(
      eventLabel,
      () => {
        eventLabelLocal.value = eventLabel.value;
      },
      { immediate: true },
    );

    async function save() {
      const success = await form.value.validate();
      if (!success) return;

      try {
        if (cancelSource.value) cancelSource.value.cancel();
        cancelSource.value = axios.CancelToken.source();

        await store.dispatch('links/updateLinkEventTag', {
          id: link.value._id.$oid,
          data: {
            value: eventLabelLocal.value,
          },
          cancelToken: cancelSource.value.token,
        });
      } catch (e) {
        console.error(e);
        const { error_message } = e.response?.data || {};
        toast.error(
          error_message || 'Something went wrong. Please try again later.',
        );
      }
    }

    function formatEvent(value) {
      eventLabelLocal.value = kebabCase(value);

      save();
    }
    const formatEventDebounced = debounce(formatEvent, 750);

    watch(eventLabelLocal, formatEventDebounced);

    return { id, eventLabelLocal, form };
  },
};
</script>

<style lang="scss" scoped></style>
