<template>
  <Fragment>
    <CardText>
      <VText gutter-bottom style="font-size: 0.9rem"
        >By connecting your Square account, you agree that you will be solely
        liable for any transactions, including the products and/or services you
        make available, with your end user. Campsite.bio is not a party to the
        transaction.</VText
      >
      <VText gutter-bottom style="font-size: 0.9rem"
        ><strong
          >We only support accounts with the USD currency at this time.</strong
        ></VText
      >
    </CardText>
    <CardActions align="center" direction="column">
      <Button @click="authorize">Connect Square Account</Button>
      <InlineLink
        component="button"
        type="button"
        style="margin-top: 0.5rem"
        @click="handleNoAccount"
        >I don't have an account</InlineLink
      >
    </CardActions>
  </Fragment>
</template>

<script>
import {
  CardText,
  VText,
  CardActions,
  Button,
  InlineLink,
} from '@campsite-bio/component-lib';
import { inject, nextTick } from 'vue';
import { Fragment } from 'vue-fragment';

import { useSquareAuthorization, useStore } from '../../../../compositions';
import bus from '../../../../bus';

export default {
  components: {
    CardText,
    CardActions,
    VText,
    Button,
    InlineLink,
    Fragment,
  },

  setup() {
    const store = useStore();
    const { handleStepChange, isOpen } = inject('multi-step-dialog');

    function handleNoAccount() {
      handleStepChange('square-create-account');
    }

    const { authorize } = useSquareAuthorization({
      onCancel() {},
      async onSuccess(data) {
        isOpen.value = false;
        await store.dispatch('profiles/getAppAuthorizations');
        await nextTick();
        bus.$emit(`app-authorization:${data.authId}:open`);
      },
    });

    return { authorize, handleNoAccount };
  },
};
</script>

<style lang="scss" scoped></style>
