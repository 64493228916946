<template>
  <ChartCard ref="target" title="Watch Duration">
    <SimpleTable v-if="hasResults" gutter-top>
      <thead>
        <tr>
          <th class="td-name"><span class="sr-only">Name</span></th>
          <th class="text-right">Plays</th>
          <th class="text-right">Unique Plays</th>
          <th class="text-right">Avg. Duration</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(
            { thumbnail, link, total_plays, total_unique_plays, avg_duration },
            index
          ) in currentData"
          :key="index"
        >
          <td class="td-name">
            <Skeleton v-if="isLoadingLinks" height="60px" width="100px" />
            <a v-else-if="link" :href="link.url" target="_blank">
              <img
                :src="thumbnail"
                alt="Video thumbnail"
                height="60"
                style="height: 60px"
              />
            </a>
            <img
              v-else
              :src="thumbnail"
              alt="Video thumbnail"
              height="60"
              style="height: 60px"
            />
          </td>
          <td class="text-right">{{ total_plays | numberFormatter(1) }}</td>
          <td class="text-right">
            {{ total_unique_plays | numberFormatter(1) }}
          </td>
          <td class="text-right">{{ getFormattedDuration(avg_duration) }}s</td>
        </tr>
      </tbody>
    </SimpleTable>
    <NoResults v-else />
  </ChartCard>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { Skeleton, SimpleTable } from '@campsite-bio/component-lib';
import { Duration } from 'luxon';

import NoResults from '../no-results';
import ChartCard from '../chart-card';
import { localAxios } from '../../../../apis';
import { useStore } from '../../../../compositions';
import {
  getVideoThumbnailByUrl,
  DEFAULT_VIDEO_THUMBNAIL,
} from '../../../../utils';

export default {
  components: {
    ChartCard,
    SimpleTable,
    NoResults,
    Skeleton,
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();
    const links = ref([]);
    const isLoadingLinks = ref(false);

    const currentData = computed(() => {
      return props.data
        .filter((v) => v.dimensions.range === 'current')
        .slice(0, 5)
        .map((v) => {
          const link = links.value.find(
            (l) => l._id.$oid === v.dimensions.link_id,
          );
          let thumbnail = DEFAULT_VIDEO_THUMBNAIL;
          if (link)
            thumbnail = getVideoThumbnailByUrl(
              link.url,
              link.embed_options.source,
            );
          return {
            ...v,
            link,
            thumbnail,
          };
        })
        .sort((a, b) => a.avg_duration - b.avg_duration);
    });

    async function loadLinks() {
      const ids = currentData.value.map((v) => v.dimensions.link_id);
      if (!ids.length) return;

      isLoadingLinks.value = true;
      try {
        const { data } = await localAxios.get(
          `/api/links/${store.getters['profiles/currentId']}`,
          {
            params: {
              ids,
            },
          },
        );

        links.value = data;
      } catch (e) {
        console.error(e);
      }
      isLoadingLinks.value = false;
    }

    const hasResults = computed(() => props.data.length > 0);

    function getFormattedDuration(duration) {
      const d = Duration.fromSeconds(duration);
      return d.as('seconds').toFixed(2);
    }

    watch(
      () => props.data,
      () => loadLinks(),
      { immediate: true },
    );

    return {
      hasResults,
      currentData,
      isLoadingLinks,
      getFormattedDuration,
    };
  },
};
</script>

<style lang="scss" scoped>
.td-name {
  min-width: 100px;
  width: unset !important;
}
</style>
