<template>
  <LinkExpander
    :id="`default-${linkId}`"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template slot="header">
      Support Options
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <IconButton
            href="https://support.campsite.bio/en/articles/6846448-tip-jar-support-links"
            target="_blank"
            aria-label="Support setup help"
            size="1.25rem"
            v-bind="attrs"
            v-on="on"
          >
            <QuestionCircleOIcon />
          </IconButton>
        </template>
        <span>Get some help setting up your support link</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="form">
      <ValidationProvider
        v-slot="{ errors, ariaMsg, ariaInput }"
        :rules="{
          required: true,
        }"
        name="limit"
        slim
      >
        <FormGroup>
          <Label :for="`payment_provider${id}`">Payment Provider</Label>
          <AppAuthorizationsSelect
            :id="`payment_provider${id}`"
            v-model="appAuthLocal"
            store-key="profiles/paymentAuthorizations"
            :has-error="errors.length > 0"
            v-bind="ariaInput"
            required
            @change:auth="handleAuthChange"
          />
          <FormGroupHelp v-if="errors.length === 0"
            >Select where your supporters payments will go</FormGroupHelp
          >
          <FormGroupHelp v-else color="error" v-bind="ariaMsg">
            {{ errors[0] }}
          </FormGroupHelp>
          <FormGroupHelp>
            <InlineLink :to="`/profile/${profileId}/settings#commerce`"
              >Add a new provider</InlineLink
            >
          </FormGroupHelp>
        </FormGroup>
      </ValidationProvider>

      <FormGroup>
        <Label component="span" gutter-bottom>Amounts</Label>
        <ItemsEditor
          v-model="itemsLocal"
          hide-name
          :currency-code="currencyLocal"
        />
      </FormGroup>

      <FormGroup>
        <Label :for="`description${id}`">Description (optional)</Label>
        <Input
          :id="`description${id}`"
          v-model="descriptionLocal"
          component="textarea"
          rows="2"
          placeholder="Support my work with a small donation"
          maxlength="240"
        />
      </FormGroup>

      <FormGroup>
        <Label :for="`thanks${id}`">Thank You Message (optional)</Label>
        <Input
          :id="`thanks${id}`"
          v-model="thankYouMessageLocal"
          placeholder="Thanks for supporting my work!"
          maxlength="60"
        />
      </FormGroup>

      <List>
        <ListItem>
          <ListItemContent>
            <ListItemTitle>
              <Label :for="`custom_amount${id}`"
                >Allow a custom amount to be entered</Label
              >
            </ListItemTitle>
          </ListItemContent>
          <ListItemAction>
            <Toggle
              :id="`custom_amount${id}`"
              v-model="isCustomAmountEnabled"
            />
          </ListItemAction>
        </ListItem>
        <Divider thickness="1" color="gray200" />
        <ListItem>
          <ListItemContent>
            <ListItemTitle>
              <Label :for="`custom_message${id}`"
                >Supporters can send you a custom message</Label
              >
            </ListItemTitle>
          </ListItemContent>
          <ListItemAction>
            <Toggle :id="`custom_message${id}`" v-model="canAddMessage" />
          </ListItemAction>
        </ListItem>
        <Divider thickness="1" color="gray200" />
        <ListItem>
          <ListItemContent>
            <ListItemTitle>
              <Label :for="`fees${id}`"
                >Supporters can help cover transaction fees</Label
              >
            </ListItemTitle>
            <ListItemSubtitle v-if="sourceLocal"
              >Apply {{ platformFee }} to the total</ListItemSubtitle
            >
          </ListItemContent>
          <ListItemAction>
            <Toggle :id="`fees${id}`" v-model="canCoverTransactionFees" />
          </ListItemAction>
        </ListItem>
      </List>
    </ValidationObserver>
  </LinkExpander>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  IconButton,
  QuestionCircleOIcon,
  FormGroup,
  Label,
  FormGroupHelp,
  Toggle,
  Input,
  List,
  ListItem,
  ListItemContent,
  ListItemSubtitle,
  ListItemTitle,
  ListItemAction,
  Divider,
  InlineLink,
  Alert,
  InfoIcon,
  VText,
} from '@campsite-bio/component-lib';
import uniqueId from 'lodash/uniqueId';
import { computed, nextTick, onBeforeUnmount, ref, toRefs, watch } from 'vue';
import debounce from 'lodash/debounce';

import LinkExpander from '../../expand/link-expander';
import { AppAuthorizationsSelect } from '../../../form';
import { ItemsEditor } from '../components';
import { PLATFORM_FEES } from '@/config';
import { useCurrentProfile } from '../../../../compositions';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    LinkExpander,
    IconButton,
    QuestionCircleOIcon,
    FormGroup,
    Label,
    Toggle,
    Input,
    FormGroupHelp,
    AppAuthorizationsSelect,
    List,
    ListItem,
    ListItemContent,
    ListItemSubtitle,
    ListItemTitle,
    ListItemAction,
    Divider,
    ItemsEditor,
    InlineLink,
    Alert,
    InfoIcon,
    VText,
  },

  props: {
    isOpen: Boolean,

    linkId: {
      type: String,
      required: true,
    },

    commerceOptions: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { commerceOptions } = toRefs(props);
    const form = ref(null);
    const id = ref(uniqueId('support'));
    const { id: profileId } = useCurrentProfile();

    const ignoreChanges = ref(false);
    const dirty = ref(false);
    const appAuthLocal = ref(null);
    const descriptionLocal = ref(null);
    const thankYouMessageLocal = ref(null);
    const isCustomAmountEnabled = ref(true);
    const canCoverTransactionFees = ref(false);
    const canAddMessage = ref(true);
    const itemsLocal = ref([]);
    const currencyLocal = ref(null);
    const sourceLocal = ref(null);

    const platformFee = computed(() => {
      return `${PLATFORM_FEES[sourceLocal.value] * 100}%`;
    });

    watch(dirty, (newVal) => emit('change:dirty', newVal));

    watch(
      commerceOptions,
      async ({
        app_authorization_id,
        allow_custom_amount,
        thank_you_message,
        allow_user_cover_fees,
        allow_user_message,
        description,
        items,
        currency,
        source,
      }) => {
        ignoreChanges.value = true;
        appAuthLocal.value = app_authorization_id?.$oid;
        descriptionLocal.value = description;
        thankYouMessageLocal.value = thank_you_message;
        isCustomAmountEnabled.value = allow_custom_amount;
        canCoverTransactionFees.value = allow_user_cover_fees;
        canAddMessage.value = allow_user_message;
        itemsLocal.value = items;
        currencyLocal.value = currency;
        sourceLocal.value = source;
        dirty.value = false;
        await nextTick();
        ignoreChanges.value = false;
      },
      { immediate: true },
    );

    async function save() {
      if (!form.value) return;

      const success = await form.value.validate();
      if (!success) return;

      emit('save', {
        app_authorization_id: appAuthLocal.value,
        description: descriptionLocal.value,
        thank_you_message: thankYouMessageLocal.value,
        allow_custom_amount: isCustomAmountEnabled.value,
        allow_user_cover_fees: canCoverTransactionFees.value,
        allow_user_message: canAddMessage.value,
        items: itemsLocal.value,
        currency: currencyLocal.value,
        source: sourceLocal.value,
      });

      await nextTick();
      dirty.value = false;
    }

    const saveDebounced = debounce(save, 1000);

    watch(
      [
        appAuthLocal,
        descriptionLocal,
        thankYouMessageLocal,
        isCustomAmountEnabled,
        canCoverTransactionFees,
        canAddMessage,
        itemsLocal,
      ],
      () => {
        if (!ignoreChanges.value) {
          dirty.value = true;
          saveDebounced();
        }
      },
      {
        deep: true,
      },
    );

    onBeforeUnmount(() => {
      dirty.value = false;
    });

    function handleAuthChange(auth) {
      currencyLocal.value = auth?.data.currency;
      sourceLocal.value = auth?.provider;
    }

    return {
      profileId,
      id,
      form,
      appAuthLocal,
      descriptionLocal,
      thankYouMessageLocal,
      isCustomAmountEnabled,
      canCoverTransactionFees,
      canAddMessage,
      itemsLocal,
      currencyLocal,
      handleAuthChange,
      platformFee,
      sourceLocal,
    };
  },
};
</script>

<style lang="scss" scoped></style>
