<template>
  <button class="google-btn" :class="classes" v-bind="$attrs" v-on="$listeners">
    <img
      src="~images/btn_google_dark_normal_ios.svg"
      alt="Google logo"
      width="46"
      height="46"
    />
    <span class="google-btn__text">
      <img
        src="~images/sign-in-with-google.svg"
        alt="Sign in with Google"
        width="136"
        height="16"
      />
    </span>
  </button>
</template>

<script>
import 'images/btn_google_dark_normal_ios.svg';
import 'images/sign-in-with-google.svg';

export default {
  props: {
    block: Boolean,
  },

  computed: {
    classes() {
      return {
        'google-btn--block': this.block,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.google-btn {
  background: #4285f4;
  border: none;
  border-radius: 0.25rem;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin: 0.4375rem 0;
  padding: 0;

  &:hover,
  &:focus {
    background: #3367d6;
  }

  &--block {
    text-align: center;
    width: 100%;
  }

  img {
    border-radius: inherit;
  }
}

.google-btn__text {
  padding: 5px 12px;
  flex-grow: 1;
}
</style>
