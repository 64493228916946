<template>
  <Fragment>
    <CardText>
      <VText gutter-bottom style="font-size: 0.9rem"
        >By connecting Shopify, you are sharing your products and
        collections:</VText
      >

      <ul style="font-size: 0.9rem">
        <li>Your Shopify store name</li>
        <li>Products and collection data</li>
      </ul>

      <VText style="font-size: 0.9rem"
        >Campsite.bio will not receive any sales data. All transactions will
        occur on your Shopify store.</VText
      >
    </CardText>
    <CardActions>
      <Button variant="flat" @click="next">Next</Button>
    </CardActions>
  </Fragment>
</template>

<script>
import {
  CardText,
  VText,
  CardActions,
  Button,
  Fragment,
} from '@campsite-bio/component-lib';
import { inject } from 'vue';

export default {
  components: {
    CardText,
    CardActions,
    VText,
    Button,
    Fragment,
  },

  setup() {
    const { handleStepChange } = inject('multi-step-dialog');

    function next() {
      handleStepChange('shopify-auth');
    }

    return { next };
  },
};
</script>

<style lang="scss" scoped></style>
