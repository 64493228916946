<template>
  <WrappedDialog :channel="channel" :profile="profile">
    <template #activator="{ on, attrs }">
      <InputBtn v-bind="{ ...$props, ...attrs }" type="button" v-on="on">
        <div slot="content" class="channel-input-btn-bg" :style="styles">
          <span v-for="i in 10" :key="i" class="channel-input-btn-bg__name">
            {{ name }}
          </span>
        </div>
        <InstagramIcon v-if="channel" />
        {{ name }}
      </InputBtn>
    </template>
  </WrappedDialog>
</template>

<script>
import {
  InputBtn,
  InputBtnProps,
  useTheme,
  InstagramIcon,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';

import WrappedDialog from './wrapped-dialog';

export default {
  components: {
    InputBtn,
    WrappedDialog,
    InstagramIcon,
  },

  props: {
    ...InputBtnProps,

    channel: {
      type: Object,
      default: null,
    },

    profile: {
      type: Object,
      default: null,
    },

    index: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const theme = useTheme();
    const colors = computed(() => [
      theme.value.colors.primary,
      theme.value.colors.secondary,
      theme.value.colors.lightBlue,
      theme.value.colors.darkGreen,
      theme.value.colors.yellow,
    ]);

    const styles = computed(() => {
      const bgColor = colors.value[props.index % colors.value.length];

      return {
        backgroundColor: bgColor,
      };
    });

    const name = computed(() => {
      if (props.channel) {
        return props.channel.name;
      }
      return props.profile.username;
    });

    return { styles, name };
  },
};
</script>

<style lang="scss" scoped>
.channel-input-btn-bg {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &__name {
    animation: moveWords 8s linear infinite;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 2rem;
    margin-left: -0.75rem;
    font-weight: 700;
    padding: 0.5rem 0;
  }
}

@keyframes moveWords {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
</style>
