import { init, configureScope } from '@sentry/browser';

export async function initSentry() {
  init({
    dsn: 'https://a15076dca0684c81903c7899fd2f7692@sentry.io/1196449',
    whitelistUrls: [
      'https://d2mr2bp22s0zi7.cloudfront.net',
      'https://campsite.bio',
      'https://app.campsite.bio',
    ],
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      'ResizeObserver loop limit exceeded',
      'Redirected when going from',
      'Permission denied to access property',
      "undefined is not an object (evaluating 'a.R')",
    ],
    denyUrls: [
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
  });
}

export async function configureUser(user) {
  if (!user || Object.keys(user).length === 0) return;

  configureScope((scope) => {
    scope.setUser({ id: user._id.$oid, email: user.email });
  });
}
