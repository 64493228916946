<template>
  <Select v-model="localValue" v-bind="{ ...$attrs, ...$props }">
    <slot />
    <option v-if="includeEmpty" :value="null">- Select a provider</option>
    <option
      v-for="auth in authorizations"
      :key="auth._id.$oid"
      :value="auth._id.$oid"
    >
      {{ auth.title || auth.name }} - {{ auth.provider_title }}
    </option>
  </Select>
</template>

<script>
import { Select } from '@campsite-bio/component-lib';
import { computed, onMounted, toRefs, watch } from 'vue';

import { useModel, useStore } from '@/compositions';
import toast from '@/utils/toast';

export default {
  components: {
    Select,
  },

  props: {
    ...Select.props,

    value: {
      type: String,
      default: null,
    },

    /** The getter key for the store */
    storeKey: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const store = useStore();
    const localValue = useModel(value, (val) => emit('input', val));

    const includeEmpty = computed(() => {
      return !localValue.value;
    });

    const authorizations = computed(() =>
      store.getters[props.storeKey]?.filter(
        (auth) => auth.status === 'connected',
      ),
    );

    // Select first option if no value
    watch(
      authorizations,
      () => {
        if (!localValue.value && authorizations.value?.length) {
          localValue.value = authorizations.value[0]._id.$oid;
        }

        emit('update:authorizations', authorizations.value);
      },
      { immediate: true },
    );

    watch(
      localValue,
      (newValue) => {
        if (!newValue) return;

        const auth = authorizations.value?.find(
          (auth) => auth._id.$oid === newValue,
        );
        emit('change:auth', auth);
      },
      { immediate: true },
    );

    async function load() {
      try {
        await store.dispatch('profiles/getAppAuthorizations');
      } catch (e) {
        toast.error('There was an error trying to get your providers');
      }
    }

    onMounted(load);

    return { localValue, authorizations, includeEmpty };
  },
};
</script>

<style lang="scss" scoped></style>
