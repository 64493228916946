import { render, staticRenderFns } from "./items-editor.vue?vue&type=template&id=253b6540&scoped=true&"
import script from "./items-editor.vue?vue&type=script&lang=js&"
export * from "./items-editor.vue?vue&type=script&lang=js&"
import style0 from "./items-editor.vue?vue&type=style&index=0&id=253b6540&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "253b6540",
  null
  
)

export default component.exports