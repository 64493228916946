<template>
  <ThemeProvider :theme="theme">
    <v-app>
      <component :is="layout"></component>

      <v-snackbar v-model="newVersion" top :timeout="-1">
        A new version of Campsite is available
        <Button color="white" variant="flat" @click="reloadPage">Update</Button>
      </v-snackbar>

      <v-dialog v-model="isSessionEndedOpen" persistent max-width="350">
        <Card>
          <CardTitle
            ><VText component="h2" variant="h4"
              >Your session has expired!</VText
            ></CardTitle
          >
          <Divider color="gray200" />
          <CardText
            ><VText
              >You'll have to login again to continue editing your
              Campsite</VText
            ></CardText
          >
          <CardActions>
            <FlexSpacer />
            <Button
              color="success"
              variant="flat"
              href="/login"
              @click.prevent="sessionClear"
              >Log in</Button
            >
          </CardActions>
        </Card>
      </v-dialog>

      <AuthorizationDialog />
    </v-app>
  </ThemeProvider>
</template>

<script>
import {
  ThemeProvider,
  Card,
  CardTitle,
  CardText,
  CardActions,
  Button,
  VText,
  FlexSpacer,
  Divider,
  createThemeFromSettings,
} from '@campsite-bio/component-lib';
import { computed, provide, ref, watch } from 'vue';
import { useDocumentVisibility, useIdle } from '@vueuse/core';

import router from '@/router/';
import AppLayout from '@/layouts/app-layout';
import SimpleLayout from '@/layouts/simple-layout';
import bus from '@/bus';
import '@/validate/config';
import { useCurrentUserSubscription, useStore } from '@/compositions';
import { getMeta } from '@/utils';

export default {
  name: 'App',

  components: {
    AppLayout,
    SimpleLayout,
    AuthorizationDialog: () =>
      import('./components/dialogs/authorization-dialog'),
    ThemeProvider,
    Card,
    CardTitle,
    CardText,
    CardActions,
    Button,
    VText,
    FlexSpacer,
    Divider,
  },

  setup() {
    const store = useStore();
    const user = computed(() => store.getters.currentUser);
    const visibility = useDocumentVisibility();
    const { idle } = useIdle(30 * 1000); // 30 seconds
    const { load } = useCurrentUserSubscription(false);

    watch(
      visibility,
      () => {
        store.commit('updateField', {
          path: 'isDocumentVisible',
          value: document.hidden,
        });
      },
      { immediate: true },
    );
    watch(idle, (value) => {
      store.commit('updateField', { path: 'isIdle', value });
    });

    provide('app', {
      isInAdmin: true,
      hideMenu: ref(false),
    });

    watch(
      user,
      (newUser) => {
        if (!store.getters['analytics/selectedDateFilter']) {
          // Set user preferences
          const userPreferences = getMeta('preferences', {}, newUser.meta);
          // Set analytics default date filter
          store.commit('analytics/set', {
            name: 'selectedDateFilter',
            value: userPreferences.analytics_date_filter,
          });
        }
      },
      { deep: true, immediate: true },
    );

    const isProPlan = computed(() => {
      return store.getters.isProPlan;
    });

    // Load in the subscription for paid users
    watch(
      isProPlan,
      (newVal) => {
        if (newVal) {
          load();
        }
      },
      { immediate: true },
    );
  },

  data() {
    return {
      newVersion: false,
      isSessionEndedOpen: false,
    };
  },

  head: {
    title: 'Loading...',
    titleTemplate: '%s - Campsite.bio',
  },

  computed: {
    layout() {
      return this.$store.getters.layout;
    },

    sessionEnded() {
      return this.$store.getters.sessionEnded;
    },

    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },

    settings() {
      return this.$store.getters['profiles/currentProfile'];
    },

    clientVersion() {
      return this.$store.getters.clientVersion;
    },

    maintenanceMode() {
      return this.$store.getters.maintenanceMode;
    },

    theme() {
      if (!this.$store.getters['profiles/hasProfile']) return 'campsite';

      return createThemeFromSettings(this.settings);
    },
  },

  watch: {
    clientVersion(newValue, oldValue) {
      // if client version has changed, show snackbar to reload
      if (newValue && oldValue && newValue !== oldValue) this.newVersion = true;
    },

    sessionEnded(newValue) {
      // hide session modal if on login page
      if (router.currentRoute.path.indexOf('/login') >= 0)
        this.isSessionEndedOpen = false;
      else this.isSessionEndedOpen = newValue;
    },

    maintenanceMode() {
      this.checkMaintenanceMode();
    },
  },

  mounted() {
    this.checkMaintenanceMode();

    bus.$on('media-custom-deleted', (id) => {
      if (
        this.settings &&
        this.settings.site_bg_image &&
        this.settings.site_bg_image.media_id.$oid === id
      ) {
        this.$store.commit('profiles/setBGImage', null);
      }
    });
  },

  destroyed() {
    bus.$off('media-custom-deleted');
  },

  methods: {
    sessionClear() {
      this.$store.commit('setUser', {});
      router.push('/login');
      this.isSessionEndedOpen = false;
      this.$store.commit('setSessionEnded', false);
    },

    reloadPage() {
      window.location.reload();
    },

    checkMaintenanceMode() {
      if (this.maintenanceMode) router.push('/maintenance');
    },
  },
};
</script>

<style scoped></style>
