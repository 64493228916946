<template>
  <div class="box-input__site-btn-wrapper">
    <ProfileBtn :image-url="link.image" :img-class="{ [imgClass]: true }">
      {{ link.label }}
    </ProfileBtn>
  </div>
</template>

<script>
import { ProfileBtn } from '@campsite-bio/component-lib';

export default {
  components: {
    ProfileBtn,
  },
  props: {
    link: {
      type: Object,
      required: true,
    },
    imgClass: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.box-input__site-btn-wrapper {
  position: absolute;
  left: 7%;
  top: 50%;
  transform: translateY(-50%) scale(0.6);
  transform-origin: center left;
  width: 320px;
  pointer-events: none;
}
</style>
