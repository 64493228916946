<template>
  <Card
    component="a"
    :href="item.url"
    target="_blank"
    class="news-item"
    elevate-on-hover
  >
    <CardTitle class="news-item__title">
      <Badge :color="badgeColor" :content="item.category" />
      <VText variant="h4" component="h3">{{ item.title }}</VText>
    </CardTitle>
    <CardText>
      <VText class="news-item__text">{{ description | truncate(140) }}</VText>
    </CardText>
    <CardActions class="news-item__actions" align="left">
      <InlineLink component="span"
        >Read more&nbsp;<ExternalLinkIcon
      /></InlineLink>
    </CardActions>
  </Card>
</template>

<script>
import {
  Card,
  CardTitle,
  CardText,
  CardActions,
  InlineLink,
  VText,
  Badge,
  colors,
  ExternalLinkIcon,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';

const CATEGORY_COLORS = {
  Blog: 'primary',
  New: colors.blueLight,
  Improvement: colors.purple,
  Fix: colors.red,
};

export default {
  components: {
    Card,
    CardTitle,
    CardText,
    CardActions,
    InlineLink,
    VText,
    Badge,
    ExternalLinkIcon,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const badgeColor = computed(() => {
      return CATEGORY_COLORS[props.item.category];
    });

    const description = computed(() => {
      if (props.item.category === 'Blog') return props.item.description;

      const html = document.createElement('div');
      // remove all img tags
      html.innerHTML = props.item.description.replace(/<img[^>]*>/g, '');

      return html.querySelector('p').innerText || props.item.description;
    });

    return { badgeColor, description };
  },
};
</script>

<style lang="scss" scoped>
.news-item {
  padding: 0.5rem 0.75rem;

  &__actions {
    margin-top: auto;
  }
}

.news-item.news-item {
  display: flex;
  flex-direction: column;
}

.news-item__title.news-item__title {
  flex-direction: column;
  gap: 0.75rem;
  align-items: flex-start;
}

.news-item__text.news-item__text {
  font-size: 0.9rem;
}
</style>
