<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form action="#" novalidate @submit.prevent="handleSubmit(save)">
      <SettingsSection>
        <SettingsSectionTitle>General Settings</SettingsSectionTitle>

        <FormGroup>
          <ProfileImage
            :profile-image="user.profile_image"
            @change="handleProfileImageChange"
            @remove="handleProfileImageChange"
          />
        </FormGroup>

        <ValidationProvider
          v-slot="{ errors, ariaMsg, ariaInput }"
          rules="required|max:30"
          name="first name"
          slim
        >
          <FormGroup>
            <Label for="firstName">First Name or Company Name</Label>
            <Input
              id="firstName"
              v-model="firstName"
              type="text"
              name="firstName"
              maxlength="30"
              required
              :has-error="errors.length > 0"
              v-bind="ariaInput"
            />
            <FormGroupHelp color="error" v-bind="ariaMsg">{{
              errors[0]
            }}</FormGroupHelp>
          </FormGroup>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, ariaMsg, ariaInput }"
          :rules="emailRules"
          name="email"
          slim
        >
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              id="email"
              v-model="email"
              type="email"
              name="email"
              :has-error="errors.length > 0"
              v-bind="ariaInput"
              @blur="verifyEmail"
            >
              <template slot="append">
                <CheckIcon
                  v-show="
                    errors.length === 0 &&
                    email &&
                    email.toLowerCase() !== user.email.toLowerCase()
                  "
                  color="success"
                />
                <ErrorOutlineIcon v-show="errors.length > 0" color="error" />
              </template>
            </Input>
            <FormGroupHelp v-if="emailSuggestion"
              >Did you mean
              <InlineLink
                @click="(email = emailSuggestion), (emailSuggestion = '')"
              >
                {{ emailSuggestion }}</InlineLink
              >?</FormGroupHelp
            >
            <FormGroupHelp v-if="isConfirmingNewEmail">
              Your email is {{ user.email }} until you confirm your new one.
              <InlineLink @click.prevent="cancelEmailChange">
                Cancel this request </InlineLink
              >.
            </FormGroupHelp>
            <FormGroupHelp v-show="!isEmailVerified">
              Confirm your email
              {{ user.unconfirmed_email || user.email }}.
              <InlineLink @click.prevent="resendEmailConfirmation">
                Resend email confirmation</InlineLink
              >.
            </FormGroupHelp>
            <FormGroupHelp color="error" v-bind="ariaMsg">{{
              errors[0]
            }}</FormGroupHelp>
          </FormGroup>
        </ValidationProvider>
        <template v-if="hasPassword">
          <ValidationProvider
            v-slot="{ errors, ariaMsg, ariaInput }"
            rules="min:10"
            name="password"
            slim
          >
            <FormGroup>
              <Label for="password">Update Password</Label>
              <PasswordInput
                id="password"
                v-model="password"
                name="password"
                maxlength="64"
                :has-error="errors.length > 0"
                v-bind="ariaInput"
              />
              <FormGroupHelp color="error" v-bind="ariaMsg">{{
                errors[0]
              }}</FormGroupHelp>
            </FormGroup>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors, ariaMsg, ariaInput }"
            vid="currentPassword"
            :rules="currentPasswordRules"
            name="password"
            slim
          >
            <FormGroup v-show="password">
              <Label for="currentPassword">Current Password</Label>
              <PasswordInput
                id="currentPassword"
                v-model="currentPassword"
                name="currentPassword"
                :has-error="errors.length > 0"
                v-bind="ariaInput"
              />
              <FormGroupHelp color="error" v-bind="ariaMsg">{{
                errors[0]
              }}</FormGroupHelp>
            </FormGroup>
          </ValidationProvider>
        </template>
        <template v-else>
          <ValidationProvider
            v-slot="{ errors, ariaMsg, ariaInput }"
            rules="min:10"
            name="password"
            slim
          >
            <FormGroup>
              <Label for="password">Update Password</Label>
              <PasswordInput
                id="password"
                v-model="password"
                name="password"
                maxlength="64"
                :has-error="errors.length > 0"
                v-bind="ariaInput"
              />
              <FormGroupHelp color="error" v-bind="ariaMsg">{{
                errors[0]
              }}</FormGroupHelp>
              <FormGroupHelp v-show="errors.length === 0"
                >Adding a password will allow you to login with your username or
                email</FormGroupHelp
              >
            </FormGroup>
          </ValidationProvider>
        </template>
        <ValidationProvider
          v-slot="{ errors, ariaMsg, ariaInput }"
          rules="required"
          name="timezone"
          slim
        >
          <FormGroup>
            <Label for="timezone">Timezone</Label>
            <TimezoneSelect
              v-model="timezone"
              input-id="timezone"
              required
              v-bind="ariaInput"
            />
            <FormGroupHelp color="error" v-bind="ariaMsg">{{
              errors[0]
            }}</FormGroupHelp>
          </FormGroup>
        </ValidationProvider>

        <div class="form-actions">
          <Button :disabled="isSaving" :loading="isSaving" type="submit"
            >Save</Button
          >
        </div>
      </SettingsSection>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Mailcheck from 'mailcheck';
import {
  Button,
  InlineLink,
  FormGroup,
  Label,
  Input,
  FormGroupHelp,
  CheckIcon,
  ErrorOutlineIcon,
} from '@campsite-bio/component-lib';
import { computed, ref, watch } from 'vue';

import TimezoneSelect from '../form/timezone-select';
import { PasswordInput } from '../form';
import { SettingsSection, SettingsSectionTitle } from '../settings-section';
import {
  useCurrentUser,
  useResendEmailConfirmation,
  useStore,
} from '../../compositions';
import toast from '../../utils/toast';
import { ProfileImage } from '../profile-image';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Button,
    InlineLink,
    FormGroup,
    Label,
    Input,
    FormGroupHelp,
    CheckIcon,
    ErrorOutlineIcon,
    TimezoneSelect,
    PasswordInput,
    SettingsSection,
    SettingsSectionTitle,
    ProfileImage,
  },

  setup() {
    const store = useStore();
    const isSaving = ref(false);
    const firstName = ref(null);
    const email = ref(null);
    const emailSuggestion = ref(null);
    const timezone = ref(null);
    const currentPassword = ref(null);
    const password = ref(null);
    const { user } = useCurrentUser();

    const { resendEmailConfirmation, cancelEmailChange } =
      useResendEmailConfirmation();

    watch(
      user,
      ({
        email: user_email,
        unconfirmed_email,
        first_name,
        timezone: user_timezone,
      }) => {
        email.value = unconfirmed_email || user_email;
        firstName.value = first_name;
        timezone.value = user_timezone;
      },
      { immediate: true },
    );

    const hasPassword = computed(() => {
      return user.value.has_password;
    });

    const emailRules = computed(() => {
      if (email.value.toLowerCase() !== user.value.email.toLowerCase()) {
        return { required: true, email: true, email_remote: true };
      }

      return {};
    });

    const currentPasswordRules = computed(() => {
      if (password.value) return 'required';
      return '';
    });

    const isConfirmingNewEmail = computed(() => {
      return user.value.unconfirmed_email !== null;
    });

    const isEmailVerified = computed(() => {
      return user.value.unconfirmed_email === null && user.value.email_verified;
    });

    watch(email, () => (emailSuggestion.value = null));

    function verifyEmail() {
      if (email.value) {
        Mailcheck.run({
          email: email.value,
          suggested: (suggestion) => {
            emailSuggestion.value = suggestion.full;
          },
          empty: () => {
            emailSuggestion.value = null;
          },
        });
      }
    }

    async function save() {
      if (isSaving.value) return;

      isSaving.value = true;
      const postData = {
        first_name: firstName.value,
        email: email.value,
        timezone: timezone.value,
      };
      // user is changing an existing password
      if (hasPassword.value && password.value && currentPassword.value) {
        postData.password = password.value;
        postData.currentPassword = currentPassword.value;
      }
      // user is adding a password
      else if (!hasPassword.value && password.value) {
        postData.password = password.value;
      }

      try {
        const data = await store.dispatch('updateUser', postData);

        let messages = 0;
        if (user.value.unconfirmed_email) {
          toast.success('Check your inbox for an email confirmation link');
          messages++;
        }
        // password has been added
        if (!hasPassword.value && password.value) {
          toast.success(
            "You've added a password to your account. You can now use it along with your email to log in.",
          );
          messages++;
        }

        if (!messages) {
          toast.success('Your account settings have been updated');
        }

        store.commit('setUser', data.user);
        password.value = null;
        currentPassword.value = null;
        emailSuggestion.value = null;
      } catch (e) {
        const { error_message } = e.response?.data || {};
        console.error(e);
        toast.error(
          error_message || 'Error trying to update your account settings',
          { timeout: 3000 },
        );
      }

      isSaving.value = false;
    }

    async function handleProfileImageChange(url) {
      await store.dispatch('updateProfileImage', url);
      toast.success('Your profile image has been updated');
    }

    return {
      isSaving,
      firstName,
      email,
      emailSuggestion,
      timezone,
      currentPassword,
      password,
      hasPassword,
      emailRules,
      currentPasswordRules,
      isConfirmingNewEmail,
      isEmailVerified,
      user,
      verifyEmail,
      save,
      resendEmailConfirmation,
      cancelEmailChange,
      handleProfileImageChange,
    };
  },
};
</script>

<style lang="scss" scoped></style>
