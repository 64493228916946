var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contents"},[_c('GridTitle',{staticClass:"print-hide"},[_c('InstagramIcon'),_vm._v("\n    "+_vm._s(_vm.appAuth.name)+"\n    Stats\n    "),_c('span',{attrs:{"slot":"text"},slot:"text"},[_vm._v("\n      This channel has been authorized on the\n      "),_c('router-link',{staticStyle:{"color":"inherit","text-decoration":"none"},attrs:{"to":`/profile/${_vm.appAuth.campsite._id.$oid}/settings`}},[_c('Avatar',{attrs:{"size":"20"}},[_c('img',{attrs:{"src":_vm.profileImageSrc,"alt":""}})]),_vm._v(" "),_c('b',[_vm._v(_vm._s(_vm.appAuth.app_authorization.campsite.username))])],1),_vm._v("\n      profile.\n    ")],1)],1),_vm._v(" "),_c('ChannelHighlights',{attrs:{"data":_vm.data,"is-loading":_vm.isLoading}}),_vm._v(" "),_c('ChannelEngagementScoreCard',{staticClass:"span-12",attrs:{"all-data":_vm.allData,"data":_vm.data,"is-loading":_vm.isLoading}}),_vm._v(" "),_c('ChannelLineCard',{staticClass:"span-12",attrs:{"title":"Reach and Impressions","subtitle":"Number of times your Instagram content has been viewed during the selected period","all-data":_vm.allData,"data":_vm.data.ig_profile_data,"is-loading":_vm.isLoading,"metrics":[
      { name: 'Reach', value: 'reach' },
      { name: 'Impressions', value: 'impressions' },
    ]}}),_vm._v(" "),_c('ChannelLineCard',{staticClass:"span-12",attrs:{"title":"Views and Bio Clicks","subtitle":"Number of times your Instagram profile has been viewed during the selected period","all-data":_vm.allData,"data":_vm.data.ig_profile_data,"is-loading":_vm.isLoading,"metrics":[
      { name: 'Profile Views', value: 'profile_views' },
      { name: 'Bio link clicks', value: 'website_clicks' },
    ]}}),_vm._v(" "),_c('ChannelLineCard',{staticClass:"span-12",attrs:{"title":"Followers","subtitle":"Number of followers for the selected period","all-data":_vm.allData,"data":_vm.data.ig_profile_data,"is-loading":_vm.isLoading,"metrics":{ name: 'Followers', value: 'followers_count' },"annotations":{
      data: _vm.data.ig_media_data,
      dateKey: 'timestamp',
    },"totals-method":"last","yaxis-start-at-lowest":""}},[_c('template',{slot:"no-data"},[_vm._v("Follower count may not be available until after several days.")])],2),_vm._v(" "),_c('ChannelPostsTable',{staticClass:"span-12",attrs:{"all-data":_vm.allData,"data":_vm.data.ig_media_data,"is-loading":_vm.isLoading}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }