<template>
  <div class="og">
    <div class="og__image">
      <img v-if="image" :src="image" :alt="previewTitle" />
      <img v-else src="~images/campsite-og.jpg" :alt="previewTitle" />
    </div>
    <div class="og__content">
      <div class="og__site">CAMPSITE.BIO</div>
      <div class="og__title">
        <a>{{ previewTitle }}</a>
      </div>
      <div class="og__description">{{ previewDescription | truncate(75) }}</div>
    </div>
  </div>
</template>

<script>
import 'images/campsite-og.jpg';

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
  },

  computed: {
    user() {
      return this.$store.getters.currentUser;
    },

    settings() {
      return this.$store.getters['profiles/currentProfile'];
    },

    previewTitle() {
      if (this.title) return this.title;
      return `${this.settings.username}'s Campsite.bio`;
    },

    previewDescription() {
      if (this.description) return this.description;
      return `${this.settings.username}'s Campsite.bio profile. Create your own bio link for free today.`;
    },
  },
};
</script>

<style lang="scss" scoped>
.og {
  background-color: #f2f3f5;
  border: 1px solid #dddfe2;
  border-radius: var(--g-border-radius-standard);
  border-radius: min(
    var(--g-border-radius-standard),
    var(--g-border-radius-min)
  );
  color: #1d2129;
  max-width: 500px;
}

.og__image {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  height: 237px;
  position: relative;
  overflow: hidden;
}

.og__image img {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  height: auto;
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @supports (object-fit: cover) {
    height: 100%;
    position: static;
    object-fit: cover;
    transform: none;
    width: 100%;
  }
}

.og__content {
  border-top: 1px solid #dddfe2;
  padding: 10px 12px;
}

.og__site {
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  text-transform: uppercase;
}

.og__title {
  font-weight: 700;
  margin: 5px 0 0;
  word-wrap: break-word;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.og__description {
  color: #606770;
  font-size: 13px;
  line-height: 20px;
  margin: 3px 0 0;
  max-height: 80px;
  overflow: hidden;
  word-wrap: break-word;
}
</style>
