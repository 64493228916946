import { computed, onMounted, ref, watch } from 'vue';

import store from '@/store';
import { useRouter } from '@/compositions/use-router';
import { useRoute } from '@/compositions/use-route';
import { useCurrentUser } from '../user';
import { localAxios } from '@/apis';
import { CAN_MANAGE_ANALYTICS } from '@/config';
import toast from '@/utils/toast';

export function useAccountForReports() {
  const route = useRoute();
  const router = useRouter();
  const isLoading = ref(false);
  const profiles = ref([]);
  const { id: userId } = useCurrentUser();

  const accounts = computed(() =>
    store.getters['analytics/getField']('accounts'),
  );

  const hasOnlyUserAccount = computed(() => {
    return accounts.value?.length === 1;
  });

  const selectedAccount = computed({
    get: () => store.getters['analytics/getField']('selectedAccount'),
    set: (value) =>
      store.commit('analytics/updateField', {
        path: 'selectedAccount',
        value,
      }),
  });

  const account = computed(() => {
    return accounts.value?.find((p) => p._id.$oid === selectedAccount.value);
  });

  const hasAnalyticsAddon = computed(() => {
    return account.value?.has_analytics_addon;
  });

  const isAccountUser = computed(() => {
    return selectedAccount.value === userId.value;
  });

  function setInitialAccount() {
    if (selectedAccount.value) return;

    // The first item will be the current user's account
    const userAccount = accounts.value[0]?._id.$oid;
    const firstAccountWithAnalyticsAddon = accounts.value.find(
      (a) => a.has_analytics_addon,
    )?._id.$oid;
    selectedAccount.value = firstAccountWithAnalyticsAddon || userAccount;
  }

  watch(
    route,
    () => {
      if (route.value.params.id) {
        // Reset the loaded profile if the route id changes
        if (route.value.params.id !== selectedAccount.value) {
          selectedAccount.value = route.value.params.id;
        }
      }
    },
    { immediate: true },
  );

  watch(
    selectedAccount,
    () => {
      if (!selectedAccount.value) return;

      // Update the route if the selected auth changes
      const currentId = route.value.params.id;
      if (currentId !== selectedAccount.value)
        router.push({ params: { id: selectedAccount.value } });

      loadProfiles();
    },
    { immediate: true },
  );

  async function loadAccounts() {
    isLoading.value = true;
    try {
      await store.dispatch('analytics/loadAccounts');
      setInitialAccount();
    } catch (e) {
      console.error(e);
      toast.error('Failed to load accounts');
    }
  }

  async function loadProfiles() {
    isLoading.value = true;
    try {
      const profileParams = { enabled: true, sort: 'username' };

      if (isAccountUser.value) {
        profileParams.type = 'owner_access';
      } else {
        profileParams.organization_id = selectedAccount.value;
      }

      const { data } = await localAxios.get(`/api/profiles/all`, {
        params: profileParams,
      });

      if (isAccountUser.value) {
        const ownerProfiles = data.profiles.filter(
          (p) => p.access.entitlements === 'owner',
        );
        const collabProfiles = data.profiles.filter(
          (p) => p.access.entitlements !== 'owner',
        );

        // Combine owner profiles with collaborator profiles that have the proper permissions
        profiles.value = [
          ...ownerProfiles,
          ...collabProfiles.filter(
            (p) =>
              p.access.entitlements.includes(CAN_MANAGE_ANALYTICS) &&
              p.addons.includes('analytics'),
          ),
        ];
      } else {
        profiles.value = data.profiles;
      }
    } catch (e) {
      console.error(e);
      toast.error('Failed to your profiles');
    }
    isLoading.value = false;
  }

  onMounted(() => {
    loadAccounts();
  });

  return {
    selectedAccount,
    isLoading,
    profiles,
    hasAnalyticsAddon,
    account,
    hasOnlyUserAccount,
  };
}
