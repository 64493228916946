<template>
  <ItemAccordion :active="localHasDob" @update:active="localHasDob = $event">
    Date of birth

    <VText slot="action" component="span" :color="status.color">{{
      status.label
    }}</VText>

    <template slot="content">
      <ValidationProvider
        v-slot="{ errors, ariaInput, ariaMsg }"
        :rules="{
          required: hasDob,
        }"
        name="minimum age"
        slim
      >
        <FormGroup>
          <Label :for="`minage${id}`" gutter-bottom>Set minimum age</Label>
          <MinimumAgeSelect
            :id="`minage${id}`"
            v-model="localAge"
            v-bind="ariaInput"
            :has-error="errors.length > 0"
          />
          <FormGroupHelp color="error" v-bind="ariaMsg">{{
            errors[0]
          }}</FormGroupHelp>
          <FormGroupHelp
            >Visitors must be over the selected age to unlock the
            link</FormGroupHelp
          >
        </FormGroup>
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors, ariaInput, ariaMsg }"
        rules="max:240"
        name="age explanation"
        slim
      >
        <FormGroup>
          <Label :for="`ageexplanation${id}`" gutter-bottom
            >Lock explanation</Label
          >
          <Input
            :id="`ageexplanation${id}`"
            v-model="localAgeDescription"
            style="max-width: 100%; width: 450px"
            component="textarea"
            rows="3"
            v-bind="ariaInput"
            maxlength="240"
            :has-error="errors.length > 0"
          />
          <FormGroupHelp color="error" v-bind="ariaMsg">{{
            errors[0]
          }}</FormGroupHelp>
          <FormGroupHelp
            >Provide a short explanation for this lock</FormGroupHelp
          >
        </FormGroup>
      </ValidationProvider>
    </template>
  </ItemAccordion>
</template>

<script>
import {
  Input,
  FormGroup,
  FormGroupHelp,
  Label,
  ItemAccordion,
  VText,
} from '@campsite-bio/component-lib';
import { ValidationProvider } from 'vee-validate';
import { computed, toRefs } from 'vue';

import { MinimumAgeSelect } from '../../../form';
import { useModel } from '../../../../compositions';

export default {
  components: {
    Input,
    FormGroup,
    FormGroupHelp,
    Label,
    MinimumAgeSelect,
    ItemAccordion,
    ValidationProvider,
    VText,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    hasDob: Boolean,

    age: {
      type: [String, Number],
      default: null,
    },

    ageDescription: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { hasDob, age, ageDescription } = toRefs(props);
    const localHasDob = useModel(hasDob, (val) => emit('update:has-dob', val));
    const localAge = useModel(age, (val) => emit('update:age', val));
    const localAgeDescription = useModel(ageDescription, (val) =>
      emit('update:age-description', val),
    );

    const status = computed(() => {
      if (age.value) {
        return {
          label: 'Active',
          color: 'success',
        };
      }

      return {
        label: 'Set up',
        color: 'info',
      };
    });

    return { localHasDob, localAge, localAgeDescription, status };
  },
};
</script>

<style lang="scss" scoped></style>
