import { ref } from 'vue';
import { useTheme } from '@campsite-bio/component-lib';

let JSConfetti;

export function useConfetti() {
  const confetti = ref(null);
  const theme = useTheme();

  async function showConfetti() {
    if (!JSConfetti) {
      JSConfetti = await import('js-confetti');
      JSConfetti = JSConfetti.default;
    }
    if (!confetti.value) confetti.value = new JSConfetti();
    confetti.value.clearCanvas();
    const { lightBlue, darkGreen, lightGreen, orange, yellow } =
      theme.value.colors;
    confetti.value.addConfetti({
      confettiColors: [lightBlue, darkGreen, lightGreen, orange, yellow],
    });
  }

  return showConfetti;
}
