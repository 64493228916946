<template>
  <List>
    <ListItem
      v-for="{ name, value } in items"
      :key="value"
      component="button"
      @click="localValue = value"
    >
      <ListItemTitle>{{ name }}</ListItemTitle>
    </ListItem>
  </List>
</template>

<script>
import { List, ListItem, ListItemTitle } from '@campsite-bio/component-lib';
import { toRefs } from 'vue';

import { useModel } from '../../compositions';

export default {
  components: {
    List,
    ListItem,
    ListItemTitle,
  },

  props: {
    value: {
      type: String,
      default: null,
    },

    items: {
      type: Array,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const localValue = useModel(value, (val) => emit('input', val));

    return { localValue };
  },
};
</script>

<style lang="scss" scoped></style>
