export const CAN_MANAGE_LINKS = 'can_manage_links';
export const CAN_MANAGE_APPEARANCE = 'can_manage_appearance';
export const CAN_MANAGE_SETTINGS = 'can_manage_settings';
export const CAN_MANAGE_URLS = 'can_manage_urls';
export const CAN_MANAGE_ANALYTICS = 'can_manage_analytics';
export const CAN_MANAGE_USERS = 'can_manage_users';
export const IS_PROFILE_OWNER = 'owner';

export const PROFILE_ENTITLEMENTS = [
  {
    id: CAN_MANAGE_LINKS,
    name: 'Can manage links',
    description: 'An invited user will always have access to your links',
    default: true,
    locked: true,
  },
  {
    id: CAN_MANAGE_ANALYTICS,
    name: 'Can view analytics',
    default: true,
  },
  {
    id: CAN_MANAGE_APPEARANCE,
    name: 'Can manage appearance',
  },
  {
    id: CAN_MANAGE_SETTINGS,
    name: 'Can manage settings',
  },
  {
    id: CAN_MANAGE_URLS,
    name: 'Can manage URLs and domain',
  },
];
