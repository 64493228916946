export const LinkProps = {
  link: {
    type: Object,
    required: true,
  },

  pinnedDisabled: Boolean,
  isAddLinkDisabled: Boolean,
  readonly: Boolean,
  hideActions: {
    type: Array,
    default: () => [],
  },
};
