<template>
  <RatioBox v-bind="$attrs" ref="root" :ratio="1">
    <Skeleton height="100%" width="100%" />
  </RatioBox>
</template>

<script>
import { computed, ref } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';
import { Skeleton, RatioBox } from '@campsite-bio/component-lib';

export default {
  components: {
    Skeleton,
    RatioBox,
  },

  setup(props, { emit }) {
    const root = ref(null);
    const rootEl = computed(() => root.value?.$el);
    useIntersectionObserver(rootEl, ([{ isIntersecting }]) => {
      if (isIntersecting) {
        emit('is-in-view');
      }
    });

    return { root };
  },
};
</script>

<style lang="scss" scoped></style>
