<template>
  <div class="commerce-items">
    <Item
      v-for="(item, i) in items"
      :key="i"
      :item="item"
      :hide-remove="i === 0"
      :hide-name="hideName"
      :currency="currencyInfo ? currencyInfo.symbol : null"
      @remove="remove(item)"
      @input="update(item, $event)"
    />

    <FormGroupHelp> Maximum of {{ maxAmount }} items. </FormGroupHelp>

    <Button
      variant="outline"
      :disabled="items.length === maxAmount"
      @click="add"
      >Add Option</Button
    >
  </div>
</template>

<script>
import {
  Button,
  FormGroupHelp,
  useCurrency,
} from '@campsite-bio/component-lib';
import { toRefs } from 'vue';

import Item from './item';
import { useModel } from '../../../../compositions';

export default {
  components: {
    Button,
    Item,
    FormGroupHelp,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    hideName: Boolean,

    maxAmount: {
      type: Number,
      default: 5,
    },

    currencyCode: {
      type: String,
      default: 'USD',
    },
  },

  setup(props, { emit }) {
    const { value, currencyCode } = toRefs(props);
    const items = useModel(value, (val) => emit('input', val));

    const currencyInfo = useCurrency(currencyCode);

    function add() {
      items.value.push({ price: null });
    }

    function remove(item) {
      const index = items.value.indexOf(item);

      if (index !== -1) {
        items.value.splice(index, 1);
      }
    }

    function update(item, newItem) {
      const index = items.value.indexOf(item);

      if (index !== -1) {
        items.value.splice(index, 1, newItem);
      }
    }

    return { items, add, remove, update, currencyInfo };
  },
};
</script>

<style lang="scss" scoped>
.commerce-items > * {
  margin-bottom: 0.75rem;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
