<template>
  <Card class="settings-section" :component="component" v-bind="$attrs">
    <CardText>
      <slot />
    </CardText>
    <CardActions v-if="$slots.actions" align="left">
      <slot name="actions" />
    </CardActions>
  </Card>
</template>

<script>
import { Card, CardText, CardActions } from '@campsite-bio/component-lib';

export default {
  components: {
    Card,
    CardText,
    CardActions,
  },

  props: {
    component: {
      type: String,
      default: 'section',
    },
  },

  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.settings-section {
  margin-bottom: 1.875rem;
  scroll-margin: calc(
    var(--header-height) + var(--settings-scroll-header-height)
  );

  @media (min-width: 992px) {
    scroll-margin: var(--profile-menu-height);
  }
}
</style>
