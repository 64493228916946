<template>
  <div>
    <VText component="h3" variant="h5" gutter-bottom>Jotform Options</VText>

    <ValidationProvider
      v-slot="{ errors, ariaMsg }"
      rules="required"
      name="layout"
      slim
    >
      <FormGroup>
        <Label component="span" block gutter-bottom>Layout</Label>
        <InputBtnGroup
          v-model="layoutLocal"
          aria-label="Layout options"
          size="sm"
        >
          <IconInputBtn
            v-for="{ value, name, icon } in layoutOptions"
            :key="value"
            :value="value"
            :ratio="1"
          >
            <component :is="icon" slot="icon" />
            {{ name }}
          </IconInputBtn>
        </InputBtnGroup>
        <FormGroupHelp color="error" v-bind="ariaMsg">
          {{ errors[0] }}
        </FormGroupHelp>
      </FormGroup>
    </ValidationProvider>

    <template v-if="layoutLocal === 'standard'">
      <FormGroup>
        <Toggle v-model="removeBgLocal">Remove background</Toggle>
        <FormGroupHelp
          >Remove the background that is added behind the form</FormGroupHelp
        >
      </FormGroup>
    </template>

    <template v-else>
      <ValidationProvider
        v-slot="{ errors, ariaMsg, ariaInput }"
        rules="required|max:80"
        name="button text"
        slim
      >
        <FormGroup>
          <Label :for="`height${id}`" gutter-bottom>Button Text</Label>
          <Input
            v-model="buttonTextLocal"
            v-bind="ariaInput"
            style="max-width: 300px"
            maxlength="80"
          />
          <FormGroupHelp color="error" v-bind="ariaMsg">
            {{ errors[0] }}
          </FormGroupHelp>
        </FormGroup>
      </ValidationProvider>
    </template>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import {
  FormGroup,
  FormGroupHelp,
  Label,
  VText,
  Input,
  Toggle,
  Alert,
  InfoIcon,
  InputBtnGroup,
  IconInputBtn,
} from '@campsite-bio/component-lib';
import { computed, toRefs } from 'vue';
import uniqueId from 'lodash/uniqueId';

import { LAYOUT_ICONS } from '../icons';
import { useModel } from '../../../../compositions';

export default {
  components: {
    ValidationProvider,
    FormGroup,
    Label,
    FormGroupHelp,
    InputBtnGroup,
    IconInputBtn,
    VText,
    Input,
    Toggle,
    Alert,
    InfoIcon,
    ...LAYOUT_ICONS,
  },

  props: {
    formOptions: {
      type: Object,
      required: true,
    },
    formType: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { formOptions, formType } = toRefs(props);
    const id = uniqueId('form');

    const removeBg = computed(() => {
      return formOptions.value.remove_bg;
    });

    const layout = computed(() => {
      return formOptions.value.layout;
    });

    const height = computed(() => {
      return formOptions.value.height;
    });

    const buttonText = computed(() => {
      return formOptions.value.button_text;
    });

    const removeBgLocal = useModel(removeBg, (val) =>
      emit('input-remove-bg', val),
    );
    const layoutLocal = useModel(layout, (val) => emit('input-layout', val));
    const heightLocal = useModel(height, (val) => emit('input-height', val));
    const buttonTextLocal = useModel(buttonText, (val) =>
      emit('input-button-text', val),
    );

    const layoutOptions = computed(() => {
      return formType.value.layouts;
    });

    return {
      layoutOptions,
      layoutLocal,
      id,
      heightLocal,
      buttonTextLocal,
      removeBgLocal,
    };
  },
};
</script>

<style lang="scss"></style>
