import { useStore } from '../use-store';
import { useAuthorizationDialog } from '../use-authorization-dialog';
import { unref } from 'vue';

export function useInstagramAuthorization(authDialogOptions, openParams = {}) {
  const store = useStore();

  const { open, isAuthorizing } = useAuthorizationDialog(authDialogOptions);

  function authorize() {
    const { type, ...rest } = openParams;
    open({
      provider: 'Instagram',
      url: '/redirect/instagram',
      urlMethod: 'POST',
      params: {
        profile_id: store.getters['profiles/currentProfileId'],
        type: unref(type),
        ...rest,
      },
    });
  }

  return {
    authorize,
    isAuthorizing,
  };
}
