<template>
  <Fragment>
    <VideoEventsCard
      v-for="linkData in videoLinks"
      :key="linkData.id"
      class="span-6"
      :data="linkData"
      :is-loading-links="isLoadingLinks"
    />
  </Fragment>
</template>

<script>
import { computed } from 'vue';
import { Fragment } from '@campsite-bio/component-lib';

import VideoEventsCard from './video-events-card';

export default {
  components: {
    Fragment,
    VideoEventsCard,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    links: {
      type: Array,
      default: null,
    },

    isLoadingLinks: Boolean,
  },

  setup(props) {
    const uniqueLinks = computed(() => {
      return props.data.video_quartiles_by_link.reduce((acc, data) => {
        if (!acc.find((id) => id === data.dimensions.lid))
          acc.push(data.dimensions.lid);
        return acc;
      }, []);
    });

    const videoLinks = computed(() => {
      return uniqueLinks.value.map((id) => {
        return {
          id,
          link: props.links ? props.links.find((l) => l._id.$oid === id) : null,
          video_quartiles_by_link: props.data.video_quartiles_by_link.filter(
            (data) => data.dimensions.lid === id,
          ),
          standard_events: props.data.standard_events.filter(
            (data) => data.dimensions.link_id === id,
          ),
        };
      });
    });

    return {
      videoLinks,
    };
  },
};
</script>

<style lang="scss" scoped></style>
