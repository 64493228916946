<template>
  <ListItem class="profile-item">
    <ListItemAvatar>
      <img :src="imageSrc" alt="" />
    </ListItemAvatar>

    <ListItemContent>
      <ListItemTitle class="profile-item__title">
        {{ profile.username }}
      </ListItemTitle>

      <ListItemSubtitle v-if="showStats" class="profile-item__stats">
        <span class="profile-item__stats__stat">
          <span class="profile-item__stats__label">
            Views
            <Badge
              v-if="!isLoading && isTopline"
              :color="viewBadgeColor"
              :content="viewDifferenceFormatted"
            />
          </span>
          <Skeleton v-if="isLoading" width="50px" />
          <v-tooltip v-else-if="hasLoadError" bottom>
            <template #activator="{ on, attrs }">
              <InfoIcon color="error" v-bind="attrs" v-on="on" />
            </template>
            <span>There was an error loading data</span>
          </v-tooltip>
          <template v-else-if="currentData">
            {{ currentData.views | numberFormatter(2) }}
          </template>
        </span>

        <span class="profile-item__stats__stat">
          <span class="profile-item__stats__label">
            Clicks
            <Badge
              v-if="!isLoading && isTopline"
              :color="clickBadgeColor"
              :content="clicksDifferenceFormatted"
            />
          </span>
          <Skeleton v-if="isLoading" width="50px" />
          <v-tooltip v-else-if="hasLoadError" bottom>
            <template #activator="{ on, attrs }">
              <InfoIcon color="error" v-bind="attrs" v-on="on" />
            </template>
            <span>There was an error loading data</span>
          </v-tooltip>
          <template v-else-if="currentData">
            {{ currentData.clicks | numberFormatter(2) }}
          </template>
        </span>

        <span class="profile-item__stats__stat">
          <span class="profile-item__stats__label">
            CTR
            <Badge
              v-if="!isLoading && isTopline"
              :color="ctrBadgeColor"
              :content="ctrDifferenceFormatted"
            />
          </span>
          <Skeleton v-if="isLoading" width="50px" />
          <v-tooltip v-else-if="hasLoadError" bottom>
            <template #activator="{ on, attrs }">
              <InfoIcon color="error" v-bind="attrs" v-on="on" />
            </template>
            <span>There was an error loading data</span>
          </v-tooltip>
          <template v-else-if="currentData">
            {{ (currentData.ctr * 100).toFixed(1) }}%
          </template>
        </span>
      </ListItemSubtitle>
    </ListItemContent>

    <ListItemAction v-if="!hideActions">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <IconButton
              aria-label="Open profile"
              :to="`profile/${profile._id.$oid}`"
            >
              <LoginIcon size="1.25rem" />
            </IconButton>
          </span>
        </template>
        <span>Open this profile</span>
      </v-tooltip>
    </ListItemAction>
  </ListItem>
</template>

<script>
import {
  ListItem,
  ListItemContent,
  ListItemTitle,
  ListItemSubtitle,
  ListItemAction,
  ListItemAvatar,
  IconButton,
  convertImageUrlToEncodedUrl,
  MEDIA_SIZES,
  LoginIcon,
  Badge,
  Skeleton,
} from '@campsite-bio/component-lib';
import { computed, ref, watch } from 'vue';

import { percentageChange } from '../../utils';
import { useLoadData } from '../analytics';

export default {
  components: {
    ListItem,
    ListItemContent,
    ListItemTitle,
    ListItemSubtitle,
    ListItemAvatar,
    ListItemAction,
    IconButton,
    LoginIcon,
    Badge,
    Skeleton,
  },

  props: {
    profile: {
      type: Object,
      required: true,
    },

    showStats: Boolean,
    statsVariant: {
      type: String,
      default: 'lifetime',
    },
    hideActions: Boolean,
  },

  setup(props) {
    const hasLoadError = ref(false);

    const { data, load, isLoading } = useLoadData(
      `profiles.${props.profile._id.$oid}_topline`,
      'loadReport',
      {
        token: props.profile.token,
        report: 'topline',
        range: 'last_7',
      },
      {
        onError() {
          hasLoadError.value = true;
        },
      },
    );

    const imageSrc = computed(() =>
      convertImageUrlToEncodedUrl(
        props.profile.profile_image,
        MEDIA_SIZES.profile_image,
      ),
    );

    const isTopline = computed(() => props.statsVariant === 'topline');

    const currentData = computed(() => {
      if (!isTopline.value) {
        const { lifetime_clicks, lifetime_views } = props.profile;

        return {
          views: lifetime_views,
          clicks: lifetime_clicks,
          ctr: lifetime_views === 0 ? 0 : lifetime_clicks / lifetime_views,
        };
      }

      return data.value?.find(({ range }) => range === 'current');
    });

    const pastData = computed(() => {
      return data.value?.find(({ range }) => range === 'past');
    });

    const viewDifference = computed(() => {
      return percentageChange(pastData.value?.views, currentData.value?.views);
    });

    const viewDifferenceFormatted = computed(() => {
      if (typeof viewDifference.value === 'number')
        return `${viewDifference.value.toFixed(1)}%`;
      return viewDifference.value;
    });

    const viewBadgeColor = computed(() => {
      return viewDifference.value > 0 ? 'success' : 'info';
    });

    const clicksDifference = computed(() => {
      return percentageChange(
        pastData.value?.clicks,
        currentData.value?.clicks,
      );
    });

    const clicksDifferenceFormatted = computed(() => {
      if (typeof clicksDifference.value === 'number')
        return `${clicksDifference.value.toFixed(1)}%`;
      return clicksDifference.value;
    });

    const clickBadgeColor = computed(() => {
      return clicksDifference.value > 0 ? 'success' : 'info';
    });

    const ctrDifference = computed(() => {
      return percentageChange(pastData.value?.ctr, currentData.value?.ctr);
    });

    const ctrDifferenceFormatted = computed(() => {
      if (typeof ctrDifference.value === 'number')
        return `${ctrDifference.value.toFixed(1)}%`;
      return ctrDifference.value;
    });

    const ctrBadgeColor = computed(() => {
      return ctrDifference.value > 0 ? 'success' : 'info';
    });

    watch(
      () => props.showStats,
      (newValue) => {
        if (newValue && props.statsVariant === 'topline') load();
      },
      { immediate: true },
    );

    return {
      isLoading,
      currentData,
      viewDifferenceFormatted,
      viewBadgeColor,
      clicksDifferenceFormatted,
      clickBadgeColor,
      ctrDifferenceFormatted,
      ctrBadgeColor,
      imageSrc,
      hasLoadError,
      isTopline,
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-item {
  background: #fff;
  border: 1px solid var(--g-color-gray-200);
  border-radius: var(--g-border-radius-standard);
  border-radius: min(
    var(--g-border-radius-standard),
    var(--g-border-radius-min)
  );

  &__title {
    font-weight: 600;
  }

  &__stats {
    display: flex;
    margin-top: 0.15rem;

    &__stat {
      width: 75px;

      @media (min-width: 500px) {
        width: 100px;
      }
    }

    &__label {
      display: block;
      font-weight: 600;

      &::v-deep .v-badge {
        display: none;
        font-size: 65%;

        @media (min-width: 500px) {
          display: unset;
        }

        @media (min-width: 768px) {
          font-size: 70%;
        }
      }
    }
  }
}
</style>
