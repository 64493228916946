<template>
  <button class="social-btn" :class="classes" v-bind="$attrs" v-on="$listeners">
    <slot />
  </button>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    variant: {
      type: String,
      default: null,
    },

    block: Boolean,
  },

  setup(props) {
    const classes = computed(() => {
      return {
        'social-btn--block': props.block,
        [`social-btn--${props.variant}`]: props.variant,
      };
    });

    return { classes };
  },
};
</script>

<style lang="scss" scoped>
.social-btn {
  align-items: center;
  border: none;
  border-radius: 0.25rem;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: var(--g-font-family);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: normal;
  justify-content: center;
  margin: 0.4375rem 0;
  padding: 0.75rem 0.925rem;
  text-decoration: none;
  transition: all 200ms;

  &--block {
    display: flex;
    width: 100%;
  }

  &--instagram {
    background: #4c68d7;

    &:hover,
    &:focus {
      background: darken(#4c68d7, 10%);
      color: #fff;
    }
  }

  &--facebook {
    background: #4267b2;

    &:hover,
    &:focus {
      background: darken(#4267b2, 10%);
      color: #fff;
    }
  }

  &--google {
    background: #db4437;

    &:hover,
    &:focus {
      background: darken(#db4437, 10%);
      color: #fff;
    }
  }

  .v-svg-icon.v-svg-icon {
    font-size: 160%;
    margin-right: 0.375rem;
  }
}
</style>
