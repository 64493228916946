<template>
  <Card border-color="primary">
    <CardTitle>
      <VText component="h2" variant="h5">Setup your opt-in link</VText>
      <FlexSpacer />
      <IconButton
        size="1.25rem"
        :loading="isDeleting"
        title="Delete"
        aria-label="Delete"
        @click="$emit('remove')"
      >
        <CloseIcon />
      </IconButton>
    </CardTitle>
    <Divider color="primary" />
    <CardText>
      <ValidationObserver v-slot="{ invalid, handleSubmit }" ref="form" slim>
        <form @submit.prevent="handleSubmit(handleSetup)">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            name="type"
            slim
          >
            <FormGroup>
              <Label component="span" block gutter-bottom>Opt-in Type</Label>
              <BoxInputGroup>
                <BoxInput
                  v-for="({ name, icon }, index) in types"
                  :key="name"
                  v-model="type"
                  :name="`type${linkId}`"
                  :input-id="`type${index}${linkId}`"
                  :input-value="name"
                  :tooltip="startCase(name)"
                >
                  <component :is="icon" slot="icon" />
                </BoxInput>
              </BoxInputGroup>
              <FormGroupHelp color="error">{{ errors[0] }}</FormGroupHelp>
            </FormGroup>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            name="destination"
            slim
          >
            <FormGroup :gutter-bottom="!!selectedSource">
              <Label component="span" block gutter-bottom
                >Choose your destination</Label
              >
              <BoxInputGroup>
                <BoxInput
                  v-for="(
                    { name, source, iconComponent, color }, index
                  ) in sources"
                  :key="source"
                  v-model="selectedSource"
                  :name="`destination${linkId}`"
                  :input-id="`destination${index}${linkId}`"
                  :input-value="source"
                  :tooltip="name"
                >
                  <component :is="iconComponent" slot="icon" :color="color" />
                </BoxInput>
              </BoxInputGroup>
              <FormGroupHelp color="error">{{ errors[0] }}</FormGroupHelp>
            </FormGroup>
          </ValidationProvider>

          <template v-if="selectedSource && type">
            <DestinationActiveCampaign
              v-if="selectedSource === 'active_campaign'"
              :link="link"
              :type="type"
              @change="handleDestinationChange"
            />
            <DestinationGoogle
              v-if="selectedSource === 'google'"
              :link="link"
              :type="type"
              @change="handleDestinationChange"
            />
            <DestinationMailchimp
              v-if="selectedSource === 'mailchimp'"
              :link="link"
              :type="type"
              @change="handleDestinationChange"
            />
            <DestinationMailerLite
              v-if="selectedSource === 'mailer_lite'"
              :link="link"
              :type="type"
              @change="handleDestinationChange"
            />
            <DestinationKlaviyo
              v-if="selectedSource === 'klaviyo'"
              :link="link"
              :type="type"
              @change="handleDestinationChange"
            />
            <DestinationConvertKit
              v-if="selectedSource === 'convert_kit'"
              :link="link"
              :type="type"
              @change="handleDestinationChange"
            />
            <DestinationZapier
              v-if="selectedSource === 'zapier'"
              :link="link"
              :type="type"
              @change="handleDestinationChange"
            />
            <DestinationOmnisend v-if="selectedSource === 'omnisend'" />
            <Button type="submit" :loading="isSaving" :disabled="invalid"
              >Finish Initial Setup</Button
            >
          </template>
        </form>
      </ValidationObserver>
    </CardText>
  </Card>
</template>

<script>
import {
  Card,
  CardText,
  CardTitle,
  Button,
  Divider,
  Input,
  Label,
  FormGroup,
  FormGroupHelp,
  VText,
  IconButton,
  CloseIcon,
  FlexSpacer,
} from '@campsite-bio/component-lib';
import { computed, nextTick, ref, watch } from 'vue';
import startCase from 'lodash/startCase';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import {
  DestinationMailchimp,
  DestinationGoogle,
  DestinationKlaviyo,
  DestinationActiveCampaign,
  DestinationMailerLite,
  DestinationOmnisend,
  DestinationConvertKit,
  DestinationZapier,
} from './destinations';
import { EMAIL_TYPES, EMAIL_ICONS } from './email-types';
import { useStore } from '@/compositions';
import { BoxInput, BoxInputGroup } from '../../box-input';
import toast from '@/utils/toast';

export default {
  components: {
    Card,
    CardText,
    CardTitle,
    Button,
    Divider,
    Input,
    FormGroup,
    FormGroupHelp,
    Label,
    VText,
    FlexSpacer,
    IconButton,
    CloseIcon,
    BoxInput,
    BoxInputGroup,
    ValidationProvider,
    ValidationObserver,
    DestinationMailchimp,
    DestinationGoogle,
    DestinationKlaviyo,
    DestinationActiveCampaign,
    DestinationMailerLite,
    DestinationOmnisend,
    DestinationConvertKit,
    DestinationZapier,
    ...EMAIL_ICONS,
  },

  props: {
    emailOptions: {
      type: Object,
      required: true,
    },

    linkId: {
      type: String,
      required: true,
    },

    link: {
      type: Object,
      required: true,
    },

    isDeleting: Boolean,
  },

  setup(props, { emit }) {
    const store = useStore();
    const selectedSource = ref(null);
    const types = [
      { name: 'email', icon: 'EmailIcon' },
      { name: 'sms', icon: 'PhoneIcon' },
    ];
    const type = ref(types[0].name);
    const destinationId = ref(null);
    const destinationTitle = ref(null);
    const destinationVariant = ref(null);
    const isSaving = ref(false);
    const form = ref(null);

    const sources = computed(() => {
      if (!type.value) return EMAIL_TYPES;
      return EMAIL_TYPES.filter((source) => source.types.includes(type.value));
    });

    const selectedSourceDetails = computed(() => {
      if (!selectedSource.value) return null;
      return EMAIL_TYPES.find((type) => type.source === selectedSource.value);
    });

    watch([type, selectedSource], async () => {
      if (form.value) {
        await nextTick();
        form.value.reset();
      }
    });

    // Reset selected source on type change
    watch(type, async () => {
      selectedSource.value = null;
    });

    async function handleSetup() {
      isSaving.value = true;

      try {
        // Save the result to the feed options
        const { link } = await store.dispatch('links/updateOptions', {
          id: props.linkId,
          name: 'email',
          type: type.value,
          source: selectedSource.value,
          has_setup: true,
          destination_id: destinationId.value,
          destination_title:
            destinationTitle.value || selectedSourceDetails.value.name,
          variant: destinationVariant.value,
        });
        // TODO: add default phone or email field to merge fields

        // Show the link now that it's been setup
        store.commit('links/updateLinkOptions', {
          id: props.linkId,
          value: link.email_options,
          name: 'email_options',
        });
        emit('setup-complete');
      } catch (e) {
        console.error(e);
        toast.error('There was an error trying to get your email destination');
      }

      isSaving.value = false;
    }

    function handleDestinationChange({ id, title, variant }) {
      destinationId.value = id;
      destinationTitle.value = title;
      destinationVariant.value = variant;
    }

    return {
      sources,
      selectedSource,
      selectedSourceDetails,
      handleSetup,
      isSaving,
      form,
      destinationId,
      destinationTitle,
      handleDestinationChange,
      type,
      startCase,
      types,
    };
  },
};
</script>

<style lang="scss" scoped></style>
