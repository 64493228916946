<template>
  <header
    class="header js-header-mobile"
    :class="classes"
    @click.prevent="$emit('scrollTop')"
  >
    <HeaderNavLink
      class="header__nav-link--home"
      aria-label="Home"
      to="/"
      exact
    >
      <div
        class="header__logo-wrapper"
        :class="{ 'header__logo-wrapper--loading': isLoading }"
      >
        <Logo size="30" class="header__logo" only-mark />
        <span class="header__loader">
          <CircularLoader v-if="isLoading" size="3rem" :thickness="2" />
        </span>
      </div>
    </HeaderNavLink>

    <FlexSpacer />

    <ProfileSwitchBtn
      v-if="showProfileSwitchBtn"
      :is-loading="isLoadingProfile && !hasProfile"
      :image-src="profile.profile_image"
      :username="profile.username"
    />

    <button
      class="header__menu-trigger js-nav-trigger"
      type="button"
      aria-label="Toggle menu"
      @click.stop="isMenuOpen = !isMenuOpen"
    >
      <span class="header__menu-trigger__item" />
      <span class="header__menu-trigger__item" />
      <span class="header__menu-trigger__item" />
    </button>

    <nav class="header__nav" aria-label="Main menu" @click="isMenuOpen = false">
      <div class="header__nav__inner">
        <List @click.stop>
          <ListItem to="/">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemTitle>Home</ListItemTitle>
          </ListItem>
          <Divider color="gray100" />
          <template v-if="!hasNoCampsites">
            <ListItem :to="`/profile/${profileId}`">
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemTitle>Profile</ListItemTitle>
            </ListItem>
            <Divider color="gray100" />
            <ListItem
              class="header__nav__item header__nav__item--indent"
              :to="`/profile/${profileId}/settings/appearance`"
            >
              <ListItemTitle>Appearance</ListItemTitle>
            </ListItem>
            <Divider color="gray100" />
            <ListItem
              class="header__nav__item header__nav__item--indent"
              :to="`/profile/${profileId}/settings`"
            >
              <ListItemTitle>Settings</ListItemTitle>
            </ListItem>
            <Divider color="gray100" />
            <ListItem
              class="header__nav__item header__nav__item--indent"
              :to="`/profile/${profileId}/analytics`"
            >
              <ListItemTitle>Analytics</ListItemTitle>
            </ListItem>
            <Divider color="gray100" />
            <ListItem to="/profiles">
              <ListItemIcon>
                <AccountGroupIcon />
              </ListItemIcon>
              <ListItemTitle>Profiles</ListItemTitle>
            </ListItem>
            <Divider color="gray100" />
            <ListItem to="/reports">
              <ListItemIcon>
                <BarChartAltIcon />
              </ListItemIcon>
              <ListItemTitle>Reports</ListItemTitle>
            </ListItem>
            <Divider color="gray100" />
          </template>
          <ListItem to="/account">
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemTitle>Account</ListItemTitle>
          </ListItem>
          <Divider color="gray100" />
          <ListItem
            class="header__nav__item header__nav__item--indent"
            component="button"
            :disabled="isPortalLoading"
            @click="getBillingPortal"
          >
            <ListItemTitle>Billing</ListItemTitle>
          </ListItem>
          <Divider color="gray100" />
          <ListItem
            class="header__nav__item header__nav__item--indent"
            to="/account/organizations"
          >
            <ListItemTitle> Organizations </ListItemTitle>
          </ListItem>
          <Divider :thickness="5" color="gray100" />
          <ListItem component="button" type="button" @click="$emit('logout')">
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemTitle>Log out</ListItemTitle>
          </ListItem>
        </List>
      </div>
    </nav>
  </header>
</template>

<script>
import {
  PersonIcon,
  HomeIcon,
  LogoutIcon,
  AccountGroupIcon,
  FlexSpacer,
  List,
  ListItem,
  ListItemTitle,
  ListItemIcon,
  CircularLoader,
  BarChartAltIcon,
  LinkIcon,
  Logo,
  Divider,
  Badge,
} from '@campsite-bio/component-lib';
import { computed, inject, ref, watch } from 'vue';
import { useMediaQuery } from '@vueuse/core';

import { ProfileSwitchBtn, HeaderNavLink } from '.';
import { useRoute, useStore, useUserBillingPortal } from '../../compositions';

export default {
  components: {
    Logo,
    PersonIcon,
    HomeIcon,
    LogoutIcon,
    AccountGroupIcon,
    FlexSpacer,
    List,
    ListItem,
    ListItemTitle,
    ListItemIcon,
    ProfileSwitchBtn,
    HeaderNavLink,
    CircularLoader,
    BarChartAltIcon,
    LinkIcon,
    List,
    ListItem,
    ListItemTitle,
    Divider,
    Badge,
  },

  setup() {
    const store = useStore();
    const showSwitchButton = useMediaQuery('(max-width: 675px)');
    const route = useRoute();
    const isMenuOpen = ref(false);
    const { hideMenu } = inject('app');

    const { isLoading: isPortalLoading, getBillingPortal } =
      useUserBillingPortal();

    const classes = computed(() => {
      return {
        'header--is-open': isMenuOpen.value,
        'header--hide': hideMenu.value,
      };
    });

    const profile = computed(() => {
      return store.getters['profiles/currentProfile'];
    });

    const profileId = computed(() => {
      return store.getters['profiles/currentId'];
    });

    const hasProfile = computed(() => {
      return store.getters['profiles/hasProfile'];
    });

    const isLoadingProfile = computed(() => {
      return store.getters['profiles/isLoadingProfile'];
    });

    const isStripeCustomer = computed(() => {
      return store.getters.isStripeCustomer;
    });

    const isLoggedIn = computed(() => {
      return store.getters.isLoggedIn;
    });

    const isLoading = computed(() => {
      return store.getters.isLoading;
    });

    const hasNoCampsites = computed(() => {
      return store.getters.hasNoCampsites;
    });

    const showProfileSwitchBtn = computed(() => {
      return showSwitchButton.value && route.value.path.startsWith('/profile');
    });

    watch(route, () => {
      isMenuOpen.value = false;
    });

    return {
      classes,
      isMenuOpen,
      showProfileSwitchBtn,
      profile,
      profileId,
      isStripeCustomer,
      isLoggedIn,
      isLoading,
      hasNoCampsites,
      hasProfile,
      isLoadingProfile,
      isPortalLoading,
      getBillingPortal,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: var(--g-color-bg);
  align-items: center;
  border-bottom: 1px solid var(--g-color-gray-200);
  display: flex;
  height: var(--header-height);
  padding: 0 0.5rem;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 101;
  width: 100%;
  transition: transform var(--g-transition-speed);

  &--hide {
    transform: translate3d(0, -100%, 0);
  }

  &--is-open {
    .header__nav {
      visibility: visible;
      opacity: 1;
    }

    .header__nav__inner {
      transform: translateX(0);
    }
  }

  @media print {
    display: none;
  }

  &__logo-wrapper {
    display: inline-flex;
    position: relative;
    transform: translateZ(0);

    &--loading .header__logo {
      transform: scale(0.7);
    }
  }

  &__loader {
    display: inline-flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__menu-trigger {
    background: none;
    border: none;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    gap: 0.25rem;
    margin-left: 0.5rem;
    padding: 0.5rem;
    color: var(--g-color-gray-700);
    width: 2.75rem;

    .header--is-open & {
      .header__menu-trigger__item {
        &:first-child {
          transform: translateY(8px) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          transform: translateY(-8px) rotate(-45deg);
        }
      }
    }

    &:hover,
    &:focus {
      color: var(--g-color-primary);
    }

    &__item {
      background: currentColor;
      border-radius: var(--g-border-radius-standard);
      display: block;
      height: 0.25rem;
      transition: all 200ms;
    }
  }

  &__nav {
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    display: flex;
    top: var(--header-height);
    right: 0;
    left: 0;
    z-index: 101;
    height: 100vh;
    transition: all var(--g-transition-speed);
    visibility: hidden;
    opacity: 0;

    &__inner {
      background: var(--g-color-bg);
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05),
        0 4px 24px 2px rgba(0, 0, 0, 0.1);
      height: 100%;
      margin-left: auto;
      transform: translateX(100%);
      transition: inherit;
      width: min(95vw, 300px);
    }

    &__item {
      &--indent {
        ::v-deep .v-list-item__title {
          padding-left: 3.5rem;
          font-size: 0.9rem;
        }
      }
    }
  }
}

.header__nav-link--home {
  color: var(--g-color-primary);
}
</style>
