<template>
  <nav class="submenu print-hide" :aria-labelledby="_uid" :style="styles">
    <div class="submenu__inner">
      <VText
        :id="_uid"
        class="submenu__title"
        variant="h4"
        component="div"
        :title="title"
      >
        {{ title }}
      </VText>

      <div class="submenu__list">
        <slot />
      </div>
    </div>
  </nav>
</template>

<script>
import { VText, useTheme } from '@campsite-bio/component-lib';
import { computed } from 'vue';
import tinycolor from 'tinycolor2';

import { useStore } from '../../compositions';

export default {
  components: {
    VText,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  setup() {
    const store = useStore();
    const theme = useTheme();

    const isOpen = computed({
      get: () => store.getters['getField']('isSubmenuOpen'),
      set: (value) =>
        store.commit('updateField', { path: 'isSubmenuOpen', value }),
    });

    const styles = computed(() => {
      return {
        '--c-menu-active-color': tinycolor(theme.value.colors.primary)
          .setAlpha(0.08)
          .toRgbString(),
      };
    });

    return { isOpen, styles };
  },
};
</script>

<style lang="scss" scoped>
.submenu {
  background: #fff;
  border-bottom: 1px solid var(--g-color-gray-200);

  @media (min-width: 992px) {
    background: var(--g-color-gray-100);
    border-bottom: none;
    width: 230px;
  }

  &__inner {
    display: flex;
    height: var(--profile-menu-height);
    overflow-x: scroll;
    scrollbar-width: none;

    @media (min-width: 992px) {
      flex-direction: column;
      padding: 0.75rem 1.5rem;
      overflow-x: unset;
      height: unset;
      position: sticky;
      top: 0;
    }
  }

  &__list {
    display: flex;

    @media (min-width: 992px) {
      display: revert;
    }
  }
}

.submenu__title.submenu__title {
  display: none;
  margin-bottom: 1.5rem;

  @media (min-width: 992px) {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
