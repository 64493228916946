<template>
  <div ref="root" class="configuration">
    <VText
      component="h2"
      variant="h3"
      :color="stepColor"
      style="margin-bottom: 0.75rem"
      >Step 2.</VText
    >

    <template v-if="isLoading">
      <AccordionSection v-for="i in 5" :key="i">
        <VText slot="title" variant="h3">
          <Skeleton width="200px" />
        </VText>
        <VText slot="subtitle">
          <Skeleton width="175px" />
        </VText>
      </AccordionSection>
    </template>

    <template v-else-if="designData">
      <AccordionSection
        id="step1"
        v-model="configStatus.step1.isOpen"
        title="General Configuration"
        subtitle="Get started here"
        :variant="configStatus.step1.status"
      >
        <VText style="margin-bottom: 2rem">
          Here is what we found on your website. Take a minute to review or
          modify anything.
        </VText>

        <FormGroup>
          <Label component="span" block gutter-bottom>Profile Image</Label>
          <Avatar v-if="profileImage" size="100">
            <img :src="profileImage" alt="Profile image" />
          </Avatar>
          <VText v-else>We couldn't find a profile image for you.</VText>
        </FormGroup>

        <FormGroup>
          <Label for="site-title">Site Title</Label>
          <InputSwitcher
            id="site-title"
            v-model="siteTitle"
            maxlength="30"
            :items="siteTitles"
          />
        </FormGroup>

        <FormGroup>
          <Label for="site-description">Site Intro</Label>
          <InputSwitcher
            id="site-description"
            v-model="siteDescription"
            maxlength="140"
            :items="siteDescriptions"
          />
        </FormGroup>

        <FormGroup>
          <Label component="span" block gutter-bottom>Background Color</Label>
          <ColorPicker v-model="backgroundColor">
            <Color
              v-for="color in backgroundColors"
              :key="color"
              :color="color"
              selectable
            />
          </ColorPicker>
        </FormGroup>

        <FormGroup>
          <Label component="span" block gutter-bottom>Text Color</Label>
          <ColorPicker v-model="textColor">
            <Color
              v-for="color in textColors"
              :key="color"
              :color="color"
              selectable
            />
          </ColorPicker>
        </FormGroup>

        <FormGroup>
          <Label component="span" block gutter-bottom>Font Family</Label>
          <FontPreviewGrid v-model="fontFamily">
            <FontPreview
              v-for="font in allFonts"
              :key="font._id.$oid"
              :value="font.family"
              :weight="font.variants"
              :is-google-font="font.is_google_font"
              selectable
              load-all-weights
            >
              Make My Bio Link wizards just love to flex quick design help.
            </FontPreview>
          </FontPreviewGrid>
          <FormGroupHelp
            >Font families are sometimes our best guess and not an exact
            match.</FormGroupHelp
          >
        </FormGroup>

        <Button style="margin-top: 1.5rem" @click="nextStep(1)">Next</Button>
      </AccordionSection>

      <AccordionSection
        id="step2"
        v-model="configStatus.step2.isOpen"
        title="Buttons"
        subtitle="Get your button styles setup"
        :variant="configStatus.step2.status"
      >
        <FormGroup>
          <Label for="button-style0">Button Styles</Label>
          <BoxInputGroup>
            <ThemeProvider
              v-for="(button, i) in designData.button"
              :key="i"
              :theme="buttonThemes[i]"
              inject-locally
            >
              <BoxInput
                v-model="buttonStyle"
                :input-id="`button-style${i}`"
                is-checkered
                :input-value="i"
                :label="`Style ${i + 1}`"
                is-label-boxed
                is-wide
                label-position="bottom-right"
              >
                <BoxInputSiteButton :link="exampleLink" />
              </BoxInput>
            </ThemeProvider>
          </BoxInputGroup>
        </FormGroup>

        <FormGroup>
          <Label component="span" block gutter-bottom>Font Family</Label>
          <FontPreviewGrid v-model="buttonFontFamily">
            <FontPreview
              v-for="font in allFonts"
              :key="font._id.$oid"
              :value="font.family"
              :weight="font.variants"
              :is-google-font="font.is_google_font"
              selectable
              load-all-weights
            >
              Make My Bio Link wizards just love to flex quick design help.
            </FontPreview>
          </FontPreviewGrid>
        </FormGroup>

        <FormGroup>
          <Label
            :for="`site_button_font_weight${
              Object.keys(currentButtonFontWeights)[0]
            }`"
            block
            gutter-bottom
            >Font Weight</Label
          >
          <BoxInputGroup :style="{ fontFamily: buttonFontFamily }">
            <BoxInput
              v-for="(name, weight) in currentButtonFontWeights"
              :key="weight"
              v-model="buttonFontWeight"
              :input-id="`site_button_font_weight${weight}`"
              name="site_button_font_weight"
              :input-value="weight"
              :label="name"
            >
              <BoxInputFont :weight="weight" />
            </BoxInput>
          </BoxInputGroup>
        </FormGroup>

        <Button style="margin-top: 1.5rem" @click="nextStep(2)">Next</Button>
      </AccordionSection>

      <LinksSection
        id="step3"
        v-model="selectedLinks"
        :links="links"
        :is-open="configStatus.step3.isOpen"
        :variant="configStatus.step3.status"
        :feed="feed"
        :is-free-plan="isFreePlan"
        @links="links = $event"
        @update:open="configStatus.step3.isOpen = $event"
        @update:feed="isFeedActivated = $event"
        @next="nextStep(3)"
      />

      <AccordionSection
        id="step4"
        v-model="configStatus.step4.isOpen"
        title="Social links"
        subtitle="Here are some social accounts we found"
        :variant="configStatus.step4.status"
      >
        <FormGroup>
          <Label component="span" block gutter-bottom>Color</Label>
          <ColorPicker v-model="socialAccountColor">
            <Color
              v-for="color in socialAccountColors"
              :key="color"
              :color="color"
              selectable
            />
          </ColorPicker>
        </FormGroup>

        <List>
          <ListItemGroup
            v-model="selectedSocialAccounts"
            multiple
            :multiple-limit="6"
            aria-label="Select your social accounts"
          >
            <ListItem
              v-for="({ type, url }, i) in socialAccounts"
              :key="i"
              :value="i"
            >
              <template #default="{ active }">
                <ListItemAction>
                  <Checkbox :input-value="active" />
                </ListItemAction>
                <ListItemContent>
                  <ListItemTitle>{{ startCase(type) }}</ListItemTitle>
                  <ListItemSubtitle>{{ url }}</ListItemSubtitle>
                </ListItemContent>
              </template>
            </ListItem>
          </ListItemGroup>
        </List>

        <Button style="margin-top: 1.5rem" @click="nextStep(4)">Next</Button>
      </AccordionSection>

      <AccordionSection
        id="step5"
        v-model="configStatus.step5.isOpen"
        subtitle="Improve your profile SEO"
        :variant="configStatus.step5.status"
      >
        <VText slot="title" variant="h3"
          >Meta Tags <Badge content="Pro"
        /></VText>
        <Alert v-if="isFreePlan" style="margin-bottom: 0.75rem">
          <LightningBoltIcon slot="icon" />
          <VText color="inherit"
            >Upgrade to the Pro plan to get access to this feature on your
            profile</VText
          >
        </Alert>

        <FormGroup>
          <Label for="site-title">Meta Title</Label>
          <InputSwitcher
            id="site-title"
            v-model="metaTitle"
            maxlength="80"
            :items="metaTitles"
          />
        </FormGroup>

        <FormGroup>
          <Label for="site-description">Meta Description</Label>
          <InputSwitcher
            id="site-description"
            v-model="metaDescription"
            maxlength="300"
            :items="metaDescriptions"
          />
        </FormGroup>

        <FormGroup>
          <Label component="span" block gutter-bottom>Favicon</Label>
          <img
            v-if="favicon"
            :src="favicon"
            alt="Site favicon"
            style="max-width: 75px"
          />
          <VText v-else>We couldn't find a favicon on your site.</VText>
        </FormGroup>

        <FormGroup>
          <Label component="span" block gutter-bottom>Open Graph Image</Label>
          <img
            v-if="metaImage"
            :src="metaImage"
            alt="Share image"
            style="max-width: 300px"
          />
          <VText v-else
            >We couldn't find an open graph image on your site.</VText
          >
        </FormGroup>

        <FormGroup>
          <Label component="span" block gutter-bottom
            >Facebook Share Preview</Label
          >
          <MetaPreview
            :title="metaTitle"
            :description="metaDescription"
            :image="metaImage"
          />
        </FormGroup>

        <Button style="margin-top: 1.5rem" @click="nextStep(5)">Next</Button>
      </AccordionSection>
    </template>
  </div>
</template>

<script>
import {
  computed,
  nextTick,
  onMounted,
  ref,
  toRefs,
  watch,
  watchEffect,
} from 'vue';
import {
  FormGroup,
  FormGroupHelp,
  Label,
  Color,
  ColorPicker,
  Skeleton,
  VText,
  FontPreview,
  FontPreviewGrid,
  FONT_WEIGHTS,
  List,
  ListItemGroup,
  ListItem,
  ListItemTitle,
  ListItemAction,
  ListItemContent,
  ListItemSubtitle,
  Checkbox,
  ThemeProvider,
  Avatar,
  Alert,
  LightningBoltIcon,
  AccordionSection,
  Button,
  Duration,
  Badge,
  createThemeFromSettings,
} from '@campsite-bio/component-lib';
import tinycolor from 'tinycolor2';
import startCase from 'lodash/startCase';
import truncate from 'lodash/truncate';

import { LinksSection, InputSwitcher } from '../components';
import {
  BoxInputGroup,
  BoxInput,
  BoxInputSiteButton,
  BoxInputFont,
} from '../../box-input';
import MetaPreview from '../../meta-preview';
import { localAxios } from '../../../apis';
import config from '../../../config';
import {
  getButtonBorderRadiusFromNumber,
  getButtonBorderWidthFromNumber,
  getSocialAccountType,
  timeout,
} from '../../../utils';
import { useModel } from '../../../compositions';
import toast from '../../../utils/toast';
import {
  getUniqueColorPalette,
  getSocialAccountInitialColor,
  getInitialButtonStyle,
} from '../utils';
import { FEED_LINK } from '../links';

export default {
  components: {
    FormGroup,
    FormGroupHelp,
    Label,
    Color,
    ColorPicker,
    Skeleton,
    VText,
    ThemeProvider,
    List,
    ListItemGroup,
    ListItem,
    ListItemTitle,
    ListItemAction,
    ListItemContent,
    ListItemSubtitle,
    Checkbox,
    BoxInputGroup,
    BoxInput,
    BoxInputSiteButton,
    BoxInputFont,
    MetaPreview,
    FontPreview,
    FontPreviewGrid,
    LinksSection,
    InputSwitcher,
    Avatar,
    Alert,
    LightningBoltIcon,
    AccordionSection,
    Button,
    Badge,
  },

  props: {
    campsiteId: {
      type: String,
      required: true,
    },

    designId: {
      type: String,
      required: true,
    },

    site: {
      type: String,
      required: true,
    },

    stepColor: {
      type: String,
      required: true,
    },

    value: {
      type: Object,
      default: () => ({}),
    },

    isFreePlan: Boolean,
    completeAllSteps: Boolean,
  },

  setup(props, { emit }) {
    const { campsiteId, designId, site, value, isFreePlan } = toRefs(props);
    const root = ref(null);
    const designData = ref(null);
    const profileData = ref(null);
    const isLoading = ref(false);
    const configStatus = ref({
      step1: {
        isOpen: false,
        status: 'incomplete',
      },
      step2: {
        isOpen: false,
        status: 'incomplete',
      },
      step3: {
        isOpen: false,
        status: 'incomplete',
      },
      step4: {
        isOpen: false,
        status: 'incomplete',
      },
      step5: {
        isOpen: false,
        status: 'incomplete',
      },
    });
    const showAllLinks = ref(false);
    const allFonts = ref([]);
    const links = ref([]);
    const exampleLink = ref({
      label: 'Check out our store',
      url: location.url,
      image: `${config.ASSET_URL}images/button-image-size-style-example.jpg`,
    });
    const configurationState = useModel(value, (val) => emit('input', val));

    const profileImage = ref(null);
    const siteTitle = ref(null);
    const siteDescription = ref(null);
    const backgroundColor = ref(null);
    const textColor = ref(null);
    const fontFamily = ref(null);

    const metaTitle = ref(null);
    const metaDescription = ref(null);
    const favicon = ref(null);
    const metaImage = ref(null);

    const buttonStyle = ref(0);
    const buttonFontFamily = ref(null);
    const buttonFontWeight = ref(null);

    const isFeedActivated = ref(true);
    const selectedLinks = ref([]);
    const selectedSocialAccounts = ref([]);

    const socialAccountColor = ref(null);

    function formatInputSwitchOptions(items, length) {
      return items.map((t) =>
        truncate(t, { length: length, separator: /,? +/ }),
      );
    }

    const hasCompletedAllSteps = computed(() => {
      return Object.values(configStatus.value).every(
        (step) => step.status === 'success',
      );
    });

    const siteTitles = computed(() => {
      if (!designData.value) return [];

      return formatInputSwitchOptions(
        [
          designData.value.title,
          ...designData.value.alt_titles.filter((t) => t),
        ],
        30,
      );
    });

    const metaTitles = computed(() => {
      if (!designData.value) return [];

      return formatInputSwitchOptions(
        [
          designData.value.title,
          ...designData.value.alt_titles.filter((t) => t),
        ],
        80,
      );
    });

    const siteDescriptions = computed(() => {
      if (!designData.value) return [];

      return formatInputSwitchOptions(
        [
          designData.value.description,
          ...designData.value.alt_descriptions.filter((t) => t),
        ],
        140,
      );
    });

    const metaDescriptions = computed(() => {
      if (!designData.value) return [];

      return formatInputSwitchOptions(
        [
          designData.value.description,
          ...designData.value.alt_descriptions.filter((t) => t),
        ],
        300,
      );
    });

    const colorPalette = computed(() => {
      if (!designData.value) return [];

      // Filter out any colors that have an alpha of 0
      return designData.value.color_palette.filter((c) => {
        const color = tinycolor(c);

        return color.isValid() && color.getAlpha() !== 0;
      });
    });

    const textColors = computed(() => {
      if (!designData.value) return [];

      return getUniqueColorPalette(
        designData.value.colors.p_font_color,
        colorPalette.value,
      );
    });

    const backgroundColors = computed(() => {
      if (!designData.value) return [];

      return getUniqueColorPalette(
        designData.value.body.background,
        colorPalette.value,
      );
    });

    const socialAccountColors = computed(() => {
      if (!designData.value) return [];

      return getUniqueColorPalette(
        designData.value.brand_color.color,
        colorPalette.value,
      );
    });

    const buttonThemes = computed(() => {
      if (!designData.value) return [];

      return designData.value.button.map((button) => {
        return createThemeFromSettings({
          ...profileData.value,
          site_theme: 'custom',
          theme: null,
          ...getThemeSettingsFromButtonStyle(button),
        });
      });
    });

    const fontFamilies = computed(() => {
      if (!designData.value) return [];

      let families = [];

      if (designData.value.fonts.p_font_family.alternative) {
        families.push(designData.value.fonts.p_font_family.match);
      } else {
        families.push(designData.value.fonts.p_font_family.family);
        families.push(designData.value.fonts.p_font_family.match);
      }

      if (designData.value.fonts.h_font_family.alternative) {
        families.push(designData.value.fonts.h_font_family.match);
      } else {
        families.push(designData.value.fonts.h_font_family.family);
        families.push(designData.value.fonts.h_font_family.match);
      }

      families = families.concat(
        designData.value.button.reduce((acc, b) => {
          const family = b['font-family'];
          if (family.alternative) {
            acc.push(family.match);
          } else {
            acc.push(family.family);
            acc.push(family.match);
          }
          return acc;
        }, []),
      );

      return Array.from(new Set(families.filter((f) => f)));
    });

    function selectFontFamily(fontFamily) {
      const tempFamily = fontFamily.alternative
        ? fontFamily.match
        : fontFamily.family;
      if (allFonts.value.some((f) => f.family === tempFamily)) {
        return tempFamily;
      }

      return allFonts.value[0].family;
    }

    const currentlySelectedFont = computed(() => {
      return allFonts.value.find((font) => font.family === fontFamily.value);
    });

    const currentlySelectedButtonFont = computed(() => {
      let selected = allFonts.value.find(
        (font) => font.family === buttonFontFamily.value,
      );

      if (!selected) return allFonts.value[0];

      return selected;
    });

    function getFontWeights(variants) {
      return variants.reduce((acc, variant) => {
        let weight = +variant;
        if (variant === 'regular') weight = 400;
        if (!variant.includes('italic')) acc[weight] = FONT_WEIGHTS[weight];

        return acc;
      }, {});
    }

    const currentFontWeights = computed(() => {
      if (!designData.value) return [];

      return getFontWeights(currentlySelectedFont.value.variants);
    });

    const currentButtonFontWeights = computed(() => {
      if (!designData.value) return [];

      return getFontWeights(
        currentlySelectedButtonFont.value
          ? currentlySelectedButtonFont.value.variants
          : [],
      );
    });

    function getThemeSettingsFromButtonStyle(buttonStyles) {
      const buttonBgColor = tinycolor(buttonStyles.background);
      const buttonStyle = buttonBgColor.getAlpha() === 0 ? 'hollow' : 'filled';

      const borderWidth = getButtonBorderWidthFromNumber(
        parseInt(buttonStyles['border-width'], 10),
        buttonStyle,
      );
      const borderRadius = getButtonBorderRadiusFromNumber(
        parseInt(buttonStyles['border-radius'], 10),
      );

      return {
        site_button_style: buttonStyle,
        site_button_color: buttonStyles.color,
        site_button_bg_color: buttonStyles.background,
        site_button_border_color: buttonStyles['border-color'],
        site_button_border_width: borderWidth,
        site_button_corner_radius: borderRadius,
      };
    }
    function getThemeSettingsFromSocialStyle(color) {
      return {
        site_social_color: color,
      };
    }
    const themeSettings = computed(() => {
      if (!designData.value) return {};

      const selectedButtonStyle =
        designData.value.button[buttonStyle.value] || {};

      return {
        site_bg_style: 'flat',
        site_bg_color: backgroundColor.value,
        site_text_color: textColor.value,
        site_social_position: 'top',
        site_font_family: fontFamily.value,
        site_font_family_details: currentlySelectedFont.value,
        site_button_font_family: buttonFontFamily.value,
        site_button_font_family_details: currentlySelectedButtonFont.value,
        site_button_font_weight: buttonFontWeight.value,
        ...getThemeSettingsFromButtonStyle(selectedButtonStyle),
        ...getThemeSettingsFromSocialStyle(socialAccountColor.value),
      };
    });
    const theme = computed(() => {
      if (!designData.value) return 'campsite';

      return createThemeFromSettings({
        ...profileData.value,
        site_theme: 'custom',
        theme: null,

        ...themeSettings.value,
      });
    });
    watch(
      theme,
      (newTheme) => {
        emit('theme', newTheme);
      },
      {
        deep: true,
      },
    );

    function formatLinks() {
      links.value = designData.value.links
        // Take a max of 20 links
        .slice(0, 20)
        .map(({ label, link, alt_labels, ...rest }, i) => {
          const labels = [label, ...alt_labels].map((l) =>
            truncate(l, { length: 80 }),
          );
          return {
            label: labels[0],
            alt_labels: labels,
            url: link,
            enabled: selectedLinks.value.includes(i),
            _id: { $oid: i },
            ...rest,
          };
        });
    }

    const feed = computed(() => {
      if (!designData.value) return null;

      return designData.value.feed;
    });

    // Load the design
    async function load() {
      isLoading.value = true;

      try {
        const { data } = await localAxios.get(`/api/design/${designId.value}`);
        const { data: campsiteData } = await localAxios.get(
          `/api/profiles/${campsiteId.value}`,
        );
        designData.value = data;
        const { data: googleFonts } = await localAxios.get(
          `/api/assets/fonts/${fontFamilies.value
            .map((f) => encodeURIComponent(f))
            .join('|')}`,
        );
        profileData.value = campsiteData.profile;
        allFonts.value = googleFonts;

        profileImage.value = data.profile_image;
        backgroundColor.value = backgroundColors.value[0];
        textColor.value = tinycolor
          .mostReadable(backgroundColor.value, textColors.value)
          .toRgbString();
        siteTitle.value = siteTitles.value[0];
        siteDescription.value = siteDescriptions.value[0];
        fontFamily.value = selectFontFamily(data.fonts.p_font_family);
        metaTitle.value = metaTitles.value[0];
        metaDescription.value = metaDescriptions.value[0];
        favicon.value = data.icon;
        metaImage.value = data.image;

        if (data.button.length === 0) {
          data.button.push({
            color: '#fff',
            background: '#000',
            'border-radius': '0px',
            'border-width': '0px',
            'border-color': '#000',
            'font-family': {
              ...fontFamily.value,
            },
            'font-weight': '700',
          });
        }

        buttonFontFamily.value = selectFontFamily(
          data.button[0]['font-family'],
        );
        buttonFontWeight.value = data.button[0]['font-weight'];
        buttonStyle.value = getInitialButtonStyle(
          backgroundColor.value,
          data.button,
        );

        // Take the first 10 links and turn into an sequential array of numbers
        selectedLinks.value = data.links.slice(0, 10).map((l, i) => i);
        formatLinks();
        selectedSocialAccounts.value = socialAccounts.value.map((l, i) => i);

        socialAccountColor.value = getSocialAccountInitialColor(
          backgroundColor.value,
          designData.value.brand_color.color,
          socialAccountColors.value,
          colorPalette.value,
        );

        emit('loaded');

        isLoading.value = false;

        await nextTick();

        if (props.completeAllSteps) {
          completeAllSteps();
        } else {
          configStatus.value.step1.isOpen = true;
        }
      } catch (e) {
        console.error(e);
        const { error_message } = e.response?.data || {};
        toast.error(
          error_message ||
            'There was an error trying to get your design. Please try again soon.',
          { timeout: 5000 },
        );
      }

      isLoading.value = false;
    }

    async function nextStep(step) {
      configStatus.value[`step${step}`].isOpen = false;
      configStatus.value[`step${step}`].status = 'success';

      await nextTick();
      await timeout(Duration.STANDARD);

      // Get next uncompleted step
      if (hasCompletedAllSteps.value) {
        emit('done');

        root.value
          .querySelector(`#step5`)
          .scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        const nextStep =
          Object.keys(configStatus.value).findIndex(
            (key) => configStatus.value[key].status === 'incomplete',
          ) + 1;
        configStatus.value[`step${nextStep}`].isOpen = true;

        root.value
          .querySelector(`#step${nextStep}`)
          .scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }

    const profile = computed(() => {
      if (!designData.value) return {};

      return {
        ...profileData.value,
        site_intro_title: siteTitle.value,
        site_intro_text: siteDescription.value,
        site_social_position: 'top',
        custom_meta_title: metaTitle.value,
        custom_meta_description: metaDescription.value,
        profile_image: profileImage.value,
        // Theme data
        ...themeSettings.value,
      };
    });
    const socialAccounts = computed(() => {
      if (!designData.value) return [];

      return designData.value.social_links
        .filter((url) => getSocialAccountType(url))
        .map((url, i) => {
          return {
            type: getSocialAccountType(url),
            url,
            _id: { $oid: i },
          };
        });
    });
    const enabledSocialAccounts = computed(() => {
      if (!designData.value) return [];

      return socialAccounts.value.filter((account) =>
        selectedSocialAccounts.value.includes(account._id.$oid),
      );
    });

    function completeAllSteps() {
      Object.keys(configStatus.value).forEach((key) => {
        configStatus.value[key].isOpen = false;
        configStatus.value[key].status = 'success';
      });
    }

    onMounted(() => load());
    // Keep track of the current configuration
    watchEffect(() => {
      const linksToShow = [
        ...(!isFreePlan.value && isFeedActivated.value && feed.value
          ? [FEED_LINK]
          : []),
        ...links.value.filter((l) => l.enabled),
      ];

      configurationState.value = {
        profile: profile.value,
        profileImage: profileImage.value,
        links: linksToShow,
        socialAccounts: enabledSocialAccounts.value,
        metaFavicon: favicon.value,
        metaImage: metaImage.value,
        isFeedActivated: isFeedActivated.value && feed.value,
        isComplete: hasCompletedAllSteps.value,
      };
    });

    return {
      root,
      isLoading,
      configStatus,
      designData,
      showAllLinks,
      profileImage,
      siteTitles,
      metaTitles,
      siteDescriptions,
      metaDescriptions,
      backgroundColor,
      backgroundColors,
      textColor,
      textColors,
      siteDescription,
      siteTitle,
      fontFamily,
      metaTitle,
      metaDescription,
      favicon,
      metaImage,
      buttonStyle,
      exampleLink,
      profile,
      links,
      socialAccounts,
      theme,
      buttonThemes,
      allFonts,
      FONT_WEIGHTS,
      currentFontWeights,
      buttonFontFamily,
      buttonFontWeight,
      currentButtonFontWeights,
      selectedLinks,
      selectedSocialAccounts,
      enabledSocialAccounts,
      startCase,
      socialAccountColor,
      socialAccountColors,
      nextStep,
      feed,
      isFeedActivated,
      completeAllSteps,
    };
  },
};
</script>

<style lang="scss" scoped>
.configuration {
  &::v-deep {
    .v-accordion {
      scroll-margin: 60px;
    }
  }
}
</style>
