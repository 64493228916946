<template>
  <div>
    <v-dialog v-model="showUploadDialog" max-width="500">
      <Card>
        <CardTitle
          ><VText component="h2" variant="h4">{{ title }}</VText></CardTitle
        >
        <Divider color="gray200" />
        <CardText>
          <form ref="dropzone" class="dropzone dropzone--custom" action="/">
            <!-- Not displayed, just for Dropzone's `dictDefaultMessage` option -->
            <div ref="dropzoneMessage" style="display: none">
              <span
                class="dropzone-icon"
                style="display: block; line-height: 1"
              >
                <ImageIcon />
              </span>
              <span class="dropzone-title"
                >Drop file here or click to select</span
              >
            </div>
          </form>
        </CardText>
        <CardActions>
          <Button color="error" variant="flat" @click="showUploadDialog = false"
            >Cancel</Button
          >
          <Button
            color="success"
            variant="flat"
            :disabled="validFiles.length === 0"
            :loading="isUploading"
            @click="upload"
            >Save and Upload</Button
          >
        </CardActions>
      </Card>
    </v-dialog>

    <v-dialog v-model="isCropping" max-width="1000">
      <Card>
        <CardTitle
          ><VText component="h2" variant="h4">Crop Image</VText></CardTitle
        >
        <Divider color="lightgray" />
        <CardText>
          <div :style="{ maxHeight: '70vh' }">
            <img ref="cropImage" src="" alt="Crop image" crossorigin />
          </div>
        </CardText>
        <Divider color="lightgray" />
        <CardActions>
          <Button variant="flat" @click="isCropping = false">Cancel</Button>
          <Button color="success" variant="flat" @click="saveCrop"
            >Save and Crop</Button
          >
        </CardActions>
      </Card>
    </v-dialog>
  </div>
</template>

<script>
import {
  Card,
  CardTitle,
  CardText,
  CardActions,
  Button,
  VText,
  ImageIcon,
  Divider,
} from '@campsite-bio/component-lib';
import DropzoneMixin from '../mixins/dropzone';
import CropperMixin from '../mixins/cropper';
import { getAppSetting, uuid } from '../utils/';
import { dataURItoBlob } from '../utils/files';
import toast from '../utils/toast';

export default {
  components: {
    Card,
    CardTitle,
    CardText,
    CardActions,
    Button,
    ImageIcon,
    Divider,
    VText,
  },

  mixins: [DropzoneMixin, CropperMixin],

  props: {
    title: {
      type: String,
      default: null,
    },
    value: Boolean,
    canUpload: {
      type: Boolean,
      default: true,
    },
    baseFilePath: {
      required: true,
      type: String,
    },
    minWidth: {
      type: Number,
      default: 300,
    },
    minHeight: {
      type: Number,
      default: 300,
    },
  },

  data() {
    return {
      showUploadDialog: false,
      isUploading: false,
      isCropping: false,
      validFiles: [],
    };
  },

  watch: {
    value(newValue, oldValue) {
      this.$emit('input', newValue);
      // load dropzone on open
      if (!this.hasDropzoneLoaded) {
        this.loadDropzone();
        this.loadCropper();
      } else {
        this.$nextTick().then(() => {
          if (newValue && this.hasDropzoneLoaded) {
            this.createDZ();
          } else if (this.dropzone) {
            this.dropzone.destroy();
          }
        });
      }
      this.showUploadDialog = newValue;
    },

    hasDropzoneLoaded() {
      if (this.value) {
        this.createDZ();
      }
    },

    showUploadDialog(newValue) {
      this.$emit('input', newValue);
    },

    isCropping(newValue) {
      if (!newValue) {
        this.$nextTick().then(() => {
          if (this.cropper) this.cropper.destroy();
        });
      }
    },
  },

  create() {
    this.showUploadDialog = this.value;
  },

  mounted() {},

  beforeDestroy() {
    if (this.cropper) this.cropper.destroy();
  },

  methods: {
    startCropping(imageUrl) {
      if (!this.hasDropzoneLoaded) {
        this.loadDropzone();
        this.loadCropper().then(() => {
          this.startCroppingInit(imageUrl);
        });
      } else this.startCroppingInit(imageUrl);
    },

    startCroppingInit(imageUrl) {
      this.isCropping = true;
      this.$nextTick(() => {
        this.$refs.cropImage.setAttribute('src', imageUrl);
        this.createCropper();
      });
    },

    createDZ() {
      const vm = this;
      this.createDropzone(this.$refs.dropzone, {
        dictDefaultMessage: this.$refs.dropzoneMessage.innerHTML,
      });

      // Set signed upload URL for each file
      this.dropzone.on('processing', (file) => {
        this.dropzone.options.url = file.uploadURL;
      });

      this.dropzone.on('addedfile', (file) => {
        setTimeout(() => (this.validFiles = this.dropzone.getAcceptedFiles()));
      });
      this.dropzone.on('removedfile', (file) => {
        this.validFiles = this.dropzone.getAcceptedFiles();
      });

      this.dropzone.on('thumbnail', (file) => {
        // ignore files which were already cropped and re-rendered
        // to prevent infinite loop
        if (file.cropped) {
          return;
        }
        if (file.width < this.minWidth || file.height < this.minHeight) {
          // validate width to prevent too small files to be uploaded
          toast.error(
            `Image must be AT LEAST ${this.minWidth}x${this.minHeight} pixels`,
            { timeout: 3000 },
          );
          return;
        }
        if (!file.accepted) {
          return;
        }
        // cache filename to re-assign it to cropped file
        this.cachedFilename = file.name;
        this.cachedFiletype = file.type;

        // initialize FileReader which reads uploaded file
        var reader = new FileReader();
        reader.onloadend = () => {
          this.$refs.cropImage.setAttribute('src', reader.result);

          // initialize cropper for uploaded image
          this.createCropper();
        };
        // read uploaded file (triggers code above)
        reader.readAsDataURL(file);

        this.isCropping = true;

        // remove not cropped file from dropzone (we will replace it later)
        this.dropzone.removeFile(file);
      });
    },

    createCropper() {
      this.hasCropperLoadedPromise().then(() => {
        // initialize cropper for uploaded image
        this.cropper = new this.Cropper(this.$refs.cropImage, {
          aspectRatio: 1,
          viewMode: 1,
          autoCropArea: 1,
          cropmove: (e) => {
            const data = this.cropper.getData();

            if (data.width < this.minWidth) {
              e.preventDefault();
              data.width = this.minWidth;
              this.cropper.setData(data);
            }

            if (data.height < this.minHeight) {
              e.preventDefault();
              data.height = this.minHeight;
              this.cropper.setData(data);
            }
          },
        });
      });
    },

    saveCrop() {
      // get cropped image data
      const blob = this.cropper
        .getCroppedCanvas({
          // Limit max sizes
          maxWidth: 2000,
          maxHeight: 2000,
        })
        .toDataURL(this.cachedFiletype);
      // transform it to Blob object
      const newFile = dataURItoBlob(blob, this.cachedFiletype);
      // set 'cropped to true' (so that we don't get to that listener again)
      newFile.cropped = true;
      // assign original filename
      newFile.name = this.cachedFilename;

      // add cropped file to dropzone
      this.dropzone.addFile(newFile);
      // upload cropped file with dropzone
      // this.dropzone.processQueue();
      this.isCropping = false;
    },

    async upload() {
      this.isUploading = true;
      let file = this.dropzone.getAcceptedFiles()[0],
        fileNameParts = file.name.split('.');

      try {
        await this.getSignedUrl(
          file,
          `${this.baseFilePath}${uuid()}.${
            fileNameParts[fileNameParts.length - 1]
          }`,
        );

        this.dropzone.on('success', (file) => {
          this.$emit('uploaded', `${getAppSetting('assetCdn')}/${file.path}`);
          this.showUploadDialog = false;
          this.isUploading = false;
        });
        this.dropzone.on('error', (file) => {
          this.isUploading = false;
        });
        // Manually process each file
        setTimeout(() => this.dropzone.processFile(file));
      } catch (e) {
        this.isUploading = false;
        toast.error(
          'There was an error trying to upload this image. Please try again soon.',
        );
      }
    },
  },
};
</script>

<style lang="scss">
.dropzone {
  &.dropzone--custom {
    border: 0.125rem dashed var(--g-color-gray-300);
    border-radius: var(--g-border-radius-standard);
    border-radius: min(
      var(--g-border-radius-standard),
      var(--g-border-radius-min)
    );
  }

  &.dz-clickable {
    &:hover {
      border-color: var(--g-color-primary);
    }
  }
}

.dropzone-icon {
  color: var(--g-color-primary);
  font-size: 5rem;
}

.dropzone-title {
  display: block;
  margin-top: 0.625rem;
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  opacity: 0;
}

.dropzone .dz-preview .dz-error-message {
  line-height: 1.3;
}
</style>
