<template>
  <div class="quickview">
    <div class="quickview__inner">
      <div class="quickview__title-wrapper">
        <span>
          <VText class="quickview__title" component="h2" variant="h6">
            <slot name="title" />
          </VText>
          <VText class="quickview__subtitle">{{ subtitle }}</VText>
        </span>
      </div>

      <div class="quickview__stats">
        <slot />
      </div>

      <FlexSpacer />
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import { VText, FlexSpacer } from '@campsite-bio/component-lib';

export default {
  components: {
    VText,
    FlexSpacer,
  },

  props: {
    subtitle: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.quickview {
  background: #fff;
  overflow: hidden;

  @media (min-width: 544px) {
    overflow: unset;
  }

  &__inner {
    display: flex;
    padding: 0.5rem 1rem;
    margin: 0 auto;
    max-width: 800px;

    @media (min-width: 768px) {
      align-items: center;
      padding: 0.925rem;
    }
  }

  &__title-wrapper {
    align-items: center;
    display: flex;
    padding-bottom: 0.5rem;

    @media (min-width: 768px) {
      padding-bottom: 0;
    }
  }

  &__stats {
    display: flex;
  }

  &::v-deep {
    .v-badge {
      font-size: 50%;

      @media (min-width: 544px) {
        font-size: 75%;
      }
    }
  }
}

.quickview__title.quickview__title {
  align-items: center;
  display: flex;
  font-size: 0.75rem;
  margin-right: 1rem;

  @media (min-width: 768px) {
    font-size: 1rem;
    margin-right: 1.5rem;
  }
}

.quickview__subtitle.quickview__subtitle {
  font-size: 0.65rem;

  @media (min-width: 768px) {
    font-size: 0.75rem;
  }
}
</style>
