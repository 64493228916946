import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';
import pace from '../components/pace';
import Profile from '../pages/profile';
import Home from '../pages/home';
import Login from '../pages/login';
import CreateAccount from '../pages/create-account';
import { intercomUpdate } from '../utils/intercom';
import {
  CAN_MANAGE_SETTINGS,
  CAN_MANAGE_URLS,
  CAN_MANAGE_APPEARANCE,
  CAN_MANAGE_LINKS,
  CAN_MANAGE_ANALYTICS,
  CAN_MANAGE_USERS,
  IS_PROFILE_OWNER,
} from '../config/user-access';
import { userLog, USER_ACTIVITY_TYPES } from '../utils';
import { ORG_ROLE_MEMBER, ORG_ROLE_OWNER } from '@/config';

// dynamically import pages
const CreateAccountDefault = () => import('../pages/create-account/default');
const CreateAccountInformation = () =>
  import('../pages/create-account/information');
const CreateAccountConfirmEmail = () =>
  import('../pages/create-account/confirm-email');
const CreateAccountSelectPlan = () =>
  import('../pages/create-account/select-plan');
const CreateAccountProfileSetup = () =>
  import('../pages/create-account/profile-setup');

const Profiles = () => import('../pages/profile/profiles');
const ConfigLinks = () => import('../pages/profile/config-links');
const NewProfile = () => import('../pages/profile/new');
const Settings = () => import('../pages/profile/settings');
const ArchivedLinks = () => import('../pages/profile/archived-links');
const SettingsDefault = () => import('../pages/profile/settings/settings');
const SettingsAppearance = () => import('../pages/profile/settings/appearance');
const SettingsUsers = () => import('../pages/profile/settings/manage-users');
const SettingsDomain = () => import('../pages/profile/settings/domain');
const SettingsMakeMyBioLink = () =>
  import('../pages/profile/settings/make-my-bio-link');
const QrCode = () => import('../pages/profile/qr-code');
const Analytics = () => import('../pages/profile/analytics');
const AnalyticsDefault = () => import('../pages/profile/analytics/default');

const Reports = () => import('../pages/reports');
const ReportOverview = () => import('../pages/reports/overview');
const AnalyticsReportsGoals = () => import('../pages/reports/goals');
const AnalyticsReportsChannel = () => import('../pages/reports/channel');
const AnalyticsReportsTracking = () => import('../pages/reports/tracking');
const AnalyticsReportsEvents = () => import('../pages/reports/events');
const AnalyticsReportsInsights = () => import('../pages/reports/insights');
const AnalyticsReportsLeaderboard = () =>
  import('../pages/reports/leaderboard');
const AnalyticsReportsWrapped = () => import('../pages/reports/wrapped');
const AnalyticsSettings = () => import('../pages/reports/settings');

const Account = () => import('../pages/account');
const AccountOverview = () => import('../pages/account/account');
const Referrals = () => import('../pages/account/referrals');
const AccountDelete = () => import('../pages/account/account-delete');
const AccountOrganizations = () => import('../pages/account/organizations');
const Billing = () => import('../pages/account/billing');
const ChangePlan = () => import('../pages/account/change-plan');
const DowngradePlan = () => import('../pages/plan/downgrade');
const DowngradePlanCancel = () => import('../pages/plan/downgrade/cancel');
const DowngradePlanCancelConfirm = () =>
  import('../pages/plan/downgrade/cancel-confirm');

const Organization = () => import('../pages/organization');
const OrganizationIndex = () => import('../pages/organization/profiles');
const OrganizationBilling = () => import('../pages/organization/billing');
const OrganizationBillingIndex = () =>
  import('../pages/organization/billing/index');
const OrganizationChangePlan = () =>
  import('../pages/organization/billing/change-plan');
const OrganizationSettings = () => import('../pages/organization/settings');
const OrganizationPeople = () => import('../pages/organization/people');
const OrganizationLinks = () => import('../pages/organization/links');
const OrganizationLinksIndex = () =>
  import('../pages/organization/links/index');
const OrganizationLinksNew = () => import('../pages/organization/links/new');
const OrganizationLinksLinkGroup = () =>
  import('../pages/organization/links/link-set');
const OrganizationPeopleIndex = () =>
  import('../pages/organization/people/members/index');
const OrganizationPeopleMembers = () =>
  import('../pages/organization/people/members/members');
const OrganizationPeopleCollaborators = () =>
  import('../pages/organization/people/members/collaborators');
const OrganizationPeopleMember = () =>
  import('../pages/organization/people/member');
const OrganizationNew = () => import('../pages/organization/new');
const OrganizationNewConfirmEmail = () =>
  import('../pages/organization/new/confirm-email');
const OrganizationNewSelectPlan = () =>
  import('../pages/organization/new/select-plan');
const OrganizationDowngradePlan = () =>
  import('../pages/organization/billing/downgrade');
const OrganizationDowngradePlanCancel = () =>
  import('../pages/organization/billing/downgrade/cancel');
const OrganizationDowngradePlanCancelConfirm = () =>
  import('../pages/organization/billing/downgrade/cancel-confirm');

const ForgotPassword = () => import('../pages/forgot-password');
const ResetPassword = () => import('../pages/reset-password');
const Error404 = () => import('../pages/404');
const AuthCampsiteAdd = () => import('../pages/auth-campsite-add');
const DeletionRequest = () => import('../pages/deletion-request');
const Invite = () => import('../pages/invite');
const ProfileRedirect = () => import('../pages/profile-redirect');
const Maintenance = () => import('../pages/maintenance');
const Authorization = () => import('../pages/authorization');
const SalesChannelConnect = () => import('../pages/sales-channel-connect');
const EmailConfirmation = () => import('../pages/email-confirmation');

// Newsletter routes
const Unsubscribed = () => import('../pages/unsubscribed');
const Subscribed = () => import('../pages/subscribed');

Vue.use(Router);

const router = new Router({
  mode: 'history',

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (from.path === to.path) {
      return {};
    }

    return { x: 0, y: 0 };
  },

  routes: [
    {
      path: '/',
      component: Home,
      meta: {
        secure: true,
        noCampsites: true,
      },
    },
    {
      path: '/reports',
      component: Reports,
      meta: {
        secure: true,
      },
      children: [
        {
          path: 'tracking/:id?',
          component: AnalyticsReportsTracking,
          meta: {
            secure: true,
          },
        },
        {
          path: 'events/:id?',
          component: AnalyticsReportsEvents,
          meta: {
            secure: true,
          },
        },
        {
          path: 'insights/:id?',
          component: AnalyticsReportsInsights,
          meta: {
            secure: true,
          },
        },
        {
          path: 'leaderboard/:id?',
          component: AnalyticsReportsLeaderboard,
          meta: {
            secure: true,
          },
        },
        {
          path: 'channel/:id?',
          component: AnalyticsReportsChannel,
          meta: {
            secure: true,
          },
        },
        {
          path: 'wrapped',
          component: AnalyticsReportsWrapped,
          meta: {
            secure: true,
          },
        },
        {
          path: 'goals',
          component: AnalyticsReportsGoals,
          meta: {
            secure: true,
          },
        },
        {
          path: 'settings',
          component: AnalyticsSettings,
          meta: {
            secure: true,

            entitlements: [IS_PROFILE_OWNER],
          },
        },
        {
          path: ':id?',
          component: ReportOverview,
          meta: {
            secure: true,
          },
        },
      ],
    },
    {
      path: '/login',
      component: Login,
      meta: {
        secure: false,
        layout: 'simple-layout',
      },
    },
    {
      path: '/forgot-password',
      component: ForgotPassword,
      meta: {
        secure: false,
        layout: 'simple-layout',
      },
    },
    {
      path: '/reset-password/:id',
      component: ResetPassword,
      meta: {
        secure: false,
        layout: 'simple-layout',
      },
    },
    {
      path: '/authorization/:id?',
      component: Authorization,
      meta: {
        secure: false,
        standalone: true,
        layout: 'simple-layout',
      },
    },
    {
      path: '/oauth/connect/sales-channel',
      component: SalesChannelConnect,
      meta: {
        secure: true,
        standalone: true,
        layout: 'simple-layout',
      },
    },
    {
      path: '/confirm-email/:token',
      alias: '/confirm-email/org/:token',
      component: EmailConfirmation,
      meta: {
        secure: false,
        standalone: true,
        layout: 'simple-layout',
      },
    },
    {
      path: '/create-account',
      component: CreateAccount,
      meta: {
        secure: false,
        layout: 'simple-layout',
      },
      children: [
        {
          path: '',
          component: CreateAccountDefault,
          meta: {
            secure: false,
            layout: 'simple-layout',
          },
        },
        {
          path: 'confirm-email',
          component: CreateAccountConfirmEmail,
          meta: {
            secure: true,
            layout: 'simple-layout',
          },
        },
        {
          path: 'your-information',
          component: CreateAccountInformation,
          meta: {
            secure: true,
            layout: 'simple-layout',
          },
        },
        {
          path: 'select-plan',
          component: CreateAccountSelectPlan,
          meta: {
            secure: true,
            layout: 'simple-layout',
          },
        },
        {
          path: 'make-my-bio-link',
          component: CreateAccountProfileSetup,
          meta: {
            secure: true,
            layout: 'simple-layout',
          },
        },
      ],
    },
    {
      path: '/invite/:token',
      component: Invite,
      meta: {
        secure: false,
        standalone: true,
        layout: 'simple-layout',
      },
    },
    {
      path: '/profile/redirect',
      component: ProfileRedirect,
      meta: {
        secure: true,
        layout: 'simple-layout',
      },
    },
    {
      path: '/deletion',
      component: DeletionRequest,
      meta: {
        secure: false,
        standalone: true,
        layout: 'simple-layout',
      },
    },
    {
      path: '/auth-campsite-add/:campsiteToken/:requestingToken',
      component: AuthCampsiteAdd,
      meta: {
        secure: false,
        standalone: true,
        layout: 'simple-layout',
      },
    },

    {
      path: '/account',
      component: Account,
      meta: {
        secure: true,
        noCampsites: true,
      },
      children: [
        {
          path: '',
          component: AccountOverview,
          meta: {
            secure: true,
            noCampsites: true,
          },
        },
        {
          path: 'referrals',
          component: Referrals,
          meta: {
            secure: true,
            noCampsites: true,
          },
        },
        {
          path: 'organizations',
          component: AccountOrganizations,
          meta: {
            secure: true,
            noCampsites: true,
          },
        },
        {
          path: 'delete',
          component: AccountDelete,
          meta: {
            secure: true,
            noCampsites: true,
          },
        },
        {
          path: 'billing',
          component: Billing,
          meta: {
            secure: true,
            noCampsites: true,
          },
        },
        {
          path: 'change-plan',
          component: ChangePlan,
          meta: {
            secure: true,
            noCampsites: true,
          },
        },
        {
          path: 'change-plan/downgrade',
          component: DowngradePlan,
          meta: {
            secure: true,
            noCampsites: true,
          },
          children: [
            {
              path: '',
              component: DowngradePlanCancel,
              meta: {
                secure: true,
                noCampsites: true,
              },
            },
            {
              path: 'confirmation',
              component: DowngradePlanCancelConfirm,
              meta: {
                secure: true,
                noCampsites: true,
              },
            },
          ],
        },
      ],
    },

    {
      path: '/organization/new',
      component: OrganizationNew,
      meta: {
        secure: true,
        noCampsites: true,
      },
    },
    {
      path: '/organization/:id',
      component: Organization,
      meta: {
        secure: true,
        noCampsites: true,
      },
      children: [
        {
          path: '',
          component: OrganizationIndex,
          meta: {
            secure: true,
            noCampsites: true,
          },
        },
        {
          path: 'billing',
          component: OrganizationBilling,
          meta: {
            secure: true,
            entitlements: ORG_ROLE_OWNER,
            noCampsites: true,
          },
          children: [
            {
              path: '',
              component: OrganizationBillingIndex,
              meta: {
                secure: true,
                entitlements: ORG_ROLE_OWNER,
                noCampsites: true,
              },
            },
            {
              path: 'change-plan',
              component: OrganizationChangePlan,
              meta: {
                secure: true,
                entitlements: ORG_ROLE_OWNER,
                noCampsites: true,
              },
            },
            {
              path: 'change-plan/downgrade',
              component: OrganizationDowngradePlan,
              meta: {
                secure: true,
                entitlements: ORG_ROLE_OWNER,
                noCampsites: true,
              },
              children: [
                {
                  path: '',
                  component: OrganizationDowngradePlanCancel,
                  meta: {
                    secure: true,
                    entitlements: ORG_ROLE_OWNER,
                    noCampsites: true,
                  },
                },
                {
                  path: 'confirmation',
                  component: OrganizationDowngradePlanCancelConfirm,
                  meta: {
                    secure: true,
                    entitlements: ORG_ROLE_OWNER,
                    noCampsites: true,
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'settings',
          component: OrganizationSettings,
          meta: {
            secure: true,
            noCampsites: true,
          },
        },
        {
          path: 'links',
          component: OrganizationLinks,
          meta: {
            secure: true,
            noCampsites: true,
          },
          children: [
            {
              path: '',
              component: OrganizationLinksIndex,
              meta: {
                secure: true,
                noCampsites: true,
                entitlements: [ORG_ROLE_OWNER, ORG_ROLE_MEMBER],
              },
            },
            {
              path: 'new',
              component: OrganizationLinksNew,
              meta: {
                secure: true,
                noCampsites: true,
                entitlements: [ORG_ROLE_OWNER, ORG_ROLE_MEMBER],
              },
            },
            {
              path: ':groupId',
              component: OrganizationLinksLinkGroup,
              meta: {
                secure: true,
                noCampsites: true,
                entitlements: [ORG_ROLE_OWNER, ORG_ROLE_MEMBER],
              },
            },
          ],
        },
        {
          path: 'people',
          component: OrganizationPeople,
          meta: {
            secure: true,
            noCampsites: true,
          },
          children: [
            {
              path: '',
              component: OrganizationPeopleIndex,
              meta: {
                secure: true,
                noCampsites: true,
                entitlements: [ORG_ROLE_OWNER, ORG_ROLE_MEMBER],
              },
              children: [
                {
                  path: '',
                  component: OrganizationPeopleMembers,
                  meta: {
                    secure: true,
                    noCampsites: true,
                    entitlements: [ORG_ROLE_OWNER, ORG_ROLE_MEMBER],
                  },
                },
                {
                  path: 'collaborators',
                  component: OrganizationPeopleCollaborators,
                  meta: {
                    secure: true,
                    noCampsites: true,
                    entitlements: [ORG_ROLE_OWNER, ORG_ROLE_MEMBER],
                  },
                },
              ],
            },
            {
              path: ':memberId',
              component: OrganizationPeopleMember,
              meta: {
                secure: true,
                noCampsites: true,
                entitlements: [ORG_ROLE_OWNER, ORG_ROLE_MEMBER],
              },
            },
          ],
        },

        // Org setup
        {
          path: 'confirm-email',
          component: OrganizationNewConfirmEmail,
          meta: {
            secure: true,
            hideNav: true,
            noCampsites: true,
          },
        },
        {
          path: 'select-plan',
          component: OrganizationNewSelectPlan,
          meta: {
            secure: true,
            hideNav: true,
            noCampsites: true,
          },
        },
      ],
    },

    {
      path: '/profiles',
      component: Profiles,
      meta: {
        secure: true,
        noCampsites: false,
      },
    },
    {
      path: '/profile/new',
      component: NewProfile,
      meta: {
        secure: true,
        noCampsites: true,
      },
    },
    {
      path: '/profile/:id',
      component: Profile,
      meta: {
        secure: true,
      },
      children: [
        {
          path: '',
          component: ConfigLinks,
          meta: {
            secure: true,

            entitlements: [CAN_MANAGE_LINKS],
            canCheckout: true,
          },
        },
        {
          path: 'archive',
          component: ArchivedLinks,
          meta: {
            secure: true,

            entitlements: [CAN_MANAGE_LINKS],
            canCheckout: true,
          },
        },
        {
          path: 'analytics',
          component: Analytics,
          meta: {
            secure: true,

            entitlements: [CAN_MANAGE_ANALYTICS],
            canCheckout: true,
          },
          children: [
            {
              path: '',
              component: AnalyticsDefault,
              meta: {
                secure: true,

                entitlements: [CAN_MANAGE_ANALYTICS],
                canCheckout: true,
              },
            },
          ],
        },
        {
          path: 'qr-code',
          component: QrCode,
          meta: {
            secure: true,
            canCheckout: true,
          },
        },
        {
          path: 'settings',
          component: Settings,
          meta: {
            secure: true,
          },
          children: [
            {
              path: '',
              component: SettingsDefault,
              meta: {
                secure: true,
                entitlements: [CAN_MANAGE_SETTINGS],
                canCheckout: true,
              },
            },
            {
              path: 'appearance',
              component: SettingsAppearance,
              meta: {
                secure: true,
                entitlements: [CAN_MANAGE_APPEARANCE],
                canCheckout: true,
              },
            },
            {
              path: 'users',
              component: SettingsUsers,
              meta: {
                secure: true,
                entitlements: [CAN_MANAGE_USERS],
                role: 'owner',
              },
            },
            {
              path: 'urls',
              component: SettingsDomain,
              meta: {
                secure: true,
                entitlements: [CAN_MANAGE_URLS],
                canCheckout: true,
              },
            },
            {
              path: 'make-my-bio-link',
              component: SettingsMakeMyBioLink,
              meta: {
                secure: true,

                entitlements: [CAN_MANAGE_APPEARANCE],
                canCheckout: true,
              },
            },
          ],
        },
      ],
    },

    // Newsletter
    {
      path: '/unsubscribed',
      component: Unsubscribed,
      meta: {
        secure: false,
        layout: 'simple-layout',
        standalone: true,
      },
    },
    {
      path: '/subscribed',
      component: Subscribed,
      meta: {
        secure: false,
        layout: 'simple-layout',
        standalone: true,
      },
    },

    {
      path: '/maintenance',
      component: Maintenance,
      meta: {
        secure: false,
        layout: 'simple-layout',
        standalone: true,
      },
    },
    {
      path: '*',
      component: Error404,
      meta: {
        secure: false,
        noCampsites: true,
        layout: 'simple-layout',
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  // Redirect direct paths to profile paths
  // i.e. /analytics -> /profile/1/analytics
  if (
    to.path.startsWith('/settings') ||
    to.path.startsWith('/analytics') ||
    to.path.startsWith('/qr-code') ||
    to.path.startsWith('/archive')
  ) {
    const profileId = store.getters['profiles/currentId'];
    pace.start();
    next(`/profile/${profileId}${to.path}`);
    return;
  }

  // if signed up user tries to access create account pages, redirect to dashboard
  if (
    store.getters.isLoggedIn &&
    to.path.startsWith('/create-account') &&
    !store.getters.hasAccountCreateStep
  ) {
    next('/');
    return;
  }

  // show loader
  pace.start();

  // check if account is complete
  if (
    store.getters.isLoggedIn &&
    (!store.getters.isAccountCreated || store.getters.isAtStepSocial) &&
    to.path !== '/create-account' &&
    !to.meta.standalone
  ) {
    pace.stop();
    return next('/create-account');
  }
  // check if not logged in
  else if (!store.getters.isLoggedIn && to.meta.secure) {
    pace.stop();
    return next(`/login?return_url=${to.fullPath}`);
  }
  // user is in account creation process
  else if (
    store.getters.isLoggedIn &&
    store.getters.isAtStepConfirmEmail &&
    to.path !== '/create-account/confirm-email' &&
    !to.meta.standalone
  ) {
    pace.stop();
    return next('/create-account/confirm-email');
  } else if (
    store.getters.isLoggedIn &&
    store.getters.isAtStepInfo &&
    to.path !== '/create-account/your-information'
  ) {
    pace.stop();
    return next('/create-account/your-information');
  } else if (
    store.getters.isLoggedIn &&
    store.getters.isAtStepPlan &&
    to.path !== '/create-account/select-plan'
  ) {
    pace.stop();
    return next('/create-account/select-plan');
  } else if (
    store.getters.isLoggedIn &&
    store.getters.isAtStepMakeBio &&
    to.path !== '/create-account/make-my-bio-link'
  ) {
    pace.stop();
    return next('/create-account/make-my-bio-link');
  }
  // if they don't have campsites, they can only access a few pages
  else if (
    store.getters.isLoggedIn &&
    store.getters.isAccountCreated &&
    store.getters.hasNoCampsites &&
    !store.getters.hasAccountCreateStep &&
    !to.meta.noCampsites &&
    !to.meta.standalone
  ) {
    pace.stop();
    return next('/');
  }
  // if they are already logged in, redirect to admin
  else if (
    store.getters.isLoggedIn &&
    store.getters.isAccountCreated &&
    !store.getters.hasAccountCreateStep &&
    !to.meta.secure &&
    !to.meta.standalone
  ) {
    pace.stop();
    return next('/');
  }

  next();
});

router.afterEach((to, from) => {
  pace.stop();
  store.commit('setLayout', to.meta.layout || 'app-layout');
  // track page view in analytics
  if (window.gtag) {
    gtag('config', 'G-BBZYB7YVCV', { page_path: to.path });
  }
  if (window.fbq) {
    fbq('track', 'PageView');
  }

  intercomUpdate();

  // Log page view to user
  if (store.getters.isLoggedIn) {
    const pageName =
      to.matched[to.matched.length - 1]?.components?.default?.head?.title;
    userLog(store.getters.currentUserId, USER_ACTIVITY_TYPES.PAGE_VIEW, {
      page_name: pageName,
      page: to.path,
      previous_page: from?.path,
    });
  }
});

export default router;
