<template>
  <div ref="root" class="story" :style="styles">
    <div v-if="$slots.header" class="story__header">
      <slot name="header" />
    </div>
    <div class="story__content">
      <slot />
    </div>
    <div class="story__footer">
      <Logo :color="color" size=".9em" />
      <FlexSpacer />
      <slot name="footer-right">
        <span class="story__footer__url">campsite.bio/wrapped</span>
      </slot>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref } from 'vue';
import { useResizeObserver } from '@vueuse/core';
import {
  useTheme,
  getThemeColor,
  Logo,
  FlexSpacer,
} from '@campsite-bio/component-lib';

export default {
  components: {
    Logo,
    FlexSpacer,
  },

  props: {
    color: {
      type: String,
      default: '#fff',
    },

    backgroundColor: {
      type: String,
      default: 'primary',
    },
  },

  setup(props, { emit }) {
    const root = ref(null);
    const height = ref(0);
    const width = ref(0);
    const fontSize = ref(null);
    const isReady = ref(false);
    const theme = useTheme();

    const { name } = inject('wrapped');

    const themeColor = computed(() => {
      return getThemeColor(props.backgroundColor, theme.value);
    });

    const styles = computed(() => {
      return {
        '--c-color': getThemeColor(props.color, theme.value),
        backgroundColor: themeColor.value,
        fontSize: `${fontSize.value}px`,
      };
    });

    useResizeObserver(root, ([entry]) => {
      const { width: rectWidth, height: rectHeight } = entry.contentRect;
      height.value = rectHeight;
      width.value = rectWidth;
      fontSize.value = rectWidth * 0.05;
      if (!isReady.value) emit('on-ready');
      isReady.value = true;
    });

    return { root, height, width, themeColor, styles, name };
  },
};
</script>

<style lang="scss" scoped>
.story {
  --padding-side: 0.75em;

  color: var(--c-color);
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  &__content {
    padding-top: 5.33em;
    padding-left: var(--padding-side);
    padding-right: var(--padding-side);
  }

  &__header {
    align-items: center;
    display: flex;
    position: absolute;
    gap: 0.25em;
    top: var(--padding-side);
    left: var(--padding-side);
    font-weight: 700;
    font-family: var(--g-font-header-family);
    font-size: 1em;
  }

  &__footer {
    align-items: center;
    display: flex;
    position: absolute;
    bottom: var(--padding-side);
    left: var(--padding-side);
    right: var(--padding-side);

    &__url {
      font-weight: 700;
      text-transform: uppercase;
      font-family: var(--g-font-header-family);
      font-size: 0.6em;
      letter-spacing: 0.05em;
    }
  }
}
</style>
