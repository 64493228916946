import { useDocumentVisibility, useTimestamp } from '@vueuse/core';
import { watch } from 'vue';

export function useLastUpdated({
  interval,
  callback,
  lastUpdatedTimestamp,
  timeShouldHavePassed,
}) {
  const { timestamp, pause, resume } = useTimestamp({
    interval,
    controls: true,
  });
  const visibility = useDocumentVisibility();

  watch(timestamp, (currentTime) => {
    const lastUpdated = lastUpdatedTimestamp.value;
    if (lastUpdated) {
      const difference = currentTime - lastUpdated;
      if (difference > timeShouldHavePassed) {
        callback();
      }
    }
  });

  watch(visibility, (value) => {
    if (value === 'visible') resume();
    else pause();
  });
}
