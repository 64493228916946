import { computed } from 'vue';

import { useMeta, useStore } from '../../compositions';

export function useHomeTour() {
  const store = useStore();
  const user = computed(() => store.getters.currentUser);
  const tours = useMeta('tours', {
    defaultValue: {},
    itemRef: user,
  });

  function tryToRun() {
    const hasTakenTour = tours.value.home_page;
    if (!hasTakenTour) {
      import('../home').then((Tour) => {
        new Tour.default();
      });
    }
  }

  return {
    hasTakenTour: computed(() => tours.value.home_page),
    run: tryToRun,
    dismiss() {
      store.dispatch('updateTourOption', 'home_page');
    },
  };
}
