<template>
  <TabPanel class="panel" value="countries">
    <CountriesTable
      :is-loading="isLoading"
      :data="countryData"
      :has-results="hasResults"
      data-title="Clicks"
      hide-unique
      style="margin-top: 0"
    />
  </TabPanel>
</template>

<script>
import { TabPanel } from '@campsite-bio/component-lib';
import { computed } from 'vue';

import { CountriesTable } from '../../analytics/tables';
import flags from '../../../emojis/flags.json';

export default {
  components: {
    TabPanel,
    CountriesTable,
  },

  props: {
    period: {
      type: Number,
      required: true,
    },

    linkId: {
      type: String,
      required: true,
    },

    data: {
      type: Object,
      default: null,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const countryData = computed(() => {
      if (!props.data) return [];

      const stats = props.data.clicks_by_country
        .filter((i) => i._id.range === 'current')
        .map((item) => {
          const flag = flags.find((flag) => flag.name === item._id.country);
          return [
            item._id.country,
            `${flag ? `${flag.emoji} ` : ''}${item._id.country}`,
            item.count,
            0,
          ];
        });

      return stats.sort((a, b) => b[2] - a[2]);
    });

    const hasResults = computed(() => countryData.value.length > 0);

    return { countryData, hasResults };
  },
};
</script>

<style lang="scss" scoped>
.panel {
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 1rem;
}
</style>
