import { ref } from 'vue';
import { useRouter } from './use-router';

export function useRoute() {
  const router = useRouter();
  const route = ref(router.app.$route);

  router.afterEach(() => {
    route.value = router.app.$route;
  });

  return route;
}
