<template>
  <div class="three-columns">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.three-columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
  gap: 2rem;

  @media (min-width: 992px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
</style>
