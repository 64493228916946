import { computed, unref } from 'vue';
import {
  convertImageUrlToEncodedUrl,
  MEDIA_SIZES,
} from '@campsite-bio/component-lib';
import { DateTime } from 'luxon';
import { ORG_ROLE_OWNER } from '@/config';
import { getMeta } from '@/utils';

// Returns computed values for a profile
export function useProfile(incomingProfile) {
  const profile = computed(() => {
    return unref(incomingProfile);
  });

  const organizationId = computed(() => {
    return profile.value?.organization_id?.$oid;
  });

  const isOrgProfile = computed(() => {
    return !!organizationId.value;
  });

  const defaultBaseRole = computed(() => {
    return profile.value?.default_member_role;
  });

  const isOrgOwner = computed(() => {
    return profile.value?.user_access?.role === ORG_ROLE_OWNER;
  });

  const isOwner = computed(() => {
    return profile.value?.user_access?.entitlements === 'owner';
  });

  const createdAt = computed(() => {
    return DateTime.fromISO(profile.value?.created_at);
  });

  const profileImage = computed(() => {
    return convertImageUrlToEncodedUrl(
      profile.value?.profile_image,
      MEDIA_SIZES.profile_image,
    );
  });

  const id = computed(() => {
    return profile.value?._id?.$oid;
  });

  const isProfileOnFreePlan = computed(() => profile.value?.plan === 'free');

  const username = computed(() => {
    return profile.value?.username;
  });

  const usernameLowercase = computed(() => {
    return profile.value?.username_lowercase;
  });

  const domain = computed(() => {
    return profile.value?.domain;
  });

  const subdomain = computed(() => {
    return profile.value?.subdomain;
  });

  const subdomainChangedAt = computed(() => {
    return DateTime.fromISO(profile.value?.subdomain_changed_at);
  });

  const customDomain = computed(() => {
    return profile.value?.custom_domain;
  });

  const certificate = computed(() => {
    return profile.value?.certificate;
  });

  const usernameLink = computed(() => {
    return `https://${domain.value}/${usernameLowercase.value}`;
  });

  const coverImage = computed(() => {
    return getMeta('cover', null, profile.value.meta);
  });

  const hasCoverImage = computed(() => {
    return coverImage.value?.enabled;
  });

  return {
    profile,
    isOwner,
    createdAt,
    profileImage,
    id,
    isProfileOnFreePlan,
    username,
    usernameLowercase,
    domain,
    subdomain,
    subdomainChangedAt,
    customDomain,
    usernameLink,
    certificate,
    organizationId,
    isOrgProfile,
    defaultBaseRole,
    isOrgOwner,
    coverImage,
    hasCoverImage,
  };
}
