export function getButtonBorderWidthFromNumber(width, buttonStyle = null) {
  if (width >= 4) return 'thick';
  else if (width > 0 || buttonStyle === 'hollow') return 'thin';
  return 'none';
}

export function getButtonBorderRadiusFromNumber(width) {
  if (width >= 20) return 'circular';
  if (width >= 6) return 'rounded';
  else if (width > 0) return 'slightlyrounded';
  return 'square';
}
