// Get a social account name from a URL
export function getSocialAccountType(url) {
  if (url.includes('twitter.com')) {
    return 'twitter';
  } else if (url.includes('facebook.com')) {
    return 'facebook';
  } else if (url.includes('instagram.com')) {
    return 'instagram';
  } else if (url.includes('youtube.com')) {
    return 'youtube';
  } else if (url.includes('linkedin.com')) {
    return 'linkedin';
  } else if (url.includes('pinterest.com')) {
    return 'pinterest';
  } else if (url.includes('tumblr.com')) {
    return 'tumblr';
  } else if (url.includes('snapchat.com')) {
    return 'snapchat';
  } else if (url.includes('tiktok.com')) {
    return 'tiktok';
  } else if (url.includes('github.com')) {
    return 'github';
  } else if (url.includes('spotify.com')) {
    return 'spotify';
  } else if (url.includes('ko-fi.com')) {
    return 'ko-fi';
  }
  return null;
}
