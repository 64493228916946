<template>
  <ChartCard
    class="channel-card"
    :title="title"
    :subtitle="subtitle"
    :is-loading="isLoading"
    :style="styles"
    :highlights="defaultCurrentTotals"
  >
    <template v-if="hasData">
      <ChartWrapper
        width="100%"
        height="250"
        type="line"
        :options="chartOptions"
        :data="series"
      />
      <div v-if="annotations" class="text-center">
        <div v-if="annotations" class="legend">
          <div class="legend__marker"></div>
          New Post
        </div>
      </div>

      <div slot="after" class="channel-card__table" gutter-top>
        <SimpleTable>
          <tbody>
            <template v-if="isLoading">
              <tr v-for="i in 3" :key="i">
                <td><Skeleton width="100px" /></td>
                <td><Skeleton width="20px" /></td>
                <td>
                  <Skeleton width="40px" />
                </td>
              </tr>
            </template>
            <template v-else>
              <tr v-for="({ name, value }, i) in metricsAsArray" :key="value">
                <td>{{ name }}</td>
                <td :title="defaultCurrentTotals[i].title">
                  {{ defaultCurrentTotals[i].title | numberFormatter(2) }}
                </td>
                <td>
                  <ChangeBadge
                    :current="defaultCurrentTotals[i].title"
                    :past="defaultPastTotals[i].title"
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </SimpleTable>
      </div>
    </template>
    <template v-else>
      <div
        class="text-center"
        style="
          min-height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <VText>
          <slot name="no-data"> No data to display </slot>
        </VText>
      </div>
    </template>
  </ChartCard>
</template>

<script>
import { VText, SimpleTable, Skeleton } from '@campsite-bio/component-lib';
import { computed } from 'vue';
import { DateTime } from 'luxon';

import { useApexOptions, useDataColors } from '../../compositions';
import { ChartCard } from '../';
import { ChartWrapper } from '../../../analytics';
import { ChangeBadge } from '../../components';

export default {
  components: {
    ChartWrapper,
    ChartCard,
    VText,
    SimpleTable,
    Skeleton,
    ChangeBadge,
  },

  props: {
    allData: {
      type: Object,
      required: true,
    },

    data: {
      type: Array,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      default: null,
    },

    metrics: {
      type: [Array, Object],
      required: true,
    },

    annotations: {
      type: Object,
      default: null,
    },

    /** sum | last */
    totalsMethod: {
      type: String,
      default: 'sum',
    },

    /** Start the yaxis at the lowest value, rather than 0 */
    yaxisStartAtLowest: Boolean,

    compare: Boolean,
    isLoading: Boolean,
  },

  setup(props) {
    const dataColors = useDataColors();

    const styles = computed(() => {
      return {
        '--c-data-color': dataColors.value[0],
      };
    });

    const isMultipleMetrics = computed(() => {
      return Array.isArray(props.metrics);
    });

    const metricsAsArray = computed(() => {
      return isMultipleMetrics.value ? props.metrics : [props.metrics];
    });

    const metrics = computed(() => {
      if (isMultipleMetrics.value) {
        return props.metrics.reduce((acc, metric) => {
          return [...acc, { ...metric, range: 'past' }, { ...metric }];
        }, []);
      }

      return [
        { ...props.metrics, range: 'past' },
        { ...props.metrics, range: 'current' },
      ];
    });

    const allSeriesData = computed(() => {
      const data = metrics.value.map(({ name, range = 'current' }, i) => {
        return {
          label: name,
          data: [],
          range,
        };
      });
      props.data.forEach((item) => {
        metrics.value.forEach(({ value, range }, index) => {
          if (
            (range === 'past' && item.range === 'past') ||
            (item.range === 'current' && (!range || range === 'current'))
          )
            data[index].data.push(item[value] || item.data[value] || 0);
        });
      });

      return data;
    });

    const categories = computed(() => {
      return props.data
        .filter((d) => d.range === 'current')
        .map((stat) => {
          return DateTime.fromISO(stat.date).toFormat('MMM d');
        });
    });

    const series = computed(() => {
      return {
        datasets: allSeriesData.value
          .filter((d) => d.range === 'current')
          .map((d, i) => ({
            ...d,
            backgroundColor: dataColors.value[i],
            borderColor: dataColors.value[i],
            pointBackgroundColor: dataColors.value[i],
            pointBorderColor: dataColors.value[i],
          })),
        labels: categories.value,
      };
    });

    const hasData = computed(() => {
      return series.value.datasets[0].data.some((s) => s);
    });

    const annotations = computed(() => {
      if (!props.annotations) return [];

      const results = series.value.datasets
        .filter((d) => d.range === 'current')
        .reduce((accum, { data: seriesData, range }) => {
          const data = props.data.filter((d) => d.range === range);

          data.forEach((item, i) => {
            const date = DateTime.fromISO(item.date);
            const formattedDate = date.toISODate();
            const value = props.annotations.data.find((d) => {
              return (
                DateTime.fromISO(d[props.annotations.dateKey]).toISODate() ===
                formattedDate
              );
            });

            if (value) {
              const color = dataColors.value[0];
              accum[`point${i}`] = {
                type: 'point',
                xValue: categories.value.findIndex(
                  (c) => c === date.toFormat('MMM d'),
                ),
                yValue: seriesData[i],
                backgroundColor: 'white',
                borderColor: color,
                radius: 4,
                borderWidth: 2,
              };
            }
          });

          return accum;
        }, {});

      return Object.keys(results).length ? results : null;
    });

    const overrideOptions = computed(() => {
      let defaultOptions = {};

      if (annotations.value) {
        defaultOptions.plugins = {
          annotation: {
            annotations: annotations.value,
          },
        };
        // defaultOptions.xaxis = {
        //   labels: {
        //     show: true,
        //     rotate: 0,
        //   },
        // };
      }

      if (isMultipleMetrics.value) {
        defaultOptions = {
          // stroke: {
          //   width: 3,
          // },
          legend: {
            display: true,
          },
        };
      }
      if (props.yaxisStartAtLowest) {
        const min =
          Math.min(
            ...series.value.datasets.reduce((accum, dataSet) => {
              return [...accum, ...dataSet.data];
            }, []),
          ) - 1;

        defaultOptions.yaxis = {
          min: min,
        };
      }

      return defaultOptions;
    });

    const chartOptions = useApexOptions({
      ...overrideOptions.value,
    });

    const defaultCurrentTotals = computed(() => {
      return allSeriesData.value
        .filter((d) => d.range === 'current')
        .reduce((accum, { label, data }, i) => {
          let count = data[data.length - 1];
          if (props.totalsMethod === 'sum') {
            count = data.reduce((acc, stat) => {
              return acc + stat;
            }, 0);
          }

          const valueData = {
            title: Math.round(count),
          };

          // if (Array.isArray(props.metrics)) {
          valueData.subtitle = label;
          valueData.color = dataColors.value[i];
          // }

          accum.push(valueData);
          return accum;
        }, []);
    });

    const defaultPastTotals = computed(() => {
      return allSeriesData.value
        .filter((d) => d.range === 'past')
        .reduce((accum, { label, data }, i) => {
          let count = data[data.length - 1];
          if (props.totalsMethod === 'sum') {
            count = data.reduce((acc, stat) => {
              return acc + stat;
            }, 0);
          }

          accum.push({
            title: Math.round(count),
          });
          return accum;
        }, []);
    });

    const previousTotals = computed(() => {
      if (isMultipleMetrics.value) return [];

      return defaultPastTotals.value;
    });

    return {
      isMultipleMetrics,
      categories,
      styles,
      chartOptions,
      series,
      defaultCurrentTotals,
      defaultPastTotals,
      previousTotals,
      hasData,
      metricsAsArray,
    };
  },
};
</script>

<style lang="scss" scoped>
.channel-card {
  min-height: unset;

  &__table {
    border-top: 0.125rem solid var(--g-color-gray-200);
    margin: 1rem -1rem -1rem;
  }
}

.legend {
  align-items: center;
  display: inline-flex;
  gap: 0.25rem;
  font-size: 0.75rem;

  &__marker {
    background: #fff;
    border-radius: 50%;
    border: 0.15rem solid var(--c-data-color);
    width: 1em;
    height: 1em;
  }
}
</style>
