<template>
  <ListItem
    component="button"
    type="button"
    :disabled="disabled"
    @click="$emit('click', 'group')"
  >
    <ListItemAction>
      <LockIcon v-if="disabled" size="1.25rem" />
      <GroupIcon v-else size="1.5rem" />
    </ListItemAction>
    <ListItemContent>
      <ListItemTitle>Group</ListItemTitle>
      <ListItemSubtitle wrap
        >Group similar links together and save space.</ListItemSubtitle
      >
    </ListItemContent>
  </ListItem>
</template>

<script>
import {
  ListItem,
  ListItemAction,
  ListItemContent,
  ListItemSubtitle,
  ListItemTitle,
  GroupIcon,
  LockIcon,
} from '@campsite-bio/component-lib';

export default {
  components: {
    ListItem,
    ListItemAction,
    ListItemContent,
    ListItemSubtitle,
    ListItemTitle,
    GroupIcon,
    LockIcon,
  },

  props: {
    disabled: Boolean,
  },
};
</script>

<style lang="scss" scoped></style>
