import { ref, watch } from 'vue';
import { useWindowScroll } from '@vueuse/core';

/**
 * Hide navbar on scroll down
 * @param {Object} options
 * @param {Number} options.scrollOffset - How much to scroll before hiding the navbar
 * @param {Boolean} options.hiddenOnMount
 * @returns {Ref<Boolean>}
 */
export function useNavbarScroll({ scrollOffset = 0, hiddenOnMount = false }) {
  const { y } = useWindowScroll();
  const hide = ref(hiddenOnMount);
  const lastScrollPosition = ref(0);

  watch(
    y,
    (scrollPosition) => {
      if (scrollPosition < 0) return;

      if (Math.abs(scrollPosition - lastScrollPosition.value) < scrollOffset)
        return;

      hide.value = scrollPosition > lastScrollPosition.value;
      lastScrollPosition.value = scrollPosition;
    },
    { immediate: true },
  );

  return hide;
}
