<template>
  <div>
    <VText component="h2" variant="h4" style="margin-bottom: 0.5rem">
      Recent profile
    </VText>
    <ProfileItem
      v-if="hasProfile"
      class="js-profile-item"
      :profile="profile"
      show-stats
    />
    <Skeleton v-else width="100%" height="90px" />
  </div>
</template>

<script>
import { VText, Skeleton } from '@campsite-bio/component-lib';
import { computed } from 'vue';

import { ProfileItem } from '../../profiles';

export default {
  components: {
    VText,
    ProfileItem,
    Skeleton,
  },

  props: {
    profile: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const hasProfile = computed(() => {
      return props.profile && Object.keys(props.profile).length > 0;
    });

    return { hasProfile };
  },
};
</script>

<style lang="scss" scoped></style>
