<template>
  <MultiStepDialog
    v-model="isOpen"
    max-width="400px"
    content-class="connected-dialog"
    :steps="steps"
  >
    <template v-for="(index, name) in $scopedSlots" #[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
  </MultiStepDialog>
</template>

<script>
import { computed, ref } from 'vue';

import { MultiStepDialog } from '../../multi-step-dialog';
import {
  ProviderList,
  GoogleAuth,
  GoogleAnalyticsAuth,
  InstagramAuth,
  FacebookAuth,
} from './steps';
import {
  useCurrentUserProfileEntitlements,
  useProfileLoader,
  useStore,
} from '../../../compositions';
import { PROVIDERS } from '../../../config';

export default {
  components: {
    MultiStepDialog,
  },

  props: {
    profileId: {
      type: String,
      default: null,
    },

    providers: {
      type: Array,
      default: () => [
        PROVIDERS.google,
        PROVIDERS.google_analytics,
        PROVIDERS.instagram,
        PROVIDERS.facebook,
        PROVIDERS.youtube,
      ],
    },
  },

  setup(props) {
    const store = useStore();
    const isOpen = ref(false);

    const { profile } = useProfileLoader(props.profileId);
    const { hasAnalyticsAddon } = useCurrentUserProfileEntitlements(
      profile,
      false,
    );

    const hasInstagram = computed(() => {
      return !!store.getters['profiles/instagramAuthorization'];
    });

    const hasFacebook = computed(() => {
      return !!store.getters['profiles/facebookAuthorization'];
    });

    const hasGoogle = computed(() => {
      return !!store.getters['profiles/googleAuthorization'];
    });

    const hasGoogleAnalytics = computed(() => {
      return !!store.getters['profiles/googleAnalyticsAuthorization'];
    });

    const steps = computed(() => {
      return [
        {
          path: '',
          component: ProviderList,
          title: 'Connect Account',
          props: {
            hasInstagram,
            hasFacebook,
            hasGoogle,
            hasGoogleAnalytics,
            hasAnalyticsAddon,
            providers: props.providers,
          },
        },
        {
          path: 'instagram-auth',
          component: InstagramAuth,
          title: 'Connect Instagram',
        },
        {
          path: 'facebook-auth',
          component: FacebookAuth,
          title: 'Connect Facebook',
          props: {
            profileId: props.profileId,
          },
        },
        {
          path: 'google-auth',
          component: GoogleAuth,
          title: 'Connect Google',
        },
        {
          path: 'google-analytics-auth',
          component: GoogleAnalyticsAuth,
          title: 'Connect Google Analytics 4',
        },
      ];
    });

    return { isOpen, steps };
  },
};
</script>

<style lang="scss">
.connected-dialog {
  display: flex;
  min-height: 375px;

  .v-card.v-card {
    display: flex;
    flex-direction: column;
  }

  .v-card__text {
    flex-grow: 1;
  }
}
</style>
