<template>
  <Dialog v-model="isOpen" max-width="450px" title="Associate your accounts">
    <template #activator="data">
      <slot name="activator" v-bind="data" />
    </template>

    <Card>
      <CardText>
        <VText gutter-bottom
          >Connect your Instagram accounts with your profiles.</VText
        >
        <List>
          <template v-for="(account, i) in accounts">
            <Item
              :key="account.instagramAccount.id"
              :menu-items="menuItems"
              :account="account"
              @update:account="updateAccount(account, $event)"
            />
            <Divider
              v-if="i !== accounts.length - 1"
              :key="i"
              :thickness="1"
              color="gray200"
              style="margin: 0.5rem 0"
            />
          </template>
        </List>

        <VText color="gray600" style="margin-top: 1rem"
          ><small
            >By connecting your Instagram accounts with your profiles, we can
            associate performance data together.</small
          ></VText
        >
      </CardText>
      <CardActions>
        <Button
          variant="flat"
          color="error"
          :disabled="isSaving"
          @click="isOpen = false"
        >
          Cancel
        </Button>
        <Button
          variant="flat"
          color="success"
          :loading="isSaving"
          @click="save"
        >
          Save
        </Button>
      </CardActions>
    </Card>
  </Dialog>
</template>

<script>
import {
  Card,
  CardText,
  CardActions,
  Button,
  VText,
  List,
  Divider,
} from '@campsite-bio/component-lib';
import { computed, onMounted, ref, toRefs, watch } from 'vue';

import { useModel, useStore } from '../../../../compositions';
import { Dialog } from '../../../dialogs';
import Item from './item';

export default {
  components: {
    Dialog,
    Card,
    VText,
    CardText,
    CardActions,
    Button,
    List,
    Item,
    Divider,
  },

  props: {
    accounts: {
      type: Array,
      required: true,
    },

    value: Boolean,
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const store = useStore();
    const isOpen = useModel(value, (val) => emit('input', val));
    const isSaving = ref(false);
    const profiles = computed(() =>
      store.getters['analytics/getField']('ownerProfiles'),
    );
    const localAccounts = ref(null);

    watch(
      () => props.accounts,
      (value) => {
        localAccounts.value = [...value];
      },
      {
        immediate: true,
      },
    );

    const menuItems = computed(() => {
      return profiles.value
        ?.map((profile) => {
          return {
            name: profile.username,
            value: profile._id.$oid,
          };
        })
        .sort((a, b) => a.name?.localeCompare(b.name));
    });

    async function load() {
      try {
        await store.dispatch('analytics/loadProfiles');
      } catch (e) {
        console.error(e);
      }
    }

    function updateAccount({ instagramAccount }, campsite_id) {
      const index = localAccounts.value.findIndex(
        (a) => a.instagramAccount.id === instagramAccount.id,
      );

      // account may not be set, so we need to set it ourselves to be sure
      localAccounts.value.splice(index, 1, {
        ...localAccounts.value[index],
        account: {
          ...(localAccounts.value[index].account || {}),
          name: instagramAccount.username,
          uid: instagramAccount.id,
          campsite_id,
        },
      });
    }

    // Update any account that has a campsite_id (i.e. is mapped to a profile)
    async function save() {
      emit(
        'save',
        localAccounts.value.filter((a) => a.account?.campsite_id),
      );
      isOpen.value = false;
    }

    onMounted(load);

    return {
      isOpen,
      isSaving,
      menuItems,
      save,
      updateAccount,
    };
  },
};
</script>

<style lang="scss" scoped></style>
