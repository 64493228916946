<template>
  <ChartCard title="Video Events" :subtitle="subtitle">
    <div v-if="hasResults" :style="styles">
      <div class="video-events__top">
        <div class="video-events__left">
          <Skeleton
            v-if="!images && isLoadingImages"
            height="100px"
            width="140px"
          />
          <a v-else-if="data.link" :href="data.link.url" target="_blank">
            <img
              :src="thumbnail"
              alt="Video thumbnail"
              height="100"
              style="height: 100px"
            />
          </a>
          <img
            v-else
            :src="thumbnail"
            alt="Video thumbnail"
            height="100"
            style="height: 100px"
          />

          <div class="stats">
            <div class="stat">
              <span class="stat__title">Views</span>
              <span class="stat__number">{{
                eventBreakdown['video-started'].total_events
                  | numberFormatter(1)
              }}</span>
            </div>
            <div class="stat">
              <span class="stat__title">Unique Views</span>
              <span class="stat__number">{{
                eventBreakdown['video-started'].unique_events
                  | numberFormatter(1)
              }}</span>
            </div>
          </div>
        </div>

        <div>
          <ChartWrapper
            width="100%"
            height="250"
            type="polarArea"
            :options="chartOptions"
            :data="series"
          />
        </div>
      </div>

      <SimpleTable gutter-top>
        <thead>
          <tr>
            <th><span class="sr-only">Name</span></th>
            <th class="text-right">Events</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="({ dimensions, count }, index) in currentData"
            :key="index"
          >
            <td class="td-name">
              <span
                ><span :class="`color-${index}`" />
                {{ EVENT_TO_LABEL[dimensions.threshold] }}</span
              >
            </td>
            <td class="text-right">
              {{ count | numberFormatter(1) }}
            </td>
          </tr>
        </tbody>
      </SimpleTable>
    </div>
    <NoResults v-else />
  </ChartCard>
</template>

<script>
import { computed } from 'vue';
import {
  EVENT_TO_LABEL,
  Skeleton,
  SimpleTable,
} from '@campsite-bio/component-lib';
import tinycolor from 'tinycolor2';

import NoResults from '../no-results';
import ChartCard from '../chart-card';
import { useApexOptions, useDataColors } from '../../compositions';
import { useMagicImage } from '@/compositions';
import { DEFAULT_VIDEO_THUMBNAIL } from '@/utils';
import { ChartWrapper } from '../../';

export default {
  components: {
    ChartCard,
    SimpleTable,
    ChartWrapper,
    NoResults,
    Skeleton,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    isLoadingLinks: Boolean,
  },

  setup(props) {
    const dataColors = useDataColors();

    const url = computed(() => {
      const { link } = props.data;
      return link?.url || '';
    });

    const {
      images,
      isLoading: isLoadingImages,
      data: imageUrlData,
    } = useMagicImage({
      url,
      immediate: true,
    });

    const thumbnail = computed(() => {
      if (!images.value || images.value.length === 0)
        return DEFAULT_VIDEO_THUMBNAIL;

      return images.value[0];
    });

    const subtitle = computed(() => {
      return imageUrlData.value ? imageUrlData.value.title : null;
    });

    const ORDER = [
      'video-25-percent-watched',
      'video-50-percent-watched',
      'video-75-percent-watched',
      'video-ended',
    ];

    const eventBreakdown = computed(() => {
      return props.data.standard_events
        .filter((v) => v.dimensions.range === 'current')
        .reduce((acc, e) => {
          const eventName = e.dimensions.event_name;
          if (acc[eventName]) {
            acc[eventName]['sessions_with_event'] += e.sessions_with_event;
            acc[eventName]['total_events'] += e.total_events;
            acc[eventName]['unique_events'] += e.unique_events;
          } else {
            acc[eventName] = {
              sessions_with_event: e.sessions_with_event,
              total_events: e.total_events,
              unique_events: e.unique_events,
            };
          }

          return acc;
        }, {});
    });

    const currentData = computed(() => {
      return props.data.video_quartiles_by_link
        .filter((v) => v.dimensions.range === 'current')
        .sort(
          (a, b) =>
            ORDER.indexOf(a.dimensions.threshold) -
            ORDER.indexOf(b.dimensions.threshold),
        );
    });

    const series = computed(() => {
      return {
        datasets: [
          {
            label: 'Video Events',
            data: currentData.value.map((v) => v.count),
          },
        ],
        labels: currentData.value.map(
          (f) => EVENT_TO_LABEL[f.dimensions.threshold],
        ),
      };
    });

    const chartOptions = useApexOptions({
      backgroundColor: dataColors.value.map((c) =>
        tinycolor(c).setAlpha(0.75).toString(),
      ),
      borderWidth: 0,
      scales: {
        y: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        x: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
          border: {
            display: false,
          },
        },
      },
    });

    const styles = computed(() => {
      return dataColors.value.reduce((accum, color, index) => {
        accum[`--c-chart-${index}`] = color;
        return accum;
      }, {});
    });

    const hasResults = computed(() => currentData.value.length > 0);

    return {
      chartOptions,
      series,
      hasResults,
      currentData,
      styles,
      EVENT_TO_LABEL,
      eventBreakdown,
      images,
      thumbnail,
      isLoadingImages,
      imageUrlData,
      subtitle,
    };
  },
};
</script>

<style lang="scss" scoped>
.video-events {
  &__top {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1.5rem;
    padding-top: 1rem;
  }

  &__left {
    text-align: center;
  }
}

.stats {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 1rem;
  margin-top: 1.25rem;
  justify-content: center;
}

.stat {
  &__title {
    display: block;
    font-size: 1rem;
  }

  &__number {
    font-size: 1.75rem;
    font-weight: 700;
  }
}

.color-0,
.color-1,
.color-2,
.color-3 {
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.3em;
  height: 1em;
  width: 1em;
  flex-shrink: 0;
}

.color-0 {
  background-color: var(--c-chart-0);
}

.color-1 {
  background-color: var(--c-chart-1);
}

.color-2 {
  background-color: var(--c-chart-2);
}

.color-3 {
  background-color: var(--c-chart-3);
}
</style>
