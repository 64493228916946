<template>
  <LinkWrapper v-bind="$props" @add-link="$emit('add-link', $event)">
    <LinkCard>
      <LinkField>
        <ProfileDivider
          :link="link"
          :style="{
            '--p-divider-color': 'var(--g-color-gray-900)',
            margin: '1rem 0',
          }"
        />
      </LinkField>

      <template slot="btm">
        <LinkBtn
          v-if="!isDeleted && !isScheduleHidden"
          aria-label="Schedule link"
          :active="isExpanded('schedule')"
          :highlight="isExpanded('schedule')"
          :aria-controls="`schedule-${id}`"
          @click="toggleExpand('schedule')"
        >
          <CalendarIcon slot="icon" />
        </LinkBtn>
        <v-tooltip v-if="!isDeleted && !isPinnedHidden" top>
          <template #activator="{ on }">
            <LinkBtn
              v-if="!isDeleted"
              aria-label="Pin link"
              :pinned="pinned"
              :disabled="isPinnedDisabled"
              v-on="on"
              @click="pinned = !pinned"
            >
              <PinIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>{{ pinnedText }}</span>
        </v-tooltip>
        <v-tooltip v-if="isDeleted" top>
          <template #activator="{ on }">
            <LinkBtn
              class="link__btn--restore js-link-restore"
              aria-label="Restore link"
              :disabled="isRestoring"
              v-on="on"
              @click="handleRestore"
            >
              <RepeatIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>Restore link</span>
        </v-tooltip>
      </template>

      <template slot="actions">
        <DividerSettingsExpand
          :is-open="isExpanded('default')"
          @close="toggleExpand('default')"
        />
        <ScheduleExpand
          :is-open="isExpanded('schedule')"
          :link="link"
          @close="toggleExpand('schedule')"
        />
      </template>
    </LinkCard>
  </LinkWrapper>
</template>

<script>
import {
  CalendarIcon,
  PinIcon,
  RepeatIcon,
  ProfileDivider,
} from '@campsite-bio/component-lib';
import { computed, provide } from 'vue';

import { ScheduleExpand } from '../expand';
import { LinkBtn, LinkWrapper, LinkCard, LinkField } from '../components';
import { LinkProps } from '../link-props';
import { useLink } from '../compositions';
import DividerSettingsExpand from './settings-expand';
import { useStore } from '../../../compositions';

export default {
  components: {
    CalendarIcon,
    PinIcon,
    RepeatIcon,
    LinkBtn,
    LinkWrapper,
    LinkCard,
    LinkField,
    DividerSettingsExpand,
    ProfileDivider,
    ScheduleExpand,
  },

  props: LinkProps,

  setup(props) {
    const store = useStore();
    const { link, ...rest } = useLink(props, {
      expandOnAdd: 'default',
      name: 'divider',
    });
    const profile = computed(() => store.getters['profiles/currentProfile']);
    provide('profile', profile);

    return {
      ...rest,
    };
  },
};
</script>

<style scoped></style>
