<template>
  <ValidationProvider
    v-slot="{ errors, ariaInput, ariaMsg }"
    rules="required"
    name="location"
    slim
  >
    <FormGroup>
      <Label for="integration-location">Location</Label>
      <Select
        id="integration-location"
        v-model="localValue"
        v-bind="ariaInput"
        required
      >
        <option
          v-for="location in locations"
          :key="location.id"
          :value="location.id"
        >
          {{ location.name }}
        </option>
      </Select>
      <FormGroupHelp color="error" v-bind="ariaMsg" gutter-bottom>{{
        errors[0]
      }}</FormGroupHelp>
    </FormGroup>
  </ValidationProvider>
</template>

<script>
import {
  FormGroup,
  FormGroupHelp,
  Select,
  Label,
} from '@campsite-bio/component-lib';
import { ValidationProvider } from 'vee-validate';
import { onMounted, ref, toRefs } from 'vue';

import { useModel, useStore } from '../../../../compositions';
import toast from '../../../../utils/toast';
import { localAxios } from '../../../../apis';

export default {
  components: {
    FormGroup,
    FormGroupHelp,
    Select,
    Label,
    ValidationProvider,
  },

  props: {
    authId: {
      type: String,
      required: true,
    },

    value: {
      type: String,
      default: null,
    },

    profileId: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const { value } = toRefs(props);
    const localValue = useModel(value, (val) => emit('input', val));
    const locations = ref([]);

    async function loadLocations() {
      try {
        const { data } = await localAxios.get(
          `/api/profiles/authorizations/${props.profileId}/${props.authId}/locations`,
        );
        locations.value = data;
      } catch (e) {
        console.error(e);
        toast.error('There was an error trying to load your Square locations.');
      }
    }

    onMounted(() => {
      loadLocations();
    });

    return { localValue, locations };
  },
};
</script>

<style lang="scss" scoped></style>
