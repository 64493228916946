<template>
  <div class="submenu__item">
    <component
      :is="component"
      class="submenu__item__link"
      :class="{ 'submenu__item__link--active-on-match': activeOnMatch }"
      :type="component === 'button' ? 'button' : null"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot />
      <span v-if="hasArrow" class="submenu__item__arrow">
        <ChevronIcon direction="right" />
      </span>
    </component>
  </div>
</template>

<script>
import { ChevronIcon } from '@campsite-bio/component-lib';
import { computed } from 'vue';

export default {
  components: {
    ChevronIcon,
  },

  props: {
    hasArrow: {
      type: Boolean,
      default: true,
    },

    /** Show active styles when matching URL, doesn't have to be exact */
    activeOnMatch: Boolean,
  },

  setup(props, { attrs }) {
    const component = computed(() => {
      if (attrs.to) return 'router-link';
      if (attrs.href) return 'a';
      return 'button';
    });

    return {
      component,
    };
  },
};
</script>

<style lang="scss" scoped>
.submenu__item {
  display: inline-flex;

  @media (min-width: 992px) {
    display: revert;
  }

  &__arrow {
    display: none;
    font-size: 1.25em;
    margin-top: 0.05em;

    @media (min-width: 992px) {
      display: flex;
    }
  }

  &__link {
    background: none;
    border: none;
    align-items: center;
    border-radius: 0;
    color: var(--g-color-gray-700);
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    color: var(--g-color-gray-700);
    display: inline-flex;
    font-weight: 700;
    font-size: 0.85rem;
    padding: 0 0.75rem;
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      transform: translate(-50%, 100%);
      left: 50%;
      background: var(--g-color-primary);
      opacity: 0;
      border-radius: 50%;
      width: 1.75em;
      height: 0.65em;
      transition: all var(--g-transition-speed);
    }

    &:hover,
    &:focus {
      background: var(--c-menu-active-color);
    }

    &.router-link-exact-active {
      &:after {
        opacity: 0.75;
        transform: translate(-50%, 50%);
      }
    }

    @media (min-width: 992px) {
      background: none;
      display: flex;
      gap: 0.15rem;
      padding: 0.25rem 0;
      text-decoration: none;
      font-size: 0.9rem;
      font-weight: 600;

      &:after {
        display: none;
      }

      &.router-link-exact-active,
      &.submenu__item__link--active-on-match.router-link-active,
      &:hover,
      &:focus {
        background: none;
        color: var(--g-color-primary);
      }

      &.router-link-exact-active,
      &.submenu__item__link--active-on-match.router-link-active {
        .submenu__item__arrow {
          display: none;
        }
      }
    }
  }
}
</style>
