<template>
  <LinkExpander
    :id="`embed-link-${id}`"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template slot="header"
      >{{ embedOptions.name }} options

      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <IconButton
            :href="helpUrl"
            target="_blank"
            aria-label="Embed help"
            size="1.25rem"
            v-bind="attrs"
            v-on="on"
          >
            <QuestionCircleOIcon title="help" />
          </IconButton>
        </template>
        <span>Get some help with your embed</span>
      </v-tooltip>
    </template>

    <EmbedOptions
      v-model="embedType"
      :embed-type="embedOptions.id"
      :source-name="embedOptions.name"
      :embed-options="embedOptions.embedTypes"
    />
  </LinkExpander>
</template>

<script>
import { IconButton, QuestionCircleOIcon } from '@campsite-bio/component-lib';
import LinkExpander from './link-expander.vue';
import { computed, inject, toRefs } from 'vue';
import { useModel } from '../../../compositions';
import EmbedOptions from '../components/embed-options.vue';

export default {
  components: {
    LinkExpander,
    IconButton,
    QuestionCircleOIcon,
    EmbedOptions,
  },

  props: {
    isOpen: Boolean,
    embedOptions: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { value, embedOptions } = toRefs(props);
    const { id } = inject('link');

    const embedType = useModel(value, (val) => emit('input', val));

    const helpUrl = computed(() => {
      return embedOptions.value.type === 'video'
        ? 'https://support.campsite.bio/en/articles/6827355-adding-a-video-to-your-profile'
        : 'https://support.campsite.bio/en/articles/6827286-embedding-content-on-your-profile';
    });

    return {
      id,
      embedType,
      helpUrl,
    };
  },
};
</script>

<style lang="scss" scoped></style>
