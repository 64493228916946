<template>
  <v-menu max-height="400">
    <template #activator="{ on, attrs }">
      <Button variant="flat" v-bind="attrs" v-on="on">
        <slot />&nbsp;
        <ChevronIcon />
      </Button>
    </template>
    <List>
      <ListItem
        v-for="{ name, value } in items"
        :key="value"
        component="button"
        @click="selected = value"
      >
        <ListItemTitle>{{ name }}</ListItemTitle>
      </ListItem>
    </List>
  </v-menu>
</template>

<script>
import { toRefs } from 'vue';
import {
  Button,
  ChevronIcon,
  List,
  ListItem,
  ListItemTitle,
} from '@campsite-bio/component-lib';
import { useModel } from '../../../compositions';

export default {
  components: {
    Button,
    ChevronIcon,
    List,
    ListItem,
    ListItemTitle,
  },

  props: {
    value: {
      type: String,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const selected = useModel(value, (val) => emit('input', val));

    return { selected };
  },
};
</script>

<style lang="scss" scoped></style>
