<template>
  <Fragment>
    <NotFound
      v-if="is404"
      title="Profile not found"
      description="The profile wasn't found or you don't have access to it."
    >
      <InlineLink slot="actions" to="/profiles">View your profiles</InlineLink>
    </NotFound>
    <template v-else>
      <ProfileMenu
        :is-loading="!hasProfile && isLoadingProfile"
        :hide="hideProfileMenu"
      />
      <router-view :profile-menu-hidden="hideProfileMenu" />
      <CheckedOutDialog v-if="canCheckout" @take-over="takeOver" />
      <QrCodeDialog />
    </template>
  </Fragment>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue';
import { InlineLink, Fragment } from '@campsite-bio/component-lib';

import {
  useNavbarScroll,
  useProfileCheckOut,
  useRoute,
  useStore,
} from '@/compositions';
import ProfileMenu from '../components/layout/profile-menu';
import { QrCodeDialog, CheckedOutDialog } from '../components/dialogs';
import NotFound from '../components/not-found';

export default {
  components: {
    ProfileMenu,
    Fragment,
    QrCodeDialog,
    CheckedOutDialog,
    NotFound,
    InlineLink,
  },

  setup() {
    const is404 = ref(false);
    const hideProfileMenu = useNavbarScroll({ scrollOffset: 50 });
    const route = useRoute();
    const store = useStore();
    const { canCheckout } = useProfileCheckOut();

    const hasProfile = computed(() => store.getters['profiles/hasProfile']);
    const isLoadingProfile = computed(
      () => store.getters['profiles/isLoadingProfile'],
    );
    const links = computed(() => store.getters['links/links']);

    const isIdle = computed(() => {
      return store.getters.getField('isIdle');
    });
    const isDocumentVisible = computed(() => {
      return store.getters.getField('isDocumentVisible');
    });
    const lastSyncedAt = computed(() => {
      return store.getters['profiles/getField']('lastSyncedAt');
    });

    watch(
      route,
      () => {
        checkAndChangeCurrentProfile();
      },
      { immediate: true },
    );

    // Reload data when user becomes idle
    watch(isIdle, async (value) => {
      if (value) {
        await reloadProfile();
        reloadAllLinks();
      }
    });
    // Reload data if it's been 5 minutes since last sync
    watch(isDocumentVisible, async (value) => {
      const now = new Date();
      if (now - lastSyncedAt.value > 1000 * 60 * 5) {
        await reloadProfile();
        reloadAllLinks();
      }
    });

    async function reloadProfile() {
      if (store.getters['profiles/isLoadingProfile'] || is404.value) return;

      try {
        // refresh campsite settings
        await store.dispatch('profiles/get');
      } catch (e) {
        console.error(e);
        // Redirect to profiles page if we get a 404 on their profile
        if (
          e.response &&
          (e.response.status === 404 || e.response.status === 401)
        ) {
          is404.value = true;
          store.dispatch('unsetProfile');
        }
      }
    }

    async function reloadAllLinks() {
      if (store.getters['links/isLoadingLinks'] || is404.value) return;

      const linksLength = links.value.length;
      const linksPage = store.getters['links/linksPage'];
      try {
        // refresh current links
        await store.dispatch('links/getLinks', {
          force: true,
          page: 1,
          pageSize: Math.max(linksLength, 20),
          setIgnoreChanges: true,
          merge: true,
        });
        // make sure links page is still correct
        store.commit('links/setLinksPage', linksPage);
      } catch (e) {
        console.error(e);
      }
    }

    // Check if the currently profile URL is the same as the one in the store
    async function checkAndChangeCurrentProfile() {
      const routeId = route.value.params.id;
      const currentId = store.getters['profiles/currentProfileId'];

      if (routeId !== currentId) {
        store.dispatch('switchProfile', routeId);
      }
    }

    async function init() {
      await checkAndChangeCurrentProfile();
      reloadProfile();
      // Get the links for the profile
      store.dispatch('links/getLinks');

      // Update last accessed when a profile is first loaded
      store.dispatch(
        'profiles/touch',
        store.getters['profiles/currentProfileId'],
      );
    }

    onMounted(() => {
      init();
    });

    function takeOver() {
      reloadProfile();
      reloadAllLinks();
    }

    return {
      hideProfileMenu,
      hasProfile,
      isLoadingProfile,
      canCheckout,
      takeOver,
      is404,
    };
  },

  head: {
    title: 'Profile',
  },
};
</script>

<style lang="scss" scoped></style>
