<template>
  <Fragment>
    <CardText>
      <VText gutter-bottom
        >Transactions will incur fees from both Square and Campsite.bio.</VText
      >
      <VText gutter-bottom>
        <strong>Square Fees:</strong><br />
        New Square account fees are free up to $5,000 for the first 180 days.
        <a
          href="https://squareup.com/help/us/en/article/6109-fees-and-payments-faqs"
          target="_blank"
          >Learn more about Square fees</a
        >.
      </VText>
      <VText>
        <strong>Campsite.bio Fees:</strong><br />
        Pro+ - 0.5%<br />
        Pro - 2%<br />
        Free - 8%
      </VText>
    </CardText>
    <CardActions>
      <Button variant="flat" @click="next">Next</Button>
    </CardActions>
  </Fragment>
</template>

<script>
import {
  CardText,
  VText,
  CardActions,
  Button,
} from '@campsite-bio/component-lib';
import { inject } from 'vue';
import { Fragment } from 'vue-fragment';

export default {
  components: {
    CardText,
    CardActions,
    VText,
    Button,
    Fragment,
  },

  setup() {
    const { handleStepChange } = inject('multi-step-dialog');

    function next() {
      handleStepChange('square-auth');
    }

    return { next };
  },
};
</script>

<style lang="scss" scoped></style>
