<template>
  <SettingsSection>
    <SettingsSectionHeader>
      <SettingsSectionTitle>Your Subscription</SettingsSectionTitle>
      <Button
        slot="btn"
        variant="outline"
        size="small"
        to="/account/change-plan"
        >Change Plan</Button
      >
    </SettingsSectionHeader>

    <SubscriptionDetailsCard
      v-bind="subscriptionCardProps"
      :number-of-users="numberOfUsers"
      :number-of-profiles="numberOfProfiles"
    />
  </SettingsSection>
</template>

<script>
import { Button } from '@campsite-bio/component-lib';

import {
  SettingsSection,
  SettingsSectionTitle,
  SettingsSectionHeader,
} from '../settings-section';
import SubscriptionDetailsCard from '../billing/subscription-details-card';
import { useCurrentUserSubscription } from '@/compositions';

export default {
  components: {
    Button,
    SettingsSection,
    SettingsSectionTitle,
    SettingsSectionHeader,
    SubscriptionDetailsCard,
  },

  setup() {
    const { subscriptionCardProps, numberOfProfiles, numberOfUsers } =
      useCurrentUserSubscription();

    return {
      subscriptionCardProps,
      numberOfProfiles,
      numberOfUsers,
    };
  },
};
</script>

<style lang="scss" scoped></style>
