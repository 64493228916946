<template>
  <ChartCard
    class="goals-card"
    title="Your Goals"
    :is-loading="isLoading"
    :padding-bottom="false"
  >
    <SimpleTable fixed-header gutter-top>
      <thead>
        <tr>
          <th>Goal</th>
          <th>Per</th>
          <th>Account</th>
          <th>Set on</th>
          <th>Streak</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-if="isLoading">
          <tr v-for="i in 3" :key="i">
            <td><Skeleton width="120px" /></td>
            <td><Skeleton width="30px" /></td>
            <td><Skeleton width="30px" /></td>
            <td><Skeleton width="30px" /></td>
            <td><Skeleton width="30px" /></td>
            <td><Skeleton width="30px" /></td>
          </tr>
        </template>
        <template v-else-if="goals.length === 0">
          <GoalsTableItem
            key="example-item"
            hide-actions
            :item="{
              channel: 'instagram',
              metric: 'posts',
              frequency: 'week',
              tally: 3,
              account_name: 'myusername',
              created_at: new Date().toISOString(),
            }"
          />
          <tr key="example">
            <td colspan="5">
              Above is an example goal. Add your own using the "Add a goal"
              button above.
            </td>
          </tr>
        </template>
        <GoalsTableItem
          v-for="goal in goals"
          :key="goal._id.$oid"
          :item="goal"
          @delete="deleteGoal"
        />
      </tbody>
    </SimpleTable>
  </ChartCard>
</template>

<script>
import { Skeleton, SimpleTable } from '@campsite-bio/component-lib';
import { onMounted, onUnmounted, ref } from 'vue';
import { DateTime } from 'luxon';

import { localAxios } from '../../../../apis';
import { ChartCard } from '../../cards';
import GoalsTableItem from './goals-table-item';
import bus from '../../../../bus';
import toast from '../../../../utils/toast';

export default {
  components: {
    ChartCard,
    Skeleton,
    SimpleTable,
    GoalsTableItem,
  },

  setup() {
    const isLoading = ref(false);
    const goals = ref([]);

    async function load() {
      isLoading.value = true;
      try {
        const { data } = await localAxios.get('/api/goals');
        goals.value = data;
      } catch (e) {
        console.error(e);
        toast.error('Failed to load goals');
      }
      isLoading.value = false;
    }

    async function deleteGoal(goal) {
      load();
    }

    onMounted(() => {
      load();

      bus.$on('goal:created', () => {
        load();
      });
    });

    onUnmounted(() => {
      bus.$off('goal:created');
    });

    return {
      isLoading,
      goals,
      load,
      DateTime,
      deleteGoal,
    };
  },
};
</script>

<style lang="scss" scoped>
.goals-card {
  min-height: unset;
}
</style>
