import { onMounted, onBeforeUnmount, ref, computed } from 'vue';

export function useAudio({ emit }) {
  const audioRef = ref(null);
  const time = ref(0);
  const duration = ref(0);
  const isPlaying = ref(false);
  const isLoading = ref(false);
  const hasLoaded = ref(false);

  const position = computed(() => {
    if (duration.value === 0) {
      return 0;
    }

    return 100 * (time.value / duration.value);
  });

  const timelineOn = {
    change(e) {
      const time = (e.target.value / 100) * duration.value;
      seek(time);
    },
  };

  const timeLeft = computed(() => {
    const timeLeft = duration.value - time.value;
    const minutes = Math.floor(timeLeft / 60);
    const seconds = Math.floor(timeLeft % 60);

    return `${duration.value !== timeLeft ? '-' : ' '}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`;
  });

  async function play() {
    audioRef.value.play();
    isPlaying.value = true;
    emit('play');
  }

  function pause() {
    audioRef.value.pause();
    isPlaying.value = false;
    emit('pause');
  }

  function toggle() {
    if (isPlaying.value) {
      pause();
    } else {
      play();
    }
  }

  function stop() {
    pause();
    audioRef.value.currentTime = 0;
  }

  function seek(time) {
    audioRef.value.currentTime = time;
  }

  function back15() {
    audioRef.value.currentTime -= 15;
  }

  function forward15() {
    audioRef.value.currentTime += 15;
  }

  function handleTimeUpdate() {
    time.value = audioRef.value.currentTime;
  }

  function handleDurationChange() {
    duration.value = audioRef.value.duration;
  }

  function handleLoadStart() {
    isLoading.value = true;
  }

  function handleCanPlay() {
    isLoading.value = false;
    hasLoaded.value = true;
  }

  onMounted(() => {
    audioRef.value.addEventListener('timeupdate', handleTimeUpdate);
    audioRef.value.addEventListener('durationchange', handleDurationChange);
    // audioRef.value.addEventListener('loadstart', handleLoadStart);
    // audioRef.value.addEventListener('canplay', handleCanPlay);
  });

  onBeforeUnmount(() => {
    audioRef.value.removeEventListener('timeupdate', handleTimeUpdate);
    audioRef.value.removeEventListener('durationchange', handleDurationChange);
    // audioRef.value.removeEventListener('loadstart', handleLoadStart);
    // audioRef.value.removeEventListener('canplay', handleCanPlay);
  });

  return {
    audioRef,
    time,
    duration,
    isPlaying,
    play,
    pause,
    toggle,
    stop,
    seek,
    back15,
    forward15,
    position,
    timelineOn,
    timeLeft,
    isLoading,
  };
}
