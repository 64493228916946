<template>
  <div class="style-group" role="listbox" :aria-label="ariaLabel">
    <slot />
  </div>
</template>

<script>
import { useGroup } from '@campsite-bio/component-lib';
import { toRefs } from 'vue';

export default {
  props: {
    value: {
      type: [Array, Number, String],
      default: null,
    },

    ariaLabel: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);

    useGroup(value, (val) => emit('input', val), {
      multiple: false,
      groupName: 'style-group',
    });
  },
};
</script>

<style lang="scss" scoped>
.style-group {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}
</style>
