<template>
  <div class="button-group">
    <slot />
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.button-group {
  display: inline-flex;

  &::v-deep .v-button {
    margin-right: 1px;
    border-radius: 0;
    flex-shrink: 0;

    &:first-child {
      border-bottom-left-radius: var(--g-border-radius-standard);
      border-top-left-radius: var(--g-border-radius-standard);
    }

    &:last-child {
      margin-right: 0;
      border-bottom-right-radius: var(--g-border-radius-standard);
      border-top-right-radius: var(--g-border-radius-standard);
    }
  }
}
</style>
