/**
 * Validate a subset of URLs, that:
 * - is a valid URL (and absolute, because we provide no base URL)
 * - has a TLD
 * - has a protocol of http: or https:
 *
 * NOTE: A URL not having a TLD or http(s) as the protocol does not make it an **invalid** URL.
 * That is a deliberate restriction that our app imposes, on top of being a valid URL.
 *
 * @see https://url.spec.whatwg.org/#urls
 */
export function isValidUrl(value, options = {}) {
  const {
    protocols = ['https:', 'http:'],
    checkTld = true,
    checkIpAddress = true,
  } = options;

  let url;
  try {
    url = new URL(value);
  } catch (err) {
    // Invalid URL; maybe there is no protocol, or it is relative, or any of the many other possible reasons
    return { isAccepted: false, reason: 'Invalid URL' };
  }

  const isLink = url.protocol === 'http:' || url.protocol === 'https:';

  // Check whether the protocol is http or https, and reject otherwise
  if (protocols && protocols.indexOf(url.protocol) === -1) {
    return { isAccepted: false, reason: 'Protocol not valid' };
  }

  // Check if there is a TLD
  // (Not sure if this check offers anything in for machines, but it can be good to give hints to a user)
  if (isLink) {
    if (checkTld && url.origin.split('.').length === 1) {
      return { isAccepted: false, reason: 'No TLD' };
    }
    if (
      checkIpAddress &&
      /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi.test(
        url.host,
      )
    ) {
      return { isAccepted: false, reason: 'No IP addresses' };
    }
  }

  return { isAccepted: true, url, isLink };
}
