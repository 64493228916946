<template>
  <section class="profiles__list">
    <div class="profiles__list__header">
      <div>
        <div class="profiles__list__title-wrapper">
          <VText class="profiles__list__title" component="h2" variant="h4">
            {{ title }}
          </VText>
          <CircularLoader v-show="isLoading" size="1.25rem" />
        </div>
        <VText v-if="subtitle" class="profiles__list__subtitle">
          {{ subtitle }}
        </VText>
      </div>

      <FlexSpacer />

      <div class="profiles__list__header__actions">
        <Input
          v-if="canSearch"
          v-model="search"
          class="profiles__list__search"
          placeholder="username"
        >
          <SearchIcon slot="prepend" />
        </Input>
        <v-menu v-if="canSort">
          <template #activator="{ on, attrs }">
            <IconButton v-bind="attrs" v-on="on">
              <SortIcon />
            </IconButton>
          </template>

          <List>
            <ListItem component="button" @click="sortLocal = null">
              <ListItemIcon>
                <CheckIcon v-show="!sortLocal" />
              </ListItemIcon>
              <ListItemTitle>Last Accessed</ListItemTitle>
            </ListItem>
            <ListItem component="button" @click="sortLocal = 'username'">
              <ListItemIcon>
                <CheckIcon v-show="sortLocal === 'username'" />
              </ListItemIcon>
              <ListItemTitle>Username</ListItemTitle>
            </ListItem>
          </List>
        </v-menu>
      </div>
    </div>
    <List class="profiles__list__list">
      <template v-if="isLoading && (!profiles || profiles.length === 0)">
        <template v-for="i in loaderCount">
          <ListItem :key="`list${_uid}${i}`">
            <ListItemAvatar>
              <Skeleton circle height="40px" width="40px" block />
            </ListItemAvatar>

            <ListItemContent>
              <ListItemTitle><Skeleton width="100px" /></ListItemTitle>
            </ListItemContent>
          </ListItem>
          <Divider
            v-if="i < loaderCount"
            :key="`divider${_uid}${i}`"
            color="gray200"
            :thickness="1"
          />
        </template>
      </template>
      <ListItem v-else-if="profiles.length === 0">
        <ListItemTitle>We don't have any profiles to show here.</ListItemTitle>
      </ListItem>
      <template v-for="(campsite, index) in profiles" v-else>
        <CampsiteSelectorListItem
          :key="`item${campsite.username}`"
          :campsite="campsite"
          :can-enable="canEnable"
          :can-delete="canDelete"
          @clone="$emit('clone', $event)"
          @enabled="$emit('enabled', $event)"
        />
        <Divider
          v-if="index < profiles.length - 1"
          :key="`divider${campsite.username}`"
          color="gray200"
          :thickness="1"
        />
      </template>
      <slot />
    </List>
  </section>
</template>

<script>
import {
  VText,
  Divider,
  Skeleton,
  List,
  ListItem,
  ListItemTitle,
  ListItemAvatar,
  ListItemContent,
  ListItemIcon,
  CheckIcon,
  Input,
  SearchIcon,
  FlexSpacer,
  IconButton,
  SortIcon,
  CircularLoader,
} from '@campsite-bio/component-lib';
import CampsiteSelectorListItem from './campsite-selector-list-item';
import { toRefs } from 'vue';

import { useModel } from '../../compositions';

export default {
  components: {
    VText,
    Divider,
    CampsiteSelectorListItem,
    Skeleton,
    List,
    ListItem,
    ListItemTitle,
    ListItemAvatar,
    ListItemContent,
    ListItemIcon,
    CheckIcon,
    Input,
    SearchIcon,
    FlexSpacer,
    IconButton,
    SortIcon,
    CircularLoader,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      default: null,
    },

    profiles: {
      type: Array,
      default() {
        return [];
      },
    },

    isLoading: Boolean,
    canEnable: Boolean,
    canDelete: Boolean,
    canSearch: Boolean,
    canSort: Boolean,
    value: {
      type: String,
      default: '',
    },
    sort: {
      type: String,
      default: null,
    },
    loaderCount: {
      type: Number,
      default: 3,
    },
  },

  setup(props, { emit }) {
    const { value, sort } = toRefs(props);
    const search = useModel(value, (val) => emit('input', val));
    const sortLocal = useModel(sort, (val) => emit('update:sort', val));

    return { search, sortLocal };
  },
};
</script>

<style lang="scss" scoped>
.profiles__list {
  &__title-wrapper {
    display: flex;
    align-items: center;
  }
}

.profiles__list__header {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  margin-bottom: 0.5rem;

  &__actions {
    align-items: center;
    display: flex;
    gap: 0.75rem;
  }

  @media (min-width: 544px) {
    align-items: center;
    flex-direction: row;
  }
}

.profiles__list__title.profiles__list__title {
  flex-shrink: 0;
  margin-right: 0.5rem;
}

.profiles__list__subtitle.profiles__list__subtitle {
  font-size: 0.825rem;
  opacity: 0.8;
  margin-top: 0.15rem;
}

.profiles__list__search.profiles__list__search {
  @media (min-width: 544px) {
    margin-left: 1rem;
    max-width: 200px;
  }
}

.profiles__list__list.profiles__list__list {
  border: 1px solid var(--g-color-gray-200);
  padding: 0;
  border-radius: var(--g-border-radius-standard);
  border-radius: min(
    var(--g-border-radius-standard),
    var(--g-border-radius-min)
  );
}
</style>
