import { computed } from 'vue';

import { useMeta, useStore } from '../../compositions';

export function useEnableLinksTour() {
  const store = useStore();
  const user = computed(() => store.getters.currentUser);
  const tours = useMeta('tours', {
    defaultValue: {},
    itemRef: user,
  });

  function tryToRun() {
    const hasTakenTour = tours.value.enable_links;
    if (!hasTakenTour) {
      import('../enable-links').then((Tour) => {
        new Tour.default();
      });
    }
  }

  return tryToRun;
}
