export const EMBED_TYPES = [
  {
    source: 'apple',
    name: 'Apple Music',
    iconComponent: 'AppleIcon',
    color: '#ff453a',
    placeholder:
      'https://music.apple.com/us/album/what-it-means-to-be-king/1611984334',
    matchesMultiple: true,
  },
  {
    source: 'apple_podcast',
    name: 'Apple Podcast',
    iconComponent: 'ApplePodcastIcon',
    color: '#B150E2',
    placeholder:
      'https://podcasts.apple.com/us/podcast/the-holderness-family-podcast/id1378725018',
  },
  {
    source: 'facebook_video',
    name: 'Facebook',
    iconComponent: 'FacebookLetterFIcon',
    color: '#4267b2',
    placeholder:
      'https://www.facebook.com/100044534315153/videos/508382850175969',
  },
  {
    source: 'spotify',
    name: 'Spotify',
    iconComponent: 'SpotifyIcon',
    color: '#1DB954',
    placeholder:
      'https://open.spotify.com/track/2EtwxAprswAqDZ3uRq8pr8?si=c6dd23e1a90b4c38',
    matchesMultiple: true,
  },
  {
    source: 'soundcloud',
    name: 'SoundCloud',
    iconComponent: 'SoundcloudIcon',
    color: '#ff5500',
    placeholder:
      'https://soundcloud.com/tomsize/twenty-one-pilots-stressed-out-tomsize-remix',
    matchesMultiple: true,
  },
  {
    source: 'twitch',
    name: 'Twitch',
    iconComponent: 'TwitchIcon',
    color: '#6441a5',
    placeholder: 'https://www.twitch.tv/videos/984867870',
    matchesMultiple: true,
  },
  {
    source: 'vimeo_video',
    name: 'Vimeo',
    iconComponent: 'VimeoIcon',
    color: '#86c9ef',
    placeholder: 'https://vimeo.com/534526174',
  },
  {
    source: 'youtube',
    name: 'Youtube',
    iconComponent: 'YoutubeIcon',
    color: '#c4302b',
    placeholder: 'https://www.youtube.com/watch?v=DHEERJKqLzA',
  },
];

export const EMBED_TYPES_WITH_CLICKS = [
  'youtube',
  'twitch',
  'vimeo_video',
  'facebook_video',
];
