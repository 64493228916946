<template>
  <div class="filters">
    <ChipGroup aria-label="Filters" :selectable="false">
      <Chip
        v-for="(filter, i) in filters"
        :key="i"
        label
        close
        :disabled="isLoadingFilters || disabled"
        selectable
        @click="handleFilterClick(filter)"
        @click:close="removeFilter(filter)"
      >
        {{ filter.filter.name }} {{ filter.operator.label.toLowerCase() }}&nbsp;
        <b :title="filter.value">{{
          truncate(filter.value, { length: 30 })
        }}</b>
      </Chip>
      <Chip
        label
        selectable
        :disabled="isLoadingFilters || disabled"
        @click="isAddOpen = true"
      >
        <PlusIcon />&nbsp;Add Filter
      </Chip>
    </ChipGroup>

    <AddFilterDialog
      v-model="isAddOpen"
      :filters="availableFilters"
      :current-filters="filters"
      @add="addFilter"
    />
    <EditFilterDialog
      v-model="isEditOpen"
      :filters="availableFilters"
      :editing-filter="selectedFilter"
      @update="updateFilter"
    />
  </div>
</template>

<script>
import { Chip, ChipGroup, PlusIcon } from '@campsite-bio/component-lib';
import { computed, ref, watch } from 'vue';
import axios from 'axios';
import { DateTime } from 'luxon';
import truncate from 'lodash/truncate';

import { useStore } from '../../../compositions';
import { AddFilterDialog, EditFilterDialog } from './add-filter-dialog';
import { useFilters } from '../compositions';

export default {
  components: {
    Chip,
    ChipGroup,
    PlusIcon,
    AddFilterDialog,
    EditFilterDialog,
  },

  props: {
    disabled: Boolean,
  },

  setup() {
    const availableFilters = ref(null);
    const { filters, addFilter, removeFilter, updateFilter } = useFilters();
    const cancelSource = ref(null);
    const isAddOpen = ref(false);
    const isEditOpen = ref(false);
    const isLoadingFilters = ref(false);
    const selectedFilter = ref(null);
    const store = useStore();
    const timezone = computed(() => DateTime.local().zoneName);
    const selectedDateFilter = computed(
      () => store.getters['analytics/selectedDateFilter'],
    );

    async function loadFilters() {
      isLoadingFilters.value = true;
      if (cancelSource.value) cancelSource.value.abort();
      cancelSource.value = new AbortController();

      try {
        const params = {};
        if (Array.isArray(selectedDateFilter.value)) {
          params.range = 'custom';
          params.start_date = selectedDateFilter.value[0];
          params.end_date = selectedDateFilter.value[1];
        } else params.range = selectedDateFilter.value;

        const data = await store.dispatch('analytics/loadFilters', {
          cancelToken: cancelSource.value.signal,
          timezone: timezone.value,
          ...params,
        });
        availableFilters.value = { ...data.filters };
      } catch (e) {
        if (axios.isCancel(e) || e.name === 'AbortError') return;
        console.error(e);
      }

      isLoadingFilters.value = false;
    }

    function handleFilterClick(filter) {
      selectedFilter.value = filter;
      isEditOpen.value = true;
    }

    watch(selectedDateFilter, loadFilters, { immediate: true });

    return {
      selectedFilter,
      filters,
      availableFilters,
      isAddOpen,
      isEditOpen,
      isLoadingFilters,
      addFilter,
      removeFilter,
      updateFilter,
      truncate,
      handleFilterClick,
    };
  },
};
</script>

<style lang="scss" scoped></style>
