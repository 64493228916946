<template>
  <GoogleV1
    v-if="googleAuth && googleAuth.is_v1_google"
    v-bind="$props"
    @change="$emit('change', $event)"
  />
  <GoogleV2 v-else v-bind="$props" @change="$emit('change', $event)" />
</template>

<script>
import { computed } from 'vue';

import { GoogleV1, GoogleV2 } from './google-versions';
import { useStore } from '@/compositions';

export default {
  components: {
    GoogleV1,
    GoogleV2,
  },

  props: {
    link: {
      type: Object,
      required: true,
    },

    type: {
      type: String,
      default: null,
    },
  },

  setup() {
    const store = useStore();

    const googleAuth = computed(() => {
      return store.getters['profiles/googleAuthorization'];
    });

    return {
      googleAuth,
    };
  },
};
</script>

<style lang="scss" scoped></style>
