<template>
  <component
    :is="component"
    class="link-btn"
    :class="classes"
    type="button"
    v-bind="$attrs"
    :style="styles"
    :aria-controls="ariaControls"
    :aria-expanded="ariaControls ? active.toString() : null"
    v-on="$listeners"
  >
    <span v-if="$slots.icon" class="link-btn__icon">
      <slot name="icon" />
    </span>
    <span v-if="$slots.stat" class="link-btn__stat">
      <slot name="stat" />
    </span>
    <span v-if="$slots.default" class="link-btn__text"><slot /></span>
    <span v-if="badge" class="link-btn__badge">
      <Badge :content="badge" />
    </span>
  </component>
</template>

<script>
import { computed, useSlots } from 'vue';
import { Badge, getThemeColor, useTheme } from '@campsite-bio/component-lib';
import tinycolor from 'tinycolor2';

export default {
  components: {
    Badge,
  },

  props: {
    component: {
      type: String,
      default: 'button',
    },
    badge: {
      type: [String, Number],
      default: null,
    },
    active: Boolean,
    highlight: Boolean,
    pinned: Boolean,
    error: Boolean,
    color: {
      type: String,
      default: 'gray700',
    },
    ariaControls: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    const theme = useTheme();
    const slots = useSlots();

    const classes = computed(() => {
      return {
        'link-btn--no-btn': props.component === 'div',
        'link-btn--active': props.active,
        'link-btn--highlight': props.highlight,
        'link-btn--pinned': props.pinned,
        'link-btn--error': props.error,
        'link-btn--has-text':
          slots.default !== undefined ||
          slots.badge !== undefined ||
          slots.stat !== undefined,
      };
    });

    const styles = computed(() => {
      const themeColor = getThemeColor(props.color, theme);

      return {
        '--c-color': themeColor,
        '--c-bg-color': tinycolor(themeColor).setAlpha(0.05).toRgbString(),
        '--c-bg-active-color': tinycolor(themeColor)
          .setAlpha(0.1)
          .toRgbString(),
      };
    });

    return {
      classes,
      styles,
    };
  },
};
</script>

<style lang="scss" scoped>
.link-btn {
  align-items: center;
  background: none;
  border: none;
  border-radius: var(--g-border-radius-standard);
  color: var(--c-color);
  display: inline-flex;
  font-size: 0.925rem;
  padding: 0.3125rem 0.5rem;
  text-align: center;
  transition: all 250ms;
  position: relative;

  &:before {
    background: var(--c-bg-color);
    border-radius: 50%;
    content: '';
    left: 50%;
    height: 2.5em;
    position: absolute;
    opacity: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: inherit;
    width: 2.5em;
  }

  &:not(.link-btn--no-btn) {
    &:hover,
    &:focus,
    &:active {
      &:before {
        opacity: 1;
      }
    }
  }

  &:disabled {
    background: none;
    color: var(--g-color-gray-400);
  }

  &--has-text:not(.link-btn--no-btn) {
    &:before {
      border-radius: var(--g-border-radius-standard);
      height: calc(100% + 0.25em);
      width: calc(100% + 0.25em);
    }
  }

  &--highlight {
    &:before {
      opacity: 1;
    }
  }

  &--active,
  &--pinned {
    &:before {
      background: var(--c-bg-active-color);
      opacity: 1;
    }
  }

  &--pinned {
    .link-btn__icon {
      transform: rotate(45deg);
    }
  }

  &--restore {
    transform: rotate(180deg);
  }

  &--error {
    background: rgba($color-error, 0.1);
  }

  &__icon {
    display: inline-flex;
    font-size: 1.125rem;
    flex-shrink: 0;

    + .link-btn__text {
      margin-left: 0.35rem;
    }

    + .link-btn__stat {
      margin-left: 0.35rem;
    }
  }

  &__stat {
    opacity: 0.85;
    font-size: 0.825rem;
    align-self: flex-start;
    line-height: 1;
    margin-top: -0.1rem;
  }

  &__badge {
    font-size: 0.75rem;
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 0;
    transform: translate(40%, -40%);
  }
}
</style>
