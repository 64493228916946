<template>
  <LinkExpander
    :id="`default-${link._id.$oid}`"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template slot="header">
      Text options
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <IconButton
            href="https://support.campsite.bio/en/articles/6942781-text-link"
            target="_blank"
            aria-label="Text options help"
            size="1.25rem"
            v-bind="attrs"
            v-on="on"
          >
            <QuestionCircleOIcon />
          </IconButton>
        </template>
        <span>Get some help setting up your text block</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="form">
      <VText gutter-bottom>Options available to modify your text block.</VText>

      <FormGroup>
        <Toggle v-model="centerTextLocal"> Center text </Toggle>
      </FormGroup>

      <FormGroup>
        <Toggle v-model="removeBackgroundLocal"> Remove background </Toggle>
      </FormGroup>

      <FormGroup :gutter-bottom="isFreePlan">
        <Label component="span" block gutter-bottom>Quick markdown guide</Label>
        <pre style="margin: 0"><code>// Headers
# H1
## H2
### H3

// Lists
- unordered list
1. ordered list

// Emphasis
**bold**
*italic*
~~strikethrough~~

// Links and images (Pro only)
[link label](https://myurl.com)
![image label](https://myimage.com/image.jpg "alt text")</code></pre>
        <FormGroupHelp>
          <a
            href="https://support.campsite.bio/en/articles/6942781-text-link"
            target="_blank"
            >Learn more about markdown formatting options</a
          >
        </FormGroupHelp>
      </FormGroup>

      <Alert v-if="isFreePlan" variant="outline">
        <LightningBoltIcon slot="icon" />
        Unlock links and images inside your text block with Pro
        <Button slot="actions" to="/account/change-plan" variant="flat"
          >Upgrade</Button
        >
      </Alert>
    </ValidationObserver>
  </LinkExpander>
</template>

<script>
import {
  FormGroup,
  FormGroupHelp,
  IconButton,
  QuestionCircleOIcon,
  Toggle,
  VText,
  Alert,
  Button,
  LightningBoltIcon,
  Label,
} from '@campsite-bio/component-lib';
import { computed, nextTick, onBeforeUnmount, ref, watch } from 'vue';
import { ValidationObserver } from 'vee-validate';
import debounce from 'lodash/debounce';
import DOMPurify, { sanitize } from 'dompurify';
import marked, { parse } from 'marked';

import LinkExpander from '../expand/link-expander';
import { useStore } from '../../../compositions';

export default {
  components: {
    FormGroup,
    FormGroupHelp,
    IconButton,
    QuestionCircleOIcon,
    Toggle,
    VText,
    LinkExpander,
    ValidationObserver,
    Alert,
    Button,
    LightningBoltIcon,
    Label,
  },

  props: {
    isOpen: Boolean,
    textOptions: {
      type: Object,
      required: true,
    },
    link: {
      type: Object,
      required: true,
    },
    text: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const form = ref(null);
    const ignoreChanges = ref(false);
    const dirty = ref(false);

    const centerTextLocal = ref(null);
    const removeBackgroundLocal = ref(null);

    const isFreePlan = computed(() => {
      return store.getters['profiles/isProfileOnFreePlan'];
    });

    const tokenizer = computed(() => {
      if (isFreePlan.value) {
        return {
          lheading() {},
          link() {},
          autolink() {},
          url() {},
          reflink() {},
          image() {},
        };
      } else {
        return {
          lheading() {},
        };
      }
    });

    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
      // set all elements owning target to target=_blank
      if ('target' in node) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener ugc');
      }
    });

    watch(
      () => props.textOptions,
      async ({ text, center_text, remove_bg }) => {
        ignoreChanges.value = true;
        emit('input:text', text);
        centerTextLocal.value = center_text;
        removeBackgroundLocal.value = remove_bg;
        dirty.value = false;
        await nextTick();
        ignoreChanges.value = false;
      },
      { immediate: true },
    );

    watch(dirty, (newVal) => emit('change:dirty', newVal));

    async function save() {
      const success = await form.value?.validate();
      if (form.value && !success) return;

      marked.use({ tokenizer: tokenizer.value });

      const cleanedText = DOMPurify.sanitize(props.text, {
        USE_PROFILES: { html: false },
      });

      emit('save', {
        text: props.text,
        html: sanitize(parse(cleanedText)),
        center_text: centerTextLocal.value,
        remove_bg: removeBackgroundLocal.value,
      });

      await nextTick();
      dirty.value = false;
    }

    const saveDebounced = debounce(save, 1000);

    watch(
      () => props.text,
      () => {
        if (!ignoreChanges.value) {
          dirty.value = true;
          saveDebounced();
        }
      },
    );

    watch([centerTextLocal, removeBackgroundLocal], () => {
      if (!ignoreChanges.value) {
        dirty.value = true;
        saveDebounced();
      }
    });

    onBeforeUnmount(() => {
      dirty.value = false;
    });

    return {
      form,
      centerTextLocal,
      removeBackgroundLocal,
      isFreePlan,
    };
  },
};
</script>

<style lang="scss" scoped></style>
