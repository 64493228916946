<template>
  <ListItem class="payment-card">
    <ListItemContent>
      <ListItemTitle>
        <strong>{{ card.card.brand }} •••• {{ card.card.last4 }}</strong
        >&nbsp;&nbsp;
        <Badge v-if="isDefault" color="gray600" content="Default" />
        <Badge v-if="hasExpired" color="info" content="Expired" />
      </ListItemTitle>
      <ListItemSubtitle>Expires {{ cardExpiresDate }}</ListItemSubtitle>
    </ListItemContent>
  </ListItem>
</template>

<script>
import {
  ListItem,
  ListItemContent,
  ListItemTitle,
  ListItemSubtitle,
  Badge,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';
import { DateTime } from 'luxon';

export default {
  components: {
    ListItem,
    ListItemContent,
    ListItemTitle,
    ListItemSubtitle,
    Badge,
  },

  props: {
    card: {
      type: Object,
      required: true,
    },

    isDefault: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const cardExpiresDate = computed(() => {
      const { exp_month, exp_year } = props.card;
      return DateTime.fromObject({ month: exp_month, year: exp_year }).toFormat(
        'MMM yyyy',
      );
    });

    const hasExpired = computed(() => {
      const today = DateTime.local();
      const cardExpires = DateTime.fromObject({
        year: props.card.exp_year,
        month: props.card.exp_month,
        day: 1,
      }).endOf('month');

      return today > cardExpires;
    });

    return {
      cardExpiresDate,
      hasExpired,
    };
  },
};
</script>

<style lang="scss" scoped>
.payment-card {
  background: #fff;
  border: 1px solid var(--g-color-gray-200);
  border-radius: var(--g-border-radius-standard);
  border-radius: min(
    var(--g-border-radius-standard),
    var(--g-border-radius-min)
  );
}
</style>
