<template>
  <svg viewBox="0 0 1000 1000">
    <rect class="cls-3" x="38.5" y="69" width="923" height="152" rx="5" />
    <rect class="cls-3" x="51.5" y="91" width="923" height="152" rx="5" />
    <rect class="cls-3" x="44.5" y="80" width="923" height="152" rx="5" />
    <rect class="cls-3" x="38.5" y="69" width="923" height="152" rx="5" />
    <rect class="cls-3" x="38.5" y="69" width="923" height="152" rx="5" />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.cls-3 {
  --left: 0px;
  --top: 0px;
  animation: stacked-rect 6s ease infinite;
  fill: #ccc;
  filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));

  &:nth-child(3) {
    --left: -5px;
    --top: 167px;
  }

  &:nth-child(2) {
    --left: -12px;
    --top: 334px;
  }

  &:nth-child(1) {
    --top: 533px;
  }

  &:nth-child(4) {
    --top: 710px;
  }
}

@keyframes stacked-rect {
  25% {
    transform: none;
  }

  30%,
  100% {
    filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
    transform: translate(var(--left), var(--top));
  }

  100% {
    filter: none;
  }
}
</style>
