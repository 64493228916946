<template>
  <ChartCard
    class="leaderboard-events-card"
    title="Events Top 3"
    :is-loading="isLoading"
  >
    <ChartWrapper
      width="100%"
      height="300"
      type="bar"
      :options="chartOptions"
      :data="series"
    />
  </ChartCard>
</template>

<script>
import { computed } from 'vue';
import { ChartCard } from '..';
import { useLeaderboardChartOptions } from '../../compositions';
import { ChartWrapper } from '../../';

export default {
  components: {
    ChartCard,
    ChartWrapper,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const categories = computed(() => {
      return props.data.events.slice(0, 3).map((item) => item.occurrences);
    });

    const series = computed(() => {
      return props.data.events.slice(0, 3).reduce(
        (acc, stat, i) => {
          acc.datasets[0].data.push(stat.count);
          return acc;
        },
        {
          datasets: [
            {
              label: 'Action Score',
              data: [],
            },
          ],
          labels: categories.value,
        },
      );
    });

    const chartOptions = useLeaderboardChartOptions();

    return {
      chartOptions,
      series,
    };
  },
};
</script>

<style lang="scss" scoped>
.leaderboard-events-card {
  min-height: unset;
}
</style>
