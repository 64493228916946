<template>
  <v-app>
    <v-snackbar
      v-model="active"
      :timeout="timeout"
      :color="color"
      :multi-line="multiline"
      @click="dismiss"
    >
      {{ text }}
      <template #action="{ attrs }">
        <IconButton
          v-if="showButton"
          size="1.1rem"
          color="inherit"
          v-bind="attrs"
          aria-label="Close"
          @click="close"
        >
          <CloseIcon />
        </IconButton>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { IconButton, CloseIcon } from '@campsite-bio/component-lib';

export default {
  components: {
    IconButton,
    CloseIcon,
  },

  data() {
    return {
      active: false,
      text: '',
      color: 'info',
      timeout: 6000,
      dismissible: false,
      showButton: true,
      multiline: false,
    };
  },

  watch: {
    active(newValue, oldValue) {
      if (!newValue)
        this.$nextTick(() => {
          this.$emit('close');
        });
    },
  },

  methods: {
    show(options = {}) {
      if (this.active) {
        this.close();
        this.$nextTick(() => this.show(options));
        return;
      }

      Object.keys(options).forEach((field) => (this[field] = options[field]));

      this.active = true;
    },

    close() {
      this.active = false;
    },

    dismiss() {
      if (this.dismissible) {
        this.active = false;
      }
    },
  },
};
</script>
