import { getYoutubeId } from '@campsite-bio/component-lib';

export const DEFAULT_VIDEO_THUMBNAIL =
  'https://img.youtube.com/vi/default/maxresdefault.jpg';

export function getVideoThumbnailByUrl(url, source) {
  switch (source) {
    case 'youtube':
      const youtubeVideoId = getYoutubeId(url);
      return `https://img.youtube.com/vi/${youtubeVideoId}/0.jpg`;

    case 'vimeo':
      const vimeoId = getVimeoVideoId(url);
      return `https://vumbnail.com/${vimeoId}_medium.jpg`;

    default:
      return DEFAULT_VIDEO_THUMBNAIL;
  }
}
