<template>
  <div class="media-inner">
    <div
      v-infinite-scroll="nextPage"
      class="media-inner__content"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="20"
      infinite-scroll-immediate-check="false"
    >
      <v-media-custom-breadcrumbs
        v-if="id"
        v-model="parent"
        :path="path"
        :media-parent-id="id"
        :organization-id="organizationId"
      />
      <Divider color="lightgray" style="margin: 9px 0 12px" />

      <v-media-uploader
        v-if="id"
        :media-parent-id="id"
        :organization-id="organizationId"
      />

      <Alert
        v-if="hasRestrictions"
        variant="outline"
        style="margin-bottom: 8px"
      >
        <InfoIcon slot="icon" />
        <template v-if="options.minWidth && options.minHeight"
          >Minimum image size: {{ options.minWidth }}px X
          {{ options.minHeight }}px</template
        >
        <template v-else-if="options.minWidth"
          >Minimum image size: {{ options.minWidth }}px wide</template
        >
        <template v-else-if="options.minHeight"
          >Minimum image size: {{ options.minHeight }}px height</template
        >
      </Alert>

      <div class="media-items-row row row--no-gutters">
        <div
          v-for="item in folders"
          :key="item._id.$oid"
          class="col col--xs-6 col--no-gutters"
        >
          <v-media-folder
            :name="item.filename"
            :checked="
              selectedFolder && selectedFolder._id.$oid == item._id.$oid
            "
            @change="select(item)"
            @click="parent = item._id.$oid"
          />
        </div>
        <div
          v-for="(item, index) in images"
          :key="item._id.$oid"
          class="col col--xs-6 col--no-gutters"
        >
          <v-media-item
            ref="mediaItem"
            :checked="selected && selected.url == item.url"
            :index="index"
            :url="getCroppedMediaUrl(item.url)"
            :alt="item.filename"
            :disabled="disableCheck(item)"
            @select="select(item)"
            @double-click="doubleClick(item, index)"
          />
        </div>
      </div>

      <div v-show="loading" class="text--xs-center">
        <CircularLoader size="2.5rem" style="margin: 15px auto" />
      </div>
    </div>
    <div class="media-inner__actions">
      <Divider color="lightgray" />
      <CardActions>
        <Button
          v-show="selected || selectedFolder"
          variant="outline"
          color="error"
          :disabled="isDeleting"
          :loading="isDeleting"
          @click="deleteMedia"
          >Delete</Button
        >
        <FlexSpacer />
        <Button :disabled="!selected" @click="save">Select</Button>
      </CardActions>
    </div>
  </div>
</template>

<script>
import {
  CardActions,
  Button,
  Divider,
  FlexSpacer,
  Alert,
  InfoIcon,
  CircularLoader,
  getCroppedMediaUrl,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';

import VMediaUploader from './media-uploader.vue';
import VMediaItem from './media-item.vue';
import VMediaFolder from './media-folder.vue';
import VMediaCustomBreadcrumbs from './media-custom-breadcrumbs.vue';
import toast from '@/utils/toast';
import bus from '@/bus';
import { useCurrentProfile, useCurrentOrg, useRoute } from '@/compositions';

export default {
  components: {
    VMediaUploader,
    VMediaItem,
    VMediaFolder,
    VMediaCustomBreadcrumbs,
    CardActions,
    Button,
    FlexSpacer,
    Alert,
    InfoIcon,
    Divider,
    CircularLoader,
  },

  props: {
    options: {
      type: Object,
      default: null,
    },

    show: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { id: profileId } = useCurrentProfile();
    const { id: organizationId } = useCurrentOrg();
    const route = useRoute();

    // Determine if we could be loading org media instead of profile media
    const isOnOrg = computed(() => {
      return route.value.fullPath.includes('organization/');
    });

    const id = computed(() => {
      return isOnOrg.value ? organizationId.value : profileId.value;
    });

    return { id, organizationId, isOnOrg, getCroppedMediaUrl };
  },

  data() {
    return {
      imageUrl: '',
      loading: true,
      disableLoad: false,
      isDeleting: false,
      count: 20,
      page: 1,
      selected: undefined,
      selectedFolder: undefined,
    };
  },

  computed: {
    media() {
      return this.$store.getters['media/getField'](`media.${this.id}`);
    },

    path() {
      return this.$store.getters['media/getField'](`mediaPath.${this.id}`);
    },

    folders() {
      return this.media?.filter((item) => item.type === 'folder');
    },

    images() {
      return this.media?.filter((item) => item.type === 'image');
    },

    parent: {
      get() {
        return this.$store.getters['media/getField'](`mediaParent.${this.id}`);
      },
      set(newValue) {
        this.$store.commit('media/setMediaParent', {
          id: this.id,
          value: newValue,
        });
      },
    },

    busy() {
      return this.loading || this.disableLoad;
    },

    showMediaDialog() {
      return this.$store.getters.showMediaDialog;
    },

    hasRestrictions() {
      return this.options && (this.options.minWidth || this.options.minHeight);
    },
  },

  watch: {
    page() {
      this.load();
    },

    parent() {
      this.selectedFolder = undefined;
      this.selected = undefined;
      this.disableLoad = false;
      if (this.page === 1) this.load();
      this.page = 1;
    },

    show(newValue) {
      if (newValue) this.load();
    },

    showMediaDialog(newValue) {
      if (newValue && this.show) this.load();
    },
  },

  created() {
    this.load();
  },

  methods: {
    nextPage() {
      this.page++;
    },

    async load() {
      this.loading = true;

      try {
        const data = await this.$store.dispatch('media/all', {
          page: this.page,
          pageSize: this.count,
          parent: this.parent,
          id: this.id,
          organization_id: this.isOnOrg ? this.organizationId : null,
        });

        if (!data.has_more) this.disableLoad = true;
        this.loading = false;
      } catch (e) {
        toast.error('There was an error getting your media files');
      }

      this.loading = false;
    },

    disableCheck(media) {
      if (
        this.options &&
        this.options.minWidth &&
        media.width < this.options.minWidth
      )
        return true;
      if (
        this.options &&
        this.options.minHeight &&
        media.height < this.options.minHeight
      )
        return true;
      return false;
    },

    // if the user is clicking the same image twice, select the image
    doubleClick: function (item, index) {
      if (this.$refs.mediaItem[index].checked) {
        this.select(item);
        this.save();
      }
    },

    select(item) {
      switch (item.type) {
        case 'folder':
          this.selected = undefined;
          this.selectedFolder = item;
          break;

        default:
          this.selected = item;
          this.selectedFolder = undefined;
          break;
      }
    },

    async deleteMedia() {
      let item = this.selected ? this.selected : this.selectedFolder,
        itemId = item._id.$oid;
      this.isDeleting = true;

      try {
        await this.$store.dispatch('media/remove', {
          mediaId: item._id.$oid,
          id: this.id,
          organization_id: this.organizationId,
        });

        this.selected = undefined;
        this.selectedFolder = undefined;
        toast.success('Media item deleted', { timeout: 3000 });
        bus.$emit('media-custom-deleted', itemId);
      } catch (err) {
        console.log(err);
        toast.error('There was an error trying to delete your media item', {
          timeout: 3000,
        });
      }

      this.isDeleting = false;
    },

    save() {
      this.$emit('select', {
        source: 'custom',
        mediaId: this.selected._id.$oid,
        media: this.selected,
      });

      this.selected = undefined;
    },
  },
};
</script>

<style scoped></style>
