import { getSignedUrl } from '../utils/media';

// lazy load dropzone
export default {
  data() {
    return {
      hasDropzoneLoaded: false,
      dropzoneOptions: {
        // The URL will be changed for each new file being processing
        url: '/',

        // Since we're going to do a `PUT` upload to S3 directly
        method: 'put',

        acceptedFiles: 'image/jpeg,image/png',
        addRemoveLinks: true,

        // resize stuff to test
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        maxFilesize: 6,
        resizeWidth: 300,
        resizeHeight: 300,
        resizeQuality: 0.85,

        // Hijack the xhr.send since Dropzone always upload file by using formData
        // ref: https://github.com/danialfarid/ng-file-upload/issues/743
        sending(file, xhr) {
          const _send = xhr.send;
          xhr.send = (formData) => _send.call(xhr, formData.get('file'));
        },

        // Upload one file at a time since we're using the S3 pre-signed URL scenario
        parallelUploads: 1,
        uploadMultiple: false,
        maxFiles: 1,

        // Content-Type should be included, otherwise you'll get a signature
        // mismatch error from S3. We're going to update this for each file.
        header: '',

        // Headers
        headers: { 'Cache-Control': 'public, max-age=31536000' },

        // Customize the wording
        dictDefaultMessage: '',

        // We're going to process each file manually (see `accept` below)
        autoProcessQueue: false,
      },
    };
  },

  beforeDestroy() {
    if (this.dropzone) this.dropzone.destroy();
  },

  methods: {
    loadDropzone() {
      import('dropzone').then((Dropzone) => {
        Dropzone.default.autoDiscover = false;
        this.Dropzone = Dropzone.default;
        this.hasDropzoneLoaded = true;
      });
    },

    createDropzone(el, options = {}) {
      options = Object.assign(this.dropzoneOptions, options);
      // Instantiate Dropzone
      this.dropzone = new this.Dropzone(el, options);
    },

    async getSignedUrl(file, path) {
      const url = await getSignedUrl(path, file.type);

      file.uploadURL = url;
      file.path = path;
      return url;
    },
  },
};
