<template>
  <Card class="v-chart-card" v-bind="$attrs">
    <CardText class="v-chart-card__inner" :style="innerStyles">
      <div class="v-chart-card__title">
        <div class="v-chart-card__title__content">
          <VText
            class="v-chart-card__title__text"
            component="h2"
            variant="h5"
            :color="titleColor"
            >{{ title }}</VText
          >
          <VText
            v-if="subtitle"
            class="v-chart-card__title__subtitle"
            :color="subtitleColor"
            >{{ subtitle }}</VText
          >
        </div>
        <span v-if="helpText" class="v-chart-card__help">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <QuestionCircleOIcon title="help" v-bind="attrs" v-on="on" />
            </template>
            <span>{{ helpText }}</span>
          </v-tooltip>
        </span>
        <span v-if="$slots.badge" class="v-chart-card__badge">
          <slot name="badge" />
        </span>

        <span v-if="isLoading" class="v-chart-card__loader">
          <CircularLoader size="1.5em" />
        </span>
      </div>
      <div class="v-chart-card__content">
        <div v-if="highlights" class="v-chart-card__numbers">
          <InteractiveChartCardNumber
            v-for="(highlight, index) in highlights"
            :key="`a${index}`"
            v-bind="highlight"
          />
        </div>
        <slot />
        <slot name="after" />
      </div>
      <div v-if="timestamp" class="v-chart-card__timestamp">
        <VText :color="timestampColor" component="span" variant="inherit"
          >Last updated {{ timeAgo }}</VText
        >
      </div>
    </CardText>
    <template v-if="$slots.actions">
      <Divider class="print-hide" color="gray100" />
      <CardActions class="v-chart-card__actions print-hide" align="left">
        <slot name="actions" />
      </CardActions>
    </template>

    <div v-if="disabled" class="v-chart-card__disabled">
      <slot name="disabled" />
    </div>
  </Card>
</template>

<script>
import {
  Card,
  CardText,
  CardActions,
  VText,
  CircularLoader,
  QuestionCircleOIcon,
  useTheme,
  Divider,
} from '@campsite-bio/component-lib';
import { computed, toRefs } from 'vue';
import { useTimeAgo } from '@vueuse/core';

import InteractiveChartCardNumber from './interactive-chart-card-number';

export default {
  components: {
    Card,
    CardText,
    CardActions,
    VText,
    CircularLoader,
    QuestionCircleOIcon,
    Divider,
    InteractiveChartCardNumber,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
    },

    helpText: {
      type: String,
      default: null,
    },
    timestamp: Date,

    disabled: Boolean,
    isLoading: Boolean,
    paddingBottom: {
      type: Boolean,
      default: true,
    },
    highlights: {
      type: Array,
      default: null,
    },
  },

  setup(props) {
    const theme = useTheme();
    const { timestamp } = toRefs(props);
    const timeAgo = useTimeAgo(timestamp);

    const innerStyles = computed(() => {
      return {
        'padding-bottom': props.paddingBottom ? null : 0,
      };
    });

    return {
      titleColor: theme.value.colors.gray800,
      subtitleColor: theme.value.colors.gray400,
      timestampColor: theme.value.colors.gray800,
      timeAgo,
      innerStyles,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-chart-card {
  flex-direction: column;
  min-height: 486px;
  height: 100%;
  max-width: 100%;

  @media print {
    break-inside: avoid;
    border: none !important;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__title {
    align-items: center;
    display: flex;
    position: relative;
  }

  &__loader {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &__badge,
  &__help {
    align-items: center;
    display: inline-flex;
    margin-left: 0.5rem;

    svg {
      display: block;
    }
  }

  &__help {
    @media print {
      display: none;
    }
  }

  &__content {
    flex-grow: 1;
    position: relative;
    height: 100%;
  }

  &__numbers {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.5rem;
    gap: 1rem;
    margin-bottom: 1rem;

    @media (min-width: 544px) {
      font-size: 1.75rem;
    }
  }

  &__timestamp {
    font-size: 0.75rem;
    padding-top: 1rem;
    margin-bottom: -0.5rem;
  }

  &__disabled {
    background: rgba(0, 0, 0, 0.4);
    color: white;
    font-weight: 700;
    font-size: 1.125rem;
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.v-chart-card.v-chart-card {
  display: flex;
}

.v-chart-card__title__text.v-chart-card__title__text {
  font-size: 1rem;
  // margin-top: -0.25rem;
}

.v-chart-card__title__subtitle.v-chart-card__title__subtitle {
  font-size: 0.85rem;
  font-weight: 400;
  margin-top: 0.25rem;
}

.v-chart-card__actions.v-chart-card__actions {
  padding: 0;
}
</style>
