<template>
  <CountriesTable
    :is-loading="isLoading"
    :data="countryData"
    :has-results="hasResults"
    :data-title="startCase(type)"
  />
</template>

<script>
import { computed } from 'vue';
import startCase from 'lodash/startCase';

import flags from '../../../emojis/flags.json';
import { CountriesTable } from '../tables';

export default {
  components: {
    CountriesTable,
  },

  props: {
    allData: {
      type: Object,
      default: null,
    },

    data: {
      type: Array,
      default: null,
    },

    isLoading: Boolean,

    /**
     * events|views
     */
    type: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const countryData = computed(() => {
      if (!props.data) return [];

      const stats = props.data.reduce((acc, { _id, count, unique }) => {
        const flag = flags.find((flag) => flag.name === _id);
        acc.push([_id, `${flag ? `${flag.emoji} ` : ''}${_id}`, count, unique]);
        return acc;
      }, []);
      return stats.sort((a, b) => b[2] - a[2]).slice(0, 10);
    });

    const hasResults = computed(() => countryData.value.length > 0);

    return { countryData, hasResults, startCase };
  },
};
</script>

<style lang="scss" scoped></style>
