<template>
  <div class="no-results">
    <VText component="p" variant="h5" gutter-bottom>Nothing to show here</VText>
    <VText>Check back in tomorrow</VText>
  </div>
</template>

<script>
import { VText } from '@campsite-bio/component-lib';

export default {
  components: {
    VText,
  },
};
</script>

<style lang="scss" scoped>
.no-results {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
</style>
