<template>
  <div class="link__field">
    <slot />
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
.link__field {
  margin-bottom: 0.375rem;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
