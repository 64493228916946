export * from './analytics';
export * from './axios';
export * from './user-access';
export * from './email-platforms';
export * from './billing';

import store from '../store/';
import { handleStatus } from '../utils/';

function formatOptions(options) {
  if (options.credentials === 'none') delete options.credentials;
}

function setHeaders(options) {
  let headers;
  if (options.headers === 'none') headers = new Headers();
  else {
    headers = new Headers(
      Object.assign(
        {
          'X-CSRF-Token': store.getters.csrfToken,
          'Content-Type': 'application/json',
        },
        options.headers,
      ),
    );
  }
  delete options.headers;
  return headers;
}

function convertObjectToParams(data) {
  let addedParams = [];
  Object.keys(data).forEach((key) => {
    if (typeof data[key] !== 'undefined' && typeof data[key] !== 'null')
      addedParams.push(key + '=' + encodeURIComponent(data[key]));
  });

  return (addedParams.length ? '?' : '') + addedParams.join('&');
}

function fetchAjax(url, options, customOptions) {
  options.headers = setHeaders(customOptions);
  options = Object.assign({}, options, customOptions);
  formatOptions(options);

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(handleStatus)
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function post(url, data = {}, options = {}) {
  let o = {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify(data),
  };

  return fetchAjax(url, o, options);
}

function put(url, data = {}, options = {}) {
  let o = {
    method: 'put',
    credentials: 'include',
    body: JSON.stringify(data),
  };

  return fetchAjax(url, o, options);
}

function get(url, data = {}, options = {}) {
  let o = {
    method: 'get',
    credentials: 'include',
  };

  return fetchAjax(url + convertObjectToParams(data), o, options);
}

function del(url, data = {}, options = {}) {
  let o = {
    method: 'delete',
    credentials: 'include',
  };

  return fetchAjax(url + convertObjectToParams(data), o, options);
}

export default {
  post,
  del,
  get,
  put,
};
