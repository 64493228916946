<template>
  <LinkExpander
    :id="`default-${linkId}`"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template slot="header"
      >Embed options

      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <IconButton
            href="https://support.campsite.bio/en/articles/6830893-adding-an-embed-link"
            target="_blank"
            aria-label="Embed help"
            size="1.25rem"
            v-bind="attrs"
            v-on="on"
          >
            <QuestionCircleOIcon title="help" />
          </IconButton>
        </template>
        <span>Get some help with your embed</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="form">
      <FormGroup :gutter-bottom="showEmbedOptions">
        <Toggle v-model="removeBackgroundLocal">Remove background</Toggle>
        <FormGroupHelp
          >Remove the background that is added behind the embed</FormGroupHelp
        >
      </FormGroup>

      <template v-if="showEmbedOptions">
        <FormGroup :gutter-bottom="false">
          <Label component="span" block>Embed Options</Label>
          <EmbedOptions
            v-model="embedTypeLocal"
            :embed-type="linkEmbedOptions.id"
            :source-name="linkEmbedOptions.name"
            :embed-options="linkEmbedOptions.embedTypes"
            hide-empty-option
          />
        </FormGroup>
      </template>
    </ValidationObserver>
  </LinkExpander>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import {
  IconButton,
  QuestionCircleOIcon,
  FormGroup,
  Label,
  FormGroupHelp,
  Toggle,
} from '@campsite-bio/component-lib';
import LinkExpander from '../expand/link-expander.vue';
import { computed, ref, toRefs, watch } from 'vue';
import debounce from 'lodash/debounce';
import EmbedOptions from '../components/embed-options.vue';
import uniqueId from 'lodash/uniqueId';

export default {
  components: {
    ValidationObserver,
    LinkExpander,
    IconButton,
    QuestionCircleOIcon,
    FormGroup,
    Label,
    FormGroupHelp,
    Toggle,
    EmbedOptions,
  },

  props: {
    isOpen: Boolean,
    linkId: {
      type: String,
      required: true,
    },
    embedOptions: {
      type: Object,
      required: true,
    },
    embedType: {
      type: Object,
      required: true,
    },
    linkEmbedOptions: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { embedOptions, linkEmbedOptions } = toRefs(props);
    const form = ref(null);
    const id = ref(uniqueId('embed'));

    const removeBackgroundLocal = ref(false);
    const embedTypeLocal = ref(null);

    const showEmbedOptions = computed(() => {
      return linkEmbedOptions.value.embedTypes.length > 1;
    });

    watch(
      embedOptions,
      ({ remove_bg, embed_type }) => {
        removeBackgroundLocal.value = remove_bg;
        embedTypeLocal.value = embed_type;
      },
      { immediate: true },
    );

    async function save() {
      const success = await form.value.validate();
      if (!success) return;

      emit('save', {
        remove_bg: removeBackgroundLocal.value,
        embed_type: embedTypeLocal.value,
      });
    }

    watch([removeBackgroundLocal, embedTypeLocal], debounce(save, 1000));

    return {
      id,
      form,
      removeBackgroundLocal,
      embedTypeLocal,
      showEmbedOptions,
    };
  },
};
</script>

<style lang="scss" scoped></style>
