import { setupComponentLibrary } from '@campsite-bio/component-lib';

import { loadFullStory } from '@/plugins';
import { getAppSetting, getMeta } from '@/utils';
import { intercomBoot } from '@/utils/intercom';
import store from '@/store';

export function startup() {
  setupComponentLibrary({
    apiBaseUrl: `${getAppSetting('apiBaseUrl')}/api`,
    env: window.APP_ENV,
    assetCdn: getAppSetting('assetCdn'),
    assetsBucketName: getAppSetting('assetsBucketName'),
  });

  intercomBoot();

  if (getMeta('enable_full_story', false, store.getters.currentUser.meta)) {
    loadFullStory();
  }
}
