import { computed, unref } from 'vue';

import { percentageChange } from '../../../utils';

export function getCtrStats(options) {
  const { totalClicks, totalViews, totalPastClicks, totalPastViews } = options;
  const totalClicksUnrefed = unref(totalClicks);
  const totalViewsUnrefed = unref(totalViews);
  const totalPastClicksUnrefed = unref(totalPastClicks);
  const totalPastViewsUnrefed = unref(totalPastViews);

  const currentCtr =
    totalViewsUnrefed === 0
      ? 0
      : (totalClicksUnrefed / totalViewsUnrefed) * 100;
  const pastCtr =
    totalPastViewsUnrefed === 0
      ? 0
      : (totalPastClicksUnrefed / totalPastViewsUnrefed) * 100;
  let ctrDifference = 0;
  if (currentCtr && pastCtr)
    ctrDifference = percentageChange(pastCtr, currentCtr);
  const ctrDifferenceFormatted =
    typeof ctrDifference === 'number'
      ? `${ctrDifference.toFixed(1)}%`
      : ctrDifference;
  const ctrBadgeColor = ctrDifference > 0 ? 'success' : 'info';

  return {
    currentCtr,
    pastCtr,
    ctrDifference,
    ctrDifferenceFormatted,
    ctrBadgeColor,
  };
}

export function useCtr(options) {
  const ctr = computed(() => {
    return getCtrStats(options);
  });

  return {
    currentCtr: computed(() => ctr.value.currentCtr),
    pastCtr: computed(() => ctr.value.pastCtr),
    ctrDifference: computed(() => ctr.value.ctrDifference),
    ctrDifferenceFormatted: computed(() => ctr.value.ctrDifferenceFormatted),
    ctrBadgeColor: computed(() => ctr.value.ctrBadgeColor),
  };
}
