<template>
  <ListItem :value="value" :disabled="disabled">
    <template #default="{ active }">
      <ListItemAction>
        <Checkbox :input-value="active" />
      </ListItemAction>
      <ListItemContent>
        <ListItemTitle>{{ instagramAccount.username }}</ListItemTitle>
        <ListItemSubtitle v-if="disabled"
          >This account has already been added</ListItemSubtitle
        >
      </ListItemContent>
      <ListItemAction v-if="!disabled">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <IconButton
              v-bind="attrs"
              :disabled="disableMapping"
              @click.stop="$emit('map')"
              v-on="on"
            >
              <CheckIcon v-if="hasBeenMapped" color="success" />
              <ErrorIcon v-else color="error" />
            </IconButton>
          </template>
          <span>{{ hasBeenMapped ? 'Change mapping' : 'Map to profile' }}</span>
        </v-tooltip>
      </ListItemAction>
    </template>
  </ListItem>
</template>

<script>
import {
  ListItem,
  ListItemContent,
  ListItemAction,
  ListItemTitle,
  ListItemSubtitle,
  IconButton,
  CheckIcon,
  ErrorIcon,
  Checkbox,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';

export default {
  components: {
    ListItem,
    ListItemContent,
    ListItemAction,
    ListItemTitle,
    ListItemSubtitle,
    Checkbox,
    IconButton,
    CheckIcon,
    ErrorIcon,
  },

  props: {
    instagramAccount: {
      type: Object,
      required: true,
    },

    account: {
      type: Object,
      default: null,
    },

    value: {
      type: String,
      required: true,
    },

    disabled: Boolean,
    disableMapping: Boolean,
  },

  setup(props) {
    const hasBeenMapped = computed(() => {
      return props.account && props.account.campsite_id;
    });

    return { hasBeenMapped };
  },
};
</script>

<style lang="scss" scoped>
.instagram-list.instagram-list {
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0;
}
</style>
