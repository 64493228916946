<template>
  <div class="ig-stories-wrapper">
    <Stories
      class="ig-stories"
      :stories="stories"
      :is-paused="isPaused"
      @update:isPaused="isPaused = $event"
    >
      <template #header>
        <div class="ig-stories__header">
          <div class="ig-stories__header__content">
            <Logo
              v-if="type === 'profile'"
              color="white"
              only-mark
              size="1em"
            />
            <InstagramIcon v-else-if="type === 'instagram'" />
            {{ name }}
          </div>

          <div class="ig-stories__header__close">
            <IconButton
              color="white"
              size="1.5rem"
              aria-label="Close"
              @click="handleClose"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </template>

      <template v-for="(story, i) in stories" #[story.template]>
        <component
          :is="story.component"
          :key="i"
          :is-paused="isPaused"
          v-bind="story.data"
        >
          <button
            slot="footer-right"
            class="story-share"
            type="button"
            @mousedown.stop
            @mouseup.stop
            @touchstart.stop
            @touchend.stop
            @touchmove.stop
            @click.stop="handleShare"
          >
            <ExportIcon class="story-share__icon" /> Share this story
          </button>
        </component>
      </template>
    </Stories>
  </div>
</template>

<script>
import { inject, ref, watch } from 'vue';
import {
  InstagramIcon,
  IconButton,
  CloseIcon,
  ExportIcon,
  Logo,
  Stories,
} from '@campsite-bio/component-lib';

import { useDataFromWrapped } from './use-data-from-wrapped';

export default {
  components: {
    Stories,
    InstagramIcon,
    IconButton,
    CloseIcon,
    ExportIcon,
    Logo,
  },

  props: {
    value: Boolean,
    isShareOpen: Boolean,
  },

  setup(props, { emit }) {
    const isPaused = ref(false);

    const { name, type } = inject('wrapped');
    const { stories } = useDataFromWrapped();

    function handleClose() {
      emit('close');
    }

    function handleShare() {
      emit('share');
    }

    watch(
      () => props.isShareOpen,
      (val) => {
        isPaused.value = val;
      },
    );

    return { isPaused, name, type, stories, handleClose, handleShare };
  },
};
</script>

<style lang="scss">
.vue-insta-stories .header {
  right: 16px;
}
</style>

<style lang="scss" scoped>
.ig-stories-wrapper {
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  height: -webkit-fill-available;
}

.ig-stories {
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;

  &__header {
    display: flex;
    align-items: center;

    &__content {
      color: white;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 0.25em;
      font-family: var(--g-font-header-family);
      font-size: 1rem;
      font-weight: 700;
    }

    &__close {
      position: absolute;
      right: -0.5rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.story-share {
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  display: flex;
  gap: 0.35em;
  font-weight: 700;
  text-transform: uppercase;
  font-family: var(--g-font-header-family);
  font-size: 0.6em;
  letter-spacing: 0.05em;
  padding: 0.25em 0;
  position: relative;
  z-index: 100;
}

@media (min-width: 768px) {
  .ig-stories {
    position: relative;
    height: 730px;
    width: 420px;
  }
}
</style>
