<template>
  <StatCard v-bind="$props">
    <template slot="badge">
      <slot name="badge" />
    </template>
    <template slot="default">
      <slot />
      <FlexSpacer />
      <span class="rank" :class="{ 'rank--text': !isEmoji }">
        {{ rankFormatted }}
      </span>
    </template>
  </StatCard>
</template>

<script>
import { FlexSpacer } from '@campsite-bio/component-lib';
import { computed } from 'vue';

import StatCard from './stat-card';
import { getMedalEmojiFromRank, ordinal } from '../../../utils';

export default {
  components: {
    StatCard,
    FlexSpacer,
  },

  props: {
    ...StatCard.props,

    rank: {
      type: Number,
      default: null,
    },
  },

  setup(props) {
    const isEmoji = computed(() => {
      return props.rank <= 3;
    });

    const rankFormatted = computed(() => {
      return (
        getMedalEmojiFromRank(props.rank) ||
        `${props.rank}${ordinal(props.rank)}`
      );
    });

    return { isEmoji, rankFormatted };
  },
};
</script>

<style lang="scss" scoped>
.rank {
  &--text {
    font-size: 0.65em;
  }
}
</style>
