<template>
  <Alert variant="outline">
    <InfoIcon slot="icon" />
    You don't have any profiles! Create one to get started.
    <Button slot="actions" variant="flat" to="/profile/new">Add</Button>
  </Alert>
</template>

<script>
import { Alert, InfoIcon, Button } from '@campsite-bio/component-lib';

export default {
  components: {
    Alert,
    InfoIcon,
    Button,
  },
};
</script>

<style lang="scss" scoped></style>
