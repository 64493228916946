import {
  ref,
  onMounted,
  onBeforeUnmount,
  useSlots,
  nextTick,
  watch,
} from 'vue';
import { convertToUnit } from '@campsite-bio/component-lib';

// https://lorisleiva.com/renderless-resizable-textarea/
export default {
  props: {
    maxHeight: {
      type: Number,
      default: null,
    },
  },

  setup(props) {
    const slots = useSlots();
    const textarea = ref(null);

    function resize() {
      if (!textarea.value) return;

      textarea.value.style.height = 'auto';
      if (props.maxHeight && textarea.value.scrollHeight > props.maxHeight)
        textarea.value.style.height = convertToUnit(props.maxHeight);
      else textarea.value.style.height = textarea.value.scrollHeight + 'px';
    }

    watch(() => props.maxHeight, resize, { immediate: true });

    onMounted(() => {
      textarea.value = slots.default()[0].elm;
      textarea.value.addEventListener('input', resize);
      nextTick().then(() => resize());
    });

    onBeforeUnmount(() => {
      textarea.value.removeEventListener('input', resize);
    });
  },

  render() {
    return this.$slots.default[0];
  },
};
