<template>
  <ChartCard
    ref="target"
    title="Top Countries"
    :is-loading="isLoading"
    :timestamp="allData ? allData.timestamp : null"
    :padding-bottom="false"
  >
    <CountriesTable
      type="views"
      :is-loading="isLoading"
      :data="data"
      :all-data="allData"
    />
  </ChartCard>
</template>

<script>
import ChartCard from './chart-card';
import { CountriesTable } from '../charts';

export default {
  components: {
    ChartCard,
    CountriesTable,
  },

  props: {
    allData: {
      type: Object,
      default: null,
    },

    data: {
      type: Array,
      default: null,
    },

    isLoading: Boolean,
  },
};
</script>

<style lang="scss" scoped></style>
