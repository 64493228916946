<template>
  <tr class="goals-item">
    <td>
      <span class="goals-item__title">
        <Logo v-if="item.channel === 'bio'" only-mark size="1em" />
        <InstagramIcon v-if="item.channel === 'instagram'" />
        {{ item.tally | numberFormatter(2) }} {{ startCase(item.metric) }}
      </span>
    </td>
    <td>{{ item.frequency }}</td>
    <td>{{ item.account_name }}</td>
    <td>
      {{ createdAt }}
    </td>
    <td>Coming soon</td>
    <td class="text-end">
      <div v-if="!hideActions" class="print-hide">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <IconButton v-bind="attrs" :loading="isDeleting" v-on="on">
              <DotsVerticalIcon />
            </IconButton>
          </template>
          <List>
            <ListItem component="button" @click="deleteGoal">
              <ListItemIcon>
                <TrashIcon />
              </ListItemIcon>
              <ListItemTitle>Delete</ListItemTitle>
            </ListItem>
          </List>
        </v-menu>
      </div>
    </td>
  </tr>
</template>

<script>
import {
  Logo,
  InstagramIcon,
  IconButton,
  DotsVerticalIcon,
  List,
  ListItem,
  ListItemTitle,
  ListItemIcon,
  TrashIcon,
} from '@campsite-bio/component-lib';
import { DateTime } from 'luxon';
import { computed, ref } from 'vue';
import startCase from 'lodash/startCase';

import { localAxios } from '../../../../apis';

export default {
  components: {
    Logo,
    InstagramIcon,
    IconButton,
    DotsVerticalIcon,
    List,
    ListItem,
    ListItemTitle,
    ListItemIcon,
    TrashIcon,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },

    hideActions: Boolean,
  },

  setup(props, { emit }) {
    const isDeleting = ref(false);

    const createdAt = computed(() =>
      DateTime.fromISO(props.item.created_at).toLocaleString(DateTime.DATE_MED),
    );

    async function deleteGoal() {
      isDeleting.value = true;
      try {
        await localAxios.delete(`/api/goals/${props.item._id.$oid}`);
        emit('delete', props.item);
      } catch (e) {
        console.error(e);
      }
      isDeleting.value = false;
    }

    return { createdAt, deleteGoal, isDeleting, startCase };
  },
};
</script>

<style lang="scss" scoped>
.goals-item {
  &__title {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
}
</style>
