import { isValidUrl } from '../../utils';

const INVALID_WEBSITES = [
  'facebook.com',
  'spotify.com',
  'instagram.com',
  'tiktok.com',
  'google.com',
  'twitter.com',
  'linkedin.com',
  'pinterest.com',
  'youtube.com',
  'soundcloud.com',
  'github.com',
  'vimeo.com',
  'wasap.my',
  'wa.me',
  'whats.link',
  'whatsapp.com',
  'youngliving.com',
  'gotub.me',
  'bit.ly',
  'rebrand.ly',
  'lazada.co.id',
  'campsite.bio',
  'line.me',
  'gumroad.com',
  'shopee.co.id',
  'shopee.com',
  'shp.ee',
  't.me',
  'goo.gl',
  'forms.gle',
  'tokopedia.link',
  'tokopedia.com',
  'tinyurl.com',
  'onlyfans.com',
  'anchor.fm',
  'etsy.com',
  'ty.gl',
  'trendyol.com',
  'app.link',
  'crsmc.link',
  'camsoda.com',
  'jotform.com',
  'toko.ly',
  'eventbrite.com',
  'secreto.site',
  'walmart.com',
  'hotm.art',
  'hotmart.com',
  'wa.link',
  'clickbank.net',
  'twitch.tv',
  'discord.gg',
];

export const wizardUrl = {
  name: 'wizardUrl',

  message: 'The {_field_} field is not a valid URL',

  validate(value) {
    const { isAccepted, url, reason } = isValidUrl(value);

    if (!isAccepted)
      return `The {_field_} field is not a valid URL (${reason})`;

    // Check if url.host contains a substring of INVALID_WEBSITES
    const isInvalidWebsite = INVALID_WEBSITES.some((website) =>
      url.host.includes(website),
    );
    if (isInvalidWebsite)
      return `The {_field_} field is not a valid URL (invalid website)`;

    return true;
  },
};
