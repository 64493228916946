<template>
  <LinkWrapper v-bind="$props" @add-link="$emit('add-link', $event)">
    <LinkCard>
      <ValidationObserver ref="form">
        <ValidationProvider
          v-slot="{ errors, ariaInput, ariaMsg }"
          :rules="labelRules"
          name="title"
          immediate
          slim
        >
          <LinkField>
            <EmojiInput
              :id="'label' + _uid"
              ref="labelRef"
              v-model="label"
              border-color="gray200"
              :has-error="errors.length > 0"
              autocomplete="off"
              maxlength="80"
              placeholder="My section title"
              :show-emoji="isLargeScreen"
              :disabled="isReadonly"
              v-bind="ariaInput"
              aria-label="Section title"
              @focus="focusLink"
            >
              <IconButton
                slot="prepend"
                v-clipboard
                :data-clipboard-text="label"
                tabindex="-1"
                aria-label="Copy title"
              >
                <PencilIcon title="Copy title" />
              </IconButton>
            </EmojiInput>
            <FormGroupHelp color="error" v-bind="ariaMsg">{{
              errors[0]
            }}</FormGroupHelp>
          </LinkField>
        </ValidationProvider>
      </ValidationObserver>

      <template slot="btm">
        <LinkBtn
          v-if="!isDeleted && !isScheduleHidden"
          aria-label="Schedule link"
          :active="isExpanded('schedule')"
          :highlight="isExpanded('schedule')"
          :aria-controls="`schedule-${id}`"
          @click="toggleExpand('schedule')"
        >
          <CalendarIcon slot="icon" />
        </LinkBtn>
        <v-tooltip v-if="!isDeleted && !isPinnedHidden" top>
          <template #activator="{ on }">
            <LinkBtn
              v-if="!isDeleted"
              aria-label="Pin link"
              :pinned="pinned"
              :disabled="isPinnedDisabled"
              v-on="on"
              @click="pinned = !pinned"
            >
              <PinIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>{{ pinnedText }}</span>
        </v-tooltip>
        <v-tooltip v-if="isDeleted" top>
          <template #activator="{ on }">
            <LinkBtn
              class="link__btn--restore js-link-restore"
              aria-label="Restore link"
              :disabled="isRestoring"
              v-on="on"
              @click="handleRestore"
            >
              <RepeatIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>Restore link</span>
        </v-tooltip>
      </template>

      <template slot="actions">
        <ScheduleExpand
          :is-open="isExpanded('schedule')"
          :link="link"
          @close="toggleExpand('schedule')"
        />
      </template>
    </LinkCard>
  </LinkWrapper>
</template>

<script>
import {
  PinIcon,
  RepeatIcon,
  EmojiInput,
  PencilIcon,
  CalendarIcon,
  IconButton,
  FormGroupHelp,
} from '@campsite-bio/component-lib';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import { ScheduleExpand } from '../expand';
import { LinkBtn, LinkWrapper, LinkCard, LinkField } from '../components';
import { LinkProps } from '../link-props';
import { useLink } from '../compositions';

export default {
  components: {
    PinIcon,
    RepeatIcon,
    CalendarIcon,
    LinkBtn,
    EmojiInput,
    PencilIcon,
    IconButton,
    FormGroupHelp,
    ScheduleExpand,
    ValidationObserver,
    ValidationProvider,
    LinkWrapper,
    LinkCard,
    LinkField,
  },

  props: LinkProps,

  setup(props) {
    const { link, ...rest } = useLink(props, {
      name: 'title',
    });

    return {
      ...rest,
    };
  },
};
</script>

<style scoped></style>
