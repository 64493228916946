<template>
  <InteractiveChartCard
    ref="target"
    class="page-views-card"
    title="Page Views"
    :percent-difference="percentDifference"
    :current-value="totalViews"
    :current-value-subtitle="totalViewsSubtitle"
    :previous-value="totalPastViews"
    :previous-value-subtitle="pastViewsSubtitle"
    :start-date="startDate"
    :end-date="endDate"
    :is-loading="isLoading"
    :timestamp="allData ? allData.timestamp : null"
  >
    <ChartWrapper
      width="100%"
      height="150"
      type="Line"
      :options="chartOptions"
      :data="series"
      @mouseleave="resetHoveredValues"
    />
  </InteractiveChartCard>
</template>

<script>
import { computed, toRefs } from 'vue';
import { colors } from '@campsite-bio/component-lib';

import InteractiveChartCard from './interactive-chart-card';
import { percentageChange } from '../../../utils';
import {
  useApexOptions,
  useCategories,
  useChartStartEndDate,
  useDataColors,
  useHoveredValues,
} from '../compositions';
import { ChartWrapper } from '../';

export default {
  components: {
    ChartWrapper,
    InteractiveChartCard,
  },

  props: {
    allData: {
      type: Object,
      default: null,
    },

    data: {
      type: Array,
      default: null,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const { allData } = toRefs(props);
    const currentData = computed(() => {
      return props.data?.filter(({ _id }) => _id.range === 'current');
    });

    const pastData = computed(() => {
      return props.data?.filter(({ _id }) => _id.range === 'past');
    });

    const { categories, pastCategories } = useCategories(
      allData,
      currentData,
      pastData,
    );
    const dataColors = useDataColors();
    const series = computed(() => {
      if (!currentData.value) return {};

      const currentStats = {
        label: 'Page views',
        data: [],
        borderColor: dataColors.value[0],
        backgroundColor: dataColors.value[0],
      };
      const pastStats = {
        label: 'Page views',
        data: [],
        borderColor: colors.gray,
        backgroundColor: colors.gray,
      };
      currentData.value.forEach((stat, index) => {
        currentStats.data.push(stat.count);
      });
      pastData.value.forEach((stat, index) => {
        pastStats.data.push(stat.count);
      });
      return {
        datasets: [currentStats, pastStats],
        labels: categories.value,
      };
    });

    const { startDate, endDate } = useChartStartEndDate(allData);

    const { hoveredValues, mouseMove, resetHoveredValues } = useHoveredValues({
      series,
      categories,
      pastCategories,
    });

    const chartOptions = useApexOptions({
      categories,
      mouseMove,
      gridPadding: {
        left: 0,
        right: 0,
        top: -24,
        bottom: -4,
      },
    });

    const totalViews = computed(() => {
      if (!series.value.length) return 0;
      if (hoveredValues.value !== null) return hoveredValues.value.current;
      return series.value[1].data.reduce((accum, stat) => {
        accum += stat;
        return accum;
      }, 0);
    });
    const totalPastViews = computed(() => {
      if (!series.value.length) return 0;
      if (hoveredValues.value !== null) return hoveredValues.value.past;
      return series.value[0].data.reduce((accum, stat) => {
        accum += stat;
        return accum;
      }, 0);
    });
    const percentDifference = computed(() => {
      if (
        (!totalViews.value && !totalPastViews.value) ||
        typeof totalViews.value === 'string'
      )
        return 0;

      return percentageChange(totalPastViews.value, totalViews.value);
    });
    const totalViewsSubtitle = computed(() => {
      if (!hoveredValues.value) return null;
      return hoveredValues.value.currentSubtitle;
    });
    const pastViewsSubtitle = computed(() => {
      if (!hoveredValues.value) return null;
      return hoveredValues.value.pastSubtitle;
    });

    return {
      chartOptions,
      totalViews,
      totalViewsSubtitle,
      totalPastViews,
      pastViewsSubtitle,
      percentDifference,
      startDate,
      endDate,
      resetHoveredValues,
      series,
      categories,
      pastCategories,
    };
  },
};
</script>

<style lang="scss" scoped>
.page-views-card {
  min-height: unset;
}

#chart .apexcharts-tooltip {
  display: none;
}
</style>
