import { computed, ref } from 'vue';
import { useTheme } from '@campsite-bio/component-lib';
import { useMediaQuery } from '@vueuse/core';
import { useStore } from './use-store';

export function useQrCode() {
  const store = useStore();
  const theme = useTheme();
  const qrCode = ref(null);
  const fileType = ref('png');
  const username = computed(
    () => store.getters['profiles/currentProfile'].username_lowercase,
  );
  const subdomain = computed(
    () => store.getters['profiles/currentProfile'].subdomain,
  );
  const customDomain = computed(
    () => store.getters['profiles/currentProfile'].custom_domain,
  );
  const isFreePlan = computed(
    () => store.getters['profiles/isProfileOnFreePlan'],
  );
  const qrCodeData = computed(() => store.getters['qrCode/code']);
  const data = computed(() => {
    let urlType = qrCodeData.value ? qrCodeData.value.url_type : '';
    if (isFreePlan.value) urlType = '';

    return getQrUrl(urlType);
  });
  const isMobile = useMediaQuery(
    `(max-width: ${theme.value.breakpoints.lg}px)`,
  );
  const qrCodeProps = computed(() => {
    const { dots_color, dots_type, background_color, image_type, _id } =
      qrCodeData.value || {};

    return {
      data: data.value,
      dotsColor: dots_color,
      dotsType: dots_type,
      backgroundColor: background_color,
      imageType: image_type,
      imageMargin: 25,
      id: _id.$oid,
    };
  });

  function getQrUrl(urlType) {
    switch (urlType) {
      case 'subdomain':
        return `https://${subdomain.value}.campsite.bio`;
      case 'custom_domain':
        return `https://${customDomain.value}`;
      default:
        return `https://campsite.bio/${username.value}`;
    }
  }

  function download() {
    // open the image in a new tab if mobile
    if (isMobile.value) {
      const canvas = qrCode.value.$el.querySelector('canvas');
      const data = canvas.toDataURL();
      let w = window.open('about:blank');
      let image = new Image();
      image.src = data;
      setTimeout(function () {
        w.document.write(image.outerHTML);
      }, 0);
    } else qrCode.value.download(fileType.value);

    try {
      store.dispatch('qrCode/trackDownload');
    } catch (e) {
      console.error(e);
    }
  }

  return {
    qrCode,
    qrCodeProps,
    data,
    download,
    getQrUrl,
    fileType,
    username,
    subdomain,
    customDomain,
  };
}
