<template>
  <SimpleLayoutCard>
    <VText variant="h2" component="h1" style="margin-bottom: 1rem"
      >Log in</VText
    >

    <div class="row">
      <div class="col col--md-6 text--xs-center">
        <GoogleBtn block @click="authorize('google')" />
      </div>
      <div class="col col--md-6">
        <SocialBtn variant="facebook" block @click="authorize('facebook')">
          <FacebookLetterFIcon />
          Sign in with Facebook
        </SocialBtn>
      </div>
    </div>

    <Divider color="lightGray" style="margin: 1.5rem 0">or</Divider>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form action="#" novalidate @submit.prevent="handleSubmit(login)">
        <ValidationProvider
          v-slot="{ errors, ariaMsg, ariaInput }"
          rules="required"
          name="username"
          slim
        >
          <FormGroup>
            <Label for="username">Your Username or Email</Label>
            <Input
              id="username"
              v-model.trim="username"
              type="text"
              name="username"
              required
              placeholder="hello@gmail.com"
              autocomplete="username"
              :has-error="errors.length > 0"
              v-bind="ariaInput"
            />
            <FormGroupHelp color="error" v-bind="ariaMsg">{{
              errors[0]
            }}</FormGroupHelp>
          </FormGroup>
        </ValidationProvider>

        <input
          v-model="honeypot"
          class="hide"
          type="email"
          name="email"
          placeholder="email address"
          autocomplete="off"
        />

        <ValidationProvider
          v-slot="{ errors, ariaMsg, ariaInput }"
          rules="required"
          name="password"
          slim
        >
          <FormGroup>
            <Label for="password">Your Password</Label>
            <PasswordInput
              id="password"
              v-model="password"
              name="password"
              :has-error="errors.length > 0"
              v-bind="ariaInput"
            />
            <FormGroupHelp color="error" v-bind="ariaMsg">{{
              errors[0]
            }}</FormGroupHelp>
          </FormGroup>
        </ValidationProvider>

        <div class="form-actions">
          <Button :loading="isFinishing" type="submit">Log in</Button
          >&nbsp;&nbsp;
          <router-link to="/forgot-password">Forgot your password?</router-link>
        </div>
      </form>
    </ValidationObserver>

    <VText style="margin-top: 1.5rem; font-size: 0.825rem" color="gray500">
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a>
      and
      <a href="https://policies.google.com/terms">Terms of Service</a>
      apply.
    </VText>

    <div slot="after" class="card-alt-actions">
      <VText color="white">
        Don't have an account?&nbsp;&nbsp;
        <Button
          size="small"
          color="white"
          variant="outline"
          to="/create-account"
          >Sign up</Button
        >
      </VText>
    </div>
  </SimpleLayoutCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  Button,
  Divider,
  FormGroup,
  Label,
  Input,
  FormGroupHelp,
  FacebookLetterFIcon,
  VText,
} from '@campsite-bio/component-lib';
import { onMounted, ref } from 'vue';

import { SimpleLayoutCard } from '@/components/layout';
import toast from '../utils/toast';
import { intercomUpdate } from '../utils/intercom';
import { GoogleBtn, SocialBtn } from '../components/buttons';
import { PasswordInput } from '../components/form';
import { createFormAndRedirect, getAppSetting } from '../utils';
import {
  useAuthorizationDialog,
  useCaptcha,
  useRoute,
  useRouter,
  useStore,
} from '../compositions';

export default {
  components: {
    SimpleLayoutCard,
    ValidationProvider,
    ValidationObserver,
    GoogleBtn,
    SocialBtn,
    Button,
    Divider,
    FormGroup,
    Label,
    Input,
    FormGroupHelp,
    PasswordInput,
    FacebookLetterFIcon,
    VText,
  },

  setup() {
    const username = ref(null);
    const password = ref(null);
    const honeypot = ref(null);
    const returnUrl = ref(null);
    const isFinishing = ref(false);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const appEnv = getAppSetting('env');

    const { execute: runCaptcha } = useCaptcha();

    const { open, isAuthorizing } = useAuthorizationDialog({
      onCancel() {},
      async onSuccess() {
        await store.dispatch('getUserData', { force: true });
        router.push('/');
        track();
      },
    });

    function authorize(provider) {
      open({
        provider,
        params: {
          type: 'login',
        },
      });
    }

    onMounted(() => {
      returnUrl.value = route.value.query.return_url;
      if (route.value.query.error_message) {
        toast.error(route.value.query.error_message);
        router.replace('/login');
      }
    });

    function redirect(e) {
      let link = e.target;
      while (link.tagName !== 'A') {
        link = link.parentElement;
      }
      createFormAndRedirect(link.href);
    }

    function track() {
      if (window.gtag) {
        gtag('event', 'login', {
          event_category: 'engagement',
          event_label: 'method',
        });
      }

      intercomUpdate();
    }

    async function login() {
      if (isFinishing.value) return;

      isFinishing.value = true;

      try {
        const token = await runCaptcha('login');
        const { user } = await store.dispatch('login', {
          honeypot: honeypot.value,
          username: username.value,
          password: password.value,
          captcha: token,
        });

        store.commit('setUser', user);
        toast.success('Log in successful');
        // only redirect if returnUrl exists and
        // is relative path
        if (returnUrl.value && returnUrl.value.charAt(0) === '/')
          router.push(returnUrl.value);
        else router.push('/');

        track();
      } catch (e) {
        const { error_message } = e.response?.data || {};
        console.error(e);
        toast.error(
          error_message || 'There was an error logging in to your account',
        );
        isFinishing.value = false;
      }
    }

    return {
      appEnv,
      username,
      password,
      returnUrl,
      isFinishing,
      redirect,
      login,
      authorize,
      honeypot,
    };
  },

  head: {
    title: 'Log in',
    description:
      'Log in to your Campsite account with your email, Google or Instagram',
  },
};
</script>

<style lang="scss" scoped>
::v-deep iframe {
  margin-bottom: 0;
}
</style>
