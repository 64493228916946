import { computed, unref } from 'vue';
import { PROFILE_ACTIVITY_TYPES } from '../../utils/log';

import { useActivityLogger } from './use-activity-logger';

export function useProfileLogger(profileId) {
  // Make sure profileId is passed
  if (!profileId) {
    throw new Error('Profile ID is required');
  }

  const endpoint = computed(() => `/api/profiles/${unref(profileId)}/log`);
  const activityLog = useActivityLogger(endpoint);

  async function log(type, data) {
    // Make sure type is valid
    if (!Object.values(PROFILE_ACTIVITY_TYPES).includes(type)) {
      throw new Error(`Invalid activity type: ${type}`);
    }

    await activityLog(type, data);
  }

  return log;
}
