/**
 * Calculates in percent, the change between 2 numbers.
 * e.g from 1000 to 500 = 50%
 *
 * @param oldNumber The initial value
 * @param newNumber The value that changed
 */
export function percentageChange(oldNumber, newNumber, ifZero = '-') {
  if (!oldNumber || typeof newNumber === undefined) return ifZero;

  let a1 = oldNumber;
  let a2 = newNumber;

  if (a1 < 0 && a2 < 0) {
    const oldA1 = a1;
    a1 = Math.abs(a2);
    a2 = Math.abs(oldA1);
  }

  const result = ((a2 - a1) / a1) * 100;

  if (a1 < 0) {
    return result * -1;
  }

  return result;
}
