export const EVENTS_CATEGORIES = {
  DOWNGRADE: 'downgrade',
};

export const EVENT_ACTIONS = {
  PAGE_VIEW: 'page_view',
  SHOWN_OFFER: 'shown_offer',
  ACCEPTED_OFFER: 'accepted_offer',
  DECLINED_OFFER: 'declined_offer',
  DOWNGRADED: 'downgraded',
};

export const EVENT_LABELS = {
  DOWNGRADE_MISSING_PAGE: 'Missing offers page',
  DOWNGRADE_CANCEL_PAGE: 'Downgrade form page',
};

/**
 * Send an event to Google Analytics
 * @param {String} category
 * @param {String} action
 * @param {String} label
 * @param {String} value
 */
export function trackEvent(category, action, label, value) {
  if (window.ga) {
    window.ga('send', 'event', {
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
      transport: 'beacon',
    });
  }
}
