<template>
  <MultiStepDialog
    v-model="isOpen"
    max-width="400px"
    content-class="connected-dialog"
    :steps="steps"
  >
    <template v-for="(index, name) in $scopedSlots" #[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
  </MultiStepDialog>
</template>

<script>
import { computed, ref } from 'vue';

import { MultiStepDialog } from '../../multi-step-dialog';
import { ProviderList, FacebookAuth } from './steps';
import { PROVIDERS } from '../../../config';

export default {
  components: {
    MultiStepDialog,
  },

  props: {
    providers: {
      type: Array,
      default: () => [PROVIDERS.facebook, PROVIDERS.youtube],
    },
  },

  setup(props) {
    const isOpen = ref(false);

    const steps = computed(() => {
      return [
        {
          path: '',
          component: ProviderList,
          title: 'Connect Account',
          props: {
            hasAnalyticsAddon: true,
            providers: props.providers,
          },
        },
        {
          path: 'facebook-auth',
          component: FacebookAuth,
          title: 'Connect Facebook',
        },
      ];
    });

    return { isOpen, steps };
  },
};
</script>

<style lang="scss">
.connected-dialog {
  display: flex;
  min-height: 375px;

  .v-card.v-card {
    display: flex;
    flex-direction: column;
  }

  .v-card__text {
    flex-grow: 1;
  }
}
</style>
