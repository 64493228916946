<script>
export default {
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },

  setup() {
    return {};
  },

  render(h) {
    return null;
  },
};
</script>

<style lang="scss" scoped></style>
