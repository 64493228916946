import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue';
import { captureException } from '@sentry/browser';

import bus from '@/bus';
import { useStore } from '@/compositions';
import { getMeta } from '@/utils';
import toast from '@/utils/toast';

export function useLinkMedia(link, { ignoreUpdate }) {
  const store = useStore();
  const isSavingImage = ref(false);
  const linkId = computed(() => link.value._id.$oid);

  const media = computed({
    get() {
      return getMeta('media', null, link.value.meta);
    },
    set(value) {
      store.commit('links/updateLinkMedia', { value, id: linkId.value });
    },
  });

  const mediaImage = computed(() => {
    return media.value?.url || media.value?.image;
  });

  const hasPostImage = computed(() => {
    return !!media.value;
  });

  const hasFutureImage = computed(() => {
    return !!media.value?.instagram_post_min_id;
  });

  onMounted(() => {
    bus.$on('media-custom-deleted', mediaDeleted);
    bus.$on('media-custom-updated', mediaUpdated);
  });

  onUnmounted(() => {
    bus.$off('media-custom-deleted', mediaDeleted);
    bus.$off('media-custom-updated', mediaUpdated);
  });

  // remove media if it is deleted
  async function mediaDeleted(id) {
    if (media.value?.media_id?.$oid === id) {
      ignoreUpdate.value = true;
      store.commit('links/updateLinkMedia', { value: null, id: linkId.value });
      await nextTick();
      ignoreUpdate.value = false;
    }
    if (link.value.banners) {
      const banners = link.value.banners.filter((b) => b.media?.$oid === id);
      banners.forEach((b) => {
        store.commit('links/updateLinkBannerMedia', {
          value: null,
          bannerId: b._id.$oid,
          linkId: linkId.value,
        });
        store.commit('links/updateLinkBannerEnabled', {
          value: false,
          bannerId: b._id.$oid,
          linkId: linkId.value,
        });
      });
    }
  }

  // update media if it is updated
  async function mediaUpdated(media) {
    const id = media._id.$oid;

    if (media.value?.media_id?.$oid === id) {
      ignoreUpdate.value = true;
      store.commit('links/updateLinkMedia', {
        value: {
          ...media.value,
          url: media.url,
          height: media.height,
          width: media.width,
          media_id: media._id.$oid,
        },
        id: linkId.value,
      });
      await nextTick();
      ignoreUpdate.value = false;
    }
    if (link.value.banners) {
      const banners = link.value.banners.filter((b) => b.media?.$oid === id);
      banners.forEach((b) => {
        store.commit('links/updateLinkBannerMediaUrl', {
          value: media.url,
          bannerId: b._id.$oid,
          linkId: linkId.value,
        });
      });
    }
  }

  function imageRemove() {
    media.value = null;
  }

  async function selectMedia(selectedItem) {
    // custom media
    if (selectedItem.mediaId) {
      media.value = {
        media_id: {
          $oid: selectedItem.mediaId,
        },
        url: selectedItem.media.url,
        height: selectedItem.media.height,
        width: selectedItem.media.width,
      };
    }
    // static url to asset
    else if (selectedItem.url) {
      if (selectedItem.source === 'instagram') {
        isSavingImage.value = true;
        try {
          await store.dispatch('links/uploadPostImage', {
            url: selectedItem.url,
            id: linkId.value,
          });
        } catch (e) {
          captureException(e);
          console.error(e);
          media.value = null;
          toast.error('There was an error trying to upload your post image');
        }
        isSavingImage.value = false;
      } else {
        media.value = {
          image: selectedItem.url,
        };
      }
    }
    // upcoming post : instagram
    else {
      media.value = {
        instagram_post_min_id: selectedItem.minId,
      };
    }
  }

  return {
    mediaImage,
    hasPostImage,
    hasFutureImage,
    isSavingImage,
    imageRemove,
    selectMedia,
  };
}
