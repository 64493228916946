import { localAxios } from './axios';

export async function requestCert(profileId, postData = {}) {
  const { data } = await localAxios.post(
    `/api/profiles/${profileId}/certificate`,
    postData,
  );
  return data;
}

export async function loadCert(profileId) {
  const { data } = await localAxios.get(
    `/api/profiles/${profileId}/certificate`,
  );
  return data;
}
