<template>
  <div class="grid grid--container">
    <div class="row">
      <transition name="fade" mode="out-in">
        <router-view
          :referral-code="referralCode"
          :invite-token="inviteToken"
          :org-id="orgId"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useRoute } from '@/compositions';

export default {
  setup() {
    const route = useRoute();
    const referralCode = ref(null);
    const inviteToken = ref(route.value.query.invite);
    const orgId = ref(route.value.query.org_id);

    onMounted(() => {
      if (route.value.query.referral) {
        sessionStorage['campsite-referral-code'] = route.value.query.referral;
      }
      if (route.value.query.freeTrial) {
        sessionStorage['free-trial'] = true;
      }
      if (sessionStorage['campsite-referral-code']) {
        referralCode.value =
          route.value.query.referral ||
          sessionStorage['campsite-referral-code'];
      }
    });

    return {
      referralCode,
      inviteToken,
      orgId,
    };
  },

  head: {
    description: 'Get started for free by creating an account on Campsite',
  },
};
</script>

<style scoped></style>
