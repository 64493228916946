<template>
  <LinkWrapper v-bind="$props" @add-link="$emit('add-link', $event)">
    <LinkCard v-if="hasSetup">
      <LinkField v-if="hasAuthWarning">
        <Alert>
          <InfoIcon slot="icon" />
          There is an authentication issue with your Instagram account. New
          links won't be able to be synced until it is fixed.
          <Button
            slot="actions"
            variant="flat"
            color="white"
            :to="authSettingsUrl"
          >
            Go to settings
          </Button>
        </Alert>
      </LinkField>

      <LinkField>
        <Label :for="'label' + _uid">Grid Title</Label>
        <EmojiInput
          ref="labelRef"
          v-model="label"
          :input-id="'label' + _uid"
          border-color="gray200"
          autocomplete="off"
          maxlength="80"
          placeholder="My Instagram Feed"
          :show-emoji="isLargeScreen"
          :disabled="isReadonly"
          @focus="focusLink"
        >
          <IconButton
            slot="prepend"
            v-clipboard
            :data-clipboard-text="label"
            tabindex="-1"
            aria-label="Copy title"
          >
            <PencilIcon title="Copy title" />
          </IconButton>
        </EmojiInput>
      </LinkField>

      <LinkField>
        <Label component="span">Grid Items ({{ link.item_count }})</Label>
        <Items :link="link" :items="items" :disabled="isReadonly" />
      </LinkField>

      <template slot="btm">
        <LinkBtn
          v-if="!isStatsHidden"
          :active="isExpanded('stats')"
          :aria-label="`Link analytics - ${link.number_of_clicks} clicks`"
          :aria-controls="`stats-${id}`"
          @click="toggleExpand('stats')"
        >
          <LineChartIcon slot="icon" />
          <template v-if="link.number_of_clicks" slot="stat">
            {{ link.number_of_clicks | numberFormatter(1) }}
          </template>
        </LinkBtn>
        <LinkBtn
          v-if="!isDeleted && !isScheduleHidden"
          aria-label="Schedule link"
          :active="isExpanded('schedule')"
          :highlight="isExpanded('schedule')"
          :aria-controls="`schedule-${id}`"
          @click="toggleExpand('schedule')"
        >
          <CalendarIcon slot="icon" />
        </LinkBtn>
        <v-tooltip v-if="!isDeleted && !isPinnedHidden" top>
          <template #activator="{ on }">
            <LinkBtn
              v-if="!isDeleted"
              aria-label="Pin link"
              :pinned="pinned"
              :disabled="isPinnedDisabled"
              v-on="on"
              @click="pinned = !pinned"
            >
              <PinIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>{{ pinnedText }}</span>
        </v-tooltip>
        <v-tooltip v-if="isDeleted" top>
          <template #activator="{ on }">
            <LinkBtn
              class="link__btn--restore js-link-restore"
              aria-label="Restore link"
              :disabled="isRestoring"
              v-on="on"
              @click="handleRestore"
            >
              <RepeatIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>Restore link</span>
        </v-tooltip>
      </template>

      <template slot="actions">
        <StatsExpand
          :is-open="isExpanded('stats')"
          @close="toggleExpand('stats')"
        />
        <ScheduleExpand
          :is-open="isExpanded('schedule')"
          :link="link"
          @close="toggleExpand('schedule')"
        />
        <EventLabelExpand
          v-if="!isFreePlan"
          :is-open="isExpanded('event-tag')"
          :link="link"
          @close="toggleExpand('event-tag')"
        />
        <ImageGridSettingsExpand
          :is-open="isExpanded('default')"
          :image-grid-options="options"
          :link-id="id"
          @close="toggleExpand('default')"
          @save="saveOptions"
        />
      </template>
    </LinkCard>
    <ImageGridSetup
      v-else
      :link-id="id"
      :image-grid-options="options"
      :is-deleting="isDeleting"
      @setup-complete="toggleExpand('image-grid')"
      @remove="handleDelete"
    />
  </LinkWrapper>
</template>

<script>
import {
  LineChartIcon,
  CalendarIcon,
  PinIcon,
  RepeatIcon,
  EmojiInput,
  IconButton,
  PencilIcon,
  Label,
  Alert,
  InfoIcon,
  Button,
} from '@campsite-bio/component-lib';
import { computed, provide } from 'vue';

import { LinkBtn, LinkWrapper, LinkCard, LinkField } from '../components';
import { StatsExpand, ScheduleExpand, EventLabelExpand } from '../expand';
import { useCurrentProfile, useStore } from '@/compositions';
import ImageGridSetup from './image-grid-setup';
import ImageGridSettingsExpand from './settings-expand';
import { Items } from './components';
import { AUTHORIZATION_STATUS } from '@/config';
import { LinkProps } from '../link-props';
import { useLink } from '../compositions';

export default {
  components: {
    LineChartIcon,
    CalendarIcon,
    PinIcon,
    RepeatIcon,
    StatsExpand,
    ScheduleExpand,
    EventLabelExpand,
    LinkBtn,
    EmojiInput,
    IconButton,
    PencilIcon,
    ImageGridSettingsExpand,
    Items,
    ImageGridSetup,
    Label,
    Alert,
    InfoIcon,
    Button,
    LinkWrapper,
    LinkCard,
    LinkField,
  },

  props: LinkProps,

  setup(props) {
    const { link, isSavingOptions, options, hasSetup, saveOptions, ...rest } =
      useLink(props, {
        name: 'image grid link',
        optionsName: 'image_grid',
        expandOnSetup: 'default',
      });
    const store = useStore();
    const { id: profileId } = useCurrentProfile();

    const items = computed(() => {
      return store.getters['linkItems/getField'](
        `items.${link.value._id.$oid}`,
      );
    });

    const instagramAuthorization = computed(() => {
      return store.getters['profiles/instagramAuthorization'];
    });

    const hasProfile = computed(() => {
      return store.getters['profiles/hasProfile'];
    });

    const hasAuthWarning = computed(() => {
      if (!hasProfile.value) return false;

      return (
        !instagramAuthorization.value ||
        instagramAuthorization.value?.status ===
          AUTHORIZATION_STATUS.DISCONNECTED
      );
    });

    const authSettingsUrl = computed(() => {
      // open the auth item for the user
      return `/profile/${profileId.value}/settings?auth_id=${instagramAuthorization.value?._id.$oid}`;
    });

    provide('imageGrid', {
      hasAuthWarning,
    });

    return {
      ...rest,
      isSavingOptions,
      options,
      saveOptions,
      hasSetup,
      items,
      hasAuthWarning,
      authSettingsUrl,
    };
  },
};
</script>

<style scoped></style>
