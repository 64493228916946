<template>
  <Alert>
    <InfoIcon slot="icon" />
    You don't have access to reports on this profile.
  </Alert>
</template>

<script>
import { Alert, InfoIcon } from '@campsite-bio/component-lib';

export default {
  components: {
    Alert,
    InfoIcon,
  },
};
</script>

<style lang="scss" scoped></style>
