<template>
  <AccordionSectionItem title="Add a link to your profile" :variant="variant">
    <span slot="icon">{{ step }}</span>

    <VText gutter-bottom>
      Adding a link is the first step in your Campsite.bio journey.
    </VText>

    <ol>
      <li>Click the <b>Add Link</b> button.</li>
      <li>Add a label to your link. This will appear as the link text.</li>
      <li>Paste or type in your url.</li>
      <li>
        Switch the toggle in the bottom left corner to enable your link
        <VisibilityIcon />.
      </li>
    </ol>

    <img
      src="~images/getting-started/editing-first-link.gif"
      alt="Adding a new link"
      loading="lazy"
      style="margin-bottom: 2rem"
    />

    <AccordionItemGroup style="margin-bottom: 1.5em">
      <AccordionItem
        variant="outline"
        title="Why is my link not visible on my profile?"
      >
        <VText gutter-bottom
          >A link won't be visible on your profile until you enable it.</VText
        >

        <img
          src="~images/getting-started/enable-link.gif"
          alt="Enabling a new link"
          loading="lazy"
          style="margin-bottom: 2rem"
        />
      </AccordionItem>
    </AccordionItemGroup>

    <CardActions class="actions" align="left">
      <Button :href="`/profile/${id}`" @click.prevent="handleClick">
        Open Links Page
      </Button>
      <Button
        href="https://support.campsite.bio/en/articles/6818213-getting-started-with-campsite-bio?utm_campaign=getting-set-up&utm_source=add-link"
        target="_blank"
        variant="outline"
        >Learn More</Button
      >
    </CardActions>
  </AccordionSectionItem>
</template>

<script>
import {
  AccordionSectionItem,
  AccordionItem,
  AccordionItemGroup,
  VisibilityIcon,
  VText,
  Button,
  CardActions,
} from '@campsite-bio/component-lib';

import { useScrollToRoute } from './use-scroll-to-route';
import { useCurrentProfile } from '../../../compositions';

export default {
  components: {
    AccordionSectionItem,
    AccordionItem,
    AccordionItemGroup,
    VisibilityIcon,
    VText,
    Button,
    CardActions,
  },

  props: {
    step: {
      type: [String, Number],
      required: true,
    },

    variant: {
      type: String,
      default: null,
    },
  },

  setup() {
    const { handleClick } = useScrollToRoute('.js-config-header');
    const { id } = useCurrentProfile();

    return { handleClick, id };
  },
};
</script>

<style lang="scss" scoped>
.actions.actions {
  margin: 0;
  padding: 0;
}
</style>
