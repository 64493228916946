<template>
  <List class="source-item">
    <ListItem>
      <ListItemContent>
        <ListItemTitle>{{ title }}</ListItemTitle>
        <ListItemSubtitle>{{ subtitle }}</ListItemSubtitle>
      </ListItemContent>
      <ListItemAction v-if="!hideAction">
        <Button variant="flat" @click="$emit('click', $event)">Change</Button>
      </ListItemAction>
    </ListItem>
  </List>
</template>

<script>
import {
  List,
  ListItem,
  ListItemContent,
  ListItemTitle,
  ListItemSubtitle,
  ListItemAction,
  Button,
} from '@campsite-bio/component-lib';

export default {
  components: {
    List,
    ListItem,
    ListItemContent,
    ListItemTitle,
    ListItemSubtitle,
    ListItemAction,
    Button,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },

    hideAction: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.source-item {
  border: 1px solid var(--g-color-gray-200);
  border-radius: var(--g-border-radius-standard);
  border-radius: min(
    var(--g-border-radius-standard),
    var(--g-border-radius-min)
  );
  padding: 0;
}
</style>
