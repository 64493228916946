<template>
  <Dialog v-model="isOpen" max-width="380" title="Your QR Code">
    <CardText>
      <template v-if="isLoading">
        <RatioBox class="qr-code" :ratio="1">
          <Skeleton height="100%" width="100%" />
        </RatioBox>
        <VText><Skeleton /></VText>
      </template>
      <template v-else>
        <ProfileQrCode v-bind="qrCodeProps" ref="qrCode" class="qr-code" />
        <VText nowrap
          ><strong>{{ data }}</strong></VText
        >
      </template>
    </CardText>
    <Divider :color="dividerColor" />
    <CardActions>
      <template v-if="isLoading">
        <Skeleton width="100px" height="30px" />
        <Skeleton width="100px" height="30px" />
      </template>
      <template v-else>
        <Button
          to="/qr-code"
          variant="outline"
          size="small"
          @click.native="isOpen = false"
          >Customize</Button
        >
        <Button size="small" @click="download">Download</Button>
      </template>
    </CardActions>
  </Dialog>
</template>

<script>
import {
  CardText,
  CardActions,
  Skeleton,
  RatioBox,
  VText,
  Divider,
  Button,
  colors,
} from '@campsite-bio/component-lib';
import { computed, ref, watch } from 'vue';

import { ProfileQrCode } from '../qr';
import { useQrCode, useStore } from '../../compositions';
import Dialog from './dialog';

export default {
  components: {
    CardText,
    CardActions,
    ProfileQrCode,
    Skeleton,
    RatioBox,
    VText,
    Divider,
    Button,
    Dialog,
  },

  setup() {
    const store = useStore();
    const { qrCodeProps, data, qrCode, download } = useQrCode();
    const isQrDialogOpen = computed(
      () => store.getters['qrCode/isQrDialogOpen'],
    );
    const isOpen = ref(isQrDialogOpen.value);
    const isLoading = ref(true);

    async function load() {
      isLoading.value = true;
      try {
        await store.dispatch('qrCode/findOrCreate');
      } catch (e) {
        console.error(e);
      }
      isLoading.value = false;
    }

    watch(isQrDialogOpen, (value) => {
      isOpen.value = value;
      if (value) load();
    });
    watch(isOpen, (value) => {
      store.commit('qrCode/setIsQrDialogOpen', value);
    });

    return {
      download,
      qrCode,
      qrCodeProps,
      isOpen,
      isLoading,
      data,
      dividerColor: colors.grayLight,
    };
  },
};
</script>

<style lang="scss" scoped>
.qr-code {
  margin-bottom: 1rem;
}
</style>
