import { captureException } from '@sentry/browser';
import { computed, ref, watch } from 'vue';
import { useStore, useMeta } from '../../compositions';

export function useGettingStarted() {
  const store = useStore();
  const isActive = ref(false);

  const user = computed(() => {
    return store.getters.currentUser;
  });
  const gettingStartedData = useMeta('getting_started', {
    defaultValue: null,
    itemRef: user,
  });

  watch(
    gettingStartedData,
    (data) => {
      isActive.value = !!data;
    },
    { immediate: true },
  );

  async function completeChecklist(completed = true) {
    try {
      await store.dispatch('deleteGettingStarted', {
        completed,
      });
      isActive.value = false;
    } catch (e) {
      captureException(e);
      console.error(e);
    }
  }

  async function dismissChecklist() {
    await completeChecklist(false);
  }

  async function updateChecklist(checklist) {
    try {
      await store.dispatch('updateGettingStarted', {
        getting_started: checklist,
      });
    } catch (e) {
      captureException(e);
      console.error(e);
    }
  }

  return {
    isActive,
    attrs: {
      data: gettingStartedData,
    },
    on: {
      'update:data': updateChecklist,
      complete: completeChecklist,
      dismiss: dismissChecklist,
    },
  };
}
