<template>
  <LinkExpander
    :id="`default-${linkId}`"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template slot="header">
      Contact details
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <IconButton
            href="https://support.campsite.bio/en/articles/6946567-contact-details-link"
            target="_blank"
            aria-label="Contact details help"
            size="1.25rem"
            v-bind="attrs"
            v-on="on"
          >
            <QuestionCircleOIcon />
          </IconButton>
        </template>
        <span>Get some help setting up your contact details</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="form">
      <VText gutter-bottom
        >Display your contact details for visitors. Only the information you
        enter will be displayed.</VText
      >

      <VText component="h3" variant="h5" class="section-title">Name</VText>
      <div class="settings-grid">
        <div class="settings-grid__50">
          <ValidationProvider
            v-slot="{ errors, ariaInput, ariaMsg }"
            rules="max:100"
            name="first name"
            slim
          >
            <FormGroup :gutter-bottom="false">
              <Label :for="`first_name${_uid}`" gutter-bottom>First name</Label>
              <Input
                :id="`first_name${_uid}`"
                v-model.trim="firstNameLocal"
                :has-error="errors.length > 0"
                maxlength="100"
                autocomplete="given-name"
                placeholder="John"
                v-bind="ariaInput"
              />
              <FormGroupHelp color="error" v-bind="ariaMsg">{{
                errors[0]
              }}</FormGroupHelp>
            </FormGroup>
          </ValidationProvider>
        </div>
        <div class="settings-grid__50">
          <ValidationProvider
            v-slot="{ errors, ariaInput, ariaMsg }"
            rules="max:100"
            name="last name"
            slim
          >
            <FormGroup :gutter-bottom="false">
              <Label :for="`last_name${_uid}`" gutter-bottom>Last name</Label>
              <Input
                :id="`last_name${_uid}`"
                v-model.trim="lastNameLocal"
                :has-error="errors.length > 0"
                maxlength="100"
                autocomplete="family-name"
                placeholder="Smith"
                v-bind="ariaInput"
              />
              <FormGroupHelp color="error" v-bind="ariaMsg">{{
                errors[0]
              }}</FormGroupHelp>
            </FormGroup>
          </ValidationProvider>
        </div>
      </div>

      <VText component="h3" variant="h5" class="section-title"
        >Occupation</VText
      >
      <ValidationProvider
        v-slot="{ errors, ariaInput, ariaMsg }"
        rules="max:100"
        name="organization"
        slim
      >
        <FormGroup>
          <Label :for="`organization${_uid}`" gutter-bottom>Organization</Label>
          <Input
            :id="`organization${_uid}`"
            v-model.trim="organizationLocal"
            :has-error="errors.length > 0"
            maxlength="100"
            autocomplete="organization"
            placeholder="Acme Studios"
            v-bind="ariaInput"
          />
          <FormGroupHelp color="error" v-bind="ariaMsg">{{
            errors[0]
          }}</FormGroupHelp>
        </FormGroup>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors, ariaInput, ariaMsg }"
        rules="max:100"
        name="position"
        slim
      >
        <FormGroup>
          <Label :for="`position${_uid}`" gutter-bottom>Position</Label>
          <Input
            :id="`position${_uid}`"
            v-model.trim="positionLocal"
            :has-error="errors.length > 0"
            maxlength="100"
            autocomplete="organization-title"
            placeholder="Acme Studios"
            v-bind="ariaInput"
          />
          <FormGroupHelp color="error" v-bind="ariaMsg">{{
            errors[0]
          }}</FormGroupHelp>
        </FormGroup>
      </ValidationProvider>

      <VText component="h3" variant="h5" class="section-title">Email</VText>
      <div class="settings-grid">
        <template v-for="({ type, value }, i) in emailsLocal">
          <div :key="`type${i}`" class="settings-grid__33">
            <ValidationProvider
              v-slot="{ errors, ariaInput, ariaMsg }"
              :rules="determineRules('email', i, 'value')"
              :name="`type ${i + 1}`"
              slim
            >
              <FormGroup :gutter-bottom="false">
                <Label :for="`type${i}${_uid}`" gutter-bottom>Type</Label>
                <Select
                  :id="`type${i}${_uid}`"
                  :value="type"
                  :has-error="errors.length > 0"
                  v-bind="ariaInput"
                  @input="updateArrayValue('email', i, 'type', $event)"
                >
                  <option :value="null"></option>
                  <option value="work">Work</option>
                  <option value="personal">Personal</option>
                  <option value="other">Other</option>
                </Select>
                <FormGroupHelp color="error" v-bind="ariaMsg">{{
                  errors[0]
                }}</FormGroupHelp>
              </FormGroup>
            </ValidationProvider>
          </div>
          <div :key="`email${i}`" class="settings-grid__66">
            <ValidationProvider
              v-slot="{ errors, ariaInput, ariaMsg }"
              :rules="{
                email: true,
                ...determineRules('email', i, 'type'),
              }"
              :name="`email address ${i + 1}`"
              slim
            >
              <FormGroup :gutter-bottom="false">
                <Label :for="`email${i}${_uid}`" gutter-bottom>Address</Label>
                <Input
                  :id="`email${i}${_uid}`"
                  :value="value"
                  type="email"
                  :has-error="errors.length > 0"
                  autocomplete="email"
                  placeholder="john.smith@gmail.com"
                  v-bind="ariaInput"
                  @input="updateArrayValue('email', i, 'value', $event)"
                />
                <FormGroupHelp color="error" v-bind="ariaMsg">{{
                  errors[0]
                }}</FormGroupHelp>
              </FormGroup>
            </ValidationProvider>
          </div>
        </template>
      </div>

      <VText component="h3" variant="h5" class="section-title">Phone</VText>
      <div class="settings-grid">
        <template v-for="({ type, value }, i) in phoneNumbersLocal">
          <div :key="`type${i}`" class="settings-grid__33">
            <ValidationProvider
              v-slot="{ errors, ariaInput, ariaMsg }"
              :rules="determineRules('phone', i, 'value')"
              :name="`type ${i + 1}`"
              slim
            >
              <FormGroup :gutter-bottom="false">
                <Label :for="`typephone${i}${_uid}`" gutter-bottom>Type</Label>
                <Select
                  :id="`typephone${i}${_uid}`"
                  :value="type"
                  :has-error="errors.length > 0"
                  v-bind="ariaInput"
                  @input="updateArrayValue('phone', i, 'type', $event)"
                >
                  <option :value="null"></option>
                  <option value="mobile">Mobile</option>
                  <option value="work">Work</option>
                  <option value="home">Home</option>
                  <option value="other">Other</option>
                </Select>
                <FormGroupHelp color="error" v-bind="ariaMsg">{{
                  errors[0]
                }}</FormGroupHelp>
              </FormGroup>
            </ValidationProvider>
          </div>
          <div :key="`phone${i}`" class="settings-grid__66">
            <ValidationProvider
              v-slot="{ errors, ariaInput, ariaMsg }"
              :rules="determineRules('phone', i, 'type')"
              :name="`phone number ${i + 1}`"
              slim
            >
              <FormGroup :gutter-bottom="false">
                <Label :for="`phone${i}${_uid}`" gutter-bottom>Number</Label>
                <Input
                  :id="`phone${i}${_uid}`"
                  :value="value"
                  type="tel"
                  :has-error="errors.length > 0"
                  autocomplete="tel"
                  placeholder="123-123-1234"
                  v-bind="ariaInput"
                  @input="updateArrayValue('phone', i, 'value', $event)"
                />
                <FormGroupHelp color="error" v-bind="ariaMsg">{{
                  errors[0]
                }}</FormGroupHelp>
              </FormGroup>
            </ValidationProvider>
          </div>
        </template>
      </div>

      <VText component="h3" variant="h5" class="section-title">Address</VText>
      <AddressForm />

      <VText component="h3" variant="h5" class="section-title">
        Website
        <Badge content="Pro" />
      </VText>
      <ValidationProvider
        v-slot="{ errors, ariaInput, ariaMsg }"
        rules="url"
        name="website"
        slim
      >
        <FormGroup>
          <Label :for="`website${_uid}`" gutter-bottom>URL</Label>
          <Input
            :id="`website${_uid}`"
            v-model.trim="websiteLocal"
            type="url"
            :has-error="errors.length > 0"
            autocomplete="url"
            placeholder="https://mywebsite.com"
            :disabled="isFreePlan"
            v-bind="ariaInput"
          />
          <FormGroupHelp color="error" v-bind="ariaMsg">{{
            errors[0]
          }}</FormGroupHelp>
        </FormGroup>
      </ValidationProvider>

      <FormGroup>
        <Toggle v-model="hideProfileUrlLocal" :disabled="isFreePlan"
          >Hide profile URL</Toggle
        >
        <FormGroupHelp
          >Remove the profile URL that is added to your contact
          details.</FormGroupHelp
        >
      </FormGroup>

      <FormGroup style="margin-top: 2rem" :gutter-bottom="false">
        <Label :for="`open${_uid}`" gutter-bottom>Opening Hours</Label>
        <Input
          :id="`open${_uid}`"
          v-model.trim="hoursLocal"
          component="textarea"
          maxlength="100"
          placeholder="Monday-Friday 12-6pm"
          rows="3"
        />
      </FormGroup>
    </ValidationObserver>
  </LinkExpander>
</template>

<script>
import {
  Label,
  FormGroup,
  FormGroupHelp,
  IconButton,
  QuestionCircleOIcon,
  Input,
  Select,
  Toggle,
  VText,
  Badge,
} from '@campsite-bio/component-lib';
import { computed, provide, nextTick, onBeforeUnmount, ref, watch } from 'vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import debounce from 'lodash/debounce';

import LinkExpander from '../expand/link-expander';
import AddressForm from '../../address/address-form';
import { useStore } from '../../../compositions';

export default {
  components: {
    FormGroup,
    FormGroupHelp,
    Label,
    Input,
    Select,
    IconButton,
    QuestionCircleOIcon,
    Toggle,
    Badge,
    VText,
    LinkExpander,
    ValidationObserver,
    ValidationProvider,
    AddressForm,
  },

  props: {
    isOpen: Boolean,
    contactDetailOptions: {
      type: Object,
      required: true,
    },
    link: {
      type: Object,
      required: true,
    },
    linkId: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const form = ref(null);
    const ignoreChanges = ref(false);
    const dirty = ref(false);

    const isFreePlan = computed(() => {
      return store.getters['profiles/isProfileOnFreePlan'];
    });

    const profile = computed(() => {
      return store.getters['profiles/currentProfile'];
    });

    const firstNameLocal = ref(null);
    const lastNameLocal = ref(null);
    const organizationLocal = ref(null);
    const positionLocal = ref(null);
    const address1Local = ref(null);
    const address2Local = ref(null);
    const cityLocal = ref(null);
    const stateLocal = ref(null);
    const postalCodeLocal = ref(null);
    const countryLocal = ref(null);
    const hoursLocal = ref(null);
    const websiteLocal = ref(null);
    const hideProfileUrlLocal = ref(false);
    const emailsLocal = ref([]);
    const phoneNumbersLocal = ref([]);

    const address = computed(() => {
      const { address_1, address_2, city, state, postal_code, country } =
        props.contactDetailOptions;
      return {
        line1: address_1,
        line2: address_2,
        city,
        state,
        postal_code,
        country,
      };
    });

    provide('address', {
      address,
      addressLine1: address1Local,
      addressLine2: address2Local,
      city: cityLocal,
      state: stateLocal,
      postalCode: postalCodeLocal,
      country: countryLocal,
      isLoading: false,
    });

    watch(
      () => props.contactDetailOptions,
      async ({
        first_name,
        last_name,
        organization,
        position,
        address_1,
        address_2,
        city,
        state,
        postal_code,
        country,
        emails,
        phone_numbers,
        hours,
        website,
        hide_profile_url,
      }) => {
        ignoreChanges.value = true;
        firstNameLocal.value = first_name;
        lastNameLocal.value = last_name;
        organizationLocal.value = organization;
        positionLocal.value = position;
        address1Local.value = address_1;
        address2Local.value = address_2;
        cityLocal.value = city;
        stateLocal.value = state;
        postalCodeLocal.value = postal_code;
        countryLocal.value = country;
        websiteLocal.value = website;
        hideProfileUrlLocal.value = hide_profile_url;
        createDefaultValues(emailsLocal, emails);
        createDefaultValues(phoneNumbersLocal, phone_numbers);
        hoursLocal.value = hours;
        dirty.value = false;
        await nextTick();
        ignoreChanges.value = false;
      },
      { immediate: true },
    );

    watch(dirty, (newVal) => emit('change:dirty', newVal));

    function createDefaultValues(ref, incomingValues, length = 2) {
      const values = Array.from({ length }).map((_, i) => {
        return {
          type: null,
          value: null,
          ...(incomingValues[i] || {}),
        };
      });
      ref.value = values;
    }

    function getRefFromKey(name) {
      switch (name) {
        case 'email':
          return emailsLocal;
        case 'phone':
          return phoneNumbersLocal;
      }
    }

    function updateArrayValue(name, i, key, value) {
      const ref = getRefFromKey(name);
      ref.value.splice(i, 1, { ...ref.value[i], [key]: value?.trim() });
    }

    function determineRules(name, i, valueKey) {
      const ref = getRefFromKey(name);

      // If the other field is filled out, this one is required
      if (ref.value[i][valueKey]) return { required: true };
      return '';
    }

    const websites = computed(() => {
      const baseSite = {
        value: `https://${profile.value.domain}/${profile.value.username_lowercase}`,
        type: 'profile',
      };
      if (isFreePlan.value) {
        return [baseSite];
      } else {
        const website = websiteLocal.value
          ? [{ value: websiteLocal.value }]
          : [];
        // If they want, they can hide their profile URL
        if (hideProfileUrlLocal.value) return [...website];
        else return [...website, baseSite];
      }
    });

    async function save() {
      const success = await form.value.validate();
      if (!success) return;

      const VCard = (await import('vcard-creator')).default;
      const myVCard = new VCard();

      myVCard
        .addName(lastNameLocal.value || '', firstNameLocal.value || '')
        .addCompany(organizationLocal.value || '')
        .addJobtitle(positionLocal.value || '')
        .addAddress(
          '',
          '',
          [address1Local.value, address2Local.value]
            .filter((value) => value)
            .join(' ') || '', // street
          cityLocal.value || '',
          stateLocal.value?.name || '',
          postalCodeLocal.value || '',
          countryLocal.value?.name || '',
        );

      emailsLocal.value
        .filter((v) => v && v.value)
        .forEach(({ value, type }) => {
          myVCard.addEmail(value, type?.toUpperCase());
        });
      phoneNumbersLocal.value
        .filter((v) => v && v.value)
        .forEach(({ value, type }) => {
          myVCard.addPhoneNumber(value, type?.toUpperCase());
        });
      websites.value.forEach(({ value }) => {
        myVCard.addURL(value);
      });

      emit('save', {
        first_name: firstNameLocal.value,
        last_name: lastNameLocal.value,
        organization: organizationLocal.value,
        position: positionLocal.value,
        address_1: address1Local.value,
        address_2: address2Local.value,
        city: cityLocal.value,
        state: stateLocal.value,
        postal_code: postalCodeLocal.value,
        country: countryLocal.value,
        emails: emailsLocal.value,
        website: websiteLocal.value,
        phone_numbers: phoneNumbersLocal.value,
        hours: hoursLocal.value,
        hide_profile_url: hideProfileUrlLocal.value,
        vcard: myVCard.toString(),
      });

      await nextTick();
      dirty.value = false;
    }

    const saveDebounced = debounce(save, 1000);

    watch(
      [emailsLocal, phoneNumbersLocal],
      () => {
        if (!ignoreChanges.value) {
          dirty.value = true;
          saveDebounced();
        }
      },
      {
        deep: true,
      },
    );

    watch(
      [
        firstNameLocal,
        lastNameLocal,
        organizationLocal,
        positionLocal,
        postalCodeLocal,
        address1Local,
        address2Local,
        cityLocal,
        stateLocal,
        postalCodeLocal,
        countryLocal,
        hoursLocal,
        websiteLocal,
        hideProfileUrlLocal,
      ],
      () => {
        if (!ignoreChanges.value) {
          dirty.value = true;
          saveDebounced();
        }
      },
    );

    onBeforeUnmount(() => {
      dirty.value = false;
    });

    return {
      form,
      isFreePlan,
      firstNameLocal,
      lastNameLocal,
      organizationLocal,
      positionLocal,
      address1Local,
      address2Local,
      cityLocal,
      stateLocal,
      postalCodeLocal,
      countryLocal,
      emailsLocal,
      phoneNumbersLocal,
      hoursLocal,
      websiteLocal,
      hideProfileUrlLocal,
      updateArrayValue,
      determineRules,
    };
  },
};
</script>

<style lang="scss" scoped>
.settings-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem 1.5rem;

  &__33 {
    grid-column: span 4;
  }

  &__50 {
    grid-column: span 6;
  }

  &__66 {
    grid-column: span 8;
  }
}

.section-title.section-title {
  margin-top: 1.75rem;
  margin-bottom: 0.5rem;
}
</style>
