<template>
  <div class="account-start">
    <Card>
      <CardTitle>
        <VText component="h2" variant="h4">Getting Started</VText>
      </CardTitle>
      <CardText>
        <VText
          >Let us show you how to get started on the right foot. Learn how to
          setup your profile.</VText
        >
      </CardText>
      <CardActions align="left">
        <Button
          size="small"
          href="https://support.campsite.bio/en/articles/6818213-getting-started-with-campsite-bio"
          target="_blank"
          >View Article</Button
        >
        <Button size="small" variant="outline" @click="startIntroTour"
          >Intro Tour</Button
        >
      </CardActions>
    </Card>
    <Card>
      <CardTitle>
        <VText component="h2" variant="h4">Make My Bio Link 🪄</VText>
      </CardTitle>
      <CardText>
        <VText gutter-bottom
          >Let us do all the hard work by creating a profile from your
          website.</VText
        >
        <VText v-if="isFreePlan && !hasPaidWizard"
          >Pay a one time, $5 charge.</VText
        >
      </CardText>
      <CardActions align="left">
        <Button
          size="small"
          :to="`/profile/${profileId}/settings/make-my-bio-link`"
          >Learn More</Button
        >
      </CardActions>
    </Card>
  </div>
</template>

<script>
import {
  Card,
  CardTitle,
  CardText,
  CardActions,
  Button,
  VText,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';

import { useMeta, useStore } from '../../compositions';

export default {
  components: {
    Card,
    CardTitle,
    CardText,
    CardActions,
    Button,
    VText,
  },

  setup() {
    const store = useStore();

    const user = computed(() => store.getters.currentUser);
    const hasPaidWizard = useMeta('wizard_paid', {
      defaultValue: false,
      itemRef: user,
    });

    const isFreePlan = computed(() => {
      return store.getters.isFreePlan;
    });

    function startIntroTour() {
      import('../../tours/config').then((ConfigTour) => {
        new ConfigTour.default();
      });
    }

    return {
      profileId: computed(() => store.getters['profiles/currentProfileId']),
      isFreePlan,
      hasPaidWizard,
      startIntroTour,
    };
  },
};
</script>

<style lang="scss" scoped>
.account-start {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-top: 1.75rem;

  &::v-deep .v-card {
    display: flex;
    flex-direction: column;
  }

  &::v-deep .v-card__text {
    flex-grow: 1;
  }
}
</style>
