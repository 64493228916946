<template>
  <Card border-color="primary">
    <CardTitle>
      <VText component="h2" variant="h5">Setup your feed link</VText>
      <FlexSpacer />
      <IconButton
        size="1.25rem"
        :loading="isDeleting"
        title="Delete"
        aria-label="Delete"
        @click="$emit('remove')"
      >
        <CloseIcon />
      </IconButton>
    </CardTitle>
    <Divider color="primary" />
    <CardText>
      <ValidationObserver v-slot="{ invalid, handleSubmit }" ref="form" slim>
        <form @submit.prevent="handleSubmit(handleSetup)">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            name="source"
            slim
          >
            <FormGroup :gutter-bottom="!!selectedSource">
              <Label component="span" block gutter-bottom
                >Choose your feed source</Label
              >
              <BoxInputGroup>
                <BoxInput
                  v-for="(
                    { name, source, iconComponent, color }, index
                  ) in feedTypes"
                  :key="source"
                  v-model="selectedSource"
                  :name="`feed${linkId}`"
                  :input-id="`feed${index}${linkId}`"
                  :input-value="source"
                  :tooltip="name"
                >
                  <component :is="iconComponent" slot="icon" :color="color" />
                </BoxInput>
              </BoxInputGroup>
              <FormGroupHelp color="error">{{ errors[0] }}</FormGroupHelp>
            </FormGroup>
          </ValidationProvider>

          <template v-if="selectedSource">
            <ValidationProvider
              v-slot="{ errors, reset }"
              ref="feedUrlProvider"
              rules="required|url"
              name="feed url"
              mode="aggressive"
            >
              <SourceYoutube
                v-if="selectedSource === 'youtube'"
                v-model="feedUrl"
                :error="errors[0] || error"
                @clear="reset"
                @input:title="title = $event"
              />
              <SourceCustom
                v-else-if="selectedSource === 'custom'"
                v-model="feedUrl"
                :source-name="selectedSourceDetails.name"
                :error="errors[0] || error"
              />
              <SourceBlog
                v-else
                v-model="feedUrl"
                :source-name="selectedSourceDetails.name"
                :error="errors[0] || error"
              />
            </ValidationProvider>
            <Button type="submit" :loading="isSaving" :disabled="invalid"
              >Finish Setup</Button
            >
          </template>
        </form>
      </ValidationObserver>
    </CardText>
  </Card>
</template>

<script>
import {
  Card,
  CardText,
  CardTitle,
  Button,
  Divider,
  Input,
  Label,
  FormGroup,
  FormGroupHelp,
  VText,
  IconButton,
  CloseIcon,
  FlexSpacer,
  YoutubeIcon,
  WordpressIcon,
  BloggerIcon,
  SquarespaceIcon,
  ShopifyIcon,
  WixLogoIcon,
  TumblrIcon,
  MediumIcon,
  SubstackIcon,
  RssIcon,
} from '@campsite-bio/component-lib';
import { computed, nextTick, ref, watch } from 'vue';
import axios from 'axios';
import { FEED_TYPES } from './feed-types';
import { SourceBlog, SourceYoutube, SourceCustom } from './sources';
import { useStore } from '../../../compositions';
import { BoxInput, BoxInputGroup } from '../../box-input';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import toast from '../../../utils/toast';
import config from '../../../config';
import { localAxios } from '@/apis';

export default {
  components: {
    Card,
    CardText,
    CardTitle,
    Button,
    Divider,
    Input,
    FormGroup,
    FormGroupHelp,
    Label,
    VText,
    FlexSpacer,
    IconButton,
    CloseIcon,
    SourceBlog,
    SourceYoutube,
    SourceCustom,
    YoutubeIcon,
    WordpressIcon,
    BloggerIcon,
    SquarespaceIcon,
    ShopifyIcon,
    WixLogoIcon,
    TumblrIcon,
    MediumIcon,
    SubstackIcon,
    RssIcon,
    BoxInput,
    BoxInputGroup,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    feedOptions: {
      type: Object,
      required: true,
    },

    linkId: {
      type: String,
      required: true,
    },

    isDeleting: Boolean,
  },

  setup(props, { emit }) {
    const store = useStore();
    const selectedSource = ref(null);
    const feedUrl = ref(null);
    const title = ref(null);
    const isSaving = ref(false);
    const error = ref(null);
    const form = ref(null);

    const selectedSourceDetails = computed(() => {
      if (!selectedSource.value) return null;
      return FEED_TYPES.find((type) => type.source === selectedSource.value);
    });

    watch(selectedSource, async () => {
      error.value = null;
      feedUrl.value = null;
      if (form.value) {
        await nextTick();
        form.value.reset();
      }
    });

    async function handleSetup() {
      error.value = null;
      isSaving.value = true;

      try {
        // Get the feed URL
        const { data } = await localAxios.get('/api/utils/feed-finder', {
          params: {
            url: feedUrl.value,
            source: selectedSource.value,
          },
        });

        // Save the result to the feed options
        const { link } = await store.dispatch('links/updateOptions', {
          id: props.linkId,
          name: 'feed',
          source: selectedSource.value,
          feed: data.feed,
          has_setup: true,
          title: title.value,
        });

        store.commit('links/updateLinkUrl', {
          id: props.linkId,
          value: feedUrl.value,
        });
        // Show the link now that it's been setup
        store.commit('links/updateLinkOptions', {
          id: props.linkId,
          value: link.feed_options,
          name: 'feed_options',
        });
        emit('setup-complete');
      } catch (e) {
        console.error(e);
        if (e.response && e.response.status === 404) {
          error.value =
            "We couldn't find a feed source with the URL you've provided. Try entering a URL to one of your blog posts instead.";
        } else toast.error('There was an error trying to get your feed source');
      }

      isSaving.value = false;
    }

    return {
      feedTypes: FEED_TYPES,
      selectedSource,
      selectedSourceDetails,
      feedUrl,
      title,
      handleSetup,
      isSaving,
      error,
      form,
    };
  },
};
</script>

<style lang="scss" scoped></style>
