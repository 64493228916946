<template>
  <v-menu offset-y max-width="220px" max-height="400px">
    <template #activator="{ on, attrs }">
      <FilterBtn :disabled="menuItems.length === 0" v-bind="attrs" v-on="on">
        <Avatar v-if="encodedImageSrc" size="1em">
          <img :src="encodedImageSrc" alt="" />
        </Avatar>
        <span class="display: inline-flex">
          {{ profile ? profile.username : '-' | truncate(20) }}
        </span>
        <ChevronIcon />
      </FilterBtn>
    </template>

    <ProfileSelectorList v-model="selectedProfile" :items="menuItems" />
  </v-menu>
</template>

<script>
import {
  ChevronIcon,
  Avatar,
  convertImageUrlToEncodedUrl,
  MEDIA_SIZES,
} from '@campsite-bio/component-lib';
import { computed, onMounted, ref, toRefs } from 'vue';

import FilterBtn from './filter-btn';
import ProfileSelectorList from '../../profiles/profile-selector-list';
import { useModel, useStore } from '../../../compositions';

export default {
  components: {
    FilterBtn,
    ChevronIcon,
    Avatar,
    ProfileSelectorList,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const { value } = toRefs(props);
    const selectedProfile = useModel(value, (val) => emit('input', val));
    const profiles = computed(() =>
      store.getters['analytics/getField']('allProfiles'),
    );

    const profile = computed(() => {
      return profiles.value?.find((p) => p._id.$oid === selectedProfile.value);
    });

    const encodedImageSrc = computed(() => {
      if (!profile.value) return null;
      return convertImageUrlToEncodedUrl(
        profile.value?.profile_image,
        MEDIA_SIZES.profile_image,
      );
    });

    const menuItems = computed(() => {
      return profiles.value
        ?.map((profile) => {
          return {
            name: profile.username,
            value: profile._id.$oid,
          };
        })
        .sort((a, b) => a.name?.localeCompare(b.name));
    });

    async function load() {
      try {
        await store.dispatch('analytics/loadProfiles');
      } catch (e) {
        console.error(e);
      }
    }

    onMounted(load);

    return {
      selectedProfile,
      profile,
      menuItems,
      encodedImageSrc,
    };
  },
};
</script>

<style lang="scss" scoped></style>
