<template>
  <Fragment>
    <StatCard
      v-for="{ name, key } in HIGHLIGHT_FIELDS"
      :key="key"
      class="span-3"
      :title="name"
      :is-loading="isLoading"
      :badge="highlights[`${key}Ctr`]"
      :badge-color="highlights[`${key}CtrColor`]"
    >
      {{ highlights[key] | numberFormatter(2) }}
    </StatCard>
  </Fragment>
</template>

<script>
import { toRefs } from 'vue';
import { Fragment } from '@campsite-bio/component-lib';

import { StatCard } from '..';
import { useReportHighlights } from '../compositions/use-report-highlights';

const HIGHLIGHT_FIELDS = [
  { name: 'Total', key: 'total_events' },
  { name: 'Unique', key: 'unique_events' },
  { name: 'Sessions w/ Events', key: 'sessions_with_event' },
];

export default {
  components: {
    Fragment,
    StatCard,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const { data } = toRefs(props);

    const highlights = useReportHighlights(
      HIGHLIGHT_FIELDS,
      data,
      'topline_events',
    );

    return {
      HIGHLIGHT_FIELDS,
      highlights,
    };
  },
};
</script>

<style lang="scss" scoped></style>
