import { ref } from 'vue';

import { useStore } from '../use-store';
import toast from '@/utils/toast';

const TIME_BETWEEN_SENDS = 30;

export function useResendEmailConfirmation(variant = 'user') {
  const isSendingConfirmation = ref(false);
  const isEmailResendEnabled = ref(true);
  const isCancellingEmailChange = ref(false);
  const emailResendTimePassed = ref(0);
  const resendEmailButtonCopy = ref('Resend Confirmation');
  const store = useStore();

  const resendEmailStoreName =
    variant === 'user'
      ? 'resendEmailConfirmation'
      : 'organizations/resendEmailConfirmation';
  const cancelEmailChangeStoreName =
    variant === 'user'
      ? 'cancelEmailChange'
      : 'organizations/cancelEmailChange';

  function resendTimer() {
    setTimeout(() => {
      emailResendTimePassed.value += 1;
      if (emailResendTimePassed.value >= TIME_BETWEEN_SENDS) {
        isEmailResendEnabled.value = true;
        emailResendTimePassed.value = 0;
        resendEmailButtonCopy.value = 'Resend Confirmation';
      } else {
        resendEmailButtonCopy.value = `Resend in ${
          TIME_BETWEEN_SENDS - emailResendTimePassed.value
        } s`;
        resendTimer();
      }
    }, 1000);
  }

  async function resendEmailConfirmation() {
    if (isSendingConfirmation.value) return;

    isSendingConfirmation.value = true;

    try {
      await store.dispatch(resendEmailStoreName);

      toast.success(
        "Email confirmation sent (it may take a minute to arrive). Please check your inbox. If you're not receiving a confirmation, please double check your email address.",
        { multiline: true, timeout: 6000 },
      );
      isEmailResendEnabled.value = false;
      resendTimer();
    } catch (e) {
      const { error_message } = e.response?.data || {};
      console.error(e);
      toast.error(error_message || 'Error sending an email confirmation');
    }

    isSendingConfirmation.value = false;
  }

  async function cancelEmailChange() {
    if (isCancellingEmailChange.value) return;

    isCancellingEmailChange.value = true;

    try {
      await store.dispatch(cancelEmailChangeStoreName);
    } catch (e) {
      const { error_message } = e.response?.data || {};
      console.error(e);
      toast.error(error_message || 'Error cancelling email change');
    }

    isCancellingEmailChange.value = false;
  }

  return {
    isSendingConfirmation,
    isEmailResendEnabled,
    isCancellingEmailChange,
    emailResendTimePassed,
    resendEmailButtonCopy,
    resendEmailConfirmation,
    cancelEmailChange,
  };
}
