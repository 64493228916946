<template>
  <ChartCard
    title="Top Events"
    :is-loading="isLoading"
    :timestamp="allData ? allData.timestamp : null"
    :padding-bottom="false"
  >
    <SimpleTable
      v-if="hasResults || isLoading"
      gutter-top
      fixed-header
      height="436"
    >
      <thead>
        <tr>
          <th>Name</th>
          <th class="text-right">Events</th>
          <th class="text-right">Unique</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="isLoading && !hasResults">
          <tr v-for="i in 10" :key="i">
            <td><Skeleton width="150px" /></td>
            <td class="text-right"><Skeleton width="30px" /></td>
            <td class="text-right"><Skeleton width="30px" /></td>
          </tr>
        </template>
        <tr v-for="[name, pageViews, uniquePageViews] in eventData" :key="name">
          <td class="td-name" :title="name">
            <span>
              {{ getEventLabel(name) }}
            </span>
          </td>
          <td class="text-right">{{ pageViews | numberFormatter(1) }}</td>
          <td class="text-right">{{ uniquePageViews | numberFormatter(1) }}</td>
        </tr>
      </tbody>
    </SimpleTable>
    <NoResults v-else />
  </ChartCard>
</template>

<script>
import { computed } from 'vue';
import {
  EVENT_TO_LABEL,
  Skeleton,
  SimpleTable,
} from '@campsite-bio/component-lib';

import ChartCard from './chart-card';
import NoResults from './no-results';

export default {
  components: {
    ChartCard,
    Skeleton,
    SimpleTable,
    NoResults,
  },

  props: {
    allData: {
      type: Object,
      default: null,
    },

    data: {
      type: Array,
      default: null,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const eventData = computed(() => {
      if (!props.data) return [];

      const stats = props.data.reduce((acc, { _id, count, unique }) => {
        acc.push([_id, count, unique]);
        return acc;
      }, []);

      return stats.sort((a, b) => b[1] - a[1]).slice(0, 10);
    });

    const hasResults = computed(() => eventData.value.length > 0);

    function getEventLabel(eventName) {
      return EVENT_TO_LABEL[eventName] || eventName;
    }

    return { eventData, hasResults, getEventLabel };
  },
};
</script>

<style lang="scss" scoped></style>
