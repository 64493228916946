<template>
  <LinkExpander
    :id="`default-${linkId}`"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template slot="header">
      Group options
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <IconButton
            href="https://support.campsite.bio/en/articles/7263397-group-link"
            target="_blank"
            aria-label="Group link help"
            size="1.25rem"
            v-bind="attrs"
            v-on="on"
          >
            <QuestionCircleOIcon />
          </IconButton>
        </template>
        <span>Get some help setting up your group link</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="form">
      <ValidationProvider
        v-slot="{ errors, ariaMsg }"
        ref="limitProvider"
        :rules="{
          required: false,
          min_value: 3,
          max_value: 20,
        }"
        name="limit"
        :detect-input="false"
        slim
      >
        <FormGroup :gutter-bottom="false">
          <Label for="limit">Links to show</Label>
          <ChipGroup v-model="limitLocal" aria-label="Number of links to show">
            <Chip
              v-for="limit in LIMIT_OPTIONS"
              :key="limit"
              :value="limit"
              selectable
              >{{ limit }}</Chip
            >
            <Chip :value="null" selectable>No limit</Chip>
            <Chip value="custom" selectable>Custom</Chip>
            <div style="max-width: 80px">
              <Input
                v-if="
                  !LIMIT_OPTIONS.includes(limitLocal) && limitLocal !== null
                "
                v-model.number="actualLimit"
                type="number"
                min="3"
                max="20"
                step="1"
                pattern="[0-9]*"
                style="height: 2rem"
              />
            </div>
          </ChipGroup>
          <FormGroupHelp v-if="errors.length === 0">
            We'll show up to this many links if they're available.
          </FormGroupHelp>
          <FormGroupHelp v-else color="error" v-bind="ariaMsg">
            {{ errors[0] }}
          </FormGroupHelp>
        </FormGroup>
      </ValidationProvider>
    </ValidationObserver>
  </LinkExpander>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  IconButton,
  QuestionCircleOIcon,
  FormGroup,
  Label,
  FormGroupHelp,
  Input,
  ChipGroup,
  Chip,
} from '@campsite-bio/component-lib';
import { ref, watch } from 'vue';
import debounce from 'lodash/debounce';
import uniqueId from 'lodash/uniqueId';

import LinkExpander from '../expand/link-expander';

const LIMIT_OPTIONS = [3, 6, 9, 12, 15];

export default {
  components: {
    FormGroup,
    FormGroupHelp,
    IconButton,
    QuestionCircleOIcon,
    LinkExpander,
    ValidationObserver,
    ValidationProvider,
    Label,
    Input,
    Chip,
    ChipGroup,
  },

  props: {
    isOpen: Boolean,
    linkId: {
      type: String,
      required: true,
    },
    groupOptions: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const form = ref(null);
    const limitProvider = ref(null);
    const id = ref(uniqueId('group'));

    const limitLocal = ref(null);
    const actualLimit = ref(null);

    watch(limitLocal, (val) => {
      if (typeof val === 'number' || val === null) actualLimit.value = val;
      else if (val === 'custom' && !LIMIT_OPTIONS.includes(val))
        actualLimit.value = 15;
    });

    watch(actualLimit, (val) => {
      if (limitProvider.value) limitProvider.value.syncValue(val);
    });

    watch(
      () => props.groupOptions,
      ({ limit }) => {
        limitLocal.value =
          !LIMIT_OPTIONS.includes(limit) && limit !== null ? 'custom' : limit;
        actualLimit.value = limit;
      },
      { immediate: true },
    );

    async function save() {
      if (!form.value) return;

      const success = await form.value.validate();
      if (!success) return;

      emit('save', {
        limit: actualLimit.value,
      });
    }

    watch([actualLimit, limitLocal], debounce(save, 1000));

    return {
      id,
      form,
      limitLocal,
      actualLimit,
      LIMIT_OPTIONS,
      limitProvider,
    };
  },
};
</script>

<style lang="scss" scoped></style>
