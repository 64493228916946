<template>
  <SettingsSection>
    <SettingsSectionHeader>
      <SettingsSectionTitle>Billing</SettingsSectionTitle>
      <Button
        slot="btn"
        variant="outline"
        size="small"
        :loading="isPortalLoading"
        @click="getBillingPortal"
        >Manage</Button
      >
    </SettingsSectionHeader>

    <div style="margin-bottom: 1.5rem">
      <PaymentCards v-if="defaultCard">
        <PaymentCard :card="defaultCard" hide-remove-button is-default />
      </PaymentCards>
      <VText v-else>You don't have a default payment</VText>
    </div>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form action="#" novalidate @submit.prevent="handleSubmit(save)">
        <FormGroup>
          <Label for="description">Description</Label>
          <Input id="description" v-model="description" />
          <FormGroupHelp
            >Add a line of text to your receipt PDF above your
            email</FormGroupHelp
          >
        </FormGroup>

        <ValidationProvider
          v-slot="{ errors, ariaMsg, ariaInput }"
          rules="email"
          name="email"
          slim
        >
          <FormGroup>
            <Label for="billing_email">Billing Email</Label>
            <Input
              id="billing_email"
              v-model="email"
              type="email"
              placeholder="billing@example.com"
              v-bind="ariaInput"
            />
            <FormGroupHelp color="error" v-bind="ariaMsg">{{
              errors[0]
            }}</FormGroupHelp>
            <FormGroupHelp
              >Send your receipts and payment failures to an additional email
              address</FormGroupHelp
            >
          </FormGroup>
        </ValidationProvider>
        <FormActions>
          <Button :loading="isSaving" type="submit">Save</Button>
        </FormActions>
      </form>
    </ValidationObserver>
  </SettingsSection>
</template>

<script>
import {
  Button,
  VText,
  FormGroup,
  Input,
  FormGroupHelp,
  FormActions,
  Label,
} from '@campsite-bio/component-lib';
import { computed, onMounted, ref, watch } from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import {
  SettingsSection,
  SettingsSectionTitle,
  SettingsSectionHeader,
} from '../settings-section';
import { useStore, useUserBillingPortal } from '@/compositions';
import { getCards } from '@/apis/billing';
import PaymentCard from '../billing/payment-card';
import PaymentCards from '../billing/payment-cards';
import toast from '@/utils/toast';

export default {
  components: {
    Button,
    VText,
    SettingsSection,
    SettingsSectionTitle,
    SettingsSectionHeader,
    PaymentCard,
    PaymentCards,
    FormGroup,
    Input,
    FormGroupHelp,
    FormActions,
    Label,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    const store = useStore();
    const cards = ref(null);
    const defaultCardId = ref(null);
    const isSaving = ref(false);
    const description = ref(null);
    const email = ref(null);

    const { isLoading: isPortalLoading, getBillingPortal } =
      useUserBillingPortal();

    const user = computed(() => store.getters.currentUser);

    watch(
      user,
      () => {
        description.value = user.value.invoice_description;
        email.value = user.value.billing_emails
          ? user.value.billing_emails[0]
          : null;
      },
      { immediate: true },
    );

    const defaultCard = computed(() => {
      const card = cards.value?.find((card) => card.id === defaultCardId.value);

      if (card) {
        return card;
      }

      return cards.value?.[0];
    });

    async function loadDefaultCard() {
      try {
        const { cards: allCards, default_card } = await getCards();
        cards.value = allCards;
        defaultCardId.value = default_card;
      } catch (e) {
        console.error(e);
        toast.error('Error getting your payment methods');
      }
    }

    onMounted(() => {
      loadDefaultCard();
    });

    async function save() {
      if (isSaving.value) return;

      isSaving.value = true;

      try {
        await store.dispatch('updateBillingSettings', {
          type: 'details',
          description: description.value,
          email: email.value ? [email.value] : null,
        });

        toast.success('Your billing details have been updated');
      } catch (e) {
        const { error_message } = e.response?.data || {};
        toast.error(
          error_message ||
            'There was an error trying to update your billing details. Please try again soon.',
          { timeout: 5000 },
        );
      }

      isSaving.value = false;
    }

    return {
      cards,
      defaultCard,
      description,
      email,
      save,
      isSaving,
      isPortalLoading,
      getBillingPortal,
    };
  },
};
</script>

<style lang="scss" scoped></style>
