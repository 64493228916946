<template>
  <Fragment>
    <CardText>
      <VText gutter-bottom
        >Granting us access to your Google account will allow you to save
        opt-ins to a Google Sheet (Pro only).</VText
      >
    </CardText>
    <CardActions align="center" direction="column">
      <Button @click="authorize">Connect Google Account</Button>
    </CardActions>
  </Fragment>
</template>

<script>
import {
  CardText,
  VText,
  CardActions,
  Button,
} from '@campsite-bio/component-lib';
import { inject, nextTick } from 'vue';
import { Fragment } from 'vue-fragment';

import { useGoogleAuthorization, useStore } from '../../../../compositions';
import bus from '../../../../bus';

export default {
  components: {
    CardText,
    CardActions,
    VText,
    Button,
    Fragment,
  },

  setup() {
    const store = useStore();
    const { isOpen } = inject('multi-step-dialog');

    const { authorize } = useGoogleAuthorization(
      {
        onCancel() {},
        async onSuccess(data) {
          isOpen.value = false;
          await store.dispatch('profiles/getAppAuthorizations');
          await nextTick();
          bus.$emit(`app-authorization:${data.authId}:open`);
        },
      },
      {
        type: 'authorize',
      },
      'google_sheets',
    );

    return { authorize };
  },
};
</script>

<style lang="scss" scoped></style>
