<template>
  <div class="profile-menu-wrapper">
    <nav
      class="profile-menu"
      :class="{ 'profile-menu--hide': hide }"
      aria-label="Profile menu"
      :style="styles"
    >
      <ul>
        <template v-if="isLoading">
          <li v-for="i in 6" :key="i">
            <a class="profile-menu__item">
              <Skeleton width="55px" />
            </a>
          </li>
        </template>
        <template v-else>
          <li>
            <router-link
              class="profile-menu__item"
              :to="`/profile/${profileId}`"
              active-class="profile-menu__item--active"
              exact
            >
              Links
            </router-link>
          </li>
          <li v-if="canManageAppearance">
            <router-link
              class="profile-menu__item js-appearance-menu"
              :to="`/profile/${profileId}/settings/appearance`"
              active-class="profile-menu__item--active"
              exact
            >
              Appearance
            </router-link>
          </li>
          <li v-if="canManageAnalytics">
            <router-link
              class="profile-menu__item"
              :to="`/profile/${profileId}/analytics`"
              active-class="profile-menu__item--active"
            >
              <Badge variant="floating" dot>Analytics</Badge>
            </router-link>
          </li>
          <li v-if="canManageSettings">
            <router-link
              class="profile-menu__item"
              :to="`/profile/${profileId}/settings`"
              active-class="profile-menu__item--active"
              exact
            >
              Settings
            </router-link>
          </li>
          <li v-if="canManageUrls">
            <router-link
              class="profile-menu__item"
              :to="`/profile/${profileId}/settings/urls`"
              active-class="profile-menu__item--active"
              exact
            >
              Urls
            </router-link>
          </li>
          <li v-if="isProfileOnFreePlan">
            <router-link
              class="profile-menu__item"
              :to="upgradeUrl"
              active-class="profile-menu__item--active"
              exact
            >
              Upgrade
            </router-link>
          </li>
          <li v-if="!isProfileOnFreePlan && isProfileOwner">
            <router-link
              class="profile-menu__item"
              :to="`/profile/${profileId}/settings/users`"
              active-class="profile-menu__item--active"
              exact
            >
              Collaborators
            </router-link>
          </li>
          <li>
            <v-menu bottom offset-y>
              <template #activator="{ on, attrs }">
                <button
                  class="profile-menu__item js-profile-menu-additional"
                  type="button"
                  v-bind="attrs"
                  aria-label="More options"
                  v-on="on"
                >
                  <DotsVerticalIcon />
                </button>
              </template>

              <List>
                <ListItem
                  class="js-archive-list-item"
                  :to="`/profile/${profileId}/archive`"
                >
                  <ListItemIcon>
                    <ArchiveIcon />
                  </ListItemIcon>
                  <ListItemTitle>Links Archive</ListItemTitle>
                </ListItem>
                <ListItem :to="`/profile/${profileId}/qr-code`">
                  <ListItemIcon>
                    <QrCodeIcon />
                  </ListItemIcon>
                  <ListItemTitle>QR Code</ListItemTitle>
                </ListItem>
                <ListItem :to="switchProfileUrl">
                  <ListItemIcon>
                    <ExchangeIcon />
                  </ListItemIcon>
                  <ListItemTitle>Switch Profiles</ListItemTitle>
                </ListItem>
                <ListItem
                  v-if="isOrgProfile"
                  :to="`/organization/${organizationId}`"
                >
                  <ListItemTitle>View Organization</ListItemTitle>
                </ListItem>
              </List>
            </v-menu>
          </li>
        </template>
      </ul>

      <FlexSpacer />

      <ProfileSwitchBtn
        v-if="showSwitchButton"
        class="profile-menu__switch"
        :is-loading="isLoadingProfile && !hasProfile"
        :image-src="profile.profile_image"
        :username="profile.username"
      />
    </nav>
  </div>
</template>

<script>
import {
  DotsVerticalIcon,
  FlexSpacer,
  Skeleton,
  List,
  ListItem,
  ListItemTitle,
  ListItemIcon,
  ExchangeIcon,
  QrCodeIcon,
  ArchiveIcon,
  Badge,
  useTheme,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';
import tinycolor from 'tinycolor2';
import { useMediaQuery } from '@vueuse/core';

import {
  useCurrentProfile,
  useCurrentUserProfileEntitlements,
  useProfileUpgradeUrl,
  useRoute,
  useStore,
} from '@/compositions';
import { ProfileSwitchBtn } from '../header';

export default {
  components: {
    DotsVerticalIcon,
    FlexSpacer,
    Skeleton,
    List,
    ListItem,
    ListItemTitle,
    ListItemIcon,
    ProfileSwitchBtn,
    ExchangeIcon,
    QrCodeIcon,
    ArchiveIcon,
    Badge,
  },

  props: {
    isLoading: Boolean,
    hide: Boolean,
  },

  setup() {
    const {
      canManageLinks,
      canManageAppearance,
      canManageSettings,
      canManageUrls,
      canManageAnalytics,
      isProfileOwner,
      isOrgProfile,
    } = useCurrentUserProfileEntitlements();
    const route = useRoute();
    const store = useStore();
    const theme = useTheme();

    const { profile, hasProfile, id, isProfileOnFreePlan, organizationId } =
      useCurrentProfile();
    const showSwitchButton = useMediaQuery('(min-width: 675px)');

    const upgradeUrl = useProfileUpgradeUrl();

    const switchProfileUrl = computed(() => {
      const returnUrl =
        route.value.path.indexOf('/profiles') === -1
          ? encodeURIComponent(route.value.path)
          : '';
      return `/profiles?returnUrl=${returnUrl}`;
    });

    const isLoadingProfile = computed(() => {
      return store.getters['profiles/isLoadingProfile'];
    });

    const styles = computed(() => {
      return {
        '--c-menu-active-color': tinycolor(theme.value.colors.primary)
          .setAlpha(0.08)
          .toRgbString(),
      };
    });

    return {
      canManageLinks,
      canManageAppearance,
      canManageSettings,
      canManageUrls,
      canManageAnalytics,
      isProfileOwner,
      switchProfileUrl,
      profile,
      hasProfile,
      isLoadingProfile,
      isProfileOnFreePlan,
      styles,
      showSwitchButton,
      profileId: id,
      upgradeUrl,
      isOrgProfile,
      organizationId,
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-menu-wrapper {
  height: var(--profile-menu-height);

  @media print {
    display: none;
  }
}

.profile-menu {
  background: #fff;
  border-bottom: 1px solid var(--g-color-gray-200);
  display: flex;
  height: var(--profile-menu-height);
  overflow-x: scroll;
  scrollbar-width: none;
  position: relative;
  z-index: 100;
  transition: transform var(--g-transition-speed);

  @media (min-width: 992px) {
    position: fixed;
    right: 0;
    left: var(--header-width);
    overflow-x: unset;
    top: 0;

    &--hide {
      transform: translate3d(0, -100%, 0);
    }
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-flex;
    }
  }

  &__item {
    align-items: center;
    background: none;
    border: none;
    color: var(--g-color-gray-700);
    display: inline-flex;
    font-weight: 700;
    font-size: 0.85rem;
    padding: 0 0.75rem;
    text-decoration: none;
    white-space: nowrap;
    position: relative;
    overflow: hidden;

    @media (min-width: 992px) {
      padding: 0 1.5rem;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      transform: translate(-50%, 100%);
      left: 50%;
      background: var(--g-color-primary);
      opacity: 0;
      border-radius: 50%;
      width: 1.75em;
      height: 0.65em;
      transition: all var(--g-transition-speed);
    }

    &:hover,
    &:focus {
      background: var(--c-menu-active-color);
    }

    &--active {
      &:after {
        opacity: 0.75;
        transform: translate(-50%, 50%);
      }

      &::v-deep .v-badge__badge {
        opacity: 0;
      }
    }
  }

  &__switch {
    margin-right: 0.5rem;
  }
}

button.profile-menu__item {
  font-size: 1.5rem;
}
</style>
