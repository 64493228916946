<template>
  <ValidationProvider
    v-slot="{ errors, ariaInput, ariaMsg }"
    rules="url"
    name="url"
    slim
  >
    <FormGroup :gutter-bottom="false">
      <Label :for="`url${_uid}`">Url</Label>
      <Input
        :id="`url${_uid}`"
        v-model="url"
        type="url"
        v-bind="ariaInput"
        :has-error="errors.length > 0"
        placeholder="Add a link to any web page"
      />
      <FormGroupHelp color="error" v-bind="ariaMsg" gutter-bottom>{{
        errors[0]
      }}</FormGroupHelp>
    </FormGroup>
  </ValidationProvider>
</template>

<script>
import { toRefs } from 'vue';
import {
  FormGroup,
  Label,
  Input,
  FormGroupHelp,
} from '@campsite-bio/component-lib';
import { ValidationProvider } from 'vee-validate';

import { useModel } from '../../../../../compositions';

export default {
  components: {
    FormGroup,
    Label,
    Input,
    FormGroupHelp,
    ValidationProvider,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const url = useModel(value, (val) => emit('input', val));

    return { url };
  },
};
</script>

<style lang="scss" scoped></style>
