<template>
  <SimpleTable
    v-if="hasResults || isLoading"
    gutter-top
    fixed-header
    height="436"
  >
    <thead>
      <tr>
        <th>Country</th>
        <th class="text-right">{{ dataTitle }}</th>
        <th v-if="!hideUnique" class="text-right">Unique</th>
      </tr>
    </thead>
    <tbody>
      <template v-if="isLoading && !hasResults">
        <tr v-for="i in 10" :key="i">
          <td><Skeleton width="150px" /></td>
          <td class="text-right"><Skeleton width="30px" /></td>
          <td v-if="!hideUnique" class="text-right">
            <Skeleton width="30px" />
          </td>
        </tr>
      </template>
      <tr v-for="[key, name, all, unique] in data" :key="key">
        <td class="td-name">
          {{ name }}
        </td>
        <td class="text-right">{{ all | numberFormatter(1) }}</td>
        <td v-if="!hideUnique" class="text-right">
          {{ unique | numberFormatter(1) }}
        </td>
      </tr>
    </tbody>
  </SimpleTable>
  <NoResults v-else :style="{ 'min-height': noResultsMinHeight }" />
</template>

<script>
import { Skeleton, SimpleTable } from '@campsite-bio/component-lib';

import NoResults from '../cards/no-results';

export default {
  components: {
    Skeleton,
    SimpleTable,
    NoResults,
  },

  props: {
    hasResults: Boolean,
    isLoading: Boolean,
    data: {
      type: Array,
      default: null,
    },
    hideUnique: Boolean,
    dataTitle: {
      type: String,
      required: true,
    },
    noResultsMinHeight: {
      type: String,
      default: '150px',
    },
  },
};
</script>

<style lang="scss" scoped></style>
