<template>
  <BaseCard title="Add a new profile">
    <VText slot="subtitle" style="font-size: 0.8em">
      You're using {{ totalEnabledCampsites }}/{{ allowedCampsites }}
    </VText>
    <VText gutter-bottom>
      You're at your limit. Add more by modifying your subscription.
    </VText>
    <Button size="small" :to="actionUrl">Modify Subscription</Button>
  </BaseCard>
</template>

<script>
import { VText, Button } from '@campsite-bio/component-lib';

import BaseCard from './base-card.vue';

export default {
  components: {
    VText,
    Button,
    BaseCard,
  },

  props: {
    totalEnabledCampsites: {
      type: Number,
      required: true,
    },

    allowedCampsites: {
      type: Number,
      required: true,
    },

    actionUrl: {
      type: String,
      default: '/account/change-plan',
    },
  },
};
</script>

<style lang="scss" scoped></style>
