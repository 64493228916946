<template>
  <RadioGroup v-model="localValue" aria-label="Embed options">
    <FormControlLabel
      v-if="!hideEmptyOption"
      :label="`Link off to the ${sourceName} website`"
    >
      <Radio :value="null" />
    </FormControlLabel>
    <FormControlLabel
      v-for="({ name, value }, index) in embedOptions"
      :key="index"
      :label="name"
    >
      <Radio :value="value" />
    </FormControlLabel>
  </RadioGroup>
</template>

<script>
import {
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@campsite-bio/component-lib';

export default {
  components: {
    RadioGroup,
    Radio,
    FormControlLabel,
  },

  props: {
    embedType: {
      type: String,
      required: true,
    },

    sourceName: {
      type: String,
      required: true,
    },

    embedOptions: {
      type: Array,
      required: true,
    },

    value: String,
    hideEmptyOption: Boolean,
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  created() {
    if (this.value && !this.embedOptions.find((o) => o.value === this.value))
      this.$emit('value', null);
  },
};
</script>

<style lang="scss" scoped></style>
