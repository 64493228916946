<template>
  <Story ref="root" v-bind="$props" :style="styles" @on-ready="start">
    <slot slot="header" name="header" />
    <slot slot="footer-right" name="footer-right" />

    <div class="top-posts">
      <div class="top-posts__title">Top 3 Posts</div>

      <div class="posts">
        <div v-for="item in postsToShow" :key="item.id" class="post">
          <div class="post__img">
            <img :src="imageUrl(item)" alt="" width="100" loading="lazy" />
            <InstagramCarouselIcon
              v-if="item.media_type === 'CAROUSEL_ALBUM'"
            />
            <InstagramVideoIcon v-else-if="item.media_type === 'VIDEO'" />
          </div>
          <div class="post__content">
            <div>
              <div class="post__title">
                {{ formatDate(item) }}
              </div>
              <p class="post__caption" :title="item.caption">
                {{ item.caption }}
              </p>
            </div>
            <div class="post__engagement">
              <div class="post__engagement__stat">
                {{ formatEngagement(item) }}
              </div>
              <div class="post__engagement__text">
                engagement<br />
                rate
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Story>
</template>

<script>
import { computed, ref } from 'vue';
import {
  useTheme,
  getThemeColor,
  InstagramCarouselIcon,
  InstagramVideoIcon,
  convertImageUrlToEncodedUrl,
} from '@campsite-bio/component-lib';
import anime from 'animejs';
import tinycolor from 'tinycolor2';
import { DateTime } from 'luxon';

import Story from './story';
import { useTimelineWithStory } from './use-timeline-with-story';

export default {
  components: {
    Story,
    InstagramCarouselIcon,
    InstagramVideoIcon,
  },

  props: {
    color: {
      type: String,
      default: '#fff',
    },

    isPaused: Boolean,
    static: Boolean,

    posts: {
      type: Array,
      required: true,
    },

    ...Story.props,
  },

  setup(props) {
    const currentStat = ref(0);
    const postsShadow = ref('');
    const theme = useTheme();

    const shadowColor = computed(() => {
      return tinycolor(theme.value.colors.orange).darken(10).toRgbString();
    });

    const styles = computed(() => {
      return {
        '--c-color': getThemeColor(props.color, theme.value),
        '--c-shadow-color': shadowColor.value,
        '--c-posts-shadow': postsShadow.value,
      };
    });

    const postsToShow = computed(() => {
      return props.posts.slice(0, 3);
    });

    const { root, timeline, onMount } = useTimelineWithStory(props);

    function generateShadow(value) {
      postsShadow.value = [...Array(Math.ceil(value)).keys()]
        .map((i) => `${i}px ${i}px 0 var(--c-shadow-color)`)
        .join(',');
    }

    async function start() {
      const { $el, width } = root.value;

      const target = {
        shadows: 1,
      };

      timeline.add({
        targets: $el.querySelector('.top-posts__title'),
        duration: 1000,
        opacity: [0, 1],
        translateY: [20, 0],
      });

      timeline.add({
        targets: $el.querySelectorAll('.posts'),
        delay: anime.stagger(500),
        duration: 1000,
        opacity: [0, 1],
        translateX: [20, 0],
      });

      timeline.add(
        {
          targets: target,
          shadows: width,
          duration: 1500,
          update() {
            generateShadow(target.shadows);
          },
        },
        '-=500',
      );

      timeline.add(
        {
          targets: $el.querySelectorAll('.post'),
          delay: anime.stagger(500),
          duration: 750,
          opacity: [0, 1],
          translateX: [20, 0],
        },
        '-=2000',
      );

      onMount();

      if (props.static) {
        generateShadow(width);
      }
    }

    function imageUrl(item) {
      return convertImageUrlToEncodedUrl(item.cdn_media_url, 400);
    }

    function formatDate(item) {
      return DateTime.fromISO(item.date).toFormat('LLL d');
    }

    function formatEngagement(item) {
      return `${item.engagement_rate.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      })}%`;
    }

    return {
      root,
      styles,
      start,
      currentStat,
      imageUrl,
      formatDate,
      postsToShow,
      formatEngagement,
    };
  },
};
</script>

<style lang="scss" scoped>
.top-posts {
  &__title {
    font-size: 1.9em;
    font-family: var(--g-font-header-family);
    line-height: 1;
    margin-bottom: 0.6em;
  }
}

.posts {
  background: var(--g-color-orange);
  border-radius: var(--g-border-radius-min);
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  padding: var(--padding-side);
  box-shadow: var(--c-posts-shadow);
}

.post {
  display: flex;
  align-items: center;
  gap: 0.5em;

  line-height: 1.1;
  text-decoration: none;

  &__img {
    flex-shrink: 0;
    position: relative;

    img {
      background: var(--g-color-gray-100);
      border-radius: var(--g-border-radius-min);
      display: block;
      width: 3.75em;
      height: 3.75em;
      object-fit: cover;
      overflow: hidden;
    }

    &::v-deep {
      .v-svg-icon {
        display: inline-flex;
        color: #fff;
        position: absolute;
        top: 0.19em;
        right: 0.19em;
        font-size: 0.75em;
      }
    }
  }

  &__content {
    display: flex;
    gap: 3em;
    flex: 1;
    min-width: 0;
  }

  &__title {
    font-weight: 700;
    font-size: 0.75em;
    margin-bottom: 0.25rem;
  }

  &__caption {
    display: block;
    overflow: hidden;
    height: 3em;
    line-height: 1.4;
    margin: 0;
    font-size: 0.5em;
    max-width: 15em;
  }

  &__engagement {
    margin-left: auto;
    text-align: right;

    &__stat {
      font-size: 0.75em;
      font-family: var(--g-font-header-family);
      font-weight: 700;
    }

    &__text {
      font-size: 0.45em;
      line-height: 1.4;
    }
  }
}
</style>
