import { computed, reactive, watch } from 'vue';

import { percentageChange } from '../../../utils';

export function useReportHighlights(fields, data, dataKey) {
  const computedValues = reactive({});

  const pastData = computed(() => {
    return data.value[dataKey].filter(
      ({ dimensions }) => dimensions.range === 'past',
    );
  });

  const currentData = computed(() => {
    return data.value[dataKey].filter(
      ({ dimensions }) => dimensions.range === 'current',
    );
  });

  function getValues() {
    fields.forEach(({ key, lookupKey }) => {
      const keyToUse = lookupKey || key;

      computedValues[key] = currentData.value.reduce((acc, item) => {
        acc = acc + item[keyToUse];

        return acc;
      }, 0);
      computedValues[`${key}Past`] = pastData.value.reduce((acc, item) => {
        acc = acc + item[keyToUse];

        return acc;
      }, 0);
      // If the values are null, we should hide the highlight
      computedValues[`${key}Hide`] = currentData.value[0][keyToUse] === null;
      computedValues[`${key}Difference`] = percentageChange(
        computedValues[`${key}Past`],
        computedValues[key],
      );
      if (typeof computedValues[`${key}Difference`] === 'number')
        computedValues[`${key}Ctr`] = `${computedValues[
          `${key}Difference`
        ].toFixed(1)}%`;
      else computedValues[`${key}Ctr`] = computedValues[`${key}Difference`];
      computedValues[`${key}CtrColor`] =
        computedValues[`${key}Difference`] > 0 ? 'success' : 'info';
    });
  }

  watch(data, getValues, { immediate: true });

  return computedValues;
}
