<template>
  <FancyAd dismissible-key="hide-upgrade-to-pro-analytics">
    <picture slot="img">
      <source
        srcset="~images/ads/pro-users-vertical.png"
        media="(min-width: 768px)"
      />
      <img
        class="upgrade-to-pro__img"
        src="~images/ads/pro-users.png"
        alt="Campsite.bio pro profiles"
        loading="lazy"
      />
    </picture>

    <VText component="h2" variant="h4" gutter-bottom
      >Upgrade your insights</VText
    >
    <ul>
      <li>View up to 60 days of analytics data.</li>
      <li>View your top referrals, countries, links and events.</li>
      <li>Unlock even more insights from your analytics data.</li>
    </ul>

    <InlineLink component="span">Start your free trial</InlineLink>
  </FancyAd>
</template>

<script>
import { VText, InlineLink } from '@campsite-bio/component-lib';

import FancyAd from './fancy-ad';

export default {
  components: {
    FancyAd,
    VText,
    InlineLink,
  },
};
</script>

<style lang="scss" scoped></style>
