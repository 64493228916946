<template>
  <ChartCard
    class="channel-card"
    title="Engagement"
    subtitle="Number of active interactions your account received for the selected period"
    :is-loading="isLoading"
  >
    <div class="channel-card__table">
      <SimpleTable gutter-top :gutter-bottom="false">
        <tbody>
          <template v-if="isLoading">
            <tr v-for="i in 4" :key="i">
              <td><Skeleton width="100px" /></td>
              <td><Skeleton width="20px" /></td>
              <td>
                <Skeleton width="40px" />
              </td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="{ name, value, past } in formattedData" :key="name">
              <td>{{ name }}</td>
              <td>{{ value }}</td>
              <td>
                <ChangeBadge :current="value" :past="past" />
              </td>
            </tr>
          </template>
        </tbody>
      </SimpleTable>
    </div>
  </ChartCard>
</template>

<script>
import { computed, ref } from 'vue';
import { SimpleTable, Skeleton } from '@campsite-bio/component-lib';
import startCase from 'lodash/startCase';

import ChartCard from '../../cards/chart-card';
import { ChangeBadge } from '../../';

export default {
  components: {
    ChartCard,
    SimpleTable,
    ChangeBadge,
    Skeleton,
  },

  props: {
    allData: {
      type: Object,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const stats = ['like_count', 'comments_count', 'saved'];

    const postEngagementData = computed(() => {
      return props.data.ig_media_data
        .filter(
          (m) => m.range === 'current' && m.media_product_type !== 'STORY',
        )
        .reduce((accum, post) => {
          stats.forEach((stat) => {
            accum[stat] = accum[stat] || 0;
            accum[stat] += post.data[stat];
          });
          return accum;
        }, {});
    });

    const pastPostEngagementData = computed(() => {
      return props.data.ig_media_data
        .filter((m) => m.range === 'past' && m.media_product_type !== 'STORY')
        .reduce((accum, post) => {
          stats.forEach((stat) => {
            accum[stat] = accum[stat] || 0;
            accum[stat] += post.data[stat];
          });
          return accum;
        }, {});
    });

    const formattedData = computed(() => {
      const totalEngagement =
        postEngagementData.value.like_count +
        postEngagementData.value.comments_count +
        postEngagementData.value.saved;
      const pastTotalEngagement =
        pastPostEngagementData.value.like_count +
        pastPostEngagementData.value.comments_count +
        pastPostEngagementData.value.saved;
      return [
        {
          name: 'Total engagement',
          value: totalEngagement,
          past: pastTotalEngagement,
        },
        ...stats.map((stat) => ({
          name: startCase(stat),
          value: postEngagementData.value[stat],
          past: pastPostEngagementData.value[stat],
        })),
      ];
    });

    return { formattedData };
  },
};
</script>

<style lang="scss" scoped>
.channel-card {
  min-height: unset;

  &__table {
    margin: 0 -1rem -1rem;
  }
}
</style>
