<template>
  <HeaderDesktop v-if="isLargeScreen" @logout="logout" />
  <HeaderMobile v-else @scrollTop="scrollTop" @logout="logout" />
</template>

<script>
import { onMounted } from 'vue';
import { useMediaQuery } from '@vueuse/core';

import { loadScript } from '../../utils';
import HeaderDesktop from './header-desktop';
import HeaderMobile from './header-mobile';
import { useLogout } from '../../compositions';

export default {
  components: {
    HeaderDesktop,
    HeaderMobile,
  },

  setup() {
    const isLargeScreen = useMediaQuery('(min-width: 992px)');
    const { logout } = useLogout();

    onMounted(() => {
      window.HW_config = {
        selector: '.js-changelog', // CSS selector where to inject the badge
        account: 'JAb0ry',
        trigger: '.js-changelog',
      };

      loadScript('https://cdn.headwayapp.co/widget.js');
    });

    function scrollTop() {
      try {
        window.scroll({ top: 0, behavior: 'smooth' });
      } catch {
        window.scrollTo(0, 0);
      }
    }

    return {
      isLargeScreen,
      logout,
      scrollTop,
    };
  },
};
</script>

<style lang="scss" scoped></style>
