<template>
  <ChartCard
    class="goals-card"
    title="Goal breakdown"
    :is-loading="isLoading"
    disabled
  >
    <ChartWrapper
      width="100%"
      height="300"
      type="line"
      :options="chartOptions"
      :data="series"
    />
    <VText slot="disabled" variant="inherit" component="span" color="inherit"
      >Coming soon...</VText
    >
  </ChartCard>
</template>

<script>
import { VText } from '@campsite-bio/component-lib';

import { ChartCard } from '../../cards';
import { ChartWrapper } from '../../';
import { useApexOptions, useDataColors } from '../../compositions';
import { computed } from 'vue';

export default {
  components: {
    VText,
    ChartCard,
    ChartWrapper,
  },

  props: {
    isLoading: Boolean,
  },

  setup() {
    const dataColors = useDataColors();

    const chartOptions = useApexOptions(
      computed(() => {
        return {
          pointBackgroundColor: dataColors.value[0],
          pointBorderColor: dataColors.value[0],
        };
      }),
    );

    const series = {
      datasets: [
        {
          name: 'Goals',
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
    };

    return { chartOptions, series };
  },
};
</script>

<style lang="scss" scoped>
.goals-card {
  min-height: unset;
}
</style>
