<template>
  <div class="root">
    <slot name="header" />
    <template v-if="showNoAnalyticsPermissions">
      <slot name="no-access">
        <ReportsNoAccess />
      </slot>
    </template>
    <ReportsUpsell v-else-if="showUpsell" />
    <slot v-else />
  </div>
</template>

<script>
import ReportsUpsell from './reports-upsell';
import ReportsNoAccess from './reports-no-access';

export default {
  components: {
    ReportsUpsell,
    ReportsNoAccess,
  },

  props: {
    showUpsell: Boolean,
    showNoAnalyticsPermissions: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.root {
  display: contents;
}
</style>
