<template>
  <AnalyticsQuickview subtitle="profile stats">
    <template slot="title"> Lifetime&nbsp;&nbsp;<LineChartIcon /> </template>

    <QuickviewStat title="Views">
      <Skeleton v-if="!hasProfile" width="50px" />
      <template v-else>
        {{ profile.lifetime_views | numberFormatter(2) }}
      </template>
    </QuickviewStat>
    <QuickviewStat title="Clicks">
      <Skeleton v-if="!hasProfile" width="50px" />
      <template v-else>
        {{ profile.lifetime_clicks | numberFormatter(2) }}
      </template>
    </QuickviewStat>
    <QuickviewStat
      title="CTR"
      help-text="The percentage of views that resulted in a click"
    >
      <Skeleton v-if="!hasProfile" width="50px" />
      <template v-else>
        {{ ctr }}
      </template>
    </QuickviewStat>
  </AnalyticsQuickview>
</template>

<script>
import { LineChartIcon, Skeleton } from '@campsite-bio/component-lib';
import { computed, ref } from 'vue';
import AnalyticsQuickview from './analytics-quickview';
import QuickviewStat from './quickview-stat';
import { useStore } from '../../../compositions';
import { useLastUpdated } from '../compositions';

export default {
  components: {
    AnalyticsQuickview,
    QuickviewStat,
    LineChartIcon,
    Skeleton,
  },

  setup() {
    const store = useStore();
    const profile = computed(() => store.getters['profiles/currentProfile']);
    const hasProfile = computed(() => store.getters['profiles/hasProfile']);
    const timestamp = ref(new Date());

    async function reload() {
      try {
        await store.dispatch('profiles/getLifeTimeStats');
        timestamp.value = new Date();
      } catch (e) {
        console.error(e);
      }
    }

    const ctr = computed(() => {
      const { lifetime_clicks, lifetime_views } = profile.value;

      if (!hasProfile.value || lifetime_views === 0) {
        return '0%';
      }

      return `${((lifetime_clicks / lifetime_views) * 100).toFixed(1)}%`;
    });

    // keep the data updated
    useLastUpdated({
      interval: 20000, // update timestamp every 30 seconds
      timeShouldHavePassed: 60 * 3 * 1000, // 3 minutes
      lastUpdatedTimestamp: timestamp,
      callback() {
        reload();
      },
    });

    return {
      profile,
      hasProfile,
      ctr,
    };
  },
};
</script>

<style lang=" scss" scoped v-on="on"></style>
