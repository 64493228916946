/**
 * Check if a user has the correct entitlements
 * @param {Array} neededEntitlements
 * @param {Array|String} userEntitlements
 * @returns Boolean
 */
export function hasProfileEntitlements(neededEntitlements, userEntitlements) {
  if (userEntitlements === 'owner') return true;

  if (userEntitlements.some((v) => neededEntitlements.includes(v))) return true;

  return false;
}
