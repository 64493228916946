vb
<template>
  <Paper
    v-if="!dismissed || !dismissible"
    class="upgrade-to-pro"
    border-color="gray200"
    border-width="1"
    elevate-on-hover
    hide-static-shadow
  >
    <component :is="linkComponent" v-bind="linkAttrs">
      <div class="upgrade-to-pro__img-wrapper" :class="imgClasses">
        <slot name="img" />
      </div>
      <div class="upgrade-to-pro__content">
        <slot />
      </div>
    </component>

    <div v-if="dismissible" class="upgrade-to-pro__dismiss">
      <IconButton
        color="white"
        aria-label="Hide ad"
        size="1.25rem"
        filled
        @click="dismiss"
      >
        <CloseIcon />
      </IconButton>
    </div>

    <div v-if="isAd" class="upgrade-to-pro__ad">
      <Badge color="white" content="Sponsored" />
    </div>
  </Paper>
</template>

<script>
import {
  Paper,
  IconButton,
  CloseIcon,
  Badge,
} from '@campsite-bio/component-lib';
import { computed } from 'vue';

import { useDismissable } from '@/compositions';

export default {
  components: {
    Paper,
    IconButton,
    CloseIcon,
    Badge,
  },

  props: {
    goToUrl: {
      type: String,
      default: '/account/change-plan',
    },

    dismissible: {
      type: Boolean,
      default: true,
    },

    dismissibleKey: {
      type: String,
      default: null,
    },

    dismissibleStorage: {
      type: String,
      default: 'session',
    },

    imageFit: {
      type: String,
      default: 'cover',
    },

    isAd: Boolean,
  },

  setup(props) {
    if (!props.dismissible) return { dismissed: false };

    const { dismissed, dismiss } = useDismissable(props.dismissibleKey, {
      storage: props.dismissibleStorage,
    });

    const linkComponent = computed(() => {
      if (props.goToUrl.startsWith('http')) return 'a';
      return 'router-link';
    });

    const linkAttrs = computed(() => {
      if (props.goToUrl.startsWith('http'))
        return { href: props.goToUrl, target: '_blank' };
      return { to: props.goToUrl };
    });

    const imgClasses = computed(() => ({
      'upgrade-to-pro__img-wrapper--fit-cover': props.imageFit === 'cover',
      'upgrade-to-pro__img-wrapper--fit-contain': props.imageFit === 'contain',
    }));

    return { dismissed, dismiss, imgClasses, linkComponent, linkAttrs };
  },
};
</script>

<style lang="scss" scoped>
.upgrade-to-pro {
  border: 0.125rem solid #fff;
  background: #fff;
  padding-top: 150px;
  position: relative;

  > a,
  &::v-deep picture {
    border-radius: inherit;
  }

  > a {
    color: var(--g-color-on-bg);
    text-decoration: none;
  }

  @media (min-width: 768px) {
    padding-left: 200px;
    padding-top: 0;
  }

  &__img-wrapper {
    background: var(--g-color-gray-100);
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: 0;
    height: 150px;
    width: 100%;

    @media (min-width: 768px) {
      height: 100%;
      width: 200px;
    }

    &::v-deep img {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      object-fit: cover;
      height: 100%;
      width: 100%;

      @media (min-width: 768px) {
        border-bottom-left-radius: inherit;
        border-top-right-radius: 0;
      }
    }

    &--fit-contain {
      &::v-deep img {
        object-fit: contain;
      }
    }
  }

  &__content {
    padding: 2rem 1.5rem 1rem;
    font-size: 0.9rem;

    @media (min-width: 768px) {
      padding: 2rem 1.5rem;
    }

    &::v-deep li {
      margin-bottom: 0.4rem;
    }
  }

  &__dismiss {
    position: absolute;
    top: 0.25rem;
    right: 0.2rem;
  }

  &__ad {
    position: absolute;
    bottom: 0.25rem;
    right: 0.25rem;
  }
}
</style>
