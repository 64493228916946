<template>
  <div
    class="config-toolbar"
    :class="{ 'config-toolbar--profile-menu-hidden': profileMenuHidden }"
  >
    <Toolbar :rounded="isLargerScreen">
      <template v-if="isSelectionModeEnabled">
        <label class="config-toolbar__check">
          <Checkbox
            :value="selectedCheckbox"
            :checked="areAllLinkSelected"
            :indeterminate="selectedLinks.length > 0 && !areAllLinkSelected"
            :disabled="isFreePlan"
            @input="toggleAllLinksSelection"
          />
        </label>
        <ToolbarTitle>
          {{ selectedLinks.length }} / {{ totalLinks }} link{{
            totalLinks !== 1 ? 's' : ''
          }}
          selected
        </ToolbarTitle>

        <FlexSpacer />

        <!-- <v-menu key="sort" bottom offset-y>
          <template #activator="{ on, attrs }">
            <v-tooltip key="close" bottom>
              <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                <IconButton
                  v-bind="{ ...attrs, ...tooltipAttrs }"
                  aria-label="Sort links"
                  :disabled="isActionLoading"
                  v-on="{ ...on, ...tooltipOn }"
                >
                  <SortIcon />
                </IconButton>
              </template>
              <span>Sort links</span>
            </v-tooltip>
          </template>

          <List>
            <ListItem component="button" type="button">
              <ListItemIcon>
                <CheckIcon v-show="!sort" />
              </ListItemIcon>
              <ListItemTitle>Position</ListItemTitle>
            </ListItem>
            <ListItem component="button" type="button">
              <ListItemIcon>
                <CheckIcon v-show="sort === 'created_at_asc'" />
              </ListItemIcon>
              <ListItemTitle>Newest first</ListItemTitle>
            </ListItem>
            <ListItem component="button" type="button">
              <ListItemIcon>
                <CheckIcon v-show="sort === 'created_at_desc'" />
              </ListItemIcon>
              <ListItemTitle>Oldest first</ListItemTitle>
            </ListItem>
          </List>
        </v-menu> -->

        <v-menu key="actions" bottom offset-y>
          <template #activator="{ on, attrs }">
            <v-tooltip key="close" bottom>
              <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                <IconButton
                  v-bind="{ ...attrs, ...tooltipAttrs }"
                  aria-label="Open menu"
                  :loading="isActionLoading"
                  v-on="{ ...on, ...tooltipOn }"
                >
                  <DotsVerticalIcon />
                </IconButton>
              </template>
              <span>Actions</span>
            </v-tooltip>
          </template>
          <List>
            <ListItem
              component="button"
              type="button"
              :disabled="isCreateGroupLinkDisabled"
              @click="handleCreateGroupFromSelection"
            >
              <ListItemIcon><GroupIcon /></ListItemIcon>
              <ListItemTitle>Create new link group</ListItemTitle>
            </ListItem>
            <ListItem
              component="button"
              type="button"
              :disabled="selectedLinks.length === 0"
              @click="handleArchiveLinks"
            >
              <ListItemIcon><ArchiveIcon /></ListItemIcon>
              <ListItemTitle>Archive links</ListItemTitle>
            </ListItem>
            <ListItem
              component="button"
              type="button"
              :disabled="selectedLinks.length === 0"
              @click="isDeleteDialogOpen = true"
            >
              <ListItemIcon><TrashIcon /></ListItemIcon>
              <ListItemTitle>Delete links</ListItemTitle>
            </ListItem>
          </List>
        </v-menu>
        <v-tooltip key="close" bottom>
          <template #activator="{ on, attrs }">
            <IconButton
              aria-label="Close"
              title=" "
              v-bind="attrs"
              :disabled="isActionLoading"
              @click="toggleSelectionMode"
              v-on="on"
            >
              <CloseIcon />
            </IconButton>
          </template>
          <span>Close editor</span>
        </v-tooltip>
      </template>

      <template v-else>
        <ToolbarTitle>Links</ToolbarTitle>
        <FlexSpacer />
        <v-tooltip v-if="!hideBulkEditor" key="selection-mode" left>
          <template #activator="{ on, attrs }">
            <IconButton
              v-bind="attrs"
              :disabled="isAddingLink || isLoadingLinks"
              @click="toggleSelectionMode"
              v-on="on"
            >
              <FormatListCheckboxIcon />
            </IconButton>
          </template>
          <span>Bulk link editor</span>
        </v-tooltip>
        <Button
          class="js-add-link"
          :disabled="isAddingLink || isLoadingLinks"
          :loading="isAddingLink"
          :size="buttonSize"
          @click="$emit('add-link')"
          >Add Link</Button
        >
        <v-menu bottom offset-y max-height="500">
          <template #activator="{ on, attrs }">
            <Button
              class="js-explore-links"
              :disabled="isAddingLink || isLoadingLinks"
              :size="buttonSize"
              variant="outline"
              v-bind="attrs"
              v-on="on"
            >
              {{ isLargerScreen ? 'Explore Links' : 'Explore' }}
            </Button>
          </template>
          <LinkList @click="$emit('add-link', $event)" />
        </v-menu>
      </template>
    </Toolbar>

    <DeleteDialog v-model="isDeleteDialogOpen" @remove="handleDeleteLinks">
      {{ selectedLinks.length }} link{{ totalLinks !== 1 ? 's' : '' }} and their
      data will be permanently deleted. This cannot be undone.
    </DeleteDialog>
  </div>
</template>

<script>
import {
  Toolbar,
  ToolbarTitle,
  FlexSpacer,
  Button,
  IconButton,
  Checkbox,
  CloseIcon,
  DotsVerticalIcon,
  List,
  ListItem,
  ListItemIcon,
  ListItemTitle,
  ArchiveIcon,
  TrashIcon,
  GroupIcon,
  FormatListCheckboxIcon,
} from '@campsite-bio/component-lib';
import { computed, ref, watch } from 'vue';
import { useMagicKeys, useMediaQuery } from '@vueuse/core';

import DeleteDialog from '../links/components/delete-dialog';
import LinkList from '../links/link-list/link-list';
import { useSelectionMode } from './use-selection-mode';
import { useStore, useUserPreferences } from '@/compositions';
import toast from '@/utils/toast';

export default {
  components: {
    Toolbar,
    ToolbarTitle,
    FlexSpacer,
    Button,
    LinkList,
    IconButton,
    Checkbox,
    CloseIcon,
    DotsVerticalIcon,
    List,
    ListItem,
    ListItemIcon,
    ListItemTitle,
    ArchiveIcon,
    TrashIcon,
    GroupIcon,
    FormatListCheckboxIcon,
    DeleteDialog,
  },

  props: {
    isAddingLink: Boolean,
    isLoadingLinks: Boolean,
    buttonSize: {
      type: String,
      default: 'small',
    },
    profileMenuHidden: Boolean,
    hideBulkEditor: Boolean,
  },

  setup() {
    const store = useStore();
    const {
      isSelectionModeEnabled,
      areAllLinkSelected,
      selectedLinks,
      toggleSelectionMode,
      toggleAllLinksSelection,
      selectedCheckbox,
      totalLinks,
      isActionLoading,
      resetSelectedLinks,
      isGroupLinkSelected,
    } = useSelectionMode();
    const { userPreferences, save: saveUserPreferences } = useUserPreferences();
    const sort = ref(userPreferences.value.links_sort);
    const { escape } = useMagicKeys();
    const isDeleteDialogOpen = ref(false);
    const isLargerScreen = useMediaQuery('(min-width: 425px)');

    const isFreePlan = computed(() => {
      return store.getters['profiles/isProfileOnFreePlan'];
    });

    const isCreateGroupLinkDisabled = computed(
      () =>
        selectedLinks.value.length === 0 ||
        isGroupLinkSelected.value ||
        selectedLinks.value.length > 20,
    );

    watch(sort, (newSort) => {
      saveUserPreferences({ links_sort: newSort });
    });

    // Close selection mode if esc is pressed
    watch(escape, (value) => {
      if (value && isSelectionModeEnabled.value) {
        toggleSelectionMode();
      }
    });

    async function handleCreateGroupFromSelection() {
      isActionLoading.value = true;
      try {
        toast.success('Creating group...');
        const { link } = await store.dispatch('links/bulkGroupLink', {
          type: 'group',
          ids: selectedLinks.value,
        });
        resetSelectedLinks();
        toggleSelectionMode();
        // Scroll new link into view
        const linkId = link._id.$oid;
        document.querySelector(`[data-id="${linkId}"]`)?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        toast.success('Group link created');
      } catch (e) {
        const { error_message } = e.response?.data || {};
        console.error(e);
        toast.error(error_message || 'Error creating group link');
      }
      isActionLoading.value = false;
    }

    async function handleArchiveLinks() {
      isActionLoading.value = true;
      try {
        toast.success('Archiving links...');
        await store.dispatch('links/bulkArchiveLinks', {
          ids: selectedLinks.value,
        });
        resetSelectedLinks();
        toast.success('Links archived');
      } catch (e) {
        const { error_message } = e.response?.data || {};
        console.error(e);
        toast.error(error_message || 'Error archiving the selected links');
      }
      isActionLoading.value = false;
    }

    async function handleDeleteLinks() {
      isActionLoading.value = true;
      isDeleteDialogOpen.value = false;
      try {
        toast.success('Deleting links...');
        await store.dispatch('links/bulkDeleteLinks', {
          ids: selectedLinks.value,
        });
        resetSelectedLinks();
        toast.success('Links deleted');
      } catch (e) {
        const { error_message } = e.response?.data || {};
        console.error(e);
        toast.error(error_message || 'Error deleting the selected links');
      }
      isActionLoading.value = false;
    }

    return {
      isLargerScreen,
      isDeleteDialogOpen,
      isSelectionModeEnabled,
      areAllLinkSelected,
      selectedLinks,
      toggleSelectionMode,
      sort,
      toggleAllLinksSelection,
      selectedCheckbox,
      totalLinks,
      handleCreateGroupFromSelection,
      handleArchiveLinks,
      handleDeleteLinks,
      isActionLoading,
      isGroupLinkSelected,
      isCreateGroupLinkDisabled,
      isFreePlan,
    };
  },
};
</script>

<style lang="scss" scoped>
.config-toolbar {
  position: sticky;
  z-index: 100;
  transition: all var(--g-transition-speed);
  top: calc(var(--header-height) + 0.5rem);

  &--profile-menu-hidden {
    top: 0.5rem;
  }

  @media (min-width: 992px) {
    top: calc(var(--profile-menu-height) + 0.5rem);

    &--profile-menu-hidden {
      top: 0.5rem;
    }
  }

  .v-button {
    margin: 0;
    margin-left: 0.25rem;
  }

  &__check {
    display: flex;
    margin-right: 1rem;
  }
}
</style>
