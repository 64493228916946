<template>
  <SvgIcon viewBox="0 0 750 750">
    <defs
      ><clipPath id="a"
        ><path d="M237.012 58.488h264.937v264.938H237.012Zm0 0" /></clipPath
      ><clipPath id="b"
        ><path
          d="M369.48 58.488c-73.16 0-132.468 59.309-132.468 132.469S296.32 323.426 369.48 323.426s132.47-59.309 132.47-132.469S442.64 58.488 369.48 58.488" /></clipPath
      ><clipPath id="c"
        ><path d="M78.05 394.871H672v115.082H78.05Zm0 0" /></clipPath
      ><clipPath id="d"
        ><path
          d="M96.05 394.871h557.9a17.997 17.997 0 0 1 18 18v78.805a18 18 0 0 1-5.274 12.726 17.984 17.984 0 0 1-12.727 5.274H96.051a17.984 17.984 0 0 1-12.727-5.274 18 18 0 0 1-5.273-12.726V412.87a17.997 17.997 0 0 1 18-18" /></clipPath
      ><clipPath id="e"
        ><path d="M319.441 580.926h110.391v110.39h-110.39Zm0 0" /></clipPath
      ><clipPath id="f"
        ><path
          d="M334.441 580.926h80.391c8.285 0 15 6.715 15 15v80.39c0 8.286-6.715 15-15 15h-80.39c-8.286 0-15-6.714-15-15v-80.39c0-8.285 6.714-15 15-15" /></clipPath
      ><clipPath id="g"
        ><path d="M21.387 580.926h110.39v110.39H21.387Zm0 0" /></clipPath
      ><clipPath id="h"
        ><path
          d="M36.387 580.926h80.39c8.285 0 15 6.715 15 15v80.39c0 8.286-6.714 15-15 15h-80.39c-8.282 0-15-6.714-15-15v-80.39c0-8.285 6.718-15 15-15" /></clipPath
      ><clipPath id="i"
        ><path d="M170.414 580.926h110.39v110.39h-110.39Zm0 0" /></clipPath
      ><clipPath id="j"
        ><path
          d="M185.414 580.926h80.39c8.286 0 15 6.715 15 15v80.39c0 8.286-6.714 15-15 15h-80.39c-8.285 0-15-6.714-15-15v-80.39c0-8.285 6.715-15 15-15" /></clipPath
      ><clipPath id="k"
        ><path d="M468.832 580.926h110.39v110.39h-110.39Zm0 0" /></clipPath
      ><clipPath id="l"
        ><path
          d="M483.832 580.926h80.39c8.282 0 15 6.715 15 15v80.39c0 8.286-6.718 15-15 15h-80.39c-8.285 0-15-6.714-15-15v-80.39c0-8.285 6.715-15 15-15" /></clipPath
      ><clipPath id="m"
        ><path d="M618.223 580.926h110.39v110.39h-110.39Zm0 0" /></clipPath
      ><clipPath id="n"
        ><path
          d="M633.223 580.926h80.39c8.282 0 15 6.715 15 15v80.39c0 8.286-6.718 15-15 15h-80.39c-8.285 0-15-6.714-15-15v-80.39c0-8.285 6.715-15 15-15" /></clipPath></defs
    ><g clip-path="url(#a)"
      ><g clip-path="url(#b)"
        ><path d="M237.012 58.488h264.937v264.938H237.012Zm0 0" /></g></g
    ><g clip-path="url(#c)"
      ><g clip-path="url(#d)"
        ><path d="M78.05 394.871h594.036V509.54H78.05Zm0 0" /></g></g
    ><g clip-path="url(#e)"
      ><g clip-path="url(#f)"
        ><path d="M319.441 580.926h110.391v110.39h-110.39Zm0 0" /></g></g
    ><g clip-path="url(#g)"
      ><g clip-path="url(#h)"
        ><path d="M21.387 580.926h110.39v110.39H21.387Zm0 0" /></g></g
    ><g clip-path="url(#i)"
      ><g clip-path="url(#j)"
        ><path d="M170.414 580.926h110.39v110.39h-110.39Zm0 0" /></g></g
    ><g clip-path="url(#k)"
      ><g clip-path="url(#l)"
        ><path d="M468.832 580.926h110.39v110.39h-110.39Zm0 0" /></g></g
    ><g clip-path="url(#m)"
      ><g clip-path="url(#n)"
        ><path d="M618.223 580.926h110.39v110.39h-110.39Zm0 0" /></g
    ></g>
  </SvgIcon>
</template>

<script>
import { SvgIcon } from '@campsite-bio/component-lib';

export default {
  components: {
    SvgIcon,
  },
};
</script>

<style lang="scss" scoped></style>
