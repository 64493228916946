<template>
  <div>
    <ValidationProvider
      ref="provider"
      rules="required"
      name="sheet"
      :detect-input="false"
      slim
    >
      <template v-if="!hasGoogleSheetsAuthed">
        <VText style="margin: 15px 0 8px; font-size: 14px">
          <strong
            >We need to authorize your account with Google Sheets before you can
            select or create one. Please click below to get started.</strong
          >
        </VText>
        <GoogleBtn
          style="margin-bottom: 1rem"
          :auth-type="googleAuth ? 'reauthorize' : 'authorize'"
        />
      </template>

      <template v-else>
        <FormGroup v-if="sheetId">
          <SourceItem
            title="Google Sheet"
            :subtitle="sheetTitle"
            @click="clearSelected"
          />
        </FormGroup>

        <template v-else>
          <FormGroup>
            <Label :for="'sheet_name' + _uid">Google Sheet Name</Label>
            <Input
              :id="'sheet_name' + _uid"
              v-model="sheetName"
              :placeholder="`My ${type} list`"
              :disabled="isCreatingSheet"
              @keyup.enter="add"
            />
            <FormGroupHelp gutter-bottom
              >Create a new google sheet to store {{ type }} in</FormGroupHelp
            >
            <Button :loading="isCreatingSheet" @click="add">Create</Button>
          </FormGroup>

          <Divider color="gray300">or</Divider>

          <FormGroup style="margin-bottom: 2rem">
            <Label :for="'sheet_existing' + _uid"
              >Select Existing Google Sheet</Label
            >
            <div class="row">
              <div class="col col--md-6">
                <Input
                  v-model="search"
                  placeholder="Search by title"
                  aria-label="Search by title"
                />
              </div>
              <div class="col col--md-6">
                <Select v-model="tempSheetId" :name="'sheet_existing' + _uid">
                  <template v-if="sheets">
                    <option
                      v-for="sheet in sheets"
                      :key="sheet.id"
                      :value="sheet.id"
                    >
                      {{ sheet.name }}
                    </option>
                  </template>
                  <option
                    v-if="sheets && sheets.length === 0"
                    value=""
                    selected
                  >
                    No sheets found
                  </option>
                </Select>
              </div>
            </div>
            <FormGroupHelp gutter-bottom
              >New signups are appended to the existing Google
              sheet</FormGroupHelp
            >
            <Button :loading="isLoadingSheets" @click="save"
              >Select Sheet</Button
            >
          </FormGroup>
        </template>
      </template>
    </ValidationProvider>
  </div>
</template>

<script>
import {
  FormGroup,
  Input,
  FormGroupHelp,
  Label,
  VText,
  Button,
  Divider,
  Select,
} from '@campsite-bio/component-lib';
import { computed, onMounted, ref, toRefs, watch } from 'vue';
import { ValidationProvider } from 'vee-validate';
import debounce from 'lodash/debounce';

import { useStore } from '@/compositions';
import GoogleBtn from '../../../../authorize-google-btn';
import { createGoogleSheet, getAllGoogleSheets } from '@/apis/sheets';
import toast from '@/utils/toast';
import SourceItem from '../../source-item';
import { AUTHORIZATION_STATUS } from '@/config';

export default {
  components: {
    FormGroup,
    Input,
    FormGroupHelp,
    Label,
    VText,
    Button,
    ValidationProvider,
    GoogleBtn,
    Divider,
    SourceItem,
    Select,
  },

  props: {
    link: {
      type: Object,
      required: true,
    },

    type: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { link, type } = toRefs(props);
    const store = useStore();
    const provider = ref(null);
    const sheets = ref(null);
    const sheetId = ref(null);
    const sheetTitle = ref(null);
    const search = ref(null);
    const tempSheetId = ref(null);
    const sheetName = ref(null);
    const isLoadingSheets = ref(true);
    const isCreatingSheet = ref(false);

    const emailOptions = computed(() => {
      return link.value.email_options;
    });

    const profileId = computed(() => {
      return store.getters['profiles/currentProfileId'];
    });

    const googleAuth = computed(() => {
      return store.getters['profiles/googleAuthorization'];
    });

    const hasGoogleSheetsAuthed = computed(() => {
      return googleAuth.value?.status === AUTHORIZATION_STATUS.CONNECTED;
    });

    // After auth success, reload sheets
    watch(hasGoogleSheetsAuthed, (newVal) => {
      if (newVal) {
        loadSheets();
      }
    });

    watch(
      emailOptions,
      ({ destination_id, destination_title }) => {
        sheetId.value = destination_id;
        sheetTitle.value = destination_title;
      },
      { immediate: true },
    );

    watch(search, debounce(loadSheets, 1000));

    async function loadSheets() {
      isLoadingSheets.value = true;
      try {
        const { data } = await getAllGoogleSheets(
          profileId.value,
          search.value,
        );

        sheets.value = data.sheets;
        if (sheets.value.length) tempSheetId.value = sheets.value[0].id;
      } catch (e) {
        const { error_message } = e.response?.data || {};
        console.error(e);
        toast.error(
          error_message ||
            'There was an error loading your Google Sheets. Please try again soon.',
        );
      }
      isLoadingSheets.value = false;
    }

    async function add() {
      if (!sheetName.value) return;

      isCreatingSheet.value = true;
      try {
        const { data } = await createGoogleSheet(link.value._id.$oid, {
          title: sheetName.value,
          type: type.value,
        });

        sheetTitle.value = sheetName.value;
        sheetId.value = data.sheet.spreadsheet_id;
      } catch (e) {
        const { error_message } = e.response?.data || {};
        console.error(e);
        toast.error(
          error_message ||
            'There was an error trying to create your Google Sheets. Please try again soon.',
        );
      }
      isCreatingSheet.value = false;
    }

    function save() {
      sheetTitle.value = sheets.value.find(
        (s) => s.id === tempSheetId.value,
      ).name;
      sheetId.value = tempSheetId.value;
    }

    function clearSelected() {
      sheetId.value = null;
      sheetTitle.value = null;

      loadSheets();
    }

    onMounted(() => {
      if (hasGoogleSheetsAuthed.value && !sheetId.value) loadSheets();
      else if (sheetId.value) provider.value.syncValue(sheetId.value);
    });

    watch(sheetId, () => {
      provider.value.syncValue(sheetId.value);
      provider.value.applyResult({
        errors: [],
        valid: sheetId.value,
        failedRules: {},
      });

      if (sheetId.value) {
        emit('change', {
          id: sheetId.value,
          title: sheetTitle.value,
        });
      }
    });

    return {
      provider,
      googleAuth,
      hasGoogleSheetsAuthed,
      sheetId,
      sheetTitle,
      sheets,
      isLoadingSheets,
      search,
      tempSheetId,
      sheetName,
      isCreatingSheet,
      add,
      save,
      clearSelected,
    };
  },
};
</script>

<style lang="scss" scoped></style>
