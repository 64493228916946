import {
  ActiveCampaignIcon,
  ConvertkitIcon,
  MailchimpIcon,
  GoogleSheetsIcon,
  KlaviyoIcon,
  MailerLiteIcon,
  OmnisendIcon,
  EmailIcon,
  PhoneIcon,
  ZapierIcon,
} from '@campsite-bio/component-lib';

export const EMAIL_TYPES = [
  {
    source: 'active_campaign',
    name: 'ActiveCampaign',
    iconComponent: 'ActiveCampaignIcon',
    color: '#004cff',
    types: ['email'],
  },
  {
    source: 'convert_kit',
    name: 'ConvertKit',
    iconComponent: 'ConvertkitIcon',
    color: '#FB6970',
    types: ['email'],
  },
  {
    source: 'google',
    name: 'Google Sheets',
    iconComponent: 'GoogleSheetsIcon',
    color: '#0F9D58',
    types: ['email', 'sms'],
  },
  {
    source: 'mailchimp',
    name: 'Mailchimp',
    iconComponent: 'MailchimpIcon',
    color: '#241C15',
    types: ['email'],
  },
  {
    source: 'mailer_lite',
    name: 'MailerLite',
    iconComponent: 'MailerLiteIcon',
    color: '#51bf6c',
    types: ['email'],
  },
  {
    source: 'omnisend',
    name: 'Omnisend',
    iconComponent: 'OmnisendIcon',
    color: '#555c66',
    types: ['email', 'sms'],
  },
  {
    source: 'klaviyo',
    name: 'Klaviyo',
    iconComponent: 'KlaviyoIcon',
    color: '#26b06d',
    types: ['email', 'sms'],
  },
  {
    source: 'zapier',
    name: 'Zapier Webhook',
    iconComponent: 'ZapierIcon',
    color: '#FF4A00',
    types: ['email', 'sms'],
  },
];

export const EMAIL_ICONS = {
  ActiveCampaignIcon,
  ConvertkitIcon,
  MailchimpIcon,
  KlaviyoIcon,
  GoogleSheetsIcon,
  MailerLiteIcon,
  OmnisendIcon,
  EmailIcon,
  PhoneIcon,
  ZapierIcon,
};
