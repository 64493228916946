<template>
  <div>
    <Alert
      v-if="startTime && link.enabled"
      variant="outline"
      style="margin-bottom: 12px"
    >
      <InfoIcon slot="icon" />
      The start time you have entered won't work if your link is enabled
      <Button slot="actions" variant="flat" @click="disableLink('a')"
        >Disable Link</Button
      >
    </Alert>
    <Alert
      v-if="!startTime && endTime && !link.enabled"
      variant="outline"
      style="margin-bottom: 12px"
    >
      <InfoIcon slot="icon" />
      The end time you have entered won't work if your link is disabled
      <Button slot="actions" variant="flat" @click="enableLink()"
        >Enable Link</Button
      >
    </Alert>

    <div class="row">
      <div class="col col--lg-6">
        <FormGroup>
          <FormGroupHeader gutter-bottom>
            <Label :for="'start' + _uid">Start Time</Label>
            <FlexSpacer />
            <InlineLink
              v-show="startTime"
              color="gray500"
              style="font-size: 0.875rem"
              @click="startTime = undefined"
            >
              Clear
            </InlineLink>
          </FormGroupHeader>
          <v-datetime-picker
            v-model="startTime"
            :input-id="'start' + _uid"
            :format="defaultFormat"
            :min-date="minDate"
            :timezone="startTimeTimezoneID"
            @input="disableLink"
          />
        </FormGroup>
      </div>
      <div class="col col--lg-6">
        <FormGroup>
          <FormGroupHeader gutter-bottom>
            <Label :for="'end' + _uid">End Time</Label>
            <FlexSpacer />
            <InlineLink
              v-show="endTime"
              color="gray500"
              style="font-size: 0.875rem"
              @click="endTime = undefined"
            >
              Clear
            </InlineLink>
          </FormGroupHeader>
          <v-datetime-picker
            ref="endDatePicker"
            v-model="endTime"
            :input-id="'end' + _uid"
            :min-date="startTimeFormattedDate"
            :format="defaultFormat"
            :timezone="startTimeTimezoneID"
          />
          <FormGroupHelp v-if="isEndError" color="error"
            >The End Time cannot be before the Start Time</FormGroupHelp
          >
        </FormGroup>
      </div>
    </div>
    <FormGroup style="margin-bottom: 0">
      <Label :for="'start-t' + _uid">Timezone</Label>
      <v-timezone-select
        v-model="startTimeTimezone"
        :input-id="'start-t' + _uid"
        style="padding-left: 10px"
      />
      <FormGroupHelp style="margin-bottom: 0"
        >This defaults to the timezone set on your
        <router-link to="/account">account page</router-link>.</FormGroupHelp
      >
    </FormGroup>
    <div v-if="hasSchedulerHistory" style="margin-top: 7px">
      <InlineLink
        style="font-size: 0.75rem"
        color="gray600"
        @click="showSchedulerHistory = !showSchedulerHistory"
      >
        View Scheduler History
      </InlineLink>
      <ul
        v-show="showSchedulerHistory"
        style="margin-top: 10px; margin-bottom: 0; font-size: 14px"
      >
        <li v-for="log in schedulerHistory" :key="log.id">
          {{ schedulerLogItem(log) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  InfoIcon,
  Button,
  FlexSpacer,
  InlineLink,
  FormGroup,
  FormGroupHeader,
  FormGroupHelp,
  Label,
} from '@campsite-bio/component-lib';
import { DateTime } from 'luxon';

import VTimezoneSelect from '../form/timezone-select.vue';
import VDatetimePicker from '../form/datetime-picker.vue';
import toast from '@/utils/toast';

export default {
  components: {
    VTimezoneSelect,
    VDatetimePicker,
    Alert,
    InfoIcon,
    Button,
    FlexSpacer,
    InlineLink,
    FormGroup,
    FormGroupHeader,
    FormGroupHelp,
    Label,
  },

  props: {
    link: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      menu1: false,
      minDate: DateTime.local().toISODate(),
      minTime: DateTime.local().toFormat('HH:mm'),
      showSchedulerHistory: false,
      defaultFormat: 'MMM d, yyyy h:mm a',
    };
  },

  computed: {
    id() {
      return this.link._id.$oid;
    },

    user() {
      return this.$store.getters.currentUser;
    },

    hasSchedulerHistory() {
      return !!this.link.link_schedule_logs;
    },

    startTimeTimezone: {
      get() {
        return this.link.start_time_timezone || this.user.timezone;
      },
      set(value) {
        this.$store.commit('links/updateLinkStartTimeTimezone', {
          value,
          id: this.id,
        });
      },
    },

    startTimeTimezoneID() {
      return this.link.start_time_timezone_id || this.user.timezone_id;
    },

    startTime: {
      get() {
        let startTime = this.link.start_time;
        if (typeof startTime === 'string')
          startTime = DateTime.fromISO(startTime);
        return startTime ? startTime.toFormat("yyyy-MM-dd'T'HH:mm") : undefined;
      },
      set(value) {
        const startTime = DateTime.fromISO(value);
        const endTime = DateTime.fromISO(this.endTime);
        if (value && this.endTime && startTime > endTime)
          this.endTime = DateTime.local().plus({ minutes: 5 });
        this.$store.commit('links/updateLinkStartTime', {
          value,
          id: this.id,
        });
      },
    },

    startTimeFormattedDate() {
      return this.startTime
        ? DateTime.fromISO(this.startTime, {
            zone: this.startTimeTimezoneID,
          }).toISODate()
        : DateTime.local().toISODate();
    },

    endTime: {
      get() {
        let endTime = this.link.end_time;
        if (typeof endTime === 'string') endTime = DateTime.fromISO(endTime);
        return endTime ? endTime.toFormat("yyyy-MM-dd'T'HH:mm") : undefined;
      },
      set(value) {
        const startTime = DateTime.fromISO(this.startTime);
        const endTime = DateTime.fromISO(value);
        if (value && this.startTime && startTime > endTime) {
          value = undefined;
          this.$refs.endDatePicker.clear();
          toast.error('The End Time cannot be before the Start Time', {
            timeout: 2000,
          });
        }
        this.$store.commit('links/updateLinkEndTime', {
          value,
          id: this.id,
        });
      },
    },

    schedulerHistory() {
      return this.hasSchedulerHistory
        ? [...this.link.link_schedule_logs].reverse()
        : undefined;
    },

    isEndError() {
      return this.startTime > this.endTime;
    },
  },

  methods: {
    schedulerLogItem(log) {
      if (!log.timezone) return null;
      const time = DateTime.fromISO(log.current_time, {
        zone: log.timezone_id,
      }).toLocaleString(DateTime.DATETIME_FULL);

      return `Link schedule ${log.start_time ? 'started' : 'ended'}: ${time}`;
    },

    disableLink(value) {
      if (value)
        this.$store.commit('links/updateLinkEnabled', {
          value: false,
          id: this.id,
        });
    },

    enableLink() {
      this.$store.commit('links/updateLinkEnabled', {
        value: true,
        id: this.id,
      });
    },
  },
};
</script>

<style scoped></style>
