<template>
  <ItemAccordion
    :active="localHasCode"
    :disabled="disabled"
    @update:active="localHasCode = $event"
  >
    Code&nbsp;
    <Badge content="Pro" />

    <VText slot="action" component="span" :color="status.color">{{
      status.label
    }}</VText>

    <template slot="content">
      <ValidationProvider
        v-slot="{ errors, ariaInput, ariaMsg }"
        :rules="{
          required: localHasCode,
        }"
        name="code"
        slim
      >
        <FormGroup>
          <Label :for="`code${id}`" gutter-bottom block>Set code</Label>
          <OtpInput
            :id="`code${id}`"
            v-model="localCode"
            v-bind="ariaInput"
            type="number"
            :has-error="errors.length > 0"
            placeholder="0000"
          />
          <FormGroupHelp color="error" v-bind="ariaMsg">{{
            errors[0]
          }}</FormGroupHelp>
          <FormGroupHelp v-if="isActive" color="success"
            >Code {{ code }} is active</FormGroupHelp
          >
          <FormGroupHelp
            >Visitors must enter the above code to unlock the
            link</FormGroupHelp
          >
        </FormGroup>
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors, ariaInput, ariaMsg }"
        rules="max:240"
        name="code explanation"
        slim
      >
        <FormGroup>
          <Label :for="`codeexplanation${id}`" gutter-bottom
            >Lock explanation</Label
          >
          <Input
            :id="`codeexplanation${id}`"
            v-model="localCodeDescription"
            style="max-width: 100%; width: 450px"
            component="textarea"
            rows="3"
            v-bind="ariaInput"
            maxlength="240"
            :has-error="errors.length > 0"
          />
          <FormGroupHelp color="error" v-bind="ariaMsg">{{
            errors[0]
          }}</FormGroupHelp>
          <FormGroupHelp
            >Provide a short explanation for this lock</FormGroupHelp
          >
        </FormGroup>
      </ValidationProvider>
    </template>
  </ItemAccordion>
</template>

<script>
import {
  Badge,
  Input,
  FormGroup,
  FormGroupHelp,
  Label,
  OtpInput,
  ItemAccordion,
  VText,
} from '@campsite-bio/component-lib';
import { ValidationProvider } from 'vee-validate';
import { computed, toRefs } from 'vue';

import { useModel } from '../../../../compositions';

export default {
  components: {
    Badge,
    Input,
    FormGroup,
    FormGroupHelp,
    Label,
    OtpInput,
    ItemAccordion,
    ValidationProvider,
    VText,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    hasCode: Boolean,
    disabled: Boolean,

    code: {
      type: String,
      default: null,
    },

    codeDescription: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { hasCode, code, codeDescription } = toRefs(props);
    const localHasCode = useModel(hasCode, (val) =>
      emit('update:has-code', val),
    );
    const localCode = useModel(code, (val) => emit('update:code', val));
    const localCodeDescription = useModel(codeDescription, (val) =>
      emit('update:code-description', val),
    );

    const isActive = computed(
      () => props.hasCode && localCode.value?.length === 4,
    );

    const status = computed(() => {
      if (isActive.value) {
        return {
          label: 'Active',
          color: 'success',
        };
      }

      return {
        label: 'Set up',
        color: 'info',
      };
    });

    return { localHasCode, localCode, localCodeDescription, status, isActive };
  },
};
</script>

<style lang="scss" scoped></style>
