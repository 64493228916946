export const FORM_TYPES = [
  {
    source: 'jotform',
    name: 'Jotform',
    iconComponent: 'JotformIcon',
    color: '#3595f6',
    placeholder: 'https://form.jotform.com/220797050372051',
    layouts: [
      {
        name: 'Standard',
        value: 'standard',
        icon: 'StandardIcon',
      },
      {
        name: 'Expand',
        value: 'expand',
        icon: 'ExpandIcon',
      },
    ],
    defaultSettings: {
      layout: 'expand',
      button_text: 'Open Me',
    },
  },
  {
    source: 'typeform',
    name: 'Typeform',
    iconComponent: 'TypeformIcon',
    color: '#262627',
    placeholder: 'https://sia4g461nva.typeform.com/to/YrfjLJ1c',
    layouts: [
      {
        name: 'Standard',
        value: 'standard',
        icon: 'StandardIcon',
      },
      {
        name: 'Popup',
        value: 'popup',
        icon: 'PopupIcon',
      },
      {
        name: 'Slider',
        value: 'slider',
        icon: 'SliderIcon',
      },
    ],
    defaultSettings: {
      layout: 'popup',
      height: '400',
      button_text: 'Launch Me',
    },
  },
];
