import { computed, ref, unref } from 'vue';

import { useStore } from '../use-store';
import { localAxios } from '../../apis';

export function usePayPalAuthorization(params, currencyCode) {
  const store = useStore();

  const signupData = ref(null);
  const actionUrl = computed(() => {
    if (!signupData.value) return null;

    return signupData.value.links.find(
      (link) => link.table.rel === 'action_url',
    ).table.href;
  });

  async function authorize() {
    await loadReferralLinks();
    window.location = actionUrl.value;
  }

  async function loadReferralLinks() {
    const currency = unref(currencyCode);

    const { data } = await localAxios.get(
      `/api/authorize/${store.getters['profiles/currentProfileId']}/paypal-action-url`,
      {
        params: {
          currency,
          ...params,
        },
      },
    );

    signupData.value = data;
  }

  return {
    authorize,
  };
}
