import config from '../config';
import axios from 'axios';
import { localAxios } from './axios';

export async function loadCampsiteClickData(options) {
  const { token, range, timezone, cancelToken, ...rest } = options;
  const { data } = await axios.get('/click_stream_data/click', {
    params: {
      campsite_token: token,
      range,
      timezone,
      datetime: new Date().toString(),
      ...rest,
    },
    withCredentials: false,
    baseURL: config.API_BASE_URL,
    signal: cancelToken,
  });

  data.timestamp = new Date();

  return data;
}

export async function loadCampsiteFilters(options) {
  const { token, cancelToken, ...rest } = options;
  const { data } = await localAxios.get('/api/analytics/performance-filters', {
    params: {
      campsite_token: token,
      datetime: new Date().toString(),
      compare: false,
      ...rest,
    },
    signal: cancelToken,
  });

  return data;
}

export async function loadCampsiteFiltersV1(options) {
  const { token, cancelToken, ...rest } = options;
  const { data } = await axios.get('/performance_data_filters', {
    params: {
      campsite_token: token,
      datetime: new Date().toString(),
      compare: false,
      ...rest,
    },
    withCredentials: false,
    baseURL: config.API_BASE_URL,
    signal: cancelToken,
  });

  return data;
}

export async function loadReport(options) {
  const { token, cancelToken, ...rest } = options;

  const { data } = await localAxios.get('/api/analytics/report', {
    params: {
      campsite_token: token,
      datetime: new Date().toString(),
      ...rest,
    },
    signal: cancelToken,
  });

  return data;
}

export async function loadReportV1(options) {
  const { token, cancelToken, ...rest } = options;

  const { data } = await axios.get('/reporting_edge', {
    params: {
      campsite_token: token,
      datetime: new Date().toString(),
      ...rest,
    },
    withCredentials: false,
    baseURL: config.API_BASE_URL,
    signal: cancelToken,
  });

  return data;
}

export async function loadAnalyticsDownload(options) {
  const { token, cancelToken, ...rest } = options;
  const params = {
    datetime: new Date().toString(),
    ...rest,
  };
  if (token) params.campsite_token = token;
  const { data } = await axios.get('/export_data', {
    params,
    withCredentials: false,
    baseURL: config.API_BASE_URL,
    signal: cancelToken,
  });

  return data;
}
