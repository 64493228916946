<template>
  <DevicesTable
    :is-loading="isLoading"
    :data="deviceData"
    :has-results="hasResults"
    :data-title="startCase(type)"
    :series="series"
  />
</template>

<script>
import { computed } from 'vue';
import startCase from 'lodash/startCase';

import { DevicesTable } from '../tables';
import { useOnDataColors } from '../compositions';

export default {
  components: {
    DevicesTable,
  },

  props: {
    allData: {
      type: Object,
      default: null,
    },

    data: {
      type: Array,
      default: null,
    },

    isLoading: Boolean,

    /**
     * clicks|views
     */
    type: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const deviceData = computed(() => {
      if (!props.data) return [];

      const stats = props.data.reduce((acc, { _id, count, unique }) => {
        acc.push([_id, count, unique]);
        return acc;
      }, []);

      return stats.sort((a, b) => b[1] - a[1]).slice(0, 4);
    });

    const series = computed(() => {
      return {
        labels: deviceData.value.map(([name]) => name),
        datasets: [
          {
            data: deviceData.value.map(([, views]) => views),
          },
        ],
      };
    });

    const hasResults = computed(() => deviceData.value.length > 0);

    return {
      deviceData,
      startCase,
      series,
      hasResults,
    };
  },
};
</script>

<style lang="scss" scoped></style>
