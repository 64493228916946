<template>
  <LinkWrapper disabled>
    <LinkCard loader>
      <LinkField>
        <Skeleton width="100%" height="40px" />
      </LinkField>
      <LinkField>
        <Skeleton width="100%" height="40px" />
      </LinkField>
      <div slot="media" class="media-selector--post">
        <Skeleton width="100%" height="100%" />
      </div>
      <template slot="btm">
        <Skeleton class="v-toggle" width="54px" height="24px" />
        <Skeleton class="link__btn" width="34px" height="28px" />
        <Skeleton class="link__btn" width="34px" height="28px" />
        <Skeleton class="link__btn" width="34px" height="28px" />

        <FlexSpacer />

        <Skeleton width="34px" height="28px" />
      </template>
    </LinkCard>
  </LinkWrapper>
</template>

<script>
import { Skeleton, FlexSpacer } from '@campsite-bio/component-lib';
import { provide } from 'vue';

import { LinkWrapper, LinkCard, LinkField } from './components';

export default {
  components: {
    Skeleton,
    FlexSpacer,
    LinkWrapper,
    LinkCard,
    LinkField,
  },

  setup() {
    provide('link', {});

    return {};
  },
};
</script>

<style scoped></style>
