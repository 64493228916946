<template>
  <Dialog v-model="localValue" title="Your profile subdomain" max-width="500">
    <ValidationObserver v-slot="{ handleSubmit }" slim>
      <form novalidate @submit.prevent="handleSubmit(save)">
        <CardText>
          <VText gutter-bottom
            >Get a profile URL that starts with your username.</VText
          >

          <ValidationProvider
            v-slot="{ errors, ariaMsg, ariaInput }"
            :rules="subdomainRules"
            name="subdomain"
            mode="aggressive"
            :debounce="500"
            slim
          >
            <FormGroup>
              <Label for="subdomain">Custom Subdomain</Label>
              <Input
                id="subdomain"
                v-model="subdomainLocal"
                type="text"
                name="subdomain"
                placeholder="myusername"
                autocomplete="off"
                autocapitalize="off"
                maxlength="30"
                suffix=".campsite.bio"
                v-bind="ariaInput"
              >
                <template slot="append">
                  <CheckIcon
                    v-show="
                      errors.length === 0 &&
                      subdomainLocal &&
                      subdomainLocal !== subdomain
                    "
                    color="success"
                  />
                  <ErrorOutlineIcon v-show="errors.length > 0" color="error" />
                </template>
              </Input>
              <FormGroupHelp color="error" v-bind="ariaMsg">{{
                errors[0]
              }}</FormGroupHelp>
              <FormGroupHelp>
                It can take up to five minutes for your subdomain to become
                available</FormGroupHelp
              >
            </FormGroup>
          </ValidationProvider>

          <Alert
            v-if="hasChangedRecently"
            variant="outline"
            style="margin-top: 0.75rem"
          >
            <InfoIcon slot="icon" />
            You can only update your subdomain once every 5 minutes. Last
            changed at {{ formattedSubdomainChangedAt }}.
          </Alert>
        </CardText>
        <CardActions>
          <Button variant="flat" color="error" @click="close">Cancel</Button>
          <Button
            color="success"
            variant="flat"
            type="submit"
            :loading="isSaving"
            :disabled="!subdomainLocal || hasChangedRecently"
            >Save</Button
          >
        </CardActions>
      </form>
    </ValidationObserver>
  </Dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  CardText,
  CardActions,
  Button,
  Alert,
  InfoIcon,
  VText,
  FormGroup,
  Label,
  Input,
  FormGroupHelp,
  ErrorOutlineIcon,
  CheckIcon,
} from '@campsite-bio/component-lib';
import { computed, ref, toRefs, watch } from 'vue';
import { DateTime } from 'luxon';
import { useTimeAgo } from '@vueuse/core';

import { useModel, useCurrentProfile, useStore } from '@/compositions';
import toast from '@/utils/toast';
import Dialog from './dialog';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CardText,
    CardActions,
    Button,
    Alert,
    InfoIcon,
    VText,
    FormGroup,
    Label,
    Input,
    FormGroupHelp,
    ErrorOutlineIcon,
    CheckIcon,
    Dialog,
  },

  props: {
    value: Boolean,
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const { subdomain, subdomainChangedAt } = useCurrentProfile();
    const isSaving = ref(false);
    const subdomainLocal = ref(null);
    const store = useStore();
    const localValue = useModel(value, (val) => emit('input', val));
    const timeAgo = useTimeAgo();

    const hasChangedRecently = computed(() => {
      if (!subdomainChangedAt.value) return false;

      // Keep updating this computed value so after 5 minutes it will return false
      const longAgo = timeAgo.value;
      const now = DateTime.local().minus({ minutes: 5 });

      return now < subdomainChangedAt.value;
    });

    const formattedSubdomainChangedAt = computed(() => {
      if (!subdomainChangedAt.value) return null;

      return subdomainChangedAt.value.toLocaleString(DateTime.TIME_SIMPLE);
    });

    watch(
      subdomain,
      (val) => {
        subdomainLocal.value = val;
      },
      { immediate: true },
    );

    const subdomainRules = computed(() => {
      if (
        subdomainLocal.value &&
        subdomainLocal.value.toLowerCase() !== subdomain.value &&
        value.value // is dialog open
      ) {
        return {
          required: false,
          min: 3,
          max: 30,
          regex:
            /^[A-Za-z0-9](?![_.])(?!.*[_]{2})(?:(?:[A-Za-z0-9]){0,30}(?:[A-Za-z0-9]))$/,
          subdomain: true,
        };
      }

      return {};
    });

    function close() {
      emit('input', false);
    }

    async function save() {
      if (isSaving.value) return;

      isSaving.value = true;

      try {
        await store.dispatch(
          'profiles/updateSubdomain',
          subdomainLocal.value.toLowerCase(),
        );

        toast.success('Your subdomain has been updated');
        close();
      } catch (e) {
        console.error(e);
        toast.error('Error trying to update your subdomain');
      }

      isSaving.value = false;
    }

    return {
      subdomain,
      subdomainLocal,
      subdomainRules,
      save,
      isSaving,
      close,
      localValue,
      hasChangedRecently,
      formattedSubdomainChangedAt,
    };
  },
};
</script>

<style lang="scss" scoped></style>
