import { computed, watch } from 'vue';

import { useRouteQuery, useStore } from '../../../compositions';
import { DEFAULT_FILTER_OPERATORS } from '../filters';

export function useFilters() {
  const store = useStore();
  const storedFilters = computed(() => store.getters['analytics/filters']);

  const queryFilters = useRouteQuery(
    'filters',
    storedToQuery(storedFilters.value),
    {
      mode: 'push',
    },
  );

  watch(
    queryFilters,
    (query) => {
      const filters = parseQueryFilters(query);
      store.commit('analytics/set', { name: 'filters', value: filters });
    },
    { immediate: true },
  );

  watch(storedFilters, (filters) => {
    const query = storedToQuery(filters);
    if (query !== queryFilters.value) queryFilters.value = query;
  });

  function storedToQuery(filters) {
    if (!filters) return null;

    return JSON.stringify(
      filters.map(({ filter, operator, value }) => {
        return {
          filter,
          value,
          operator: operator.value,
        };
      }),
    );
  }

  function parseQueryFilters(query) {
    if (!query) return [];

    return JSON.parse(query).map(({ filter, operator, value }) => ({
      filter,
      operator: DEFAULT_FILTER_OPERATORS.find((o) => o.value === operator),
      value,
    }));
  }

  function addFilter(filter) {
    store.commit('analytics/set', {
      name: 'filters',
      value: [...storedFilters.value, filter],
    });
  }

  function removeFilter(filter) {
    store.commit('analytics/set', {
      name: 'filters',
      value: [...storedFilters.value.filter((f) => f !== filter)],
    });
  }

  function updateFilter(filter) {
    const filters = [...storedFilters.value];
    const index = filters.findIndex(
      (f) => f.filter.path === filter.filter.path,
    );
    filters[index] = filter;
    store.commit('analytics/set', {
      name: 'filters',
      value: filters,
    });
  }

  return {
    filters: storedFilters,
    addFilter,
    removeFilter,
    updateFilter,
  };
}
