<template>
  <Card class="profiles-card">
    <CardTitle style="display: block">
      <VText component="h2" variant="h4">Add a profile</VText>
      <VText style="font-size: 0.9rem"
        >Each profile gets it's own URL and theme.</VText
      >
    </CardTitle>

    <List style="padding: 0">
      <div class="profile-item">
        <ListItem>
          <ListItemTitle class="profile-item__title"
            >Personal <Badge :content="planName"
          /></ListItemTitle>
          <ListItemAction class="profile-item__count"
            >{{ numberOfProfilesEnabled }} /
            {{ numberOfProfiles }}</ListItemAction
          >
        </ListItem>
        <ListItem v-if="isFreePlan" class="profile-item__actions">
          <ListItemContent style="padding: 0">
            <ListItemSubtitle>Manage more profiles with Pro</ListItemSubtitle>
            <div style="margin-top: 0.25rem">
              <Button to="/account/change-plan" size="small" variant="outline"
                >Upgrade Now</Button
              >
            </div>
          </ListItemContent>
        </ListItem>
        <ListItem v-else-if="isAtLimit" class="profile-item__actions">
          <Button to="/account/change-plan" size="small" variant="outline"
            >Add more profiles</Button
          >
        </ListItem>
      </div>
      <Divider color="gray200" thickness="1" />
      <template
        v-for="{
          _id,
          name,
          number_of_campsites,
          number_of_campsites_enabled,
          member_role,
        } in orgs"
      >
        <div :key="`org${_id.$oid}`" class="profile-item">
          <ListItem>
            <ListItemContent>
              <ListItemTitle class="profile-item__title">
                <InlineLink :to="`/organization/${_id.$oid}`">{{
                  name
                }}</InlineLink>
              </ListItemTitle>
              <ListItemSubtitle>
                {{ member_role }}
              </ListItemSubtitle>
            </ListItemContent>
            <ListItemAction class="profile-item__count">
              {{ number_of_campsites_enabled }} / {{ number_of_campsites }}
            </ListItemAction>
          </ListItem>
        </div>
        <Divider :key="`divider${_id.$oid}`" color="gray200" thickness="1" />
      </template>
    </List>

    <CardActions>
      <v-menu v-if="orgs.length > 0" offset-y max-width="200px">
        <template #activator="{ on, attrs }">
          <Button
            variant="flat"
            :disabled="areAddButtonsDisabled"
            v-bind="attrs"
            v-on="on"
            >Add New</Button
          >
        </template>

        <List>
          <ListItem
            class="v-profile-card__action-item"
            to="/profile/new"
            :disabled="isAtLimit"
          >
            <ListItemTitle>Add to personal</ListItemTitle>
          </ListItem>
          <ListItem
            v-for="{
              _id,
              name,
              number_of_campsites_enabled,
              number_of_campsites,
            } in orgs"
            :key="_id.$oid"
            class="v-profile-card__action-item"
            :to="`/profile/new?organization_id=${_id.$oid}`"
            :disabled="number_of_campsites_enabled >= number_of_campsites"
          >
            <ListItemTitle>Add to {{ name }}</ListItemTitle>
          </ListItem>
        </List>
      </v-menu>
      <Button
        v-else
        variant="flat"
        :disabled="areAddButtonsDisabled"
        to="/profile/new"
        >Add New</Button
      >
      <v-menu v-if="orgs.length > 0" offset-y max-width="200px">
        <template #activator="{ on, attrs }">
          <Button
            variant="flat"
            :disabled="areAddButtonsDisabled"
            v-bind="attrs"
            v-on="on"
            >Transfer</Button
          >
        </template>

        <List>
          <ListItem
            class="v-profile-card__action-item"
            component="button"
            type="button"
            :disabled="isAtLimit"
            @click="addExistingCampsite(null)"
          >
            <ListItemTitle>Transfer to personal</ListItemTitle>
          </ListItem>
          <ListItem
            v-for="org in orgs"
            :key="org._id.$oid"
            class="v-profile-card__action-item"
            component="button"
            type="button"
            :title="`Transfer to ${org.name}`"
            :disabled="
              org.number_of_campsites_enabled >= org.number_of_campsites
            "
            @click="addExistingCampsite(org)"
          >
            <ListItemTitle>Transfer to {{ org.name }}</ListItemTitle>
          </ListItem>
        </List>
      </v-menu>
      <Button
        v-else
        variant="flat"
        :disabled="areAddButtonsDisabled"
        @click="addExistingCampsite(null)"
        >Transfer</Button
      >
    </CardActions>

    <AddExistingCampsiteDialog
      v-model="showAddExistingDialog"
      :organization-id="addExistingOrg ? addExistingOrg._id.$oid : null"
      :organization="addExistingOrg"
    />
  </Card>
</template>

<script>
import {
  Card,
  CardTitle,
  CardActions,
  VText,
  List,
  ListItem,
  ListItemTitle,
  ListItemSubtitle,
  ListItemAction,
  ListItemContent,
  Divider,
  Badge,
  InlineLink,
  Button,
} from '@campsite-bio/component-lib';
import { computed, ref } from 'vue';

import { useCurrentUser, useCurrentUserSubscription } from '@/compositions';

export default {
  components: {
    Card,
    CardTitle,
    CardActions,
    VText,
    List,
    ListItem,
    ListItemTitle,
    ListItemSubtitle,
    ListItemContent,
    ListItemAction,
    Divider,
    Badge,
    InlineLink,
    Button,
    AddExistingCampsiteDialog: () =>
      import('@/components/dialogs/add-existing-campsite'),
  },

  props: {
    orgs: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const showAddExistingDialog = ref(false);
    const addExistingOrg = ref(null);
    const { numberOfProfiles, numberOfProfilesEnabled } = useCurrentUser();
    const { planName, isFreePlan } = useCurrentUserSubscription(false);

    const isAtLimit = computed(() => {
      return numberOfProfilesEnabled.value >= numberOfProfiles.value;
    });

    const areAddButtonsDisabled = computed(() => {
      return isAtLimit.value && props.orgs.length === 0;
    });

    function addExistingCampsite(org = null) {
      showAddExistingDialog.value = true;
      addExistingOrg.value = org;
    }

    return {
      showAddExistingDialog,
      numberOfProfiles,
      numberOfProfilesEnabled,
      isFreePlan,
      planName,
      isAtLimit,
      areAddButtonsDisabled,
      addExistingCampsite,
      addExistingOrg,
    };
  },
};
</script>

<style lang="scss">
.v-profile-card__action-item[disabled] {
  background-color: var(--g-color-gray-100);
}
</style>

<style lang="scss" scoped>
.profile-item {
  &__title {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    a {
      font-weight: 400;
    }
  }

  &__count {
    white-space: nowrap;
    flex-shrink: 0;
  }

  &__actions {
    background: linear-gradient(
      to bottom,
      var(--g-color-bg),
      var(--g-color-gray-100)
    );
    gap: 0.5rem;
    padding-bottom: 0.5rem;
    min-height: unset;
  }
}
</style>
