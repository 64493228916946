<template>
  <ChartCard
    class="leaderboard-card"
    :title="statName"
    :is-loading="isLoading"
    :padding-bottom="false"
  >
    <SimpleTable height="310" fixed-header gutter-top>
      <thead>
        <tr>
          <th>Rank</th>
          <th>{{ startCase(selectedFilterName) }} of</th>
          <th>{{ statName }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="{ rank, dates, count } in series" :key="`${statKey}${rank}`">
          <td>
            {{ rank }}
          </td>
          <td>
            <span
              v-for="date in dates"
              :key="`x${date}`"
              class="leaderboard-card__date"
            >
              {{ formatDate(date) }}&nbsp;
              <v-tooltip right>
                <template #activator="{ on, attrs }">
                  <IconButton
                    v-if="showOpenUrl"
                    class="leaderboard-card__action"
                    :aria-label="`open ${selectedFilterName} in analytics`"
                    size="small"
                    style="display: inline-flex; vertical-align: middle"
                    :href="getAnalyticsUrl(date)"
                    target="_blank"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <ExternalLinkIcon />
                  </IconButton>
                </template>
                <span>Open {{ selectedFilterName }} in analytics</span>
              </v-tooltip>
            </span>
          </td>
          <td>{{ count | numberFormatter(2) }}</td>
        </tr>
      </tbody>
    </SimpleTable>
    <template slot="actions">
      <slot name="actions" />
    </template>
  </ChartCard>
</template>

<script>
import { computed, inject } from 'vue';
import startCase from 'lodash/startCase';
import {
  SimpleTable,
  IconButton,
  ExternalLinkIcon,
} from '@campsite-bio/component-lib';
import { DateTime } from 'luxon';

import { ChartCard } from '..';
import { useStore } from '../../../../compositions';

export default {
  components: {
    ChartCard,
    SimpleTable,
    IconButton,
    ExternalLinkIcon,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
    statName: {
      type: String,
      required: true,
    },
    statKey: {
      type: String,
      required: true,
    },
    statCountKey: {
      type: String,
      default: 'count',
    },

    isLoading: Boolean,
    showOpenUrl: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const store = useStore();

    const { selectedFilterName } = inject('leaderboard', {});

    const selectedProfile = computed(() =>
      store.getters['analytics/getField']('selectedProfile'),
    );

    const series = computed(() => {
      return props.data[props.statKey].reduce((acc, stat, i) => {
        const dates = stat.occurrences ? stat.occurrences : [stat.occurrence];
        acc.push({
          rank: `#${i + 1}`,
          dates,
          count: stat[props.statCountKey],
        });
        return acc;
      }, []);
    });

    function getAnalyticsUrl(date) {
      const startDate = DateTime.fromISO(date, { zone: 'utc' });
      const endDate = startDate.endOf(selectedFilterName?.value);
      return `/profile/${
        selectedProfile.value
      }/analytics?start_date=${startDate.toISODate()}&end_date=${endDate.toISODate()}`;
    }

    function formatDate(date) {
      if (selectedFilterName?.value === 'month') {
        return DateTime.fromISO(date).toFormat('LLL yyyy');
      }

      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
    }

    return {
      series,
      startCase,
      formatDate,
      getAnalyticsUrl,
      selectedFilterName,
    };
  },
};
</script>

<style lang="scss" scoped>
.leaderboard-card {
  min-height: unset;

  &__date {
    display: block;
  }

  &__action {
    opacity: 0;
  }

  tr:hover &__action {
    opacity: 1;
  }
}
</style>
