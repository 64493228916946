<template>
  <Fragment>
    <StatCard class="span-3" title="Campaigns" :is-loading="isLoading">
      {{ numberOfCampaigns }}
    </StatCard>
    <StatCard class="span-3" title="Mediums" :is-loading="isLoading">
      {{ numberOfMediums }}
    </StatCard>
    <StatCard class="span-3" title="Sources" :is-loading="isLoading">
      {{ numberOfSources }}
    </StatCard>
  </Fragment>
</template>

<script>
import { computed, toRefs } from 'vue';
import { Fragment } from '@campsite-bio/component-lib';

import { StatCard } from '..';

export default {
  components: {
    Fragment,
    StatCard,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const { data } = toRefs(props);

    const numberOfCampaigns = computed(() => {
      const campaigns = data.value.all_views.reduce((acc, { dimensions }) => {
        if (
          dimensions.campaign &&
          dimensions.campaign !== 'not set' &&
          dimensions.range === 'current'
        ) {
          acc.push(dimensions.campaign);
        }

        return acc;
      }, []);

      return [...new Set(campaigns)].length;
    });

    const numberOfMediums = computed(() => {
      const mediums = data.value.all_views.reduce((acc, { dimensions }) => {
        if (
          dimensions.medium &&
          dimensions.medium !== 'not set' &&
          dimensions.range === 'current'
        ) {
          acc.push(dimensions.medium);
        }

        return acc;
      }, []);

      return [...new Set(mediums)].length;
    });

    const numberOfSources = computed(() => {
      const sources = data.value.all_views.reduce((acc, { dimensions }) => {
        if (
          dimensions.source &&
          dimensions.source !== 'not set' &&
          dimensions.range === 'current'
        ) {
          acc.push(dimensions.source);
        }

        return acc;
      }, []);

      return [...new Set(sources)].length;
    });

    return {
      numberOfCampaigns,
      numberOfMediums,
      numberOfSources,
    };
  },
};
</script>

<style lang="scss" scoped></style>
