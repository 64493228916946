<template>
  <div class="submenu__divider">
    <Divider color="gray300" thickness="1" />
  </div>
</template>

<script>
import { Divider } from '@campsite-bio/component-lib';

export default {
  components: {
    Divider,
  },
};
</script>

<style lang="scss" scoped>
.submenu__divider {
  background: var(--g-color-gray-300);
  margin: 0.25rem;
  width: 1px;

  @media (min-width: 992px) {
    background: none;
    margin: 0.75rem 0;
    width: unset;
  }
}
</style>
