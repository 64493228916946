var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LinkExpander',{attrs:{"id":`default-${_vm.linkId}`,"is-open":_vm.isOpen},on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[_vm._v("\n    Group options\n    "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('IconButton',_vm._g(_vm._b({attrs:{"href":"https://support.campsite.bio/en/articles/7263397-group-link","target":"_blank","aria-label":"Group link help","size":"1.25rem"}},'IconButton',attrs,false),on),[_c('QuestionCircleOIcon')],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("Get some help setting up your group link")])])],1),_vm._v(" "),_c('ValidationObserver',{ref:"form"},[_c('ValidationProvider',{ref:"limitProvider",attrs:{"rules":{
        required: false,
        min_value: 3,
        max_value: 20,
      },"name":"limit","detect-input":false,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors, ariaMsg }){return [_c('FormGroup',{attrs:{"gutter-bottom":false}},[_c('Label',{attrs:{"for":"limit"}},[_vm._v("Links to show")]),_vm._v(" "),_c('ChipGroup',{attrs:{"aria-label":"Number of links to show"},model:{value:(_vm.limitLocal),callback:function ($$v) {_vm.limitLocal=$$v},expression:"limitLocal"}},[_vm._l((_vm.LIMIT_OPTIONS),function(limit){return _c('Chip',{key:limit,attrs:{"value":limit,"selectable":""}},[_vm._v(_vm._s(limit))])}),_vm._v(" "),_c('Chip',{attrs:{"value":null,"selectable":""}},[_vm._v("No limit")]),_vm._v(" "),_c('Chip',{attrs:{"value":"custom","selectable":""}},[_vm._v("Custom")]),_vm._v(" "),_c('div',{staticStyle:{"max-width":"80px"}},[(
                !_vm.LIMIT_OPTIONS.includes(_vm.limitLocal) && _vm.limitLocal !== null
              )?_c('Input',{staticStyle:{"height":"2rem"},attrs:{"type":"number","min":"3","max":"20","step":"1","pattern":"[0-9]*"},model:{value:(_vm.actualLimit),callback:function ($$v) {_vm.actualLimit=_vm._n($$v)},expression:"actualLimit"}}):_vm._e()],1)],2),_vm._v(" "),(errors.length === 0)?_c('FormGroupHelp',[_vm._v("\n          We'll show up to this many links if they're available.\n        ")]):_c('FormGroupHelp',_vm._b({attrs:{"color":"error"}},'FormGroupHelp',ariaMsg,false),[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")])],1)]}}])})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }