<template>
  <ChipGroup v-model="selectedPeriod" aria-label="Dates" scroll>
    <Chip
      v-for="{ name, value } in availableDates"
      :key="value"
      :value="value"
      :disabled="disabled"
      selectable
      >{{ name }}</Chip
    >
  </ChipGroup>
</template>

<script>
import { Chip, ChipGroup } from '@campsite-bio/component-lib';
import { computed, toRefs } from 'vue';

import {
  DEFAULT_PERIODS_MOBILE,
  ENHANCED_DEFAULT_PERIODS_MOBILE,
} from '../../';
import {
  useCurrentUserProfileEntitlements,
  useModel,
} from '../../../../compositions';

export default {
  components: {
    Chip,
    ChipGroup,
  },

  props: {
    value: {
      type: [String, Array],
      required: true,
    },

    availablePeriods: {
      type: Array,
      default: null,
    },

    disabled: Boolean,
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const { hasAnalyticsAddon } = useCurrentUserProfileEntitlements();
    const selectedPeriod = useModel(value, (val) => emit('input', val));

    const availableDates = computed(() => {
      if (props.availablePeriods) return props.availablePeriods;

      return hasAnalyticsAddon.value
        ? ENHANCED_DEFAULT_PERIODS_MOBILE
        : DEFAULT_PERIODS_MOBILE;
    });

    return {
      selectedPeriod,
      availableDates,
    };
  },
};
</script>

<style lang="scss" scoped></style>
