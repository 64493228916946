<template>
  <AppAuthorizationDialog v-model="isOpen" :item="item" :disabled="readonly">
    <template #activator="{ on, attrs }">
      <ListItem
        class="v-app-auth-item"
        :component="readonly ? 'div' : 'button'"
        v-bind="attrs"
        :style="styles"
        v-on="on"
      >
        <ListItemIcon>
          <PaypalIcon v-if="item.provider === 'paypal'" />
          <SquareIcon v-if="item.provider === 'square'" />
          <InstagramIcon v-if="item.provider === 'instagram'" />
          <FacebookLetterFIcon v-if="item.provider === 'facebook'" />
          <ShopifyIcon v-if="item.provider === 'shopify'" />
          <GoogleIcon
            v-if="
              item.provider === 'google' ||
              item.provider === 'google_analytics_4'
            "
          />
        </ListItemIcon>
        <ListItemContent>
          <ListItemSubtitle>
            {{ item.provider_title }}
            <Badge v-if="item.disabled" color="info" content="Disabled" />
            <Badge
              v-else-if="item.provider === 'facebook'"
              content="Pro+ / Analytics add-on"
            />
          </ListItemSubtitle>
          <ListItemTitle>{{
            item.title || item.name || item.email
          }}</ListItemTitle>
        </ListItemContent>
        <ListItemAction class="v-app-auth-item__status">
          <Status :color="statusColor" :aria-label="item.status" />
        </ListItemAction>
      </ListItem>
    </template>
  </AppAuthorizationDialog>
</template>

<script>
import {
  Badge,
  ListItem,
  ListItemIcon,
  ListItemContent,
  ListItemTitle,
  ListItemSubtitle,
  ListItemAction,
  Status,
  PaypalIcon,
  SquareIcon,
  GoogleIcon,
  InstagramIcon,
  FacebookLetterFIcon,
  ShopifyIcon,
  useTheme,
} from '@campsite-bio/component-lib';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import tinycolor from 'tinycolor2';

import AppAuthorizationDialog from './app-authorization-dialog';
import { AUTHORIZATION_STATUS } from '../../config';
import { useRoute, useStore } from '../../compositions';
import bus from '../../bus';

export default {
  components: {
    Badge,
    ListItem,
    ListItemIcon,
    ListItemContent,
    ListItemTitle,
    ListItemSubtitle,
    ListItemAction,
    Status,
    PaypalIcon,
    SquareIcon,
    GoogleIcon,
    InstagramIcon,
    FacebookLetterFIcon,
    ShopifyIcon,
    AppAuthorizationDialog,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },

    readonly: Boolean,
    test: Boolean,
  },

  setup(props) {
    const store = useStore();
    const theme = useTheme();
    const isOpen = ref(false);
    const route = useRoute();

    const isConnected = computed(() => {
      return props.item.status === AUTHORIZATION_STATUS.CONNECTED;
    });

    const statusColor = computed(() => {
      return isConnected.value ? 'success' : 'error';
    });

    onMounted(() => {
      if (props.test && isConnected.value && !props.readonly) {
        try {
          store.dispatch('profiles/testAppAuthorization', props.item);
        } catch (e) {
          console.error(e);
        }
      }

      bus.$on(`app-authorization:${props.item._id.$oid}:open`, () => {
        isOpen.value = true;
      });

      // Handle PayPal authorization
      if (route.value.query.auth_id === props.item._id.$oid) {
        isOpen.value = true;
      }
    });

    onUnmounted(() => {
      bus.$off(`app-authorization:${props.item._id.$oid}:open`);
    });

    const styles = computed(() => {
      return {
        '--c-bg-color': isConnected.value
          ? '#fff'
          : tinycolor(theme.value.colors.error).setAlpha(0.04).toRgbString(),
      };
    });

    return { statusColor, styles, isOpen };
  },
};
</script>

<style lang="scss" scoped>
.v-app-auth-item {
  background: var(--c-bg-color);
  border: 1px solid var(--g-color-gray-200);
  border-radius: var(--g-border-radius-standard);
  border-radius: min(
    var(--g-border-radius-standard),
    var(--g-border-radius-min)
  );

  &__status {
    align-self: flex-start;
    margin-right: -0.25rem;
    min-width: unset;
  }
}
</style>
