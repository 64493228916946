<template>
  <ListItem
    component="button"
    type="button"
    @click="$emit('click', 'image-grid')"
  >
    <ListItemAction>
      <GridIcon size="1.25rem" />
    </ListItemAction>
    <ListItemContent>
      <ListItemTitle>Image Grid</ListItemTitle>
      <ListItemSubtitle wrap
        >Show your latest Instagram posts in a grid layout.</ListItemSubtitle
      >
    </ListItemContent>
  </ListItem>
</template>

<script>
import {
  ListItem,
  ListItemAction,
  ListItemContent,
  ListItemSubtitle,
  ListItemTitle,
  GridIcon,
} from '@campsite-bio/component-lib';

export default {
  components: {
    ListItem,
    ListItemAction,
    ListItemContent,
    ListItemSubtitle,
    ListItemTitle,
    GridIcon,
  },
};
</script>

<style lang="scss" scoped></style>
