<template>
  <ProfileOptInForm
    :is-sending="isSending"
    :success="success"
    :has-error="hasError"
    :error-message="errorMessage"
    v-bind="$props"
    @subscribe="handleSubscribe"
    @hide-error="hideError"
  />
</template>

<script>
import { ref } from 'vue';
import { ProfileOptInForm } from '@campsite-bio/component-lib';
import axios from 'axios';

export default {
  name: 'OptInFormWrapper',

  components: {
    ProfileOptInForm,
  },

  props: {
    link: {
      type: Object,
      required: true,
    },

    imageStyle: {
      type: String,
      default: null,
    },
    btnSize: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    const isSending = ref(false);
    const success = ref(false);
    const hasError = ref(false);
    const errorMessage = ref('');

    async function handleSubscribe(formData) {
      isSending.value = true;

      try {
        const { data } = await axios.request({
          method: 'post',
          url: `/api/public/links/${props.link._id.$oid}/subscribe-email`,
          data: formData,
          responseType: 'json',
        });

        if (data.errors && !data.errors.length) {
          success.value = true;
        } else {
          let error;
          if (data.errors && typeof data.errors[0] === 'object')
            error = data.errors[0].error;
          else if (data.errors && typeof data.errors[0] === 'string')
            error = data.errors[0];

          hasError.value = true;
          if (error) errorMessage.value = error;
        }
      } catch (e) {
        console.error(e);
        if (e.response && e.response.data && e.response.data.errors) {
          errorMessage.value = e.response.data.errors[0];
        }
        hasError.value = true;
      }

      isSending.value = false;
    }

    function hideError() {
      hasError.value = false;
    }

    return {
      handleSubscribe,
      hideError,
      isSending,
      success,
      hasError,
      errorMessage,
    };
  },
};
</script>

<style lang="scss" scoped></style>
