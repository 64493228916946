<template>
  <div class="span-12 analytics-grid__title">
    <VText class="analytics-grid__title__t" component="h2" variant="h4">
      <slot />
    </VText>
    <div v-if="$slots.text" class="analytics-grid__title__text">
      <VText component="p" variant="inherit" color="gray600">
        <slot name="text" />
      </VText>
    </div>
  </div>
</template>

<script>
import { VText } from '@campsite-bio/component-lib';

export default {
  components: {
    VText,
  },
};
</script>

<style lang="scss" scoped>
.analytics-grid__title {
  margin-top: 1rem;

  @media print {
    width: 100%;
  }

  &__text {
    font-size: 0.9rem;
    margin-top: 0.25rem;
  }
}

.analytics-grid__title__t.analytics-grid__title__t {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}
</style>
