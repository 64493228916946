<template>
  <Paper
    class="feature-card"
    :class="classes"
    :elevation="1"
    :color="bgColor"
    :style="styles"
  >
    <div class="feature-card__inner">
      <div class="feature-card__content">
        <FancyIconCircle
          v-if="$slots.icon"
          class="feature-card__icon"
          :color="textColor || 'primary'"
          :size="isLargeScreen ? '2rem' : '1.5rem'"
        >
          <slot name="icon" />
        </FancyIconCircle>
        <VText class="feature-card__title" component="h3" variant="h2">{{
          title
        }}</VText>
        <div class="feature-card__text">
          <slot />
        </div>
      </div>
      <div v-if="$slots.img" class="feature-card__img">
        <slot name="img" />
      </div>
    </div>
  </Paper>
</template>

<script>
import { computed } from 'vue';
import {
  Paper,
  VText,
  FancyIconCircle,
  useTheme,
  getTextColor,
} from '@campsite-bio/component-lib';
import { useMediaQuery } from '@vueuse/core';

export default {
  components: {
    Paper,
    VText,
    FancyIconCircle,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    bgColor: {
      type: String,
      default: 'gray100',
    },

    color: {
      type: String,
      default: null,
    },

    span: {
      type: [Number, String],
      default: null,
    },
  },

  setup(props) {
    const theme = useTheme();

    const isLargeScreen = useMediaQuery('(min-width: 768px)');

    const classes = computed(() => {
      return {
        'feature-card--span-2': +props.span === 2,
      };
    });

    const textColor = computed(() => {
      return props.color ? getTextColor(props.color, theme.value) : null;
    });

    const styles = computed(() => {
      return {
        '--c-text-color': textColor.value,
      };
    });

    return { classes, styles, textColor, isLargeScreen };
  },
};
</script>

<style lang="scss" scoped>
.feature-card {
  color: var(--c-text-color);

  &__content {
    padding: 2rem;
  }

  &--span-2 {
    @media (min-width: 768px) {
      grid-column: span 2;
    }
  }
}

.feature-card--span-2 {
  @media (min-width: 768px) {
    width: calc(100% - 1rem);
  }

  .feature-card__inner {
    display: grid;

    @media (min-width: 768px) {
      grid-template-columns: 0.5fr 1fr;
    }
  }

  .feature-card__img {
    padding: 1rem;
    margin-bottom: -2rem;
    overflow: hidden;
    border-radius: var(--g-border-radius-standard);
    border-radius: Min(
      var(--g-border-radius-standard),
      var(--g-border-radius-min)
    );

    @media (min-width: 768px) {
      align-self: center;
      margin-right: -1rem;
      margin-bottom: unset;
      padding-right: 0;
    }

    ::v-deep img {
      background-color: #fff;
      border-radius: inherit;
      border: 0.225rem solid white;
    }
  }
}

.feature-card__icon.feature-card__icon {
  margin-bottom: 1.5rem;
}

.feature-card__title.feature-card__title {
  margin-bottom: 1rem;
}

.feature-card__title.feature-card__title,
.feature-card__text ::v-deep p {
  color: inherit;
}
</style>
