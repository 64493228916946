<template>
  <ChartCard :title="title" :is-loading="isLoading" :timestamp="timestamp">
    <Badge
      v-if="percentDifference || typeof percentDifference === 'number'"
      slot="badge"
      :color="badgeColor"
      :content="percentDifferenceFormatted"
    />
    <div class="v-chart-card__numbers">
      <div class="v-chart-card__numbers__item">
        <VText component="span" variant="inherit">{{
          currentValueFormatted
        }}</VText>
        <VText
          v-show="currentValueSubtitle"
          component="span"
          variant="inherit"
          >{{ currentValueSubtitle }}</VText
        >
      </div>
      <div class="v-chart-card__numbers__item">
        <VText component="span" variant="inherit" :color="dimColor">{{
          previousValueFormatted
        }}</VText>
        <VText
          v-show="previousValueSubtitle"
          component="span"
          variant="inherit"
          :color="dimColor"
          >{{ previousValueSubtitle }}</VText
        >
      </div>
    </div>
    <slot />
    <div class="v-chart-card__timeline">
      <VText component="span" variant="inherit" :color="dimColor">{{
        startDate
      }}</VText>
      <VText component="span" variant="inherit" :color="dimColor">{{
        endDate
      }}</VText>
    </div>
  </ChartCard>
</template>

<script>
import {
  colors,
  VText,
  Badge,
  numberFormatter,
} from '@campsite-bio/component-lib';
import { toRefs, computed } from 'vue';
import ChartCard from './chart-card';

export default {
  components: {
    VText,
    Badge,
    ChartCard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },

    percentDifference: {
      type: [String, Number],
      required: true,
    },

    currentValue: {
      type: [String, Number],
      required: true,
    },
    currentValueSubtitle: {
      type: String,
      default: null,
    },

    previousValue: {
      type: [String, Number],
      required: true,
    },
    previousValueSubtitle: {
      type: String,
      default: null,
    },

    startDate: {
      type: String,
      required: true,
    },

    endDate: {
      type: String,
      required: true,
    },

    isLoading: Boolean,
    timestamp: Date,
  },

  setup(props) {
    const { percentDifference, currentValue, previousValue } = toRefs(props);

    const badgeColor = computed(() => {
      return percentDifference.value >= 0 ? 'success' : 'info';
    });

    const currentValueFormatted = computed(() => {
      return typeof currentValue.value === 'number'
        ? numberFormatter(currentValue.value, 2)
        : currentValue.value;
    });

    const previousValueFormatted = computed(() => {
      return typeof previousValue.value === 'number'
        ? numberFormatter(previousValue.value, 2)
        : previousValue.value;
    });

    const percentDifferenceFormatted = computed(() => {
      if (typeof percentDifference.value === 'number')
        return `${percentDifference.value.toFixed(1)}%`;
      return percentDifference.value;
    });

    return {
      badgeColor,
      dimColor: colors.grayDark,
      percentDifferenceFormatted,
      currentValueFormatted,
      previousValueFormatted,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-chart-card {
  &__numbers,
  &__timeline {
    align-items: center;
    display: flex;
  }

  &__numbers,
  &__timeline {
    justify-content: space-between;
  }

  &__numbers {
    font-size: 1.5rem;

    @media (min-width: 544px) {
      font-size: 1.75rem;
    }

    &__item {
      padding-bottom: 0.5em;
      position: relative;

      &:last-child .v-text {
        right: 0;
      }

      .v-text:last-child {
        font-size: 0.5em;
        bottom: 0;
        position: absolute;
        white-space: nowrap;
      }
    }
  }

  &__timeline {
    font-size: 0.75rem;
    margin-top: -0.25rem;
  }
}
</style>
