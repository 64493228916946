<template>
  <router-link
    class="suggest suggest--fixed"
    to="/account/referrals?utm_source=campsitead"
  >
    <img
      src="~images/referral-program-small.jpg"
      alt="Referral Program"
      loading="lazy"
    />
    <span class="suggest__text"
      >Get free Campsite Pro when you refer a friend.</span
    >
  </router-link>
</template>

<script>
import 'images/referral-program-small.jpg';

export default {};
</script>

<style lang="scss" scoped>
.suggest {
  background: #fff;
  color: #06c;
  display: block;
  margin: 1.5rem auto;
  max-width: 200px;
  width: 100%;
  text-decoration: none;
}

.suggest__text {
  display: block;
  font-size: 0.825rem;
  line-height: 1.4;
  padding: 0.625rem 1rem;
}

@media (min-width: 1800px) {
  .suggest--fixed {
    margin: 0;
    bottom: 0.625rem;
    left: calc(var(--header-width) + 0.825rem);
    position: fixed;
  }
}
</style>
