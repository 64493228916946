<template>
  <ChartCard
    ref="target"
    class="page-views-card"
    title="Activity"
    :highlights="highlights"
    :is-loading="isLoading"
  >
    <ChartWrapper
      width="100%"
      height="300"
      type="Line"
      :options="chartOptions"
      :data="series"
    />
  </ChartCard>
</template>

<script>
import { computed, toRefs } from 'vue';
import { useTheme, numberFormatter } from '@campsite-bio/component-lib';
import tinycolor from 'tinycolor2';

import ChartCard from './chart-card';
import { formatTooltipLabels, percentageChange } from '@/utils';
import {
  useApexOptions,
  useCategories,
  useDataColors,
  useOnDataColors,
} from '@/components/analytics/compositions';
import { ChartWrapper } from '..';

export default {
  components: {
    ChartWrapper,
    ChartCard,
  },

  props: {
    allData: {
      type: Object,
      default: null,
    },

    data: {
      type: Array,
      default: null,
    },

    includeReach: Boolean,
    isLoading: Boolean,
  },

  setup(props) {
    const { allData, data } = toRefs(props);
    const dataColors = useDataColors();
    const onDataColors = useOnDataColors();

    const dataToUse = computed(() => {
      if (!props.allData) return null;

      const primaryColor = tinycolor(dataColors.value[0]);

      const allData = {
        Views: {
          data: props.allData?.data.views_by_date || [],
          color: primaryColor.toString(),
          order: 3,
        },
        Reach: {
          data: props.allData?.data.reach_by_date || [],
          color: tinycolor(primaryColor).darken(15).toString(),
          order: 2,
          barPercentage: 0.7,
        },
        Clicks: {
          data: props.allData?.data.clicks_by_date || [],
          color: dataColors.value[1],
          order: 4,
          stack: 'clicks',
        },
        CTR: { data: [], color: dataColors.value[2], order: 1 },
      };

      allData.Views.data.forEach((views) => {
        const date = views._id.date;
        const clicks = allData.Clicks.data.find(
          (c) => c._id.date === date && c._id.range === views._id.range,
        );

        allData.CTR.data.push({
          _id: {
            date,
            range: views._id.range,
          },
          count:
            clicks && views.count > 0 ? (clicks.count / views.count) * 100 : 0,
        });
      });

      return allData;
    });

    function getSeriesData(range = 'current') {
      return {
        datasets: Object.keys(dataToUse.value).reduce((accum, key) => {
          if (key === 'Reach' && !props.includeReach) return accum;

          const { data: rawData, color, ...rest } = dataToUse.value[key];
          const data = rawData
            .filter((d) => d._id.range === range)
            .map((d) => d.count);

          if (key === 'CTR') {
            accum.push({
              label: key,
              type: 'line',
              data,
              backgroundColor: color,
              borderColor: color,
              xAxisID: 'x',
              yAxisID: 'y2',
              tension: 0.1,
              ...rest,
            });
            return accum;
          }

          accum.push({
            label: key,
            type: 'bar',
            data,
            backgroundColor: color,
            borderColor: color,
            barPercentage: 1.0,
            ...rest,
          });

          return accum;
        }, []),
        labels: categories.value,
      };
    }

    const { categories } = useCategories({ allData, data });

    const series = computed(() => {
      if (!dataToUse.value) return {};
      // Bring in dependencies
      const categoriesLocal = categories.value;

      return getSeriesData();
    });

    const highlights = computed(() => {
      if (!dataToUse.value) return [];

      const pastSeries = getSeriesData('past');

      return series.value.datasets.map(
        ({ label, data, backgroundColor }, i) => {
          const count = data.reduce((acc, stat) => {
            return acc + stat;
          }, 0);
          const pastCount = pastSeries.datasets[i].data.reduce((acc, stat) => {
            return acc + stat;
          }, 0);

          let title = count;
          let value = count;
          let change = percentageChange(pastCount, count);
          if (label === 'CTR') {
            const ctr = count / data.length;
            const pastCtr = pastCount / pastSeries.labels.length;
            change = percentageChange(pastCtr, ctr);
            title = `${ctr > 0 ? numberFormatter(ctr, 1) : ctr}%`;
            value = ctr;
          }

          return {
            title,
            subtitle: label,
            color: backgroundColor,
            badge: change,
            badgeColor: change > 0 ? 'success' : 'info',
            value,
          };
        },
      );
    });

    const ctrAverage = computed(() => {
      if (!dataToUse.value) return 0;

      return highlights.value.find((h) => h.subtitle === 'CTR').value;
    });

    const theme = useTheme();
    const chartOptions = useApexOptions(
      computed(() => ({
        scales: {
          x: {
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              maxRotation: 0,
            },
            min: 0,
            stacked: true,
          },
          y: {
            border: {
              display: false,
            },
            grid: {
              color: theme.value.colors.gray100,
            },
            min: 0,
          },
          y2: {
            position: 'right',
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              display: false,
              step: 20,
              callback: function (value) {
                return (value / 100).toLocaleString('en-US', {
                  style: 'percent',
                });
              },
            },
            min: 0,
          },
        },
        tooltip: {
          callbacks: {
            label(context) {
              if (context.dataset.label === 'CTR')
                return `CTR: ${numberFormatter(context.parsed.y, 2)}%`;
              return formatTooltipLabels(context);
            },
          },
          itemSort(a, b) {
            return a.datasetIndex - b.datasetIndex;
          },
        },
        plugins: {
          annotation: {
            annotations: {
              ctrLine: {
                type: 'line',
                mode: 'horizontal',
                yMin: ctrAverage.value,
                yMax: ctrAverage.value,
                borderColor: dataColors.value[2],
                borderWidth: 1,
                yScaleID: 'y2',
                borderDash: [5, 5],
                label: {
                  content: `CTR ${numberFormatter(ctrAverage.value, 1)}%`,
                  position: 'end',
                  display: true,
                  backgroundColor: tinycolor(dataColors.value[2])
                    .setAlpha(0.7)
                    .toString(),
                  color: onDataColors.value[2],
                  font: {
                    size: 10,
                  },
                },
              },
            },
          },
        },
      })),
    );

    return {
      chartOptions,
      series,
      categories,
      highlights,
    };
  },
};
</script>

<style lang="scss" scoped>
.page-views-card {
  min-height: unset;
}
</style>
