import { watch } from 'vue';

import router from '@/router';
import { hasProfileEntitlements } from '@/utils';
import { useRoute } from '@/compositions/use-route';
import toast from '@/utils/toast';
import { useCurrentProfile } from './use-current-profile';

export function useProfileEntitlements(to = '/') {
  const route = useRoute();
  const { profile, hasProfile, isOrgProfile } = useCurrentProfile();

  watch(
    [hasProfile, profile, route],
    ([value]) => {
      if (!value) return;

      const entitlements = route.value.meta.entitlements;
      const role = route.value.meta.role;

      let hasAccess = false;
      // If this is an org profile, check the role
      if (isOrgProfile.value) {
        const userRole = profile.value.user_access.role;

        if (role && role === 'owner' && profile.value.user_access.is_owner)
          hasAccess = true;
        else if (!role)
          hasAccess = userRole === 'admin' || userRole === 'write';
      } else {
        const userEntitlements = profile.value.user_access.entitlements;
        hasAccess =
          entitlements &&
          userEntitlements &&
          hasProfileEntitlements(entitlements, userEntitlements);
      }

      if (!hasAccess) {
        router.push(to);
        toast.error(
          'You do not have the required entitlements to view this page.',
        );
      }
    },
    { immediate: true },
  );
}
