<template>
  <div class="how-it-works">
    <div class="how-it-works__item">
      <FancyIconCircle>1</FancyIconCircle>
      <VText component="h3" variant="h4">Provide your website</VText>
      <VText>Give us your website URL and we'll do all of the hard work.</VText>
    </div>
    <div class="how-it-works__item">
      <FancyIconCircle>2</FancyIconCircle>
      <VText component="h3" variant="h4">Customize</VText>
      <VText>Tweak the options we've returned to your liking.</VText>
    </div>
    <div class="how-it-works__item">
      <FancyIconCircle>3</FancyIconCircle>
      <VText component="h3" variant="h4">The magic starts</VText>
      <VText v-if="hasToPay"
        >Pay a one time charge of <b>$5</b>, and we'll get your profile set up
        for you auto-magically.</VText
      >
      <VText v-else
        >We'll get your profile set up for you auto-magically.</VText
      >
    </div>
  </div>
</template>

<script>
import { FancyIconCircle, VText } from '@campsite-bio/component-lib';

export default {
  components: {
    FancyIconCircle,
    VText,
  },

  props: {
    hasToPay: Boolean,
  },

  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.how-it-works {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 2rem;
  margin-top: 2rem;

  &__item {
    ::v-deep .v-text {
      margin-top: 0.5rem;
    }
  }
}
</style>
