import { computed, inject, unref } from 'vue';
import { IntroStory, StatStory, TopPostsStory } from '../stories';

import { percentageChange } from '../../../../utils';

export function useDataFromWrapped() {
  const { wrappedData, name } = inject('wrapped');

  const isAttachedToAuth = computed(() => {
    return wrappedData.value.app_authorization_id !== null;
  });

  const stories = computed(() => {
    if (isAttachedToAuth.value) {
      const currentTopline = wrappedData.value.topline.find(
        (t) => t._id === 'current',
      );
      const pastTopline = wrappedData.value.topline.find(
        (t) => t._id === 'past',
      );

      const impressionsPercentDifference = percentageChange(
        pastTopline.impressions,
        currentTopline.impressions,
      );

      const profileViewsPercentDifference = percentageChange(
        pastTopline.profile_views,
        currentTopline.profile_views,
      );

      const reachPercentDifference = percentageChange(
        pastTopline.reach,
        currentTopline.reach,
      );

      const websiteClicksPercentDifference = percentageChange(
        pastTopline.website_clicks,
        currentTopline.website_clicks,
      );

      const currentData = wrappedData.value.by_month.filter(
        (d) => d._id.range === 'current',
      );
      const pastData = wrappedData.value.by_month.filter(
        (d) => d._id.range === 'past',
      );

      return [
        {
          template: 'intro',
          component: IntroStory,
          duration: 12000,
          skip: true,
          data: {
            name: unref(name),
          },
        },
        {
          template: 'impressions',
          component: StatStory,
          duration: 10000,
          data: {
            stat: currentTopline.impressions,
            label: 'media impressions in 2022',
            chartValues: currentData.reduce((accum, d) => {
              accum.push(d.impressions);
              return accum;
            }, []),
            pastChartValues: pastData.reduce((accum, d) => {
              accum.push(d.impressions);
              return accum;
            }, []),
          },
        },
        {
          template: 'impressions-change',
          component: StatStory,
          duration: 10000,
          data: {
            stat: impressionsPercentDifference,
            label: `${
              impressionsPercentDifference < 0 ? 'decrease' : 'increase'
            } in media impressions from 2021 (${pastTopline.impressions.toLocaleString(
              'en-US',
              {
                maximumFractionDigits: 2,
              },
            )})`,
            appendToStat: '%',
            chartValues: currentData.reduce((accum, d) => {
              accum.push(d.impressions);
              return accum;
            }, []),
            pastChartValues: pastData.reduce((accum, d) => {
              accum.push(d.impressions);
              return accum;
            }, []),
          },
        },
        {
          template: 'profile-views',
          component: StatStory,
          duration: 10000,
          data: {
            stat: currentTopline.profile_views,
            label: 'profile views in 2022',
            chartValues: currentData.reduce((accum, d) => {
              accum.push(d.profile_views);
              return accum;
            }, []),
            pastChartValues: pastData.reduce((accum, d) => {
              accum.push(d.profile_views);
              return accum;
            }, []),
          },
        },
        {
          template: 'profile-views-change',
          component: StatStory,
          duration: 10000,
          data: {
            stat: profileViewsPercentDifference,
            label: `${
              profileViewsPercentDifference < 0 ? 'decrease' : 'increase'
            } in profile views from 2021 (${pastTopline.profile_views.toLocaleString(
              'en-US',
              {
                maximumFractionDigits: 2,
              },
            )})`,
            appendToStat: '%',
            chartValues: currentData.reduce((accum, d) => {
              accum.push(d.profile_views);
              return accum;
            }, []),
            pastChartValues: pastData.reduce((accum, d) => {
              accum.push(d.profile_views);
              return accum;
            }, []),
          },
        },
        {
          template: 'reach',
          component: StatStory,
          duration: 10000,
          data: {
            stat: currentTopline.reach,
            label: 'reached in 2022',
            chartValues: currentData.reduce((accum, d) => {
              accum.push(d.reach);
              return accum;
            }, []),
            pastChartValues: pastData.reduce((accum, d) => {
              accum.push(d.reach);
              return accum;
            }, []),
          },
        },
        {
          template: 'reach-change',
          component: StatStory,
          duration: 10000,
          data: {
            stat: reachPercentDifference,
            label: `${
              reachPercentDifference < 0 ? 'decrease' : 'increase'
            } in reach from 2021 (${pastTopline.reach.toLocaleString('en-US', {
              maximumFractionDigits: 2,
            })})`,
            appendToStat: '%',
            chartValues: currentData.reduce((accum, d) => {
              accum.push(d.reach);
              return accum;
            }, []),
            pastChartValues: pastData.reduce((accum, d) => {
              accum.push(d.reach);
              return accum;
            }, []),
          },
        },
        {
          template: 'website-clicks',
          component: StatStory,
          duration: 10000,
          data: {
            stat: currentTopline.website_clicks,
            label: 'website clicks in 2022',
            chartValues: currentData.reduce((accum, d) => {
              accum.push(d.website_clicks);
              return accum;
            }, []),
            pastChartValues: pastData.reduce((accum, d) => {
              accum.push(d.website_clicks);
              return accum;
            }, []),
          },
        },
        {
          template: 'website-clicks-change',
          component: StatStory,
          duration: 10000,
          data: {
            stat: websiteClicksPercentDifference,
            label: `${
              websiteClicksPercentDifference < 0 ? 'decrease' : 'increase'
            } in website clicks from 2021 (${pastTopline.website_clicks.toLocaleString(
              'en-US',
              {
                maximumFractionDigits: 2,
              },
            )})`,
            appendToStat: '%',
            chartValues: currentData.reduce((accum, d) => {
              accum.push(d.website_clicks);
              return accum;
            }, []),
            pastChartValues: pastData.reduce((accum, d) => {
              accum.push(d.website_clicks);
              return accum;
            }, []),
          },
        },
        {
          template: 'top-posts',
          duration: 12000,
          component: TopPostsStory,
          data: {
            posts: wrappedData.value.top_posts,
          },
        },
      ];
    }

    const topline = wrappedData.value.topline[0];
    const currentData = wrappedData.value.by_month;

    return [
      {
        template: 'intro',
        component: IntroStory,
        duration: 12000,
        skip: true,
        data: {
          name: unref(name),
        },
      },
      {
        template: 'views',
        component: StatStory,
        duration: 10000,
        data: {
          stat: topline.views,
          label: 'profile views in 2022',
          chartValues: currentData.reduce((accum, d) => {
            accum.push(d.views);
            return accum;
          }, []),
        },
      },
      {
        template: 'clicks',
        component: StatStory,
        duration: 10000,
        data: {
          stat: topline.clicks,
          label: 'link clicks in 2022',
          chartValues: currentData.reduce((accum, d) => {
            accum.push(d.clicks);
            return accum;
          }, []),
        },
      },
      {
        template: 'ctr',
        component: StatStory,
        duration: 10000,
        data: {
          stat: (topline.clicks / topline.views) * 100,
          appendToStat: '%',
          label: 'CTR (click-through rate) in 2022',
          chartValues: currentData.reduce((accum, d) => {
            accum.push((d.clicks / d.views) * 100);
            return accum;
          }, []),
        },
      },
      {
        template: 'reach',
        component: StatStory,
        duration: 10000,
        data: {
          stat: topline.reach,
          label: 'reached in 2022',
          chartValues: currentData.reduce((accum, d) => {
            accum.push(d.reach);
            return accum;
          }, []),
        },
      },
    ];
  });

  return {
    stories,
  };
}
