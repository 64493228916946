<template>
  <Input
    v-model="localValue"
    :type="showPassword ? 'text' : 'password'"
    v-bind="{ ...$props, ...$attrs }"
  >
    <IconButton
      slot="append"
      :aria-label="showPassword ? 'Hide password' : 'Show password'"
      @click="showPassword = !showPassword"
    >
      <VisibilityOffIcon v-if="showPassword" />
      <VisibilityIcon v-else />
    </IconButton>
  </Input>
</template>

<script>
import {
  IconButton,
  Input,
  InputProps,
  VisibilityIcon,
  VisibilityOffIcon,
} from '@campsite-bio/component-lib';
import { ref, toRefs } from 'vue';
import { useModel } from '../../compositions';

export default {
  components: {
    Input,
    IconButton,
    VisibilityIcon,
    VisibilityOffIcon,
  },

  props: InputProps,

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const showPassword = ref(false);
    const localValue = useModel(value, (val) => emit('input', val));

    return { showPassword, localValue };
  },
};
</script>

<style lang="scss" scoped></style>
