import { colors } from '@campsite-bio/component-lib';

/**
 * Returns the medal emoji based on the rank
 * @param {Number} rank
 * @returns {String} medal emoji
 */
export function getMedalEmojiFromRank(rank) {
  switch (rank) {
    case 1:
      return '🥇';
    case 2:
      return '🥈';
    case 3:
      return '🥉';
    default:
      return '';
  }
}

/**
 * Returns the color of the medal based on the rank
 * @param {Number} rank
 * @returns {String} color
 */
export function getMedalColorFromRank(rank) {
  switch (rank) {
    case 1:
      return colors.goldLight;
    case 2:
      return colors.silver;
    case 3:
      return colors.bronze;
    default:
      return '';
  }
}
