<template>
  <div>
    <ValidationProvider
      :rules="hasAPIKey ? '' : 'required'"
      name="audience"
      slim
    >
      <Alert v-if="!hasAPIKey" style="margin-bottom: 1rem">
        <InfoIcon slot="icon" />
        We can't find an Omnisend API key for your profile.
        <Button
          slot="actions"
          :to="`/profile/${profileId}/settings#integrations`"
          variant="flat"
          color="white"
        >
          Add&nbsp;<span style="font-size: 1.25em">→</span>
        </Button>
      </Alert>
    </ValidationProvider>
  </div>
</template>

<script>
import { Button, Alert, InfoIcon } from '@campsite-bio/component-lib';
import { computed } from 'vue';
import { ValidationProvider } from 'vee-validate';

import { useCurrentProfile, useStore } from '../../../../compositions';
import { getMeta } from '../../../../utils';

export default {
  components: {
    Button,
    Alert,
    InfoIcon,
    ValidationProvider,
  },

  setup() {
    const store = useStore();

    const { profile, id: profileId } = useCurrentProfile();

    const hasAPIKey = computed(() => {
      return !!getMeta('omnisend_api_key', null, profile.value.meta);
    });

    return { hasAPIKey, profileId };
  },
};
</script>

<style lang="scss" scoped></style>
