<template>
  <div class="media-inner">
    <div
      v-infinite-scroll="loadMore"
      class="media-inner__content"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="20"
      infinite-scroll-immediate-check="false"
    >
      <div class="media-items-row row row--no-gutters">
        <div v-if="posts.length" class="col col--xs-6 col--no-gutters">
          <div class="media-item media-item--future">
            <input
              id="insta00"
              ref="future"
              class="image-select"
              type="radio"
              name="post_select"
              :checked="!!minID"
              @change="saveImage()"
            />
            <label for="insta00" @click="doubleClick('00')">
              <span class="media-item__future-inner">
                <UpdateIcon />
                <span>Upcoming post</span>
              </span>
            </label>
          </div>
        </div>
        <div
          v-for="post in posts"
          :key="post.id"
          class="col col--xs-6 col--no-gutters"
        >
          <div class="media-item">
            <input
              :id="'insta' + post.id"
              class="image-select"
              type="radio"
              name="post_select"
              :checked="isPostChecked(post)"
              @change="saveImage(post)"
            />
            <label :for="'insta' + post.id" @click="doubleClick(post.id)">
              <img
                :src="post.thumbnail_url ? post.thumbnail_url : post.media_url"
                :alt="post.caption"
              />
            </label>
          </div>
        </div>
      </div>

      <div v-show="loading" class="text--xs-center">
        <CircularLoader size="2.5rem" style="margin: 15px auto" />
      </div>
    </div>
    <div class="media-inner__actions">
      <Divider color="lightgray" />
      <CardActions>
        <span class="modal__tip">Double click to select a post</span>
        <FlexSpacer />
        <Button
          :disabled="postImageUrl === '' && !minID"
          @click.prevent="selectImage"
          >Select</Button
        >
      </CardActions>
    </div>
  </div>
</template>

<script>
import {
  CardActions,
  Button,
  Divider,
  FlexSpacer,
  CircularLoader,
  UpdateIcon,
} from '@campsite-bio/component-lib';
import toast from '../../utils/toast';
import { localAxios } from '../../apis/';

export default {
  components: {
    CardActions,
    Button,
    FlexSpacer,
    Divider,
    CircularLoader,
    UpdateIcon,
  },

  props: {
    show: Boolean,
  },

  data() {
    return {
      posts: [],
      next: undefined,
      minID: undefined,
      postImageUrl: '',
      loading: true,
      disableLoad: false,
      count: 30,
      post: undefined,
    };
  },

  computed: {
    user() {
      return this.$store.getters.currentUser;
    },

    settings() {
      return this.$store.getters['profiles/currentProfile'];
    },

    busy() {
      return this.loading || this.disableLoad;
    },

    showMediaDialog() {
      return this.$store.getters.showMediaDialog;
    },
  },

  watch: {
    show(newValue) {
      if (newValue) this.opened();
    },

    showMediaDialog(newValue) {
      if (newValue && this.show) this.opened();
    },
  },

  created: function () {
    this.opened();
  },

  methods: {
    async opened() {
      try {
        const { data } = await localAxios.get(
          `/api/profiles/${
            this.$store.getters['profiles/currentProfileId']
          }/instagram-feed?count=${this.count - 1}`,
        );

        this.checkCache(data, this.count - 1);
      } catch (e) {
        const { code, error_message, auth } =
          (e.response && e.response.data) || {};
        switch (code) {
          case 10:
          case 190:
            toast.error(error_message, { timeout: -1, multiline: true });
            this.$store.commit('profiles/updateAuthorization', auth);
            break;

          default:
            toast.error(
              error_message
                ? error_message
                : 'There was an error loading your Instagram posts',
              { timeout: 5000 },
            );
            break;
        }
      }

      this.loading = false;
    },

    saveImage(post) {
      if (post) {
        const mediaUrl = post.thumbnail_url
          ? post.thumbnail_url
          : post.media_url;
        this.minID = '';
        this.postImageUrl = mediaUrl;
        this.post = post;
      } else {
        this.postImageUrl = '';
        this.minID = this.posts[0].id;
        this.post = undefined;
      }
    },

    isPostChecked(post) {
      const mediaUrl = post.thumbnail_url ? post.thumbnail_url : post.media_url;
      return this.postImageUrl === mediaUrl;
    },

    // if the user is clicking the same image twice, select the image
    doubleClick(id) {
      if (document.getElementById('insta' + id).checked) {
        this.selectImage();
      }
    },

    checkCache(feed, pageSize) {
      this.posts = feed.data;
      if (feed.paging && feed.paging.cursors)
        this.next = feed.paging.cursors.after;

      if (this.posts.length < pageSize) {
        this.disableLoad = true;
      }
    },

    async loadMore() {
      this.loading = true;

      try {
        const { data } = await localAxios.get(
          `/api/profiles/${this.$store.getters['profiles/currentProfileId']}/instagram-feed?count=${this.count}&next=${this.next}`,
        );

        this.posts = [...this.posts, ...data.data];
        this.next = data.paging.cursors.after;
        if (data.data.length < this.count) {
          this.disableLoad = true;
        }
      } catch (e) {
        console.error(e);
        toast.error('Error loading your Instagram posts', { timeout: 3000 });
      }

      this.loading = false;
    },

    selectImage() {
      this.$emit('select', {
        source: 'instagram',
        url: this.postImageUrl,
        minId: this.minID,
        media: this.post,
      });

      // clear selection
      this.postImageUrl = '';
      this.minID = '';
    },
  },
};
</script>

<style scoped></style>
