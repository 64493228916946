import { computed, inject, watch } from 'vue';

import { useStore } from '../../compositions';

export function useSelectionMode() {
  const store = useStore();
  const { isEnabled, selectedLinks, selectedCheckbox, isActionLoading } =
    inject('config', {});

  const links = computed(() => {
    return store.getters['links/links'];
  });

  const totalLinks = computed(() => {
    return links.value.length;
  });

  const isSelectionModeEnabled = computed(() => {
    return isEnabled?.value;
  });

  const areAllLinkSelected = computed(() => {
    return links.value.length === selectedLinks.value.length;
  });

  const selectedLinksLinks = computed(() => {
    return links.value.filter((l) => selectedLinks.value.includes(l._id.$oid));
  });

  // Keep track of whether a group link has been selected
  // We don't want users to be able to create a group link that contains a group link
  const isGroupLinkSelected = computed(() => {
    return selectedLinksLinks.value.some((l) => l.type === 'group');
  });

  // If links change, check selected links to make sure they still exist
  watch(links, (newLinks) => {
    selectedLinks.value = selectedLinks.value.filter((l) =>
      newLinks.some((nl) => nl._id.$oid === l),
    );
  });

  function toggleSelectionMode() {
    isEnabled.value = !isEnabled.value;
    selectedLinks.value = [];
  }

  function resetSelectedLinks() {
    selectedLinks.value = [];
  }

  function toggleLinkSelection(linkId) {
    if (selectedLinks.value.includes(linkId)) {
      selectedLinks.value = selectedLinks.value.filter((l) => l !== linkId);
    } else {
      selectedLinks.value = [...selectedLinks.value, linkId];
    }
  }

  function toggleAllLinksSelection() {
    if (areAllLinkSelected.value) {
      selectedLinks.value = [];
      selectedCheckbox.value = false;
    } else {
      selectedLinks.value = links.value.map((l) => l._id.$oid);
      selectedCheckbox.value = true;
    }
  }

  return {
    isSelectionModeEnabled,
    selectedLinks,
    totalLinks,
    areAllLinkSelected,
    toggleSelectionMode,
    toggleLinkSelection,
    toggleAllLinksSelection,
    isActionLoading,
    resetSelectedLinks,
    isGroupLinkSelected,
  };
}
