<template>
  <Fragment>
    <CardText>
      <VText gutter-bottom
        >Setup a square account in around 10 minutes. Here are some things
        you'll need:</VText
      >
      <ul>
        <li>Business name</li>
        <li>100 points of ID</li>
        <li>Banking details</li>
      </ul>
    </CardText>
    <CardActions align="center" direction="column">
      <Button href="https://squareup.com/i/CAMPSTEBIO" target="_blank"
        >Create Square Account</Button
      >
    </CardActions>
  </Fragment>
</template>

<script>
import {
  CardText,
  VText,
  CardActions,
  Button,
} from '@campsite-bio/component-lib';
import { Fragment } from 'vue-fragment';
import { inject } from 'vue';

import { useSquareAuthorization, useStore } from '../../../../compositions';

export default {
  components: {
    CardText,
    CardActions,
    VText,
    Button,
    Fragment,
  },

  setup() {
    const store = useStore();
    const { isOpen } = inject('multi-step-dialog');

    const { authorize } = useSquareAuthorization({
      onCancel() {},
      onSuccess() {
        store.dispatch('profiles/getAppAuthorizations');
        isOpen.value = false;
      },
    });

    return { authorize };
  },
};
</script>

<style lang="scss" scoped></style>
