<template>
  <LinkExpander
    :id="`schedule-${id}`"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template slot="header">
      Schedule link
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <IconButton
            href="https://support.campsite.bio/en/articles/6848826-scheduling-your-links"
            target="_blank"
            aria-label="Schedule your links help"
            size="1.25rem"
            v-bind="attrs"
            v-on="on"
          >
            <QuestionCircleOIcon />
          </IconButton>
        </template>
        <span>Get some help scheduling your links</span>
      </v-tooltip>
    </template>

    <LinkScheduler v-if="!isFreePlan" :link="link" />
    <div v-else class="text--xs-center">
      <p class="add-margin-btm-small">
        To schedule your links, upgrade to pro.
      </p>
      <Button size="small" to="/account/change-plan">Find out more</Button>
    </div>
  </LinkExpander>
</template>

<script>
import {
  Button,
  IconButton,
  QuestionCircleOIcon,
} from '@campsite-bio/component-lib';
import LinkExpander from './link-expander.vue';
import { useStore } from '../../../compositions';
import { computed, inject } from 'vue';
import LinkScheduler from '../link-scheduler.vue';

export default {
  components: {
    LinkExpander,
    Button,
    IconButton,
    QuestionCircleOIcon,
    LinkScheduler,
  },

  props: {
    isOpen: Boolean,
    link: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();
    const { id } = inject('link');

    const isFreePlan = computed(() => {
      return store.getters['profiles/isProfileOnFreePlan'];
    });

    return { id, isFreePlan };
  },
};
</script>

<style lang="scss" scoped></style>
