import { computed, ref, watch } from 'vue';
import {
  convertImageUrlToEncodedUrl,
  MEDIA_SIZES,
} from '@campsite-bio/component-lib';
import { DateTime } from 'luxon';

import { localAxios } from '@/apis';
import store from '@/store';
import { useCurrentUserProfileEntitlements } from '../use-current-user-profile-entitlements';
import { useRouter } from '../use-router';
import { useRoute } from '../use-route';

export function useProfileForReports() {
  const route = useRoute();
  const router = useRouter();
  const isLoading = ref(false);
  const selectedProfile = computed({
    get() {
      return store.getters['analytics/getField']('selectedProfile');
    },
    set(newValue) {
      store.commit('analytics/set', {
        name: 'selectedProfile',
        value: newValue,
      });
    },
  });

  if (!selectedProfile.value) {
    selectedProfile.value = store.getters['profiles/currentId'];
  }

  const profile = ref(null);
  const { hasAnalyticsAddon, canManageAnalytics } =
    useCurrentUserProfileEntitlements(profile, false);
  const token = computed(() => {
    return profile.value?.token;
  });

  const profileImage = computed(() => {
    return convertImageUrlToEncodedUrl(
      profile.value?.profile_image,
      MEDIA_SIZES.profile_image,
    );
  });

  const showNewProfileMessage = computed(() => {
    const createdAt = DateTime.fromISO(profile.value?.created_at);
    return DateTime.local().minus({ days: 10 }) < createdAt;
  });

  watch(
    route,
    () => {
      if (route.value.params.id) {
        selectedProfile.value = route.value.params.id;
      }
    },
    { immediate: true },
  );

  watch(
    selectedProfile,
    () => {
      const currentId = route.value.params.id;
      if (currentId !== selectedProfile.value)
        router.push({ params: { id: selectedProfile.value } });
      loadProfile(selectedProfile.value);
    },
    { immediate: true },
  );

  async function loadProfile(id) {
    isLoading.value = true;
    try {
      const { data } = await localAxios.get(`/api/profiles/${id}`);
      profile.value = data.profile;
    } catch (e) {
      console.error(e);
    }
    isLoading.value = false;
  }

  return {
    isLoading,
    selectedProfile,
    profile,
    profileImage,
    hasAnalyticsAddon,
    canManageAnalytics,
    token,
    showNewProfileMessage,
  };
}
