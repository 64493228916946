<template>
  <ValidationObserver ref="form">
    <CardText>
      <VText gutter-bottom style="font-size: 0.9rem"
        >By connecting your PayPal account, you agree that you will be solely
        liable for any transactions, including the products and/or services you
        make available, with your end user. Campsite.bio is not a party to the
        transaction.</VText
      >

      <ValidationProvider
        v-slot="{ errors, ariaMsg, ariaInput }"
        rules="required"
        name="currency"
        slim
      >
        <FormGroup>
          <Label for="currencyCode">Select your currency</Label>
          <Select id="currencyCode" v-model="currencyCode" v-bind="ariaInput">
            <option :value="null">- Select a currency -</option>
            <option value="USD">USD</option>
            <option disabled>-------</option>
            <option v-for="code in PAYPAL_CURRENCIES" :key="code" :value="code">
              {{ code }}
            </option>
          </Select>
          <FormGroupHelp color="error" v-bind="ariaMsg">{{
            errors[0]
          }}</FormGroupHelp>
        </FormGroup>
      </ValidationProvider>
    </CardText>
    <CardActions id="test" align="center" direction="column">
      <Button :loading="isLoading" @click="beforeAuthorize"
        >Connect PayPal Account</Button
      >
      <InlineLink
        component="button"
        type="button"
        style="margin-top: 0.5rem"
        :disabled="isLoading"
        @click="beforeAuthorize"
        >I don't have an account</InlineLink
      >
    </CardActions>
  </ValidationObserver>
</template>

<script>
import {
  CardText,
  VText,
  CardActions,
  Button,
  InlineLink,
  FormGroup,
  FormGroupHelp,
  Select,
  Label,
} from '@campsite-bio/component-lib';
import { ref } from 'vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import { usePayPalAuthorization } from '../../../../compositions';
import { PAYPAL_CURRENCIES } from '../../../../config';

export default {
  components: {
    CardText,
    CardActions,
    VText,
    Button,
    InlineLink,
    FormGroup,
    FormGroupHelp,
    Select,
    Label,
    ValidationObserver,
    ValidationProvider,
  },

  setup() {
    const isLoading = ref(false);
    const form = ref(null);
    const currencyCode = ref(null);

    async function beforeAuthorize() {
      const success = await form.value.validate();
      if (!success) return;

      isLoading.value = true;
      authorize();
    }

    const { authorize } = usePayPalAuthorization({}, currencyCode);

    return {
      form,
      beforeAuthorize,
      isLoading,
      PAYPAL_CURRENCIES,
      currencyCode,
    };
  },
};
</script>

<style lang="scss" scoped></style>
