<template>
  <div>
    <ValidationProvider
      v-slot="{ errors, ariaInput, ariaMsg }"
      :rules="{
        required: true,
        regex: /^https:\/\/hooks\.zapier\.com\/hooks\/catch\/\d+\/\w+\/$/,
      }"
      name="webhook url"
      slim
    >
      <FormGroup>
        <FormGroupHeader gutter-bottom>
          <Label :for="'zapier_webhook' + _uid">Zapier Webhook Url</Label>
          <FlexSpacer />
          <v-tooltip top>
            <template #activator="{ on }">
              <QuestionCircleOIcon style="font-size: 20px" v-on="on" />
            </template>
            <span>Choose the form sign ups will be added to</span>
          </v-tooltip>
        </FormGroupHeader>
        <Input
          :id="'zapier_webhook' + _uid"
          v-model="webhookUrl"
          type="url"
          v-bind="ariaInput"
          placeholder="https://hooks.zapier.com/hooks/catch/2349493/5kd291i/"
        />
        <FormGroupHelp color="error" v-bind="ariaMsg">{{
          errors[0]
        }}</FormGroupHelp>
        <FormGroupHelp v-if="errors.length === 0">
          <a
            href="https://support.campsite.bio/en/articles/8382442-setting-up-a-zapier-webhook"
            target="_blank"
            >Learn how to set up your Zapier webhook</a
          >.
        </FormGroupHelp>
      </FormGroup>
    </ValidationProvider>
  </div>
</template>

<script>
import {
  FormGroup,
  FormGroupHeader,
  FormGroupHelp,
  Label,
  FlexSpacer,
  QuestionCircleOIcon,
  Button,
  Input,
} from '@campsite-bio/component-lib';
import { computed, ref, toRefs, watch } from 'vue';
import { ValidationProvider } from 'vee-validate';

import { useCurrentProfile } from '@/compositions';

export default {
  components: {
    FormGroup,
    FormGroupHeader,
    FormGroupHelp,
    Label,
    FlexSpacer,
    QuestionCircleOIcon,
    ValidationProvider,
    Button,
    Input,
  },

  props: {
    link: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { link } = toRefs(props);
    const webhookUrl = ref(null);

    const { profile, id: profileId } = useCurrentProfile();

    const emailOptions = computed(() => {
      return link.value.email_options;
    });

    watch(
      emailOptions,
      ({ destination_id }) => {
        webhookUrl.value = destination_id;
      },
      { immediate: true },
    );

    watch(webhookUrl, () => {
      emit('change', {
        id: webhookUrl.value,
      });
    });

    return { webhookUrl, profileId };
  },
};
</script>

<style lang="scss" scoped></style>
