import {
  PodcastIcon,
  PodiumIcon,
  TextBoxOutlineIcon,
} from '@campsite-bio/component-lib';
import { computed, ref, unref } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';

import { localAxios } from '../../apis';
import toast from '../../utils/toast';

export function useNotification(defaultNotification, { emit }) {
  const root = ref(null);
  const isDismissing = ref(false);

  const rootEl = computed(() => {
    return root.value?.$el;
  });

  useIntersectionObserver(
    rootEl,
    ([{ isIntersecting }]) => {
      if (isIntersecting) handleImpression();
    },
    {
      threshold: 0.75,
    },
  );

  const notification = computed(() => {
    return unref(defaultNotification);
  });

  const id = computed(() => {
    return typeof notification.value._id === 'object'
      ? notification.value._id.$oid
      : notification.value._id;
  });

  const title = computed(() => {
    if (notification.value.title) {
      return notification.value.title;
    }

    switch (notification.value.subtype.toLowerCase()) {
      case 'itw':
        return 'In the Wild podcast';
      case 'podium_finish':
        return 'Leaderboard report';
      default:
        return null;
    }
  });

  const message = computed(() => {
    return notification.value.message;
  });

  const icon = computed(() => {
    switch (notification.value.subtype.toLowerCase()) {
      case 'itw':
        return PodcastIcon;
      case 'podium_finish':
        return PodiumIcon;
      case 'blog':
        return TextBoxOutlineIcon;
      default:
        return null;
    }
  });

  const actions = computed(() => {
    if (!notification.value.send_to) return null;

    switch (notification.value.send_to) {
      case 'insights':
        return [
          {
            text: 'View Insights',
            to: `/reports/insights/${notification.value.campsite_id.$oid}`,
          },
        ];
      case 'leaderboard':
        return [
          {
            text: 'View Leaderboard',
            to: `/reports/leaderboard/${notification.value.campsite_id.$oid}`,
          },
        ];
      default:
        return [
          {
            text: 'View More',
            href: notification.value.send_to,
            target: '_blank',
          },
        ];
    }
  });

  async function logClick() {
    try {
      await localAxios.put(`/api/notifications/${id.value}`, {
        clicked: true,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async function handleImpression() {
    try {
      await localAxios.put(`/api/notifications/${id.value}/impression`);
    } catch (e) {
      console.error(e);
    }
  }

  async function dismiss() {
    isDismissing.value = true;

    try {
      await localAxios.put(`/api/notifications/${id.value}`, {
        dismissed: true,
      });

      emit('dismissed');
    } catch (e) {
      console.error(e);
      toast.error('Error dismissing notification. Please try again later.');
    }

    isDismissing.value = false;
  }

  return {
    root,
    title,
    message,
    icon,
    actions,
    dismiss,
    logClick,
    isDismissing,
  };
}
