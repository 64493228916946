<template>
  <table class="table summary-table">
    <thead v-if="$slots.thead">
      <slot name="thead" />
    </thead>
    <tbody>
      <slot />
    </tbody>
  </table>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.summary-table {
  &::v-deep td:last-child {
    text-align: right;
  }
}
</style>
