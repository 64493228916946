<template>
  <div class="link-card-wrapper">
    <Paper
      ref="root"
      class="link js-link"
      :class="{ ...linkClasses, ...cardClasses }"
      :elevation="linkCardElevation"
      :hide-static-shadow="linkCardElevation < 0"
      @click="handleLinkClick"
    >
      <div class="link__inner">
        <div class="link__left-action">
          <div
            v-if="isSelectionModeEnabled && !loader && !isUniversalLink"
            class="link__selection"
          >
            <Checkbox :input-value="isLinkSelected" :disabled="isFreePlan" />
          </div>
          <template v-else-if="canDrag">
            <div v-if="isLoading" class="link__loader">
              <CircularLoader size="1.125rem" />
            </div>
            <div v-else-if="!isDragHidden" class="link__drag">
              <DragIcon class="icon" />
            </div>
          </template>
        </div>
        <div class="link__content">
          <div class="link__top">
            <div class="link__fields">
              <slot />
            </div>
            <slot name="media" />
          </div>
          <div class="link__btm">
            <template v-if="!isSelectionModeEnabled">
              <template v-if="!loader">
                <VisibilityToggle
                  v-if="!isDeleted"
                  v-model="enabled"
                  :disabled="isReadonly"
                  class="js-link-toggle"
                  label="Enable link"
                />

                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <LinkBtn
                      color="primary"
                      v-bind="{ ...attrs, ...defaultLinkButtonProps }"
                      :aria-controls="`default-${id}`"
                      :disabled="isReadonly"
                      v-on="on"
                      @click="toggleExpand('default')"
                    >
                      <component :is="linkIconComponent" slot="icon" />
                    </LinkBtn>
                  </template>
                  <span>{{ defaultLinkButtonProps['aria-label'] }}</span>
                </v-tooltip>
              </template>

              <slot name="btm" />

              <template v-if="!loader && !isReadonly">
                <FlexSpacer />

                <LinkBtn
                  v-if="isDeleted || !canArchive || isInUniversalLinkGroup"
                  aria-label="Delete link"
                  @click="isDeleteDialogOpen = true"
                >
                  <TrashIcon slot="icon" />
                </LinkBtn>
                <v-menu v-else bottom>
                  <template #activator="{ on, attrs }">
                    <LinkBtn
                      aria-label="Delete link"
                      :disabled="isDeleting"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <TrashIcon slot="icon" />
                    </LinkBtn>
                  </template>
                  <List>
                    <ListItem
                      component="button"
                      type="button"
                      @click="handleArchive"
                    >
                      <ListItemAction><ArchiveIcon /></ListItemAction>
                      <ListItemTitle>Archive</ListItemTitle>
                    </ListItem>
                    <ListItem
                      component="button"
                      type="button"
                      @click="isDeleteDialogOpen = true"
                    >
                      <ListItemAction><TrashIcon /></ListItemAction>
                      <ListItemTitle>Delete</ListItemTitle>
                    </ListItem>
                  </List>
                </v-menu>
              </template>
              <template v-else-if="!loader && isUniversalLink">
                <FlexSpacer />

                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <span class="link__ul" v-bind="attrs" v-on="on">
                      Universal link
                    </span>
                  </template>
                  <span>Universal link from org</span>
                </v-tooltip>
              </template>
            </template>
            <template v-else>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <LinkBtn
                    color="primary"
                    component="div"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <component :is="linkIconComponent" slot="icon" />
                  </LinkBtn>
                </template>
                <span>{{ linkReadableName }}</span>
              </v-tooltip>

              <slot name="readonly-btm" />
            </template>
          </div>
        </div>
      </div>

      <slot name="actions" />
    </Paper>

    <template v-if="linkStackShadow">
      <Paper class="link link--stack-shadow" />
      <Paper class="link link--stack-shadow" />
    </template>
  </div>
</template>

<script>
import {
  Paper,
  DragIcon,
  CircularLoader,
  List,
  ListItem,
  ListItemTitle,
  ListItemAction,
  ArchiveIcon,
  TrashIcon,
  FlexSpacer,
  Checkbox,
  VisibilityToggle,
} from '@campsite-bio/component-lib';
import { computed, inject, onMounted, ref } from 'vue';

import LinkBtn from './link-btn';

export default {
  components: {
    Paper,
    DragIcon,
    CircularLoader,
    List,
    ListItem,
    ListItemTitle,
    ListItemAction,
    ArchiveIcon,
    TrashIcon,
    FlexSpacer,
    LinkBtn,
    Checkbox,
    VisibilityToggle,
  },

  props: {
    loader: Boolean,
    /** Make it look like there are multiple links stacked under the current one */
    linkStackShadow: Boolean,
  },

  setup(props) {
    if (props.loader)
      return {
        linkClasses: '',
        linkCardElevation: -1,
        isSelectionModeEnabled: false,
        handleLinkClick: () => {},
        cardClasses: {},
        canDrag: false,
      };

    const cardClasses = computed(() => {
      return {
        'link--stacked': props.linkStackShadow,
      };
    });

    const {
      id,
      root: linkRootEl,
      linkClasses,
      linkCardElevation,
      isPinned,
      isDeleted,
      isSaving,
      isRestoring,
      isDeleting,
      isLoading,
      isDragHidden,
      isReadonly,
      isDeleteDialogOpen,
      handleArchive,
      canArchive,
      isSelectionModeEnabled,
      handleLinkClick,
      isLinkSelected,
      linkIconComponent,
      linkReadableName,
      enabled,
      isExpanded,
      toggleExpand,
      hasDefaultExpand,
      isFreePlan,
      isUniversalLink,
      isUniversalLinkPinned,
      isInUniversalLinkGroup,
    } = inject('link');
    const root = ref(null);

    onMounted(() => {
      linkRootEl.value = root.value.$el;
    });

    const defaultLinkButtonProps = computed(() => {
      if (hasDefaultExpand.value) {
        return {
          component: 'button',
          disabled: isDeleted.value,
          'aria-label': `${linkReadableName.value} settings`,
          active: isExpanded('default'),
        };
      }

      return {
        'aria-label': linkReadableName.value,
        component: 'div',
      };
    });

    const canDrag = computed(() => {
      return (
        !props.loader &&
        (!isReadonly.value ||
          (isUniversalLink.value && !isUniversalLinkPinned.value))
      );
    });

    return {
      id,
      cardClasses,
      root,
      linkClasses,
      linkCardElevation,
      isPinned,
      isDeleted,
      isSaving,
      isRestoring,
      isDeleting,
      isLoading,
      isDragHidden,
      isReadonly,
      isDeleteDialogOpen,
      handleArchive,
      canArchive,
      isSelectionModeEnabled,
      handleLinkClick,
      isLinkSelected,
      linkIconComponent,
      linkReadableName,
      enabled,
      isExpanded,
      toggleExpand,
      hasDefaultExpand,
      defaultLinkButtonProps,
      isFreePlan,
      isUniversalLink,
      isUniversalLinkPinned,
      isInUniversalLinkGroup,
      canDrag,
    };
  },
};
</script>

<style lang="scss">
.media-selector--post.media-selector--post.media-selector--post {
  right: 0;
  height: 4.375rem;
  position: absolute;
  top: calc(100% + 0.75rem);
  width: 4.375rem;
}

@media (min-width: 544px) {
  .media-selector--post.media-selector--post.media-selector--post {
    height: 4.75rem;
    top: auto;
    right: auto;
    position: relative;
    width: 4.75rem;
  }
}
</style>

<style lang="scss" scoped>
.link-card-wrapper {
  position: relative;
}

.link.link {
  background: rgba(#fff, 0.95);
  border: 1px solid var(--g-color-gray-100);
  border-radius: 0;
  position: relative;
  transition: all var(--g-transition-speed);
  transform: translateZ(0);
  z-index: 2;

  &--stacked {
    border-color: var(--g-color-gray-200);
    margin-bottom: 0.5rem;
  }

  &:focus-within {
    border-color: var(--g-color-gray-300);
  }

  &--stack-shadow {
    background: #fff;
    border: 1px solid var(--g-color-gray-200);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transform: translate3d(0.25rem, 0.25rem, 0);

    &:last-child {
      transform: translate3d(0.5rem, 0.5rem, 0);
      z-index: 0;
    }
  }
}

.link__inner {
  display: flex;
}

.link__left-action {
  width: 2.1875rem;
  position: relative;
  flex-shrink: 0;

  > * {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
}

.link__content {
  padding: 0.625rem 0.925rem 0.625rem 0;
  position: relative;
  flex-grow: 1;
  min-width: 0;
}

.link__top {
  position: relative;
}

.link__fields {
  flex-grow: 1;
}

.link__btm {
  margin-top: 1rem;
  min-height: 4.375rem;
  padding-right: 5rem;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;

  &::v-deep {
    .v-toggle {
      margin-right: 0.5rem;
    }

    .v-flex-spacer {
      display: none;
    }
  }
}

.link--no-post .link__btm {
  padding-right: 0;
  min-height: unset;
}

.link__drag {
  border: none;
  background: transparent;
  color: var(--g-color-primary);
  cursor: move;

  > .v-svg-icon {
    font-size: 1.5rem;
  }
}

@media (min-width: 425px) {
  .link.link {
    border-radius: var(--g-border-radius-standard);
    border-radius: min(
      var(--g-border-radius-standard),
      var(--g-border-radius-min)
    );
  }
}

@media (min-width: 544px) {
  .link--no-post {
    .link__fields {
      padding-right: 0;
      max-width: auto;
      max-width: unset;
    }
  }

  .link__fields {
    padding-right: 0.925rem;
    max-width: calc(100% - 4.75rem);
  }

  .link__top {
    display: flex;
    flex-direction: row;
  }

  .link__btm {
    height: auto;
    min-height: auto;
    min-height: unset;
    position: static;
    padding-right: 0;

    &::v-deep {
      .v-flex-spacer {
        display: block;
      }
    }
  }

  .link__ul {
    font-size: 0.85rem;
    color: var(--g-color-gray-400);
  }
}
</style>
