<template>
  <LinkExpander
    :id="`default-${linkId}`"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template slot="header">
      Feed Options
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <IconButton
            href="https://support.campsite.bio/en/articles/6846362-adding-a-feed"
            target="_blank"
            aria-label="Feed setup help"
            size="1.25rem"
            v-bind="attrs"
            v-on="on"
          >
            <QuestionCircleOIcon />
          </IconButton>
        </template>
        <span>Get some help setting up your feed link</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="form">
      <ValidationProvider
        v-slot="{ errors, ariaMsg, ariaInput }"
        :rules="{
          required: true,
          min_value: 1,
          max_value: feedType.limit,
        }"
        name="limit"
        slim
      >
        <FormGroup>
          <Label for="limit">Links to show</Label>
          <Input
            id="limit"
            v-model.number="limitLocal"
            type="number"
            pattern="*[0-9]"
            min="1"
            :max="feedType.limit"
            :has-error="errors.length > 0"
            style="max-width: 100px"
            v-bind="ariaInput"
          />
          <FormGroupHelp v-if="errors.length === 0"
            >We'll show up to this many links if they're
            available</FormGroupHelp
          >
          <FormGroupHelp v-else color="error" v-bind="ariaMsg">
            {{ errors[0] }}
          </FormGroupHelp>
        </FormGroup>
      </ValidationProvider>

      <FormGroup v-if="feedType.hasImages">
        <Toggle v-model="showImagesLocal">Show images</Toggle>
        <FormGroupHelp>We'll show images if they're available</FormGroupHelp>
      </FormGroup>

      <FormGroup v-if="embedOptions">
        <Label component="span" block gutter-bottom>Embed Options</Label>
        <EmbedOptions
          v-model="embedTypeLocal"
          :embed-type="embedOptions.id"
          :source-name="embedOptions.name"
          :embed-options="embedOptions.embedTypes"
        />
      </FormGroup>

      <FormGroup>
        <Label :for="`layout0${id}`" block gutter-bottom>Layout</Label>
        <BoxInputGroup>
          <BoxInput
            v-model="layoutLocal"
            is-checkered
            is-label-boxed
            is-large
            :name="`layout${id}`"
            :input-id="`layout0${id}`"
            input-value="list"
            label="List"
            label-position="top-right"
          >
            <ListLinksIcon />
          </BoxInput>
          <BoxInput
            v-model="layoutLocal"
            is-checkered
            is-label-boxed
            is-large
            :name="`layout${id}`"
            :input-id="`layout1${id}`"
            input-value="stacked"
            label="Stacked"
            label-position="top-right"
          >
            <StackedLinksIcon />
          </BoxInput>
        </BoxInputGroup>
      </FormGroup>

      <FormGroup v-if="showStackedColorToggle">
        <Toggle v-model="disableStackedColorLocal"
          >Disable button color override</Toggle
        >
        <FormGroupHelp
          >Your button has an opacity and we've overridden the styles to make
          sure they work with the stacked layout</FormGroupHelp
        >
      </FormGroup>
    </ValidationObserver>
  </LinkExpander>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  IconButton,
  QuestionCircleOIcon,
  FormGroup,
  Label,
  FormGroupHelp,
  Input,
  Toggle,
  useTheme,
} from '@campsite-bio/component-lib';
import tinycolor from 'tinycolor2';
import uniqueId from 'lodash/uniqueId';

import LinkExpander from '../expand/link-expander.vue';
import { computed, ref, toRefs, watch } from 'vue';
import debounce from 'lodash/debounce';
import EmbedOptions from '../components/embed-options.vue';
import { BoxInputGroup, BoxInput } from '../../box-input';
import { ListLinksIcon, StackedLinksIcon } from '../../../icons';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    LinkExpander,
    IconButton,
    QuestionCircleOIcon,
    FormGroup,
    Label,
    FormGroupHelp,
    Input,
    Toggle,
    EmbedOptions,
    BoxInputGroup,
    BoxInput,
    ListLinksIcon,
    StackedLinksIcon,
  },

  props: {
    isOpen: Boolean,
    linkId: {
      type: String,
      required: true,
    },
    feedOptions: {
      type: Object,
      required: true,
    },
    feedType: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { feedOptions, feedType } = toRefs(props);
    const form = ref(null);
    const id = ref(uniqueId('feed'));
    const theme = useTheme();

    const limitLocal = ref(0);
    const showImagesLocal = ref(false);
    const embedTypeLocal = ref(null);
    const layoutLocal = ref(null);
    const disableStackedColorLocal = ref(false);

    watch(
      feedOptions,
      ({ limit, show_images, embed_type, layout, disable_stacked_color }) => {
        limitLocal.value = limit;
        showImagesLocal.value = show_images;
        embedTypeLocal.value = embed_type;
        layoutLocal.value = layout;
        disableStackedColorLocal.value = disable_stacked_color;
      },
      { immediate: true },
    );

    async function save() {
      if (!form.value) return;

      const success = await form.value.validate();
      if (!success) return;

      emit('save', {
        limit: limitLocal.value,
        show_images: showImagesLocal.value,
        embed_type: embedTypeLocal.value,
        layout: layoutLocal.value,
        disable_stacked_color: disableStackedColorLocal.value,
      });
    }

    watch(
      [
        limitLocal,
        showImagesLocal,
        embedTypeLocal,
        layoutLocal,
        disableStackedColorLocal,
      ],
      debounce(save, 1000),
    );

    const embedOptions = computed(() => {
      return feedType.value.embedOptions;
    });

    const showStackedColorToggle = computed(() => {
      if (layoutLocal.value !== 'stacked') return false;

      const bgHasAlpha =
        tinycolor(theme.value.profile.btnBgColor).getAlpha() < 0.95;
      if (!bgHasAlpha) return false;

      return true;
    });

    return {
      id,
      form,
      limitLocal,
      showImagesLocal,
      embedTypeLocal,
      embedOptions,
      layoutLocal,
      disableStackedColorLocal,
      showStackedColorToggle,
    };
  },
};
</script>

<style lang="scss" scoped></style>
