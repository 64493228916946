<template>
  <div class="app-container trees-bg">
    <header class="header-only-logo">
      <Logo
        class="logo"
        color="white"
        component="a"
        href="https://campsite.bio"
        size="30"
      />
    </header>
    <main>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </main>

    <CircularLoader v-if="isLoading" size="2.5rem" class="loader" />
  </div>
</template>

<script>
import { CircularLoader, Logo } from '@campsite-bio/component-lib';

export default {
  components: {
    CircularLoader,
    Logo,
  },

  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  width: 100%;
}

main {
  padding-bottom: 1.25rem;
}

.header-only-logo {
  padding-bottom: 1.25rem;
  padding-top: 1.875rem;
  text-align: center;

  @media (min-width: 544px) {
    padding-bottom: 1.875rem;
    padding-top: 2.25rem;
  }
}

.loader {
  bottom: 25px;
  left: 25px;
  position: fixed;
  z-index: 99999;
}
</style>
