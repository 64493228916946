import { computed, unref } from 'vue';

import { getPlanData } from '@/config/plans';

/**
 * Get plan data by price id.
 * @param {String} price_id
 * @returns Information about the plan
 */
export function usePlanData(price_id) {
  const planData = computed(() => {
    return getPlanData(unref(price_id)) || {};
  });

  return planData;
}
