import { getMimeType } from '@campsite-bio/component-lib';

import { localAxios } from '@/apis';

export async function getSignedUrl(path, fileType) {
  const { data } = await localAxios.post('/api/media/presigned-url', {
    path,
    content_type: fileType,
  });

  return data.url;
}

export async function uploadWithUrl(url, path) {
  const { data } = await localAxios.post('/api/utils/upload-from-url', {
    path,
    url,
  });

  return data;
}

export async function getMagicImages({ url, cancelToken }) {
  const { data } = await localAxios.get('/api/utils/magic-image', {
    params: { url },
    cancelToken,
  });

  return data;
}

export function getFormattedSize(media, size) {
  let url,
    folderSize = MEDIA_SIZES[size],
    path = `media/${folderSize}x${folderSize}/${media.filename}`,
    trgWidth = folderSize,
    ratio = media.height / media.width;

  if (trgWidth > media.width) trgWidth = media.width;
  url = media.url.replace(media.path, path);

  return {
    path: path,
    url: url,
    width: trgWidth,
    height: Math.round(trgWidth * ratio),
  };
}

export function getProfileImagePath(filename) {
  return `media/profile-images/${filename}`;
}

export async function getImageInfoFromUrl(url) {
  const { data } = await localAxios.get('/api/assets/image/info', {
    params: {
      url,
    },
  });

  return data;
}

export async function getMediaFilename() {
  const { data } = await localAxios.get('/api/media/filename');

  return data;
}

export async function createMedia(profileId, postData) {
  const { data } = await localAxios.post(`/api/media/${profileId}`, postData);

  return data;
}

export async function updateMedia(id, profileId, postData) {
  const { data } = await localAxios.put(
    `/api/media/${profileId}/${id}`,
    postData,
  );

  return data;
}

export async function createMediaWithUrl(url, profileId, mediaData = {}) {
  // const { filename: baseFilename } = await getMediaFilename();
  const originalFileName = new URL(url).pathname.split('/').pop();
  const imageInfo = await getImageInfoFromUrl(url);
  const extension = imageInfo.type;
  const filename = originalFileName;
  const mimeType = getMimeType(extension);
  const media = await createMedia(profileId, {
    filename,
    file_type: mimeType,
    height: imageInfo.size.height,
    width: imageInfo.size.width,
    ...mediaData,
  });
  await uploadWithUrl(url, media.path);
  const finalMedia = await updateMedia(media._id.$oid, profileId, {
    completed: true,
  });

  return finalMedia;
}
