<template>
  <div class="button-style" v-bind="$attrs" :style="styles" :class="classes">
    <input
      :id="inputId"
      class="button-style__input sr-only"
      type="radio"
      :value="inputValue"
      :checked="isChecked"
      :disabled="disabled"
      :name="inputName"
      @change="handleClick"
    />
    <label class="button-style__label" :for="inputId">
      <span class="button-style__btn" :class="btnClasses">
        <img
          v-if="hasImage"
          class="button-style__btn__img"
          :src="`${config.ASSET_URL}images/button-image-size-style-example.jpg`"
          alt=""
          height="46"
          width="46"
        />
        <CheckIcon class="button-style__check" />
      </span>
      <span class="button-style__label-text">
        <slot />
        <Badge v-if="showProBadge" class="button-style__badge" content="Pro" />
      </span>
    </label>
  </div>
</template>

<script>
import {
  useGroupItem,
  CheckIcon,
  useTheme,
  Badge,
} from '@campsite-bio/component-lib';
import { computed, toRefs } from 'vue';
import uniqueId from 'lodash/uniqueId';
import tinycolor from 'tinycolor2';

import config from '../../../config';

export default {
  components: {
    CheckIcon,
    Badge,
  },

  props: {
    inputValue: {
      type: [Number, String],
      default: null,
    },

    variant: {
      type: String,
      default: null,
    },

    disabled: Boolean,
    showProBadge: Boolean,
  },

  setup(props, { emit }) {
    const { inputValue, variant, disabled } = toRefs(props);
    const theme = useTheme();

    const inputId = computed(() => uniqueId('button-style-'));

    const hasImage = computed(() => {
      if (!variant.value) return false;

      return variant.value.indexOf('image') !== -1;
    });

    const { isInGroup, isSelected, inputName, onToggle } = useGroupItem(
      inputValue,
      'style-group',
    );

    const isChecked = computed(() => {
      return isSelected.value;
    });

    function getVariantClasses(variants, className) {
      if (!variants) return {};

      return variants.split(' ').reduce((accum, variant) => {
        accum[`${className}--${variant}`] = true;
        return accum;
      }, {});
    }

    const classes = computed(() => {
      return {
        'button-style--disabled': disabled.value,
        ...getVariantClasses(variant.value, 'button-style'),
      };
    });

    const btnClasses = computed(() => {
      return {
        [`button-style__btn--${inputValue.value}`]: true,
        ...getVariantClasses(variant.value, 'button-style__btn'),
      };
    });

    const styles = computed(() => {
      const primaryColor = tinycolor(theme.value.colors.primary);

      return {
        '--c-active-color': primaryColor.setAlpha(0.65).toRgbString(),
      };
    });

    function handleClick(e) {
      if (disabled.value) return;

      emit('click', e);

      if (isInGroup) onToggle(inputValue.value);
    }

    return {
      classes,
      btnClasses,
      isChecked,
      inputId,
      inputName,
      styles,
      hasImage,
      config,
      handleClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.button-style {
  --button-height: 50px;
  text-align: center;

  &--imagelarge {
    --button-height: 60px;
  }

  &--disabled {
    opacity: 0.75;
    pointer-events: none;
  }

  &__input:checked + .button-style__label,
  &__input:focus + .button-style__label,
  &__label:hover {
    .button-style__btn {
      outline: 1px outset var(--c-active-color);
    }
  }

  &__input:checked + .button-style__label {
    .button-style__check {
      opacity: 1;
    }
  }

  &__label {
    display: block;
    position: relative;

    &:before {
      content: '';
      border: 0.125rem solid transparent;
      border-radius: var(--g-border-radius-standard);
      border-radius: min(
        var(--g-border-radius-standard),
        var(--g-border-radius-min)
      );
      position: absolute;
      left: -0.25rem;
      top: -0.25rem;
      height: calc(var(--button-height) + 0.5rem);
      width: calc(100% + 0.5rem);
      transition: var(--g-transition-speed);
    }
  }

  &__check {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    z-index: 1;
  }

  &__btn {
    background: var(--g-color-gray-300);
    border: 2px solid var(--g-color-gray-700);
    font-size: 1.75rem;
    display: flex;
    height: var(--button-height);
    position: relative;

    &__img {
      height: 46px;
      width: 46px;

      .button-style--imagelarge & {
        height: 56px;
        width: 56px;
      }
    }

    &--imagecircle {
      .button-style__btn__img {
        border-radius: 50%;
        height: 42px;
        width: 42px;
        margin-top: 2px;
        margin-left: 2px;
      }
    }

    &[class*='noborder'] {
      border: none;
    }

    &[class*='thinborder'] {
      border-width: 2px;
    }

    &[class*='thickborder'] {
      border-width: 4px;
    }

    &[class*='rounded'] {
      border-radius: 8px;
    }

    &[class*='slightlyrounded'] {
      border-radius: 3px;
    }

    &[class*='circular'] {
      border-radius: 80px;
    }

    &[class*='hollow'] {
      background: transparent;
    }

    &[class*='soft-shadow'] {
      background: #fff;
      box-shadow: 0.3px 0.5px 0.6px hsl(0deg 0% 0% / 0.2),
        1.3px 2.5px 3px -1.4px hsl(0deg 0% 0% / 0.18),
        5.2px 10.4px 12.5px -2.8px hsl(0deg 0% 0% / 0.17);

      &.button-style__btn--shadowlow {
        box-shadow: 0.3px 0.5px 0.7px hsl(0deg 0% 0% / 0.2),
          1.3px 2.5px 3px -1.1px hsl(0deg 0% 0% / 0.18);
      }

      &.button-style__btn--shadowhigh {
        box-shadow: 0.3px 0.5px 0.7px hsl(0deg 0% 0% / 0.19),
          2.7px 5.5px 6.9px -0.6px hsl(0deg 0% 0% / 0.19),
          6.6px 13.2px 16.6px -1.2px hsl(0deg 0% 0% / 0.19),
          14.5px 29px 36.5px -1.9px hsl(0deg 0% 0% / 0.19);
      }
    }

    &[class*='hard-shadow'] {
      background: #fff;
      box-shadow: 4px 4px 0 var(--g-color-gray-700);

      &.button-style__btn--shadowmedium {
        box-shadow: 8px 8px 0 var(--g-color-gray-700);
        margin-bottom: 0.4rem;
      }

      &.button-style__btn--shadowhigh {
        box-shadow: 12px 12px 0 var(--g-color-gray-700);
        margin-bottom: 0.65rem;
      }
    }

    &--four-corners {
      --border-width: 6px;
      border: 3px solid var(--g-color-gray-700);

      &:before,
      &:after {
        background-color: var(--g-color-gray-300);
        content: '';
        position: absolute;
      }

      &:before {
        top: 8px;
        left: calc(-1 * var(--border-width) / 2);
        height: calc(100% - 16px);
        width: calc(100% + var(--border-width));
      }

      &:after {
        left: 8px;
        top: calc(-1 * var(--border-width) / 2);
        height: calc(100% + var(--border-width));
        width: calc(100% - 16px);
      }
    }

    &--glossy {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.75),
          rgba(255, 255, 255, 0.25)
        );
      }
    }

    &--offset {
      background: transparent;
      border-color: transparent;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        height: calc(100% + 4px);
        width: calc(100% + 4px);
      }

      &:before {
        background: var(--g-color-gray-300);
        transform: translate(4px, 4px);
        height: calc(100% + 3px);
        width: calc(100% + 3px);
      }

      &:after {
        border: 2px solid var(--g-color-gray-700);
      }
    }

    &--3d {
      border: none;
      box-shadow: 0 6px 0 var(--g-color-gray-700);

      &.button-style__btn--shadowmedium {
        box-shadow: 0 10px 0 var(--g-color-gray-700);
        margin-bottom: 0.4rem;
      }

      &.button-style__btn--shadowhigh {
        box-shadow: 0 14px 0 var(--g-color-gray-700);
        margin-bottom: 0.65rem;
      }
    }
  }

  &__badge {
    margin-left: 0.35rem;
  }

  &__label-text {
    align-items: center;
    display: inline-flex;
    font-weight: 600;
    margin-top: 0.5rem;
    font-size: 0.825rem;
  }
}
</style>
