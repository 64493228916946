import { ref } from 'vue';
import { localAxios } from '../../apis';
import { intercomShutdown } from '../../utils/intercom';
import toast from '../../utils/toast';

export function useLogout() {
  const isLoggingOut = ref(false);

  async function logout() {
    isLoggingOut.value = true;
    toast.success('Logging out...');
    intercomShutdown();
    await localAxios.delete('/api/sessions/logout');
    window.location = '/login';
  }

  return {
    isLoggingOut,
    logout,
  };
}
