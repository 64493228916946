import { computed, unref } from 'vue';
import { DateTime } from 'luxon';

import { usePlanData } from '../';

export function useSubscription({ plan: incomingPlan, customer, freeAfter }) {
  const plan = computed(() => unref(incomingPlan));

  const planData = usePlanData(plan);

  const customerData = computed(() => unref(customer));

  const subscriptionId = computed(() => {
    return customerData.value?.subscription?._id.$oid;
  });

  const subscriptionStripeId = computed(() => {
    return customerData.value?.subscription?.stripe_id;
  });

  const hasHadSubscription = computed(
    () => customerData.value?.has_had_subscription,
  );

  const isFreePlan = computed(() => {
    return plan.value === 'free' || !plan.value;
  });

  const planName = computed(() => {
    return isFreePlan.value ? 'Free' : planData.value.name;
  });

  const address = computed(() => {
    return customerData.value?.address;
  });

  const planInterval = computed(() => {
    if (!customerData.value?.subscription) return '-';

    return `${customerData.value.subscription?.items[0].plan.interval}ly`;
  });

  const isAnalyticsAddonIncluded = computed(() => {
    return planData.value?.addons?.includes('analytics');
  });

  // Next payment details
  const nextInvoice = computed(() => {
    return customerData.value?.next_invoice;
  });

  const nextPaymentAttemptDate = computed(() => {
    if (!nextInvoice.value?.next_payment_attempt) return null;

    return DateTime.fromSeconds(
      nextInvoice.value.next_payment_attempt,
    ).toLocaleString(DateTime.DATE_MED);
  });

  // Subscription data
  const subscription = computed(() => {
    return customerData.value?.subscription;
  });

  const isTrialing = computed(() => {
    return subscription.value?.status === 'trialing';
  });

  const subscriptionStatus = computed(() => {
    return subscription.value?.status;
  });

  const isPastDue = computed(() => {
    return subscription.value?.status === 'past_due';
  });

  const trialEndDate = computed(() => {
    return DateTime.fromISO(subscription.value?.trial_end).toFormat('MMM d');
  });

  // Downgrade data
  const hasFreeAfter = computed(() => {
    return !!freeAfter.value;
  });

  const freeAfterDate = computed(() => {
    return DateTime.fromISO(freeAfter.value).toLocaleString(DateTime.DATE_MED);
  });

  const subscriptionCardProps = computed(() => {
    return {
      planName: planName.value,
      planInterval: planInterval.value,
      isFreePlan: isFreePlan.value,
      isTrialing: isTrialing.value,
      trialEndDate: trialEndDate.value,
      isPastDue: isPastDue.value,
      isAnalyticsAddonIncluded: isAnalyticsAddonIncluded.value,
      nextPaymentAttemptDate: nextPaymentAttemptDate.value,
      hasFreeAfter: hasFreeAfter.value,
      freeAfterDate: freeAfterDate.value,
      subscriptionStatus: subscriptionStatus.value,
      nextInvoice: nextInvoice.value,
    };
  });

  return {
    address,
    planData,
    customerData,
    subscriptionId,
    subscriptionStripeId,
    isFreePlan,
    planName,
    planInterval,
    subscription,
    subscriptionStatus,
    isTrialing,
    trialEndDate,
    isPastDue,
    isAnalyticsAddonIncluded,
    nextInvoice,
    nextPaymentAttemptDate,
    hasFreeAfter,
    freeAfterDate,
    subscriptionCardProps,
    hasHadSubscription,
  };
}
