<template>
  <ListItem class="ig-item">
    <div class="ig-title" :title="instagramAccount.username">
      <InstagramIcon />
      {{ instagramAccount.username | truncate(15) }}
    </div>
    <div class="ig-icon">
      <ArrowIcon direction="right" />
    </div>
    <div class="ig-select">
      <label class="sr-only" :for="`${_uid}`"
        >Select a profile for IG {{ instagramAccount.username }}</label
      >
      <Select :id="`${_uid}`" v-model="selectedProfile">
        <option :value="null">- Select a profile</option>
        <option
          v-for="{ name, value } in menuItems"
          :key="value"
          :value="value"
        >
          {{ name }}
        </option>
      </Select>
    </div>
  </ListItem>
</template>

<script>
import {
  ListItem,
  ArrowIcon,
  InstagramIcon,
  Select,
} from '@campsite-bio/component-lib';
import { computed, ref, watch } from 'vue';

export default {
  components: {
    ListItem,
    ArrowIcon,
    InstagramIcon,
    Select,
  },

  props: {
    account: {
      type: Object,
      required: true,
    },

    menuItems: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const selectedProfile = ref(null);

    watch(
      () => props.account,
      ({ account }) => {
        selectedProfile.value =
          account?.campsite_id?.$oid || account?.campsite_id;
      },
      { immediate: true, deep: true },
    );

    watch(selectedProfile, (value) => {
      emit('update:account', value);
    });

    const instagramAccount = computed(() => {
      return props.account.instagramAccount;
    });

    return { selectedProfile, instagramAccount };
  },
};
</script>

<style lang="scss" scoped>
.ig-item.ig-item {
  align-items: center;
  padding-left: 0;
  padding-right: 0;
}

.ig-title,
.ig-select {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  flex: 1;
  margin-right: 0.5rem;
  flex-basis: 45%;
}

.ig-icon {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 1.25rem;
  flex-basis: 10%;
}
</style>
