<template>
  <LinkExpander
    :id="`lock-${link._id.$oid}`"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template slot="header">
      Lock this link
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <IconButton
            href="https://support.campsite.bio/en/articles/6868592-locking-your-links"
            target="_blank"
            aria-label="Lock link help"
            size="1.25rem"
            v-bind="attrs"
            v-on="on"
          >
            <QuestionCircleOIcon />
          </IconButton>
        </template>
        <span>Get some help with your link lock</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="form" slim>
      <VText
        >Visitors can only open this link by fulfilling certain criteria.</VText
      >

      <Items gutter-top>
        <CodeLock
          :id="id"
          key="code"
          :has-code="hasCode"
          :code="code"
          :code-description="codeDescription"
          :disabled="isFreePlan"
          @update:has-code="hasCode = $event"
          @update:code="code = $event"
          @update:code-description="codeDescription = $event"
        />

        <DobLock
          :id="id"
          key="dob"
          :has-dob="hasDob"
          :age="age"
          :age-description="ageDescription"
          @update:has-dob="hasDob = $event"
          @update:age="age = $event"
          @update:age-description="ageDescription = $event"
        />

        <ItemAccordion
          key="sensitive"
          :active="hasSensitiveContent"
          @update:active="hasSensitiveContent = $event"
        >
          Sensitive content

          <VText
            v-if="hasSensitiveContent"
            slot="action"
            component="span"
            color="success"
            >Active</VText
          >

          <VText slot="content"
            >Visitors must acknowledge that this link may contain content that
            is not appropriate for all audiences.</VText
          >
        </ItemAccordion>
      </Items>
    </ValidationObserver>
  </LinkExpander>
</template>

<script>
import {
  IconButton,
  QuestionCircleOIcon,
  VText,
  Items,
  ItemAccordion,
} from '@campsite-bio/component-lib';
import uniqueId from 'lodash/uniqueId';
import debounce from 'lodash/debounce';
import axios from 'axios';
import { ValidationObserver } from 'vee-validate';
import { computed, ref, watch, nextTick } from 'vue';

import LinkExpander from '../link-expander.vue';
import { useStore } from '../../../../compositions';
import toast from '../../../../utils/toast';
import CodeLock from './code-lock';
import DobLock from './dob-lock';

export default {
  components: {
    LinkExpander,
    IconButton,
    QuestionCircleOIcon,
    VText,
    ValidationObserver,
    Items,
    ItemAccordion,
    CodeLock,
    DobLock,
  },

  props: {
    isOpen: Boolean,
    link: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();
    const isDirty = ref(false);
    const ignoreChanges = ref(false);
    const id = ref(uniqueId('lock'));
    const form = ref(null);
    const cancelSource = ref(null);
    const hasCode = ref(null);
    const hasDob = ref(null);
    const hasSensitiveContent = ref(null);
    const age = ref(null);
    const ageDescription = ref(null);
    const code = ref(null);
    const codeDescription = ref(null);

    const lockData = computed(() => props.link.locked || {});

    watch(
      lockData,
      async (value) => {
        ignoreChanges.value = true;
        // set default values, otherwise the checkbox won't check properly
        hasCode.value = value.has_code || false;
        hasDob.value = value.has_dob || false;
        hasSensitiveContent.value = value.has_sensitive_content || false;
        age.value = value.age;
        ageDescription.value = value.age_description;
        code.value = value.code;
        codeDescription.value = value.code_description;
        isDirty.value = false;
        await nextTick();
        ignoreChanges.value = false;
      },
      { immediate: true },
    );

    const isFreePlan = computed(() => {
      return store.getters['profiles/isProfileOnFreePlan'];
    });

    async function save() {
      try {
        if (cancelSource.value) cancelSource.value.cancel();
        cancelSource.value = axios.CancelToken.source();

        isDirty.value = false;

        const data = await store.dispatch('links/updateLinkLock', {
          id: props.link._id.$oid,
          data: {
            has_code: hasCode.value,
            has_dob: hasDob.value,
            has_sensitive_content: hasSensitiveContent.value,
            age: age.value,
            age_description: ageDescription.value,
            code: code.value,
            code_description: codeDescription.value,
          },
          cancelToken: cancelSource.token,
        });

        if (!isDirty.value) {
          store.commit('links/updateLinkOptions', {
            id: props.link._id.$oid,
            value: data.link.locked,
            name: 'locked',
          });
        }
      } catch (e) {
        if (axios.isCancel(e) || e.name === 'AbortError') return;
        console.error(e);
        const { error_message } = e.response?.data || {};
        toast.error(
          error_message || 'Something went wrong. Please try again later.',
        );
      }
    }

    const saveDebounced = debounce(save, 750);

    watch(
      [
        hasDob,
        hasCode,
        hasSensitiveContent,
        age,
        ageDescription,
        code,
        codeDescription,
      ],
      () => {
        if (!ignoreChanges.value) {
          isDirty.value = true;
          saveDebounced();
        }
      },
    );

    return {
      id,
      form,
      isFreePlan,
      hasCode,
      hasDob,
      hasSensitiveContent,
      age,
      ageDescription,
      code,
      codeDescription,
    };
  },
};
</script>

<style lang="scss" scoped></style>
