<template>
  <div class="title" :class="{ [`title--${align}`]: align }">
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <button
          class="toggle"
          :class="{ 'toggle--open': isOpen }"
          type="button"
          aria-label="Collapse menu"
          v-bind="attrs"
          @click="isOpen = !isOpen"
          v-on="on"
        >
          <SvgIcon viewBox="0 0 24 24" :direction="isOpen ? 'down' : 'up'">
            <path
              d="M21,15.61L19.59,17L14.58,12L19.59,7L21,8.39L17.44,12L21,15.61M3,6H16V8H3V6M3,13V11H13V13H3M3,18V16H16V18H3Z"
            />
          </SvgIcon>
        </button>
      </template>
      <span>Collapse menu</span>
    </v-tooltip>
    <VText component="h1" variant="h2">
      <slot />
    </VText>

    <div class="title__actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import { VText, SvgIcon } from '@campsite-bio/component-lib';
import { computed } from 'vue';

import { useStore } from '../../compositions';

export default {
  components: {
    VText,
    SvgIcon,
  },

  props: {
    align: {
      type: String,
      default: null,
    },
  },

  setup() {
    const store = useStore();

    const isOpen = computed({
      get: () => store.getters['getField']('isSubmenuOpen'),
      set: (value) =>
        store.commit('updateField', { path: 'isSubmenuOpen', value }),
    });

    return { isOpen };
  },
};
</script>

<style lang="scss" scoped>
.title {
  align-items: center;
  display: flex;
  gap: 1rem;

  &--center {
    justify-content: center;
  }

  &::v-deep .v-text {
    flex: 1 1 auto;
  }

  &__actions {
    align-self: center;
    flex: 0 1 auto;
    margin: 0.25rem 0 0.25rem 0.25rem;
  }
}

.toggle {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--g-color-on-bg);
  display: none;
  padding: 0;
  font-size: 1.75rem;
  transition: all var(--g-transition-speed);

  @media (min-width: 992px) {
    display: flex;
  }

  &:hover,
  &:focus {
    color: var(--g-color-primary);
  }
}
</style>
