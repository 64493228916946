<template>
  <div
    class="analytics__title"
    :class="{ 'analytics__title--hide-toolbar': hideToolbar }"
  >
    <div class="analytics__title__top">
      <div>
        <component :is="titleComponent" v-bind="titleProps">
          <slot />
          <span v-if="includeProfileSelectorList" class="print-hide">
            for <ProfileSelectorBtn v-model="selectedProfileValue" />
          </span>
          <span v-if="printTitle" class="print-only">{{ printTitle }}</span>
        </component>
        <div v-if="$slots.subtitle" class="analytics__subtitle">
          <slot name="subtitle" />
        </div>
        <div v-if="dates" class="analytics__dates">
          {{ currentStartEndDate }}
        </div>
      </div>

      <FlexSpacer />

      <slot v-if="!isLargeScreen" name="actions" />
    </div>
    <div v-if="!hideToolbar" class="analytics__toolbar">
      <slot name="dates">
        <DateBar
          v-if="dates"
          :dates="selectedDateFilter"
          :available-periods="availablePeriods"
          :min-date="minCustomSelectionDate"
          :disabled="!hasProfile && disableOnNoProfile"
          :start-date="dates[0]"
          :end-date="dates[1]"
          :compare-start-date="dates[2]"
          :compare-end-date="dates[3]"
          :hide-compare="!isLargeScreen"
          :can-choose-custom="canChooseCustom"
          @update:dates="selectedDateFilter = $event"
        />
      </slot>

      <FlexSpacer />

      <template v-if="isLargeScreen">
        <slot name="actions" />

        <IconButton
          v-if="!hidePrintButton"
          aria-label="Print this page"
          @click="handlePrint"
        >
          <PrintIcon />
        </IconButton>
      </template>
    </div>
  </div>
</template>

<script>
import { computed, toRefs } from 'vue';
import {
  VText,
  FlexSpacer,
  IconButton,
  PrintIcon,
} from '@campsite-bio/component-lib';
import { DateTime } from 'luxon';
import { useMediaQuery } from '@vueuse/core';

import {
  useCurrentUserProfileEntitlements,
  useModel,
  useProfile,
} from '@/compositions';
import { useSelectedDateFilter } from '../compositions';
import { DateBar, SimpleDateSelector } from './dates';
import ProfileSelectorBtn from './profile-selector-btn';
import { TitleWithSubmenuToggle } from '../../layout';

export default {
  components: {
    VText,
    FlexSpacer,
    DateBar,
    IconButton,
    PrintIcon,
    SimpleDateSelector,
    ProfileSelectorBtn,
    TitleWithSubmenuToggle,
  },

  props: {
    dates: {
      type: Array,
      default: null,
    },

    selectedDate: {
      type: [String, Array],
      default: null,
    },

    availablePeriods: {
      type: Array,
      default: null,
    },

    canChooseCustom: {
      type: Boolean,
      default: true,
    },

    hideToolbar: Boolean,
    hidePrintButton: Boolean,
    useCollapsableTitle: Boolean,
    canDownload: Boolean,

    disableOnNoProfile: Boolean,
    includeProfileSelectorList: Boolean,

    profile: {
      type: Object,
      default: null,
    },
    selectedProfile: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { profile, selectedDate, selectedProfile } = toRefs(props);
    let selectedDateFilter;
    if (props.selectedDate) {
      selectedDateFilter = useModel(selectedDate, (val) =>
        emit('update:selectedDate', val),
      );
    } else {
      selectedDateFilter = useSelectedDateFilter();
    }
    let selectedProfileValue;
    if (props.includeProfileSelectorList) {
      selectedProfileValue = useModel(selectedProfile, (val) =>
        emit('update:selectedProfile', val),
      );
    }
    const hasProfile = computed(
      () => props.profile && Object.keys(props.profile).length > 0,
    );

    const { hasAnalyticsAddon } = useCurrentUserProfileEntitlements(
      profile,
      computed(() => profile.value === null),
    );
    const isLargeScreen = useMediaQuery('(min-width: 544px)');
    const { isProfileOnFreePlan } = useProfile(profile);
    const minCustomSelectionDate = computed(() => {
      if (!props.profile) return null;
      const createdAt = DateTime.fromISO(props.profile.created_at);
      // free get 14 days
      if (isProfileOnFreePlan.value)
        return DateTime.max(
          createdAt,
          DateTime.local().minus({ days: 14 }),
        ).toISODate();
      // Pro get 60 days
      if (!isProfileOnFreePlan.value && !hasAnalyticsAddon.value)
        return DateTime.max(
          createdAt,
          DateTime.local().minus({ days: 60 }),
        ).toISODate();
      // Analytics addon gets unlimited
      return props.profile.created_at;
    });

    const printTitle = computed(() => {
      if (!hasProfile.value) return null;
      return ` for ${props.profile.username}`;
    });

    const currentStartEndDate = computed(() => {
      if (!props.dates) return null;

      const dateFormat = 'MMMM d, yyyy';
      return `${DateTime.fromISO(props.dates[0]).toFormat(
        dateFormat,
      )} - ${DateTime.fromISO(props.dates[1]).toFormat(dateFormat)}`;
    });

    const titleComponent = computed(() => {
      if (props.useCollapsableTitle) {
        return 'TitleWithSubmenuToggle';
      }
      return 'VText';
    });

    const titleProps = computed(() => {
      if (props.useCollapsableTitle) {
        return {};
      }

      return {
        component: 'h1',
        variant: 'h2',
      };
    });

    async function handlePrint() {
      window.print();
    }

    return {
      isLargeScreen,
      currentStartEndDate,
      selectedDateFilter,
      hasProfile,
      hasAnalyticsAddon,
      minCustomSelectionDate,
      handlePrint,
      printTitle,
      selectedProfileValue,
      titleComponent,
      titleProps,
    };
  },
};
</script>

<style lang="scss" scoped>
.analytics__title {
  border-bottom: 1px solid var(--g-color-gray-200);
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  padding-bottom: 0.25rem;

  &--hide-toolbar {
    padding-bottom: 0.5rem;
  }

  @media print {
    border-bottom: none;
    text-align: center;
  }

  &__top {
    display: flex;
    gap: 0.5rem;

    @media print {
      flex-direction: column-reverse;
      align-items: center;

      h1 {
        font-size: 1.5rem;
      }
    }
  }

  &__logo {
    height: 40px;

    @media print {
      flex-grow: 0;
      height: 100px;
      margin-bottom: 0.5rem;
    }
  }
}

.analytics__subtitle {
  @media print {
    display: none;
  }

  > p {
    font-weight: 500;
    margin-top: 0.25rem;
  }
}

.analytics__dates {
  display: none;

  @media print {
    display: block;
  }
}

.analytics__toolbar {
  align-items: center;
  display: flex;
  gap: 0.25rem;
  margin-top: 0.5rem;

  @media print {
    display: none;
  }
}
</style>
