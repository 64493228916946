<template>
  <Button v-bind="$attrs" :loading="isAuthorizing" @click="authorize">
    <slot>Authorize</slot>
  </Button>
</template>

<script>
import { Button } from '@campsite-bio/component-lib';
import { computed } from 'vue';

import { useInstagramAuthorization, useStore } from '../compositions';

export default {
  components: {
    Button,
  },

  setup(props) {
    const store = useStore();

    const instagramAuthorization = computed(() => {
      return store.getters['profiles/instagramAuthorization'];
    });

    const authType = computed(() => {
      return instagramAuthorization.value ? 'reauthorize' : 'authorize';
    });

    const { authorize, isAuthorizing } = useInstagramAuthorization(
      {
        onCancel() {},
        onSuccess() {
          store.dispatch('profiles/getAppAuthorizations');
        },
      },
      {
        type: authType,
      },
    );

    return { authorize, isAuthorizing };
  },
};
</script>

<style scoped></style>
