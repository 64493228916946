import { computed, unref } from 'vue';
import {
  CAN_MANAGE_ANALYTICS,
  CAN_MANAGE_APPEARANCE,
  CAN_MANAGE_LINKS,
  CAN_MANAGE_SETTINGS,
  CAN_MANAGE_URLS,
} from '../config/user-access';
import store from '../store';
import { hasProfileEntitlements } from '../utils';

export function useCurrentUserProfileEntitlements(
  profile = null,
  useStore = true,
) {
  const currentProfile = computed(() => {
    return unref(useStore)
      ? store.getters['profiles/currentProfile'] || {}
      : unref(profile);
  });
  const entitlements = computed(() => {
    return currentProfile.value?.user_access?.entitlements || [];
  });
  const isOrgProfile = computed(() => {
    return !!currentProfile.value?.organization_id;
  });
  const isOrgOwner = computed(() => {
    return isOrgProfile.value && currentProfile.value?.user_access?.is_owner;
  });
  const role = computed(() => {
    return currentProfile.value?.user_access?.role;
  });
  const addons = computed(() => {
    return currentProfile.value?.addons || [];
  });

  const canManageLinks = computed(() => {
    if (isOrgProfile.value) return true;
    return hasProfileEntitlements(CAN_MANAGE_LINKS, entitlements.value);
  });
  const canManageAppearance = computed(() => {
    if (isOrgProfile.value) return true;
    return hasProfileEntitlements(CAN_MANAGE_APPEARANCE, entitlements.value);
  });
  const hasAnalyticsAddon = computed(() => {
    return addons.value.includes('analytics');
  });
  const canManageSettings = computed(() => {
    if (isOrgProfile.value) return true;
    return hasProfileEntitlements(CAN_MANAGE_SETTINGS, entitlements.value);
  });
  const canManageUrls = computed(() => {
    if (isOrgProfile.value) return true;
    return hasProfileEntitlements(CAN_MANAGE_URLS, entitlements.value);
  });
  const canManageAnalytics = computed(() => {
    if (isOrgProfile.value) return true;
    return hasProfileEntitlements(CAN_MANAGE_ANALYTICS, entitlements.value);
  });
  const isProfileOwner = computed(() => {
    if (isOrgProfile.value && isOrgOwner.value) return true;
    return entitlements.value === 'owner';
  });
  const isProfileOwnerOrAdmin = computed(() => {
    if (isOrgProfile.value && isOrgOwner.value) return true;
    return entitlements.value === 'owner' || role.value === 'admin';
  });

  return {
    canManageLinks,
    canManageAppearance,
    canManageSettings,
    canManageUrls,
    canManageAnalytics,
    hasAnalyticsAddon,
    isProfileOwner,
    isOrgProfile,
    isOrgOwner,
    isProfileOwnerOrAdmin,
  };
}
