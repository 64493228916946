<template>
  <ChartCard
    title="Link Types"
    :style="styles"
    :is-loading="isLoading"
    :timestamp="allData ? allData.timestamp : null"
    :padding-bottom="false"
  >
    <template v-if="hasResults || isLoading">
      <ChartWrapper
        width="100%"
        height="190"
        type="doughnut"
        :options="chartOptions"
        :data="series"
      />

      <SimpleTable gutter-top fixed-header height="243">
        <thead>
          <tr>
            <th><span class="sr-only">Type</span></th>
            <th class="text-right">Clicks</th>
            <th class="text-right">Unique</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="isLoading && !hasResults">
            <tr v-for="i in 5" :key="i">
              <td><Skeleton width="120px" /></td>
              <td class="text-right"><Skeleton width="30px" /></td>
              <td class="text-right"><Skeleton width="30px" /></td>
            </tr>
          </template>
          <tr
            v-for="(
              [key, name, pageViews, uniquePageViews], index
            ) in deviceData"
            :key="key"
          >
            <td class="td-name">
              <span
                ><span :class="`color-${index}`" /> {{ startCase(name) }}</span
              >
            </td>
            <td class="text-right">{{ pageViews | numberFormatter(1) }}</td>
            <td class="text-right">
              {{ uniquePageViews | numberFormatter(1) }}
            </td>
          </tr>
        </tbody>
      </SimpleTable>
    </template>
    <NoResults v-else />
  </ChartCard>
</template>

<script>
import { computed } from 'vue';
import { Skeleton, SimpleTable } from '@campsite-bio/component-lib';
import startCase from 'lodash/startCase';

import ChartCard from './chart-card';
import NoResults from './no-results';
import { useApexOptions, useDataColors } from '../compositions';
import { ChartWrapper } from '../';

export default {
  components: {
    ChartCard,
    Skeleton,
    SimpleTable,
    ChartWrapper,
    NoResults,
  },

  props: {
    allData: {
      type: Object,
      default: null,
    },

    data: {
      type: Array,
      default: null,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const dataColors = useDataColors();

    const deviceData = computed(() => {
      if (!props.data) return [];

      const stats = props.data.reduce((acc, { _id, count, unique }) => {
        acc.push([_id, _id, count, unique]);
        return acc;
      }, []);

      return stats.sort((a, b) => b[2] - a[2]).slice(0, 6);
    });

    const series = computed(() => {
      return {
        labels: deviceData.value.map(([name]) => name),
        datasets: [
          {
            data: deviceData.value.map(([, , views]) => views),
          },
        ],
      };
    });

    const chartOptions = useApexOptions({
      scales: {
        x: {
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
        y: {
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      },
      datalabels: { display: true },
    });

    const styles = computed(() => {
      return dataColors.value.reduce((accum, color, index) => {
        accum[`--c-chart-${index}`] = color;
        return accum;
      }, {});
    });

    const hasResults = computed(() => deviceData.value.length > 0);

    return {
      deviceData,
      startCase,
      chartOptions,
      series,
      styles,
      hasResults,
    };
  },
};
</script>

<style lang="scss" scoped>
.color-0,
.color-1,
.color-2,
.color-3,
.color-4,
.color-5,
.color-6 {
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.3em;
  height: 1em;
  width: 1em;
}

.color-0 {
  background-color: var(--c-chart-0);
}

.color-1 {
  background-color: var(--c-chart-1);
}

.color-2 {
  background-color: var(--c-chart-2);
}

.color-3 {
  background-color: var(--c-chart-3);
}

.color-4 {
  background-color: var(--c-chart-4);
}

.color-5 {
  background-color: var(--c-chart-5);
}

.color-6 {
  background-color: var(--c-chart-6);
}
</style>
