<template>
  <ListItem
    component="button"
    type="button"
    @click="$emit('click', 'delimiter')"
  >
    <ListItemAction>
      <MinusIcon size="1.25rem" />
    </ListItemAction>
    <ListItemContent>
      <ListItemTitle>Divider</ListItemTitle>
      <ListItemSubtitle wrap
        >Split up your links and give them some space.</ListItemSubtitle
      >
    </ListItemContent>
  </ListItem>
</template>

<script>
import {
  ListItem,
  ListItemAction,
  ListItemContent,
  ListItemSubtitle,
  ListItemTitle,
  MinusIcon,
} from '@campsite-bio/component-lib';

export default {
  components: {
    ListItem,
    ListItemAction,
    ListItemContent,
    ListItemSubtitle,
    ListItemTitle,
    MinusIcon,
  },
};
</script>

<style lang="scss" scoped></style>
