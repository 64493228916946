import { useStore } from '../use-store';
import { useAuthorizationDialog } from '../use-authorization-dialog';

export function useGoogleAuthorization(
  authDialogOptions,
  openParams = {},
  provider = 'google',
) {
  const store = useStore();

  const { open, isAuthorizing } = useAuthorizationDialog(authDialogOptions);

  function authorize() {
    open({
      provider,
      providerTitle: 'Google',
      params: {
        profile_id: store.getters['profiles/currentProfileId'],
        ...openParams,
      },
    });
  }

  return {
    authorize,
    isAuthorizing,
  };
}
