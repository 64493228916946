<template>
  <Dialog v-model="isOpen" max-width="450" title="Add a goal">
    <template #activator="data">
      <slot name="activator" v-bind="data" />
    </template>

    <ValidationObserver v-slot="{ handleSubmit }" slim>
      <form @submit.prevent="handleSubmit(save)">
        <CardText>
          <ValidationProvider
            v-slot="{ errors, ariaInput, ariaMsg }"
            name="account"
            rules="required"
            slim
          >
            <FormGroup>
              <Label for="goal-account">Select an account/profile</Label>
              <Select id="goal-account" v-model="account" v-bind="ariaInput">
                <optgroup label="Instagram">
                  <option
                    v-for="a in formattedInstagramAccounts"
                    :key="a.id"
                    :value="a.id"
                  >
                    {{ a.name }}
                  </option>
                </optgroup>
                <optgroup label="Profiles">
                  <option
                    v-for="a in formattedProfiles"
                    :key="a.id"
                    :value="a.id"
                  >
                    {{ a.name }}
                  </option>
                </optgroup>
              </Select>
              <FormGroupHelp color="error" v-bind="ariaMsg" gutter-bottom>{{
                errors[0]
              }}</FormGroupHelp>
              <FormGroupHelp>
                Which account or profile would you like to track a goal for?
              </FormGroupHelp>
            </FormGroup>
          </ValidationProvider>

          <template v-if="account">
            <ValidationProvider
              v-slot="{ errors, ariaInput, ariaMsg }"
              name="metric"
              rules="required"
              slim
            >
              <FormGroup>
                <Label for="goal-metric">Select a metric</Label>
                <Select id="goal-metric" v-model="metric" v-bind="ariaInput">
                  <option v-for="m in metrics" :key="m" :value="m">
                    {{ startCase(m) }}
                  </option>
                </Select>
                <FormGroupHelp color="error" v-bind="ariaMsg" gutter-bottom>{{
                  errors[0]
                }}</FormGroupHelp>
              </FormGroup>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, ariaInput, ariaMsg }"
              name="frequency"
              rules="required"
              slim
            >
              <FormGroup>
                <Label for="goal-frequency">Select a frequency</Label>
                <Select
                  id="goal-frequency"
                  v-model="frequency"
                  v-bind="ariaInput"
                >
                  <option value="day">day</option>
                  <option value="week">week</option>
                  <option value="month">month</option>
                  <option value="year">year</option>
                </Select>
                <FormGroupHelp color="error" v-bind="ariaMsg" gutter-bottom>{{
                  errors[0]
                }}</FormGroupHelp>
              </FormGroup>
            </ValidationProvider>

            <ValidationProvider
              v-if="metric && frequency"
              v-slot="{ errors, ariaInput, ariaMsg }"
              name="tally"
              rules="required"
              slim
            >
              <FormGroup>
                <Label for="goal-tally"
                  >How many {{ startCase(metric) }} per {{ frequency }}</Label
                >
                <Input
                  id="goal-tally"
                  v-model.number="tally"
                  v-bind="ariaInput"
                  type="number"
                  min="1"
                  step="1"
                  placeholder="5"
                />
                <FormGroupHelp color="error" v-bind="ariaMsg" gutter-bottom>{{
                  errors[0]
                }}</FormGroupHelp>
              </FormGroup>
            </ValidationProvider>
          </template>
        </CardText>

        <CardActions>
          <Button
            variant="flat"
            color="error"
            :disabled="isSaving"
            @click="isOpen = false"
            >Cancel</Button
          >
          <Button variant="flat" type="submit" :loading="isSaving"
            >Create Goal</Button
          >
        </CardActions>
      </form>
    </ValidationObserver>
  </Dialog>
</template>

<script>
import {
  CardText,
  CardActions,
  Button,
  FormGroup,
  FormGroupHelp,
  Label,
  Select,
  Input,
} from '@campsite-bio/component-lib';
import { computed, watch, ref, toRefs } from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { Dialog } from '../../';
import { useModel } from '../../../../compositions';
import { localAxios } from '../../../../apis';
import startCase from 'lodash/startCase';
import toast from '../../../../utils/toast';
import bus from '../../../../bus';

export default {
  components: {
    Dialog,
    CardText,
    CardActions,
    Button,
    FormGroup,
    FormGroupHelp,
    Label,
    Select,
    Input,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    value: Boolean,
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const isOpen = useModel(value, (val) => emit('input', val));
    const isSaving = ref(false);
    const allProfiles = ref([]);

    const account = ref(null);
    const metric = ref(null);
    const frequency = ref(null);
    const tally = ref(null);

    const selectedAccount = computed(() => {
      const allAccounts = [
        ...formattedInstagramAccounts.value,
        ...formattedProfiles.value,
      ];
      return allAccounts.find((a) => a.id === account.value);
    });

    const formattedProfiles = computed(() => {
      return allProfiles.value.reduce((accum, profile) => {
        accum.push({
          type: 'profile',
          id: profile._id.$oid,
          name: profile.username,
        });

        return accum;
      }, []);
    });

    const formattedInstagramAccounts = computed(() => {
      const appAuthorizations = allProfiles.value
        .filter((profile) => profile.app_authorizations.length)
        .reduce((accum, profile) => {
          accum = [...accum, ...profile.app_authorizations];

          return accum;
        }, []);

      return appAuthorizations
        .filter((auth) => auth.accounts?.length)
        .reduce((accum, auth) => {
          auth.accounts.forEach((account) => {
            accum.push({
              type: 'instagram',
              id: auth._id.$oid,
              account_id: account._id.$oid,
              name: account.name,
            });
          });

          return accum;
        }, []);
    });

    const metrics = computed(() => {
      if (selectedAccount.value.type === 'profile') {
        return [
          'clicks',
          'form-submits',
          'opt-ins',
          'reach',
          'transactions',
          'transaction-amount',
          'views',
        ];
      }

      return ['followers_gained', 'media_impressions', 'posts', 'reach'];
    });

    async function load() {
      try {
        const { data } = await localAxios.get('/api/goals/account-options');

        allProfiles.value = data;
      } catch (e) {
        console.error(e);
        toast.error(
          'There was an error trying to load your accounts. Please try again soon.',
        );
      }
    }

    watch(
      isOpen,
      (val) => {
        if (val) {
          load();
        }
      },
      { immediate: true },
    );

    function reset() {
      account.value = null;
      metric.value = null;
      frequency.value = null;
      tally.value = null;
    }

    async function save() {
      isSaving.value = true;

      try {
        let accountData = {};
        if (selectedAccount.value.type === 'profile') {
          accountData = {
            campsite_id: selectedAccount.value.id,
            channel: 'bio',
          };
        } else {
          accountData = {
            app_authorization_id: selectedAccount.value.id,
            account_id: selectedAccount.value.account_id,
            channel: 'instagram',
          };
        }

        await localAxios.post('/api/goals', {
          ...accountData,
          metric: metric.value,
          frequency: frequency.value,
          tally: tally.value,
        });

        isOpen.value = false;
        toast.success('Goal created successfully');

        bus.$emit('goal:created');
        reset();
      } catch (e) {
        console.error(e);
        toast.error(
          'There was an error saving your new goal. Please try again soon.',
        );
      }

      isSaving.value = false;
    }

    return {
      isOpen,
      account,
      metric,
      frequency,
      metrics,
      tally,
      save,
      formattedProfiles,
      formattedInstagramAccounts,
      startCase,
      isSaving,
    };
  },
};
</script>

<style lang="scss" scoped></style>
