<template>
  <List class="link-list">
    <component
      :is="link.component"
      v-for="link in linkComponents"
      :key="link.type || link.id"
      :disabled="link.disabled"
      @click="$emit('click', $event)"
    />
  </List>
</template>

<script>
import { List } from '@campsite-bio/component-lib';
import { computed } from 'vue';

import { useCurrentUser, useStore } from '../../../compositions';
import CarouselItem from './carousel-item';
import ContactDetailsItem from './contact-details-item';
import DividerItem from './divider-item';
import EmbedItem from './embed-item';
import FeedItem from './feed-item';
import FormItem from './form-item';
import ImageGridItem from './image-grid-item';
import LinkItem from './link-item';
import OptInItem from './opt-in-item';
import RequestItem from './request-item';
import SupportItem from './support-item';
import TextItem from './text-item';
import TitleItem from './title-item';
import GroupItem from './group-item';
import ShopItem from './shop-item';

export default {
  components: {
    List,
    TextItem,
    TitleItem,
    FormItem,
    LinkItem,
    OptInItem,
    RequestItem,
    SupportItem,
    ImageGridItem,
    FeedItem,
    EmbedItem,
    DividerItem,
    CarouselItem,
    ContactDetailsItem,
    GroupItem,
    ShopItem,
  },

  props: {
    showLink: Boolean,
    exclude: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const store = useStore();
    const { isAdmin } = useCurrentUser();

    const isFreePlan = computed(
      () => store.getters['profiles/isProfileOnFreePlan'],
    );

    const linkComponents = computed(() => {
      const defaultComponents = [
        {
          component: CarouselItem,
          type: 'carousel',
        },
        {
          component: ContactDetailsItem,
          type: 'contact-details',
        },
        {
          component: DividerItem,
          type: 'divider',
        },
        {
          component: EmbedItem,
          type: 'embed',
        },
        {
          component: FeedItem,
          type: 'feed',
          disabled: isFreePlan.value,
        },
        {
          component: FormItem,
          type: 'form',
          disabled: isFreePlan.value,
        },
        {
          component: GroupItem,
          type: 'group',
          disabled: isFreePlan.value,
        },
        {
          component: ImageGridItem,
          type: 'image-grid',
        },
        {
          component: OptInItem,
          type: 'opt-in',
          disabled: isFreePlan.value,
        },
        {
          component: RequestItem,
          type: 'request',
        },
        // {
        //   component: ShopItem,
        //   type: 'shop',
        // },
        {
          component: SupportItem,
          type: 'support',
        },
        {
          component: TextItem,
          type: 'text',
        },
        {
          component: TitleItem,
          type: 'title',
        },
      ];

      if (isAdmin.value) {
        defaultComponents.splice(10, 0, {
          component: ShopItem,
          type: 'shop',
        });
      }

      if (props.showLink) {
        defaultComponents.unshift({
          component: LinkItem,
          type: null,
          id: 'link',
        });
      }

      return defaultComponents.filter(
        (component) => !props.exclude.includes(component.type),
      );
    });

    return {
      linkComponents,
    };
  },
};
</script>

<style lang="scss" scoped>
.link-list {
  &::v-deep {
    .v-list-item__title {
      font-weight: 600;
    }
  }
}
</style>
