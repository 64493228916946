<template>
  <ChartCard ref="target" :title="title">
    <ChartWrapper
      width="100%"
      height="430"
      type="bar"
      :options="chartOptions"
      :data="series"
    />
  </ChartCard>
</template>

<script>
import { computed } from 'vue';
import { DateTime } from 'luxon';
import { useTheme } from '@campsite-bio/component-lib';

import ChartCard from '../chart-card';
import { useApexOptions, useDataColors } from '../../compositions';
import { ChartWrapper } from '../../';

const DOW = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export default {
  components: {
    ChartCard,
    ChartWrapper,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    data: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const dataColors = useDataColors();
    const theme = useTheme();

    const series = computed(() => {
      const data = DOW.reduce(
        (acc, e) => {
          const dayOfWeek = DateTime.fromFormat(e, 'ccc');
          const dayOfWeekFull = dayOfWeek.toFormat('cccc');
          const currentEvent = props.data.find(
            (v) =>
              v.dimensions.range === 'current' &&
              v.dimensions.dow === dayOfWeekFull,
          );
          const pastEvent = props.data.find(
            (v) =>
              v.dimensions.range === 'past' &&
              v.dimensions.dow === dayOfWeekFull,
          );
          const index = DOW.indexOf(dayOfWeek.toFormat('ccc'));

          acc[0].data[index] = currentEvent ? currentEvent.total_events : 0;
          acc[1].data[index] = pastEvent ? pastEvent.total_events : 0;

          return acc;
        },
        [
          {
            label: 'Current Events',
            data: DOW.map(() => 0),
            backgroundColor: dataColors.value[0],
          },
          {
            label: 'Previous Events',
            data: DOW.map(() => 0),
            backgroundColor: theme.value.colors.gray200,
          },
        ],
      );

      return {
        datasets: data,
        labels: DOW,
      };
    });

    const chartOptions = useApexOptions({
      indexAxis: 'y',
      scales: {
        x: {
          border: {
            display: false,
          },
          grid: {
            color: theme.value.colors.gray100,
          },
        },
        y: {
          border: {
            display: false,
          },
          grid: {
            color: theme.value.colors.gray100,
          },
          position: 'right',
        },
      },
      datalabels: {
        display: true,
      },
      legend: {
        display: true,
      },
    });

    return {
      chartOptions,
      series,
    };
  },
};
</script>

<style lang="scss" scoped></style>
