<template>
  <ChartCard class="stat" :title="title" :is-loading="isLoading">
    <Badge
      v-if="badge"
      slot="badge"
      class="v-chart-card__badge"
      :color="badgeColor"
      :content="badge"
    />
    <VText class="analytics-number" component="span">
      <slot />
    </VText>
  </ChartCard>
</template>

<script>
import { Badge, VText } from '@campsite-bio/component-lib';
import { ChartCard } from './';

export default {
  components: {
    Badge,
    VText,
    ChartCard,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    badge: {
      type: String,
      default: null,
    },

    badgeColor: {
      type: String,
      default: 'success',
    },

    isLoading: Boolean,
  },

  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.stat {
  min-height: unset;
}

.analytics-number.analytics-number {
  display: flex;
  font-size: 1.5rem;
  margin-top: 0.25rem;
}
</style>
