<template>
  <VText
    class="settings-section__title"
    component="h2"
    variant="h3"
    v-bind="$attrs"
  >
    <slot />
  </VText>
</template>

<script>
import { VText } from '@campsite-bio/component-lib';
export default {
  components: {
    VText,
  },
};
</script>

<style lang="scss" scoped>
.settings-section__title.settings-section__title {
  margin-bottom: 0.925rem;
}
</style>
