<template>
  <v-menu offset-y max-width="220px" max-height="400px">
    <template #activator="{ on, attrs }">
      <FilterBtn :disabled="menuItems.length === 0" v-bind="attrs" v-on="on">
        <span style="display: inline-flex; gap: 0.35rem; align-items: center">
          <Avatar v-if="account" size="1em">
            <img :src="getProfileImageSrc(account.profile_image)" alt="" />
          </Avatar>

          <span>{{ account ? account.name : '-' | truncate(20) }}</span>
        </span>
        <ChevronIcon />
      </FilterBtn>
    </template>

    <List dense>
      <ListItem
        v-for="({ value, name, profile_image }, i) in menuItems"
        :key="value"
        component="button"
        @click="selectedAccount = value"
      >
        <ListItemAvatar size="30">
          <img :src="getProfileImageSrc(profile_image)" :alt="name" />
        </ListItemAvatar>
        <ListItemContent>
          <ListItemTitle>{{ name }}</ListItemTitle>
          <ListItemSubtitle v-if="i !== 0">Organization</ListItemSubtitle>
        </ListItemContent>
      </ListItem>
    </List>
  </v-menu>
</template>

<script>
import {
  Avatar,
  ChevronIcon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemTitle,
  ListItemSubtitle,
  ListItemContent,
  convertImageUrlToEncodedUrl,
  MEDIA_SIZES,
} from '@campsite-bio/component-lib';
import { computed, toRefs } from 'vue';

import FilterBtn from './filter-btn';
import { useModel, useStore } from '@/compositions';

export default {
  components: {
    FilterBtn,
    Avatar,
    ChevronIcon,
    List,
    ListItem,
    ListItemAvatar,
    ListItemTitle,
    ListItemSubtitle,
    ListItemContent,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const { value } = toRefs(props);
    const selectedAccount = useModel(value, (val) => emit('input', val));
    const accounts = computed(() =>
      store.getters['analytics/getField']('accounts'),
    );

    const account = computed(() => {
      return accounts.value?.find((p) => p._id.$oid === selectedAccount.value);
    });

    const menuItems = computed(() => {
      return accounts.value
        ?.map((p) => ({
          name: p.name,
          value: p._id.$oid,
          profile_image: p.profile_image,
        }))
        .sort((a, b) => a.name?.localeCompare(b.name));
    });

    function getProfileImageSrc(profileImage) {
      return convertImageUrlToEncodedUrl(
        profileImage,
        MEDIA_SIZES.profile_image,
      );
    }

    return {
      selectedAccount,
      account,
      menuItems,
      getProfileImageSrc,
    };
  },
};
</script>

<style lang="scss" scoped></style>
