import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,

  state: {
    configuration: null,
    theme: null,
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
  },
};
