<template>
  <div>
    <FormGroup :gutter-bottom="!!selectedItem">
      <Label block gutter-bottom for="feed-input"
        >Search for a Channel or Playlist</Label
      >
      <span v-if="selectedItem" class="selected-item">
        <Avatar size="50">
          <img
            :src="selectedItem.snippet.thumbnails.default.url"
            alt=""
            aria-hidden="true"
            height="50"
            width="50"
          />
        </Avatar>
        <span>
          <strong>{{ selectedItem.snippet.title }}</strong> -
          {{
            selectedItem.id.kind === 'youtube#playlist'
              ? 'Playlist'
              : 'Channel'
          }}<br />
          <InlineLink @click="changeSelection">Change</InlineLink>
        </span>
      </span>
      <YoutubeSearch
        v-show="!selectedItem"
        v-model="searchText"
        input-id="feed-input"
        :types="['playlist', 'channel']"
        @select="formatResult"
      />
      <FormGroupHelp color="error">{{ error }}</FormGroupHelp>
    </FormGroup>
    <template v-if="!selectedItem">
      <Divider color="gray200" style="margin: 0.75rem 0">or</Divider>
      <FormGroup>
        <Label block gutter-bottom for="feed-input-url"
          >Enter the URL to a Channel or Playlist</Label
        >
        <Input
          id="feed-input-url"
          v-model="feedUrl"
          type="url"
          placeholder="https://www.youtube.com/watch?v=daquswAazJM&list=PL5Y7gl-JeF-YkZmInw10FubfyGo3aF0Fd"
        />
        <FormGroupHelp color="error">{{ error }}</FormGroupHelp>
      </FormGroup>
    </template>
  </div>
</template>

<script>
import {
  Avatar,
  Label,
  FormGroup,
  FormGroupHelp,
  InlineLink,
  Divider,
  Input,
} from '@campsite-bio/component-lib';
import { nextTick, ref, toRefs } from 'vue';
import { useModel } from '../../../../compositions';
import { YoutubeSearch } from '../../../form';

export default {
  components: {
    Avatar,
    YoutubeSearch,
    Label,
    FormGroup,
    FormGroupHelp,
    InlineLink,
    Divider,
    Input,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const searchText = ref(null);
    const selectedItem = ref(null);
    const feedUrl = useModel(value, (val) => emit('input', val));

    function formatResult(item) {
      selectedItem.value = item;
      emit('input:title', item.snippet.title);

      switch (item.id.kind) {
        case 'youtube#playlist':
          feedUrl.value = `https://www.youtube.com/watch?list=${item.id.playlistId}`;
          break;
        case 'youtube#channel':
          feedUrl.value = `https://www.youtube.com/channel/${item.id.channelId}`;
          break;
      }
    }

    async function changeSelection() {
      feedUrl.value = null;
      selectedItem.value = null;
      await nextTick();
      emit('clear');
      emit('input:title', null);
    }

    return { feedUrl, formatResult, selectedItem, searchText, changeSelection };
  },
};
</script>

<style lang="scss" scoped>
.selected-item {
  align-items: center;
  display: flex;
  line-height: 1.4;

  .v-avatar {
    flex-shrink: 0;
  }

  > * {
    margin-right: 0.5rem;
  }
}
</style>
