import { computed, provide } from 'vue';

import { useStore } from '../../compositions';

export function useSelectionModeCreate() {
  const store = useStore();

  const isEnabled = computed({
    get() {
      return store.getters['links/getField']('isSelectionModeEnabled');
    },
    set(value) {
      store.commit('links/updateField', {
        path: 'isSelectionModeEnabled',
        value,
      });
    },
  });
  const selectedLinks = computed({
    get() {
      return store.getters['links/getField']('selectedLinks');
    },
    set(value) {
      store.commit('links/updateField', { path: 'selectedLinks', value });
    },
  });
  const selectedCheckbox = computed({
    get() {
      return store.getters['links/getField']('selectedCheckbox');
    },
    set(value) {
      store.commit('links/updateField', { path: 'selectedCheckbox', value });
    },
  });
  const isActionLoading = computed({
    get() {
      return store.getters['links/getField']('isActionLoading');
    },
    set(value) {
      store.commit('links/updateField', { path: 'isActionLoading', value });
    },
  });

  provide('config', {
    isEnabled,
    selectedLinks,
    selectedCheckbox,
    isActionLoading,
  });

  return {
    isEnabled,
    selectedLinks,
    selectedCheckbox,
    isActionLoading,
  };
}
