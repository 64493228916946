<template>
  <AnalyticsQuickview subtitle="vs yesterday">
    <template slot="title"> Today&nbsp;&nbsp;<LineChartIcon /> </template>

    <QuickviewStat title="Views">
      <Badge
        slot="badge"
        :color="viewBadgeColor"
        :content="viewDifferenceFormatted"
      />
      <Skeleton v-if="isLoading" width="50px" />
      <v-tooltip v-else-if="hasError" bottom>
        <template #activator="{ on, attrs }">
          <InfoIcon color="error" v-bind="attrs" v-on="on" />
        </template>
        <span>There was an error loading todays views</span>
      </v-tooltip>
      <template v-else>
        {{ totalViews | numberFormatter(2) }}
      </template>
    </QuickviewStat>
    <QuickviewStat title="Clicks">
      <Badge
        slot="badge"
        :color="clicksBadgeColor"
        :content="clicksDifferenceFormatted"
      />
      <Skeleton v-if="isLoading" width="50px" />
      <v-tooltip v-else-if="hasError" bottom>
        <template #activator="{ on, attrs }">
          <InfoIcon color="error" v-bind="attrs" v-on="on" />
        </template>
        <span>There was an error loading clicks</span>
      </v-tooltip>
      <template v-else>
        {{ totalClicks | numberFormatter(2) }}
      </template>
    </QuickviewStat>
    <QuickviewStat
      title="CTR"
      help-text="The percentage of views that resulted in a click"
    >
      <Badge
        slot="badge"
        :color="ctrBadgeColor"
        :content="ctrDifferenceFormatted"
      />
      <Skeleton v-if="isLoading" width="50px" />
      <template v-else> {{ currentCtr.toFixed(1) }}% </template>
    </QuickviewStat>
  </AnalyticsQuickview>
</template>

<script>
import {
  InfoIcon,
  LineChartIcon,
  Badge,
  Skeleton,
} from '@campsite-bio/component-lib';
import { computed, ref, watch } from 'vue';
import { useLoadData } from '../compositions/use-load-data';
import AnalyticsQuickview from './analytics-quickview';
import QuickviewStat from './quickview-stat';
import { percentageChange } from '../../../utils';
import { useLastUpdated, useCtr } from '../compositions';
import { useStore } from '../../../compositions';

export default {
  components: {
    AnalyticsQuickview,
    QuickviewStat,
    InfoIcon,
    LineChartIcon,
    Badge,
    Skeleton,
  },

  setup() {
    const store = useStore();
    const token = computed(
      () => store.getters['profiles/currentProfile'].token,
    );
    const hasError = ref(false);

    const { data, load, isLoading } = useLoadData(
      'todayViews',
      'loadReport',
      {
        range: 'today',
        compare: true,
        report: 'analytics',
        granularity: 'hour',
      },
      {
        immediate: true,
        onBeforeLoad() {
          hasError.value = false;
          if (!token.value) return false;
        },
        onError() {
          hasError.value = true;
        },
      },
    );
    const timestamp = computed(() =>
      data.value ? data.value.timestamp : null,
    );

    const totalViews = computed(() => {
      if (!data.value) return 0;
      return data.value.views_by_date
        .filter((d) => d._id.range === 'current')
        .reduce((accum, stat) => {
          accum += stat.count;
          return accum;
        }, 0);
    });

    const totalPastViews = computed(() => {
      if (!data.value) return 0;
      return data.value.views_by_date
        .filter((d) => d._id.range === 'past')
        .slice(
          0,
          data.value.views_by_date.filter((d) => d._id.range === 'current')
            .length - 1,
        )
        .reduce((accum, stat) => {
          accum += stat.count;
          return accum;
        }, 0);
    });

    const viewDifference = computed(() => {
      if (!totalViews.value && !totalPastViews.value) return 0;
      return percentageChange(totalPastViews.value, totalViews.value);
    });

    const viewDifferenceFormatted = computed(() => {
      if (typeof viewDifference.value === 'number')
        return `${viewDifference.value.toFixed(1)}%`;
      return viewDifference.value;
    });

    const viewBadgeColor = computed(() => {
      return viewDifference.value > 0 ? 'success' : 'info';
    });

    ///

    const totalClicks = computed(() => {
      if (!data.value) return 0;
      return data.value.clicks_by_date
        .filter((d) => d._id.range === 'current')
        .reduce((accum, stat) => {
          accum += stat.count;
          return accum;
        }, 0);
    });

    const totalPastClicks = computed(() => {
      if (!data.value) return 0;
      return data.value.clicks_by_date
        .filter((d) => d._id.range === 'past')
        .slice(
          0,
          data.value.clicks_by_date.filter((d) => d._id.range === 'current')
            .length - 1,
        )
        .reduce((accum, stat) => {
          accum += stat.count;
          return accum;
        }, 0);
    });

    const clicksDifference = computed(() => {
      if (!totalClicks.value && !totalPastClicks.value) return 0;
      return percentageChange(totalPastClicks.value, totalClicks.value);
    });

    const clicksDifferenceFormatted = computed(() => {
      if (typeof clicksDifference.value === 'number')
        return `${clicksDifference.value.toFixed(1)}%`;
      return clicksDifference.value;
    });

    const clicksBadgeColor = computed(() => {
      return clicksDifference.value > 0 ? 'success' : 'info';
    });

    ///

    const { currentCtr, ctrDifferenceFormatted, ctrBadgeColor } = useCtr({
      totalViews,
      totalPastViews,
      totalClicks,
      totalPastClicks,
    });

    watch(token, (value) => {
      if (value) {
        load();
      }
    });

    // keep the data updated
    useLastUpdated({
      interval: 20000, // update timestamp every 20 seconds
      timeShouldHavePassed: 60 * 3 * 1000, // 3 minutes
      lastUpdatedTimestamp: timestamp,
      callback() {
        if (!isLoading.value && token.value) {
          load();
        }
      },
    });

    return {
      hasError,
      isLoading,
      totalViews,
      viewDifferenceFormatted,
      viewBadgeColor,
      totalClicks,
      clicksDifferenceFormatted,
      clicksBadgeColor,
      currentCtr,
      ctrDifferenceFormatted,
      ctrBadgeColor,
    };
  },
};
</script>

<style lang="scss" scoped></style>
