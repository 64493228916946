import {
  CarouselIcon,
  LinkIcon,
  RssIcon,
  TitleIcon,
  MinusIcon,
  EmailIcon,
  EmbedOutlineIcon,
  DynamicFormIcon,
  DonationIcon,
  RequestIcon,
  GridIcon,
} from '@campsite-bio/component-lib';

export const LINK_ICONS = {
  CarouselIcon,
  LinkIcon,
  RssIcon,
  TitleIcon,
  MinusIcon,
  EmailIcon,
  EmbedOutlineIcon,
  DynamicFormIcon,
  DonationIcon,
  RequestIcon,
  GridIcon,
};

export const LINK_ICON_MAP = {
  carousel: 'CarouselIcon',
  feed: 'RssIcon',
  title: 'TitleIcon',
  delimiter: 'MinusIcon',
  'opt-in': 'EmailIcon',
  embed: 'EmbedOutlineIcon',
  form: 'DynamicFormIcon',
  support: 'DonationIcon',
  request: 'RequestIcon',
  'image-grid': 'GridIcon',
};

/**
 * Get the component name of a social icon
 * @param {String} icon
 * @returns the component name
 */
export function getLinkIconByType(type) {
  return LINK_ICON_MAP[type] || 'LinkIcon';
}
