<template>
  <ListItem
    component="button"
    type="button"
    :disabled="disabled"
    @click="$emit('click', 'opt-in-form')"
  >
    <ListItemAction>
      <LockIcon v-if="disabled" size="1.25rem" />
      <EmailIcon v-else size="1.25rem" />
    </ListItemAction>
    <ListItemContent>
      <ListItemTitle>Opt-in Form</ListItemTitle>
      <ListItemSubtitle wrap
        >Collect email addresses or SMS numbers from your
        visitors.</ListItemSubtitle
      >
    </ListItemContent>
  </ListItem>
</template>

<script>
import {
  ListItem,
  ListItemAction,
  ListItemContent,
  ListItemSubtitle,
  ListItemTitle,
  LockIcon,
  EmailIcon,
} from '@campsite-bio/component-lib';

export default {
  components: {
    ListItem,
    ListItemAction,
    ListItemContent,
    ListItemSubtitle,
    ListItemTitle,
    LockIcon,
    EmailIcon,
  },

  props: {
    disabled: Boolean,
  },
};
</script>

<style lang="scss" scoped></style>
