<template>
  <LinkWrapper v-bind="$props" @add-link="$emit('add-link', $event)">
    <LinkCard>
      <ValidationObserver ref="form" slim>
        <ValidationProvider
          v-slot="{ errors, ariaInput, ariaMsg }"
          rules="max:1000"
          name="text"
          immediate
          slim
        >
          <LinkField>
            <Label :for="'text' + _uid" class="sr-only">Text</Label>
            <ResizeableTextarea :max-height="isReadonly ? 100 : null">
              <textarea
                :id="'text' + _uid"
                v-model="textLocal"
                class="markdown"
                autocomplete="off"
                :disabled="isReadonly"
                v-bind="ariaInput"
                @focus="focusLink"
              />
            </ResizeableTextarea>
            <FormGroupHelp color="error" v-bind="ariaMsg">{{
              errors[0]
            }}</FormGroupHelp>
          </LinkField>
        </ValidationProvider>
      </ValidationObserver>

      <template slot="btm">
        <LinkBtn
          v-if="!isStatsHidden"
          :active="isExpanded('stats')"
          :aria-label="`Link analytics - ${link.number_of_clicks} clicks`"
          :aria-controls="`stats-${id}`"
          @click="toggleExpand('stats')"
        >
          <LineChartIcon slot="icon" />
          <template v-if="link.number_of_clicks" slot="stat">
            {{ link.number_of_clicks | numberFormatter(1) }}
          </template>
        </LinkBtn>
        <LinkBtn
          v-if="!isDeleted && !isScheduleHidden"
          aria-label="Schedule link"
          :active="isExpanded('schedule')"
          :highlight="isExpanded('schedule')"
          :aria-controls="`schedule-${id}`"
          @click="toggleExpand('schedule')"
        >
          <CalendarIcon slot="icon" />
        </LinkBtn>
        <v-tooltip v-if="!isDeleted && !isPinnedHidden" top>
          <template #activator="{ on }">
            <LinkBtn
              v-if="!isDeleted"
              aria-label="Pin link"
              :pinned="pinned"
              :disabled="isPinnedDisabled"
              v-on="on"
              @click="pinned = !pinned"
            >
              <PinIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>{{ pinnedText }}</span>
        </v-tooltip>
        <v-tooltip v-if="isDeleted" top>
          <template #activator="{ on }">
            <LinkBtn
              class="link__btn--restore js-link-restore"
              aria-label="Restore link"
              :disabled="isRestoring"
              v-on="on"
              @click="handleRestore"
            >
              <RepeatIcon slot="icon" />
            </LinkBtn>
          </template>
          <span>Restore link</span>
        </v-tooltip>
      </template>

      <template slot="actions">
        <StatsExpand
          :is-open="isExpanded('stats')"
          @close="toggleExpand('stats')"
        />
        <ScheduleExpand
          :is-open="isExpanded('schedule')"
          :link="link"
          @close="toggleExpand('schedule')"
        />
        <SettingsExpand
          :is-open="isExpanded('default')"
          :link="link"
          :text-options="options"
          :text="textLocal"
          @close="toggleExpand('default')"
          @save="saveOptions"
          @change:dirty="isOptionsDirty = $event"
          @input:text="textLocal = $event"
        />
      </template>
    </LinkCard>
  </LinkWrapper>
</template>

<script>
import {
  CalendarIcon,
  PinIcon,
  LineChartIcon,
  RepeatIcon,
  Label,
  FormGroupHelp,
} from '@campsite-bio/component-lib';
import { ref } from 'vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import { StatsExpand, ScheduleExpand } from '../expand';
import SettingsExpand from './settings-expand';
import ResizeableTextarea from '../../form/resizeable-textarea';
import { LinkProps } from '../link-props';
import { useLink } from '../compositions';
import { LinkBtn, LinkWrapper, LinkCard, LinkField } from '../components';

export default {
  components: {
    StatsExpand,
    ScheduleExpand,
    LineChartIcon,
    CalendarIcon,
    PinIcon,
    RepeatIcon,
    LinkBtn,
    Label,
    SettingsExpand,
    FormGroupHelp,
    ResizeableTextarea,
    ValidationObserver,
    ValidationProvider,
    LinkWrapper,
    LinkCard,
    LinkField,
  },

  props: LinkProps,

  setup(props) {
    const {
      link,
      isSavingOptions,
      isOptionsDirty,
      options,
      saveOptions: saveTextOptions,
      ...rest
    } = useLink(props, {
      expandOnAdd: 'default',
      name: 'text link',
      optionsName: 'text',
    });
    const textLocal = ref(null);

    async function saveOptions(options) {
      // Make sure too long of text doesn't save
      if (textLocal.value?.length > 1000) return;

      return saveTextOptions(options);
    }

    return {
      ...rest,
      options,
      textLocal,
      isSavingOptions,
      saveOptions,
      isOptionsDirty,
    };
  },
};
</script>

<style lang="scss" scoped>
.markdown {
  border-radius: 0.25rem;
  border: none;
  padding: 0.5rem 0.25rem;
  resize: none;
  width: 100%;
  transition: all var(--g-transition-speed);

  &:focus,
  &:hover {
    background: var(--g-color-gray-100);
    outline: none;
  }

  &:disabled {
    background: var(--g-color-gray-100);
    color: var(--g-color-gray-600);
    cursor: default;
  }
}
</style>
