<template>
  <nav v-bind="$attrs" ref="root" class="scroll-header" :class="classes">
    <div class="scroll-header__inner">
      <span class="scroll-header__text">Jump to:</span>
      <ul>
        <li v-for="(item, i) in items" :key="i">
          <a :href="item.href" @click.prevent="handleClick(item)">
            {{ item.text }}
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { computed, inject, ref } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const root = ref(null);
    const { hideMenu } = inject('app');

    const classes = computed(() => {
      return {
        'scroll-header--menu-hidden': hideMenu.value,
      };
    });

    useIntersectionObserver(
      root,
      ([{ intersectionRatio }]) => {
        root.value?.toggleAttribute('stuck', intersectionRatio < 1);
      },
      {
        threshold: [1],
      },
    );

    function handleClick(item) {
      document.getElementById(item.href.substring(1)).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }

    return { root, classes, handleClick };
  },
};
</script>

<style lang="scss" scoped>
.scroll-header {
  margin-left: -1rem;
  margin-right: -1rem;
  position: sticky;
  top: -1px;
  height: 2.5rem;
  z-index: 99;

  &[stuck] {
    background: #fff;

    .scroll-header__inner {
      background: #fff;
      border-bottom: 1px solid var(--g-color-gray-200);
      margin-top: var(--header-height);
    }

    &.scroll-header--menu-hidden {
      .scroll-header__inner {
        margin-top: 0;
      }
    }
  }

  &__inner {
    border-bottom: 1px solid transparent;
    align-items: center;
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    white-space: nowrap;
    width: 100%;
    transition: background-color var(--g-transition-speed),
      border-color var(--g-transition-speed),
      margin-top var(--g-transition-speed);
  }

  &__text {
    color: var(--g-color-gray-600);
    font-weight: 700;
    font-size: 0.9rem;
    margin: 0 0.75rem 0 1rem;
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;

    li {
      display: inline-flex;
    }
  }

  a {
    color: var(--g-color-gray-700);
    text-decoration: none;
    font-weight: 600;
    font-size: 0.9rem;
    padding: 0.5rem;
  }
}
</style>
