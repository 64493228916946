<template>
  <div class="app-container">
    <Header />
    <main class="app-container__main">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </main>

    <v-media-dialog v-model="showMediaDialog" v-bind="mediaDialogProps" />

    <v-snackbar v-if="isOffline" v-model="isOffline" bottom :timeout="-1">
      You're offline. Changes won't be saved.
      <Button variant="flat" color="white" @click="offlineClick">
        Reload
      </Button>
    </v-snackbar>

    <UpdateUserDetailsDialog />
  </div>
</template>

<script>
import { Button } from '@campsite-bio/component-lib';
import { computed, inject, watch } from 'vue';

import { Header } from '@/components/header';
import toast from '@/utils/toast';
import { VueOfflineMixin } from '@/mixins/offline';
import { intercomUpdate } from '@/utils/intercom';
import router from '@/router';
import { useNavbarScroll, useStore } from '@/compositions';

export default {
  components: {
    Header,
    Button,
    UpdateUserDetailsDialog: () =>
      import('@/components/dialogs/update-user-details-dialog'),
  },

  mixins: [VueOfflineMixin],

  setup() {
    const store = useStore();
    const hideMenuLocal = useNavbarScroll({ scrollOffset: 50 });

    const { hideMenu } = inject('app', {});

    const showMediaDialog = computed({
      get() {
        return store.getters.showMediaDialog;
      },
      set(newValue) {
        store.commit('setShowMediaDialog', newValue);
      },
    });

    // Sync the hideMenu value with the app injection
    watch(
      hideMenuLocal,
      (newValue) => {
        hideMenu.value = newValue;
      },
      { immediate: true },
    );

    return {
      hideMenu: hideMenuLocal,
      showMediaDialog,
    };
  },

  computed: {
    user() {
      return this.$store.getters.currentUser;
    },

    hideFeedback() {
      return this.$store.getters.hideFeedback;
    },

    mediaDialogProps() {
      return this.$store.getters.mediaDialogProps;
    },

    isDocumentVisible() {
      return this.$store.getters.isDocumentVisible;
    },

    hasNoCampsites() {
      return this.$store.getters.hasNoCampsites;
    },

    showIntercom() {
      return !this.mediaDialog && !this.hideFeedback;
    },
  },

  watch: {
    // show/hide the intercom messenger
    showIntercom() {
      intercomUpdate({
        hide_default_launcher: !this.showIntercom,
      });
    },

    // on tab switch, get user data
    async isDocumentVisible(newValue) {
      if (!newValue) {
        this.reloadUserData();
      }
    },
  },

  mounted() {
    if (this.$route.query.error_message) {
      toast.error(this.$route.query.error_message);
      router.replace(this.$route.path);
    }
    if (this.$route.query.auth_success === 'facebook') {
      toast.success(
        "You've successfully connected your Instagram Business account",
      );

      router.replace(this.$route.path);
    }
  },

  methods: {
    offlineClick() {
      window.location.reload();
    },

    async reloadUserData() {
      if (this.$store.getters['isLoadingUser']) return;

      try {
        // get up to date user info
        await this.$store.dispatch('getUserData', {
          force: true,
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  --header-height: 3rem;
  --profile-menu-height: 2.5rem;
  --settings-scroll-header-height: 2.5rem;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  isolation: isolate;

  @media (min-width: 992px) {
    --header-width: 3.75rem;
    --profile-menu-height: 3rem;
    padding-left: var(--header-width);
    flex-direction: row;
  }

  @media print {
    padding-left: 0;
  }

  &__main {
    padding-top: var(--header-height);
    max-width: 100%;

    @media (min-width: 992px) {
      padding-top: 0;
    }

    @media print {
      padding-top: 0;
    }
  }
}
</style>
