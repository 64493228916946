import { computed, provide, ref, unref, watch, nextTick } from 'vue';
import axios from 'axios';
import debounce from 'lodash/debounce';
import camelCase from 'lodash/camelCase';
import { useMediaQuery } from '@vueuse/core';
import { getEmbedType, LINK_ICONS } from '@campsite-bio/component-lib';

import { useStore } from '@/compositions';
import toast from '@/utils/toast';
import { useMagicImage } from '@/compositions/use-magic-image';
import { getMeta } from '@/utils';
import { useLinkActions } from './use-link-actions';
import { useLinkFields } from './use-link-fields';
import { useLinkMedia } from './use-link-media';
import bus from '@/bus';
import { useLinkOptions } from './use-link-options';
import { useLinkSelectionMode } from './use-link-selection-mode';

const ALL_ACTIONS = [
  'stats',
  'schedule',
  'highlight',
  'pinned',
  'lock',
  'event_tag',
];

export function useLink(
  props,
  {
    hasMedia = false,
    expandOnAdd,
    expandOnSetup,
    name = 'link',
    optionsName,
    isLoading: isLoadingRef,
  } = {},
) {
  const store = useStore();
  const linkRef = computed(() => unref(props.link));
  const ignoreUpdate = ref(false);
  const isSaving = ref(false);
  const isDeleting = ref(false);
  const isRestoring = ref(false);
  const isDeleteDialogOpen = ref(false);
  const cancelToken = ref(null);
  const root = ref(null);
  const form = ref(null);
  const labelRef = ref(null);
  const isEnabled = computed(() => linkRef.value.enabled);
  const isDeleted = computed(() => linkRef.value.is_deleted);

  const ignoreLinkChanges = computed(() => {
    return store.getters['links/ignoreLinkChanges'];
  });

  const { url, ...linksFields } = useLinkFields(linkRef, {
    ignoreUpdate,
    ignoreLinkChanges,
  });
  const linkMedia = useLinkMedia(linkRef, {
    ignoreUpdate,
  });
  const id = computed(() => linkRef.value._id.$oid);
  const groupId = computed(() => linkRef.value.group_id?.$oid);
  const isUniversalLink = computed(
    () => typeof linkRef.value.universal_link_settings === 'object',
  );
  const isUniversalLinkPinned = computed(() =>
    isUniversalLink.value
      ? linkRef.value.universal_link_settings.is_pinned
      : false,
  );
  const isInUniversalLinkGroup = computed(
    () => !!linkRef.value.universal_link_group_id,
  );
  const { isExpandOpen, toggleExpand, isPinned, ...restLinkActions } =
    useLinkActions(linkRef);
  const isLargeScreen = useMediaQuery('(min-width: 992px)');
  let options = {};
  if (optionsName) {
    options = useLinkOptions({
      link: linkRef,
      linkId: id,
      optionsName,
      ignoreUpdate,
    });

    // Expand a link after it has been setup
    if (expandOnSetup) {
      watch(options.hasSetup, async (hasSetup) => {
        if (hasSetup) {
          await nextTick();
          toggleExpand(expandOnSetup);
        }
      });
    }
  }
  const {
    isSelectionModeEnabled,
    handleLinkSelectionToggle,
    isLinkSelected,
    ...restLinkSelectionMode
  } = useLinkSelectionMode({
    linkId: id,
  });

  function handleLinkClick() {
    // Only handle link selection on pro users and if selection mode is enabled
    if (
      !isFreePlan.value &&
      isSelectionModeEnabled.value &&
      !isUniversalLink.value
    ) {
      handleLinkSelectionToggle();
    }
  }

  const isReadonly = computed(() => {
    return (
      props.readonly ||
      isSelectionModeEnabled.value ||
      isDeleted.value ||
      isUniversalLink.value
    );
  });

  // Is this link being dragged?
  const isBeingDragged = computed(() => {
    return store.getters['links/getField']('linkDragId') === id.value;
  });

  const actionsToHide = computed(() => {
    let hideActions = props.hideActions || [];
    if (isUniversalLink.value) {
      hideActions = [
        ...hideActions,
        'schedule',
        'pinned',
        'highlight',
        'lock',
        'event_tag',
      ];
      // remove duplicate actions
      hideActions = [...new Set(hideActions)];
    }

    return hideActions;
  });

  const hiddenActions = ALL_ACTIONS.reduce((accum, action) => {
    const name = camelCase(`is_${action}_hidden`);
    accum[name] = computed(() => actionsToHide.value.includes(action));
    return accum;
  }, {});

  const isPinnedDisabled = computed(() => {
    return (
      (!isPinned.value && props.pinnedDisabled) ||
      store.getters['links/isPinningLink']
    );
  });

  const pinnedText = computed(() => {
    if (isPinnedDisabled.value && !isPinned.value)
      return 'You can only pin five links';
    return isPinned.value ? 'Unpin this link' : 'Pin this link to the top';
  });

  const isFreePlan = computed(() => {
    return store.getters['profiles/isProfileOnFreePlan'];
  });

  const numberOfClicks = computed(() => {
    return linkRef.value.number_of_clicks || 0;
  });

  const isLoading = computed(() => {
    const isLoadingOptions = optionsName
      ? options.isSavingOptions.value
      : false;
    const isLoadingRefValue = isLoadingRef ? isLoadingRef.value : false;
    return (
      isSaving.value ||
      isDeleting.value ||
      isRestoring.value ||
      isLoadingOptions ||
      isLoadingRefValue
    );
  });

  const isDragHidden = computed(() => {
    return isDeleted.value || isPinned.value;
  });

  const hasLock = computed(() => {
    const { has_code, has_dob, has_sensitive_content } =
      linkRef.value.locked || {};
    return has_code || has_dob || has_sensitive_content;
  });

  const embedOptions = computed(() => {
    return getEmbedType(linkRef.value.url);
  });

  const isEmbedLink = computed(() => embedOptions.value !== undefined);

  const { images: magicImages, show: showMagicImage } = useMagicImage({
    url,
    isDisabled: isFreePlan.value,
  });

  const embedComponentIcon = computed(() =>
    embedOptions.value ? embedOptions.value.icon : null,
  );
  const embedComponentIconColor = computed(() =>
    embedOptions.value && embedOptions.value.iconColor
      ? embedOptions.value.iconColor
      : 'inherit',
  );

  const canArchive = computed(() => {
    switch (linkRef.value.type) {
      case 'title':
      case 'delimiter':
        return false;
      default:
        return true;
    }
  });

  const hasDefaultExpand = computed(() => {
    switch (linkRef.value.type) {
      case 'carousel':
      case 'delimiter':
      case 'divider':
      case 'embed':
      case 'feed':
      case 'form':
      case 'group':
      case 'opt-in-form':
      case 'image-grid':
      case 'contact-details':
      case 'request':
      case 'support':
      case 'text':
      case 'shop':
        return true;
      default:
        return false;
    }
  });

  function justAdded() {
    if (expandOnAdd) toggleExpand(expandOnAdd);
  }

  function focus(options) {
    labelRef.value?.focus(options);
  }

  function focusLink() {
    bus.$emit('focus-link', { link: linkRef.value });
  }

  const linkIconComponent = computed(() => {
    switch (linkRef.value.type) {
      case 'embed':
        return LINK_ICONS.EmbedOutlineIcon;
      case 'form':
        return LINK_ICONS.DynamicFormIcon;
      case 'feed':
        return LINK_ICONS.RssIcon;
      case 'opt-in-form':
        return linkRef.value.email_options.type === 'email'
          ? LINK_ICONS.EmailIcon
          : LINK_ICONS.PhoneIcon;
      case 'title':
        return LINK_ICONS.TitleIcon;
      case 'carousel':
        return LINK_ICONS.CarouselIcon;
      case 'delimiter':
        return LINK_ICONS.MinusIcon;
      case 'text':
        return LINK_ICONS.TextBoxOutlineIcon;
      case 'contact-details':
        return LINK_ICONS.CardAccountDetailsOutlineIcon;
      case 'request':
        return LINK_ICONS.RequestIcon;
      case 'support':
        return LINK_ICONS.DonationIcon;
      case 'group':
        return LINK_ICONS.GroupIcon;
      case 'image-grid':
        return LINK_ICONS.GridIcon;
      case 'shop':
        return LINK_ICONS.ShopIcon;
      default:
        return LINK_ICONS.LinkIcon;
    }
  });

  const linkReadableName = computed(() => {
    switch (linkRef.value.type) {
      case 'embed':
        return 'Embed';
      case 'form':
        return 'Form';
      case 'feed':
        return 'Feed';
      case 'opt-in-form':
        return 'Opt-in form';
      case 'title':
        return 'Title';
      case 'carousel':
        return 'Carousel';
      case 'delimiter':
        return 'Divider';
      case 'group':
        return 'Link group';
      case 'text':
        return 'Text block';
      case 'contact-details':
        return 'Contact details';
      case 'request':
        return 'Request';
      case 'support':
        return 'Support';
      case 'image-grid':
        return 'Image grid';
      case 'shop':
        return 'Shop';
      default:
        return 'Link';
    }
  });

  const linkClasses = computed(() => {
    return {
      'link--enabled': linkRef.value.enabled,
      'link--deleted': isDeleted.value,
      'link--no-post': !unref(hasMedia),
      'link--readonly': props.readonly,
    };
  });

  const linkCardElevation = computed(() => {
    if (isSelectionModeEnabled.value) {
      if (isLinkSelected.value) return 1;
      return -1;
    }

    if (isExpandOpen.value) return 1;
    if (linkRef.value.enabled) return 0;
    return -1;
  });

  async function save() {
    // Don't save changes in deleted links
    if (isDeleted.value) return;

    if (form.value) {
      const success = await form.value.validate();
      if (!success) return;
    }

    isSaving.value = true;
    if (cancelToken.value) cancelToken.value.cancel();
    cancelToken.value = axios.CancelToken.source();

    // console.log('saving link', id.value);

    try {
      const linkMedia = getMeta('media', null, linkRef.value.meta);

      const { link } = await store.dispatch('links/updateLink', {
        id: id.value,
        data: { ...linkRef.value, media: linkMedia },
        cancelToken: cancelToken.value.token,
      });

      // make sure save isn't called when we update the post image
      ignoreUpdate.value = true;
      store.commit('links/updateLinkField', {
        name: 'dirty',
        value: false,
        id: id.value,
        groupId: groupId.value,
      });
      store.commit('links/updateLinkField', {
        name: 'start_time_timezone_id',
        value: link.start_time_timezone_id,
        id: id.value,
        groupId: groupId.value,
      });
      await nextTick();
      ignoreUpdate.value = false;

      bus.$emit('link-updated', { link });
    } catch (e) {
      if (axios.isCancel(e) || e.name === 'AbortError') return;
      console.error(e);
      const { error_message, status } = (e.response && e.response.data) || {};
      toast.error(error_message || 'Error trying to update your link', {
        timeout: status === 403 || status === 400 ? 8000 : 3000,
      });
    }

    isSaving.value = false;
  }

  const saveDebounced = debounce(save, 1000);

  // Watch for any changes on specific fields on a link
  watch(
    () => {
      return {
        label: linkRef.value.label,
        url: linkRef.value.url,
        meta: linkRef.value.meta,
        enabled: linkRef.value.enabled,
        pinned: linkRef.value.pinned,
        start_time: linkRef.value.start_time,
        start_time_timezone: linkRef.value.start_time_timezone,
        end_time: linkRef.value.end_time,
        highlight: linkRef.value.highlight,
        video_embed_type: linkRef.value.video_embed_type,
      };
    },
    () => {
      if (!ignoreUpdate.value && !ignoreLinkChanges.value) {
        // console.log('tracked change', id.value);
        saveDebounced();
      }
    },
    { deep: true },
  );

  watch(
    linkRef,
    () => {
      if (!ignoreUpdate.value && !ignoreLinkChanges.value) {
        // console.log('marked dirty', id.value);
        store.commit('links/updateLinkField', {
          name: 'dirty',
          value: true,
          id: id.value,
          groupId: groupId.value,
        });
      }
    },
    { deep: true },
  );

  watch(isEmbedLink, (newValue) => {
    if (newValue) toggleExpand('embed');
  });

  async function handleArchive() {
    isDeleting.value = true;

    try {
      await store.dispatch('links/archiveLink', {
        id: id.value,
        groupId: groupId.value,
      });

      toast.success('Link archived', { timeout: 2000 });
      const hasTakenArchiveTour = getMeta(
        'tours',
        {},
        store.getters.currentUser.meta,
      ).archive;

      // check if archive tour needs to be shown
      if (!hasTakenArchiveTour) {
        import('../../../tours/archive').then((ArchiveTour) => {
          new ArchiveTour.default();
        });
      }
    } catch (e) {
      console.error(e);
      const { error_message } = (e.response && e.response.data) || {};
      toast.error(
        error_message ? error_message : 'Error trying to archive your link',
        { timeout: 3000 },
      );
    }

    isDeleting.value = false;
  }

  async function handleRestore() {
    isRestoring.value = true;

    try {
      await store.dispatch('links/restoreLink', {
        id: id.value,
      });

      toast.success('Link restored', { timeout: 2000 });
    } catch (e) {
      console.error(e);
      const { error_message } = e.response?.data || {};
      toast.error(
        error_message ? error_message : 'Error trying to restore your link',
      );
    }

    isRestoring.value = false;
  }

  async function handleDelete() {
    isDeleting.value = true;

    try {
      await store.dispatch('links/deleteLink', {
        id: id.value,
        groupId: groupId.value,
      });

      bus.$emit('link-deleted', id.value);
      isDeleteDialogOpen.value = false;
    } catch (e) {
      console.error(e);
      const { error_message } = (e.response && e.response.data) || {};
      toast.error(
        error_message ? error_message : 'Error trying to delete your link',
        { timeout: 3000 },
      );
    }

    isDeleting.value = false;
  }

  const provideData = {
    name,
    root,
    form,
    labelRef,
    link: linkRef,
    id,
    isDeleting,
    isRestoring,
    isDeleted,
    isDeleteDialogOpen,
    isSaving,
    isEnabled,
    isLoading,
    isDragHidden,
    isPinnedDisabled,
    pinnedText,
    numberOfClicks,
    hasLock,
    embedOptions,
    isEmbedLink,
    magicImages,
    showMagicImage,
    embedComponentIcon,
    embedComponentIconColor,
    canArchive,
    hasDefaultExpand,
    focus,
    focusLink,
    linkIconComponent,
    linkReadableName,
    isFreePlan,
    save,
    saveDebounced,
    handleArchive,
    handleRestore,
    handleDelete,
    isPinned,
    linkClasses,
    linkCardElevation,
    isLargeScreen,
    toggleExpand,
    justAdded,
    url,
    isSelectionModeEnabled,
    isReadonly,
    isBeingDragged,
    isLinkSelected,
    isUniversalLink,
    isInUniversalLinkGroup,
    isUniversalLinkPinned,
    ...linksFields,
    ...linkMedia,
    ...restLinkActions,
    ...restLinkSelectionMode,
    ...hiddenActions,
    ...options,
  };

  provide('link', {
    ...provideData,
    handleLinkClick,
  });

  return provideData;
}
