<template>
  <v-dialog v-model="isOpen" v-bind="$props">
    <template v-for="(index, name) in $scopedSlots" #[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>

    <Card class="multi-step-dialog">
      <CardTitle>
        <template v-if="showBackButton">
          <IconButton size="1.25rem" aria-label="Back" @click="handleBack">
            <ChevronIcon direction="left" />
          </IconButton>
          <FlexSpacer />
        </template>
        <VText component="h2" variant="h4">{{
          currentStepDetails.title
        }}</VText>
        <FlexSpacer />
        <IconButton size="1.25rem" aria-label="Close" @click="isOpen = false">
          <CloseIcon />
        </IconButton>
      </CardTitle>
      <Divider color="gray200" />

      <component
        :is="currentStepDetails.component"
        v-bind="currentStepProps"
        v-on="currentStepEvents"
      />
    </Card>
  </v-dialog>
</template>

<script>
import {
  Card,
  CardTitle,
  IconButton,
  CloseIcon,
  FlexSpacer,
  VText,
  Divider,
  ChevronIcon,
} from '@campsite-bio/component-lib';
import { VDialog } from 'vuetify/lib';
import { computed, provide, ref, readonly, watch, toRefs, unref } from 'vue';

import Router from './router';
import { useModel } from '../../compositions';

export default {
  components: {
    Card,
    CardTitle,
    IconButton,
    CloseIcon,
    FlexSpacer,
    VText,
    Divider,
    ChevronIcon,
    Router,
  },

  props: {
    ...VDialog.options.props,

    value: Boolean,

    steps: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);
    const isOpen = useModel(value, (val) => emit('input', val));
    const history = ref([]);
    const currentStep = ref({ path: '' });
    const preventBack = ref(null);

    const currentStepDetails = computed(() => {
      return props.steps.find((step) => step.path === currentStep.value.path);
    });

    const currentStepProps = computed(() => {
      const stepProps = currentStep.value.props || {};
      const stepDetailProps = currentStepDetails.value.props || {};
      return {
        ...Object.keys(stepDetailProps).reduce((accum, key) => {
          accum[key] = unref(stepDetailProps[key]);
          return accum;
        }, {}),
        ...stepProps,
      };
    });

    const currentStepEvents = computed(() => {
      const stepOn = currentStep.value.on || {};
      const stepDetailOn = currentStepDetails.value.on || {};
      return {
        ...Object.keys(stepDetailOn).reduce((accum, key) => {
          accum[key] = unref(stepDetailOn[key]);
          return accum;
        }, {}),
        ...stepOn,
      };
    });

    watch(currentStep, () => {
      // Reset prevent back when moving between steps
      preventBack.value = null;
    });

    function handleStepChange(path, props) {
      history.value = [...history.value, currentStep.value];
      currentStep.value = {
        path,
        props,
      };
    }

    /**
     * Set whether a back press should confirm changes
     */
    function setPreventBack(value, message = null) {
      if (value) {
        preventBack.value = {
          value,
          message,
        };
      } else preventBack.value = null;
    }

    provide('multi-step-dialog', {
      currentStep: readonly(currentStep),
      currentStepDetails,
      handleStepChange,
      handleBack,
      isOpen,
      setPreventBack,
    });

    const showBackButton = computed(() => {
      return history.value.length > 0;
    });

    function handleBack() {
      if (
        preventBack.value &&
        !confirm(
          preventBack.value.message ||
            "Some changes haven't been saved. Are you sure you want to proceed?",
        )
      ) {
        return;
      }

      const previousPath = history.value.pop();
      currentStep.value = previousPath;
    }

    watch(isOpen, (newValue) => {
      if (!newValue) handleClose();
    });

    function handleClose() {
      // Reset history
      history.value.length = 0;
      currentStep.value = { path: '' };
      emit('close');
      isOpen.value = false;
    }

    return {
      isOpen,
      handleClose,
      handleBack,
      currentStep,
      currentStepDetails,
      showBackButton,
      currentStepProps,
      currentStepEvents,
    };
  },
};
</script>

<style lang="scss">
.multi-step-dialog {
  .v-card__title.v-card__title {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .v-card__title {
    .v-icon-btn {
      &:first-child {
        margin-left: -0.3em;
      }

      &:last-child {
        margin-right: -0.3em;
      }
    }
  }
}
</style>
