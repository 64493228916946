<template>
  <div class="settings-section__header" v-bind="$attrs">
    <div class="settings-section__header__content">
      <slot />
    </div>
    <div v-if="$slots.btn && !loading" class="settings-section__header__btn">
      <slot name="btn" />
    </div>
    <div v-show="loading" class="settings-section__header__loader">
      <span class="settings-section__header__loader-wrapper">
        <CircularLoader size="1.5rem" />
      </span>
    </div>
  </div>
</template>

<script>
import { CircularLoader } from '@campsite-bio/component-lib';

export default {
  components: {
    CircularLoader,
  },

  props: {
    loading: Boolean,
  },

  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.settings-section {
  &__header {
    display: flex;
    margin-bottom: 0.925rem;

    ::v-deep p {
      color: var(--g-color-gray-500);
      font-size: 0.925rem;
      line-height: 1.4;
      margin: 0;
    }

    ::v-deep .settings-section__title.settings-section__title {
      margin-bottom: 0;
    }

    ::v-deep .v-button {
      white-space: nowrap;
    }

    ::v-deep .v-icon-btn {
      margin-top: -0.4em;
      margin-right: -0.4em;
    }

    &__content {
      flex-grow: 1;
    }

    &__btn,
    &__loader {
      margin-left: 1rem;
    }

    &__btn {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &__loader {
      position: relative;

      &-wrapper {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }
    }
  }
}
</style>
