<template>
  <Dialog
    v-model="isOpen"
    persistent
    max-width="400px"
    title="This profile has been checked out"
    hide-close
  >
    <CardText>
      <VText gutter-bottom>
        <Skeleton v-if="isLoading" :count="4" />
        <template v-else>
          {{ user.first_name }} ({{ user.email }}) is editing this profile. Do
          you want to take over?<br /><br />
          <small>Checked out at {{ checkedOutDate }}.</small>
        </template>
      </VText>
    </CardText>
    <CardActions>
      <Button
        variant="flat"
        to="/profiles"
        :disabled="isCheckingOut"
        @click.native="close"
        >View Profiles</Button
      >
      <Button
        color="success"
        type="submit"
        variant="flat"
        :loading="isCheckingOut"
        @click="takeOver"
        >Take Over</Button
      >
    </CardActions>
  </Dialog>
</template>

<script>
import {
  CardText,
  CardActions,
  Button,
  VText,
  Skeleton,
} from '@campsite-bio/component-lib';
import { computed, ref, watch } from 'vue';
import { DateTime } from 'luxon';

import { useStore } from '../../compositions';
import toast from '../../utils/toast';
import { localAxios } from '../../apis';
import Dialog from './dialog';

export default {
  components: {
    CardText,
    CardActions,
    Button,
    VText,
    Skeleton,
    Dialog,
  },

  setup(props, { emit }) {
    const store = useStore();
    const isCheckingOut = ref(false);
    const isLoading = ref(true);
    const user = ref(null);

    const isOpen = computed({
      get() {
        return store.getters.showCheckedOutDialog;
      },
      set(value) {
        store.commit('updateField', { path: 'showCheckedOutDialog', value });
      },
    });

    const checkedOutDate = computed(() => {
      return DateTime.fromISO(
        store.getters['profiles/checkOut'].checked_out_at,
      ).toLocaleString(DateTime.TIME_SIMPLE);
    });

    async function loadUser() {
      isLoading.value = true;
      try {
        const { data } = await localAxios.get(
          `/api/profiles/${store.getters['profiles/currentProfileId']}/check-out-user`,
        );

        user.value = data;
      } catch (e) {
        console.error(e);
        user.value = {
          first_name: 'Someone',
        };
      }
      isLoading.value = false;
    }

    watch(
      isOpen,
      (newValue) => {
        if (newValue) loadUser();
      },
      { immediate: true },
    );

    async function takeOver() {
      isCheckingOut.value = true;
      try {
        // TODO: this doesn't restart the status checks in the profile check out
        await store.dispatch('profiles/checkOut', {
          take_over: true,
        });

        close();
        emit('take-over');
      } catch (e) {
        console.error(e);
        toast.error(
          'There was an error trying to check out this profile. Please try again soon.',
        );
      }
      isCheckingOut.value = false;
    }

    function close() {
      store.commit('updateField', {
        path: 'showCheckedOutDialog',
        value: false,
      });
    }

    return {
      isOpen,
      takeOver,
      isCheckingOut,
      isLoading,
      user,
      close,
      checkedOutDate,
    };
  },
};
</script>

<style lang="scss" scoped></style>
