<template>
  <component
    :is="component"
    class="image-grid-item"
    :class="classes"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <RatioBox :ratio="1">
      <img :src="imageUrl" alt="" />
    </RatioBox>

    <div v-if="component === 'button'" class="image-grid-item__edit">
      <PencilIcon />
    </div>

    <div class="image-grid-item__top-icons">
      <span v-if="!isEnabled" class="image-grid-item__icon">
        <VisibilityOffIcon />
      </span>
    </div>
    <div class="image-grid-item__btm-icons">
      <span v-if="item.urls && item.urls.length" class="image-grid-item__icon">
        <LinkIcon />
      </span>
    </div>
  </component>
</template>

<script>
import {
  RatioBox,
  convertImageUrlToEncodedUrl,
  VisibilityOffIcon,
  LinkIcon,
  PencilIcon,
} from '@campsite-bio/component-lib';
import { computed, ref } from 'vue';

export default {
  components: {
    RatioBox,
    VisibilityOffIcon,
    LinkIcon,
    PencilIcon,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },

    linkId: {
      type: String,
      required: true,
    },

    component: {
      type: String,
      default: 'div',
    },
  },

  setup(props) {
    const imageUrl = computed(() => {
      return convertImageUrlToEncodedUrl(props.item.image.url);
    });

    const isEnabled = computed(() => {
      return props.item.enabled && props.item.urls && props.item.urls.length;
    });

    const classes = computed(() => {
      return {
        'image-grid-item--disabled': !isEnabled.value,
        'image-grid-item--link': props.component === 'button',
      };
    });

    return { imageUrl, classes, isEnabled };
  },
};
</script>

<style lang="scss" scoped>
.image-grid-item {
  background: none;
  border: none;
  color: #fff;
  display: block;
  padding: 0;
  position: relative;

  &--link {
    cursor: pointer;

    &:after {
      transition: all var(--g-transition-speed);
    }

    &:hover,
    &:active,
    &:focus {
      &:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border: 2px solid var(--g-color-primary);
      }

      .image-grid-item__edit {
        opacity: 1;
      }
    }
  }

  &--disabled {
    img {
      filter: grayscale(100%);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__edit {
    background: rgba(0, 0, 0, 0.1);
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all var(--g-transition-speed);
  }

  &__btm-icons,
  &__top-icons {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.5rem;
    padding: 0.5rem;
    pointer-events: none;
  }

  &__top-icons {
    top: 0;
    right: 0;
  }

  &__btm-icons {
    bottom: 0;
    right: 0;
  }

  &__icon {
    display: inline-flex;
    position: relative;

    &:before {
      content: '';
      background: rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      position: absolute;
      width: 150%;
      height: 150%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
