<template>
  <SvgIcon viewBox="0 0 32 32" fill="none" style="stroke-width: unset">
    <rect
      x="1.5"
      y="2.5"
      width="29"
      height="27"
      rx="1.5"
      fill="white"
      stroke="#BBB"
    ></rect>
    <rect
      x="6"
      y="10"
      width="20"
      height="12"
      rx="1"
      fill="var(--g-color-primary)"
      stroke="none"
    ></rect>
    <rect x="6" y="6" width="20" height="1" fill="#BBB" stroke="none"></rect>
    <rect x="6" y="25" width="20" height="1" fill="#BBB" stroke="none"></rect>
  </SvgIcon>
</template>

<script>
import { SvgIcon } from '@campsite-bio/component-lib';

export default {
  components: {
    SvgIcon,
  },
};
</script>

<style lang="scss" scoped></style>
