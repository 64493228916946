<template>
  <TabPanel class="panel" value="referrals">
    <ReferralsTable
      :is-loading="isLoading"
      :data="referralData"
      :has-results="hasResults"
      data-title="Clicks"
      hide-unique
      style="margin-top: 0"
    />
  </TabPanel>
</template>

<script>
import { TabPanel } from '@campsite-bio/component-lib';
import { computed } from 'vue';

import { ReferralsTable } from '../../analytics/tables';
import { convertReferrerToIcon } from '../../analytics';

export default {
  components: {
    TabPanel,
    ReferralsTable,
  },

  props: {
    period: {
      type: Number,
      required: true,
    },

    linkId: {
      type: String,
      required: true,
    },

    data: {
      type: Object,
      default: null,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const referralData = computed(() => {
      if (!props.data) return [];

      const stats = props.data.clicks_by_referral
        .filter((i) => i._id.range === 'current')
        .map((item) => {
          return [
            item._id.referral,
            item.count,
            0,
            convertReferrerToIcon(item._id.referral),
          ];
        });

      return stats.sort((a, b) => b[1] - a[1]);
    });

    const hasResults = computed(() => referralData.value.length > 0);

    return { referralData, hasResults };
  },
};
</script>

<style lang="scss" scoped>
.panel {
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 1rem;
}
</style>
