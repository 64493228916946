<template>
  <div class="media-inner">
    <div class="media-inner__toolbar">
      <FormGroup>
        <Label for="search">
          Icons by&nbsp;
          <a
            href="https://tablericons.com/?utm_source=Campsite.bio&utm_medium=referral"
            target="_blank"
            rel="noopener"
            >Tabler Icons</a
          ></Label
        >
        <Input id="search" v-model="query" placeholder="Search by keyword">
          <IconButton
            slot="prepend"
            aria-label="Search"
            :loading="isLoading"
            @click="searchDebounce"
          >
            <SearchIcon />
          </IconButton>
        </Input>
      </FormGroup>
      <Divider color="lightgray" />
    </div>
    <div ref="scrollRoot" class="media-inner__content">
      <InputBtnGroup v-model="selected" aria-label="Icons" size="xs">
        <InputBtn
          v-for="icon in results"
          :key="icon._id.$oid"
          :value="icon._id.$oid"
          :ratio="1"
          center-content
          :title="startCase(icon.name)"
        >
          <div slot="content" class="icon" v-html="icon.svg" />
          <span class="icon-name">
            {{ startCase(icon.name) | truncate(15) }}
          </span>
        </InputBtn>
      </InputBtnGroup>

      <div v-show="isLoading" class="text--xs-center">
        <CircularLoader size="2.5rem" style="margin: 1rem auto" />
      </div>
    </div>
    <div class="media-inner__actions">
      <Divider color="lightgray" />
      <CardActions>
        <Button :disabled="!selected" @click="save">Select</Button>
      </CardActions>
    </div>
  </div>
</template>

<script>
import {
  CardActions,
  Button,
  Divider,
  CircularLoader,
  FormGroup,
  Label,
  Input,
  SearchIcon,
  IconButton,
  InputBtnGroup,
  InputBtn,
} from '@campsite-bio/component-lib';
import { useInfiniteScroll } from '@vueuse/core';
import { useStore } from '@/compositions';
import debounce from 'lodash/debounce';
import { computed, ref, watch } from 'vue';
import startCase from 'lodash/startCase';

import toast from '@/utils/toast';
import { localAxios } from '@/apis';
import { getAppSetting } from '@/config';

export default {
  components: {
    CardActions,
    Button,
    Divider,
    CircularLoader,
    FormGroup,
    Label,
    Input,
    SearchIcon,
    IconButton,
    InputBtnGroup,
    InputBtn,
  },

  props: {
    show: Boolean,
  },

  setup(props, { emit }) {
    const scrollRoot = ref(null);
    const query = ref(null);
    const previousQuery = ref(null);
    const results = ref([]);
    const isLoading = ref(false);
    const hasMore = ref(false);
    const store = useStore();
    const page = ref(1);
    const pageSize = 100;
    const selected = ref(null);
    const weight = ref('default');
    const filled = ref(false);

    const selectedIcon = computed(() => {
      return results.value.find((icon) => icon._id.$oid === selected.value);
    });

    const isBusy = computed(() => {
      return isLoading.value || !hasMore.value;
    });

    useInfiniteScroll(scrollRoot, () => {
      if (isBusy.value) return;
      page.value++;
      search();
    });

    const showMediaDialog = computed(() => {
      return store.getters.showMediaDialog;
    });

    async function search() {
      if (isLoading.value) return;

      isLoading.value = true;

      try {
        const { data } = await localAxios.get('/api/assets/icons', {
          params: {
            page: page.value,
            page_size: pageSize,
            query: query.value,
          },
        });

        if (page.value === 1) results.value = data.icons;
        else results.value = [...results.value, ...data.icons];
        hasMore.value = data.has_more;
      } catch (e) {
        console.error(e);
        toast.error('There was an error getting icons. Please try again soon.');
      }

      isLoading.value = false;
    }

    const searchDebounce = debounce(search, 1000);

    watch(
      () => {
        return [showMediaDialog.value, props.show];
      },
      () => {
        if (showMediaDialog.value && props.show) search();
      },
      { immediate: true },
    );

    watch(query, () => {
      if (query.value !== previousQuery.value) page.value = 1;
      previousQuery.value = query.value;
      searchDebounce();
    });

    function save() {
      emit('select', {
        source: 'icon',
        url: selectedIcon.value.svg,
        media: selected.value,
      });

      localAxios.put(`/api/assets/icons/${selected.value}/download`);

      selected.value = null;
    }

    return {
      scrollRoot,
      query,
      results,
      isLoading,
      selected,
      searchDebounce,
      save,
      startCase,
    };
  },
};
</script>

<style lang="scss" scoped>
.icon {
  height: 100%;
  width: 100%;

  &::v-deep .icon {
    height: 100%;
    width: 100%;
    stroke-width: 2;
    fill: none;
    color: currentColor;
    padding: 0.5rem;
  }
}

.icon-name {
  display: block;
  font-size: 0.75rem;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
