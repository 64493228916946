<template>
  <div class="form-actions">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FormActions',
};
</script>

<style lang="scss" scoped></style>
