import { numberFormatter } from '@campsite-bio/component-lib';

export function formatTooltipLabels({ dataset, raw }) {
  let label = dataset.label || '';
  if (label) {
    label += ': ';
  }
  if (raw !== null) {
    label += numberFormatter(raw, 2);
  }

  return label;
}
