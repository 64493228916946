<template>
  <ul class="breadcrumbs">
    <li class="breadcrumbs__ancestor">
      <a href="#" @click.prevent="parent = undefined">Media</a>
      <span class="breadcrumbs__seperator">/</span>
    </li>
    <li v-for="(item, i) in path" :key="i" class="breadcrumbs__ancestor">
      <a href="#" :title="item.name" @click.prevent="parent = item.id">{{
        item.name
      }}</a>
      <span class="breadcrumbs__seperator">/</span>
    </li>
    <li v-if="path.length < 2" class="breadcrumbs__ancestor">
      <InlineLink
        v-if="!showFolderInput"
        aria-label="Add folder"
        @click="focusFolderInput"
      >
        <PlusIcon
          size="1.25rem"
          title="Add folder"
          style="vertical-align: bottom"
        />
      </InlineLink>
      <div v-else class="input-addon breadcrumbs__input-addon">
        <div class="input-addon__field">
          <input
            id="new-folder"
            ref="folderInput"
            v-model="folderName"
            type="text"
            :disabled="isFolderLoading"
            maxlength="20"
            placeholder="Name"
            aria-label="Folder name"
            @blur="inputBlur"
            @keydown.enter="addFolder"
          />
        </div>
        <Button
          class="input-addon__btn"
          :loading="isFolderLoading"
          @click="addFolder"
          >Add</Button
        >
      </div>
    </li>
  </ul>
</template>

<script>
import { Button, InlineLink, PlusIcon } from '@campsite-bio/component-lib';
import toast from '../../utils/toast';

export default {
  components: {
    Button,
    InlineLink,
    PlusIcon,
  },

  props: {
    path: {
      type: Array,
      default() {
        return [];
      },
    },

    value: String,
    category: String,

    mediaParentId: {
      type: String,
      required: true,
    },

    organizationId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      parent: undefined,
      showFolderInput: false,
      folderName: '',
      isFolderLoading: false,
    };
  },

  watch: {
    value(newValue) {
      this.parent = newValue;
    },

    parent(newValue) {
      this.$emit('input', newValue);
    },
  },

  created() {
    this.parent = this.value;
  },

  methods: {
    async addFolder() {
      if (!this.folderName) {
        return;
      }

      this.isFolderLoading = true;
      window.clearTimeout(this.timeout);
      try {
        const media = await this.$store.dispatch('media/createFolder', {
          data: {
            name: this.folderName.trim(),
            parent: this.parent,
            category: this.category,
          },
          organization_id: this.organizationId,
          id: this.mediaParentId,
        });

        this.folderName = '';
        this.showFolderInput = false;
        this.parent = media._id.$oid;
      } catch (e) {
        console.log(e);
        toast.error('There was an issue adding the new folder');
      }

      this.isFolderLoading = false;
    },

    focusFolderInput() {
      this.showFolderInput = true;
      window.clearTimeout(this.timeout);
      this.$nextTick().then(() => {
        this.$refs.folderInput.focus();
      });
    },

    inputBlur() {
      this.timeout = setTimeout(() => {
        this.showFolderInput = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-addon {
  display: flex;

  .input-addon__btn {
    border-radius: 0 var(--g-border-radius-standard)
      var(--g-border-radius-standard) 0;
    height: 2.625rem;
    flex-shrink: 0;
  }
}

.input-addon__field {
  flex-grow: 1;
  min-width: 0;
}

.breadcrumbs {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.breadcrumbs__ancestor {
  display: flex;
  margin: 3px 0;

  a {
    color: var(--g-color-gray-700);
    display: block;
    max-width: 115px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }
}

.breadcrumbs__seperator {
  position: relative;
  top: 1px;
  margin-left: 5px;
  margin-right: 5px;
  color: #bbbabf;
}

.breadcrumbs__input-addon {
  input {
    background: #fff;
    border: 1px solid var(--g-color-gray-200);
    border-radius: var(--g-border-radius-standard) 0 0
      var(--g-border-radius-standard);
    font-size: 16px;
    height: 30px;
    margin-top: -2px;
    margin-left: 3px;
    padding: 0 5px;
    width: 100px;
  }

  .input-addon__btn {
    height: 28px;
    line-height: 28px;
    padding: 0 8px;
  }
}
</style>
