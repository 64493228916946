<template>
  <LinkExpander :id="`stats-${id}`" :is-open="isOpen" @close="$emit('close')">
    <template slot="header">Link analytics</template>
    <LinkStats v-if="!isFreePlan" class="link__chart-container" />
    <div v-else class="text--xs-center">
      <p class="no-margin-btm">
        This link has been clicked
        {{ link.number_of_clicks | numberFormatter(1) }} times.
      </p>
      <p class="add-margin-btm-small">
        To see a day-by-day breakdown of clicks, upgrade to pro.
      </p>
      <Button size="small" to="/account/change-plan">Find out more</Button>
    </div>
  </LinkExpander>
</template>

<script>
import { Button } from '@campsite-bio/component-lib';
import LinkExpander from './link-expander.vue';
import LinkStats from '../link-stats.vue';
import { useStore } from '../../../compositions';
import { computed, inject } from 'vue';

export default {
  components: {
    LinkExpander,
    Button,
    LinkStats,
  },

  props: {
    isOpen: Boolean,
  },

  setup(props) {
    const store = useStore();
    const { link, id } = inject('link');

    const isFreePlan = computed(() => {
      return store.getters['profiles/isProfileOnFreePlan'];
    });

    return { id, isFreePlan, link };
  },
};
</script>

<style lang="scss" scoped></style>
