<template>
  <div class="box-input__font" :style="{ fontWeight: weight }">
    {{ label }}
  </div>
</template>

<script>
export default {
  props: {
    weight: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      default: 'Aa',
    },
  },
};
</script>

<style lang="scss" scoped>
.box-input__font {
  font-size: 28px;
  line-height: 1.1;
  margin-bottom: 3px;
}
</style>
