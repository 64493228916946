<template>
  <Story ref="root" v-bind="$props" :style="styles" @on-ready="start">
    <slot slot="header" name="header" />
    <slot slot="footer-right" name="footer-right" />

    <div class="stat">
      <div class="stat__number">{{ currentStat }}{{ appendToStat }}</div>
      <div class="stat__text">{{ label }}</div>
      <div class="stat__spark">
        <VSparkline
          v-if="pastChartValues.length"
          :value="pastChartValues"
          :color="color"
          smooth
          line-width="2"
          padding="2"
          type="trend"
        />
        <VSparkline
          :value="chartValues"
          :color="color"
          smooth
          line-width="3"
          padding="2"
          type="trend"
        />
      </div>
    </div>
  </Story>
</template>

<script>
import { computed, ref } from 'vue';
import { useTheme, getThemeColor } from '@campsite-bio/component-lib';
import { VSparkline } from 'vuetify/lib';
import anime from 'animejs';
import tinycolor from 'tinycolor2';

import Story from './story';
import { useTimelineWithStory } from './use-timeline-with-story';

export default {
  components: {
    Story,
    VSparkline,
  },

  props: {
    stat: {
      type: Number,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    chartValues: {
      type: Array,
      default: () => [],
    },

    pastChartValues: {
      type: Array,
      default: () => [],
    },

    appendToStat: {
      type: String,
      default: '',
    },

    color: {
      type: String,
      default: '#fff',
    },

    isPaused: Boolean,
    static: Boolean,

    ...Story.props,
  },

  setup(props) {
    const currentStat = ref(0);
    const isComplete = ref(false);
    const theme = useTheme();

    const styles = computed(() => {
      const color = getThemeColor(props.color, theme.value);
      return {
        '--c-color': color,
        '--c-secondary-graph-color': tinycolor(color)
          .setAlpha(0.2)
          .toRgbString(),
      };
    });

    const { root, timeline, onMount } = useTimelineWithStory(props);

    function formatStat(value) {
      currentStat.value = value.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      });
    }

    async function start() {
      const { $el } = root.value;
      const target = {
        stat: currentStat.value,
      };

      timeline.add({
        targets: target,
        stat: props.stat,
        duration: 2000,
        update() {
          if (!isComplete.value) formatStat(Math.floor(target.stat));
        },
        complete() {
          isComplete.value = true;
          formatStat(props.stat);
        },
      });

      timeline.add(
        {
          targets: $el.querySelector('.stat__text'),
          duration: 1000,
          opacity: [0, 1],
        },
        '-=1000',
      );

      timeline.add(
        {
          targets: $el.querySelectorAll('.stat__spark svg path'),
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 2000,
        },
        '-=1000',
      );

      onMount();

      if (props.static) {
        formatStat(props.stat);
      }
    }

    return { root, styles, start, currentStat };
  },
};
</script>

<style lang="scss" scoped>
.stat {
  color: var(--c-color);

  &__number {
    font-size: 2.28em;
    font-family: var(--g-font-header-family);
    line-height: 1;
    margin-bottom: 0.05em;
  }

  &__text {
    max-width: 14.25em;
    font-size: 1em;
  }

  &__spark {
    --ng-padding-side: calc(var(--padding-side) * -1);
    margin-left: calc(var(--ng-padding-side) - 3px);
    margin-right: calc(var(--ng-padding-side) - 3px);
    margin-top: 2.28em;
    position: relative;

    svg:first-child:not(:last-child) {
      color: var(--c-secondary-graph-color) !important;
      fill: var(--c-secondary-graph-color) !important;
      stroke-dasharray: 10 10;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
