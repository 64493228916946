<template>
  <div class="date-bar">
    <DateSelector
      v-model="localDates"
      class="date-bar__selector"
      :available-periods="availablePeriods"
      :min-date="minDate"
      :disabled="disabled"
      :start-date="startDate"
      :end-date="endDate"
      :can-choose-custom="canChooseCustom"
    />

    <div v-if="!hideCompare" class="date-bar__end">
      <span>compared to</span>

      <PeriodSelector
        v-model="localPeriod"
        :start-date="compareStartDate"
        :end-date="compareEndDate"
        disabled
      />
    </div>
  </div>
</template>

<script>
import { toRefs } from 'vue';

import { useModel } from '../../../../compositions';
import DateSelector from './date-selector';
import PeriodSelector from './period-selector';

export default {
  components: {
    DateSelector,
    PeriodSelector,
  },

  props: {
    dates: {
      type: [String, Array],
      required: true,
    },

    startDate: {
      type: String,
      default: null,
    },

    endDate: {
      type: String,
      default: null,
    },

    availablePeriods: {
      type: Array,
      default: null,
    },

    compareStartDate: {
      type: String,
      default: null,
    },

    compareEndDate: {
      type: String,
      default: null,
    },

    period: {
      type: [String, Array],
      default: null,
    },

    disabled: Boolean,
    hideCompare: Boolean,
    canChooseCustom: Boolean,

    minDate: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { dates, period } = toRefs(props);

    const localDates = useModel(dates, (val) => emit('update:dates', val));
    const localPeriod = useModel(period, (val) => emit('update:period', val));

    return { localDates, localPeriod };
  },
};
</script>

<style lang="scss" scoped>
.date-bar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  font-size: 0.825rem;

  &__end {
    align-items: inherit;
    display: inherit;
    gap: inherit;
  }
}
</style>
