<template>
  <ChartCard
    class="clicks-card"
    title="Projected stats"
    :is-loading="isLoading"
    :highlights="currentTotals"
  >
    <ChartWrapper
      width="100%"
      height="300"
      type="line"
      :options="chartOptions"
      :data="series"
    />
  </ChartCard>
</template>

<script>
import { computed } from 'vue';
import { DateTime } from 'luxon';

import { ChartCard } from '../';
import { useApexOptions, useDataColors } from '../../compositions';
import { ChartWrapper } from '../../';

export default {
  components: {
    ChartWrapper,
    ChartCard,
  },

  props: {
    allData: {
      type: Object,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const dataColors = useDataColors();

    // Take regular data and projected data and combine them together
    function combineDataWithProjections(data, projectedData) {
      const combinedData = [...data];
      const lastDate = DateTime.fromISO(
        combinedData[combinedData.length - 1].date,
      );
      projectedData.forEach((projected) => {
        const date = DateTime.fromISO(projected.date);
        if (date > lastDate) {
          combinedData.push({ ...projected, projected: true });
        }
      });
      return combinedData;
    }

    const viewsData = computed(() => {
      return combineDataWithProjections(
        props.data.views,
        props.data.views_projection,
      );
    });

    const clicksData = computed(() => {
      return combineDataWithProjections(
        props.data.clicks,
        props.data.clicks_projection,
      );
    });

    const eventsData = computed(() => {
      return combineDataWithProjections(
        props.data.events,
        props.data.events_projection,
      );
    });

    const categories = computed(() => {
      return viewsData.value.map((stat) => {
        return DateTime.fromISO(stat.date).toFormat('MMM d');
      });
    });

    const series = computed(() => {
      const views = {
        label: 'Views',
        data: viewsData.value
          .filter((s) => !s.projected)
          .map((stat) => stat.count),
        pointBackgroundColor: dataColors.value[0],
        pointBorderColor: dataColors.value[0],
      };
      const clicks = {
        label: 'Clicks',
        data: clicksData.value
          .filter((s) => !s.projected)
          .map((stat) => stat.count),
        pointBackgroundColor: dataColors.value[1],
        pointBorderColor: dataColors.value[1],
      };
      const events = {
        label: 'Events',
        data: eventsData.value
          .filter((s) => !s.projected)
          .map((stat) => stat.count),
        pointBackgroundColor: dataColors.value[2],
        pointBorderColor: dataColors.value[2],
      };
      const viewsProjected = {
        label: 'Projected Views',
        data: viewsData.value.map((stat) => stat.count),
        backgroundColor: dataColors.value[0],
        borderColor: dataColors.value[0],
        pointBackgroundColor: dataColors.value[0],
        pointBorderColor: dataColors.value[0],
        borderDash: [5, 5],
      };
      const clicksProjected = {
        label: 'Projected Clicks',
        data: clicksData.value.map((stat) => stat.count),
        backgroundColor: dataColors.value[1],
        borderColor: dataColors.value[1],
        pointBackgroundColor: dataColors.value[1],
        pointBorderColor: dataColors.value[1],
        borderDash: [5, 5],
      };
      const eventsProjected = {
        label: 'Projected Views',
        data: eventsData.value.map((stat) => stat.count),
        backgroundColor: dataColors.value[2],
        borderColor: dataColors.value[2],
        borderDash: [5, 5],
        pointBackgroundColor: dataColors.value[2],
        pointBorderColor: dataColors.value[2],
      };
      return {
        datasets: [
          views,
          clicks,
          events,
          viewsProjected,
          clicksProjected,
          eventsProjected,
        ],
        labels: categories.value,
      };
    });

    const forecastDataPoints = computed(() => {
      return {
        count: Math.max(
          0,
          viewsData.value.filter((v) => v.projected).length - 1,
        ),
      };
    });

    const chartOptions = useApexOptions({
      elements: {
        point: {
          radius: 0,
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
        },
      },
    });

    const currentTotals = computed(() => {
      return [
        {
          label: 'Views',
          data: viewsData.value,
        },
        {
          label: 'Clicks',
          data: clicksData.value,
        },
        {
          label: 'Events',
          data: eventsData.value,
        },
      ].reduce((accum, { label, data }, i) => {
        accum.push({
          title: Math.round(data[data.length - 1].count),
          subtitle: label,
          color: dataColors.value[i],
        });
        return accum;
      }, []);
    });

    return {
      chartOptions,
      series,
      currentTotals,
    };
  },
};
</script>

<style lang="scss" scoped>
.clicks-card {
  min-height: unset;
}
</style>
