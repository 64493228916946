import { ref, watch } from 'vue';

export function useModel(value, onChange, options = {}) {
  const { watchOptions = {} } = options;
  const localValue = ref(null);

  watch(
    value,
    (val) => {
      localValue.value = val;
    },
    { immediate: true, ...watchOptions },
  );

  watch(
    localValue,
    (val) => {
      onChange(val);
    },
    watchOptions,
  );

  return localValue;
}
