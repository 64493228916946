export * from './user-access';
export * from './currency';
export * from './taxes';
export * from './providers';
export * from './members';

export const PLATFORM_FEES = {
  paypal: 0.026,
  square: 0.019,
};

export const PROVIDER_NAME_MAP = {
  paypal: 'PayPal',
  square: 'Square',
  instagram: 'Instagram',
  facebook: 'Facebook',
  facebook_business: 'Facebook',
  twitter: 'Twitter',
  google: 'Google',
  google_sheets: 'Google',
  google_analytics_4: 'Google',
  shopify: 'Shopify',
};

export const AUTHORIZATION_STATUS = {
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
};

export default {
  API_BASE_URL: 'https://api.campsite.bio',
  AWS_LAMBDA_GETSIGNEDURL_ENDPOINT:
    'https://klx7ojxko6.execute-api.us-west-2.amazonaws.com/prod/s3upload',
  AWS_LAMBDA_UPLOADURL_ENDPOINT:
    'https://0dvd9dhlaj.execute-api.us-west-2.amazonaws.com/Prod/asset_url_thumbify',
  AWS_LAMBDA_BACKGROUNDIMAGE_ENDPOINT:
    'https://1g90k3rdx0.execute-api.us-west-2.amazonaws.com/Prod/bg_thumb_job',
  AWS_LAMBDA_LINKCLICKS_ENDPOINT:
    'https://1g90k3rdx0.execute-api.us-west-2.amazonaws.com/Prod/link_clicks',
  AWS_X_API_KEY: 'YnoLlZxAwO6adorYSBgalaaVN9BnqsdC9zhudxge',
  ASSET_URL: 'https://cdn.campsite.bio/',
  CANVA_API_KEY: '_u5eOcNvor5Nvb6i6SuWez2_',
  BASE_URL: 'https://campsite.bio',
  RECAPTCHA_SITE_KEY: '6LeADHgUAAAAAMRMHfe0TJ8md1AODA40hl7EDpkC',
};
