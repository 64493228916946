import { captureException } from '@sentry/browser';
import { unref } from 'vue';

import { localAxios } from '../../apis';

export function useActivityLogger(endpoint) {
  async function log(type, data) {
    try {
      await localAxios.post(unref(endpoint), {
        type,
        data,
      });
    } catch (e) {
      console.error(e);
      captureException(e);
    }
  }

  return log;
}
