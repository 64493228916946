<template>
  <LinkExpander :id="`default-${id}`" :is-open="isOpen" @close="$emit('close')">
    <template slot="header"> Divider options </template>

    <FormGroup :gutter-bottom="false">
      <Label component="span" block gutter-bottom>Divider height</Label>
      <ChipGroup v-model="label" aria-label="divider height">
        <Chip value="small" selectable>Small</Chip>
        <Chip value="medium" selectable>Medium</Chip>
        <Chip value="large" selectable>Large</Chip>
      </ChipGroup>
    </FormGroup>
  </LinkExpander>
</template>

<script>
import { FormGroup, Label, ChipGroup, Chip } from '@campsite-bio/component-lib';
import { inject } from 'vue';

import LinkExpander from '../expand/link-expander';

export default {
  components: {
    FormGroup,
    LinkExpander,
    Label,
    Chip,
    ChipGroup,
  },

  props: {
    isOpen: Boolean,
  },

  setup() {
    const { id, label } = inject('link');

    return {
      id,
      label,
    };
  },
};
</script>

<style lang="scss" scoped></style>
