import { localAxios } from '../apis/';

export default {
  state: {
    fontFamilies: [],
    themes: [],
  },

  getters: {
    fontFamilies: (state) => state.fontFamilies,
    themes: (state) => state.themes,
  },

  mutations: {
    setFontFamilies(state, fontFamilies) {
      state.fontFamilies = fontFamilies;
    },

    setThemes(state, themes) {
      state.themes = themes;
    },
  },

  actions: {
    getFontFamilies({ getters, commit }) {
      if (!getters.fontFamilies.length) {
        return localAxios.get('/api/assets/fonts/all').then(({ data }) => {
          commit('setFontFamilies', data);
          return data;
        });
      } else {
        return Promise.resolve(getters.fontFamilies);
      }
    },

    getThemes({ getters, commit }) {
      if (!getters.themes.length) {
        return localAxios.get('/api/app/themes').then(({ data }) => {
          commit('setThemes', data);
          return data;
        });
      } else {
        return Promise.resolve(getters.themes);
      }
    },
  },
};
