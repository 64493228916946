import { computed, unref } from 'vue';

import { USER_ACTIVITY_TYPES } from '../../utils/log';
import { useActivityLogger } from './use-activity-logger';

export function useUserLogger(userId) {
  // Make sure userId is passed
  if (!userId) {
    throw new Error('User ID is required');
  }

  const endpoint = computed(() => `/api/users/${unref(userId)}/log`);
  const activityLog = useActivityLogger(endpoint);

  async function log(type, data) {
    // Make sure type is valid
    if (!Object.values(USER_ACTIVITY_TYPES).includes(type)) {
      throw new Error(`Invalid activity type: ${type}`);
    }

    await activityLog(type, data);
  }

  return log;
}
