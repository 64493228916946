<template>
  <Fragment>
    <RankStatCard
      v-for="{ count, rank, key, name } in highlights"
      :key="key"
      class="span-4"
      :title="name"
      :is-loading="isLoading"
      :rank="rank"
    >
      {{ count | numberFormatter(2) }}
    </RankStatCard>
  </Fragment>
</template>

<script>
import { computed } from 'vue';
import { Fragment } from '@campsite-bio/component-lib';

import { RankStatCard } from '../';

const HIGHLIGHT_FIELDS = [
  { name: 'Views', key: 'views' },
  { name: 'Reach', key: 'reach' },
  { name: 'Clicks', key: 'clicks' },
  { name: 'Events', key: 'events' },
  { name: 'Action Score', key: 'action_score' },
];

export default {
  components: {
    Fragment,
    RankStatCard,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    isLoading: Boolean,
  },

  setup(props) {
    const highlights = computed(() => {
      return HIGHLIGHT_FIELDS.reduce((accum, { key, ...rest }) => {
        const current = props.data[key].find(
          (item) => item.previous_week || item.previous_month,
        );
        accum.push({
          count: current.count,
          rank: current.rank,
          key,
          ...rest,
        });
        return accum;
      }, []);
    });

    return {
      highlights,
    };
  },
};
</script>

<style lang="scss" scoped></style>
