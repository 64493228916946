<template>
  <div class="media-inner">
    <div class="media-inner__toolbar">
      <FormGroup>
        <Label for="search">
          <span
            >Photos by
            <a
              href="https://unsplash.com/?utm_source=campsite&utm_medium=referral&utm_campaign=api-credit"
              target="_blank"
              >Unsplash</a
            ></span
          >
        </Label>
        <Input
          id="search"
          v-model="query"
          placeholder="Search photos by keyword"
        >
          <IconButton
            slot="prepend"
            aria-label="Search"
            :loading="loading"
            @click="searchDebounce"
          >
            <SearchIcon />
          </IconButton>
        </Input>
      </FormGroup>
      <Divider color="lightgray" />
    </div>
    <div
      v-infinite-scroll="nextPage"
      class="media-inner__content"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="100"
      infinite-scroll-immediate-check="false"
    >
      <div class="media-items-row row row--no-gutters">
        <div
          v-for="(image, index) in results"
          :key="image.id"
          class="col col--xs-6 col--no-gutters"
        >
          <v-media-item
            ref="mediaItem"
            :checked="selected && selected.id == image.id"
            :index="index"
            :url="image.urls.small"
            :alt="image.description"
            :caption="getCaption(image)"
            @select="selected = image"
            @double-click="doubleClick(image, index)"
          />
        </div>
      </div>

      <div v-show="loading" class="text--xs-center">
        <CircularLoader size="2.5rem" style="margin: 15px auto" />
      </div>
    </div>
    <div class="media-inner__actions">
      <Divider color="lightgray" />
      <CardActions>
        <Button :disabled="!selected" @click="save">Select</Button>
      </CardActions>
    </div>
  </div>
</template>

<script>
import {
  CardActions,
  Button,
  Divider,
  CircularLoader,
  FormGroup,
  Label,
  Input,
  SearchIcon,
  IconButton,
} from '@campsite-bio/component-lib';
import VMediaItem from './media-item.vue';
import { search, collectionPhotos, downloadPhoto } from '../../apis/unsplash';
import debounce from 'lodash/debounce';
import toast from '../../utils/toast';

export default {
  components: {
    VMediaItem,
    CardActions,
    Button,
    Divider,
    CircularLoader,
    FormGroup,
    Label,
    Input,
    SearchIcon,
    IconButton,
  },

  props: {
    show: Boolean,
  },

  data() {
    return {
      query: '',
      previousQuery: '',
      page: 1,
      pageSize: 30,
      totalPages: 0,
      results: [],
      selected: undefined,
      loading: true,
    };
  },

  computed: {
    busy() {
      return this.loading || this.page === this.totalPages;
    },

    showMediaDialog() {
      return this.$store.getters.showMediaDialog;
    },
  },

  watch: {
    query() {
      if (this.query !== this.previousQuery) this.resetPaging();
      this.previousQuery = this.query;

      this.searchDebounce();
    },

    page() {
      this.searchDebounce();
    },

    show(newValue) {
      if (newValue) this.search();
    },

    showMediaDialog(newValue) {
      if (newValue && this.show) this.search();
    },
  },

  created() {
    this.searchDebounce = debounce(this.search, 1000);
    this.search();
  },

  methods: {
    getCaption(image) {
      return `<a href="${image.user.links.html}?utm_source=campsite&utm_medium=referral&utm_campaign=api-credit" target="_blank">${image.user.name}</a>`;
    },

    resetPaging() {
      this.page = 1;
    },

    search() {
      this.loading = true;
      if (this.query) {
        search(this.query, this.page)
          .then((data) => {
            this.loading = false;
            this.totalPages = data.total_pages;
            if (this.page === 1) this.results = data.results;
            else this.results = this.results.concat(data.results);
          })
          .catch((err) => {
            toast.error(
              'There was an error getting images from unsplash. Please try again soon.',
              { timeout: 3000 },
            );
          });
      } else {
        collectionPhotos(317099, this.page)
          .then((data) => {
            this.loading = false;
            if (data.length === this.pageSize) this.totalPages = 999;
            else this.totalPages = this.page;
            if (this.page === 1) this.results = data;
            else this.results = this.results.concat(data);
          })
          .catch((err) => {
            toast.error(
              'There was an error getting images from unsplash. Please try again soon.',
              { timeout: 3000 },
            );
          });
      }
    },

    nextPage() {
      this.page++;
    },

    // if the user is clicking the same image twice, select the image
    doubleClick: function (item, index) {
      if (this.$refs.mediaItem[index].checked) {
        this.selected = item;
        this.save();
      }
    },

    save() {
      this.$emit('select', {
        source: 'unsplash',
        url: this.selected.urls.thumb,
        media: this.selected,
      });

      downloadPhoto(this.selected.links.download_location);
      this.selected = undefined;
    },
  },
};
</script>

<style scoped></style>
