<template>
  <div class="input-url">
    <Label :for="'url' + _uid" :class="labelClasses">{{ label }}</Label>
    <Input
      :id="'url' + _uid"
      v-model="url"
      class="input-url__url js-link-url"
      autocomplete="off"
      :placeholder="placeholder"
      :border-color="borderColor"
      maxlength="500"
      :disabled="disabled"
      :has-error="hasError"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
    >
      <template slot="prepend">
        <IconButton
          v-clipboard
          :data-clipboard-text="value"
          tabindex="-1"
          aria-label="Copy url"
        >
          <LinkIcon title="Copy url" />
        </IconButton>
        <select
          :id="'protocol' + _uid"
          ref="protocol"
          v-model="protocol"
          class="input-url__protocol"
          :disabled="disabled"
          aria-label="URL protocol"
        >
          <option value="http://">http://</option>
          <option value="https://">https://</option>
          <option value="mailto:">mailto:</option>
          <option value="tel:">tel:</option>
          <option value="sms:">sms:</option>
        </select>
      </template>
    </Input>
  </div>
</template>

<script>
import {
  Input,
  IconButton,
  LinkIcon,
  Label,
} from '@campsite-bio/component-lib';
import debounce from 'lodash/debounce';

export default {
  components: {
    Input,
    IconButton,
    LinkIcon,
    Label,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
    disabled: Boolean,
    label: String,
    borderColor: String,
    showLabel: Boolean,
    hasError: Boolean,
  },

  data() {
    return {
      url: '',
      protocol: 'http://',
      originalProtocol: '',
    };
  },

  computed: {
    placeholder() {
      if (this.protocol === 'mailto:') return 'support@campsite.bio';
      else if (this.protocol === 'tel:' || this.protocol === 'sms:')
        return '123-123-1234';
      return 'Add a link to any web page';
    },

    labelClasses() {
      return {
        'sr-only': !this.showLabel,
      };
    },
  },

  watch: {
    value() {
      this.parseUrl();
    },

    url() {
      this.checkUrl();
    },

    protocol() {
      this.save();
    },
  },

  created() {
    this.checkUrl = debounce(this.checkUrl, 500);
    this.protocolRegex = new RegExp(/^(https?:\/\/|mailto:|tel:|sms:)/, 'i');
    if (this.value) {
      this.parseUrl();
    }
  },

  methods: {
    save() {
      if (this.url) {
        this.$emit('input', this.protocol + this.url);
      } else {
        this.$emit('input', '');
      }
    },

    parseUrl() {
      const value = this.value ? this.value.trim() : '';
      const protocols = value.match(this.protocolRegex);

      // set protocol
      if (protocols && protocols.length) {
        this.protocol = protocols[0];
      }

      this.url = value.replace(this.protocolRegex, '');
    },

    checkUrl() {
      this.url = this.url.trim();
      if (this.url) {
        const value = this.url.trim();
        const protocols = value.match(this.protocolRegex);

        // if a protocol is detected in url field
        // remove it and save
        if (protocols && protocols.length) {
          this.protocol = protocols[0];
          this.url = value.replace(this.protocolRegex, '');
        }
      }

      this.save();
    },
  },
};
</script>

<style lang="scss" scoped>
.input-url {
  position: relative;
}

select.input-url__protocol {
  background: var(--ci-color-bg-focus);
  color: #222;
  appearance: none;
  cursor: pointer;
  border-radius: 0;
  border: none;
  left: 1.875rem;
  padding: 0 0.3125rem !important;
  margin-left: 0.6em;
  margin-right: -0.5rem;
  text-align: center;
  height: 100%;
  width: 4.3125rem;

  &::-ms-expand {
    display: none;
  }

  option {
    color: #222;
  }
}
</style>
