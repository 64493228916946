<template>
  <v-dialog v-model="localValue" max-width="400">
    <Card>
      <CardTitle>
        <VText component="h2" variant="h4">Edit Filter</VText>
        <FlexSpacer />
        <IconButton size="1.25rem" aria-label="Close" @click="close">
          <CloseIcon />
        </IconButton>
      </CardTitle>
      <Divider color="gray200" />

      <SingleSelector
        v-if="editingFilter"
        :filter-label="editingFilter.filter.name"
        :filter-set="currentFilterSet"
        :operator="filterOperator"
        :values="filterValue"
        @add="addFilter"
        @cancel="close"
      />
    </Card>
  </v-dialog>
</template>

<script>
import {
  Card,
  CardTitle,
  Divider,
  VText,
  FlexSpacer,
  IconButton,
  CloseIcon,
} from '@campsite-bio/component-lib';
import { computed, ref, toRefs, watch } from 'vue';

import { useModel } from '../../../../compositions';
import SingleSelector from './single-selector';
import { DEFAULT_FILTER_OPERATORS } from '../../filters';

export default {
  components: {
    Card,
    CardTitle,
    Divider,
    VText,
    FlexSpacer,
    IconButton,
    CloseIcon,
    SingleSelector,
  },

  props: {
    value: Boolean,

    filters: {
      type: Object,
      default: null,
    },

    editingFilter: {
      type: Object,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { value, filters } = toRefs(props);
    const localValue = useModel(value, (val) => emit('input', val));
    const filterOperator = ref(null);
    const filterValue = ref(null);

    const formattedFilters = computed(() => {
      if (!props.filters) {
        return [];
      }

      return Object.keys(props.filters).map((key) => {
        const filter = props.filters[key];

        return {
          name: key,
          path: filter.path,
        };
      });
    });

    const currentFilterSet = computed(() => {
      if (!filters.value) return [];

      const matchedFilters = filters.value[props.editingFilter.filter.name];
      return matchedFilters ? matchedFilters.values : [];
    });

    watch(localValue, (newValue) => {
      if (newValue && props.editingFilter) {
        filterOperator.value = props.editingFilter.operator.value;
        filterValue.value = [...props.editingFilter.value];
      }
    });

    function close() {
      localValue.value = false;
    }

    function addFilter({ operator, value }) {
      const selectedOperator = DEFAULT_FILTER_OPERATORS.find(
        (o) => o.value === operator,
      );

      emit('update', {
        ...props.editingFilter,
        operator: selectedOperator,
        value,
      });

      close();
    }

    return {
      localValue,
      close,
      filterOperator,
      filterValue,
      addFilter,
      currentFilterSet,
      formattedFilters,
    };
  },
};
</script>

<style lang="scss" scoped></style>
