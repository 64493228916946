import ajax from './';
const baseUrl = 'https://api.unsplash.com';
const clientId =
  '1ed928d793288e7c1de40968e300560becd0bf789a5197458d09d0f8c324aa2e';

export function search(query, page = 1, per_page = 30) {
  return ajax.get(
    `${baseUrl}/search/photos`,
    {
      query,
      page,
      per_page,
    },
    {
      headers: {
        authorization: `Client-ID ${clientId}`,
      },
      credentials: 'none',
    },
  );
}

export function collectionPhotos(
  id,
  page = 1,
  per_page = 30,
  order_by = 'latest',
) {
  return ajax.get(
    `${baseUrl}/collections/${id}/photos`,
    {
      page,
      per_page,
      order_by,
    },
    {
      headers: {
        authorization: `Client-ID ${clientId}`,
      },
      credentials: 'none',
    },
  );
}

export function downloadPhoto(url) {
  return ajax.get(
    url,
    {},
    {
      headers: {
        authorization: `Client-ID ${clientId}`,
      },
      credentials: 'none',
    },
  );
}
