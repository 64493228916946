import debounce from 'lodash/debounce';
import store from '../store';

function parseClientVersion(version) {
  if (!version) return [];
  return version.split('.');
}

function sumVersion(version) {
  const parsedVersion = parseClientVersion(version);
  return parsedVersion.reduce((accum, int) => accum + parseInt(int, 10), 0);
}

function isLowerVersion(prevVersion, nextVersion) {
  const prevTotal = sumVersion(prevVersion);
  const nextTotal = sumVersion(nextVersion);

  return nextTotal < prevTotal;
}

export const setClientVersion = debounce((nextVersion) => {
  if (!nextVersion) return;

  const prevVersion = store.getters.clientVersion;

  if (!prevVersion) {
    store.commit('setClientVersion', nextVersion);
    return;
  }

  // Only update the client version if it's actually new
  // It has to be greater than the current version or the same
  if (!isLowerVersion(prevVersion, nextVersion)) {
    store.commit('setClientVersion', nextVersion);
  }
}, 2000);
