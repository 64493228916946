<template>
  <svg viewBox="0 0 1000 1000">
    <rect class="cls-1" x="38.5" y="69" width="923" height="152" rx="5" />
    <rect class="cls-1" x="38.5" y="246.5" width="923" height="152" rx="5" />
    <rect class="cls-1" x="38.5" y="424" width="923" height="152" rx="5" />
    <rect class="cls-1" x="38.5" y="601.5" width="923" height="152" rx="5" />
    <rect class="cls-1" x="38.5" y="779" width="923" height="152" rx="5" />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #ccc;
}
</style>
