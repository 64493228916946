<template>
  <div v-if="hasResults || isLoading" :style="styles">
    <ChartWrapper
      width="100%"
      height="225"
      type="doughnut"
      :options="chartOptions"
      :data="series"
    />

    <SimpleTable gutter-top fixed-header height="208">
      <thead>
        <tr>
          <th><span class="sr-only">Device</span></th>
          <th class="text-right">{{ dataTitle }}</th>
          <th v-if="!hideUnique" class="text-right">Unique</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="isLoading && !hasResults">
          <tr v-for="i in 4" :key="i">
            <td><Skeleton width="120px" /></td>
            <td class="text-right"><Skeleton width="30px" /></td>
            <td v-if="!hideUnique" class="text-right">
              <Skeleton width="30px" />
            </td>
          </tr>
        </template>
        <tr v-for="([name, all, unique], index) in data" :key="name">
          <td class="td-name">
            <span
              ><span :class="`color-${index}`" /> {{ startCase(name) }}</span
            >
          </td>
          <td class="text-right">{{ all | numberFormatter(1) }}</td>
          <td v-if="!hideUnique" class="text-right">
            {{ unique | numberFormatter(1) }}
          </td>
        </tr>
      </tbody>
    </SimpleTable>
  </div>
  <NoResults v-else :style="{ 'min-height': noResultsMinHeight }" />
</template>

<script>
import { computed } from 'vue';
import { Skeleton, SimpleTable } from '@campsite-bio/component-lib';
import startCase from 'lodash/startCase';

import NoResults from '../cards/no-results';
import { useApexOptions, useDataColors } from '../compositions';
import { ChartWrapper } from '../';

export default {
  components: {
    Skeleton,
    SimpleTable,
    ChartWrapper,
    NoResults,
  },

  props: {
    hasResults: Boolean,
    isLoading: Boolean,
    data: {
      type: Array,
      default: null,
    },
    series: {
      type: Object,
      default: null,
    },
    hideUnique: Boolean,
    dataTitle: {
      type: String,
      required: true,
    },
    noResultsMinHeight: {
      type: String,
      default: '150px',
    },
  },

  setup(props) {
    const dataColors = useDataColors();

    const chartOptions = useApexOptions({
      scales: {
        x: {
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
        y: {
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      },
      datalabels: { display: true },
    });

    const styles = computed(() => {
      return dataColors.value.reduce((accum, color, index) => {
        accum[`--c-chart-${index}`] = color;
        return accum;
      }, {});
    });

    return {
      chartOptions,
      styles,
      startCase,
    };
  },
};
</script>

<style lang="scss" scoped>
.color-0,
.color-1,
.color-2,
.color-3 {
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.3em;
  height: 1em;
  width: 1em;
}

.color-0 {
  background-color: var(--c-chart-0);
}

.color-1 {
  background-color: var(--c-chart-1);
}

.color-2 {
  background-color: var(--c-chart-2);
}

.color-3 {
  background-color: var(--c-chart-3);
}
</style>
