<template>
  <div class="manager">
    <Setup
      :step="step"
      :disabled="step !== 1 || disabled"
      :step-color="stepColor"
      :campsite-id="campsiteId"
      :site="site"
      @kickoff="handleKickoff"
      @restart="handleRestart"
      @cancel="handleSkip"
    />
    <template v-if="step === 2">
      <Configuration
        v-model="configurationState"
        :step-color="stepColor"
        :campsite-id="campsiteId"
        :design-id="designId"
        :site="site"
        :is-free-plan="isFreePlan"
        :complete-all-steps="completeAllSteps"
        @theme="theme = $event"
        @loaded="hasLoadedConfig = true"
        @done="handleConfigDone"
      />
      <Accept
        v-if="configurationState && hasLoadedConfig"
        ref="accept"
        :step-color="stepColor"
        :configuration-state="configurationState"
        :site="site"
        :campsite-id="campsiteId"
        :design-id="designId"
        :disabled="disabled"
        :show-cancel-button="showSkip"
        :has-to-pay="hasToPay"
        :show-theme-warning="showThemeWarning"
        @cancel="handleSkip"
        @success="handleSuccess"
        @update:loading="$emit('update:loading', $event)"
      />
    </template>
  </div>
</template>

<script>
import { nextTick, onMounted, ref, watchEffect } from 'vue';

import { Configuration, Setup, Accept } from './steps';
import { useRoute } from '@/compositions';
import { timeout } from '@/utils';

export default {
  components: {
    Configuration,
    Setup,
    Accept,
  },

  props: {
    disabled: Boolean,

    stepColor: {
      type: String,
      default: 'white',
    },

    campsiteId: {
      type: String,
      required: true,
    },

    showSkip: Boolean,
    skipButtonText: {
      type: String,
      default: 'No Thanks',
    },
    hasToPay: Boolean,
    isFreePlan: Boolean,
    showThemeWarning: Boolean,
  },

  setup(props, { emit }) {
    const step = ref(1);
    const designId = ref(null);
    const site = ref(null);
    const configurationState = ref(null);
    const accept = ref(null);
    const completeAllSteps = ref(false);
    const hasLoadedConfig = ref(false);
    const theme = ref('campsite');
    const route = useRoute();

    watchEffect(() => {
      emit('configuration-state', configurationState.value);
      emit('theme', theme.value);
    });

    function handleKickoff(id, website) {
      designId.value = id;
      site.value = website;
      step.value = 2;
    }

    function handleRestart() {
      designId.value = null;
      step.value = 1;
      hasLoadedConfig.value = false;
      resetStorage();
      emit('restart');
    }

    async function handleSkip() {
      emit('skip');
    }

    async function handleSuccess(profile) {
      resetStorage();
      emit('success', profile);
    }

    function handleConfigDone() {
      accept.value.open();
    }

    function resetStorage() {
      sessionStorage.removeItem('wizard:configuration');
      sessionStorage.removeItem('wizard:site');
      sessionStorage.removeItem('wizard:design:id');
    }

    async function handleConfigReload() {
      configurationState.value = JSON.parse(
        sessionStorage['wizard:configuration'],
      );
      site.value = sessionStorage['wizard:site'];
      designId.value = sessionStorage['wizard:design:id'];
      step.value = 2;
      hasLoadedConfig.value = true;
      completeAllSteps.value = true;

      await timeout(500);

      accept.value.open();

      await nextTick();
      accept.value.$el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    onMounted(() => {
      if (
        route.value.query.paid === '1' &&
        sessionStorage['wizard:configuration'] &&
        sessionStorage['wizard:site']
      ) {
        handleConfigReload();
      }
    });

    return {
      handleKickoff,
      handleRestart,
      handleSkip,
      handleSuccess,
      step,
      designId,
      site,
      configurationState,
      theme,
      hasLoadedConfig,
      accept,
      handleConfigDone,
      completeAllSteps,
    };
  },
};
</script>

<style lang="scss" scoped></style>
