<template>
  <SimpleButtonGroup>
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <FilterBtn :disabled="disabled" v-bind="attrs" v-on="on">
          {{ selectedPeriod ? selectedPeriod.name : '-' }}
          <ChevronIcon />
        </FilterBtn>
      </template>

      <List dense>
        <ListItem
          v-for="{ name, value } in availableDates"
          :key="value"
          component="button"
          type="button"
          @click="selectPeriod(value)"
        >
          <ListItemTitle>{{ name }}</ListItemTitle>
        </ListItem>
      </List>
    </v-menu>

    <v-menu offset-y :close-on-content-click="false">
      <template #activator="{ on, attrs }">
        <FilterBtn
          :disabled="disabled || !canChooseCustom"
          v-bind="attrs"
          v-on="on"
        >
          <CalendarIcon />
          <template v-if="currentStartEndDate">
            {{ currentStartEndDate }}
          </template>
          <Skeleton v-else width="87px" />
        </FilterBtn>
      </template>

      <v-date-picker
        v-model="customDateRange"
        range
        :min="minDate"
        :max="maxDate"
        @change="selectCustomDateRange"
      />
    </v-menu>
  </SimpleButtonGroup>
</template>

<script>
import {
  List,
  ListItem,
  ListItemTitle,
  ChevronIcon,
  CalendarIcon,
  Skeleton,
} from '@campsite-bio/component-lib';
import { computed, ref, toRefs, watch } from 'vue';
import { DateTime } from 'luxon';

import {
  FREE_PERIODS,
  DEFAULT_PERIODS,
  ENHANCED_DEFAULT_PERIODS,
} from '../../';
import { SimpleButtonGroup } from '../../../form';
import FilterBtn from '../filter-btn';
import {
  useCurrentProfile,
  useCurrentUserProfileEntitlements,
} from '@/compositions';

export default {
  components: {
    FilterBtn,
    List,
    ListItem,
    ListItemTitle,
    ChevronIcon,
    SimpleButtonGroup,
    CalendarIcon,
    Skeleton,
  },

  props: {
    value: {
      type: [String, Array],
      required: true,
    },

    disabled: Boolean,
    availablePeriods: {
      type: Array,
      default: null,
    },
    canChooseCustom: Boolean,

    minDate: {
      type: String,
      default: null,
    },

    startDate: {
      type: String,
      default: null,
    },

    endDate: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { value, startDate, endDate } = toRefs(props);
    const { hasAnalyticsAddon } = useCurrentUserProfileEntitlements();
    const { isProfileOnFreePlan } = useCurrentProfile();

    const isRangePickerOpen = ref(false);
    const customDateRange = ref(null);

    const availableDates = computed(() => {
      if (props.availablePeriods) return props.availablePeriods;

      if (isProfileOnFreePlan.value) return FREE_PERIODS;

      return hasAnalyticsAddon.value
        ? ENHANCED_DEFAULT_PERIODS
        : DEFAULT_PERIODS;
    });

    const selectedPeriod = computed(() => {
      if (Array.isArray(value.value)) {
        return {
          name: `Custom`,
          value: value.value,
        };
      }

      return availableDates.value.find((p) => p.value === value.value);
    });

    // Get the dates to display in the date picker
    const currentStartEndDate = computed(() => {
      if (!startDate.value) return null;

      const dateFormat = 'MMM d';
      return `${DateTime.fromISO(startDate.value).toFormat(
        dateFormat,
      )} - ${DateTime.fromISO(endDate.value).toFormat(dateFormat)}`;
    });

    // They can't choose a date past today
    const maxDate = computed(() => {
      return DateTime.local().toISODate();
    });

    function selectPeriod(val) {
      emit('input', val);
    }

    watch(value, (val) => {
      if (Array.isArray(val)) {
        customDateRange.value = val;
      }
    });

    function selectCustomDateRange(value) {
      emit('input', value);
      isRangePickerOpen.value = false;
    }

    watch(
      [startDate, endDate],
      ([start, end]) => {
        if (start && end) {
          customDateRange.value = [
            DateTime.fromISO(start).toISODate(),
            DateTime.fromISO(end).toISODate(),
          ];
          isRangePickerOpen.value = false;
        }
      },
      { immediate: true },
    );

    return {
      availableDates,
      selectPeriod,
      selectedPeriod,
      isRangePickerOpen,
      customDateRange,
      maxDate,
      currentStartEndDate,
      selectCustomDateRange,
    };
  },
};
</script>

<style lang="scss" scoped>
.period-selector {
  display: inline-flex;
}
</style>
