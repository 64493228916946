<template>
  <AccordionSectionItem
    v-model="isOpen"
    title="Update your bio link on your social"
    :variant="variant"
  >
    <span slot="icon">{{ step }}</span>

    <VText gutter-bottom
      >Last on the list would be adding your profile Url to any of the social
      media platforms you use. Campsite.bio can be used anywhere to share your
      links.</VText
    >

    <Alert v-if="!isEmailVerified" style="margin-bottom: 1em">
      <InfoIcon slot="icon" />
      You have to confirm your email address to enable your Campsite.bio
      profile.
    </Alert>

    <FormGroup style="margin-bottom: 1.5em">
      <Label for="profile_url">Your Profile Url</Label>
      <Input
        id="profile_url"
        :value="usernameLink"
        readonly
        :disabled="!isEmailVerified"
      >
        <IconButton
          slot="append"
          v-clipboard
          aria-label="Copy profile url"
          :data-clipboard-text="usernameLink"
          :disabled="!isEmailVerified"
        >
          <CopyIcon />
        </IconButton>
      </Input>
    </FormGroup>

    <AccordionItemGroup style="margin-bottom: 1.5em">
      <AccordionItem title="Instagram">
        <ol>
          <li>Go to your Instagram profile</li>
          <li>
            Select the "Edit Profile" button<br />
            <img
              src="~images/getting-started/instagram-profile.png"
              alt="Instagram profile screenshot"
              width="400"
              loading="lazy"
            />
          </li>
          <li>
            Copy your Campsite.bio link and enter it in the website field<br />
            <img
              src="~images/getting-started/instagram-edit-profile.png"
              alt="Instagram profile screenshot"
              width="400"
              loading="lazy"
            />
          </li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Facebook Profile">
        <p>
          If you want more people on Facebook to see your profile link, you'll
          have to make sure your profile is set to public, but this isn't a
          requirement.
        </p>
        <h3 class="h4">On desktop</h3>
        <ol>
          <li>Go to your Facebook profile</li>
          <li>Directly below your profile image, select "Edit bio"</li>
          <li>Paste your profile url</li>
          <li>Click save</li>
        </ol>
        <h3 class="h4">On the mobile app</h3>
        <ol>
          <li>Open the Facebook app</li>
          <li>Open the main menu and select your profile</li>
          <li>
            Beneath your name, paste your profile url into the Edit bio field
          </li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Facebook Page">
        <h3 class="h4">On desktop</h3>
        <ol>
          <li>Go to your Facebook page</li>
          <li>Select "Settings" on the menu bar at the top of the page</li>
          <li>Select "Page Info" in the menu on the left</li>
          <li>Paste your profile url into the website field</li>
          <li>Click "Save Changes"</li>
        </ol>
        <h3 class="h4">On the mobile app</h3>
        <ol>
          <li>Open the Facebook page app</li>
          <li>Click the tools icon in the bottom right corner</li>
          <li>Select "Settings" from the menu</li>
          <li>Select "Page Info"</li>
          <li>Paste your profile url into the website field</li>
          <li>Click "Save"</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="TikTok">
        <p>Make sure your using <b>campsite.to</b> as your profile domain.</p>
        <ol>
          <li>Go to your TikTok profile</li>
          <li>
            Select the "Edit Profile" button<br />
            <img
              src="~images/getting-started/tiktok-profile.png"
              alt="TikTok profile screenshot"
              width="400"
              loading="lazy"
            />
          </li>
          <li>
            Copy your Campsite.bio link and enter it in the website field<br />
            <img
              src="~images/getting-started/tiktok-edit-profile.png"
              alt="TikTok profile screenshot"
              width="400"
              loading="lazy"
            />
          </li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Twitter">
        <h3 class="h4">On desktop</h3>
        <ol>
          <li>Go to your Twitter profile</li>
          <li>Select the "Edit profile" button</li>
          <li>
            Scroll down to the Website field and paste in your profile url
          </li>
          <li>Click the Save button</li>
        </ol>
        <h3 class="h4">On the mobile app</h3>
        <ol>
          <li>Open the Twitter app and go to your profile</li>
          <li>Select the "Edit profile" button</li>
          <li>
            Scroll down to the Website field and paste in your profile url
          </li>
          <li>Click the Save button</li>
        </ol>
      </AccordionItem>
    </AccordionItemGroup>

    <Button
      :disabled="variant === 'success' || !isEmailVerified"
      @click="handleClick"
    >
      Mark as Complete
    </Button>
  </AccordionSectionItem>
</template>

<script>
import {
  AccordionSectionItem,
  AccordionItem,
  AccordionItemGroup,
  VText,
  Button,
  FormGroup,
  Label,
  Input,
  CopyIcon,
  IconButton,
  Alert,
  InfoIcon,
} from '@campsite-bio/component-lib';
import { computed, ref } from 'vue';

import { useStore } from '@/compositions';

export default {
  components: {
    AccordionSectionItem,
    AccordionItem,
    AccordionItemGroup,
    VText,
    Button,
    FormGroup,
    Label,
    Input,
    CopyIcon,
    IconButton,
    Alert,
    InfoIcon,
  },

  props: {
    step: {
      type: [String, Number],
      required: true,
    },

    variant: {
      type: String,
      default: null,
    },

    isComplete: Boolean,
  },

  setup(props, { emit }) {
    const store = useStore();
    const isOpen = ref(false);
    const profile = computed(() => store.getters['profiles/currentProfile']);
    const isEmailVerified = computed(() => store.getters.emailVerified);

    const usernameLink = computed(() => {
      return `https://${profile.value.domain}/${profile.value.username_lowercase}`;
    });

    function handleClick() {
      emit('complete');
      isOpen.value = false;
    }

    return { isOpen, usernameLink, isEmailVerified, handleClick };
  },
};
</script>

<style lang="scss" scoped></style>
