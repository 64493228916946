import anime from 'animejs';
import { ref, watch, onBeforeUnmount, onMounted } from 'vue';

export function useTimelineWithStory(props, timelineOptions = {}) {
  const root = ref(null);

  const timeline = anime.timeline({
    easing: 'easeInOutSine',
    autoplay: !props.static,
    ...timelineOptions,
  });

  const play = () => timeline.play();
  const pause = () => timeline.pause();

  watch(
    () => props.isPaused,
    (val) => {
      if (val) {
        pause();
      } else {
        play();
      }
    },
  );

  function onMount() {
    if (props.static) {
      timeline.seek(timeline.duration);
    }
  }

  onBeforeUnmount(() => {
    timeline.pause();
  });

  return {
    timeline,
    play,
    pause,
    root,
    onMount,
  };
}
