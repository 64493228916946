<template>
  <div
    class="preview-drawer"
    :class="classes"
    :style="styles"
    :aria-expanded="isOpen.toString()"
    @animationend="onAnimationEnd"
  >
    <div v-if="profile">
      <Preview
        class="preview-drawer__phone"
        :profile="profile"
        :links="links"
        :social-accounts="socialAccounts"
        :theme="theme"
        default-profile-image="https://cdn.campsite.bio/default-profile-pic.png"
      />

      <span class="preview-drawer__close">
        <IconButton
          filled
          color="secondary"
          aria-label="Show/hide preview"
          title="Show/hide preview"
          @click="isOpen = !isOpen"
        >
          <ChevronIcon direction="left" title="Show/hide preview" />
        </IconButton>
      </span>
    </div>
  </div>
</template>

<script>
import { IconButton, ChevronIcon, Preview } from '@campsite-bio/component-lib';
import { computed, ref, nextTick } from 'vue';

import { useWindowSize } from '@vueuse/core';

export default {
  components: {
    IconButton,
    ChevronIcon,
    Preview,
  },

  props: {
    profile: {
      type: Object,
      required: true,
    },

    links: {
      type: Array,
      default: () => [],
    },

    socialAccounts: {
      type: Array,
      default: () => [],
    },

    theme: {
      type: [String, Object],
      required: true,
    },
  },

  setup() {
    const isOpen = ref(false);
    const showAnimation = ref(false);
    const container = ref(null);

    const { width } = useWindowSize();

    // CSS transform scale the child element to fit in the parent element
    const styles = computed(() => {
      if (!container.value) return;

      const cw = 428;
      const ch = 868;
      let { width: ww, height: wh } =
        container.value.$el.getBoundingClientRect();
      const windowWidth = width.value;

      let scaleAmtX = Math.min(ww / cw, wh / ch);
      let scaleAmtY = scaleAmtX;

      return {
        '--c-scale': `scale(${scaleAmtX.toFixed(3)}, ${scaleAmtY.toFixed(3)}`,
      };
    });

    const classes = computed(() => {
      return {
        'preview-drawer--open': isOpen.value,
        'preview-drawer--animate': showAnimation.value,
      };
    });

    async function animate() {
      showAnimation.value = false;
      await nextTick();
      showAnimation.value = true;
    }

    function onAnimationEnd() {
      showAnimation.value = false;
    }

    return { container, styles, classes, isOpen, animate, onAnimationEnd };
  },
};
</script>

<style lang="scss">
// Partially show preview drawer before hiding again
@keyframes preview-peek {
  from {
    transform: translate(95%, -50%);
  }
  20%,
  85% {
    transform: translate(50%, -50%);
  }
  to {
    transform: translate(95%, -50%);
  }
}
</style>

<style lang="scss" scoped>
.preview-drawer {
  padding: 0 0.5rem;
  position: fixed;
  top: 50%;
  right: 0;
  transition: all var(--g-transition-speed);
  transform: translate(95%, -50%);
  width: 300px;

  &--animate {
    animation: preview-peek 2s ease-in-out;
  }

  &--open {
    animation: none;
    transform: translateY(-50%);

    .preview-drawer__close {
      transform: translateX(-100%) rotate(180deg);
    }
  }

  &__phone {
    width: 100%;
  }

  &__close {
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all var(--g-transition-speed);
    transform: translateX(-100%);
  }
}
</style>
