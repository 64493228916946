import { useTheme } from '@campsite-bio/component-lib';
import { computed } from 'vue';

export function useDataColors() {
  const theme = useTheme();

  return computed(() => {
    return theme.value.dataColors;
  });
}
