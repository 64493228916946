<template>
  <FancyAd
    dismissible-key="social-cat"
    image-fit="contain"
    :go-to-url="goToUrl"
    is-ad
  >
    <picture slot="img">
      <template v-if="isBusiness || isNonProfit">
        <source
          srcset="~images/ads/brands_mobile.png"
          media="(min-width: 768px)"
        />
        <img
          src="~images/ads/brands_desktop.png"
          alt="Campsite.bio pro profiles"
          loading="lazy"
        />
      </template>
      <template v-else>
        <source
          srcset="~images/ads/creators_mobile.png"
          media="(min-width: 768px)"
        />
        <img
          src="~images/ads/creators_desktop.png"
          alt="Campsite.bio pro profiles"
          loading="lazy"
        />
      </template>
    </picture>

    <template v-if="isBusiness || isNonProfit">
      <VText component="h2" variant="h4" gutter-bottom
        >Are big influencers out of your budget as a small brand?</VText
      >
      <ul>
        <li>Work on gifted-only collaborations on Instagram and TikTok</li>
        <li>Get affordable UGC for your marketing channels &amp; ads</li>
        <li>Pre-vetted micro-influencers only</li>
      </ul>

      <InlineLink component="span">Start your free trial</InlineLink>
    </template>
    <template v-else>
      <VText component="h2" variant="h4" gutter-bottom
        >Are you a micro-influencer looking for gifted &amp; paid
        collaborations?</VText
      >
      <ul>
        <li>Work with brands interested in promotions &amp; content</li>
        <li>Gifted &amp; paid collaborations are available</li>
        <li>Instagram &amp; TikTok and it's completely free for you</li>
      </ul>

      <InlineLink component="span">Sign up now</InlineLink>
    </template>
  </FancyAd>
</template>

<script>
import { VText, InlineLink } from '@campsite-bio/component-lib';
import { computed } from 'vue';

import FancyAd from './fancy-ad';
import { useStore } from '../../compositions';

export default {
  components: {
    FancyAd,
    VText,
    InlineLink,
  },

  setup() {
    const store = useStore();

    const userCategories = computed(() => store.getters.currentUser.categories);

    const isNonProfit = computed(() => {
      return userCategories.value?.includes(
        'Community Organization/Non-Profit',
      );
    });
    const isBusiness = computed(() => {
      return userCategories.value?.includes('Business/Entrepreneur/Non-profit');
    });

    const goToUrl = computed(() => {
      if (isBusiness.value || isNonProfit.value) {
        return 'https://cmplnk.to/7q67vna';
      }

      return 'https://cmplnk.to/7q67wdw';
    });

    return { isBusiness, isNonProfit, goToUrl };
  },
};
</script>

<style lang="scss" scoped>
img {
  background: #fff;
  border-right: 1px solid var(--g-color-gray-200);
}
</style>
