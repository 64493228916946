import { render, staticRenderFns } from "./wrapped-share-create.vue?vue&type=template&id=6aa7ac17&scoped=true&"
import script from "./wrapped-share-create.vue?vue&type=script&lang=js&"
export * from "./wrapped-share-create.vue?vue&type=script&lang=js&"
import style0 from "./wrapped-share-create.vue?vue&type=style&index=0&id=6aa7ac17&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aa7ac17",
  null
  
)

export default component.exports