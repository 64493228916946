<template>
  <AccordionSectionItem title="Customize your theme" :variant="variant">
    <span slot="icon">{{ step }}</span>

    <VText gutter-bottom>
      Make your Campsite profile your own by changing your theme.
    </VText>

    <ul>
      <li>
        You can use one of our beautiful preset themes, or customize your own.
      </li>
      <li>
        Customizing your own theme will give you access to a number of different
        options for fonts, colors and much more.
      </li>
      <li>
        Add any of your social accounts like Facebook, Twitter or Instagram at
        the bottom of the page.
      </li>
    </ul>

    <CardActions class="actions" align="left">
      <Button
        :href="`/profile/${id}/settings/appearance`"
        @click.prevent="handleClick"
      >
        Customize my Theme
      </Button>
      <Button
        href="https://support.campsite.bio/en/articles/6818213-getting-started-with-campsite-bio#h_4d7f0cb5eb"
        target="_blank"
        variant="outline"
        >Learn More</Button
      >
    </CardActions>
  </AccordionSectionItem>
</template>

<script>
import {
  AccordionSectionItem,
  VText,
  Button,
  CardActions,
} from '@campsite-bio/component-lib';

import { useScrollToRoute } from './use-scroll-to-route';
import { useCurrentProfile } from '../../../compositions';

export default {
  components: {
    AccordionSectionItem,
    VText,
    Button,
    CardActions,
  },

  props: {
    step: {
      type: [String, Number],
      required: true,
    },

    variant: {
      type: String,
      default: null,
    },
  },

  setup() {
    const { handleClick } = useScrollToRoute('.js-theme-section');
    const { id } = useCurrentProfile();

    return { handleClick, id };
  },
};
</script>

<style lang="scss" scoped>
.actions.actions {
  margin: 0;
  padding: 0;
}
</style>
